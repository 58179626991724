import React, { useContext, useEffect, useState } from "react";
import { Backdrop, Box, CircularProgress, Tab, Tabs } from "@mui/material";
import { COLORS, SORT_BY } from "../../theme/constants";
import { getConnect, searchPosts } from "../../api";
import {
  checkExitedAndAddLocalStorage,
  handleError,
  handleSetImageSize,
} from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import NoData from "../../components/NoData";
import { useTranslation } from "react-i18next";
import { TabContext, TabPanel } from "@mui/lab";
import SearchUserList from "./SearchUserList";
import SearchPostList from "./SearchPostList";
import { SearchContext } from "../../components/layouts/SearchFeedLayout";
import CircularComponent from "../process/CircularComponent";

const Wrapper = styled.div`
  // height: calc(100vh - 170px);
`;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 150px);
  position: relative;
`;

const AntTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#1E1E1E",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  width: "50%",
  color: "#1E1E1E !important",
  maxWidth: "50% !important",
  fontWeight: "400",
  "&.Mui-selected": {
    fontWeight: "bold",
    color: "#1E1E1E !important",
  },
}));

function SearchResult(props) {
  const { logout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [activeTab, setActiveTab] = useState("1");

  const { searchKeyword, setSelectedPost } = useContext(SearchContext);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue == 1) {
      handleGetPosts({ offset: 0 });
    } else {
      handleGetUsers({ offset: 0 });
    }
  };

  const handleGetPosts = async (newRequestParams = {}) => {
    checkExitedAndAddLocalStorage("recentlyKeywords", searchKeyword);
    try {
      if (!searchKeyword.trim()) return;
      if (data.length == 0) {
        setIsLoading(true);
      }
      const requestParams = {
        keyword: searchKeyword,
        offset: data.length,
        limit: 16,
        sort_by: SORT_BY.LATEST,
        ...newRequestParams,
      };
      const res = await searchPosts(requestParams);
      if (!res?.list) return;
      const newPost = handleSetImageSize(res.list);
      if (newRequestParams?.offset === 0) {
        setData(newPost);
      } else {
        setData((prevState) => [...prevState, ...newPost]);
      }
    } catch (error) {
      handleError(error, logout, navigate);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetUsers = async (newRequestParams = {}) => {
    checkExitedAndAddLocalStorage("recentlyKeywords", searchKeyword);
    try {
      if (!searchKeyword.trim()) return;
      if (userData.length == 0) {
        setIsLoading(true);
      }
      const requestData = {
        keyword: searchKeyword,
        offset: userData.length,
        limit: 16,
        ...newRequestParams,
      };

      const res = await getConnect(requestData);

      if (!res?.list) return;
      const users = res.list;

      if (newRequestParams?.offset === 0) {
        setUserData(users);
      } else {
        setUserData((prevState) => [...prevState, ...users]);
      }
    } catch (error) {
      handleError(error, logout);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickPost = (id, type, index) => {
    setSelectedPost({
      keyword: searchKeyword,
      sort_by: SORT_BY.LATEST,
      id: id,
      index: index,
    });
    if (type == 3) {
      navigate(`/quiz/${id}`);
      return;
    }
    navigate(`/search/details/keyword`);
  };

  const handleClickUser = (nickname) => {
    navigate(`/user-profile/${nickname}`);
  };

  useEffect(() => {
    if (!searchKeyword) return;
    const delayDebounceFn = setTimeout(() => {
      handleGetPosts({ offset: 0 });
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyword]);

  // if (isLoading)
  //   return (
  //     <HeightWhenEmpty>
  //       <CircularComponent />
  //     </HeightWhenEmpty>
  //   );

  return (
    <>
      <Backdrop open={isLoading} sx={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      <Wrapper>
        <TabContext value={activeTab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              position: "sticky",
              top: "56px",
              zIndex: "1",
              backgroundColor: COLORS.white,
            }}
          >
            <AntTabs value={activeTab} onChange={handleChangeTab} aria-label='ant example'>
              <AntTab label='콘텐츠' value='1' />
              <AntTab label='유저' value='2' />
            </AntTabs>
          </Box>
          <TabPanel value='1'>
            {data.length ? (
              <SearchPostList
                data={data}
                fetchMoreData={handleGetPosts}
                onClickPost={handleClickPost}
              />
            ) : (
              <HeightWhenEmpty>
                <NoData text={t("no_results_were_found_for_your_search")} />
              </HeightWhenEmpty>
            )}
          </TabPanel>
          <TabPanel value='2'>
            {userData.length ? (
              <SearchUserList
                data={[...userData]}
                fetchMoreData={handleGetUsers}
                fetchData={() =>
                  handleGetUsers({
                    offset: 0,
                  })
                }
                onClickPost={handleClickUser}
              />
            ) : (
              <HeightWhenEmpty>
                <NoData text={t("no_results_were_found_for_your_search")} />
              </HeightWhenEmpty>
            )}
          </TabPanel>
        </TabContext>
      </Wrapper>
    </>
  );
}

export default SearchResult;
