import { Box, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { COLORS } from "@theme/constants";
import { textMediumMuiStyle } from "@utils/commonStyle";
import { useAuth } from "../../context/Auth";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ListConnection from "./ListConnection";
import ListConnecting from "./ListConnecting";
import { WIDTH } from "../../theme/constants";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    // margin-left: 100px;
    max-width: 600px;
  }
`;

function Connect() {
  const [activeTab, setActiveTab] = useState("connection");

  // begin tab
  const tabLabel = [
    { label: "커넥션", type: "connection" },
    { label: "커넥팅", type: "connecting" },
  ];

  const tabPanel = [
    { content: <ListConnection type="connection" />, type: "connection" },
    { content: <ListConnecting type="connecting" />, type: "connecting" },
  ];

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };
  // end tab
  return (
    <Wrapper>
      <Box sx={{ width: "100%" }}>
        <TabContext value={activeTab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              position: "sticky",
              top: "56px",
              zIndex: 1,
              background: COLORS.white,
            }}
          >
            <TabList
              variant="fullWidth"
              onChange={handleChangeTab}
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: COLORS.primary4,
                },
              }}
            >
              {tabLabel.map((tabItem, index) => (
                <Tab
                  label={tabItem.label}
                  key={tabItem.type}
                  value={tabItem.type}
                  sx={{
                    ...textMediumMuiStyle,
                    "&.Mui-selected": {
                      color: COLORS.primary4,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          {tabPanel.map((tabItem, index) => (
            <TabPanel value={tabItem.type} key={tabItem.type} sx={{ p: 0 }}>
              {tabItem.content}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </Wrapper>
  );
}

export default Connect;
