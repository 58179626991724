import { Avatar, Box, Button, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React from "react";
import { COLORS, FONT_WEIGHT, RADIUS } from "../../theme/constants";
import { textMediumMuiStyle, buttonMuiStyle } from "../../utils/commonStyle";
import ImageAvatar from "../../assets/images/avatar.png";
import { useTranslation } from "react-i18next";

function MemberConnected(props) {
  const {
    id = "",
    url = "",
    profile_image_url = "",
    listItemStyle = {},
    onDisconnect = () => {},
    onConnect = () => {},
    is_active = 0,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <ListItem
        alignItems='flex-start'
        sx={{
          backgroundColor: is_active === 1 ? COLORS.grey25 : "rgba(235, 67, 37, 0.7)",
          borderRadius: RADIUS.medium,
          pl: 1.5,
          py: "11px",
          maxWidth: "300px",
          "& .MuiListItemSecondaryAction-root": {
            right: "12px",
          },
          ...listItemStyle,
        }}
        secondaryAction={
          <Box sx={{ display: "flex", gap: "4px" }}>
            {is_active === 0 && (
              <Button
                sx={{
                  ...buttonMuiStyle,
                  p: 0.25,
                  minWidth: "100px",
                  backgroundColor: COLORS.primary4,
                  color: COLORS.white,
                }}
                onClick={onConnect}
              >
                {t("button.re-connect")}
              </Button>
            )}
            <Button
              sx={{
                ...buttonMuiStyle,
                p: 0.25,
                minWidth: "80px",
                backgroundColor: COLORS.primary4,
                color: COLORS.white,
              }}
              onClick={() => onDisconnect(id)}
            >
              {t("button.disconnect")}
            </Button>
          </Box>
        }
      >
        <ListItemAvatar
          sx={{ minWidth: "36px", mr: 1, my: 0 }}
          // onClick={redirectToProfile}
        >
          <Avatar
            alt=''
            src={profile_image_url || ImageAvatar}
            sx={{
              width: "36px",
              height: "36px",
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={url}
          sx={{
            display: "flex",
            alignSelf: "center",
            "& .MuiListItemText-primary": {
              ...textMediumMuiStyle,
              fontWeight: FONT_WEIGHT.large,
              color: is_active === 1 ? COLORS.black1 : COLORS.white,
            },
          }}
        />
      </ListItem>
    </>
  );
}

export default MemberConnected;
