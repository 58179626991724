import { useContext, useEffect } from "react";
import {
  COLORS,
  EVENT_NAME,
  FONT_WEIGHT,
  ICON_SIZE,
  LINE_HEIGHT,
  STATUS,
  TEXTSIZE,
} from "@theme/constants";
import { GlobalStateContext } from "../../context/GlobalStateProvider";
import useSocket from "./useSocket";
import { renderUId } from "@utils/commonFunction";
import { useQueryClient } from "@tanstack/react-query";
// import { getChats } from "../../api";
import { toast } from "react-toastify";
import { Avatar, Box } from "@mui/material";
import { textMediumMuiStyle } from "@utils/commonStyle";
import AppIcon from "@images/app_icon.jpg";
import MessageToast from "@containers/messages/MessageToast";
import { getChats } from "../../service/chat_services";

function useSocketListener() {
  const { socket } = useSocket();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const queryClient = useQueryClient();
  // const location = useLocation();
  const localUser = JSON.parse(window.localStorage.getItem("user"));
  // console.log("globalState", globalState);

  const chat_access_token = JSON.parse(localStorage.getItem("chat_access_token"));

  // console.log('location',location)

  const saveMessage = (value) => {
    // console.log("message", value);
    setGlobalState({
      ...globalState,
      chat: { ...globalState.chat, message: value },
    });
  };

  const saveMessageError = (value) => {
    setGlobalState({
      ...globalState,
      chat: { ...globalState.chat, messageError: value },
    });
  };

  const saveNotification = () => {
    setGlobalState({
      ...globalState,
      chat: { ...globalState.chat, notification: value },
    });
  };

  const getRecentChats = async () => {
    try {
      const res = await getChats({ includeEmptyMessage: true, keyword: "" });
      // console.log("res", res);
      return res;
    } catch (error) {
      // handleError(error, logout, navigate);
      console.log(error);
    }
  };

  const setSocketToChats = async (socketMessage) => {
    let recentChats = {
      pageParams: [],
      pages: [[]],
    };

    const recentChatsResponse = await queryClient.ensureQueryData({
      queryKey: ["recent-chats"],
      queryFn: getRecentChats,
    });

    if (recentChatsResponse?.pages?.length > 0) {
      recentChats = recentChatsResponse;
    } else {
      recentChats = {
        pageParams: [],
        pages: [recentChatsResponse],
      };
    }

    // console.log("socketMessage", socketMessage);
    // console.log("recentChats", recentChats);
    if (!socketMessage) return;
    const newChats = [];
    const newRecentChats = {
      pageParams: [],
      pages: [],
    };
    recentChats?.pages?.forEach((items, itemIndex) => {
      if (items?.length > 0) {
        // console.log("chat", items);
        const dataTmp = structuredClone(items);
        const index = dataTmp.findIndex(
          (element) =>
            element?.participant?.id == socketMessage?.receiverId ||
            element?.participant?.id == socketMessage?.author?.id
        );
        // console.log("index", index);
        if (index >= 0) {
          const newData = {
            ...dataTmp[index],
            lastMessage: {
              ...dataTmp[index].lastMessage,
              ...socketMessage,
            },
            countUnread: Number(dataTmp[index]?.countUnread) + 1,
          };

          dataTmp.splice(index, 1);
          dataTmp.unshift(newData);
        } else if (itemIndex === 0) {
          // when has new chat from other user
          // console.log("messageSocket",messageSocket)
          dataTmp.unshift({
            participant: {
              // id: "6568123b42ff5c2fa65ea3bb",
              // externalId: "30e8f142-ac91-4de9-9f8d-09ca33399adc",
              // app: "6502d509f0efbc58a4933f1f",
              // system: false,
              // globalName: "qc2",
              // aliasName: "Alma Test",
              // avatar: {
              //   avatarURL:
              //     "https://pub-de6d6b58d1a244ad85b640b74b9e8353.r2.dev/2023/12/13/screenshot50_1702470265.png",
              // },
              // email: "qc2@gmail.com",
              ...socketMessage.author,
            },
            isPrivateChat: true,
            lastMessage: {
              isDeleted: false,
              // timestamp: "2024-01-08T03:52:26.407Z",
              content: socketMessage.content,
              // system: false,
              type: socketMessage.type,
              // pinned: false,
              // attachments: [],
              // stickers: [],
              // mentions: null,
              // privateChatId: "6597d3d5926a216cba469ec8",
              receiver: {
                // id: "656aabf08f9a3c48746641cb",
                // externalId: "60140e37-6bf6-4897-bdbf-7bcf1b67976b",
                // app: "6502d509f0efbc58a4933f1f",
                // system: false,
                // globalName: "Henry",
                // avatar: {
                //   avatarURL:
                //     "https://pub-de6d6b58d1a244ad85b640b74b9e8353.r2.dev/2023/12/15/cc8e20a6b6643ec02b26f905f383a823_1702623860.jpg",
                // },
                // email: "henry.nguyen@metacrew.vn",
              },
              isReaded: false,
              id: "",
              author: {
                ...socketMessage.author,
              },
              createdTimestamp: socketMessage.createdTimestamp,
            },
            // countUnread: 8,
            id: socketMessage.id,
            // ts: 1704685946407,
          });
        }
        // console.log("dataTmp", dataTmp);
        newRecentChats.pages.push(dataTmp);
      }
    });

    if (!recentChats?.pages) {
      const dataTmp = [];
      dataTmp.unshift({
        participant: {
          ...socketMessage.author,
        },
        isPrivateChat: true,
        lastMessage: {
          isDeleted: false,
          content: socketMessage.content,
          type: socketMessage.type,
          receiver: {},
          isReaded: false,
          id: "",
          author: {
            ...socketMessage.author,
          },
          createdTimestamp: socketMessage.createdTimestamp,
        },
        id: socketMessage.id,
      });
      newRecentChats.pages.push(dataTmp);
    }
    // console.log("newRecentChats", newRecentChats);

    queryClient.setQueryData(["recent-chats"], newRecentChats);
  };

  useEffect(() => {
    // console.log("socket")
    if (socket) {
      // Retry to connect socket
      if (!socket?.connected && chat_access_token) {
        // // Update the extraHeaders of the socket
        // socket.io.opts.transports = ["websocket"];
        // socket.io.opts.extraHeaders = {
        //     Authorization: auth.access_token || '',
        // };

        // @ts-ignore
        socket.auth.token = chat_access_token || "";
        // // Reconnect with the updated headers
        socket.connect();
        // socket.on("connect", function () {
        //   console.log("socket.connected success: ", socket.connected);
        // });
      }
      // console.log("socket", socket);
      if (!socket.hasListeners(EVENT_NAME.UPDATE_CHAT_DIRECT_MESSAGE)) {
        // Listen to the event of receiving new messages to direct
        socket.on(EVENT_NAME.UPDATE_CHAT_DIRECT_MESSAGE, (message) => {
          // const messages = queryClient.getQueryData(['messages']);
          console.log("Socket response messages", message);
          // setSocketToMessages(message, messages);

          // const chats = queryClient.getQueriesData(["chats"]);
          // console.log("chats", chats);
          setSocketToChats(message);
          saveMessage({ ...message, status: STATUS.SUCCESS });
          if (
            localUser?.profile?.user_id === message?.author?.externalId ||
            localUser?.profile?.nickname === message?.author?.globalName ||
            message?.author?.id === JSON.parse(window.localStorage.getItem("receiverID"))
          )
            return;
          toast(
            ({ closeToast, toastProps }) => (
              <Box onClick={() => (window.location.href = `chat/detail/${message?.author?.id}`)}>
                <MessageToast
                  authorData={{
                    id: message?.author?.id,
                    avatar: AppIcon,
                    name: message?.author?.globalName,
                    job:message.type === "message" ?  message?.content : "[부착]" ,
                  }}
                  authorStyle={{ padding: 0, gap: "0.5rem" }}
                  avatarStyle={{
                    width: ICON_SIZE.large,
                    height: ICON_SIZE.large,
                  }}
                  nameStyle={{
                    ...textMediumMuiStyle,
                    fontWeight: FONT_WEIGHT.large,
                  }}
                  jobStyle={{
                    ...textMediumMuiStyle,
                    color: COLORS.grey900,
                    fontSize: TEXTSIZE.small,
                  }}
                />
              </Box>
            ),

            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              closeButton: false,
              style: {
                background: "rgba(255, 255, 255, 0.8)",
                marginTop: "50px",
                height: "72px",
                borderRadius: "8px",
                padding: 0,
              },
            }
          );
        });
      }
      if (!socket.hasListeners(EVENT_NAME.UPDATE_CHAT_GROUP)) {
        // Listen to the event of receiving new messages to group
        socket.on(EVENT_NAME.UPDATE_CHAT_GROUP, (message) => {
          console.log("message group", message);
          saveMessage(message);
        });
      }

      if (!socket.hasListeners(EVENT_NAME.EXCEPTION)) {
        // Listen to the event of error exception
        socket.on(EVENT_NAME.EXCEPTION, (message) => {
          console.log("message error in chat", message);
          saveMessageError({ ...message, id: renderUId() });
        });
      }

      if (!socket.hasListeners(EVENT_NAME.INVITED_TO_GROUP)) {
        // Listen to the event of invite to group
        socket.on(EVENT_NAME.INVITED_TO_GROUP, (message) => {
          console.log("you be invited to group", message);
        });
      }

      if (!socket.hasListeners(EVENT_NAME.UPDATE_NAME_GROUP)) {
        // Listen to the event of receiving new messages to group
        socket.on(EVENT_NAME.UPDATE_NAME_GROUP, (message) => {
          console.log("message update group name", message);
          saveMessage({ ...message, type: "CHANGE_NAME_GROUP" });
        });
      }

      if (!socket.hasListeners(EVENT_NAME.GROUP_REMOVED)) {
        // Listen to the event of receiving new messages to group
        socket.on(EVENT_NAME.GROUP_REMOVED, (message) => {
          console.log("message removed group", message);
          saveNotification({
            type: "NOTIFICATION_GROUP_REMOVED",
            data: message,
          });
        });
      }

      if (!socket.hasListeners(EVENT_NAME.REMOVED_FROM_GROUP_BY_OWNER)) {
        // Listen to the event of receiving new messages to group
        socket.on(EVENT_NAME.REMOVED_FROM_GROUP_BY_OWNER, (message) => {
          console.log("message removed group by owner", message);
          saveNotification({
            type: "NOTIFICATION_REMOVED_FROM_GROUP_BY_OWNER",
            data: message,
          });
        });
      }

      if (!socket.hasListeners(EVENT_NAME.ERROR)) {
        // Handle error events if any
        socket.on(EVENT_NAME.ERROR, (error) => {
          console.error("Socket error:", error);
        });
      }

      if (!socket.hasListeners(EVENT_NAME.DISCONNECT)) {
        // Handle disconnect events if any
        // socket.on(EVENT_NAME.DISCONNECT, () => {
        //     console.error('disconnect');
        // });
      }
    }
    return () => {
      if (socket) {
        // Close socket connection when component is unmounted
        // socket.emit(EVENT_NAME.DISCONNECT, {});
        socket.disconnect();
        socket.removeAllListeners();
      }
    };
  }, [socket]);

  return socket;
}

export default useSocketListener;
