import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import { COLORS, FONT_WEIGHT, LINE_HEIGHT, RADIUS } from "../../theme/constants";
import { textSmallMuiStyle, textMediumMuiStyle, buttonMuiStyle } from "../../utils/commonStyle";
import { createConnection } from "../../api";
import { toast } from "react-toastify";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import ImageAvatar from "@images/avatar.png";
import { ReactComponent as PurchaseBlackIcon } from "@icons/purchase_black.svg";

const Wrapper = styled.div``;

function ItemMemberNotConnected(props) {
  const navigate = useNavigate();
  const {
    id = "",
    user_id = "",
    nickname = "",
    comment = "",
    out_links = [],
    introduce = "",
    full_name = null,
    profile_image_url = "",
    bg_image_url = "",
    hashtag = null,
    total_spend = 0,
    fetchData,
    listItemStyle = {},
  } = props;
  const { logout } = useAuth();

  //connection action
  const handleConnect = async () => {
    try {
      await createConnection({ to_user_id: user_id });
      toast("성공적으로 커넥트되었습니다.");
      fetchData();
    } catch (error) {
      handleError(error, logout);
    }
  };

  const redirectToProfile = () => {
    navigate(`/user-profile/${nickname}`);
  };

  return (
    <>
      <ListItem
        alignItems='flex-start'
        sx={{
          backgroundColor: COLORS.grey25,
          borderRadius: RADIUS.medium,
          pl: 1.5,
          py: "11px",
          maxWidth: "300px",
          "& .MuiListItemSecondaryAction-root": {
            right: "12px",
          },
          ...listItemStyle,
        }}
        secondaryAction={
          <Button
            sx={{
              ...buttonMuiStyle,
              p: 0.25,
              minWidth: "80px",
              backgroundColor: COLORS.primary4,
              color: COLORS.white,
            }}
            onClick={handleConnect}
          >
            커넥트
          </Button>
        }
      >
        <ListItemAvatar
          sx={{ minWidth: "36px", mr: 1, my: 0, cursor: "pointer" }}
          onClick={redirectToProfile}
        >
          <Avatar
            alt=''
            src={profile_image_url || ImageAvatar}
            sx={{
              width: "36px",
              height: "36px",
            }}
          />
        </ListItemAvatar>
        <ListItemText
          onClick={redirectToProfile}
          primary={nickname}
          sx={{
            my: 0,
            "& .MuiListItemText-primary": {
              ...textMediumMuiStyle,
              fontWeight: FONT_WEIGHT.large,
            },
            cursor: "pointer",
          }}
          secondaryTypographyProps={{ component: "div" }}
          secondary={
            <Box display={"flex"} alignItems={"center"} gap={"2px"}>
              <PurchaseBlackIcon style={{ width: "12px", height: "12px" }} />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "16px",
                  color: COLORS.black1,
                }}
                component='span'
              >
                {total_spend}
              </Typography>
            </Box>
          }
        />
      </ListItem>
    </>
  );
}

export default ItemMemberNotConnected;
