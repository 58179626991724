import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as RightArrow } from "../../assets/svgs/arrow_right.svg";
import { COLORS, TEXTSIZE, WIDTH } from "../../theme/constants";
import { Box, Divider, Typography } from "@mui/material";
import { textMediumMuiStyle } from "../../utils/commonStyle";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { getBlockUser, getSetting } from "../../api";
import CircularComponent from "../process/CircularComponent";
import NoData from "../../components/NoData";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    // margin-left: 100px;
    max-width: 600px;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 230px);
  position: relative;
`;

function Info() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await getSetting("information");
      console.log("res", res);
      if (!res) return;
      setData(res);
    } catch (error) {
      handleError(error, logout);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const terms = [
    {
      label: "서비스 이용약관",
      link: "/terms",
    },
    {
      label: "개인정보 처리방침",
      link: "/policy",
    },
  ];

  if (isLoading)
    return (
      <HeightWhenEmpty>
        <CircularComponent />
      </HeightWhenEmpty>
    );

  return (
    <>
      {data?.length > 0 ? (
        <Wrapper>
          <Typography className='title' component={"span"} sx={{ color: COLORS.grey500, mr: 0.5 }}>
            {data[0]?.name}
            {/* 주식회사 */}
          </Typography>
          <Typography className='title' component={"span"}>
            {data[0].value}
            {/* 엑스투비 */}
          </Typography>
          <Divider sx={{ my: 2, width: "2rem" }} />
          {data.map(
            ({ name, value, index }) =>
              index != 0 && (
                <Box sx={{ display: "flex", my: 1.5 }} key={name}>
                  <Typography
                    sx={{
                      ...textMediumMuiStyle,
                      mr: 1,
                      color: COLORS.grey500,
                    }}
                  >
                    {name}
                  </Typography>
                  <Typography sx={{ ...textMediumMuiStyle, whiteSpace: "pre-line" }}>
                    {value}
                  </Typography>
                </Box>
              )
          )}
          {terms.map(({ label, link }) => (
            <Link to={link} key={link}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: 2,
                  alignItems: "center",
                }}
              >
                <Typography component={"span"} sx={{ ...textMediumMuiStyle }}>
                  {label}
                </Typography>
                <RightArrow fill={COLORS.grey600} />
              </Box>
            </Link>
          ))}
        </Wrapper>
      ) : (
        <HeightWhenEmpty>
          <NoData />
        </HeightWhenEmpty>
      )}
    </>
  );
}

export default React.memo(Info);
