import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// 언어 리소스를 가져오는 함수
import koTranslation from './language/ko.json';
import enTranslation from './language/en.json';
import zhTranslation from './language/zh.json';
import jaTranslation from './language/ja.json';

i18n.use(initReactI18next).init({
  lng: 'ko', // 기본 언어 설정
  fallbackLng: 'ko', // fallback 언어 설정

  // 언어 리소스
  resources: {
    ko: { translation: koTranslation },
    en_US: { translation: enTranslation },
    zh: { translation: zhTranslation },
    ja: { translation: jaTranslation },
  },

  // 기타 옵션들...
});

export default i18n;
