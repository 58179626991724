import React, { useContext, useEffect, useRef, useState } from "react";
import PostDetailItem from "./PostDetailItem";
import styled from "styled-components";
import { HEIGHT, POST_TYPE, WIDTH } from "../../theme/constants";
import { scroller, Element } from "react-scroll";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getHotCreator,
  getPosts,
  getPostsRecommend,
  getProfilePost,
  getPublicPost,
} from "../../api";
import InfiniteScroll from "react-infinite-scroll-component";
import NoData from "../../components/NoData";
import CircularComponent from "../process/CircularComponent";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { GlobalStateContext } from "../../context/GlobalStateProvider";
import dayjs from "dayjs";
import { Box, CircularProgress } from "@mui/material";

// const Wrapper = styled.div`
//   position: relative;
//   // min-height: ${HEIGHT.content};
//   max-width: ${WIDTH.fold};
//   margin: 0 auto;
//   padding-bottom: 100px;
//   padding-left: 16px;

//   @media screen and (min-width: ${WIDTH.desktop}) {
//     // width: ${WIDTH.desktop_main_contents};
//     padding-bottom: 0px;
//   }
// `;

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    // margin-left: 100px;
    max-width: 600px;
  }
`;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 250px);
`;

const LIMIT_POST = 4;

function PostDetails() {
  const params = useParams();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  let scrollInterval = useRef(null);
  const [inViewIndex, setInViewIndex] = useState(0);

  const { selectedPost, recommendPosts } = useContext(GlobalStateContext);

  const getDataByPostType = async (requestData) => {
    switch (params?.from) {
      case POST_TYPE.MY_FEED:
        return await getPosts(requestData);
      case POST_TYPE.RECOMMEND:
        return await getPostsRecommend({ ...requestData, offset: params?.offset, limit: 8 });
      // return { list: recommendPosts };
      case POST_TYPE.MY_POST:
        return await getProfilePost("posts", requestData);
      case POST_TYPE.MY_POST_PAID:
        return await getProfilePost("post-paid", requestData);
      case POST_TYPE.MY_LIMITED_PAID:
        return await getProfilePost("my-limited-paid", requestData);
      case POST_TYPE.MY_PAID:
        return await getProfilePost("my-paid", requestData);
      case POST_TYPE.PUBLIC_POST:
        return await getPublicPost(
          { ...requestData, onlyPaid: false },
          { nickname: params?.nickname }
        );
      case POST_TYPE.PUBLIC_POST_PAID:
        return await getPublicPost(
          { ...requestData, onlyPaid: true },
          { nickname: params?.nickname }
        );
      case POST_TYPE.HOT_CREATOR:
        return await getHotCreator({
          ...requestData,
          category_id: params?.category_id,
        });
      case POST_TYPE.HOT_CREATOR_MARKET:
        return await getHotCreator({
          ...requestData,
          type: 2,
          category_id: params?.category_id,
        });

      default:
        break;
    }
  };

  const handleScroll = (id) => {
    // console.log("handleScroll", id);
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      // containerId: "scrollableContent",
    });
  };

  const onVideoInView = (index) => {
    setInViewIndex(() => {
      return index;
    });
  };

  const fetchMoreData = async (newRequestData) => {
    try {
      if (posts.length == 0) {
        setIsLoading(true);
      }
      const requestData = {
        offset: posts.length,
        limit: LIMIT_POST,
        ...newRequestData,
      };
      const res = await getDataByPostType(requestData);
      if (!res?.list) return;
      const newPost = res.list;

      if (newRequestData.offset === 0) {
        handleScroll(selectedPost?.id);
        setPosts(newPost);
      } else {
        setPosts((prevState) => [...prevState, ...newPost]);
      }
      if (locationState?.isUpdatePostSuccess) {
        toast("게시물을 수정했습니다.");
        // window.history.replaceState({}, document.title);
      }
    } catch (error) {
      handleError(error, logout, navigate);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleFetchMoreData = () => {
  //   if (posts.length === 0) {
  //     fetchMoreData({
  //       offset: 0,
  //       limit: parseInt(selectedPost?.index) + 2,
  //       // keyword: selectedPost?.keyword === "전체" ? "" : selectedPost?.keyword,
  //     });
  //   } else {
  //     fetchMoreData({
  //       // keyword: selectedPost?.keyword === "전체" ? "" : selectedPost?.keyword,
  //     });
  //   }
  // };

  const handleConnectSuccess = (userId, status) => {
    const newConnection =
      status == "created" ? { created_at: dayjs().format("YYYY-MM-DDTHH:mm:ssZ[Z]") } : "";
    const postsTmp = posts.map((post) =>
      post?.user_profile?.user_id == userId
        ? {
            ...post,
            connection: newConnection,
          }
        : post
    );
    setPosts(postsTmp);
  };

  const fetchNewData = () => {
    const requestData = {
      offset: 0,
      limit: posts.length,
      // keyword: selectedPost?.keyword === "전체" ? "" : selectedPost?.keyword,
    };
    setPosts([]);
    fetchMoreData(requestData);
  };

  useEffect(() => {
    scrollInterval = setInterval(() => {
      if (document.getElementById(selectedPost?.id)) {
        handleScroll(selectedPost?.id);
        clearInterval(scrollInterval);
      }
    }, 1000);

    // handleFetchMoreData();
    fetchMoreData({
      offset: 0,
      limit: parseInt(selectedPost?.index) + 2,
      // keyword: selectedPost?.keyword === "전체" ? "" : selectedPost?.keyword,
    });
    return () => {
      clearInterval(scrollInterval);
    };
  }, [selectedPost?.index]);

  if (isLoading) {
    return (
      <HeightWhenEmpty>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </HeightWhenEmpty>
    );
  }

  if (!selectedPost?.id && !selectedPost?.category_id && posts.length == 0) {
    return (
      <Wrapper>
        <HeightWhenEmpty>
          <NoData />
        </HeightWhenEmpty>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {posts.length > 0 && (
        <InfiniteScroll
          dataLength={posts.length}
          next={() => {
            params?.from != POST_TYPE.RECOMMEND && fetchMoreData({});
          }}
          hasMore={true}
          // scrollableTarget='scrollableContent'
        >
          {posts.map(
            (post, indexPost) =>
              !post?.is_recommend_area && (
                <Element name={post.id} key={post.id} id={post.id}>
                  <PostDetailItem
                    indexPost={indexPost}
                    prevPost={posts[indexPost - 1]}
                    post={post}
                    fetchData={fetchNewData}
                    onVideoInView={() => onVideoInView(indexPost)}
                    onVideoOutView={() => onVideoInView(-1)}
                    isPostInView={inViewIndex === indexPost}
                    handleConnectSuccess={handleConnectSuccess}
                  />
                </Element>
              )
          )}
        </InfiniteScroll>
      )}
    </Wrapper>
  );
}

export default PostDetails;
