import React, { useContext, useEffect, useState } from "react";
import { COLORS, POST_TYPE } from "@theme/constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NoData from "../../../NoData";
import { Box, Grid, Typography } from "@mui/material";
import FeedSkeleton from "../../../layouts/skeleton/Feed";
import { useGetPosts } from "@hooks";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import RecommendInside from "../Recommend/RecommendInside";
import { GlobalStateContext } from "../../../../context/GlobalStateProvider";
import { zeroPad } from "react-countdown";
import dayjs from "dayjs";
import PostDetailItem from "../../../../containers/posts/PostDetailItem";
import QuizHomeItem from "../../../../containers/quiz/QuizHomeItem";
import PostSkeleton from "../../../layouts/skeleton/PostSkeleton";

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 500px);
  position: relative;
`;

function CreatedPost({ post }) {
  console.log("post", post);
  if (!post) return;
  return (
    <Grid item xs={12} md={12}>
      {post?.type == 3 ? <QuizHomeItem post={post} /> : <PostDetailItem post={post} />}
    </Grid>
  );
}

function MyFeed() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const { ref, inView } = useInView();
  const [inViewIndex, setInViewIndex] = useState(0);
  const { data, posts, isLoading, fetchNextPage } = useGetPosts();
  const { setSelectedPost, backgroundHomeType } = useContext(GlobalStateContext);
  // const backgroundHomeType = localStorage.getItem("backgroundHomeType");
  const { state: locationState } = useLocation();

  const [postViews, setPostViews] = useState([]);

  const onVideoInView = (index) => {
    setInViewIndex(() => {
      return index;
    });
  };

  const onClickMedia = (post, indexPost) => {
    setSelectedPost({
      id: post.id,
      index: indexPost,
      from: POST_TYPE.MY_FEED,
      nickname: "",
    });
    if (post?.type == 3) {
      navigate(`/quiz/${post.id}`);
    } else {
      navigate(`/details/${POST_TYPE.MY_FEED}`);
    }
  };

  const removePostLocal = (postID) => {
    const postViewsTmp = structuredClone(postViews);
    const newPosts = postViewsTmp.filter((item) => item?.id !== postID);
    setPostViews(newPosts);
  };

  useEffect(() => {
    setPostViews(posts);
  }, [posts]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  const rendererCountdown = ({ hours, minutes, seconds, completed }) => {
    return (
      <Box sx={{ display: "flex", gap: "8px", justifyContent: "center", alignItems: "center" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: COLORS.white,
            // minWidth: "40px"
          }}
        >
          {zeroPad(hours)}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: COLORS.grey300,
            marginTop: "-5px",
          }}
        >
          :
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: COLORS.white,
            // minWidth: "40px"
          }}
        >
          {zeroPad(minutes)}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: COLORS.grey300,
            marginTop: "-5px",
          }}
        >
          :
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: COLORS.white,
            // minWidth: "40px",
          }}
        >
          {zeroPad(seconds)}
        </Typography>
      </Box>
    );
  };

  const handleConnectSuccess = (userId, status) => {
    const newConnection =
      status == "created" ? { created_at: dayjs().format("YYYY-MM-DDTHH:mm:ssZ[Z]") } : "";
    const postsTmp = postViews.map((post) =>
      post?.user_profile?.user_id == userId
        ? {
            ...postViews,
            connection: newConnection,
          }
        : post
    );
    setPostViews(postsTmp);
  };

  if (isLoading)
    return (
      <Box>
        <PostSkeleton />
        <PostSkeleton />
      </Box>
    );

  return (
    <>
      {postViews?.length > 0 ? (
        <Grid container spacing={2}>
          {postViews?.map((post, indexPost) =>
            post?.is_recommend_area ? (
              <RecommendInside
                key={indexPost}
                data={post?.recommend_list}
                offset={post?.recommend_offset}
              />
            ) : (
              post?.id && (
                <Grid item xs={12} md={12} key={post.id}>
                  {post?.type == 3 ? (
                    <QuizHomeItem post={post} />
                  ) : (
                    <PostDetailItem
                      indexPost={indexPost}
                      prevPost={postViews[indexPost - 1]}
                      post={post}
                      fetchData={fetchNextPage}
                      onVideoInView={() => onVideoInView(indexPost)}
                      onVideoOutView={() => onVideoInView(-1)}
                      isPostInView={inViewIndex === indexPost}
                      handleConnectSuccess={handleConnectSuccess}
                    />
                  )}
                </Grid>
              )
            )
          )}
          <p ref={ref}></p>
        </Grid>
      ) : (
        <HeightWhenEmpty>
          <NoData text='게시물이 없습니다.' />
        </HeightWhenEmpty>
      )}
    </>
  );
}

export default MyFeed;
