import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { COLORS, RADIUS, TEXTSIZE, WIDTH } from "../../theme/constants";

const Wrapper = styled.div`
  .title {
    font-size: ${TEXTSIZE.large};
    font-weight: 700;
    line-height: 27px;
    height: 27px;
    color: var(--textColor);
  }

  .content {
    display: inline-block;
    font-size: ${TEXTSIZE.medium};
    line-height: 23.8px;
    margin: 12px 0 24px;
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    button:hover {
      background: var(--mainColorLight);
      color: var(--subTextColor);
    }

    .MuiButtonBase-root,
    .MuiButton-root {
      box-shadow: none;
    }
  }

  .button-cancel,
  .button-submit {
    width: calc(50% - 4px);
    height: 48px;
  }

  .button-cancel {
    background: var(--colorF3to3);
    color: ${COLORS.gray_7};
  }

  .button-submit,
  .button-bothSubmit {
    background: var(--mainColor);
    color: var(--bgColor);

    &.Mui-disabled {
      background: var(--bglightColor);
      color: var(--subTextColor);
    }
  }
`;

function UserBlockCheck({ data, closeModal, callBack }) {
  return (
    <Wrapper>
      <div className=''>
        <h3 className='title'>{data.title ? data.title : null}</h3>
        <div className='content'>{data.text}</div>
      </div>
      <div className='buttons'>
        <Button
          className='button-cancel'
          variant='contained'
          size='large'
          onClick={closeModal}>
          취소
        </Button>
        <Button
          className='button-submit'
          variant='contained'
          size='small'
          onClick={callBack}>
          차단
        </Button>
      </div>
    </Wrapper>
  );
}

export default React.memo(UserBlockCheck);
