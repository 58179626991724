import React, { useEffect } from "react";
import styled from "styled-components";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { COLORS, RADIUS, TEXTSIZE } from "../theme/constants";
import { format } from "date-fns";
import PicHistorySkeleton from "./layouts/skeleton/PcikHistory";
import { getPicHistory } from "../api";
import { useInView } from "react-intersection-observer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { useInfiniteQuery } from "react-query";
import NoData from "./NoData";

const Wrapper = styled.div`
  .history-list {
    flex-direction: column;

    .history-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      background-color: var(--colorF3to3);
      border-radius: ${RADIUS.medium};
      padding: 12px;
      font-size: ${TEXTSIZE.small};
      font-weight: 600;

      .pic-price {
        width: fit-content;
        min-width: 50px;
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
  }

  .MuiList-root {
    padding: 0;
  }

  .history-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 12px;
    text-align: left;
    font-size: ${TEXTSIZE.xSmall};
    line-height: 17px;
    color: ${COLORS.gray_7};
  }

  .info-text {
    margin: 20px auto;
    font-size: ${TEXTSIZE.xSmall};
    color: ${COLORS.gray_7};
    text-align: center;
  }
`;

function PicHistory({ tab_type }) {
  const { logout } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [ref, inView] = useInView();

  const {
    data,
    status,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ["picHistoryList", searchParams.get("tab")],
    ({ pageParam = 0 }) => {
      return searchParams.get("tab") === "2"
        ? getPicHistory(pageParam).catch((error) => {
            if (error.response.data.error.code === "UNAUTHORIZED") {
              logout();
              navigate("/login");
            }
          })
        : getPicHistory(pageParam).catch((error) => {
            if (error.response.data.error.code === "UNAUTHORIZED") {
              logout();
              navigate("/login");
            }
          });
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next ?? undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, fetchNextPage]);

  return (
    <Wrapper>
      {status === "loading" ? (
        <PicHistorySkeleton />
      ) : status === "error" ? (
        <NoData type='noconnect' />
      ) : isFetching && !isFetchingNextPage ? (
        <PicHistorySkeleton />
      ) : (
        <>
          <div className='history-table-header'>
            <div className='pic-price-header'>결제 금액</div>
            <div className='pic-count-header'>
              {tab_type === "buy" ? "PIC 개수" : "아이디"}
            </div>
            <div className='pic-date-header'>날짜</div>
          </div>
          {data.pages ? (
            data.pages.map((itemData, index) => (
              <List className='history-list' key={index}>
                {itemData?.data.map((item) => (
                  <ListItem
                    disablePadding
                    className='history-item-wrap'
                    key={item.id}>
                    <ListItemText
                      key={item.id}
                      primary={
                        <div className='history-item'>
                          <div className='pic-price'>
                            {item.cost
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            개
                          </div>
                          <div className='pic-count'>
                            {tab_type === "buy"
                              ? `${item.target_nickname
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    원`
                              : item.target_nickname}
                          </div>
                          <div className='pic-date'>
                            {format(new Date(item.paid_at), "yy.MM.dd")}
                          </div>
                        </div>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ))
          ) : (
            <NoData />
          )}
          <p className='info-text'>
            {/* 보다 자세한 내역은 웹에서 확인이 가능합니다. */}
          </p>
          <div ref={ref}></div>
        </>
      )}
    </Wrapper>
  );
}

export default React.memo(PicHistory);
