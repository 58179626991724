import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as ArrowLeft } from "@icons/arrow_left.svg";
import { useMatch, useNavigate } from "react-router-dom";
import { COLORS } from "@theme/constants";
import { useAuth } from "../../context/Auth";

function HeaderBack(props) {
  const { title = "test" } = props;
  const createNickname = useMatch("/signup/profile-settings")
  const { logout } = useAuth();

  const navigate = useNavigate();

  const handleGoBack = () => {
    if(createNickname) {
      logout();
    } else {
      navigate(-1);
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", p: "16px 0" }}>
      <ArrowLeft fill={COLORS.grey600} style={{ cursor: "pointer" }} onClick={handleGoBack} />
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "700",
          lineHeight: "24px",
        }}
      >
        {title}
      </Typography>
      <Box></Box>
    </Box>
  );
}

export default HeaderBack;
