// 이용약관
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS, TEXTSIZE, WIDTH } from "../../theme/constants";
import { Typography } from "@mui/material";
import { textMediumMuiStyle } from "../../utils/commonStyle";
import { useAuth } from "../../context/Auth";
import { handleError } from "../../utils/commonFunction";
import CircularComponent from "../process/CircularComponent";
import { getSetting } from "../../api";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 230px);
  position: relative;
`;

function Policy() {
  const { logout } = useAuth();
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await getSetting("policy");
      if (!res) return;
      setData(res);
    } catch (error) {
      handleError(error, logout);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading)
    return (
      <HeightWhenEmpty>
        <CircularComponent />
      </HeightWhenEmpty>
    );

  return (
    <Wrapper>
      <Typography
        variant='body1'
        dangerouslySetInnerHTML={{
          __html: data,
        }}
      ></Typography>
    </Wrapper>
  );
}

export default React.memo(Policy);
