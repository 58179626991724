import React, { useContext } from "react";
import styled from "styled-components";
import { Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PICComponent from "../../../components/newComponents/posts/PICComponent";
import { POST_TYPE } from "../../../theme/constants";
import { GlobalStateContext } from "../../../context/GlobalStateProvider";

const Wrapper = styled.div``;

function ItemProfilePostList(props) {
  const { post, indexPost, nickname = "", media, page } = props;
  const navigate = useNavigate();
  const { setSelectedPost } = useContext(GlobalStateContext);

  const getPostType = () => {
    switch (page) {
      case "profile/tab1":
        return POST_TYPE.MY_POST;
      case "profile/tab2":
        return POST_TYPE.MY_LIMITED_PAID;
      case "profile/my-quiz":
        return POST_TYPE.MY_QUIZ;
      case "profile/tab3":
        return POST_TYPE.MY_PAID;
      case "profile/other/tab1":
        return POST_TYPE.PUBLIC_POST;
      case "profile/other/tab2":
        return POST_TYPE.PUBLIC_POST_PAID;
      default:
        break;
    }
  };
  return (
    <Wrapper>
      <Box
        sx={{
          position: "relative",
          //   my: 1
        }}
      >
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedPost({
              id: post.id,
              index: indexPost,
              from: getPostType(),
              nickname: nickname,
            });

            if (post?.type === 3) { //getPostType() == POST_TYPE.MY_QUIZ
              navigate(`/quiz/${post.id}`);
              return;
            }

            if ([POST_TYPE.PUBLIC_POST, POST_TYPE.PUBLIC_POST_PAID].includes(getPostType())) {
              navigate(`/details/nickname/${getPostType()}/${nickname}`);
            } else {
              navigate(`/details/${getPostType()}`);
            }
          }}
        >
          {media}
        </Box>
        {/* <Box
          sx={{
            position: "absolute",
            zIndex: 9,
            bottom: "0.7rem",
            left: "0.5rem",
            // width: "100%",
            py: 0.25,
            px: 0.5,
            borderRadius: "8px",
            background: "rgba(0,0,0,0.5)",
          }}
        >
          <PICComponent post={post} page={page} />
        </Box> */}
      </Box>
    </Wrapper>
  );
}

export default ItemProfilePostList;
