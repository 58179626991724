import { useQuery } from "@tanstack/react-query";
import { getSuggestConnection } from "../api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { handleError } from "../utils/commonFunction";

function useGetSuggestConnection() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleFetch = async () => {
    try {
      const res = await getSuggestConnection();
      return res?.list
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["suggest-connection"],
    queryFn: handleFetch,
  });

  return {
    data,
    isLoading,
  };
}

export default useGetSuggestConnection;
