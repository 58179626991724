import {
  Box,
  LinearProgress,
  Slider,
  Typography,
  styled as styledMui,
} from "@mui/material";
import React from "react";
import { COLORS } from "../../theme/constants";
import "./ControlStyle.css";

const TinyText = styledMui(Typography)({
  fontSize: "0.75rem",
  // opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
  color: "black",
});

function VideoControls(props) {
  const { playerRef, playing, playedSeconds, duration } = props;

  const getProcessPercent = () => ((playedSeconds - 0) * 100) / (duration - 0);

  function formatDuration(value) {
    const minute = Math.floor(value / 60);
    const secondLeft = Math.floor(value - minute * 60);
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  const seek = (e) => {
    playerRef.current.seekTo(+e.target.value, "seconds");
  };

  return (
    <Box sx={{ width: "calc(100% - 32px)", mx: "auto" }}>
      {/* <Slider
        // aria-label="time-indicator"
        // size="small"
        value={playedSeconds}
        min={0}
        step={1}
        max={duration}
        onChange={(e) => seek(e)}
        // onMouseDown={this.handleSeekMouseDown}
        // onChange={this.handleSeekChange}
        // onMouseUp={this.handleSeekMouseUp}
        sx={{
          color: COLORS.primary4,
          // height: 4,
          padding: "0 !important",
          // "& .MuiSlider-thumb": {
          //   width: 8,
          //   height: 8,
          //   transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
          //   "&::before": {
          //     boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
          //   },
          //   "&:hover, &.Mui-focusVisible": {
          //     boxShadow: `0px 0px 0px 8px ${"rgb(0 0 0 / 16%)"}`,
          //   },
          //   "&.Mui-active": {
          //     width: 20,
          //     height: 20,
          //   },
          // },
          // "& .MuiSlider-rail": {
          //   opacity: 0.28,
          // },
        }}
      /> */}
        <input
          type="range"
          min={0}
          max={duration}
          step="any"
          value={playedSeconds}
          onChange={seek}
        />
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: -2,
        }}
      >
        <TinyText>{formatDuration(playedSeconds)}</TinyText>
        <TinyText>{formatDuration(duration - playedSeconds)}</TinyText>
      </Box> */}
    </Box>
  );
}

export default VideoControls;
