import React, { useState } from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import styled from "styled-components";
import { BlurhashCanvas } from "react-blurhash";
import Overlay from "@components/overlay/Overlay";
import {
  RADIUS,
  COLORS,
  ICON_SIZE,
  LINE_HEIGHT,
  WIDTH,
} from "@theme/constants";
import { isMine, handleError, numberWithCommas } from "@utils/commonFunction";
import ImageDefault from "@images/default_image.jpg";
import ImageAvatar from "@images/avatar.png";
import { ReactComponent as PurchaseIcon } from "@icons/purchase.svg";
import {
  buttonMuiStyle,
  truncateStyle,
  textMediumMuiStyle,
} from "@utils/commonStyle";
import { unlockOrDonatePost, getUserBalance } from "@api";
import RememberPurchase from "../../components/modal/RememberPurchase";
import ModalComponent from "../../components/modal/ModalComponent";
import { useModalReducer } from "@hooks";
import { MODAL_STATUS } from "../../hooks/useModalReducer";
import { useAuth } from "../../context/Auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import MarketCategories from "./MarketCategories";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";

const Wrapper = styled.div`
  position: relative;
  aspect-ratio: 1;
  width: 50%;
  height: fit-content;
  .bg-img {
    aspect-ratio: 1;
    border-radius: ${RADIUS.large};
    width: 100%;
  }
`;

const titleAndContentStyle = {
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",
  color: COLORS.black1,
  maxWidth: "100%",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
};

function MarketItemHorizontal(props) {
  const { data, index = 0, post, fetchData } = props;

  const { logout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { modalData, dispatchModal } = useModalReducer();
  const [checkedPurchase, setCheckedPurchase] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const isMobileWithoutFold = useMediaQuery({
    query: `(max-width: ${WIDTH.fold})`,
  });

  const getImageUrl = () => {
    let result = ImageDefault;
    if (
      post?.is_paid ||
      post?.cost <= 0 ||
      index === 0 ||
      isMine(post?.user_profile?.user_id)
    ) {
      result = data?.view_url || data?.url;
    }

    return result || ImageDefault;
  };

  const isBlurImage = () => {
    let result = false;
    if (
      !isMine(post?.user_profile?.user_id) &&
      post?.cost > 0 &&
      !post?.is_paid &&
      index != 0
    ) {
      result = true;
    }
    return result;
  };

  // Purchase begin
  const handlePurchase = (e) => {
    e.stopPropagation();
    if (localStorage.getItem("isRememberPurchase") === "true") {
      handleConfirmPurchase();
      return;
    }

    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        type: "purchase",
        btnOKText: t("button.check"),
        btnCancelText: t("button.cancel"),
        title: t("dialog.title.purchase", { cost: post.cost }),
        subTitle: "",
        content: <RememberPurchase setCheckedPurchase={setCheckedPurchase} />,
      },
    });
  };

  const handleConfirmPurchase = async () => {
    if (localStorage.getItem("isRememberPurchase") != "true") {
      localStorage.setItem("isRememberPurchase", checkedPurchase);
    }
    try {
      const user = await getUserBalance();
      if (user?.balance < post.cost) {
        dispatchModal({
          type: MODAL_STATUS.OPEN,
          data: {
            type: "notEnoughUserPIC",
            open: true,
            title: t("dialog.title.purchase_not_enough"),
            subTitle: "",
            btnOKText: t("button.charge"),
            btnCancelText: t("button.cancel"),
            content: (
              <Typography
                sx={{
                  ...textMediumMuiStyle,
                  color: COLORS.grey900,
                  lineHeight: LINE_HEIGHT.xxxLarge,
                  whiteSpace: "pre-line",
                }}
              >
                {t("dialog.content.purchase_not_enough")}
              </Typography>
            ),
          },
        });
        return;
      }
      await unlockOrDonatePost(post.id, {
        pic: post.cost,
        type: "unlock",
      });

      dispatchModal({
        type: MODAL_STATUS.OPEN,
        data: {
          type: "purchaseSuccess",
          btnOKText: t("button.check"),
          btnCancelText: "",
          title: t("dialog.title.purchase_completed"),
          subTitle: "",
          content: (
            <Typography
              sx={{
                ...textMediumMuiStyle,
                color: COLORS.grey900,
                lineHeight: LINE_HEIGHT.xxxLarge,
                whiteSpace: "pre-line",
              }}
            >
              {t("dialog.content.purchase_completed")}
            </Typography>
          ),
        },
      });
    } catch (error) {
      const errorMessage = handleError(error, logout, navigate);
      toast(errorMessage);
    }
  };
  // Purchase end

  //modal begin
  const handleOk = () => {
    handleClose();
    switch (modalData.type) {
      case "purchase":
        handleConfirmPurchase();
        break;
      case "purchaseSuccess":
        fetchData();
        break;
      case "notEnoughUserPIC":
        const drawerRoot = document.getElementById("drawer-root");
        drawerRoot.style.display = "block";
        setOpenPayment(true);
        break;
      default:
        break;
    }
  };
  const handleClose = () => {
    dispatchModal({ type: MODAL_STATUS.CLOSE });
    // if (modalData.type === "purchaseSuccess") {
    //   fetchData();
    // }
  };
  //modal end

  const onClickAuthor = (e) => {
    e.stopPropagation();
    if (isMine(post?.user_profile?.user_id)) {
      navigate("/profile");
    } else {
      navigate(`/user-profile/${post?.user_profile?.nickname}`);
    }
  };

  return (
    <>
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleOk={handleOk}
        handleCancel={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
      <Box
        sx={{ display: "flex", gap: "12px", cursor: "pointer" }}
        onClick={() => navigate(`/market/${post?.id}`)}
      >
        <Wrapper>
          {isBlurImage() && data?.blur_view_url ? (
            <BlurhashCanvas
              className="bg-img"
              hash={data?.blur_view_url}
              punch={0}
            />
          ) : (
            <img
              className="bg-img"
              src={getImageUrl()}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = ImageDefault;
              }}
            />
          )}

          <Overlay
            styleCustomize={{
              borderRadius: RADIUS.large,
              aspectRatio: "1",
              height: "auto",
            }}
          />

          {post?.files?.length > 1 && (
            <Box
              sx={{
                position: "absolute",
                zIndex: 2,
                bottom: "1rem",
                // width: "100%",
                left: "8px",
                bottom: "12px",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                p: 0.5,
                borderRadius: "4px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "8px",
                  fontWeight: "700",
                  lineHeight: "10px",
                  color: COLORS.white,
                }}
              >
                잔여수량 {numberWithCommas(post?.limited - post?.paid_posts_count)}
              </Typography>
            </Box>
          )}

          {post?.limited == post?.paid_posts_count && (
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                top: 0,
                backgroundColor: "rgb(0,0,0,0.5)",
                borderRadius: RADIUS.large,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                aspectRatio: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "20px",
                  color: COLORS.white,
                }}
              >
                SOLD OUT
              </Typography>
            </Box>
          )}
        </Wrapper>
        <Box sx={{ py: 0.5, width: "50%" }}>
          <Box
            sx={{ display: "flex", gap: "4px", mb: 0.5 }}
            onClick={onClickAuthor}
          >
            <Avatar
              alt=""
              src={post?.user_profile?.profile_image_url || ImageAvatar}
              sx={{
                width: ICON_SIZE.small,
                height: ICON_SIZE.small,
              }}
            />
            <Box
              sx={{
                width: "calc(100% - 30px)",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "500",
                  lineHeight: "12px",
                  color: COLORS.black1,
                }}
              >
                {post?.user_profile?.nickname}
              </Typography>
              <Typography
                sx={{
                  fontSize: "8px",
                  fontWeight: "400",
                  lineHeight: "12px",
                  color: COLORS.grey400,
                  ...truncateStyle,
                }}
              >
                {post?.user_profile?.introduce}
              </Typography>
            </Box>
          </Box>

          {/* title */}
          <Typography
            sx={{
              ...titleAndContentStyle,
              WebkitLineClamp: 2,
            }}
          >
            {post?.title ? post?.title : post?.contents}
          </Typography>

          <MarketCategories post={post} />
          <Box sx={{ display: "flex", gap: "4px", mt: 0.5 }}>
            <PurchaseIcon width={"24px"} height={"24px"} />
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "24px",
                color: COLORS.black1,
                // my: 1,
              }}
            >
              {numberWithCommas(post?.cost)}
            </Typography>
          </Box>
          <Button
            sx={{
              maxWidth: "88px",
              ...buttonMuiStyle,
              py: 0.5,
              backgroundColor: COLORS.primary4,
              color: COLORS.white,
              mt: 0.5,
            }}
            disabled={
              post?.limited == post?.paid_posts_count ||
              post?.is_paid ||
              isMine(post?.user_profile?.user_id)
            }
            onClick={handlePurchase}
          >
            {t("button.purchase")}
          </Button>
        </Box>
        {openPayment && (
          <DrawerPortal>
            <DrawerFrame
              setOnDrawer={() => {
                setOpenPayment(false);
                handlePurchase();
              }}
              handleModal={() => {}}
              type={"payment"}
            />
          </DrawerPortal>
        )}
      </Box>
    </>
  );
}

export default MarketItemHorizontal;
