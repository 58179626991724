import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getPostDetail,
  getQuizResult,
} from "../api";
import { ICON_SIZE, COLORS } from "@theme/constants";
import { toast } from "react-toastify";
import { handleError, getUserTimezone } from "@utils/commonFunction";
import { useAuth } from "../context/Auth";
import { useTranslation } from "react-i18next";
import ClockImage from "@images/clock.png";
import Countdown, { zeroPad } from "react-countdown";
import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";
import { WIDTH } from "../theme/constants";
import styled from "styled-components";
import CircularComponent from "../containers/process/CircularComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import Author from "../containers/author/Author";

const Wrapper = styled.div`
  padding: 0 0px;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    margin-left: 100px;
    max-width: 600px;
  }
`;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 230px);
  position: relative;
`;

function QuizDetailStatus() {
  const params = useParams();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [post, setPost] = useState();
  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });


  // START get detail
  const handleGetDetail = async () => {
    try {
      setIsLoading(true);
      const res = await getPostDetail(params.id);
      setPost(res);
    } catch (error) {
      handleError(error, logout, navigate);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetQuizResult = async (newRequestParam) => {
    try {
      setIsLoading(true);
      const requestParam = {
        limit: 15,
        offset: data.length,
        ...newRequestParam,
      };
      const res = await getQuizResult(params.id, requestParam);
      const newData = res.list;
      setTotal(res?.meta?.total);
      if (newRequestParam.offset == 0) {
        setData(newData);
      } else {
        setData((prevState) => [...prevState, ...newData]);
      }
    } catch (error) {
      handleError(error, logout, navigate);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      handleGetDetail();
      handleGetQuizResult({
        offset: 0,
      });
    }
  }, [params.id]);
  // END get detail

  const getCorrectAnswer = (quizDetail) => {
    let correctAnswer = "";
    const correctAnswerObj = quizDetail?.answer?.filter((answer) => answer.is_correct == 1);
    correctAnswer = correctAnswerObj?.[0]?.content;
    return correctAnswer;
  };

  const rendererCountdown = ({ hours, minutes, seconds }) => {
    return (
      <Box sx={{ display: "flex", gap: "12px", justifyContent: "center", alignItems: "center" }}>
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            lineHeight: "32px",
            color: COLORS.white,
            // minWidth: "40px"
          }}
        >
          {zeroPad(hours)}
        </Typography>
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            lineHeight: "32px",
            color: COLORS.grey300,
            marginTop: "-5px",
          }}
        >
          :
        </Typography>
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            lineHeight: "32px",
            color: COLORS.white,
            // minWidth: "40px"
          }}
        >
          {zeroPad(minutes)}
        </Typography>
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            lineHeight: "32px",
            color: COLORS.grey300,
            marginTop: "-5px",
          }}
        >
          :
        </Typography>
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            lineHeight: "32px",
            color: COLORS.white,
            // minWidth: "40px",
          }}
        >
          {zeroPad(seconds)}
        </Typography>
      </Box>
    );
  };

  const header = (text) => (
    <Typography
      sx={{
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "16px",
        color: COLORS.grey400,
      }}
    >
      {text}
    </Typography>
  );

  if (isLoading)
    return (
      <HeightWhenEmpty>
        <CircularComponent />
      </HeightWhenEmpty>
    );

  return (
    <Wrapper>
      <Box sx={{ minHeight: `calc(100vh - 56px - 80px - ${isPc ? "180px" : "0px"})`, px: 2 }}>
        <InfiniteScroll
          dataLength={data.length}
          next={() =>
            handleGetQuizResult({
              offset: data.length,
            })
          }
          hasMore={true}
          scrollableTarget='scrollableContent'
        >
          <Box sx={{ mb: 4 }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "20px",
                color: COLORS.black1,
                mb: 2,
              }}
            >
              퀴즈 정답
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20px",
                color: COLORS.black1,
                mb: 2,
              }}
            >
              {/* {post?.} */}
              {getCorrectAnswer(post?.quiz?.[0])}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "20px",
                color: COLORS.black1,
              }}
            >
              참여자 리스트
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "16px",
                color: COLORS.primary4,
              }}
            >
              현재 총 {total}명 참여 중
            </Typography>
          </Box>
          <Box>
            <Grid container rowSpacing={{ xs: 2 }} alignItems='center'>
              {/* START Header */}
              <Grid item xs={2}>
                {header("순위")}
              </Grid>
              <Grid item xs={5}>
                {header("유저")}
              </Grid>
              <Grid item xs={3}>
                {header("정답")}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  textAlign: "center",
                }}
              >
                {header("제출시간")}
              </Grid>
              {/* END Header */}

              {data.length > 0 &&
                data.map((quiz, index) => (
                  <Fragment key={quiz?.id}>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: "400",
                          lineHeight: "12px",
                          color: COLORS.grey600,
                          width: "40px",
                        }}
                      >
                        {index + 1}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Author
                        authorData={{
                          id: quiz?.user_id,
                          avatar: quiz?.user_profile?.profile_image_url,
                          name: quiz?.user_profile?.nickname,
                        }}
                        authorStyle={{
                          padding: "0",
                          gap: "0.5rem",
                        }}
                        avatarStyle={{
                          width: ICON_SIZE.xMedium,
                          height: ICON_SIZE.xMedium,
                        }}
                        nameStyle={{
                          width: "calc(100% - 0px)",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "18px",
                          color: COLORS.black1,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "18px",
                          color: COLORS.black1,
                        }}
                      >
                        {quiz?.answer}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "18px",
                          color: COLORS.grey500,
                        }}
                      >
                        {dayjs(quiz?.created_at)
                          .subtract(getUserTimezone(), "hour")
                          .format("HH:mm")}
                      </Typography>
                    </Grid>
                  </Fragment>
                ))}
            </Grid>
          </Box>
        </InfiniteScroll>
      </Box>

      {!post?.is_bonus && (
        <Box
          sx={{
            position: "fixed",
            width:"100%",
            maxWidth: "600px",
            // position: "sticky",
            display: "flex",
            gap: "10px",
            bottom: 0,
            zIndex: 1,
            backgroundColor: COLORS.grey700,
            padding: "1rem 2.25rem",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 0.5 }}>
            <img src={ClockImage} style={{ width: "28px" }} />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "16px",
                color: COLORS.white,
              }}
            >
              퀴즈 종료까지
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: COLORS.grey900,
              borderRadius: 1,
              p: "0.5rem 1.5rem",
            }}
          >
            <Countdown
              onComplete={() => {
                if (
                  dayjs().diff(dayjs(post?.event_end).subtract(getUserTimezone(), "hour")) < 1000
                ) {
                  toast(
                    () => (
                      (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            lineHeight: "18px",
                            color: COLORS.white,
                          }}
                        >
                          이미 종료된 퀴즈입니다.
                        </Typography>
                      ),
                      {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        closeButton: false,
                        style: {
                          background: "rgba(235, 67, 37, 0.7)",
                          bottom: isPc ? "262px" : "92px",
                          left: isPc ? "50%" : "0%",
                          borderRadius: "8px",
                          padding: 0,
                        },
                      }
                    )
                  );
                }
              }}
              now={() => dayjs()}
              date={dayjs(post?.event_end).subtract(getUserTimezone(), "hour").toString()}
              renderer={rendererCountdown}
            />
          </Box>
        </Box>
      )}
    </Wrapper>
  );
}

export default QuizDetailStatus;
