import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/Auth";

function RequireAuth({ children }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }

    if (user?.is_signup) {
      navigate("/signup/profile-settings");
    }
  }, [user]);

  return children;
}

export default RequireAuth;
