import { useState, createContext, useContext } from "react";
import { Logout } from "../api";
import { v4 as uuid } from "uuid";
import { GlobalStateContext } from "./GlobalStateProvider";
import { getAuth, signOut } from "firebase/auth";

const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const { setBackgroundHomeType } = useContext(GlobalStateContext);
  const [userData, setUser] = useState(JSON.parse(localStorage.getItem("user")) || null);

  const handleLogout = async () => {
    try {
      const requestData = {
        device_id: uuid(),
      };
      const userLocal = localStorage.getItem("user");
      const { provider_type } =  userLocal ? JSON.parse(userLocal) : {provider_type: ""};

      if (provider_type === "kakao") {
        window.location.href = `https://kauth.kakao.com/oauth/logout?client_id=${process.env.REACT_APP_KAKAO_APP_KEY}&logout_redirect_uri=${process.env.REACT_APP_URL}/login`;
      }
      if (provider_type === "google") {
        const auth = getAuth();
        signOut(auth)
      }
      if (provider_type === "amazon") {
        amazon.Login.logout();
      }
      await Logout(requestData);
    } catch (error) {
      console.error("Logout failed", error);
    } finally {
      setUser(null);
      localStorage.removeItem("access_token");
      localStorage.removeItem("chat_access_token");
      localStorage.removeItem("user");
      localStorage.removeItem("push_token");
    }
  };

  const login = async (loginResponse) => {
    const { meta, user } = loginResponse;

    // headers["Authorization"] = `Bearer ${meta?.access_token?.token}`;
    setUser(user);
    localStorage.setItem("access_token", JSON.stringify(meta?.access_token?.token || ""));
    localStorage.setItem("chat_access_token", JSON.stringify(meta?.chat_access_token || ""));
    localStorage.setItem("user", JSON.stringify(user));
    // localStorage.setItem("backgroundHomeType", user?.profile?.background || 1);
    setBackgroundHomeType(user?.profile?.background || 1);
  };

  const setProfile = (user) => {
    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
    setBackgroundHomeType(user?.profile?.background || 1);
  }

  const logout = () => {
    handleLogout();
  };

  const dispatch = {
    user: userData,
    setProfile,
    login,
    logout,
  };

  return <AuthContext.Provider value={dispatch}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};
