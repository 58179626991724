import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Button from "@mui/material/Button";
import { COLORS, LINE_HEIGHT, RADIUS, TEXTSIZE } from "../../theme/constants";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function DrawerComponentList({ modalData, close }) {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ margin: 2 }}>
        <List
          className="list-drawer"
          sx={{
            background: COLORS.white,
            borderRadius: RADIUS.large,
            mb: 2,
            p: 0,
          }}
        >
          {modalData.map((item, index) => (
            <ListItem disablePadding key={index} sx={{ height: "56px" }}>
              <ListItemButton
                sx={{ display: "flex", justifyContent: "center" }}
                onClick={item.fn}
              >
                <Box sx={{ mr: 1 }}>{item.icon}</Box>
                <Typography
                  sx={{
                    fontSize: TEXTSIZE.medium,
                    lineHeight: LINE_HEIGHT.xLarge,
                    color:
                      item.text == "신고하기" ? COLORS.redError : COLORS.black1,
                    width: "fit-content",
                  }}
                >
                  {item.text}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Button
          variant="text"
          size="large"
          onClick={close}
          sx={{
            backgroundColor: COLORS.white,
            width: "100%",
            height: "56px",
            borderRadius: RADIUS.large,
            mb: 2,
            fontSize: TEXTSIZE.medium,
            lineHeight: LINE_HEIGHT.xLarge,
            color: COLORS.black1,
            "&:hover": {
              backgroundColor: COLORS.white,
            },
          }}
        >
          {/* 닫기 */}
          {t("button.close")}
        </Button>
      </Box>
    </>
  );
}

export default React.memo(DrawerComponentList);
