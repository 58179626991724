import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { COLORS, FONT_WEIGHT, LINE_HEIGHT, RADIUS } from "../../theme/constants";
import { textSmallMuiStyle, textMediumMuiStyle } from "../../utils/commonStyle";
import { ReactComponent as MoreVerticalIcon } from "@icons/more_vertical.svg";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";
import { useNavigate } from "react-router-dom";
import ImageAvatar from "@images/avatar.png";
import { ReactComponent as PurchaseBlackIcon } from "@icons/purchase_black.svg";

const Wrapper = styled.div``;

function ItemMemberConnected(props) {
  const navigate = useNavigate();
  const {
    user_id = "",
    nickname = "",
    profile_image_url = "",
    total_spend = 0,
    isMore = true,
  } = props;

  // Drawer state
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("");

  // Drawer begin
  const handleModal = () => {
    console.log("handleModal", handleModal);
  };

  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  // Drawer end
  const handleMore = () => {
    setDrawerType("connectedSponsors");
    handleDrawer(true);
  };

  const redirectToProfile = () => {
    navigate(`/user-profile/${nickname}`);
  };

  return (
    <>
      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame
            setOnDrawer={() => handleDrawer(false)}
            // handleModal={handleModal}
            type={drawerType}
            data={{
              user_id,
              nickname,
            }}
            // refetch={handleDrawerRefetch}
            // callBack={() => navigate(-1)}
          />
        </DrawerPortal>
      )}
      <Wrapper>
        <ListItem
          sx={{
            backgroundColor: COLORS.grey25,
            borderRadius: RADIUS.medium,
            pl: 1.5,
            py: 1.25,
            my: "1rem",
            "& .MuiListItemSecondaryAction-root": {
              right: "12px",
            },
          }}
          secondaryAction={
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.25 }}>
              <PurchaseBlackIcon />
              <Typography
                sx={{
                  ...textSmallMuiStyle,
                  fontSize: "14px",
                  fontWeight: "700",
                  lineHeight: "18px",
                  color: COLORS.black1,
                }}
                component='span'
              >
                {total_spend}
              </Typography>
              {isMore && (
                <MoreVerticalIcon
                  fill={COLORS.white}
                  onClick={handleMore}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Box>
          }
        >
          <ListItemAvatar
            sx={{ minWidth: "36px", mr: 1, cursor: "pointer" }}
            onClick={redirectToProfile}
          >
            <Avatar
              alt=''
              src={profile_image_url || ImageAvatar}
              sx={{
                width: "36px",
                height: "36px",
              }}
            />
          </ListItemAvatar>
          <ListItemText
            onClick={redirectToProfile}
            primary={nickname}
            sx={{
              "& .MuiListItemText-primary": {
                ...textMediumMuiStyle,
                fontWeight: FONT_WEIGHT.large,
                cursor: "pointer",
              },
            }}
            secondary={null}
          />
        </ListItem>
      </Wrapper>
    </>
  );
}

export default ItemMemberConnected;
