const theme = {
  mainColor: "#AB66FF",
  light: {
    mainColor: "#A347FF",
    mainColorLight: "#F6EFFF",
    notification_error: "#974F4F",
    notification_errorLight: "#EB4325",
    notification_success: "#3E5B44",
    notification_successLight: "#5BBE71",
    notification_alert: "#FFC700",
    textColor: "#333",
    subTextColor: "#777",
    bgColor: "#fff",
    bgLightColor: "#F3F3F3",
    bgButtonLightColor: "#FFFFFF",
    color5toF3: "#555",
    color7toD: "#777",
    color9to7: "#999",
    color9toB: "#999",
    colorDto5: "#ddd",
    colorD9to3: "#d9d9d9",
    colorF3to3: "#f3f3f3",
    color1CtoF: "#1c1c1c",
    color1Cto7: "#1c1c1c",
  },
  dark: {
    mainColor: "#AB66FF",
    mainColorLight: "#313131",
    notification_error: "#974F4F",
    notification_errorLight: "#FF775F",
    notification_success: "#3E5B44",
    notification_successLight: "#5BBE71",
    notification_alert: "#FFC700",
    textColor: "#fff",
    subTextColor: "#777",
    bgColor: "#1c1c1c",
    bgLightColor: "#333",
    bgButtonLightColor: "#FFFFFF",
    color5tof3: "#f3f3f3",
    color7toD: "#ddd",
    color9to7: "#777",
    color9toB: "#bbb",
    colorDto5: "#555",
    colorD9to3: "#333",
    colorF3to3: "#333",
    color1CtoF: "#fff",
    color1Cto7: "#777",
  },
};

export default theme;
