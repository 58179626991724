import { Box, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "@icons/close.svg";
import { ReactComponent as PurchasePurpleIcon } from "@icons/purchase_purple.svg";
import { ReactComponent as RechargeIcon1 } from "@icons/pic_recharge_1.svg";
import { ReactComponent as RechargeIcon2 } from "@icons/pic_recharge_2.svg";
import { ReactComponent as RechargeIcon3 } from "@icons/pic_recharge_3.svg";

import { COLORS, FONT_WEIGHT, LINE_HEIGHT, RADIUS, TEXTSIZE } from "../../theme/constants";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { getProducts, getUserBalance, postStorePayment, unlockOrDonatePost } from "../../api";
import { toast } from "react-toastify";
import {
  buttonMuiStyle,
  inputAdornmentMuiStyle,
  inputMuiStyle,
  textFieldMuiStyle,
  textMediumMuiStyle,
} from "../../utils/commonStyle";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import ModalComponent from "../../components/modal/ModalComponent";
import { NumericFormatCustom } from "../../components/numberic/NumbericFormatCustom";

const Wrapper = styled.div`
  border-radius: ${RADIUS.large} ${RADIUS.large} 0 0;
  padding: 1.25rem;
  background: ${COLORS.white};

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .button-close {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .text-area {
    margin-top: 32px;
    text-align: center;
    line-height: 20.4px;
  }

  .text-title {
    font-size: ${TEXTSIZE.large};
    font-size: 700;
    line-height: 27px;
    margin-bottom: 7px;
  }

  .text-sub,
  .button-charge {
    font-size: ${TEXTSIZE.small};
    color: ${COLORS.gray_7};
    margin: 4px auto;
  }

  .button-charge {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .list-buttons {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
    height: 100%;
  }

  .radio-wrap {
    .MuiFormControlLabel-root {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;

      .MuiFormControlLabel-label {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: ${COLORS.grey700};
      }

      &:has(.Mui-checked),
      &:hover {
        background-color: ${COLORS.primary4};
        .MuiFormControlLabel-label {
          color: ${COLORS.white};
        }
      }
    }

    .MuiButtonBase-root {
      padding: 0;
      margin-bottom: 0.25rem;
    }
  }

  .error-message {
    color: var(--notification_errorLight);
    font-size: ${TEXTSIZE.small};
    margin: 8px 0;
    height: 20px;
  }

  .button-submit {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--mainColor);
    color: ${COLORS.white};

    &:hover {
      text-decoration: underline;
      background-color: var(--mainColor);
    }
  }
`;

const rechargeList = [
  {
    value: 10,
    cost: 1500,
  },
  {
    value: 100,
    cost: 15000,
  },
  {
    value: 650,
    cost: 97500,
  },
];

function RadioButton(props) {
  return <Radio disableRipple color='default' checkedIcon={<></>} icon={<></>} {...props} />;
}

function RadioButtonRecharge(props) {
  return (
    <Radio
      disableRipple
      color='default'
      sx={{
        color: COLORS.grey200,
        "&.Mui-checked": {
          color: COLORS.primary4,
        },
        "& .MuiSvgIcon-root": {
          fontSize: 24,
        },
      }}
      {...props}
    />
  );
}

function LabelRadioButtonRecharge({ icon = <RechargeIcon1 />, value, price }) {
  return (
    <Box>
      {icon}
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "700",
          lineHeight: "20px",
          textAlign: "center",
        }}
      >
        {value}
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "20px",
          textAlign: "center",
          mt: 1.5,
        }}
      >
        {new Intl.NumberFormat().format(price?.KRW?.price)}
        <Typography
          variant='span'
          sx={{
            fontWeight: FONT_WEIGHT.large,
          }}
        >
          {price?.KRW?.currency_code}
        </Typography>
      </Typography>
    </Box>
  );
}

function DrawerPurchase({ close, data, refetch }) {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const channel = new BroadcastChannel("payment-connected");
  const [picValue, setPicValue] = useState(0);
  const [rechargeValue, setRechargeValue] = useState(0);
  // const [isCustom, setIsCustom] = useState(true);
  const [errorMessages, setErrorMessages] = useState("");
  const [products, setProducts] = useState([]);

  const [userBalance, setUserBalance] = useState({
    charge: 0,
    revenue: 0,
    bonus: 0,
    event: 0,
    pic_in_month: 0,
    balance: 0,
    picSuggestion: [],
  });

  const [modalData, setModalData] = useState({
    open: false,
    type: "",
    btnOKText: "",
    btnCancelText: "",
    title: "",
    subTitle: "",
    content: "",
  });

  //Recharge Begin
  const onRecharge = () => {
    setModalData({
      open: true,
      type: "recharge",
      btnOKText: "구매하기",
      btnCancelText: "",
      title: (
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "22px",
            mb: 0.5,
          }}
        >
          확인
        </Typography>
      ),
      subTitle: (
        <Typography
          variant='span'
          sx={{
            whiteSpace: "pre-line",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            color: "#000000"
          }}
        >{`보유하고 있는 PIC이 없습니다.\n구매하시겠습니까?`}</Typography>
      ),
      content: (
        <Box>
          <RadioGroup
            onChange={handleRechargeChange}
            sx={{
              flexDirection: "row",
              justifyContent: "center",
              rowGap: "4px",
              columnGap: "4px",
              ".MuiFormControlLabel-root": {
                flexDirection: "column",
                pb: 2,
                ".MuiButtonBase-root": {
                  marginLeft: "auto",
                },
                "&:has(.Mui-checked),&:hover": {
                  border: `1px solid ${COLORS.primary4}`,
                },
              },
            }}
          >
            {products?.map(
              (product, index) =>
                [0, 2, 3].includes(index) && (
                  <FormControlLabel
                    key={product?.pic}
                    label={
                      <LabelRadioButtonRecharge
                        icon={getRechargeIcon(index)}
                        value={product?.pic}
                        price={product?.price}
                      />
                    }
                    value={product?.pic}
                    control={<RadioButtonRecharge />}
                    sx={{
                      border: "1px solid #D9D9D9",
                      borderRadius: "8px",
                      minHeight: "148px",
                      minWidth: "88px",
                      margin: 0,
                      backgroundColor: "#F2F4F7",
                    }}
                  />
                )
            )}
          </RadioGroup>
        </Box>
      ),
    });
  };

  const handleRechargeChange = (event) => {
    setRechargeValue(event.target.value);
  };

  const init_orderid = () => {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();
    let time = today.getTime();

    if (parseInt(month) < 10) {
      month = "0" + month;
    }

    if (parseInt(date) < 10) {
      date = "0" + date;
    }

    return "Danal_" + year + "" + month + "" + date + "" + time;
  };

  const handleRecharge = () => {
    const product = products.find((item) => item?.pic == rechargeValue);
    console.log("product", product);
    if (!product) return;
    try {
      const orderid = init_orderid();
      const obj = {
        amount: product?.price?.KRW?.price,
        itemname: product?.name,
        useragent: "WP",
        orderid,
        userid: user?.id,
        useremail: user?.email,
        username: user?.user_profile?.nickname,
        return_url: `${window.location.origin}/payment-connecting?orderid=${orderid}&status=true`,
        cancel_url: `${window.location.origin}/payment-connecting?orderid=${orderid}`,
        bypass_value: `user_product_id=${product?.code}`,
      };
      const data = new FormData();
      Object.entries(obj)?.forEach((item) => {
        data.append(item[0], item[1]);
      });
      const axiosPayment = axios.create();
      axiosPayment
        .post(process.env.REACT_APP_PAYMENT_PHP_API, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const value = res?.data?.data;
          postStorePayment({
            user_id: user?.id,
            product_code: product?.code,
            orderid,
            amount: product?.price?.KRW?.price,
          })
            .then((res) => {
              if (res?.status === 201) {
                const win = window.open("about:blank");
                win?.document
                  ?.write(`<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
                  <html xmlns="http://www.w3.org/1999/xhtml" >
                  <head>
                  <meta http-equiv="Content-Type" content="text/html; charset=euc-kr">
                  <link href="./css/style.css" type="text/css" rel="stylesheet"  media="all" />
                  <title>*** �ٳ� �ſ�ī�� ���� ***</title>
                  </head>
                  <body>
                      <form name="form" ACTION="https://checkout.teledit.com/creditcard_std/web" METHOD="POST" >
                      <input TYPE="HIDDEN" NAME="STARTPARAMS"  	VALUE="${value}">
                      </form>
                      <script>
                          document.form.submit();
                      </script>
                  </body>`);
                setProduct();
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleGetProduct = () => {
    try {
      getProducts().then((res) => {
        if (res) {
          setProducts(res?.list);
        }
      });
    } catch (error) {
      const err = handleError(error, logout, navigate);
    }
  };

  const handleConnectPayment = async (onMessageData) => {
    channel.close();
    setModalData({
      ...modalData,
      open: true,
      type: "payment_status",
      btnOKText: "확인",
      btnCancelText: "",
      title: onMessageData?.status == "true" ? "구매 완료" : "구매 실패",
      subTitle: "",
      content: (
        <Typography sx={{ textAlign: "center" }}>
          {onMessageData?.status == "true"
            ? "구매가 정상적으로 완료되었습니다!"
            : "구매에 오류가 발생했습니다. 다시 시도해 주세요!"}
        </Typography>
      ),
    });
  };
  channel.onmessage = (msg) => {
    const onMessageData = {
      status: msg.data,
    };
    if (msg.data == "true") {
      handleGetMyBalance();
    }
    handleConnectPayment(onMessageData);
  };

  const getRechargeIcon = (key) => {
    switch (key) {
      case 0:
        return <RechargeIcon1 />;
      case 2:
        return <RechargeIcon2 />;
      case 3:
        return <RechargeIcon3 />;
      default:
        break;
    }
  };
  //Recharge End

  //begin modal
  const handleOk = () => {
    setModalData({ ...modalData, open: false });

    switch (modalData.type) {
      case "recharge":
        handleRecharge();
        break;

      default:
        break;
    }
  };

  const isDisableBtnOk = () => {
    let result = false;
    if (modalData.type === "recharge" && !rechargeValue) result = true;
    return result;
  };

  //handle change
  const onChangeValue = (e) => {
    setPicValue(e.target.value);
    setErrorMessages("");
  };

  const handleRadioChange = (event) => {
    setErrorMessages("");
    setPicValue(event.target.value);
  };

  // const onClickCustom = () => {
  //   setIsCustom(true);
  //   setPicValue(0);
  //   setErrorMessages("");
  // };

  const handleSubmit = async () => {
    try {
      if (userBalance?.balance < picValue) {
        setErrorMessages("PIC이 부족해요!");
        return;
      }

      await unlockOrDonatePost(data.id, {
        pic: +picValue,
        type: "donate",
      });
      refetch();
      close();
      toast(`${picValue}PIC을 보냈습니다.`);
    } catch (error) {
      const err = handleError(error, logout, navigate);
      if (typeof err === "object" && err !== null) {
        setErrorMessages(err);
      } else {
        console.log("Donate Post", err);
      }
    }
  };

  const handleGetMyBalance = async () => {
    try {
      const res = await getUserBalance();
      if (res) {
        setUserBalance(res);
      }
    } catch (error) {
      handleError(error, logout);
    }
  };

  useEffect(() => {
    handleGetMyBalance();
    handleGetProduct();
  }, []);

  return (
    <>
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleOk={handleOk}
        handleClose={() => {
          setModalData({ open: false }), setRechargeValue(0);
        }}
        isCloseIcon
        btnOKProps={{
          disabled: isDisableBtnOk(),
        }}
      />
      <Wrapper>
        <IconButton
          onClick={close}
          sx={{
            width: "fit-content",
            marginLeft: "auto",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box sx={{ mb: 1.5 }}>
          <Typography
            sx={{
              fontSize: TEXTSIZE.large,
              fontWeight: FONT_WEIGHT.xxLarge,
              lineHeight: LINE_HEIGHT.xxxxLarge,
              color: COLORS.black1,
              mb: 1.5,
            }}
          >
            {/* {data?.user_profile?.nickname} 님에게 PIC 보내기 */}
            후원하기
          </Typography>
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey600,
              fontSize: TEXTSIZE.small,
              mb: 0.5,
            }}
          >
            현재 보유하고 있는 PIC : {userBalance.balance}
          </Typography>
          <Typography
            sx={{
              lineHeight: LINE_HEIGHT.xLarge,
              fontWeight: FONT_WEIGHT.large,
              color: COLORS.primary4,
              fontSize: TEXTSIZE.small,
              cursor: "pointer",
            }}
            onClick={onRecharge}
          >
            충전하기
          </Typography>
        </Box>

        <TextField
          placeholder='PIC 개수 입력'
          sx={{
            ...textFieldMuiStyle,
            "& .MuiInputBase-root": {
              height: "48px",
              paddingLeft: 0.75,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              backgroundColor: COLORS.grey50,
              border: "none",
              borderRadius: "8px",
            },
          }}
          inputProps={{
            sx: {
              ...inputMuiStyle,
              fontWeight: TEXTSIZE.large,
              fontWeight: FONT_WEIGHT.xxLarge,
              paddingLeft: 0,
            },
            min: 0,
          }}
          value={picValue}
          onChange={onChangeValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position='end' sx={{ ...inputAdornmentMuiStyle, mr: 1 }}>
                <PurchasePurpleIcon />
              </InputAdornment>
            ),
            inputComponent: NumericFormatCustom,
          }}
        />
        <Typography
          sx={{
            ...textMediumMuiStyle,
            fontSize: TEXTSIZE.small,
            color: COLORS.redError,
            mt: 0.5,
          }}
        >
          {errorMessages}
        </Typography>
        {userBalance.picSuggestion.length > 0 && (
          <div className='list-buttons'>
            <RadioGroup
              value={picValue}
              className='radio-wrap'
              onChange={handleRadioChange}
              aria-labelledby='demo-customized-radios'
              name='customized-radios'
            >
              {userBalance.picSuggestion.map((item) => (
                <FormControlLabel
                  key={item}
                  label={item}
                  value={item}
                  control={<RadioButton />}
                  // sx={{ m: 10 }}
                  sx={{
                    border: "1px solid #D9D9D9",
                    borderRadius: "8px",
                    minHeight: "40px",
                    minWidth: "60px",
                  }}
                />
              ))}
            </RadioGroup>
          </div>
        )}

        <Button
          sx={{
            ...buttonMuiStyle,
            mt: 2.5,
            "&:hover": {
              backgroundColor: COLORS.primary4,
              color: COLORS.white,
              path: {
                fill: COLORS.white,
              },
              circle: {
                stroke: COLORS.white,
              },
            },
          }}
          disabled={!!errorMessages || picValue == 0}
          variant='contained'
          size='large'
          onClick={handleSubmit}
        >
          <PurchasePurpleIcon />
          <Typography>후원하기</Typography>
        </Button>
      </Wrapper>
    </>
  );
}

export default React.memo(DrawerPurchase);
