import { useMutation } from "@tanstack/react-query";
import { createReport } from "../api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { handleError } from "../utils/commonFunction";
import { toast } from "react-toastify";

function useCreateReport() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleReport = async (requestData) => {
    try {
      await createReport(requestData);
      toast("신고가 완료되었습니다.");
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const { isLoading, isError, error, mutate } = useMutation({
    mutationFn: handleReport,
  });

  return {
    isLoading,
    handleReport,
  };
}

export default useCreateReport;
