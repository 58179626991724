import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { COLORS, RADIUS, TEXTSIZE } from "../../theme/constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as ShareIcon } from "../../assets/svgs/share.svg";
import { ReactComponent as ExploreIcon } from "../../assets/svgs/explore.svg";
import { ReactComponent as VisibilityOffIcon } from "../../assets/svgs/visibility_off.svg";
import { ReactComponent as ReportIcon } from "../../assets/svgs/report.svg";
import { ReactComponent as EditIcon } from "../../assets/svgs/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svgs/delete.svg";
import { ReactComponent as BlockIcon } from "../../assets/svgs/block.svg";
import DrawerListComponent from "./DrawerListComponent";

const ListStyleWrap = styled.div`
  .list-drawer {
    background: ${COLORS.white};
    margin: 0 16px;
    padding: 0;
    border-radius: ${RADIUS.medium};
    overflow: hidden;

    &__button {
      width: calc(100% - 32px);
      margin: 12px 16px 20px;
      background: ${COLORS.white};
      border: 0;
      font-size: ${TEXTSIZE.medium};
      color: ${COLORS.black};
      border-radius: ${RADIUS.medium};
    }

    .warning {
      color: var(--notification_errorLight);
    }
  }

  .MuiButtonBase-root {
    padding: 0;
    height: 56px;
    background-color: var(--bgColor);
    color: var(--textColor);

    &:hover {
      background-color: var(--bgColor);
      text-decoration: underline;
    }

    * {
      height: 100%;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${TEXTSIZE.medium};

      &.warning {
        color: var(--notification_errorLight);
      }
    }
  }
`;

function OtherUser({ close, data, handleModal }) {
  const modalData = [
    // {
    //   text: "게시물 공유",
    //   icon: <ShareIcon />,
    //   fn: () => {
    //     handleModal("share");
    //     close();
    //   },
    // },
    // {
    //   text: data?.isConnecting ? "커넥트 취소" : "커넥트",
    //   icon: (
    //     <ExploreIcon
    //       style={{ stroke: COLORS.black1, fill: COLORS.black1 }}
    //     />
    //   ),
    //   fn: () => {
    //     // if (isMine) return false;
    //     handleModal("connect");
    //     close();
    //   },
    // },
    {
      text: "차단하기",
      icon: <BlockIcon />,
      fn: () => {
        handleModal("block");
        close();
      },
    },
    {
      text: "신고하기",
      icon: <ReportIcon />,
      fn: () => {
        handleModal("report");
        close();
      },
    },
  ];

  return (
    <DrawerListComponent close={close} modalData={modalData} />
  );
}

export default React.memo(OtherUser);