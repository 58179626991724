import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  COLORS,
  FONT_WEIGHT,
  HEIGHT,
  ICON_SIZE,
  LINE_HEIGHT,
  RADIUS,
  TEXTSIZE,
  WIDTH,
} from "@theme/constants";
import TextField from "@mui/material/TextField";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";
import {
  blockUser,
  createReport,
  deleteComment,
  getComments,
  setComment,
} from "@api";
import { Link, useNavigate, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import NoData from "@components/NoData";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { ReactComponent as Message } from "@icons/message.svg";
import { ReactComponent as MoreIcon } from "@icons/more_vertical.svg";
import { relativeTimeKorean } from "@utils/dateTimeFormat";
import InfiniteScroll from "react-infinite-scroll-component";
import ModalComponent from "@components/modal/ModalComponent";
import { toast } from "react-toastify";
import TruncateText from "@components/newComponents/truncate/TruncateText";
import Report from "@components/newComponents/report";
import { handleError, isMine } from "@utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { useWindowDimensions } from "@hooks";
import { useMediaQuery } from "react-responsive";
import {
  inputAdornmentMuiStyle,
  inputMuiStyle,
  textFieldMuiStyle,
  textMediumMuiStyle,
  truncateStyle,
} from "@utils/commonStyle";
import dayjs from "dayjs";
import ImageAvatar from "../../assets/images/avatar.png";

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 130px);
`;

function Comment() {
  const { pageName } = useParams();
  const { logout, user } = useAuth();
  const analytics = getAnalytics();
  const { height, width } = useWindowDimensions();
  // const user = JSON.parse(window.localStorage.getItem("user"));
  const { id: post_id } = useParams();
  const [value, setValue] = useState("");
  const [drawerData, setDrawerData] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  // new state
  let enterRef = useRef(null);
  enterRef.current = dayjs();
  const [commentsData, setCommentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reasons, setReasons] = useState([]);
  // const isSubmitting = useRef(null);
  const [modalData, setModalData] = useState({
    open: false,
    type: "",
    btnOKText: "",
    btnCancelText: "",
    title: "",
    subTitle: "",
    content: "",
  });

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const removeCommentInLocal = () => {
    const tmpComments = [...commentsData];
    const found = tmpComments.filter((element) => element.id !== drawerData.id);
    setCommentData(found);
  };

  const fetchNewOneData = async (newRequestData) => {
    try {
      // setIsLoading(true);
      const requestData = {
        offset: commentsData.length,
        limit: 20,
        ...newRequestData,
      };
      const res = await getComments(post_id, requestData);
      if (res?.list) {
        const newData = res.list;
        setCommentData((prevState) => [...newData, ...prevState]);
      }
      // setIsLoading(false);
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  // const fetchMoreOneData = async (newRequestData) => {
  //   try {
  //     // setIsLoading(true);
  //     const requestData = {
  //       offset: commentsData.length,
  //       limit: 16,
  //       ...newRequestData,
  //     };
  //     const res = await getComments(post_id, requestData);
  //     if (res?.list) {
  //       const newData = res.list;
  //       setCommentData((prevState) => [...prevState, ...newData]);
  //     }
  //     // setIsLoading(false);
  //   } catch (error) {
  //     handleError(error, logout);
  //   }
  // };

  const fetchMoreData = async (newRequestData) => {
    try {
      // setIsLoading(true);
      const requestData = {
        offset: commentsData.length,
        limit: 16,
        ...newRequestData,
      };
      const res = await getComments(post_id, requestData);
      if (res?.list) {
        const newData = res.list;
        setCommentData((prevState) => [...prevState, ...newData]);
      }
      // setIsLoading(false);
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const handleDrawer = (value, data) => {
    value && setDrawerData(data);
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  const handleReportComment = async () => {
    try {
      const requestReason = reasons
        .filter((value) => value.checked)
        .map((item) => {
          return { reason: item.reason, type: item.type };
        });

      const requestData = {
        target: "comment",
        target_id: drawerData.id,
        reasons: requestReason,
      };

      await createReport(requestData);
      removeCommentInLocal();
      toast("신고가 완료되었습니다.");
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const handleBlockUser = async () => {
    try {
      const requestData = {
        target_user_id: drawerData.user_id,
        reason: "차단사유",
      };
      await blockUser(requestData);
      // removeCommentInLocal()
      const tmpComments = [...commentsData];
      const found = tmpComments.filter(
        (element) => element.user_id !== drawerData.user_id
      );
      setCommentData(found);
      toast("차단이 완료되었습니다.");
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const handleDeleteComment = async () => {
    // console.log("drawerData.id", drawerData);
    try {
      await deleteComment(drawerData.id);
      const tmpComments = [...commentsData];
      const found = tmpComments.filter(
        (element) => element.id !== drawerData.id
      );
      setCommentData(found);
      fetchMoreData({
        offset: found.length,
        limit: 1,
      });
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const handleModal = (key) => {
    switch (key) {
      case "report":
        setModalData({
          ...modalData,
          type: "report",
          open: true,
          btnOKText: "신고",
          btnCancelText: "취소",
          title: "무엇을 신고하려고 하시나요?",
          subTitle: "댓글 신고 시 자동으로 계정 차단이 진행됩니다.",
          content: <Report onChangeReason={setReasons} />,
        });
        break;
      case "block":
        handleBlockUser();
        break;
      case "delete":
        setModalData({
          ...modalData,
          type: "delete",
          open: true,
          btnOKText: "확인",
          btnCancelText: "취소",
          title: "해당 댓글을 삭제하시겠어요?",
          subTitle: "해당 댓글을 한 번 삭제하시면 되돌릴 수 없어요.",
          content: "",
        });
        break;

      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    // if (!isSubmitting.current)
    setValue(value);
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  const onSubmit = () => {
    if (!value) return;
    if (dayjs().diff(enterRef.current) > 180) {
      handleSubmit();
    }
    enterRef.current = dayjs();
  };

  const handleSubmit = async () => {
    // console.log("handleSubmit", value);
    try {
      if (!value) return;
      const data = {
        comment: value,
      };
      const res = await setComment(data, post_id);
      if (res && res.data.code === 200) {
        // isSubmitting.current = false;
        setValue("");
        fetchNewOneData({
          offset: 0,
          limit: 1,
        });
      }
    } catch (error) {
      // isSubmitting.current = false;
      handleError(error, logout);
    }
  };

  const handleFetchMoreData = () => {
    if (commentsData.length === 0) {
      fetchMoreData({ offset: 0 });
    } else {
      fetchMoreData();
    }
  };

  const redirectToProfile = (ID, name) => {
    let result = "/profile";
    if (!isMine(ID)) {
      result = `/user-profile/${name}`;
    }
    return result;
  };

  //modal action
  const handleOk = () => {
    setModalData({ ...modalData, open: false });

    switch (modalData.type) {
      case "delete":
        handleDeleteComment();
        break;
      case "report":
        handleReportComment();
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
  };

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "comment",
      firebase_screen_class: "comment",
    });
    handleFetchMoreData();
  }, []);

  const isDisableBtnOk = () => {
    let result = false;
    if (
      modalData.type === "report" &&
      reasons
        .filter((value) => value.checked)
        .map((item) => {
          return { reason: item.reason, type: item.type };
        }).length === 0
    )
      result = true;
    return result;
  };

  return (
    <div className="comment">
      {isLoading ? (
        <Box
          sx={{ width: "100%", height: "calc(100% - 56px)", overflow: "auto" }}
        >
          <Backdrop
            sx={{ color: COLORS.primary4, backgroundColor: "transparent" }}
            open={isLoading}
            onClick={() => setIsLoading(false)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      ) : commentsData?.length > 0 ? (
        <Box
          sx={{
            width: "100%",
            height: "calc(100vh - 130px);",
            overflow: "auto",
            mt: 2.25,
          }}
        >
          <InfiniteScroll
            dataLength={commentsData.length}
            next={handleFetchMoreData}
            hasMore={true}
            // scrollableTarget="scrollableContent"
          >
            {commentsData?.map((item) => (
              <Grid
                container
                spacing={1}
                sx={{
                  fontSize: TEXTSIZE.medium,
                  // py: 1,
                  mb: 3,
                }}
                key={item.id}
              >
                <Grid item xs={"auto"}>
                  <Link
                    to={redirectToProfile(
                      item?.user_profile?.user_id,
                      item?.user_profile?.nickname
                    )}
                  >
                    <Avatar
                      sx={{
                        height: ICON_SIZE.small,
                        width: ICON_SIZE.small,
                      }}
                      // alt={item?.user_profile?.nickname}
                      src={item?.user_profile?.profile_image_url || ImageAvatar}
                    />
                  </Link>
                </Grid>
                <Grid item xs={11}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: TEXTSIZE.medium,
                      mb: 1,
                    }}
                  >
                    <Link
                      to={redirectToProfile(
                        item?.user_profile?.user_id,
                        item?.user_profile?.nickname
                      )}
                      style={{
                        ...textMediumMuiStyle,
                        fontWeight: FONT_WEIGHT.large,
                        height: LINE_HEIGHT.xxxLarge,
                        maxWidth: "70%",
                      }}
                    >
                      <Typography sx={truncateStyle}>
                        {item?.user_profile?.nickname}
                      </Typography>
                    </Link>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          ...textMediumMuiStyle,
                          fontWeight: FONT_WEIGHT.large,
                          color: COLORS.grey500,
                          lineHeight: LINE_HEIGHT.xxxLarge,
                        }}
                      >
                        {relativeTimeKorean(item.commented_at)}
                      </Typography>
                      <MoreIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDrawer(true, item)}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ mr: 2 }}>
                    <TruncateText content={item.comment} lines={2} />
                  </Box>
                </Grid>
              </Grid>
            ))}
          </InfiniteScroll>
        </Box>
      ) : (
        <HeightWhenEmpty>
          <NoData text="댓글이 없습니다" />
        </HeightWhenEmpty>
      )}

      <Box
        sx={{
          position: "sticky",
          bottom: 10,
          // width: `${isPc ? width - 40 : width - 40}px`,
        }}
      >
        <TextField
          name="message"
          type="text"
          value={value}
          onChange={handleChange}
          onKeyUp={handleOnKeyDown}
          placeholder="댓글 달기"
          sx={textFieldMuiStyle}
          inputProps={{
            style: inputMuiStyle,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={inputAdornmentMuiStyle}
                onClick={onSubmit}
              >
                <Message style={{ stroke: COLORS.grey600 }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame
            setOnDrawer={() => handleDrawer(false)}
            type="comment"
            handleModal={handleModal}
            isMinePost={pageName === user?.profile?.nickname}
            data={drawerData}
          />
        </DrawerPortal>
      )}

      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => handleClose()}
        handleOk={() => handleOk()}
        handleCancel={() => handleCancel()}
        btnOKProps={{
          disabled: isDisableBtnOk(),
        }}
        btnCancelProps={{}}
      />
    </div>
  );
}

export default React.memo(Comment);
