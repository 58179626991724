import {
  Box,
  Button,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { COLORS, HEIGHT, TEXTSIZE } from "../../theme/constants";
import styled from "styled-components";
import { updateProfile } from "../../api";
import { useNavigate } from "react-router-dom";
import clearImage from "../../assets/images/clear.svg";
import { handleError } from "../../utils/commonFunction";
import {
  labelMediumMuiStyle,
  textFieldMuiStyle,
  inputMuiStyle,
  inputAdornmentMuiStyle,
  buttonLargeMuiStyle,
} from "../../utils/commonStyle";
import { useAuth } from "../../context/Auth";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  position: relative;
  height: calc(
    calc(var(--vh, 1vh) * 100) - ${HEIGHT.header_mo} - ${HEIGHT.navigation}
  );
  width: 100%;
`;

const LIMIT_LENGTH = 16;

function SetPassword(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const [requestData, setRequestData] = useState({
    password: "",
    newPassword: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    password: "",
    newPassword: "",
  });
  // const [countPassword, setCountPassword] = useState(0);
  // const [countNewPassword, setCountNewPassword] = useState(0);
  // const [isDisabled, setIsDisabled] = useState(true);

  const isValidPassword = (password) => {
    const passwordRegex = new RegExp(
      /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]{8,16}$/
    );
    return passwordRegex.test(password);
  };

  const handleChange = (key, value) => {
    // setCountPassword(value.length);
    // setCountPassword(value.length);
    const messageErr = "";
    // if (
    //   (key === "password" && requestData.newPassword != value) ||
    //   (key === "newPassword" && requestData.password != value)
    // ) {
    //   setErrorMessages({
    //     ...errorMessages,
    //     newPassword: "비밀번호가 일치하지 않습니다.",
    //   });
    // }

    setRequestData({ ...requestData, [key]: value });
  };

  const handleUpdateProfile = async () => {
    try {
      const res = await updateProfile(requestData);
      navigate("/");
    } catch (error) {
      handleError(error, logout);
      console.log("handleUpdateProfile error");
    }
  };

  const handleUpdatePassword = () => {
    navigate("/signup/profile-settings", {
      state: { password: requestData.password },
    });
  };

  return (
    <>
      <Wrapper>
        <Box>
          <Box sx={{ pt: 7 }}>
            <InputLabel sx={labelMediumMuiStyle}>비밀번호</InputLabel>
            <TextField
              name="password"
              type="password"
              value={requestData.password}
              onChange={(event) => handleChange("password", event.target.value)}
              placeholder={t("please_enter_a_password")}
              sx={textFieldMuiStyle}
              inputProps={{
                sx: inputMuiStyle,
                maxLength: LIMIT_LENGTH,
              }}
              InputProps={{
                endAdornment: requestData.password.length > 0 && (
                  <InputAdornment
                    position="end"
                    sx={inputAdornmentMuiStyle}
                    onClick={() => handleChange("password", "")}
                  >
                    <img src={clearImage} />
                  </InputAdornment>
                ),
              }}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
            >
              <Typography
                sx={{
                  color:
                    requestData.password &&
                    !isValidPassword(requestData.password)
                      ? COLORS.redError
                      : COLORS.grey400,
                  fontSize: TEXTSIZE.small,
                }}
              >
                비밀번호 8~16자 영문, 숫자 조합
              </Typography>
              <Typography
                sx={{
                  color: COLORS.grey400,
                  fontSize: TEXTSIZE.small,
                }}
              >{`${requestData.password.length}/${LIMIT_LENGTH}`}</Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <InputLabel sx={labelMediumMuiStyle}>비밀번호 확인</InputLabel>
            <TextField
              name="newPassword"
              type="password"
              value={requestData.newPassword}
              onChange={(event) =>
                handleChange("newPassword", event.target.value)
              }
              placeholder="비밀번호를 한번 더 입력해주세요."
              sx={textFieldMuiStyle}
              inputProps={{
                sx: inputMuiStyle,
                maxLength: LIMIT_LENGTH,
              }}
              InputProps={{
                endAdornment: requestData.newPassword.length > 0 && (
                  <InputAdornment
                    position="end"
                    sx={inputAdornmentMuiStyle}
                    onClick={() => handleChange("newPassword", "")}
                  >
                    <img src={clearImage} />
                  </InputAdornment>
                ),
              }}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
            >
              <Typography
                sx={{
                  color: COLORS.redError,
                  fontSize: TEXTSIZE.small,
                  visibility:
                    requestData.newPassword &&
                    requestData.newPassword != requestData.password
                      ? "visible"
                      : "hidden",
                }}
              >
                {/* 비밀번호가 일치하지 않습니다. */}
                {t("passwords_do_not_match")}
              </Typography>
              <Typography
                sx={{
                  color: COLORS.grey400,
                  fontSize: TEXTSIZE.small,
                }}
              >{`${requestData.newPassword.length}/${LIMIT_LENGTH}`}</Typography>
            </Box>
          </Box>
        </Box>
        <Button
          sx={{
            ...buttonLargeMuiStyle,
            position: "absolute",
            bottom: "0px",
          }}
          disabled={
            !!!requestData.password ||
            !!!requestData.newPassword ||
            requestData.newPassword != requestData.password ||
            !isValidPassword(requestData.password)
          }
          variant="contained"
          size="large"
          className="submit"
          onClick={handleUpdatePassword}
        >
          {/* 다음 */}
          {t("button.next")}
        </Button>
      </Wrapper>
    </>
  );
}

export default SetPassword;
