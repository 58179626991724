import React, { useEffect, useState } from "react";
import { getConfigs, getReportReason } from "../../../api";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { COLORS, LINE_HEIGHT, TEXTSIZE } from "../../../theme/constants";
import checkBoxImage from "../../../assets/images/checkBox.svg";
import checkedBoxImage from "../../../assets/images/checkedBox.svg";
import { handleError } from "../../../utils/commonFunction";
import { useAuth } from "../../../context/Auth";

function Report(props) {
  const { logout } = useAuth();
  const { onChangeReason } = props;
  const [reasons, setReasons] = useState([]);

  const handleChecked = (type) => {
    const tmpReason = [...reasons];
    const index = tmpReason.findIndex((el) => el.type === type);
    tmpReason[index].checked = !tmpReason[index].checked;
    setReasons(tmpReason);
  };

  const handleGetReasonReport = async () => {
    try {
      // const requestData = {
      //   type: "report"
      // }
      const res = await getReportReason();
      if (res) {
        console.log("res", res)
        setReasons(res)
        // mappingDataResponse(res);
      }
    } catch (error) {
      handleError(error, logout);
    }
  };

  // const mappingDataResponse = (response) => {
  //   const responseTmp = { ...response };
  //   const types = Object.keys(response);
  //   const reasonsTmp = [];
  //   types.forEach((element) => {
  //     const reason = {
  //       type: element,
  //       reason: responseTmp[element],
  //     };
  //     reasonsTmp.push(reason)
  //   });
  //   setReasons(reasonsTmp);
  // };

  
  useEffect(() => {
    onChangeReason(reasons);
    // console.log("reasons",reasons)
  }, [reasons]);
  
  useEffect(() => {
    handleGetReasonReport();
  }, []);
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      className="terms"
    >
      {reasons?.map((item, index) => (
        <FormControlLabel
          key={item.type}
          label={
            <Typography
              sx={{
                color: COLORS.grey900,
                fontSize: TEXTSIZE.medium,
                lineHeight: LINE_HEIGHT.xxxLarge,
              }}
            >
              {item.reason}
            </Typography>
          }
          control={
            <Checkbox
              data-index={index}
              //   checked={item?.status}
              onChange={() => handleChecked(item.type)}
              icon={<img src={checkBoxImage} />}
              checkedIcon={
                <img
                  src={checkedBoxImage}
                  // style={checkboxStyle}
                />
              }
            />
          }
        />
      ))}
    </Box>
  );
}

export default Report;
