import arrowRightImage from "@images/arrowRight.svg";
import { Box, Modal, Typography } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 40px)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "16px",
  padding: "24px 20px",
  textAlign: "center",
};
const Dot = () => {
  return (
    <div
      style={{
        width: "4px",
        minWidth: "4px",
        height: "4px",
        borderRadius: "4px",
        backgroundColor: "#3B3B3B",
        margin: "6px",
        marginLeft: 0,
      }}
    ></div>
  );
};

const CheckBox = ({ onChange, value }) => {
  return (
    <div
      onClick={() => onChange(!value)}
      style={{ cursor: "pointer", display: "flex", gap: "8px" }}
    >
      <div
        style={{
          width: "24px",
          height: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "4px",
          backgroundColor: value ? "#1E1E1E" : "white",
          border: "1px solid #D9D9D9",
          borderColor: value ? "#1E1E1E" : "#D9D9D9",
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
        >
          <path
            d='M3 8L6.75 12L13 4'
            stroke={value ? "white" : "#D9D9D9"}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>

      <p
        style={{
          fontSize: "14px",
          lineHeight: "18px",
          fontWeight: 500,
          marginTop: "3px",
        }}
      >
        데이터 이관 동의
      </p>
    </div>
  );
};
const ConsentTransfer = ({ nextStep, userData }) => {
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      email: "",
      nickname: "",
      isAccess: false,
    },
  });

  const onSubmit = (data) => {
    setOpen(true);
  };

  useEffect(() => {
    setValue("email", userData?.email, {
      shouldValidate: true,
    });
    setValue("nickname", userData?.nickname, {
      shouldValidate: true,
    });
  }, [userData]);
  
  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          //   maxWidth: "360px",
          margin: "auto",
          backgroundColor: "white",
          wordBreak: "keep-all",
          color: "#1E1E1E",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            padding: "16px",
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <div className='icon-wrap' onClick={() => console.log("click terms")}>
            <img
              src={arrowRightImage}
              style={{
                transform: "rotate(180deg)",
                width: "24px",
                height: "24px",
              }}
            />
          </div>

          <div style={{ flex: 1, textAlign: "center" }}>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "24px",
              }}
            >
              데이터 이관 동의
            </p>
          </div>

          <div
            style={{
              width: "24px",
              height: "24px",
            }}
          ></div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
          <div style={{ flex: 1 }}>
            <div style={{ padding: "0 20px", borderBottom: "1px solid #EBEBEB" }}>
              <div
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "8px",
                  padding: "20px 16px",
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontWeight: 400,
                  color: "#3B3B3B",
                }}
              >
                <p>피카버스는 사용자의 요청에 따라 데이터 이관 작업을 대행하고 있습니다.</p>

                <div style={{ display: "flex" }}>
                  <Dot />
                  <p>수집된 콘텐츠 정보는 인증된 개인의 피카버스 페이지에서만 보관됩니다.</p>
                </div>
                <div style={{ display: "flex", marginTop: "12px" }}>
                  <div
                    style={{
                      marginLeft: "8px",
                      marginRight: "4px",
                      width: "4px",
                    }}
                  >
                    -
                  </div>
                  <p style={{ letterSpacing: "-0.24px" }}>
                    피카버스 계정 1개당 1개의 소셜미디어 채널의 콘텐츠만 이관이 가능합니다.
                  </p>
                </div>
                <div style={{ display: "flex", marginTop: "4px" }}>
                  <div
                    style={{
                      marginLeft: "8px",
                      marginRight: "4px",
                      width: "4px",
                    }}
                  >
                    -
                  </div>
                  <p style={{ letterSpacing: "-0.24px" }}>
                    피카버스 계정에서 데이터 이관을 완료한 소셜미디어 채널의 데이터를 또다른
                    피카버스 계정에서 이관할 수 없습니다.
                  </p>
                </div>

                <div style={{ display: "flex", marginTop: "8px" }}>
                  <Dot />
                  <p>
                    타인의 콘텐츠를 동의없이 무단으로 이관할 시에 책임은 신청자 본인에게 있습니다.
                  </p>
                </div>

                <div style={{ display: "flex", marginTop: "8px" }}>
                  <Dot />
                  <p>
                    피카버스 데이터 이관은 원본 소셜미디어 채널(유튜브, 인스타그램)에 절대 영향을
                    끼치지 않습니다.
                  </p>
                </div>
              </div>

              <div style={{ marginTop: "15px", marginBottom: "26px" }}>
                <CheckBox
                  onChange={(value) =>
                    setValue("isAccess", value, {
                      shouldValidate: true,
                    })
                  }
                  value={getValues("isAccess")}
                />
              </div>
            </div>

            <div style={{ padding: "24px 20px 20px" }}>
              <div>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  피카버스 계정 이메일
                </p>

                <input
                  type='email'
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #D9D9D9",
                    width: "100%",
                    marginTop: "8px",
                  }}
                  placeholder='example@email.com'
                  disabled
                  {...register("email", { required: true })}
                />
                {/* {errors.email && <span>This field is required</span>} */}
              </div>

              <div style={{ marginTop: "20px" }}>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  피카버스 계정 이름
                </p>

                <input
                  type='text'
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #D9D9D9",
                    width: "100%",
                    marginTop: "8px",
                  }}
                  disabled
                  placeholder='계정 이름을 입력해 주세요.'
                  {...register("nickname", { required: true })}
                />
                {/* {errors.nickname && <span>This field is required</span>} */}
              </div>
            </div>
          </div>
          <div style={{ padding: "20px" }}>
            <button
              type='submit'
              style={{
                borderRadius: "8px",
                padding: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 500,
                color: getValues("isAccess") && isValid ? "#FFF" : "#707070",
                backgroundColor: getValues("isAccess") && isValid ? "#1E1E1E" : "#EBEBEB",
                width: "100%",
              }}
              disabled={!isValid || !getValues("isAccess")}
              //   onClick={() => setOpen(true)}
            >
              확인
            </button>
          </div>
        </form>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            style={{ fontSize: "16px", fontWeight: 700, lineHeight: "24px" }}
          >
            알림
          </Typography>
          <Typography
            id='modal-modal-description'
            sx={{
              mt: "16px",
              fontSize: "14px",
              lineHeight: "22px",
              fontWeight: 400,
            }}
          >
            데이터 이관을 이미 완료하였습니다.
          </Typography>
          <Typography
            sx={{
              mt: "24px",
              borderRadius: "8px",
              padding: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              color: "#FFF",
              backgroundColor: "#1E1E1E",
              width: "100%",
            }}
            onClick={nextStep}
          >
            확인
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default ConsentTransfer;
