import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import MyPost from "./MyPost";
import MyPostPaid from "./MyPostPaid";
import MyPaid from "./MyPaid";
import PublicPost from "./PublicPost";
import { COLORS } from "../../../theme/constants";
import PublicPostPaid from "./PublicPostPaid";
import MyLimitedPaid from "./MyLimitedPaid";
import MyQuiz from "./MyQuiz";
import PublicQuiz from "./PublicQuiz";

const Wrapper = styled.div`
  .MuiTabs-scroller,
  .MuiTabs-root,
  .MuiButtonBase-root {
    min-height: 100%;
  }

  .MuiTabs-flexContainer {
    align-items: center;
    justify-content: space-around;
  }

  .tab-wrap {
    .MuiButtonBase-root {
      max-width: 100%;
      color: var(--subTextColor);
      flex: 1 0 auto;
      padding: 8px 0;

      &.Mui-selected {
        color: ${COLORS.primary4};
        .tab__icon {
          fill: ${COLORS.primary4};
        }
      }
      .tab__icon {
        fill: ${COLORS.grey500};
      }
    }

    .MuiTabs-indicator {
      height: 1px;
      background-color: ${COLORS.primary4};
    }
  }

  .tab-content__wrap {
    position: relative;

    .tab-content {
      height: 100%;
    }
  }
`;

const TabContent = styled.div``;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <TabContent
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </TabContent>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProfileContent({
  profileType = "others",
  tabList = [{ label: "", text: "tab", type: "gallery", data: [] }],
  tabDefault = 0,
  activeTab = { activeTab },
}) {
  // const [activeTab, setActiveTab] = useState(tabDefault);
  // const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setSearchParams({ tab: newValue });
  };

  const tabDetail = (type) => {
    switch (type) {
      case "profile-post":
        return profileType === "my" ? <MyPost /> : <PublicPost />;
      case "post-paid":
        return profileType === "my" ? <MyPostPaid /> : <PublicPostPaid />;
      case "limited-paid":
        return profileType === "my" && <MyLimitedPaid />;
      case "quiz":
        return profileType === "my" ? <MyQuiz /> : <PublicQuiz />;
      case "my-paid":
        return <MyPaid />;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   setActiveTab(Number(searchParams.get("tab")));
  // }, [searchParams.get("tab")]);

  return (
    <Wrapper>
      <Box sx={{ width: "100%" }}>
        {/* <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="tabs"
          className="tab-wrap"
        >
          {tabList.map((item, index) => (
            <Tab
              // style={{ background: activeTab === index ? "red" : "blue" }}
              data-label={item.label}
              label={item.text}
              {...a11yProps(index)}
              key={index}
            />
          ))}
        </Tabs> */}
        <div className="tab-content__wrap" style={{ paddingTop: "8px" }}>
          {tabList.map((tabItem, index) => (
            <TabPanel key={index} value={activeTab} index={index} className="tab-content">
              {tabDetail(tabItem.type)}
            </TabPanel>
          ))}
        </div>
      </Box>
    </Wrapper>
  );
}

export default ProfileContent;
