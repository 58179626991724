import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { COLORS, RADIUS } from "@theme/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonUpload from "../uploadCloudflare/ButtonUpload";
import { useModalReducer } from "@hooks";
import ModalComponent from "../../components/modal/ModalComponent";
import { MODAL_STATUS } from "../../hooks/useModalReducer";
import RegisterBIZComplete from "./RegisterBIZComplete";
import { useTranslation } from "react-i18next";
import { registerBusinessAccount } from "@api";
import { NumericFormatCustom } from "../../components/numberic/NumbericFormatCustom";

const Wrapper = styled.div`
  margin-bottom: 60px;
  padding: 0 16px;
  .page-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: ${COLORS.black1};
  }

  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${COLORS.black1};
    white-space: pre-line;
    span {
      color: ${COLORS.primary4};
    }
  }

  .sub-title {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: ${COLORS.black1};
    white-space: pre-line;
  }

  .text-error {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: ${COLORS.redError};
    white-space: pre-line;
  }

  .btn-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${COLORS.grey600};
  }

  .label {
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    color: ${COLORS.black1};
    margin-bottom: 4px;
  }
`;

const buttonStyle = {
  padding: "1rem",
  width: "100%",
  height: "100%",
  borderRadius: "8px",
  backgroundColor: COLORS.grey50,
  color: COLORS.grey600,
  fontSize: "16px",
  lineHeight: "24px",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: COLORS.primary4,
    color: COLORS.white,
  },
  "&.Mui-disabled": {
    backgroundColor: COLORS.grey100,
    color: COLORS.grey600,
  },
};

const textMediumStyle = {
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "18px",
  color: COLORS.black1,
};

const inputStyle = {
  ...textMediumStyle,
  borderRadius: "8px",
  padding: "12px 16px",
  backgroundColor: COLORS.grey50,
  height: "24px",
};

const textFieldStyle = {
  ...textMediumStyle,
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
};

const REACT_APP_URL = process.env.REACT_APP_URL;

const FormInput = (props) => {
  const { name, placeholder, errors, register, maxLength } = props;
  return (
    <>
      <TextField
        fullWidth
        name={name}
        placeholder={placeholder}
        sx={textFieldStyle}
        inputProps={{
          sx: inputStyle,
          maxLength: maxLength,
        }}
        {...register(name)}
      />
      {errors[name] && <Typography className='text-error'>{errors[name]?.message}</Typography>}
    </>
  );
};

const FormInputNumber = (props) => {
  const { name, placeholder, errors, register, maxLength } = props;
  return (
    <>
      <TextField
        fullWidth
        name={name}
        placeholder={placeholder}
        sx={textFieldStyle}
        inputProps={{
          sx: inputStyle,
          maxLength: maxLength,
          thousandSeparator: false,
        }}
        InputProps={{
          inputComponent: NumericFormatCustom,
        }}
        {...register(name)}
      />
      {errors[name] && <Typography className='text-error'>{errors[name]?.message}</Typography>}
    </>
  );
};

const FormRadioButton = (props) => {
  const { label, value, register, name } = props;
  return (
    <Radio
      sx={{
        width: "100%",
        height: "100%",
        padding: 0,
        "&:hover": {
          ".MuiButtonBase-root": {
            backgroundColor: COLORS.primary4,
            color: COLORS.white,
          },
        },
      }}
      value={value}
      {...register(name)}
      checkedIcon={
        <Button
          sx={{
            ...buttonStyle,
            backgroundColor: COLORS.primary4,
            color: COLORS.white,
          }}
        >
          <Typography>{label}</Typography>
        </Button>
      }
      icon={
        <Button
          sx={{
            ...buttonStyle,
          }}
        >
          <Typography>{label}</Typography>
        </Button>
      }
    />
  );
};

function RegisterBIZ(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let location = useLocation();

  const schema = yup
    .object({
      company_type: yup.string().required(""),
      email: yup.string().required("이메일을 입력해 주세요.").email("포함하여 작성해 주세요."),
      nickname: yup
        .string()
        .required("닉네임을 입력해 주세요.")
        .min(2, "닉네임은 두 글자 이상이어야 합니다.")
        .max(30),
      fullName: yup.string().required("기업명을 입력해 주세요."),
      registration_number: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("번호를 입력해 주세요.")
        .typeError("숫자여야 합니다"),
      type_of_business: yup.string().required("사업 종류를 입력해 주세요."),
      location_of_business: yup.string().required("소재지를 입력해 주세요."),
      documentary: yup.string().required(),
      // official_site: yup.string().required(),
      // youtube: yup.string().required(),
      // instagram: yup.string().required(),
    })
    .required();

  const { modalData, dispatchModal } = useModalReducer();
  const {
    register,
    setValue,
    getValues,
    setError,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const [isShowImgErrModal, setIsShowImgErrModal] = useState(false);

  const onSubmit = async (data) => {
    try {
      const res = await registerBusinessAccount(data);
      if (res?.status == 200 || res?.status == 201) handleShowModalComplete(data);
    } catch (error) {
      const errors = error?.response?.data?.data?.[0];
      if (errors?.email) {
        setError("email", {
          type: "server",
          message: error?.response?.data?.data?.[0]?.email,
        });
      }
      if (errors?.nickname) {
        setError("nickname", {
          type: "server",
          message: error?.response?.data?.data?.[0]?.nickname,
        });
      }
    }
  };

  const handleShowModalComplete = (data) => {
    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        btnOKText: t("button.check"),
        btnCancelText: "",
        title: "",
        subTitle: "",
        content: (
          <RegisterBIZComplete
            companyName={data?.fullName}
            email={data?.email}
            type={data?.company_type}
          />
        ),
      },
    });
  };

  const handleOk = () => {
    dispatchModal({ type: MODAL_STATUS.CLOSE });
    // navigate(REACT_APP_URL, { replace: true });
    window.location.href = REACT_APP_URL;
  };

  useEffect(() => {
    const hostname = window.location.hostname;
    if (!hostname.includes("biz")) {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <div style={{ backgroundColor: "white" }}>
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleOk={handleOk}
        handleCancel={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <Typography className='page-name'>비즈니스(전용) 회원가입 신청하기</Typography>
          <Box sx={{ my: "40px" }}>
            {/* company_type */}
            <Box sx={{ mb: 3 }}>
              <Box sx={{ mb: 1.5 }}>
                <Typography className='title'>비즈니스 유형 선택</Typography>
                <Typography className='sub-title'>
                  사업자 유형에 해당하는 단체의 유형을 선택해 주세요.
                </Typography>
              </Box>
              <RadioGroup>
                <Grid container spacing={"20px"}>
                  <Grid item xs={6} md={3}>
                    <FormRadioButton
                      label='개인사업자'
                      value={1}
                      register={register}
                      name='company_type'
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormRadioButton
                      label='일반 법인'
                      value={2}
                      register={register}
                      name='company_type'
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormRadioButton
                      label='비영리 법인'
                      value={3}
                      register={register}
                      name='company_type'
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Radio
                      sx={{
                        width: "100%",
                        height: "100%",
                        padding: 0,
                        "&:hover": {
                          ".MuiButtonBase-root": {
                            backgroundColor: COLORS.primary4,
                            color: COLORS.white,
                          },
                        },
                      }}
                      value={4}
                      {...register("company_type")}
                      checkedIcon={
                        <Button
                          sx={{
                            ...buttonStyle,
                            display: "flex",
                            flexDirection: "column",
                            padding: "4px 8px 4px 8px",
                            minHeight: "56px",
                            backgroundColor: COLORS.primary4,
                            color: COLORS.white,
                          }}
                        >
                          <Typography>단체</Typography>
                          <Typography
                            // className='sub-title'
                            sx={{
                              fontSize: "10px",
                              fontWeight: "400",
                              lineHeight: "12px",
                            }}
                          >
                            고유번호가 있는 경우만 선택하세요.
                          </Typography>
                        </Button>
                      }
                      icon={
                        <Button
                          sx={{
                            ...buttonStyle,
                            display: "flex",
                            flexDirection: "column",
                            padding: "4px 8px 4px 8px",
                            minHeight: "56px",
                          }}
                        >
                          <Typography>단체</Typography>
                          <Typography
                            // className='sub-title'
                            sx={{
                              fontSize: "10px",
                              fontWeight: "400",
                              lineHeight: "12px",
                            }}
                          >
                            고유번호가 있는 경우만 선택하세요.
                          </Typography>
                        </Button>
                      }
                    />
                  </Grid>
                </Grid>
                {errors["company_type"] && (
                  <Typography className='text-error'>{errors["company_type"]?.message}</Typography>
                )}
              </RadioGroup>
            </Box>
            {/* email and nickname */}
            <Box sx={{ mb: 3 }}>
              <Grid container spacing={"20px"}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mb: 1 }}>
                    <Typography className='title'>
                      이메일
                      <span style={{ color: "#8F0DED", fontWeight: 400 }}>(필수)</span>
                    </Typography>
                    <Typography className='sub-title'>
                      {`회원으로 가입하실 이메일 주소를 기입해 주세요. 가입한 이메일 주소로\n가입 신청 승인 안내 및 완료 메일이 발송될 예정입니다.`}
                    </Typography>
                  </Box>
                  <FormInput
                    name='email'
                    placeholder='example@email.com'
                    errors={errors}
                    register={register}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mb: 1 }}>
                    <Typography className='title'>
                      닉네임
                      <span style={{ color: "#8F0DED", fontWeight: 400 }}>(필수)</span>
                    </Typography>
                    <Typography className='sub-title'>
                      {`플랫폼에서 활동할 계정 닉네임을 기입해 주세요. 기입하지 않을 경우,\n작성하신 기업명(단체명)으로 가입됩니다.`}
                    </Typography>
                  </Box>
                  <FormInput
                    name='nickname'
                    placeholder='닉네임을 입력해 주세요.'
                    errors={errors}
                    register={register}
                    maxLength={30}
                  />
                </Grid>
              </Grid>
            </Box>
            {/* company name  */}
            <Box sx={{ mb: 3 }}>
              <Grid container spacing={"20px"}>
                <Grid item xs={12} md={12}>
                  <Box sx={{ mb: 1 }}>
                    <Typography className='title'>
                      기업명
                      <span style={{ color: "#8F0DED", fontWeight: 400 }}>(필수)</span>
                    </Typography>
                    <Typography className='sub-title'>
                      {`사업자등록증, 혹은 법인등록증에 기입된 기업명을 입력해 주세요.`}
                    </Typography>
                  </Box>
                  <FormInput
                    name='fullName'
                    placeholder='기업명을 입력해 주세요.'
                    errors={errors}
                    register={register}
                    maxLength={50}
                  />
                </Grid>
              </Grid>
            </Box>
            {/* registration number and type of business */}
            <Box sx={{ mb: 3 }}>
              <Grid container spacing={"20px"}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mb: 1 }}>
                    <Typography className='title'>
                      법인등록번호 혹은 사업자등록번호
                      <span style={{ color: "#8F0DED", fontWeight: 400 }}>(필수)</span>
                    </Typography>
                    <Typography className='sub-title'>
                      {`고유번호가 있는 단체의 경우, 고유 번호를 입력해 주세요.`}
                    </Typography>
                  </Box>
                  <FormInputNumber
                    name='registration_number'
                    placeholder='번호를 입력해 주세요.'
                    errors={errors}
                    register={register}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mb: 1 }}>
                    <Typography className='title'>
                      업종
                      <span style={{ color: "#8F0DED", fontWeight: 400 }}>(필수)</span>
                    </Typography>
                    <Typography className='sub-title'>
                      {`사업자등록증 혹은 법인등록증에 신고된 내용으로 작성해 주세요.`}
                    </Typography>
                  </Box>
                  <FormInput
                    name='type_of_business'
                    placeholder='사업 종류를 입력해 주세요.'
                    errors={errors}
                    register={register}
                  />
                </Grid>
              </Grid>
            </Box>
            {/* location and document evidence */}
            <Box sx={{ mb: 3 }}>
              <Grid container spacing={"20px"}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mb: 1 }}>
                    <Typography className='title'>
                      사업장 소재지
                      <span style={{ color: "#8F0DED", fontWeight: 400 }}>(필수)</span>
                    </Typography>
                    <Typography className='sub-title'>
                      {`사업자등록증 혹은 법인등록증에 신고된 내용으로 작성해 주세요.`}
                    </Typography>
                  </Box>
                  <FormInput
                    name='location_of_business'
                    placeholder='소재지를 입력해 주세요.'
                    errors={errors}
                    register={register}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mb: 1 }}>
                    <Typography className='title'>
                      증빙 서류
                      <span style={{ color: "#8F0DED", fontWeight: 400 }}>(필수)</span>
                    </Typography>
                    <Typography className='sub-title'>
                      {`법인 혹은 사업자등록증 이미지를 업로드해 주세요.`}
                    </Typography>
                  </Box>
                  <ButtonUpload
                    limitSize={2}
                    handleChange={(value) => {
                      setValue("documentary", value, {
                        shouldValidate: true,
                      });
                    }}
                    handleSetError={(value) => {
                      setIsShowImgErrModal(true);
                    }}
                  />
                  <Dialog
                    sx={{
                      "& .MuiPaper-rounded": {
                        borderRadius: RADIUS.large,
                        width: "328px",
                      },
                      p: 2,
                    }}
                    open={isShowImgErrModal}
                  >
                    <div style={{ margin: "32px 20px" }}>
                      <div
                        style={{
                          fontWeight: 700,
                          textAlign: "center",
                          fontSize: 16,
                          lineHeight: "24px",
                        }}
                      >
                        업로드 실패
                      </div>
                      <div
                        style={{
                          fontSize: 14,
                          lineHeight: "20px",
                          textAlign: "center",
                          paddingTop: 16,
                          paddingBottom: 24,
                        }}
                      >
                        파일 업로드를 실패했습니다.
                      </div>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          style={{
                            background: "#1E1E1E",
                            borderRadius: 8,
                            width: 320,
                            height: 48,
                            padding: 12,
                            textAlign: "center",
                            color: "white",
                          }}
                          onClick={() => setIsShowImgErrModal(false)}
                        >
                          확인
                        </Button>
                      </div>
                    </div>
                  </Dialog>
                  {getValues("documentary") && (
                    <div style={{ marginTop: 8, display: "flex" }}>
                      <div style={{ marginRight: 4 }}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                        >
                          <path
                            d='M4.91901 6.79351L3.43004 8.28247C2.87395 8.83856 2.55416 9.59521 2.56 10.3904C2.56585 11.1856 2.87864 11.9469 3.46111 12.5114C4.02557 13.0939 4.78699 13.4066 5.58207 13.4125C6.39529 13.4185 7.13402 13.1167 7.69014 12.5606L9.17911 11.0716M11.081 9.20612L12.57 7.71715C13.126 7.16106 13.4458 6.40442 13.44 5.60921C13.4341 4.814 13.1214 4.05274 12.5389 3.48825C11.9746 2.92393 11.2133 2.61112 10.4181 2.60528C9.62285 2.59944 8.86611 2.90107 8.30999 3.45718L6.82102 4.94614M5.74207 10.2179L10.209 5.75101'
                            stroke='#C0C0C0'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          />
                        </svg>
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          lineHeight: "16px",
                          fontWeight: 500,
                        }}
                      >
                        {getValues("documentary")}
                      </div>
                    </div>
                  )}
                  {errors["documentary"] && (
                    <Typography className='text-error'>{errors["documentary"]?.message}</Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
            {/* URL  */}
            <Box sx={{ mb: 3 }}>
              <Grid container rowSpacing={1} columnSpacing={2.5}>
                <Grid item xs={12} md={12}>
                  <Box sx={{ mb: 1 }}>
                    <Typography className='title'>
                      URL
                      {/* <span>(필수)</span> */}
                    </Typography>
                    <Typography className='sub-title'>
                      {`기업 혹은 단체의 URL을 입력해 주세요.`}
                    </Typography>
                  </Box>
                  {/* official site */}
                  <Typography className='label'>{`공식 사이트`}</Typography>
                  <FormInput
                    name='official_site'
                    placeholder='URL을 입력해 주세요.'
                    errors={errors}
                    register={register}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className='label'>{`유튜브 YouTube`}</Typography>
                  <FormInput
                    name='youtube'
                    placeholder='URL을 입력해 주세요.'
                    errors={errors}
                    register={register}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className='label'>{`인스타그램 Instagram`}</Typography>
                  <FormInput
                    name='instagram'
                    placeholder='URL을 입력해 주세요.'
                    errors={errors}
                    register={register}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Button
            sx={{
              ...buttonStyle,
              borderRadius: "32px",
              maxWidth: "320px",
              margin: "0 auto",
              display: "block",
            }}
            type='submit'
            disabled={!isValid}
          >
            신청하기
          </Button>
        </Wrapper>
      </form>
    </div>
  );
}

export default RegisterBIZ;
