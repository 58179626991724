import React from "react";
import { Box, ListItem, ListItemAvatar, ListItemText, Skeleton } from "@mui/material";

function ChatSkeleton() {
  return (
    <ListItem
      sx={{
        p: 0,
        my: "1.5rem",
        alignItems: "center",
        "& .MuiListItemSecondaryAction-root": {
          right: "0px",
        },
      }}
      secondaryAction={
        <Box sx={{ display: "flex", gap: "0.25rem"}}>
          <Skeleton variant='text' width={30} height={16} />
        </Box>
      }
    >
      <ListItemAvatar
        sx={{
          mr: 1,
          my: 0,
          cursor: "pointer",
        }}
      >
        <Skeleton variant='circular' width={48} height={48} />
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton variant='text' width={100} height={18} sx={{mb:1}} />}
        sx={{
          my: 0,
        }}
        secondaryTypographyProps={{ component: "div" }}
        secondary={<Skeleton variant='text' width={150} height={18} />}
      />
    </ListItem>
  );
}

export default ChatSkeleton;
