import arrowRightImage from "@images/arrowRight.svg";
import { Box, Modal, Typography } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ButtonUpload from "../uploadCloudflare/ButtonUpload";
import { numberWithCommas } from "../../utils";
import dayjs from "dayjs";

export default function Complete({ setStep, getValues }) {
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let date = today.getDate();

  const fullDate = dayjs().format("YYYY.MM.DD");

  return (
    <div
      style={{
        //   maxWidth: "360px",
        margin: "auto",
        backgroundColor: "white",
        wordBreak: "keep-all",
        color: "#1E1E1E",
        display: "flex",
        flexDirection: "column",
        minHeight: "90svh",
      }}
    >
      <div
        style={{
          padding: "16px",
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <div
          style={{
            flex: 1,
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "24px",
            }}
          >
            창작파트너 후원완료
          </p>
        </div>
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              padding: 20,
              maxWidth: 500,
            }}
          >
            <div
              style={{
                border: "solid 24px #8F0DED",
                padding: "24px 42px 34px 42px",
                textAlign: "center",
                minWidth: 350,
              }}
            >
              <div
                style={{
                  color: "#8F0DED",
                  fontWeight: 700,
                  fontSize: 24,
                  lineHeight: "28px",
                  marginBottom: 34,
                }}
              >
                크리에이터 후원
              </div>
              <div
                style={{ fontSize: 16, lineHeight: "24px", marginBottom: 12 }}
              >
                <span
                  style={{
                    fontSize: 24,
                    fontWeight: 700,
                    lineHeight: "38px",
                  }}
                >
                  {getValues("personalName") || getValues("companyName")}
                </span>{" "}
                님이
              </div>

              <div
                style={{
                  fontSize: 24,
                  fontWeight: 700,
                  lineHeight: "38px",
                  marginBottom: 12,
                }}
              >
                {numberWithCommas(getValues("totalMoney") || 0)} 원 후원
              </div>

              <div
                style={{
                  fontSize: 14,
                  lineHeight: "20px",
                }}
              >
                {getValues("personalName") || getValues("companyName")} 님이
                후원하는 크리에이터의
              </div>
              <div
                style={{
                  fontSize: 14,
                  lineHeight: "20px",
                  marginBottom: 12,
                }}
              >
                창작활동을 지원합니다
              </div>

              <div
                style={{
                  fontSize: 14,
                  lineHeight: "20px",
                }}
              >
                {fullDate}
              </div>
            </div>
            {/* <div
              style={{
                fontSize: 14,
                fontWeight: 700,
                lineHeight: "18px",
                marginTop: 28,
                marginBottom: 16,
                textAlign: "center",
              }}
            >
              나의 기부활동을 공유해 보세요!
            </div>
            <div style={{ display: "flex", justifyContent: "center", gap: 16 }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                <rect width="48" height="48" rx="24" fill="#F3DF4A" />
                <path
                  d="M24 13.4404C17.6352 13.4404 12.48 17.5156 12.48 22.5484C12.48 25.8244 14.6688 28.69 17.9448 30.2956C17.7 31.1956 17.0736 33.5572 16.944 34.0612C16.7856 34.6876 17.1744 34.6804 17.4264 34.5076C17.6208 34.378 20.58 32.362 21.8616 31.498C22.5528 31.5988 23.2656 31.6564 24 31.6564C30.3648 31.6564 35.52 27.5812 35.52 22.5484C35.52 17.5156 30.3648 13.4404 24 13.4404Z"
                  fill="#381E1F"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                <rect width="48" height="48" rx="24" fill="#1877F2" />
                <path
                  d="M30.6068 25.7892L31.4563 20.2715H26.1653V16.6925C26.1653 15.1863 26.9031 13.7099 29.2729 13.7099H31.6799V9.01246C31.6799 9.01246 29.4964 8.63965 27.4098 8.63965C23.0504 8.63965 20.2037 11.2792 20.2037 16.0661V20.2715H15.3599V25.7892H20.2037V39.3596H26.1653V25.7892H30.6068Z"
                  fill="white"
                />
              </svg>

              <div
                style={{
                  borderRadius: 25,
                  border: "1px solid #D3D3D5",
                  padding: 11,
                  width: 48,
                  height: 48,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4 15.2044L4 18.8925C4 19.4514 4.21071 19.9875 4.58579 20.3827C4.96086 20.778 5.46957 21 6 21H18C18.5304 21 19.0391 20.778 19.4142 20.3827C19.7893 19.9875 20 19.4514 20 18.8925V15.2044M12.0011 3V14.9425M12.0011 14.9425L16.5725 10.3793M12.0011 14.9425L7.42969 10.3793"
                    stroke="#555555"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div> */}
          </div>
        </div>
        <div style={{ padding: "20px", background: "white" }}>
          <button
            type="submit"
            style={{
              borderRadius: "8px",
              padding: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              color: "#FFF",
              backgroundColor: "#1E1E1E",
              width: "100%",
            }}
            onClick={() => window.location.reload()}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
}
