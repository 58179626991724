import React, { useEffect, useState } from "react";
import DrawerListComponent from "./DrawerListComponent";
import { ReactComponent as ImageIcon } from "../../assets/svgs/image.svg";
import { ReactComponent as RefreshIcon } from "../../assets/svgs/refresh.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svgs/delete.svg";

function ProfileUploadDrawer({ close, data, callBack }) {
  const [modalData, setModalData] = useState([]);

  const uploadFile = {
    text: "앨범에서 선택",
    icon: <ImageIcon />,
    fn: () => {
      callBack("selectFile");
    },
  };

  const revertFile = {
    text: "기본 이미지로 변경",
    icon: <RefreshIcon width={28} height={28} />,
    fn: () => {
      callBack("revertFile");
    },
  };

  const deleteFile = {
    text: "커버 이미지 삭제",
    icon: <DeleteIcon />,
    fn: () => {
      callBack("deleteFile");
    },
  };

  useEffect(() => {
    const prevData = [uploadFile];
    if (data?.isRevert) {
      prevData.push(revertFile);
    }
    if (data?.isDelete) {
      prevData.push(deleteFile);
    }
    setModalData(prevData);
  }, [data]);

  return <DrawerListComponent close={close} modalData={modalData} />;
}

export default React.memo(ProfileUploadDrawer);
