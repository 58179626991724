import React, { forwardRef, useState } from "react";
import { Link, useMatch, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Home } from "@icons/home.svg";
import { ReactComponent as Search } from "@icons/search.svg";
import { ReactComponent as Smile } from "@icons/smile.svg";
import { ReactComponent as SmileWhite } from "@icons/smile_white.svg";
import { ReactComponent as CircleIcon } from "@icons/circle.svg";
import { ReactComponent as QuizIcon } from "@icons/quiz.svg";
import mainImage from "../../assets/images/main-image.png";
import { COLORS, RADIUS, WIDTH } from "../../theme/constants";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";
import { Box, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { textMediumMuiStyle } from "../../utils/commonStyle";
import { animateScroll } from "react-scroll";

const Wrapper = styled.div`
   {
    .icon-dark {
      path {
        fill: ${COLORS.grey100};
      }
    }
    .icon-light {
      path {
        fill: ${COLORS.grey600};
      }
    }

    .icon-dark__active {
      path {
        fill: ${COLORS.primary8};
      }
    }
    .icon-light__active {
      path {
        fill: ${COLORS.primary8};
      }
    }

    .icon-light-smile__active {
      circle {
        stroke: ${COLORS.primary8};
      }
      path {
        stroke: ${COLORS.primary8};
      }
    }

    .circle-icon-dark {
      circle {
        fill: ${COLORS.primary8};
      }
    }
  }
`;

const Navigation = forwardRef((props, ref) => {
  const location = useLocation();
  const navigate = useNavigate();
  const homeMatch = useMatch("/");
  const exploreMatch = useMatch("/explore");
  const searchMatch = useMatch("/search");
  const marketMatch = useMatch("/market");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };
  const [drawerType, setDrawerType] = useState("create");

  const isTabletCustom = useMediaQuery({
    query: `(min-width: 458px)`,
  });

  const isFoldSmall = useMediaQuery({
    query: `(max-width: ${WIDTH.fold_small})`,
  });

  const handleCreate = () => {
    handleDrawer(true);
    // navigate(link);
  };
  const isDark = () => {
    let result = false;
    // if (homeMatch?.pathname === "/" || location.pathname.includes("post") || exploreMatch) {
    //   result = true;
    // }
    return result;
  };

  const onClickDrawer = (option) => {
    console.log("onClickDrawer option:", option);
    switch (drawerType) {
      case "create":
        navigate(`/${option}/create`);
        break;
      default:
        break;
    }
  };

  const scrollToTop = (link) => {
    if (link === "/") {
      animateScroll.scrollToTop({
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: "scrollableContent",
      });
    }
  };

  const lightNavigates = [
    {
      link: "/",
      icon: (
        <Home
          style={{
            fill: "transparent",
            stroke: isDark() ? COLORS.grey100 : COLORS.grey600,
          }}
        />
      ),
      iconActive: (
        <Home
          style={{
            fill: "transparent",
            stroke: isDark() ? COLORS.primary8 : COLORS.primary8,
          }}
        />
      ),
      title: "Feed",
      isActive: homeMatch || location.pathname.includes("post"),
    },
    {
      link: "/search",
      icon: <Search className={`icon-${isDark() ? "dark" : "light"}`} />,
      iconActive: <Search className={`icon-${isDark() ? "dark__active" : "light__active"}`} />,
      title: "Search",
      isActive:
        searchMatch ||
        location.pathname.includes("/search/feed") ||
        location.pathname.includes("/search/details/category") ||
        location.pathname.includes("/search/details/keyword") ||
        location.pathname.includes("/search/category"),
    },
    {
      link: "/post/create",
      icon: "",
      title: "",
    },
    {
      link: "/quiz",
      icon: <QuizIcon className={`icon-${isDark() ? "dark" : "light"}`} />,
      iconActive: <QuizIcon className={`icon-${isDark() ? "dark__active" : "light__active"}`} />,
      title: "Quiz",
      isActive: location.pathname.includes("/quiz"),
    },
    {
      link: "/profile",
      icon: isDark() ? <SmileWhite /> : <Smile />,
      iconActive: isDark() ? <SmileWhite /> : <Smile className='icon-light-smile__active' />,
      title: "My",
      isActive: location.pathname.includes("/profile"),
    },
  ];

  return (
    <Wrapper>
      <Box
        ref={ref}
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          zIndex: 10,
          transition: "bottom 0.3s",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            background: isDark() ? "rgba(85, 94, 128, 0.8)" : "rgba(255, 255, 255, 0.8)",
            p: 1,
            // borderTop: `1px solid ${COLORS.grey200}`,
            border: `1px solid ${COLORS.grey600}`,
            maxWidth: "458px",
            width: "100%",
            borderRadius: isTabletCustom ? RADIUS.xxxxLarge : 0,
            mx: "auto",
            mb: isTabletCustom ? 2 : 0,
          }}
        >
          {lightNavigates.map((item) =>
            item?.title ? (
              <Link to={item.link} key={item.link} onClick={() => scrollToTop(item.link)}>
                <Box>
                  {isFoldSmall ? (
                    item?.isActive ? (
                      item.iconActive
                    ) : (
                      item.icon
                    )
                  ) : !item?.isActive ? (
                    item.icon
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          ...textMediumMuiStyle,
                          color: isDark() ? COLORS.primary8 : COLORS.primary3,
                          marginBottom: "10px",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <CircleIcon className={isDark() ? "circle-icon-dark" : ""} />
                    </Box>
                  )}
                </Box>
              </Link>
            ) : (
              <Box
                onClick={() => handleCreate(item.link)}
                sx={{ cursor: "pointer" }}
                key={item.link}
              >
                <img src={mainImage} />
              </Box>
            )
          )}
        </Box>
      </Box>
      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame
            type={drawerType}
            setOnDrawer={() => handleDrawer(false)}
            handleModal={onClickDrawer}
          />
        </DrawerPortal>
      )}
    </Wrapper>
  );
});

export default React.memo(Navigation);
