import React from "react";
import { Box, Typography } from "@mui/material";
import { COLORS, PIC_TYPE, TEXTSIZE } from "../../../theme/constants";
import { ReactComponent as PicWhite } from "../../../assets/svgs/PIC_default.svg";
import { ReactComponent as PicGrey } from "../../../assets/svgs/PIC_grey.svg";
import { ReactComponent as PicSelect } from "../../../assets/svgs/PIC_select.svg";
import { ReactComponent as TabUnlockIcon } from "@icons/purchase_black_outlined.svg";
import { isMine, numberFormatThousand } from "../../../utils/commonFunction";
import styled from "styled-components";

const WrapperIcon = styled.div`
  width: 24px;
  height: 24px;
  .tab-unlock__icon--selected {
    path {
      fill: ${COLORS.primary4};
    }
    circle {
      stroke: ${COLORS.primary4};
    }
  }
`;

function PICComponent(props) {
  const {
    handleClickPIC,
    styleBox = {},
    costComponent = <></>,
    picType = PIC_TYPE.white,

    post = {
      is_donated: false,
      is_paid: false,
      cost: 0,
      total_donated_cost: 0,
      total_unlock_cost: 0,
    },
    page = "",
  } = props;

  const renderPic = (type) => {
    switch (type) {
      case PIC_TYPE.white:
        return (
          <WrapperIcon>
            <TabUnlockIcon width={24} height={24} />
          </WrapperIcon>
        );
      case PIC_TYPE.grey:
        return (
          <WrapperIcon>
            <TabUnlockIcon width={24} height={24} />
          </WrapperIcon>
          // <PicGrey width={24} height={24} />
        );
      case PIC_TYPE.purple:
        return (
          <WrapperIcon>
            <TabUnlockIcon className='tab-unlock__icon--selected' width={24} height={24} />
          </WrapperIcon>
          // <PicSelect width={24} height={24} />
        );
      default:
        return (
          <WrapperIcon>
            <TabUnlockIcon width={24} height={24} />
          </WrapperIcon>
          // <PicWhite width={24} height={24} />
        );
    }
  };

  const renderText = (color = COLORS.white, content = post?.cost) => {
    if (!content) return;
    return (
      <Typography
        sx={{
          // ml: "4px",
          color: color,
          fontSize: TEXTSIZE.small,
          lineHeight: "1rem",
        }}
      >
        {Number.isInteger(content) ? numberFormatThousand(content) : content}
      </Typography>
    );
  };

  const renderContent = () => {
    const isMineTmp = isMine(post?.user_profile?.user_id);

    // list recommend post
    if (page === "home/recommend") {
      if (isMineTmp) {
        return (
          <>
            {renderPic(PIC_TYPE.grey)}
            {renderText(COLORS.grey, post?.cost > 0 && post?.cost)}
          </>
        );
      }

      if (post?.cost === 0) {
        return (
          <>
            {renderPic(post?.is_donated ? PIC_TYPE.purple : PIC_TYPE.grey)}
            {renderText(COLORS.primary4, post?.is_donated && "PIC")}
          </>
        );
      }

      if (post?.cost > 0) {
        return (
          <>
            {renderPic(post?.is_paid ? PIC_TYPE.purple : PIC_TYPE.grey)}
            {renderText(COLORS.grey, post?.cost)}
          </>
        );
      }
    }

    //only show when post has donate
    if (page === "home/feed") {
      if (post?.total_donated_cost > 0) {
        return (
          <>
            {renderPic(PIC_TYPE.purple)}
            {renderText(COLORS.white, post?.total_donated_cost)}
          </>
        );
      }
    }

    // post detail
    if (page === "post/detail") {
      if (isMineTmp) {
        return (
          <>
            {renderPic(PIC_TYPE.grey)}
            {renderText(
              COLORS.black1,
              post?.cost > 0 ? post?.total_unlock_cost : post?.total_donated_cost
            )}
          </>
        );
      }

      if (post?.cost === 0) {
        return (
          <>
            {renderPic(post?.is_donated ? PIC_TYPE.purple : PIC_TYPE.grey)}
            {renderText(
              post?.is_donated ? COLORS.primary6 : COLORS.black1,
              post?.total_donated_cost
            )}
          </>
        );
      }

      if (post?.cost > 0) {
        return (
          <>
            {renderPic(post?.is_paid ? PIC_TYPE.purple : PIC_TYPE.grey)}
            {renderText(post?.is_paid ? COLORS.primary6 : COLORS.black1, post?.total_unlock_cost)}
          </>
        );
      }
    }

    // list all my post
    // if (page === "profile/tab1") {
    //   return (
    //     <>
    //       {renderPic(PIC_TYPE.white)}
    //       {renderText(COLORS.white, post?.cost > 0 && post?.cost)}
    //     </>
    //   );
    // }

    // list post i have donate
    if (page === "profile/tab2") {
      return (
        // <>
        //   {renderPic(PIC_TYPE.purple)}
        //   {renderText(COLORS.primary4, "PIC")}
        // </>
        <>
          {renderPic(PIC_TYPE.purple)}
          {renderText(COLORS.white, post?.cost)}
        </>
      );
    }

    // list post i have pay for unlock
    if (page === "profile/tab3") {
      return (
        <>
          {renderPic(PIC_TYPE.purple)}
          {renderText(COLORS.white, post?.cost)}
        </>
      );
    }

    // list all post of other user
    // if (page === "profile/other/tab1") {
    //   if (isMineTmp) {
    //     return (
    //       <>
    //         {renderPic(PIC_TYPE.white)}
    //         {renderText(COLORS.white, post?.cost > 0 && post?.cost)}
    //       </>
    //     );
    //   }

    //   if (post?.cost === 0) {
    //     return (
    //       <>
    //         {renderPic(post?.is_donated ? PIC_TYPE.purple : PIC_TYPE.white)}
    //         {renderText(
    //           post?.is_donated && COLORS.primary4,
    //           post?.is_donated && "PIC"
    //         )}
    //       </>
    //     );
    //   }

    //   if (post?.cost > 0) {
    //     return (
    //       <>
    //         {renderPic(post?.is_paid ? PIC_TYPE.purple : PIC_TYPE.white)}
    //         {renderText(COLORS.white, post?.cost)}
    //       </>
    //     );
    //   }
    // }

    // list post other user have donate
    if (page === "profile/other/tab2") {
      if (isMineTmp) {
        return renderPic(PIC_TYPE.white);
      }

      return (
        <>
          {renderPic(post?.is_donated ? PIC_TYPE.purple : PIC_TYPE.white)}
          {renderText(post?.is_donated && COLORS.primary4, post?.is_donated && "PIC")}
        </>
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.25rem",
        cursor: "pointer",
        ...styleBox,
      }}
      onClick={handleClickPIC}
    >
      {renderContent()}
    </Box>
  );
}

export default PICComponent;
