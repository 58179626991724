import React from "react";
import SignUpWithEmail from "./SignUpWithEmail";
import UpdateUserInfo from "./UpdateUserInfo";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import SetPassword from "./SetPassword.jsx";
import styled from "styled-components";
import { WIDTH } from "../../theme/constants.js";
import { useTranslation } from "react-i18next";
import HeaderBack from "../../components/header/HeaderBack.jsx";

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

function RegisterPage(props) {
  const params = useParams();
  const { t } = useTranslation();
  const { page } = props;
  // console.log("params", params);
  const renderItem = () => {
    switch (page) {
      case "create_account":
        return <SignUpWithEmail />;
      case "profile_settings":
        return <UpdateUserInfo />;
      case "set_password":
        return <SetPassword />;
      default:
        break;
    }
  };

  return (
    <Wrapper>
       <HeaderBack title={t(`page_title.${page}`)}/>
      <Box>{renderItem()}</Box>
    </Wrapper>
  );
}

export default RegisterPage;
