import { Avatar, Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  blockPost,
  createConnection,
  createReport,
  deleteConnection,
  deletePost,
  editViewBuyer,
  getPostDetail,
  getUserBalance,
  unlockOrDonatePost,
} from "../../api";
import MyFeedMedia from "@components/newComponents/posts/MyFeed/MyFeedMedia";
import { ReactComponent as MoreIcon } from "@icons/more.svg";
import { ICON_SIZE, COLORS, LINE_HEIGHT, TEXTSIZE, FONT_WEIGHT, RADIUS } from "@theme/constants";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";
import PICComponent from "@components/newComponents/posts/PICComponent";
import CommentComponent from "@components/newComponents/posts/CommentComponent";
import PayDetail from "../purchase/PayDetail";
import ModalComponent from "@components/modal/ModalComponent";
import Report from "@components/newComponents/report";
import { toast } from "react-toastify";
import styled from "styled-components";
import { relativeTimeKorean } from "@utils/dateTimeFormat";
import TruncateText from "@components/newComponents/truncate/TruncateText";
import { handleError, isMine, numberWithCommas } from "@utils/commonFunction";
import {
  textMediumMuiStyle,
  textSmallMuiStyle,
  truncateStyle,
  textLargeMuiStyle,
} from "@utils/commonStyle";
import { useAuth } from "../../context/Auth";
import MyFeedMediaVideo from "@components/newComponents/posts/MyFeed/MyFeedMediaVideo";
import ImageAvatar from "@images/avatar.png";
import HomeFeedOverview from "@components/newComponents/posts/HomeFeedOverview";
import RememberPurchase from "@components/modal/RememberPurchase";
import { useTranslation } from "react-i18next";
import { GlobalStateContext } from "../../context/GlobalStateProvider";
import ButtonConnect from "../../components/connect/ButtonConnect";
import Author from "../author/Author";
import { useModalReducer } from "@hooks";
import { MODAL_STATUS } from "../../hooks/useModalReducer";

const Wrapper = styled.div``;

function PostDetailItem(props) {
  const {
    indexPost,
    post,
    prevPost,
    isPostInView = true,
    onVideoInView = () => {},
    onVideoOutView = () => {},
  } = props;
  const params = useParams();
  const { logout, user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkedPurchase, setCheckedPurchase] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { selectedPost, setSelectedPost } = useContext(GlobalStateContext);
  const [openPayment, setOpenPayment] = useState(false);
  //modal
  const [reasons, setReasons] = useState([]);
  const { modalData, dispatchModal } = useModalReducer();
  const [currentPost, setCurrentPost] = useState({});

  // Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("list");

  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  const handleMore = () => {
    setDrawerType("list");
    handleDrawer(true);
  };

  const fetchData = async () => {
    try {
      const res = await getPostDetail(currentPost?.id);
      setCurrentPost(res);
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  // begin donate
  const onClickHeart = () => {
    if (currentPost?.cost === 0) {
      if (isMine(currentPost?.user_profile?.user_id)) {
        onRedirectToMemberList();
      } else {
        setDrawerType("sendpic");
        handleDrawer(true);
      }
    }

    if (currentPost?.cost > 0) {
      onRedirectToMemberList();
    }
  };
  // end donate

  const handleModal = (type = "select") => {
    if (type === "report") {
      dispatchModal({
        type: MODAL_STATUS.OPEN,
        data: {
          type: "report",
          btnOKText: t("button.declaration"),
          btnCancelText: t("button.cancel"),
          title: t("dialog.title.report"),
          subTitle: t("dialog.subTitle.report"),
          content: <Report onChangeReason={setReasons} />,
        },
      });
    }

    if (type === "delete") {
      if (!currentPost.can_delete) {
        dispatchModal({
          type: MODAL_STATUS.OPEN,
          data: {
            type: "delete",
            title:
              currentPost?.cost > 0
                ? t("dialog.title.can_not_delete_post", { emotion: "(:" })
                : t("dialog.title.can_not_delete_post"),
            subTitle: "",
            btnOKText: "확인",
            btnCancelText: "",
            content: (
              <Typography
                sx={{
                  ...textMediumMuiStyle,
                  color: COLORS.grey900,
                  lineHeight: LINE_HEIGHT.xxxLarge,
                  whiteSpace: "pre-line",
                }}
              >
                {currentPost?.cost > 0
                  ? t("dialog.content.can_not_delete_purchased_post")
                  : t("dialog.content.can_not_delete_sponsored_post")}
              </Typography>
            ),
          },
        });
      } else {
        dispatchModal({
          type: MODAL_STATUS.OPEN,
          data: {
            type: "delete",
            title: t("dialog.title.delete_post"),
            subTitle: "",
            btnOKText: t("button.delete"),
            btnCancelText: t("button.cancel"),
            content: (
              <Typography
                sx={{
                  ...textMediumMuiStyle,
                  color: COLORS.grey900,
                  lineHeight: LINE_HEIGHT.xxxLarge,
                  whiteSpace: "pre-line",
                }}
              >
                {t("dialog.content.delete_post")}
              </Typography>
            ),
          },
        });
      }
    }

    if (type === "edit") {
      if (!currentPost.can_edit) {
        dispatchModal({
          type: MODAL_STATUS.OPEN,
          data: {
            type: "edit",
            title: t("dialog.title.can_not_edit_post"),
            subTitle: "",
            btnOKText: t("button.check"),
            btnCancelText: "",
            content: (
              <Typography
                sx={{
                  ...textMediumMuiStyle,
                  color: COLORS.grey900,
                  lineHeight: LINE_HEIGHT.xxxLarge,
                  whiteSpace: "pre-line",
                }}
              >
                {currentPost?.cost > 0
                  ? t("dialog.content.purchased_post")
                  : t("dialog.content.sponsored_post")}
              </Typography>
            ),
          },
        });
      } else {
        handleEditPost();
      }
    }

    if (type === "connect") {
      handleConnect();
    }

    if (type === "hide") {
      handleHidePost();
    }

    if (type === "share") {
      navigator.clipboard.writeText(`${window.location.origin}/post/detail/${currentPost.id}`);
    }

    // if (type === "edit_is_view_buyer") {
    //   handleUpdateViewBuyer();
    // }
  };

  const handleOpenModalErrorDelete = (value) => {
    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        type: "error",
        title:
          currentPost?.cost > 0
            ? t("dialog.title.can_not_delete_post", { emotion: "(:" })
            : t("dialog.title.can_not_delete_post"),
        subTitle: "",
        btnOKText: "확인",
        btnCancelText: "",
        content: (
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey900,
              lineHeight: LINE_HEIGHT.xxxLarge,
              whiteSpace: "pre-line",
            }}
          >
            {value}
          </Typography>
        ),
      },
    });
  };

  const handleDeletePost = async () => {
    try {
      await deletePost(currentPost.id);
      // if (selectedPost?.from) {
      //   setSelectedPost({
      //     id: prevPost.id,
      //     index: indexPost - 1,
      //     from: selectedPost.from,
      //     nickname: "",
      //   });
      //   navigate(`/details`, {
      //     replace: true,
      //   });
      // } else {
      //   navigate(-1, { replace: true });
      // }
      navigate(0);
    } catch (error) {
      const errorResponse = handleError(error, logout, navigate);
      handleOpenModalErrorDelete(errorResponse?.response?.data?.message);
    }
  };

  const handleHidePost = async () => {
    try {
      await blockPost(currentPost.id, { reason: "This is block reason." });
      toast(t("hidden_post"));
      // if (selectedPost?.from) {
      //   setSelectedPost({
      //     id: prevPost.id,
      //     index: indexPost - 1,
      //     from: selectedPost.from,
      //     nickname: "",
      //   });
      //   navigate(`/details`, {
      //     replace: true,
      //   });
      // } else {
      //   navigate(-1, { replace: true });
      // }
      navigate(0);
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const handleEditPost = () => {
    setSelectedPost({
      id: currentPost?.id,
      index: indexPost,
      from: selectedPost?.from,
    });
    navigate(`/post/edit`, { state: { post: currentPost } });
  };

  const handleReportPost = async () => {
    try {
      const requestReason = reasons
        .filter((value) => value.checked)
        .map((item) => {
          return { reason: item.reason, type: item.type };
        });
      const requestData = {
        target: "post",
        target_id: currentPost.id,
        reasons: requestReason,
      };
      await createReport(requestData);
      toast(t("report_completed"));
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  // update view buyer
  // const handleUpdateViewBuyer = async () => {
  //   try {
  //     const requestData = {
  //       is_view_buyer: currentPost?.is_view_buyer === 0 ? 1 : 0,
  //     };
  //     await editViewBuyer(currentPost?.id, requestData);
  //     toast(
  //       currentPost?.is_view_buyer === 1 ? t("buyer_has_been_private") : t("buyer_has_been_public")
  //     );
  //     fetchData();
  //   } catch (error) {
  //     handleError(error, logout, navigate);
  //   }
  // };

  //modal action
  const handleOk = () => {
    handleClose();

    switch (modalData.type) {
      case "report":
        handleReportPost();
        break;
      case "delete":
        if (currentPost.can_delete) {
          handleDeletePost();
        }
        break;
      case "purchase":
        handleConfirmPurchase();
        break;
      case "purchaseSuccess":
        fetchData();
        // setSelectedPost({
        //   id: currentPost?.id,
        //   index: indexPost,
        //   from: selectedPost.from,
        //   nickname: "",
        // });
        // navigate(`/details`, {
        //   replace: true,
        // });
        break;
      case "notEnoughUserPIC":
        setOpenPayment(true);
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    dispatchModal({ type: MODAL_STATUS.CLOSE });
  };

  const handleClose = () => {
    dispatchModal({ type: MODAL_STATUS.CLOSE });
    if (modalData.type === "purchaseSuccess") {
      fetchData();
    }
  };

  const isDisableBtnOk = () => {
    let result = false;
    if (
      modalData.type === "report" &&
      reasons
        .filter((value) => value.checked)
        .map((item) => {
          return { reason: item.reason, type: item.type };
        }).length === 0
    )
      result = true;
    return result;
  };
  //end modal action

  // Purchase
  const openModalConfirm = (type, title, description) => {
    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        type: type,
        title: title,
        subTitle: "",
        btnOKText: "확인",
        btnCancelText: "",
        content: (
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey900,
              lineHeight: LINE_HEIGHT.xxxLarge,
              whiteSpace: "pre-line",
            }}
          >
            {description}
          </Typography>
        ),
      },
    });
  };

  const onPurchase = () => {
    handleCheckUserBalance();
  };

  const handleCheckUserBalance = async () => {
    try {
      const user = await getUserBalance();
      if (user?.balance < currentPost.cost) {
        dispatchModal({
          type: MODAL_STATUS.OPEN,
          data: {
            type: "notEnoughUserPIC",
            title: t("dialog.title.would_you_like_to_charge"),
            subTitle: "",
            btnOKText: t("button.charge"),
            btnCancelText: t("button.cancel"),
            content: (
              <Typography
                sx={{
                  ...textMediumMuiStyle,
                  color: COLORS.grey900,
                  lineHeight: LINE_HEIGHT.xxxLarge,
                  whiteSpace: "pre-line",
                }}
              >
                {t("dialog.content.would_you_like_to_charge")}
              </Typography>
            ),
          },
        });
        return;
      }
      handlePurchase();
    } catch (error) {
      const errorMessage = handleError(error, logout, navigate);
      toast(errorMessage);
    }
  };

  const handlePurchase = () => {
    if (localStorage.getItem("isRememberPurchase") === "true") {
      handleConfirmPurchase();
      return;
    }
    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        type: "purchase",
        btnOKText: t("button.check"),
        btnCancelText: t("button.cancel"),
        title: t("dialog.title.purchase", { cost: currentPost.cost }),
        subTitle: "",
        content: <RememberPurchase setCheckedPurchase={setCheckedPurchase} />,
      },
    });
  };

  const handleConfirmPurchase = async () => {
    localStorage.setItem("isRememberPurchase", checkedPurchase);

    try {
      await unlockOrDonatePost(currentPost.id, {
        pic: currentPost.cost,
        type: "unlock",
      });
      openModalConfirm(
        "purchaseSuccess",
        t("dialog.title.purchase_completed"),
        t("dialog.content.purchase_completed")
      );
    } catch (error) {
      const errorMessage = handleError(error, logout, navigate);
      toast(errorMessage);
    }
  };

  //connection action
  const handleConnect = async () => {
    try {
      if (currentPost.connection) {
        await deleteConnection(currentPost?.user_profile?.user_id);
        toast(t("disconnected_successfully"));
      } else {
        await createConnection({ to_user_id: currentPost?.user_profile?.user_id });
        toast(t("connected_successfully"));
      }
      fetchData();
    } catch (error) {
      handleError(error, logout);
    }
  };

  const onRedirectToMemberList = () => {
    // if (!isMine(currentPost?.user_profile?.user_id) && currentPost?.is_view_buyer == 0) return;

    if (currentPost?.cost === 0) {
      navigate(`/post/${currentPost.id}/sponsors/donate`);
    } else {
      navigate(`/post/${currentPost.id}/sponsors/unlock`);
    }
  };

  useEffect(() => {
    const drawerRoot = document.getElementById("drawer-root");
    drawerRoot.style.display = "block";
  }, []);

  useEffect(() => {
    setCurrentPost(post);
  }, [post]);

  return (
    <Wrapper>
      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame
            setOnDrawer={() => handleDrawer(false)}
            handleModal={handleModal}
            type={drawerType}
            data={currentPost}
            refetch={fetchData}
            callBack={() => navigate(-1)}
          />
        </DrawerPortal>
      )}
      {openPayment && (
        <DrawerPortal>
          <DrawerFrame
            setOnDrawer={() => {
              setOpenPayment(false);
              onPurchase();
            }}
            handleModal={() => {}}
            type={"payment"}
          />
        </DrawerPortal>
      )}

      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => handleClose()}
        handleOk={() => handleOk()}
        handleCancel={() => handleCancel()}
        btnOKProps={{
          disabled: isDisableBtnOk(),
        }}
        btnCancelProps={{}}
      />

      {currentPost && (
        <Box sx={{ fontSize: TEXTSIZE.medium, color: COLORS.black1, pb: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Author
              authorData={{
                id: currentPost?.user_profile?.user_id,
                avatar: currentPost?.user_profile?.profile_image_url,
                name: currentPost?.user_profile?.nickname,
                job: currentPost?.user_profile?.introduce,
              }}
              authorStyle={{
                width: "calc(100% - 90px)",
                gap: "1rem",
              }}
              avatarStyle={{
                width: ICON_SIZE.large,
                height: ICON_SIZE.large,
              }}
              nameStyle={{
                color: COLORS.black1,
                fontSize: TEXTSIZE.medium,
                lineHeight: LINE_HEIGHT.xLarge,
              }}
              jobStyle={{
                color: COLORS.grey600,
                fontSize: TEXTSIZE.medium,
                lineHeight: LINE_HEIGHT.xLarge,
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {!isMine(currentPost?.user_profile?.user_id) && (
                <ButtonConnect
                  isConnect={!!currentPost?.connection}
                  userId={currentPost?.user_profile?.user_id}
                  buttonProps={{ marginRight: "1rem", height: "20px" }}
                  handleConnectSuccess={() => fetchData()}
                  textCancelConnect={t("button.connecting")}
                  textConnect={t("button.connect")}
                />
              )}
              <Box
                sx={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              >
                <MoreIcon onClick={() => handleMore()} />
              </Box>
            </Box>
          </Box>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            pagination={
              currentPost?.files?.length > 1 && currentPost?.is_paid && { type: "fraction" }
            }
            modules={[Pagination]}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          >
            {currentPost?.files?.length > 0 &&
              currentPost.files.map((media, indexMedia) => (
                <SwiperSlide key={media.id}>
                  <Box sx={{ width: "100%" }}>
                    {media?.type === "video" ? (
                      <MyFeedMediaVideo
                        data={media}
                        onInView={onVideoInView}
                        onOutView={onVideoOutView}
                        isPlay={activeIndex === indexMedia && isPostInView}
                        onClickMedia={() => {
                          if (activeIndex === indexMedia) {
                            // screenfull.request(
                            //   document.querySelector(
                            //     `#react-player-${media?.id}`
                            //   )
                            // );
                            // setActiveIndex();
                          } else {
                            setActiveIndex(indexMedia);
                          }
                        }}
                      />
                    ) : (
                      <MyFeedMedia data={media} index={indexMedia} post={currentPost} />
                    )}
                    <Box
                      sx={{
                        position: "absolute",
                        zIndex: 2,
                        bottom: "1rem",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <HomeFeedOverview post={currentPost} />
                      </Box>
                    </Box>

                    {currentPost?.user_profile?.user_id != user.id &&
                      currentPost?.cost > 0 &&
                      !currentPost?.is_paid && (
                        <>
                          {currentPost?.type === 2 && (
                            <Box
                              sx={{
                                position: "absolute",
                                zIndex: 2,
                                right: "0.75rem",
                                top: "0.75rem",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                borderRadius: RADIUS.medium,
                                padding: "6px 0.5rem",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  lineHeight: "16px",
                                  color: COLORS.white,
                                }}
                              >
                                {`잔여수량 ${numberWithCommas(
                                  currentPost?.limited - currentPost?.paid_posts_count
                                )}`}
                              </Typography>
                            </Box>
                          )}
                          {indexMedia != 0 && media?.is_locked == 1 && (
                            <PayDetail cost={currentPost.cost} handlePurchase={onPurchase} />
                          )}
                        </>
                      )}
                  </Box>
                </SwiperSlide>
              ))}
          </Swiper>
          {/* )} */}

          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                pb: 1,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Box onClick={onClickHeart}>
                  <PICComponent post={currentPost} page='post/detail' />
                </Box>
                <Link
                  to={`/comment/${currentPost.id}/${currentPost?.user_profile?.nickname}님의 게시물`}
                >
                  <CommentComponent
                    post={currentPost}
                    handleClickComment={() => console.log()}
                    styleBox={{}}
                    styleIcon={{ fill: COLORS.grey500 }}
                    styleText={{
                      // color: post?.is_paid ? COLORS.primary6 : COLORS.black1,
                      color: COLORS.black1,
                      fontSize: TEXTSIZE.small,
                    }}
                  />
                </Link>
              </Box>
              <Typography sx={textSmallMuiStyle}>
                {relativeTimeKorean(currentPost.created_at)}
              </Typography>
            </Box>

            {/* alway show when post has fee 
            if post free only show for owner */}
            {currentPost?.latest_paid &&
              (currentPost?.cost > 0 ||
                (currentPost?.cost === 0 && isMine(currentPost?.user_profile?.user_id))) && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    sx={{
                      width: "14px",
                      height: "14px",
                      mr: 0.5,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(
                        isMine(currentPost?.latest_paid?.user_id)
                          ? `/profile`
                          : `/user-profile/${currentPost?.latest_paid?.nickname}`
                      )
                    }
                    src={currentPost?.latest_paid?.profile_image_url || ImageAvatar}
                  />
                  <Link
                    to={
                      isMine(currentPost?.latest_paid?.user_id)
                        ? `/profile`
                        : `/user-profile/${currentPost?.latest_paid?.nickname}`
                    }
                  >
                    <Typography
                      component={"span"}
                      sx={{
                        ...textMediumMuiStyle,
                        fontWeight: FONT_WEIGHT.xLarge,
                      }}
                    >
                      {currentPost?.latest_paid?.nickname}
                    </Typography>
                    <Typography component={"span"} sx={textMediumMuiStyle}>
                      님 외
                    </Typography>
                  </Link>
                  <Box
                    onClick={onRedirectToMemberList}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      component={"span"}
                      sx={{
                        ...textMediumMuiStyle,
                        ml: 0.5,
                        fontWeight: FONT_WEIGHT.xLarge,
                      }}
                    >
                      {currentPost?.cost > 0 &&
                        currentPost?.paid_posts_count > 1 &&
                        currentPost?.paid_posts_count - 1}
                    </Typography>
                    <Typography component={"span"} sx={{ ...textMediumMuiStyle }}>
                      {currentPost?.cost > 0 &&
                        currentPost?.paid_posts_count > 1 &&
                        t("people_purchased_content")}

                      {currentPost?.cost > 0 &&
                        currentPost?.paid_posts_count === 1 &&
                        t("i_purchased_content")}

                      {currentPost?.cost === 0 && t("sent_a_pic_to_your_content")}
                    </Typography>
                  </Box>
                </Box>
              )}
            <Typography sx={{ ...textLargeMuiStyle }}>{currentPost?.title}</Typography>
            <TruncateText content={currentPost?.contents} breakLine={true} lines={3} />

            {/* comment */}
            <Box sx={{ mt: 0.5 }}>
              <Link
                to={`/comment/${currentPost.id}/${currentPost?.user_profile?.nickname}님의 게시물`}
              >
                <Typography
                  sx={{
                    ...textMediumMuiStyle,
                    color: COLORS.grey400,
                    cursor: "pointer",
                    textAlign: "left",
                  }}
                >
                  {t("see_more_comments")}
                </Typography>
              </Link>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    ...textMediumMuiStyle,
                    ...truncateStyle,
                    fontWeight: FONT_WEIGHT.large,
                    lineHeight: LINE_HEIGHT.xxxLarge,
                    maxWidth: "50%",
                    width: "auto",
                    marginRight: "0.5rem",
                  }}
                >
                  {currentPost?.comments?.[0]?.user_profile?.nickname}
                </Typography>
                <Typography
                  sx={{
                    ...textMediumMuiStyle,
                    ...truncateStyle,
                    lineHeight: LINE_HEIGHT.xxxLarge,
                    maxWidth: "50%",
                  }}
                >
                  {currentPost?.comments?.[0]?.comment}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Wrapper>
  );
}

export default PostDetailItem;
