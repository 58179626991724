import React, { forwardRef, useContext, useState } from "react";
import { Link, useMatch, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Home } from "@icons/home.svg";
import { ReactComponent as Search } from "@icons/search.svg";
import { ReactComponent as Smile } from "@icons/smile.svg";
import { ReactComponent as SmileWhite } from "@icons/smile_white.svg";
import { ReactComponent as CircleIcon } from "@icons/circle.svg";
import { ReactComponent as QuizIcon } from "@icons/quiz.svg";
import { ReactComponent as NavbarSwitchIcon } from "@icons/navbar-switch.svg";
import mainImage from "../../assets/images/main-image.png";
import { COLORS, WIDTH } from "../../theme/constants";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";
import { Box, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { textMediumMuiStyle } from "../../utils/commonStyle";
import { GlobalStateContext } from "../../context/GlobalStateProvider";

const Wrapper = styled.div`
   {
    .icon-dark {
      path {
        fill: ${COLORS.grey100};
      }
    }
    .icon-light {
      path {
        fill: ${COLORS.grey600};
      }
    }

    .icon-dark__active {
      path {
        fill: ${COLORS.primary8};
      }
    }
    .icon-light__active {
      path {
        fill: ${COLORS.primary8};
      }
    }

    .icon-light-smile__active {
      circle {
        stroke: ${COLORS.primary8};
      }
      path {
        stroke: ${COLORS.primary8};
      }
    }

    .circle-icon-dark {
      circle {
        fill: ${COLORS.primary8};
      }
    }
  }
`;

const NavigationTablet = forwardRef((props, ref) => {
  const location = useLocation();
  const homeMatch = useMatch("/");
  const navigate = useNavigate();

  const { setGlobalStateObj, globalState } = useContext(GlobalStateContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  const isTabletCustom = useMediaQuery({
    query: `(min-width: 458px)`,
  });

  const isFoldSmall = useMediaQuery({
    query: `(max-width: ${WIDTH.fold_small})`,
  });

  const handleUpload = (link) => {
    // handleDrawer(true);
    navigate(link);
  };
  const isDark = () => {
    let result = false;
    // if (homeMatch?.pathname === "/" || location.pathname.includes("post")) {
    //   result = true;
    // }
    return result;
  };

  const scrollToTop = (link) => {
    if (link === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const styleNavbarByPosition = (left) => {
    const styleLeft = {
      left: "0",
    };

    const styleRight = {
      right: "0",
    };
    return !left ? styleRight : styleLeft;
  };

  const lightNavigates = [
    {
      link: "/",
      icon: (
        <Home
          style={{
            fill: "transparent",
            stroke: isDark() ? COLORS.grey100 : COLORS.grey600,
          }}
        />
      ),
      iconActive: (
        <Home
          style={{
            fill: "transparent",
            stroke: isDark() ? COLORS.primary8 : COLORS.primary8,
          }}
        />
      ),
      title: "Feed",
      isActive: homeMatch || location.pathname.includes("post"),
    },
    {
      link: "/search",
      icon: <Search className={`icon-${isDark() ? "dark" : "light"}`} />,
      iconActive: <Search className={`icon-${isDark() ? "dark__active" : "light__active"}`} />,
      title: "Search",
      isActive: location.pathname.includes("/search"),
    },
    {
      link: "/post/create",
      icon: "",
      title: "",
    },
    {
      link: "/quiz",
      icon: <QuizIcon className={`icon-${isDark() ? "dark" : "light"}`} />,
      iconActive: <QuizIcon className={`icon-${isDark() ? "dark__active" : "light__active"}`} />,
      title: "Quiz",
      isActive: location.pathname.includes("/quiz"),
    },
    {
      link: "/profile",
      icon: isDark() ? <SmileWhite /> : <Smile />,
      iconActive: isDark() ? <SmileWhite /> : <Smile className='icon-light-smile__active' />,
      title: "My",
      isActive: location.pathname.includes("/profile"),
    },
  ];

  return (
    <Wrapper>
      <Box
        ref={ref}
        sx={{
          position: "fixed",
          top: "25%",
          bottom: 0,
          zIndex: 100,
          transition: "bottom 0.3s",
          ...styleNavbarByPosition(globalState?.isNavbarLeft),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            background: isDark() ? "rgba(85, 94, 128, 0.8)" : "rgba(255, 255, 255, 0.8)",
            p: 1,
            border: `1px solid ${COLORS.grey50}`,
            maxWidth: "458px",
            height: "392px",
            width: "fit-content",
            borderRadius: !globalState?.isNavbarLeft ? "20px 0px 0px 20px" : "0px 20px 20px 0px",
            mb: 1.5,
          }}
        >
          {lightNavigates.map((item) =>
            item?.title ? (
              <Link to={item.link} key={item.link} onClick={() => scrollToTop(item.link)}>
                <Box>
                  {isFoldSmall ? (
                    item?.isActive ? (
                      item.iconActive
                    ) : (
                      item.icon
                    )
                  ) : !item?.isActive ? (
                    item.icon
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          ...textMediumMuiStyle,
                          color: isDark() ? COLORS.primary8 : COLORS.primary3,
                          marginBottom: "10px",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <CircleIcon className={isDark() ? "circle-icon-dark" : ""} />
                    </Box>
                  )}
                </Box>
              </Link>
            ) : (
              <Box
                onClick={() => handleUpload(item.link)}
                sx={{ cursor: "pointer" }}
                key={item.link}
              >
                <img src={mainImage} />
              </Box>
            )
          )}
        </Box>
        <NavbarSwitchIcon
          style={{ cursor: "pointer" }}
          onClick={() => setGlobalStateObj({ isNavbarLeft: !globalState?.isNavbarLeft })}
        />
      </Box>

      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame type='appDown' setOnDrawer={() => handleDrawer(false)} />
        </DrawerPortal>
      )}
    </Wrapper>
  );
});

export default React.memo(NavigationTablet);
