import { useInfiniteQuery } from "@tanstack/react-query";
import { getPosts } from "@api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { handleError } from "@utils/commonFunction";
import { useEffect, useState } from "react";

function useGetPosts(props) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [posts, setPosts] = useState([]);
  const fetchData = async ({ pageParam }) => {
    try {
      const res = await getPosts({ limit: 32, offset: pageParam });
      return res;
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["posts"],
    queryFn: fetchData,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.meta?.offset_next;
    },
  });

  useEffect(() => {
    const newData = [];
    if (!data?.pages?.[0]) return;
    data?.pages?.forEach((post) => {
      if (post?.list?.length > 0) {
        const newPosts = structuredClone(post?.list);
        newData.push(...newPosts);
      }
    });
    setPosts(newData);
  }, [data]);

  return {
    data,
    posts,
    isLoading,
    fetchNextPage,
  };
}

export default useGetPosts;
