import React from "react";
import MyFeed from "../components/newComponents/posts/MyFeed";
import { WIDTH } from "../theme/constants";
import styled from "styled-components";
import FloatingButtonNew from "../containers/quiz/FloatingButtonNew";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

function Home(props) {
  return (
    <>
      <FloatingButtonNew />
      <Wrapper id='page-content'>
        <MyFeed />
      </Wrapper>
    </>
  );
}

export default Home;
