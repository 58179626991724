import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { animateScroll } from "react-scroll";
import { ReactComponent as ArrowUp } from "@icons/arrow_up.svg";
import styled from "styled-components";
import { COLORS, WIDTH } from "@theme/constants";
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  .icon-up--black {
    path {
      stroke: ${COLORS.black0};
    }
  }
`;

function BaseButtonScrollTopNew(props) {
  const { styleButtonProps, isDarkMode, isScrolling, isSideBar, isFooter } = props;

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const [isShow, setIsShow] = useState(false);

  const scrollToTop = () => {
    animateScroll.scrollToTop({
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      // containerId: "main-content",
    });
  };

  // useEffect(() => {
  //   if (window?.pageYOffset > 200) {
  //     setIsShow(true);
  //   } else {
  //     setIsShow(false);
  //   }
  // }, [isScrolling]);
  // useEffect(() => {
  //   console.log("offset", offset);
  // }, [offset]);

  return (
    <>
      {isPc && (
        <Wrapper id="btn-scroll-top">
          <Box
            sx={{
              position: "fixed",
              bottom: "0px",
              right: "100px",
              // width: "100%",
              zIndex: 100,
              // maxWidth: "1200px",
              display: "flex",
              justifyContent: "flex-end",
              // px: "40px",
              // visibility: offset > 2000 ? "visible" : "hidden",
            }}
          >
            <Button
              onClick={scrollToTop}
              sx={{
                transition: "opacity 0.5s",
                zIndex: 2,
                marginLeft: "auto",
                opacity: offset > 2000 ? 1 : 0,

                // position: "absolute",
                // right: "0px",
                // bottom: "180px",
                // right: isShow ? (isSideBar ? "40px" : "200px") : "-500px",
                // bottom: isFooter || isSideBar ? "180px" : "0",
                background: !isDarkMode ? COLORS.black0 : COLORS.white,
                width: "60px",
                height: "60px",
                borderRadius: "8px",
                boxShadow: "none",
                "&:hover": {
                  background: !isDarkMode ? COLORS.black0 : COLORS.white,
                },
                "&.Mui-disabled": {
                  backgroundColor: COLORS.grey100,
                },
                ...styleButtonProps,
              }}
            >
              <ArrowUp className={isDarkMode ? "icon-up--black" : ""} />
            </Button>
          </Box>
        </Wrapper>
      )}
    </>
  );
}

export default BaseButtonScrollTopNew;
