import { Box, List, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import NoData from "../../components/NoData";
import CircularComponent from "../process/CircularComponent";
import ItemMemberNotConnected from "./ItemMemberNotConnected";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useParams } from "react-router-dom";
import {
  COLORS,
  FONT_WEIGHT,
  LINE_HEIGHT,
  TEXTSIZE,
} from "../../theme/constants";
import { textSmallMuiStyle } from "../../utils/commonStyle";

const Wrapper = styled.div``;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 60px);
  position: relative;
`;

function ListMemberNotConnected(props) {
  const { postID } = useParams();
  const { data, fetchData, fetchMoreData, type, isTitle } = props;

  return (
    <Wrapper>
      {isTitle && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Link
              to={`/post/${postID}/sponsors/${type}/not-connected`}
            >
              <Typography
                sx={{
                  fontSize: TEXTSIZE.large,
                  fontWeight: FONT_WEIGHT.xxLarge,
                  lineHeight: LINE_HEIGHT.xxLarge,
                  color: COLORS.black1,
                }}
              >
                알 수도 있는 사람
              </Typography>
            </Link>
          </Box>
          <Link
            to={`/post/${postID}/sponsors/${type}/not-connected`}
          >
            <Typography sx={{ ...textSmallMuiStyle }}>더보기</Typography>
          </Link>
        </Box>
      )}
      <Box>
        {data.length > 0 ? (
          <InfiniteScroll
            dataLength={data.length}
            next={fetchMoreData}
            hasMore={true}
          >
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              {data.map((user) => (
                <ItemMemberNotConnected
                  {...user}
                  fetchData={fetchData}
                  key={user.id}
                  listItemStyle={{ maxWidth: "100%", margin: "1rem 0" }}
                />
              ))}
            </List>
          </InfiniteScroll>
        ) : (
          <HeightWhenEmpty>
            <NoData />
          </HeightWhenEmpty>
        )}
      </Box>
    </Wrapper>
  );
}

export default ListMemberNotConnected;
