import React, { useContext, useEffect, useState } from "react";
import { getAllCategories, getUserCategories, updateUserCategories } from "../../api";
import { Box, Button, Chip, Typography } from "@mui/material";
import { COLORS, RADIUS, TEXTSIZE, WIDTH } from "@theme/constants";
import { textMediumMuiStyle, buttonLargeMuiStyle, textSmallMuiStyle } from "@utils/commonStyle";
import { useNavigate } from "react-router";
import { useAuth } from "../../context/Auth";
import { handleError } from "@utils/commonFunction";
import { GlobalStateContext } from "../../context/GlobalStateProvider";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

function SearchCategory(props) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { callBackRedirectCategory, setSearchQuery, searchQuery } = useContext(GlobalStateContext);

  const [categories, setCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const onClickTag = (value) => {
    const selectedTagsTmp = structuredClone(selectedTags);

    const index = selectedTagsTmp.indexOf(value);
    if (index >= 0) {
      selectedTagsTmp.splice(index, 1);
    } else {
      selectedTagsTmp.push(value);
    }
    setSelectedTags(selectedTagsTmp);
  };

  const handleSubmit = async () => {
    try {
      const requestData = {
        categoryIds: selectedTags,
      };
      await updateUserCategories(requestData);
      if (!selectedTags.includes(searchQuery?.category_id)) {
        setSearchQuery({ ...searchQuery, category_id: "" });
      }
      navigate(callBackRedirectCategory);
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const handleGetUserCategories = async () => {
    try {
      const res = await getUserCategories();

      if (res?.list) {
        const resTmp = [...res.list];
        const userSelectedCategories = resTmp.map((category) => category.id);
        setSelectedTags(userSelectedCategories);
      }
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const handleGetCategories = async () => {
    try {
      const res = await getAllCategories();
      if (res?.list) {
        const resTmp = [...res.list];
        setCategories(resTmp);
      }
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  useEffect(() => {
    handleGetCategories();
    handleGetUserCategories();
  }, []);

  return (
    <Wrapper>
      <Box
        sx={{
          flex: "1 1 auto",
        }}
      >
        <Box
          sx={{
            mb: 4,
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "26px",
              color: COLORS.black1,
              my: 2,
            }}
          >
            콘텐츠 카테고리 선택하기
          </Typography>
          <Typography
            sx={{
              //styleName: Title/1-1;
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "24px",
              color: COLORS.black1,
              mb: 1,
              whiteSpace: "pre-line",
            }}
          >
            {`보고싶은 콘텐츠의 카테고리를 선택해\n나만을 위한 유니버스를 만들어 보세요!`}
          </Typography>
          <Typography
            sx={{
              ...textSmallMuiStyle,
              fontWeight: "400",
              lineHeight: "18px",
            }}
          >
            {`최대 ${categories?.length}개까지 선택 가능합니다!`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "wrap",
            gap: "0.75rem 0.25rem",
            my: 0.25,
          }}
        >
          {categories.length > 0 &&
            categories.map((item) => (
              <Chip
                key={item.id}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item.icon && (
                      <img
                        src={item.icon}
                        style={{
                          width: "1rem",
                          height: "1rem",
                        }}
                      />
                    )}
                    <Typography
                      sx={{
                        ...textMediumMuiStyle,
                        lineHeight: "22px",
                        color: selectedTags.includes(item.id) ? COLORS.white : COLORS.grey600,
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                }
                sx={{
                  width: "fit-content",
                  cursor: "pointer",
                  color: selectedTags.includes(item.id) ? COLORS.white : COLORS.grey700,
                  backgroundColor: selectedTags.includes(item.id) ? COLORS.primary4 : COLORS.white,
                  fontSize: TEXTSIZE.medium,
                  textAlign: "center",
                  border: `1px solid ${COLORS.grey100}`,
                  borderRadius: RADIUS.xLarge,
                  height: "30px",
                  "&:hover": {
                    // color: COLORS.white,
                    backgroundColor: selectedTags.includes(item.id)
                      ? COLORS.primary4
                      : COLORS.white,
                    boxShadow: "none",
                    "& .MuiChip-label": {
                      "& .MuiTypography-root": {
                        color: selectedTags.includes(item.id) ? COLORS.white : COLORS.grey700,
                      },
                    },
                  },
                }}
                onClick={() => onClickTag(item.id)}
              />
            ))}
        </Box>
      </Box>
      <Box
        sx={{
          zIndex: 10,
        }}
      >
        <Button
          sx={{ ...buttonLargeMuiStyle }}
          disabled={selectedTags?.length === 0}
          variant='contained'
          size='large'
          onClick={handleSubmit}
        >
          선택 완료
        </Button>
      </Box>
    </Wrapper>
  );
}

export default SearchCategory;
