import { Box, Button, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useAuth } from "../../context/Auth";
import { signUpRequest, signup, updateProfile } from "../../api";
import { COLORS, HEIGHT, RADIUS, TEXTSIZE } from "../../theme/constants";
import clearImage from "../../assets/images/clear.svg";
import { handleError } from "../../utils/commonFunction";
import {
  inputAdornmentMuiStyle,
  inputMuiStyle,
  labelMediumMuiStyle,
  textFieldMuiStyle,
  buttonLargeMuiStyle,
} from "../../utils/commonStyle";

const Wrapper = styled.div`
  position: relative;
  height: calc(calc(var(--vh, 1vh) * 100) - ${HEIGHT.header_mo} - ${HEIGHT.navigation});
  width: 100%;
`;

const LIMIT_LENGTH = 30;

function UpdateUserInfo(props) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { state: locationState } = useLocation();
  const { login } = useAuth();
  const [requestData, setRequestData] = useState({});
  const [countString, setCountString] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  // const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = (key, value) => {
    setCountString(value.length);
    setRequestData({ ...requestData, [key]: value });
  };

  const handleUpdateProfile = async () => {
    try {
      await updateProfile(requestData);
      navigate("/");
    } catch (error) {
      // console.log("handleUpdateProfile error", error);
      switch (error?.response?.data?.code) {
        case 400:
          setErrorMessage(error?.response?.data?.message);
          break;
        default:
          setErrorMessage(error?.response?.data?.errors?.[0]?.nickname);
          break;
      }
      handleError(error, logout, navigate);
    }
  };

  const handleSignUp = async (signUpData) => {
    setErrorMessage("");
    try {
      const tmpRequestData = {
        ...requestData,
        ...signUpData,
        device_id: uuid(),
        device_model: window.navigator.platform,
        device_os: "web",
      };
      const res = await signUpRequest(tmpRequestData);

      const userLocalStorage = JSON.parse(localStorage.getItem("verifyUser"));
      const responseData = {
        meta: { access_token: { token: res?.data?.data?.access_token?.token } },
        user: { ...userLocalStorage, profile: { ...requestData } },
      };

      login(responseData);
      navigate("/signup/welcome");
    } catch (error) {
      // console.log("handleSignUp error", error);
      setErrorMessage(error?.response?.data?.message);
    }
  };

  const handleUpdate = () => {
    if (locationState?.password) {
      // const user = JSON.parse(localStorage.getItem("user"));
      const verifyUser = JSON.parse(localStorage.getItem("verifyUser"));
      handleSignUp({
        password: locationState?.password,
        // email: userLocalStorage.email,
        // verify_token: access_token,
        ...verifyUser,
      });
    } else {
      handleUpdateProfile();
    }
  };

  return (
    <>
      <Wrapper>
        <Box sx={{ pt: 7 }}>
          <InputLabel sx={labelMediumMuiStyle}>프로필 이름을 입력해주세요.</InputLabel>
          <TextField
            onChange={(event) => handleChange("nickname", event.target.value)}
            placeholder='최소 2자 이상, 최대 30자 이하로 작성해주세요.'
            sx={textFieldMuiStyle}
            inputProps={{
              sx: inputMuiStyle,
              maxLength: LIMIT_LENGTH,
            }}
            InputProps={{
              endAdornment: countString > 0 && (
                <InputAdornment
                  position='end'
                  sx={inputAdornmentMuiStyle}
                  onClick={() => handleChange("nickname", "")}
                >
                  <img src={clearImage} />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
            <Typography
              sx={{
                color: COLORS.redError,
                fontSize: TEXTSIZE.small,
                visibility: countString < 2 || errorMessage ? "visible" : "hidden",
              }}
            >
              {errorMessage ? errorMessage : "최소 2자 이상으로 작성해주세요."}
            </Typography>
            <Typography
              sx={{
                color: COLORS.grey400,
                fontSize: TEXTSIZE.small,
              }}
            >{`${countString}/${LIMIT_LENGTH}`}</Typography>
          </Box>
        </Box>
        <Button
          sx={{
            ...buttonLargeMuiStyle,
            position: "absolute",
            bottom: "0px",
          }}
          disabled={countString < 2}
          variant='contained'
          size='large'
          className='submit'
          onClick={handleUpdate}
        >
          인증하기
        </Button>
      </Wrapper>
    </>
  );
}

export default UpdateUserInfo;
