import React from "react";
import ProfileBackground from "../containers/profile/overview/ProfileBackground";
import styled from "styled-components";
import { WIDTH } from "../theme/constants";

const WrapperPage = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;
  position: relative;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

function Profile(props) {
  const { type = "others" } = props;

  return (
    <WrapperPage>
      <ProfileBackground profileType={type} />
    </WrapperPage>
  );
}

export default Profile;
