import {
  Avatar,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import {
  COLORS,
  FONT_WEIGHT,
  ICON_SIZE,
  LINE_HEIGHT,
  TEXTSIZE,
} from "../../theme/constants";
import {
  textMediumMuiStyle,
  buttonMuiStyle,
  truncateStyle,
} from "../../utils/commonStyle";
import { unblockUser } from "../../api";
import { toast } from "react-toastify";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../components/modal/ModalComponent";
import ImageAvatar from "../../assets/images/avatar.png";

const Wrapper = styled.div``;

function ItemBlockAccount(props) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const {
    user_id = "",
    nickname = "",
    introduce = "",
    profile_image_url = "",
    fetchData,
    reason = "",
    blocked_at = "",
    hashtag = null,
    comment = "",
  } = props;

  const [modalData, setModalData] = useState({
    open: false,
    type: "",
    btnOKText: "",
    btnCancelText: "",
    title: "",
    subTitle: "",
    content: "",
  });

  const redirectToProfile = () => {
    // navigate(`/user-profile/${nickname}`);
  };

  const handleUnblock = async () => {
    try {
      await unblockUser({
        target_user_id: user_id,
      });
      toast("차단이 해제되었습니다.");
      fetchData();
    } catch (error) {
      handleError(error, logout);
    }
  };

  const onUnblock = () => {
    setModalData({
      open: true,
      type: "confirm",
      btnOKText: "해제",
      btnCancelText: "취소",
      title: "차단 해제",
      subTitle: "",
      content: (
        <Typography
          sx={{
            ...textMediumMuiStyle,
            color: COLORS.grey900,
            lineHeight: LINE_HEIGHT.xxxLarge,
          }}
        >
          {nickname}을 차단 해제하시겠습니까?
        </Typography>
      ),
    });
  };

  //modal action
  const handleOk = () => {
    setModalData({ ...modalData, open: false });
    handleUnblock();
  };

  const handleCancel = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
  };

  return (
    <>
      <Wrapper>
        <ListItem
          sx={{
            pl: 0,
            py: 0,
            my: "1.5rem",
            alignItems: "center",
            "& .MuiListItemSecondaryAction-root": {
              right: "0px",
            },
          }}
          secondaryAction={
            <Button
              sx={{
                ...buttonMuiStyle,
                p: 0.25,
                minWidth: "80px",
                backgroundColor: COLORS.grey50,
                color: COLORS.grey600,
                // "&:hover": {
                //   backgroundColor: COLORS.grey50,
                //   color: COLORS.grey600,
                // },
              }}
              onClick={onUnblock}
            >
              차단 해제
            </Button>
          }
        >
          <ListItemAvatar
            sx={{ minWidth: ICON_SIZE.large, mr: 1, my: 0, cursor: "pointer" }}
            onClick={redirectToProfile}
          >
            <Avatar
              alt=""
              src={profile_image_url || ImageAvatar}
              sx={{
                width: ICON_SIZE.large,
                height: ICON_SIZE.large,
              }}
            />
          </ListItemAvatar>
          <ListItemText
            onClick={redirectToProfile}
            primary={nickname}
            sx={{
              my: 0,
              "& .MuiListItemText-primary": {
                ...textMediumMuiStyle,
                ...truncateStyle,
                width: "70%",
                fontWeight: FONT_WEIGHT.large,
                cursor: "pointer",
              },
            }}
            secondaryTypographyProps={{ component: "div" }}
            secondary={
              <Typography
                sx={{
                  ...textMediumMuiStyle,
                  ...truncateStyle,
                  color: COLORS.grey500,
                  fontSize: TEXTSIZE.small,
                  width: "70%",
                }}
              >
                {introduce}
              </Typography>
            }
          />
        </ListItem>
      </Wrapper>
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => handleClose()}
        handleOk={() => handleOk()}
        handleCancel={() => handleCancel()}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
    </>
  );
}

export default ItemBlockAccount;
