import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../../assets/svgs/check_round.svg";
import { COLORS, RADIUS, TEXTSIZE, WIDTH } from "../../theme/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { getUserWithdrawer } from "../../api";
import { Button } from "@mui/material";
import { buttonMuiStyle } from "../../utils/commonStyle";

const Wrapper = styled.div`
  width: fit-content;
  // height: 100vh;
  // padding-top: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }

  .text--title {
    font-weight: 700;
    font-size: 28px;
  }

  .icon {
    margin: 1rem auto;
  }

  .text {
    font-weight: 700;
    font-size: ${TEXTSIZE.large};
  }

  .text--description {
    font-size: ${TEXTSIZE.medium};
    font-weight: 400;
    color: ${COLORS.gray_7};
    margin: 22px auto 38px;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    maxWidth: 440px;
    min-height: 287px;
    height: fit-content;
    padding: 24px 0;
    border-radius: ${RADIUS.medium};
    background: var(--colorF3to3);
  }

  .info__title {
    font-weight: 600;
    font-size: ${TEXTSIZE.large};
    color: ${COLORS.gray_7};
    margin-bottom: 0.5rem;
  }

  .info__text {
    font-weight: 600;
    font-size: ${TEXTSIZE.large};

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .button {
    margin-top: 28px;
    width: 288px;
    height: 56px;
    font-weight: 600;
    font-size: ${TEXTSIZE.large};
    background: var(--textColor);
    color: var(--bgColor);
    border-radius: ${RADIUS.medium};
    cursor: pointer;
  }
`;

function CalculateComplete() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const { state } = useLocation();
  const isRegister = location.pathname.includes("register");
  const [userData, setUserData] = useState({});

  const handleGetUserWithdrawer = async () => {
    try {
      const res = await getUserWithdrawer();
      if (res) {
        setUserData(res[0]);
      }
    } catch (error) {
      handleError(error, logout);
    }
  };

  useEffect(() => {
    handleGetUserWithdrawer();
  }, []);

  return (
    <Wrapper>
      <p className="text--title">정산 {isRegister ? "등록" : null} 신청 완료</p>
      <CheckIcon className="icon" />
      <p className="text">
        정산 {isRegister ? "등록" : null} 신청이 접수되었습니다.
      </p>
      <p className="text--description">
        심사에는 2~3일의 영업일이 소요되며 승인 거절 시 이메일을 통해 안내
        드립니다.
      </p>
      <dl className="info">
        <dt className="info__title">은행명</dt>
        <dd className="info__text">{userData?.bank}</dd>
        <dt className="info__title">계좌번호</dt>
        <dd className="info__text">{userData?.account_number}</dd>
        <dt className="info__title">예금주 명</dt>
        <dd className="info__text">{userData?.account_name}</dd>
        {isRegister ? null : (
          <>
            <dt className="info__title">정산 금액</dt>
            <dd className="info__text">{state?.amount}원</dd>
          </>
        )}
      </dl>
      {/* <button
        type="button"
        className="button"
        onClick={() => navigate("/calculate")}
      >
        확인
      </button> */}
      <Button sx={{...buttonMuiStyle, my: 4}} onClick={() => navigate("/calculate")}>
        확인
      </Button>
    </Wrapper>
  );
}

export default React.memo(CalculateComplete);
