import React, { useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import MessageHeader from "./MessageHeader";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";
import useModalReducer, { MODAL_STATUS } from "../../hooks/useModalReducer";
import ModalComponent from "../../components/modal/ModalComponent";
import Report from "../../components/newComponents/report";
import useCreateReport from "../../hooks/useCreateReport";
import MessageItem from "./MessageItem";
import MessageInput from "./MessageInput";
import { GlobalStateContext } from "../../context/GlobalStateProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useGetMessages from "../../hooks/chat/useGetMessages";
import useGetConversation from "../../hooks/chat/useGetConversation";
import { animateScroll } from "react-scroll";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth } from "../../context/Auth";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { COLORS, WIDTH } from "../../theme/constants";
import { useTranslation } from "react-i18next";
import MessageSkeleton from "../../components/layouts/skeleton/MessageSkeleton";
import { TYPE_MESSAGE_CHAT } from "./Message";

function MessageList(props) {
  const { t } = useTranslation();
  const { receiverID = "" } = useParams();
  const { state } = useLocation();
  const { ref, inView } = useInView();
  const { logout, user: localUser } = useAuth();
  const navigate = useNavigate();
  const { modalData, dispatchModal } = useModalReducer();
  const { handleReport } = useCreateReport();
  const { globalState } = useContext(GlobalStateContext);
  const [data, setData] = useState([]);

  //get list message
  const {
    data: messageFromApi,
    isLoading,
    filters,
    fetchNextPage,
    handleChangeFilters,
  } = useGetMessages({ receiverID: receiverID });

  // get conversation information
  const { data: conversationInfo } = useGetConversation({
    receiverID: receiverID,
  });

  const [reasons, setReasons] = useState([]);
  // Drawer state
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("list");

  //Drawer begin
  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  const onOpenDrawer = () => {
    setDrawerType("chat");
    handleDrawer(true);
  };
  //Drawer end

  const handleDrawerClick = (type = "select") => {
    switch (type) {
      case "report":
        dispatchModal({
          type: MODAL_STATUS.OPEN,
          data: {
            type: "report",
            btnOKText: "신고",
            btnCancelText: "취소",
            title: "무엇을 신고하려고 하시나요?",
            subTitle: "댓글 신고 시 자동으로 계정 차단이 진행됩니다.",
            content: <Report onChangeReason={setReasons} />,
          },
        });

        break;

      default:
        break;
    }
  };

  const handleReportUser = () => {
    const requestReason = reasons
      .filter((value) => value.checked)
      .map((item) => {
        return { reason: item.reason, type: item.type };
      });
    const requestData = {
      target: "user",
      target_id: "profileData.userID",
      reasons: requestReason,
    };
    // console.log("requestData", requestData);
    handleReport(requestData);
  };

  //modal action
  const handleOk = () => {
    dispatchModal({ type: MODAL_STATUS.CLOSE });

    switch (modalData.type) {
      case "report":
        handleReportUser();
        break;
      default:
        break;
    }
  };

  const onScrollToBottom = () => {
    animateScroll.scrollToBottom();
  };

  const saveLocalMessage = (localMessage) => {
    // console.log("localMessage", localMessage);
    const dataTmp = structuredClone(data);
    dataTmp.unshift({ ...localMessage });
    setData(dataTmp);
    onScrollToBottom();
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  useEffect(() => {
    // console.log("messageFromApi", messageFromApi);
    setData(messageFromApi);
  }, [messageFromApi]);

  useEffect(() => {
    const globalMessage = globalState.chat.message;
    if (!globalMessage) return;
    const dataTmp = structuredClone(data);
    const index = dataTmp.findIndex((currentMessage) => {
      if (currentMessage?.clientId) {
        return currentMessage?.clientId === globalMessage?.clientId || globalMessage?.boxId;
      }
      return false;
    });
    if (index >= 0) {
      dataTmp[index] = { ...globalMessage };
    } else {
      dataTmp.unshift(globalMessage);
    }
    setData(dataTmp);
    onScrollToBottom();
  }, [globalState.chat.message]);

  useEffect(() => {
    // set receiverID to local
    window.localStorage.setItem("receiverID", JSON.stringify(receiverID));
    // onScrollToBottom();
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
      window.localStorage.removeItem("receiverID");
    };
  }, []);

  if (isLoading) {
    return (
      <>
        <MessageHeader onClickRight={onOpenDrawer} userName={state?.userName} />
        <Box
          sx={{
            padding: "0px 1rem",
            height: "calc(100% - 56px)",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            maxWidth: WIDTH.fold,
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column-reverse",
              overflow: "auto",
              height: "calc(100vh - 120px)",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: COLORS.grey400,
                borderRadius: "5px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: COLORS.grey700,
              },
            }}
          >
            <MessageSkeleton typePosition={"left"} hasAfter={true} attachments={[{ id: 2 }]} />
            <MessageSkeleton
              typePosition={"right"}
              hasAfter={true}
              isSameTimeDay={false}
              isSameTimeMinute={false}
              content={"content"}
              type={TYPE_MESSAGE_CHAT.FILE}
              attachments={[
                {
                  id: "660f7f7a8d39efc2d5022cef",
                  originalName: "hinh-anh-con-tho-cute-30.jpg",
                  path: "https://pub-34f4a2d8a75448d2927eb5a4fe0d6e0f.r2.dev/dev/2024/04/05/f612b830-f012-1f52-9ca7-fbddc61b471c_1712291704.jpg",
                  rawPath: "2024/04/05/f612b830-f012-1f52-9ca7-fbddc61b471c_1712291704.jpg",
                  meta: null,
                },
              ]}
            />
            <MessageSkeleton
              typePosition={"left"}
              isSameTimeDay={true}
              isSameTimeMinute={true}
              content={"content"}
              type={TYPE_MESSAGE_CHAT.FILE}
              attachments={[
                {
                  id: "660f7f7a8d39efc2d5022cef",
                  originalName: "hinh-anh-con-tho-cute-30.jpg",
                  path: "https://pub-34f4a2d8a75448d2927eb5a4fe0d6e0f.r2.dev/dev/2024/04/05/f612b830-f012-1f52-9ca7-fbddc61b471c_1712291704.jpg",
                  rawPath: "2024/04/05/f612b830-f012-1f52-9ca7-fbddc61b471c_1712291704.jpg",
                  meta: null,
                },
                {
                  id: "660f7f7a8d39efc2d5022cef",
                  originalName: "hinh-anh-con-tho-cute-30.jpg",
                  path: "https://pub-34f4a2d8a75448d2927eb5a4fe0d6e0f.r2.dev/dev/2024/04/05/f612b830-f012-1f52-9ca7-fbddc61b471c_1712291704.jpg",
                  rawPath: "2024/04/05/f612b830-f012-1f52-9ca7-fbddc61b471c_1712291704.jpg",
                  meta: null,
                },
                {
                  id: "660f7f7a8d39efc2d5022cef",
                  originalName: "hinh-anh-con-tho-cute-30.jpg",
                  path: "https://pub-34f4a2d8a75448d2927eb5a4fe0d6e0f.r2.dev/dev/2024/04/05/f612b830-f012-1f52-9ca7-fbddc61b471c_1712291704.jpg",
                  rawPath: "2024/04/05/f612b830-f012-1f52-9ca7-fbddc61b471c_1712291704.jpg",
                  meta: null,
                },
              ]}
              hasAfter={true}
            />
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <Backdrop open={isLoading} sx={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame
            setOnDrawer={() => handleDrawer(false)}
            handleModal={handleDrawerClick}
            type={drawerType}
            // data={post}
            // refetch={handleDrawerRefetch}
            callBack={() => navigate(-1)}
          />
        </DrawerPortal>
      )}
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleOk={handleOk}
        handleCancel={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
      <MessageHeader onClickRight={onOpenDrawer} userName={state?.userName} />
      <Box
        sx={{
          padding: "0px 1rem",
          height: "calc(100% - 56px)",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          maxWidth: WIDTH.fold,
          margin: "0 auto",
        }}
      >
        <Box
          id='scrollableDiv'
          sx={{
            // height: 100,
            // overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
            overflow: "auto",
            height: "calc(100vh - 120px)",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              background: COLORS.grey400,
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: COLORS.grey700,
            },
          }}
        >
          {data?.length > 0 && (
            <InfiniteScroll
              dataLength={data?.length}
              next={fetchNextPage}
              hasMore={true}
              inverse={true}
              scrollableTarget='scrollableDiv'
              style={{ display: "flex", flexDirection: "column-reverse" }}
            >
              {data?.map((message, userIndex) => (
                <MessageItem
                  index={userIndex}
                  {...message}
                  // fetchData={fetchData}
                  key={userIndex}
                  messages={data}
                  localUser={localUser}
                  lastMessage={data[data.length - 1]}
                />
              ))}
            </InfiniteScroll>
          )}
        </Box>
        <MessageInput
          localUser={localUser}
          receiverId={receiverID}
          receiverName={conversationInfo?.aliasName}
          saveLocalMessage={saveLocalMessage}
          conversationInfo={conversationInfo}
        />
      </Box>
    </>
  );
}

export default MessageList;
