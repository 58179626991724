import { createGlobalStyle } from "styled-components";
import { COLORS, RADIUS, TEXTSIZE, WIDTH } from "./constants";
import reset from "styled-reset";
import "../assets/font/pretendard.css";

export const GlobalStyle = createGlobalStyle`
${reset}

/* @font-face {
  font-family: 'Pretendard-Bold';
  font-weight: 700;
  font-display: swap;
  src: url('../assets/font/woff2/Pretendard-Bold.woff2') format('font-woff2'), url('../assets/font/woff/Pretendard-Bold.woff') format('font-woff');
}

@font-face {
  font-family: 'Pretendard-semi';
  font-weight: 600;
  font-display: swap;
  src: url('../assets/font/woff2/Pretendard-SemiBold.woff2') format('font-woff2'), url('../assets/font/woff/Pretendard-SemiBold.woff') format('font-woff');
}

@font-face {
  font-family: 'Pretendard-Regular';
  font-weight: 400;
  font-display: swap;
  src: url('../assets/font/woff2/Pretendard-Regular.woff2') format('font-woff2'), url('../assets/font/woff/Pretendard-Regular.woff') format('font-woff');
} */

:root {
    --mainColor: ${(props) => props.theme.mainColor};
    --mainColorLight: ${(props) => props.theme.mainColorLight};
    --notification_error: ${(props) => props.theme.notification_error};
    --notification_errorLight: ${(props) =>
      props.theme.notification_errorLight};
    --notification_success: ${(props) => props.theme.notification_success};
    --notification_successLight: ${(props) =>
      props.theme.notification_successLight};
    --notification_alert: ${(props) => props.theme.notification_alert};
    --bgColor: ${(props) => props.theme.bgColor};
    --bglightColor: ${(props) => props.theme.bgLightColor};
    --textColor: ${(props) => props.theme.textColor};
    --subTextColor: ${(props) => props.theme.subTextColor};
    --color5toF3: ${(props) => props.theme.color5toF3};
    --color7toD: ${(props) => props.theme.color7toD};
    --color9toB: ${(props) => props.theme.color9toB};
    --colorDto5: ${(props) => props.theme.colorDto5};
    --colorD9to3: ${(props) => props.theme.colorD9to3};
    --colorF3to3: ${(props) => props.theme.colorF3to3};
    --color1CtoF: ${(props) => props.theme.color1CtoF};
    --color9to7: ${(props) => props.theme.color9to7};
    --color1Cto7: ${(props) => props.theme.color1Cto7};
  }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
menu,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: 'Pretendard', 'Pretendard-Regular','Apple SD Gothic Neo',Sans-serif;
  vertical-align: baseline;
  line-break: strict;
  word-break: keep-all;
  // line-height: 120%;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  position: relative;
  line-height: 1;
  color: ${(props) => props.theme.textColor};
  background-color: ${(props) => props.theme.bgColor};
  font-weight: 400;
  font-family: 'Pretendard-Regular';
  line-height: 1.2;
  margin: 0 auto;

  &.default {
    overflow: hidden;
    height: 100vh;
  }

  @media screen and (min-width: ${WIDTH.desktop}) {
    max-width: 1920px; 
  }
  @media screen and (max-width: ${WIDTH.tablet}) {
    max-width: 767px; 
  }
}

menu,
ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

#root {
   min-height: 100vh;
  // height: 100vh;
  // font-family: Pretendard-Regular;
  // z-index: 0;
  // position: relative;
  // min-width: 320px;
  // width: 100%;
  // min-height: calc(var(--vh, 1vh) * 100);
  // height: 100%;
  // margin: 0 auto;
  // overflow: hidden;

  // input:-webkit-autofill {
  //   -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.1) inset;
  //   border-radius: 28px;
  // }

  //   @media screen and (min-width: ${WIDTH.desktop}) {
  //     & {
  //       // display: flex;
  //       // overflow-y: auto;
  //     }
  //   }

  // .container {
  //   margin: 0 auto;
  //   height: 100%;

  //   @media screen and (min-width: ${WIDTH.desktop}) {
  //     max-width: calc(${WIDTH.desktop_contents} + 32px);
  //     width: 100%;
  //     // padding-top: 30px;
  //   }

  //   @media screen and (max-width: ${WIDTH.tablet}) {
  //     max-width: 767px; 
  //   }
  // }
}

#modal-root {
  z-index: 1300;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  width: 100vw;
  height: 100vh;
}

#drawer-root {
  z-index: 9999;
}

a {
  text-decoration: none;
  color: inherit;
  line-height: 100%;
}

img {
  width: 100%;
  object-fit: cover;
}

button {
  border: 0;
  background: transparent;
  padding: 0;
}

.button {cursor: pointer;}

// input {
//   border: 0;
//   box-shadow: none;
//   box-sizing: border-box !important;
//   font-size: ${TEXTSIZE.medium};
//   color: ${COLORS.black};

//   &::placeholfer {
//     color: ${COLORS.lightGray_b}
//   }
// }

// input ~ fieldset {
//   border: none !important;
// }

h2 {
  font-size: ${TEXTSIZE.large}
}

.swiper-pagination-fraction {
  display: flex;
  align-items: center;
  justify-content: center;
  left: auto;
  right: 12px;
  top: 12px;
  width: fit-content;
  height: fit-content;
  padding: 5px 11px;
  border-radius: 8px;
  background: rgba(51, 51, 51, 0.5);
  font-size: ${TEXTSIZE.xSmall};
  color: ${COLORS.white}
}

.Toastify__toast {
  height: 48px;
  background: var(--color1CtoF);
  opacity: 0.9;
  font-size: ${TEXTSIZE.small};
  color: var(--bgColor);
  font-weight: 600;
  border-radius: ${RADIUS.medium};
  box-sizing: border-box;
  min-height: 48px;
  bottom: 20px;

  @media screen and (max-width: ${WIDTH.desktop}) {
  .navigation + .Toastify & {
    bottom: 70px;
  }
}

  .Toastify__toast-body {
    margin: 0;
    padding: 0 12px;
  }
}

.MuiSkeleton-root {
  background-color: var(--colorF3to3) !important;
  transform: none !important;
}

  .icon-fill__main-color {
    fill: var(--mainColor);
  }
  .icon-stroke__main-color {
    stroke: var(--mainColor);
  }
  .icon-stroke__color1Cto7 {
    stroke: var(--color1Cto7);
  }
  .icon-fill__color1Cto7 {
    fill: var(--color1Cto7);
  }
  .icon-fill_color1CtoF {
    fill: var(--color1CtoF);
  }
  .icon-fill__color1CtoF {
    fill: var(--color1CtoF);
  }
  .icon-fill__text-color {
    fill: var(--textColor);
  }
  .icon-stroke__text-color {
    stroke: var(--textColor);
  }
  .icon-fill__subTextColor {
    fill: var(--subTextColor);
  }
  .icon-fill_bglightColor {
    fill: var(--bglightColor);
  }
  .icon-fill_bgColor {
    fill: var(--bgColor);
  }

  input[type="password"] {
    font: caption;
  }
`;
