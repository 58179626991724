import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Radio, RadioGroup, TextField } from "@mui/material";
import { COLORS, FONT_WEIGHT } from "../../theme/constants";
import { textMediumMuiStyle, inputMuiStyle } from "../../utils/commonStyle";
import styled from "styled-components";

const Wrapper = styled.div`
  .input-otp {
    height: 40px;
    width: 36px !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: ${COLORS.black1};
    border-radius: 4px;
    outline: ${COLORS.primary4} 1px solid;
    border: none;
  }
`;

export default function QuizAnswer(props) {
  const { setQuizAnswer, answers, answer_type, answer_length } = props;
  const [value, setValue] = useState("");

  // const [values, setValues] = useState([]);

  const handleChange = (event) => {
    const answer = event.target.value;
    setValue(answer);
    setQuizAnswer(answer);
  };

  // const handleValueChange = (answer) => {
  //   setValue(answer);
  //   setQuizAnswer(answer);
  // };

  return (
    <Wrapper>
      <FormControl component='fieldset' variant='standard' fullWidth>
        {answer_type == "multiChoice" && (
          <RadioGroup
            aria-labelledby='background-radio-buttons-group'
            name='controlled-radio-buttons-group'
            value={value}
            onChange={handleChange}
          >
            {answers?.map((item) => (
              <FormControlLabel
                key={item.id}
                value={item.id}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    ...textMediumMuiStyle,
                    fontWeight: FONT_WEIGHT.large,
                  },
                }}
                control={
                  <Radio
                    sx={{
                      color: "#904DFF",
                      "&.Mui-checked": {
                        color: "#904DFF",
                      },
                    }}
                  />
                }
                label={item.content}
              />
            ))}
          </RadioGroup>
        )}
        {answer_type == "text" && (
          // <OTPInput
          //   containerStyle={{
          //     flexWrap: "wrap",
          //     justifyContent: "center",
          //     gap: "12px 4px",
          //   }}
          //   inputStyle='input-otp'
          //   value={value}
          //   onChange={handleValueChange}
          //   numInputs={answer_length}
          //   renderInput={(props) => <input {...props} />}
          // />
          <TextField
            name='message'
            type='text'
            value={value}
            onChange={handleChange}
            placeholder='띄어쓰기 없이 입력해 주세요.'
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                backgroundColor: COLORS.white,
                border: `1px solid ${COLORS.primary4}`,
                borderRadius: "4px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: `1px solid ${COLORS.primary4}`,
                },
                "&:hover fieldset": {
                  border: `1px solid ${COLORS.primary4}`,
                },
                "&.Mui-focused fieldset": {
                  border: `1px solid ${COLORS.primary4}`,
                },
              },
            }}
            inputProps={{
              style: inputMuiStyle,
              sx: {
                "&::placeholder": {
                  color: COLORS.grey500,
                },
              },
            }}
          />
        )}
      </FormControl>
    </Wrapper>
  );
}
