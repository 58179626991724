const AUTH_LOCAL_STORAGE_KEY = "access_token";

// Function to get the user's timezone
function getUserTimezone() {
  // Get the timezone offset in minutes
  const offsetMinutes = new Date().getTimezoneOffset();

  // Convert the offset to hours and minutes
  const offsetHours = Math.abs(offsetMinutes / 60);
  const offsetMinutesPart = Math.abs(offsetMinutes % 60);

  // Determine the sign of the offset
  const sign = offsetMinutes > 0 ? "-" : "+";

  // Format the timezone as +/-HH:MM
  const timezone =
    sign +
    ("00" + offsetHours).slice(-2) +
    ":" +
    ("00" + offsetMinutesPart).slice(-2);

  return timezone;
}

const getAuth = () => {
  if (!localStorage) {
    return;
  }

  const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth = JSON.parse(lsValue);
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export function setupAxios(axios) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config) => {
      const auth = getAuth();
      // const lang = JSON.parse(localStorage.getItem("i18nConfig") || "null");

      config.headers[`lang`] = "kr";
      config.headers["User-Timezone"] = getUserTimezone();

      const token = auth;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      return Promise.reject(error)
      if (error?.response?.status) return error?.response;
    }
  );

  // axios.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },
  //   async function (error) {
  //     return error.response;
  //   }
  // );
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };
