import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { COLORS, RADIUS } from "../../theme/constants";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getUserWithdrawer, postUserWithdrawer } from "../../api";
import { getAnalytics, logEvent } from "firebase/analytics";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";

const Wrapper = styled.div`
  // min-height: 100vh;
  // height: 100%;

  .stepper {
    .MuiStepConnector-line {
      border-style: dotted;
    }

    .MuiSvgIcon-root {
      color: var(--bglightColor);
      .MuiStepIcon-text {
        fill: ${COLORS.grey600};
      }

      &.Mui-active,
      &.Mui-completed {
        color: ${COLORS.primary4};

        .MuiStepIcon-text {
          fill: ${COLORS.white};
        }
      }
    }
  }

  .button {
    // width: 120px;
    // height: 48px;
    padding: 0.75rem 3rem;
    border-radius: ${RADIUS.xxLarge};
    background: ${COLORS.grey50};
    color: ${COLORS.grey600};
    cursor: pointer;

    &:hover {
      background: ${COLORS.primary4};
      color: ${COLORS.white};
    }

    &.Mui-disabled {
      background: ${COLORS.grey100};
      color: ${COLORS.grey600};
      cursor: not-allowed;
    }
  }
`;

function Register() {
  const { state } = useLocation();
  const { logout } = useAuth();
  const { step } = state || {};
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [isDisabled, setIsDisabled] = useState(true);
  const [values, setValues] = useState({
    national: "",
    user_type: "",
    terms: [true, true, true],
    tax_type: 1,
    tax_username: "",
    personal_number_1: "",
    personal_number_2: "",
    phone_type: "",
    phone_number: "",
    bank: "",
    account_number: "",
    account_name: "",
  });
  const handleChange = (newData) => {
    // console.log("newData", newData);
    setValues({
      ...values,
      ...newData,
    });
  };
  const steps = [
    <Step1 handleChange={handleChange} data={values} key={activeStep} />,
    <Step2 handleChange={handleChange} data={values} key={activeStep} />,
    <Step3 handleChange={handleChange} data={values} key={activeStep} />,
    <Step4 handleChange={handleChange} data={values} key={activeStep} />,
  ];

  const analytics = getAnalytics();

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "register",
      firebase_screen_class: "register",
    });
  }, []);

  useEffect(() => {
    if (step){
      setActiveStep(step);
      handleGetUserWithdrawer();
    } 
  }, [step]);

  const handleNext = () => {
    let newSkipped = skipped;

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    // TODO: 계좌 인증 후 완료 페이지로 이동
    // console.log(values);
    // getBankAuthToken();
    
    try {
      const requestData = { ...values };
      delete requestData?.terms;
      delete requestData?.request_code;
      delete requestData?.amount;
      await postUserWithdrawer(requestData);
      navigate("/complete/register");
    } catch (error) {
      handleError(error, logout);
    }
  };

  const isDisabledNext = () => {
    switch (activeStep) {
      case 0:
        return !values.national || !values.user_type;
      case 1:
        return values?.terms.some((item) => !item);
      case 2:
        return (
          !values.tax_type ||
          !values.tax_username ||
          !values.personal_number_1 ||
          !values.personal_number_2 ||
          !values.phone_type ||
          !values.phone_number
        );
      case 3:
        return (
          !values.bank ||
          !values.account_name ||
          !values.account_number ||
          values.account_name !== values.tax_username
        );
      default:
        return false;
    }
  };

  // useEffect(() => {
  //   console.log("values Register", values);
  // }, [values]);

  const handleGetUserWithdrawer = async () => {
    try {
      const res = await getUserWithdrawer();
      if (res) {
        setValues({...values,...res[0]});
      }
    } catch (error) {
      handleError(error, logout);
    }
  };

  return (
    <Wrapper className="register">
      <Box
        key={activeStep}
        sx={{
          width: "100%",
          // minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          padding: "80px 0 16px",
        }}
      >
        <Stepper activeStep={activeStep} className="stepper">
          {steps.map((label, index) => {
            const stepProps = {};
            return (
              <Step key={index} {...stepProps}>
                <StepLabel></StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {steps.filter((step, index) => index === activeStep)}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            margin: "auto auto 0",
          }}
        >
          <Button
            className="button prev"
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            이전
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            disabled={isDisabledNext()}
            onClick={
              activeStep === steps.length - 1 ? handleSubmit : handleNext
            }
            className="button next"
          >
            다음
          </Button>
        </Box>
      </Box>
    </Wrapper>
  );
}

export default React.memo(Register);
