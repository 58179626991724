import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { RADIUS } from "../../../../theme/constants";
import styled from "styled-components";
import ReactPlayer from "react-player/file";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { GlobalStateContext } from "../../../../context/GlobalStateProvider";
import { ReactComponent as VolumeOn } from "../../../../assets/svgs/volume_on.svg";
import { ReactComponent as VolumeOff } from "../../../../assets/svgs/volume_off.svg";
import VideoControls from "../../../media/Controls";
import { ReactComponent as IconPlay } from "../../../../assets/svgs/play.svg";
import { ReactComponent as IconPause } from "../../../../assets/svgs/pause.svg";

const Wrapper = styled.div`
  aspect-ratio: 1;
  .bg-img {
    border-radius: ${RADIUS.large};
    width: 100%;
    height: 100%;
    video {
      border-radius: ${RADIUS.large};
    }
  }
`;

function MyFeedMediaVideo(props) {
  const { data, isPlay, onInView, onClickMedia, onOutView } = props;
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  // const [isMuted, setIsMuted] = useState(true);
  const playerRef = React.useRef();

  const location = useLocation();

  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [durationSeconds, setDurationSeconds] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleMuted = () => {
    // setIsMuted(!isMuted);
    setGlobalState({
      ...globalState,
      video: { ...globalState.video, isMuted: !globalState?.video?.isMuted },
    });
  };

  useEffect(() => {
    if (inView) {
      onInView();
    } else {
      onOutView();
    }
  }, [inView]);

  useEffect(() => {
    if (isPlay) {
      playerRef.current.seekTo(0, "seconds");
    }
    setPlaying(isPlay);
  }, [isPlay]);

  return (
    <Wrapper
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Box className="bg-img" sx={{ backgroundColor: "black" }} ref={ref}>
        <Box onClick={onClickMedia} sx={{ width: "100%", height: "100%" }}>
          <ReactPlayer
            id={`react-player-${data?.id}`}
            // controls={false}
            // controls={location?.pathname.includes("detail")}
            ref={playerRef}
            width="100%"
            height="100%"
            playing={playing}
            className="bg-img react-player"
            url={data?.url}
            loop={true}
            muted={globalState?.video?.isMuted}
            // onReady={onReady}

            onProgress={({ playedSeconds }) => setPlayedSeconds(playedSeconds)}
            onSeek={setPlayedSeconds}
            onDuration={setDurationSeconds} // This is called when the player has the duration
          />
        </Box>
        {/* {location?.pathname.includes("detail") && ( */}
          <>
            <Box
              sx={{
                position: "absolute",
                background: "transparent",
                zIndex: 10,
                width: "100%",
                bottom: 0,
                overflow: "hidden",
              }}
            >
              <VideoControls
                playerRef={playerRef}
                playing={isPlay}
                // setPlaying={setPlaying}
                playedSeconds={playedSeconds}
                duration={durationSeconds}
              />
            </Box>
            {isHover && (
              <Box
                sx={{
                  color: "white",
                  position: "absolute",
                  top: "calc(50% - 20px)",
                  left: "calc(50% - 20px)",
                  zIndex: 9,
                  cursor: "pointer",
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                  backdropFilter: "blur(4px)",
                  p: 1,
                  borderRadius: "50%",
                  height: "40px",
                }}
                onClick={() => setPlaying(!playing)}
              >
                {playing ? <IconPause /> : <IconPlay />}
              </Box>
            )}
          </>
        {/* )} */}
        {/* {!location?.pathname.includes("detail") && ( */}
        <Box
          sx={{
            color: "white",
            position: "absolute",
            bottom: 20,
            left: 10,
            zIndex: 10,
            cursor: "pointer",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            p: 0.25,
            height: "28px",
            borderRadius: RADIUS.medium,
          }}
        >
          {globalState?.video?.isMuted ? (
            <VolumeOff onClick={handleMuted} />
          ) : (
            <VolumeOn onClick={handleMuted} />
          )}
        </Box>
        {/* )} */}
      </Box>
    </Wrapper>
  );
}

export default MyFeedMediaVideo;
