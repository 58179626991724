import arrowRightImage from "@images/arrowRight.svg";
import { Box, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 40px)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "16px",
  padding: "24px 20px",
  textAlign: "center",
};

export default function Feedback() {
  const navigate = useNavigate();
  const [errorOpen, setErrorOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("delete-instagram");

  console.log(errorOpen);

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          //   maxWidth: "360px",
          margin: "auto",
          backgroundColor: "white",
          wordBreak: "keep-all",
          color: "#1E1E1E",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            padding: "16px",
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <div className="icon-wrap" onClick={() => navigate("/")}>
            <img
              src={arrowRightImage}
              style={{
                transform: "rotate(180deg)",
                width: "24px",
                height: "24px",
              }}
            />
          </div>

          <div style={{ flex: 1, textAlign: "center" }}>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "24px",
              }}
            >
              Feedback Form
            </p>
          </div>

          <div
            style={{
              width: "24px",
              height: "24px",
            }}
          ></div>
        </div>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <div style={{ flex: 1 }}>
            <div style={{ padding: "24px 20px 20px" }}>
              <div>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Email
                </p>

                <input
                  type="email"
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #D9D9D9",
                    width: "100%",
                    marginTop: "8px",
                  }}
                  placeholder="example@email.com"
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Phone Number
                </p>

                <input
                  type="text"
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #D9D9D9",
                    width: "100%",
                    marginTop: "8px",
                  }}
                  placeholder="Please input your phone number"
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Your Account ID
                </p>

                <input
                  type="text"
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #D9D9D9",
                    width: "100%",
                    marginTop: "8px",
                  }}
                  placeholder="Please input your Account ID"
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Full Name
                </p>

                <input
                  type="text"
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #D9D9D9",
                    width: "100%",
                    marginTop: "8px",
                  }}
                  placeholder="Please input your full name"
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Issue Type
                </p>

                <select
                  type="text"
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #D9D9D9",
                    width: "100%",
                    marginTop: "8px",
                  }}
                  placeholder="계정 이름을 입력해 주세요."
                  onChange={(e) => setSelectedValue(e?.target?.value)}
                >
                  <option value="delete-instagram">
                    Delete Instagram Account
                  </option>
                  <option value="check-data">Check Data</option>
                  <option value="clarify-information">
                    Clarify Information
                  </option>
                </select>
              </div>

              {selectedValue == "delete-instagram" && (
                <div style={{ marginTop: "20px" }}>
                  <p
                    style={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: 500,
                    }}
                  >
                    Your Instagram Account
                  </p>

                  <input
                    type="text"
                    style={{
                      padding: "12px",
                      borderRadius: "8px",
                      border: "1px solid #D9D9D9",
                      width: "100%",
                      marginTop: "8px",
                    }}
                    placeholder="Please input your instagram account, it will be deleted permanently"
                  />
                </div>
              )}

              <div style={{ marginTop: "20px" }}>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Description
                </p>

                <textarea
                  type="text"
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #D9D9D9",
                    width: "100%",
                    marginTop: "8px",
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Attachment
                </p>

                <input
                  type="file"
                  name="filefield"
                  multiple="multiple"
                  style={{
                    paddingTop: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "14px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ padding: "20px" }}>
            <button
              onClick={() => setErrorOpen(true)}
              style={{
                borderRadius: "8px",
                padding: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "#FFF",
                backgroundColor: "#1E1E1E",
                width: "100%",
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      <Modal
        open={errorOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ width: 600 }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ fontSize: "16px", fontWeight: 700, lineHeight: "24px" }}
          >
            Confirm
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: "16px",
              fontSize: "14px",
              lineHeight: "22px",
              fontWeight: 400,
            }}
          >
            Information has been received, we will contact you directly for
            support.
          </Typography>
          <Typography
            sx={{
              mt: "24px",
              borderRadius: "8px",
              padding: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              color: "#FFF",
              backgroundColor: "#1E1E1E",
              width: "100%",
            }}
            onClick={() => setErrorOpen(false)}
          >
            Close
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
