import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import {
  COLORS,
  FONT_WEIGHT,
  ICON_SIZE,
} from "../../theme/constants";
import {
  textMediumMuiStyle,
  truncateStyle,
} from "../../utils/commonStyle";
// import { getParticipantExternal } from "../../api";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../components/modal/ModalComponent";
import { ReactComponent as MessageIcon } from "@icons/message.svg";
import ImageAvatar from "@images/avatar.png";
import { getParticipantExternal } from "../../service/chat_services";

const Wrapper = styled.div``;

const WrapperImg = styled.img`
  aspect-ratio: 1;
  border-radius: 4px;
  width: 36px;
  height: 36px;
`;

function NewChatUserItem(props) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { receiverID = "", nickname = "", profile_image_url = "" } = props;

  const [modalData, setModalData] = useState({
    open: false,
    type: "",
    btnOKText: "",
    btnCancelText: "",
    title: "",
    subTitle: "",
    content: "",
  });

  const redirectToProfile = () => {
    // navigate(`/user-profile/${nickname}`);
  };

  const onClickContent = async () => {
    // get conversation information
    try {
      const res = await getParticipantExternal(receiverID, {});
      navigate(`/chat/detail/${res?.id}`, {state: {userName: res?.name}});
    } catch (error) {
      console.log('error', error)
      handleError(error, logout, navigate);
    }
  };

  //modal action
  const handleOk = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleCancel = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
  };

  return (
    <>
      <Wrapper>
        <ListItem
          sx={{
            p: 0,
            my: "1.5rem",
            alignItems: "center",
            "& .MuiListItemSecondaryAction-root": {
              right: "0px",
            },
          }}
          secondaryAction={
            // <Typography sx={textSmallMuiStyle}>
            //   {dateTimeFormat(dayjs(timestamp), "HH:mm")}
            // </Typography>
            <MessageIcon
              style={{ stroke: COLORS.grey500, cursor: "pointer" }}
              onClick={onClickContent}
            />
          }
        >
          <ListItemAvatar
            sx={{
              minWidth: ICON_SIZE.large,
              mr: 1,
              my: 0,
              cursor: "pointer",
            }}
            onClick={redirectToProfile}
          >
            <Avatar
              alt=""
              src={profile_image_url || ImageAvatar}
              sx={{
                width: ICON_SIZE.large,
                height: ICON_SIZE.large,
              }}
            />
          </ListItemAvatar>
          <ListItemText
            onClick={onClickContent}
            // primary={`${nickname} 님이 나를 커넥트 했습니다.`}
            primary={`${nickname}`}
            sx={{
              my: 0,
              "& .MuiListItemText-primary": {
                ...textMediumMuiStyle,
                ...truncateStyle,
                width: "80%",
                cursor: "pointer",
                fontWeight: FONT_WEIGHT.large,
              },
            }}
            secondaryTypographyProps={{ component: "div" }}
            // secondary={
            //   <Typography
            //     sx={{
            //       ...textSmallMuiStyle,
            //       ...truncateStyle,
            //       fontWeight: FONT_WEIGHT.medium,
            //       lineHeight: LINE_HEIGHT.xLarge,
            //       width: "70%",
            //       cursor: "pointer",
            //     }}
            //   >
            //     {content}
            //   </Typography>
            // }
          />
        </ListItem>
      </Wrapper>
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => handleClose()}
        handleOk={() => handleOk()}
        handleCancel={() => handleCancel()}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
    </>
  );
}

export default NewChatUserItem;
