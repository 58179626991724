import { Avatar, Box, Button, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  blockPost,
  createConnection,
  createReport,
  deleteConnection,
  deletePost,
  editViewBuyer,
  getUserBalance,
  unlockOrDonatePost,
} from "../../api";
import MyFeedMedia from "@components/newComponents/posts/MyFeed/MyFeedMedia";
import { ReactComponent as MoreIcon } from "@icons/more.svg";
import { ICON_SIZE, COLORS, LINE_HEIGHT, TEXTSIZE, FONT_WEIGHT, RADIUS } from "@theme/constants";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";
import PICComponent from "@components/newComponents/posts/PICComponent";
import CommentComponent from "@components/newComponents/posts/CommentComponent";
import PayDetail from "../purchase/PayDetail";
import ModalComponent from "@components/modal/ModalComponent";
import Report from "@components/newComponents/report";
import { toast } from "react-toastify";
import styled from "styled-components";
import { relativeTimeKorean } from "@utils/dateTimeFormat";
import TruncateText from "@components/newComponents/truncate/TruncateText";
import { handleError, isMine, numberWithCommas } from "@utils/commonFunction";
import {
  textMediumMuiStyle,
  textSmallMuiStyle,
  truncateStyle,
  textLargeMuiStyle,
} from "@utils/commonStyle";
import { useAuth } from "../../context/Auth";
import MyFeedMediaVideo from "@components/newComponents/posts/MyFeed/MyFeedMediaVideo";
import ImageAvatar from "@images/avatar.png";
import HomeFeedOverview from "@components/newComponents/posts/HomeFeedOverview";
import RememberPurchase from "../../components/modal/RememberPurchase";
import { useTranslation } from "react-i18next";
import { GlobalStateContext } from "../../context/GlobalStateProvider";
import ButtonConnect from "../../components/connect/ButtonConnect";
import Author from "../author/Author";

const Wrapper = styled.div``;

function MarketDetailItem(props) {
  const {
    indexPost,
    post,
    prevPost,
    fetchData,
    isPostInView = true,
    onVideoInView = () => {},
    onVideoOutView = () => {},
    handleScrollTo,
    handleConnectSuccess = () => {},
  } = props;
  const params = useParams();
  const { logout, user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkedPurchase, setCheckedPurchase] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { selectedPost, setSelectedPost } = useContext(GlobalStateContext);

  //modal
  const [reasons, setReasons] = useState([]);
  const [modalData, setModalData] = useState({
    open: false,
    btnOKText: "",
    btnCancelText: "",
    title: "",
    subTitle: "",
    content: "",
  });

  // Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("list");
  const [openPayment, setOpenPayment] = useState(false);
  //
  // const [user, setUser] = useState(
  //   JSON.parse(window.localStorage.getItem("user"))
  // );

  const handleMore = () => {
    setDrawerType("list");
    handleDrawer(true);
  };

  // begin donate
  const onClickHeart = () => {
    // if (isMine(post?.user_profile?.user_id)) {
    //   navigate(`/post/${post.id}/sponsors/PIC MEMBER LIST`);
    // } else {
    //   setDrawerType("sendpic");
    //   handleDrawer(true);
    // }
    if (post?.cost === 0 && !isMine(post?.user_profile?.user_id)) {
      setDrawerType("sendpic");
      handleDrawer(true);
    }
  };

  const handleDrawerRefetch = () => {
    fetchData();
  };

  // end donate

  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  const handleModal = (type = "select") => {
    if (type === "report") {
      setModalData({
        ...modalData,
        type: "report",
        open: true,
        btnOKText: t("button.declaration"),
        btnCancelText: t("button.cancel"),
        title: t("dialog.title.report"),
        subTitle: t("dialog.subTitle.report"),
        content: <Report onChangeReason={setReasons} />,
      });
    }

    if (type === "delete") {
      if (!post.can_delete) {
        setModalData({
          ...modalData,
          type: "delete",
          open: true,
          title:
            post?.cost > 0
              ? t("dialog.title.can_not_delete_post", { emotion: "(:" })
              : t("dialog.title.can_not_delete_post"),
          subTitle: "",
          btnOKText: "확인",
          btnCancelText: "",
          content: (
            <Typography
              sx={{
                ...textMediumMuiStyle,
                color: COLORS.grey900,
                lineHeight: LINE_HEIGHT.xxxLarge,
                whiteSpace: "pre-line",
              }}
            >
              {post?.cost > 0
                ? t("dialog.content.can_not_delete_purchased_post")
                : t("dialog.content.can_not_delete_sponsored_post")}
            </Typography>
          ),
        });
      } else {
        setModalData({
          ...modalData,
          type: "delete",
          open: true,
          title: t("dialog.title.delete_post"),
          subTitle: "",
          btnOKText: t("button.delete"),
          btnCancelText: t("button.cancel"),
          content: (
            <Typography
              sx={{
                ...textMediumMuiStyle,
                color: COLORS.grey900,
                lineHeight: LINE_HEIGHT.xxxLarge,
                whiteSpace: "pre-line",
              }}
            >
              {/* 게시물은 한 번 삭제하시면 되돌릴 수 없어요. */}
              {t("dialog.content.delete_post")}
            </Typography>
          ),
        });
      }
    }

    if (type === "edit") {
      if (!post.can_edit) {
        setModalData({
          ...modalData,
          type: "edit",
          open: true,
          title: t("dialog.title.can_not_edit_post"),
          subTitle: "",
          btnOKText: t("button.check"),
          btnCancelText: "",
          content: (
            <Typography
              sx={{
                ...textMediumMuiStyle,
                color: COLORS.grey900,
                lineHeight: LINE_HEIGHT.xxxLarge,
                whiteSpace: "pre-line",
              }}
            >
              {post?.cost > 0
                ? t("dialog.content.purchased_post")
                : t("dialog.content.sponsored_post")}
            </Typography>
          ),
        });
      } else {
        handleEditPost();
      }
    }

    if (type === "connect") {
      handleConnect();
    }

    if (type === "hide") {
      handleHidePost();
    }

    if (type === "share") {
      navigator.clipboard.writeText(`${window.location.origin}/market/${post.id}`);
    }

    if (type === "edit_is_view_buyer") {
      handleUpdateViewBuyer();
    }
  };

  const handleOpenModalErrorDelete = (value) => {
    setModalData({
      type: "error",
      open: true,
      title:
        post?.cost > 0
          ? t("dialog.title.can_not_delete_post", { emotion: "(:" })
          : t("dialog.title.can_not_delete_post"),
      subTitle: "",
      btnOKText: "확인",
      btnCancelText: "",
      content: (
        <Typography
          sx={{
            ...textMediumMuiStyle,
            color: COLORS.grey900,
            lineHeight: LINE_HEIGHT.xxxLarge,
            whiteSpace: "pre-line",
          }}
        >
          {value}
        </Typography>
      ),
    });
  };

  const handleDeletePost = async () => {
    try {
      await deletePost(post.id);
      // if (selectedPost?.from) {
      //   setSelectedPost({
      //     id: prevPost.id,
      //     index: indexPost - 1,
      //     from: selectedPost.from,
      //     nickname: "",
      //   });
      //   navigate(`/details`, {
      //     replace: true,
      //   });
      // } else {
      navigate(-1, { replace: true });
      // }
    } catch (error) {
      const errorResponse = handleError(error, logout, navigate);
      handleOpenModalErrorDelete(errorResponse?.response?.data?.message);
    }
  };

  const handleHidePost = async () => {
    try {
      await blockPost(post.id, { reason: "This is block reason." });
      toast(t("hidden_post"));
      // if (selectedPost?.from) {
      //   setSelectedPost({
      //     id: prevPost.id,
      //     index: indexPost - 1,
      //     from: selectedPost.from,
      //     nickname: "",
      //   });
      //   navigate(`/details`, {
      //     replace: true,
      //   });
      // } else {
      navigate(-1, { replace: true });
      // }
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const handleEditPost = () => {
    setSelectedPost({
      id: post?.id,
      index: indexPost,
      from: selectedPost?.from,
    });
    // if (selectedPost?.from) {
    //   navigate(
    //     `/post/edit`,
    //     { state: { post: post } }
    //   );
    // } else {
    navigate(`/post/edit`, { state: { post: post } });
    // }
  };

  const handleReportPost = async () => {
    try {
      const requestReason = reasons
        .filter((value) => value.checked)
        .map((item) => {
          return { reason: item.reason, type: item.type };
        });
      const requestData = {
        target: "post",
        target_id: post.id,
        reasons: requestReason,
      };
      await createReport(requestData);
      toast(t("report_completed"));
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  // update view buyer
  const handleUpdateViewBuyer = async () => {
    try {
      const requestData = {
        is_view_buyer: post?.is_view_buyer === 0 ? 1 : 0,
      };
      await editViewBuyer(post?.id, requestData);
      toast(post?.is_view_buyer === 1 ? t("buyer_has_been_private") : t("buyer_has_been_public"));
      fetchData();
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  //modal action
  const handleOk = () => {
    // setModalData({ ...modalData, open: false });
    handleClose();

    switch (modalData.type) {
      case "report":
        handleReportPost();
        break;
      case "delete":
        if (post.can_delete) {
          handleDeletePost();
        }
        break;
      case "purchase":
        handleConfirmPurchase();
        break;
      case "purchaseSuccess":
        fetchData();
        // setSelectedPost({
        //   id: post?.id,
        //   index: indexPost,
        //   from: selectedPost.from,
        //   nickname: "",
        // });
        // navigate(`/details`, {
        //   replace: true,
        // });
        break;
      case "notEnoughUserPIC":
        const drawerRoot = document.getElementById("drawer-root");
        drawerRoot.style.display = "block";
        setOpenPayment(true);
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
    if (modalData.type === "purchaseSuccess") {
      fetchData();
    }
  };

  const isDisableBtnOk = () => {
    let result = false;
    if (
      modalData.type === "report" &&
      reasons
        .filter((value) => value.checked)
        .map((item) => {
          return { reason: item.reason, type: item.type };
        }).length === 0
    )
      result = true;
    return result;
  };
  //end modal action

  // Purchase
  const openModalConfirm = (type, title, description) => {
    setModalData({
      ...modalData,
      type: type,
      open: true,
      title: title,
      subTitle: "",
      btnOKText: "확인",
      btnCancelText: "",
      content: (
        <Typography
          sx={{
            ...textMediumMuiStyle,
            color: COLORS.grey900,
            lineHeight: LINE_HEIGHT.xxxLarge,
            whiteSpace: "pre-line",
          }}
        >
          {description}
        </Typography>
      ),
    });
  };

  const handlePurchase = () => {
    if (localStorage.getItem("isRememberPurchase") === "true") {
      handleConfirmPurchase();
      return;
    }
    setModalData({
      ...modalData,
      type: "purchase",
      open: true,
      btnOKText: t("button.check"),
      btnCancelText: t("button.cancel"),
      title: t("dialog.title.purchase", { cost: post.cost }),
      subTitle: "",
      content: <RememberPurchase setCheckedPurchase={setCheckedPurchase} />,
    });
  };

  const handleConfirmPurchase = async () => {
    localStorage.setItem("isRememberPurchase", checkedPurchase);
    try {
      const user = await getUserBalance();
      if (user?.balance < post.cost) {
        setModalData({
          ...modalData,
          type: "notEnoughUserPIC",
          open: true,
          title: t("dialog.title.purchase_not_enough"),
          subTitle: "",
          btnOKText: t("button.charge"),
          btnCancelText: t("button.cancel"),
          content: (
            <Typography
              sx={{
                ...textMediumMuiStyle,
                color: COLORS.grey900,
                lineHeight: LINE_HEIGHT.xxxLarge,
                whiteSpace: "pre-line",
              }}
            >
              {t("dialog.content.purchase_not_enough")}
            </Typography>
          ),
        });
        return;
      }
      await unlockOrDonatePost(post.id, {
        pic: post.cost,
        type: "unlock",
      });
      openModalConfirm(
        "purchaseSuccess",
        t("dialog.title.purchase_completed"),
        t("dialog.content.purchase_completed")
      );
    } catch (error) {
      // switch (error?.response?.data?.code) {
      //   case 400:
      //     toast(error?.response?.data?.message);
      //     break;
      //   default:
      //     toast("error");
      //     break;
      // }
      const errorMessage = handleError(error, logout, navigate);
      toast(errorMessage);
      // openModalConfirm(
      //   "purchaseError",
      //   "알림",
      //   "구매에 오류가 발생했습니다. 다시 시도해주세요!"
      // );
    }
  };

  //connection action
  const handleConnect = async () => {
    try {
      if (post.connection) {
        await deleteConnection(post?.user_profile?.user_id);
        toast(t("disconnected_successfully"));
      } else {
        await createConnection({ to_user_id: post?.user_profile?.user_id });
        toast(t("connected_successfully"));
      }
      fetchData();
    } catch (error) {
      handleError(error, logout);
    }
  };

  const onRedirectToMemberList = () => {
    if (!isMine(post?.user_profile?.user_id) && post?.is_view_buyer == 0) return;

    if (post?.cost === 0) {
      navigate(`/post/${post.id}/sponsors/donate`);
    } else {
      navigate(`/post/${post.id}/sponsors/unlock`);
    }
  };

  return (
    <>
      <Box sx={{ minHeight: "calc(100vh - 130px)" }}>
        {drawerOpen && (
          <DrawerPortal>
            <DrawerFrame
              setOnDrawer={() => handleDrawer(false)}
              handleModal={handleModal}
              type={drawerType}
              data={post}
              refetch={handleDrawerRefetch}
              callBack={() => navigate(-1)}
            />
          </DrawerPortal>
        )}

        <ModalComponent
          open={modalData.open}
          modalData={modalData}
          handleClose={() => handleClose()}
          handleOk={() => handleOk()}
          handleCancel={() => handleCancel()}
          btnOKProps={{
            disabled: isDisableBtnOk(),
          }}
          btnCancelProps={{}}
        />

        {post && (
          <Box sx={{ fontSize: TEXTSIZE.medium, color: COLORS.black1, pb: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Author
                authorData={{
                  id: post?.user_profile?.user_id,
                  avatar: post?.user_profile?.profile_image_url,
                  name: post?.user_profile?.nickname,
                  job: post?.user_profile?.introduce,
                }}
                authorStyle={{
                  width: "calc(100% - 90px)",
                  gap: "1rem",
                }}
                avatarStyle={{
                  width: ICON_SIZE.large,
                  height: ICON_SIZE.large,
                }}
                nameStyle={{
                  color: COLORS.black1,
                  fontSize: TEXTSIZE.medium,
                  lineHeight: LINE_HEIGHT.xLarge,
                }}
                jobStyle={{
                  color: COLORS.primary4,
                  fontSize: TEXTSIZE.medium,
                  lineHeight: LINE_HEIGHT.xLarge,
                }}
              />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {!isMine(post?.user_profile?.user_id) && (
                  <ButtonConnect
                    isConnect={!!post?.connection}
                    userId={post?.user_profile?.user_id}
                    buttonProps={{ marginRight: "1rem", height: "20px" }}
                    handleConnectSuccess={handleConnectSuccess}
                    textCancelConnect={t("button.connecting")}
                    textConnect={t("button.connect")}
                  />
                )}
                <Box
                  sx={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                  }}
                >
                  <MoreIcon onClick={() => handleMore()} />
                </Box>
              </Box>
            </Box>
            {/* {post.cost > 0 &&
          !post.is_paid &&
          !isMine(post?.user_profile?.user_id) ? (
            <Box sx={{ position: "relative" }}>
              <MyFeedMedia data={post.files?.[0]} post={post}/>
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  bottom: "1rem",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <HomeFeedOverview post={post} />
                </Box>
              </Box>
            </Box>
          ) : ( */}
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              pagination={post?.files?.length > 1 && post?.is_paid && { type: "fraction" }}
              modules={[Pagination]}
              onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            >
              {post?.files?.length > 0 &&
                post.files.map((media, indexMedia) => (
                  <SwiperSlide key={media.id}>
                    <Box sx={{ width: "100%" }}>
                      {media?.type === "video" ? (
                        <MyFeedMediaVideo
                          data={media}
                          onInView={onVideoInView}
                          onOutView={onVideoOutView}
                          isPlay={activeIndex === indexMedia && isPostInView}
                          onClickMedia={() => {
                            if (activeIndex === indexMedia) {
                              // screenfull.request(
                              //   document.querySelector(
                              //     `#react-player-${media?.id}`
                              //   )
                              // );
                              // setActiveIndex();
                            } else {
                              setActiveIndex(indexMedia);
                            }
                          }}
                        />
                      ) : (
                        <MyFeedMedia data={media} index={indexMedia} post={post} />
                      )}
                      <Box
                        sx={{
                          position: "absolute",
                          zIndex: 2,
                          bottom: "1rem",
                          width: "100%",
                        }}
                      >
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <HomeFeedOverview post={post} />
                        </Box>
                      </Box>

                      {post?.user_profile?.user_id != user.id &&
                        post?.cost > 0 &&
                        !post?.is_paid && (
                          <>
                            {post?.type === 2 && (
                              <Box
                                sx={{
                                  position: "absolute",
                                  zIndex: 2,
                                  right: "0.75rem",
                                  top: "0.75rem",
                                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  borderRadius: RADIUS.medium,
                                  padding: "6px 0.5rem",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    lineHeight: "16px",
                                    color: COLORS.white,
                                  }}
                                >
                                  {`잔여수량 ${numberWithCommas(
                                    post?.limited - post?.paid_posts_count
                                  )}`}
                                </Typography>
                              </Box>
                            )}
                            {indexMedia != 0 && (
                              <PayDetail cost={post.cost} handlePurchase={handlePurchase} />
                            )}
                          </>
                        )}
                    </Box>
                  </SwiperSlide>
                ))}
            </Swiper>
            {/* )} */}

            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pb: 1,
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ mr: 2 }} onClick={onClickHeart}>
                    <PICComponent post={post} page='post/detail' />
                  </Box>
                  <Link to={`/comment/${post.id}/${post?.user_profile?.nickname}님의 게시물`}>
                    <CommentComponent
                      post={post}
                      handleClickComment={() => console.log()}
                      styleBox={{}}
                      styleIcon={{ fill: COLORS.grey500 }}
                      styleText={{
                        // color: post?.is_paid ? COLORS.primary6 : COLORS.black1,
                        color: COLORS.black1,
                        fontSize: TEXTSIZE.small,
                      }}
                    />
                  </Link>
                </Box>
                <Typography sx={textSmallMuiStyle}>
                  {relativeTimeKorean(post.created_at)}
                </Typography>
              </Box>

              {/* alway show when post has fee 
            if post don't has fee only show for owner */}
              {post?.latest_paid &&
                (post?.cost > 0 || (post?.cost === 0 && isMine(post?.user_profile?.user_id))) && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      sx={{
                        width: "14px",
                        height: "14px",
                        mr: 0.5,
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigate(
                          isMine(post?.latest_paid?.user_id)
                            ? `/profile`
                            : `/user-profile/${post?.latest_paid?.nickname}`
                        )
                      }
                      src={post?.latest_paid?.profile_image_url || ImageAvatar}
                    />
                    <Link
                      to={
                        isMine(post?.latest_paid?.user_id)
                          ? `/profile`
                          : `/user-profile/${post?.latest_paid?.nickname}`
                      }
                    >
                      <Typography
                        component={"span"}
                        sx={{
                          ...textMediumMuiStyle,
                          fontWeight: FONT_WEIGHT.xLarge,
                        }}
                      >
                        {post?.latest_paid?.nickname}
                      </Typography>
                      <Typography component={"span"} sx={textMediumMuiStyle}>
                        님 외
                      </Typography>
                    </Link>
                    <Box
                      onClick={onRedirectToMemberList}
                      sx={{
                        cursor:
                          isMine(post?.user_profile?.user_id) || post?.is_view_buyer == 1
                            ? "pointer"
                            : "default",
                      }}
                    >
                      {/* <Link
                    to={
                      post?.cost === 0
                        ? `/post/${post.id}/sponsors/donate/DONATE MEMBER LIST`
                        : `/post/${post.id}/sponsors/unlock/PIC MEMBER LIST`
                    }
                  > */}
                      <Typography
                        component={"span"}
                        sx={{
                          ...textMediumMuiStyle,
                          ml: 0.5,
                          fontWeight: FONT_WEIGHT.xLarge,
                        }}
                      >
                        {post?.cost > 0 && post?.paid_posts_count > 1 && post?.paid_posts_count - 1}
                      </Typography>
                      <Typography component={"span"} sx={{ ...textMediumMuiStyle }}>
                        {post?.cost > 0 &&
                          post?.paid_posts_count > 1 &&
                          t("people_purchased_content")}

                        {post?.cost > 0 && post?.paid_posts_count === 1 && t("i_purchased_content")}

                        {post?.cost === 0 && t("sent_a_pic_to_your_content")}
                      </Typography>
                      {/* </Link> */}
                    </Box>
                  </Box>
                )}
              <Typography sx={{ ...textLargeMuiStyle }}>{post?.title}</Typography>
              <TruncateText content={post?.contents} breakLine={true} />

              {/* comment */}
              <Box sx={{ mt: 0.5 }}>
                <Link to={`/comment/${post.id}/${post.user_profile.nickname}님의 게시물`}>
                  <Typography
                    sx={{
                      ...textMediumMuiStyle,
                      color: COLORS.grey400,
                      cursor: "pointer",
                      textAlign: "left",
                    }}
                  >
                    {t("see_more_comments")}
                  </Typography>
                </Link>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      ...textMediumMuiStyle,
                      ...truncateStyle,
                      fontWeight: FONT_WEIGHT.large,
                      lineHeight: LINE_HEIGHT.xxxLarge,
                      maxWidth: "50%",
                      width: "auto",
                      marginRight: "0.5rem",
                    }}
                  >
                    {post?.comments?.[0]?.user_profile?.nickname}
                  </Typography>
                  <Typography
                    sx={{
                      ...textMediumMuiStyle,
                      ...truncateStyle,
                      lineHeight: LINE_HEIGHT.xxxLarge,
                      maxWidth: "50%",
                    }}
                  >
                    {post?.comments?.[0]?.comment}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <Box sx={{ position: "sticky", bottom: 0, zIndex: 1 }}>
        <Button
          sx={{
            mt: 2,
            p: 2,
            borderRadius: 0,
            width: "100%",
            backgroundColor: COLORS.primary4,
            color: COLORS.white,
            boxShadow: "none",
            "&:hover": {
              backgroundColor: COLORS.primary4,
              boxShadow: "none",
            },
            "&.Mui-disabled": {
              backgroundColor: COLORS.grey100,
              color: COLORS.grey600,
            },
          }}
          variant='contained'
          size='large'
          className='submit'
          onClick={handlePurchase}
          disabled={
            post?.is_paid ||
            isMine(post?.user_profile?.user_id) ||
            post?.limited == post?.paid_posts_count
          }
        >
          {t("button.purchase")}
        </Button>
      </Box>
      {openPayment && (
        <DrawerPortal>
          <DrawerFrame
            setOnDrawer={() => {
              setOpenPayment(false);
              handlePurchase();
            }}
            handleModal={() => {}}
            type={"payment"}
          />
        </DrawerPortal>
      )}
    </>
  );
}

export default MarketDetailItem;
