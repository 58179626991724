import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { Box, List, Skeleton, Typography } from "@mui/material";
import NoData from "../../components/NoData";
import CircularComponent from "../process/CircularComponent";
import ChatItem from "./ChatItem";
import ChatHeader from "./ChatHeader";
import { textLargeMuiStyle } from "../../utils/commonStyle";
import { FONT_WEIGHT, WIDTH } from "../../theme/constants";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";
import useGetChats from "../../hooks/chat/useGetChats";
import useModalReducer, { MODAL_STATUS } from "../../hooks/useModalReducer";
import ModalComponent from "../../components/modal/ModalComponent";
import Report from "../../components/newComponents/report";
import useCreateReport from "../../hooks/useCreateReport";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import ChatSkeleton from "../../components/layouts/skeleton/ChatSkeleton";

const Wrapper = styled.div`
  padding: 0px 1rem;
  margin-top: 1.25rem;
  margin-bottom: 100px;
  max-width: ${WIDTH.fold};
  margin-left: auto;
  margin-right: auto;
`;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 230px);
  position: relative;
`;

function ChatList(props) {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: dataFromApi, isLoading, isEmptyData, fetchNextPage } = useGetChats();
  const { modalData, dispatchModal } = useModalReducer();
  const { handleReport } = useCreateReport();
  const [reasons, setReasons] = useState([]);
  const [data, setData] = useState([]);

  // Drawer state
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("list");

  //Drawer begin
  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  const onOpenDrawer = () => {
    setDrawerType("chat");
    handleDrawer(true);
  };
  //Drawer end

  const onClickRight = (type) => {
    switch (type) {
      case "add":
        navigate("/chat/new-chat");
        break;

      case "add":
        onOpenDrawer();
        break;

      default:
        break;
    }
  };

  const handleDrawerClick = (type = "select") => {
    switch (type) {
      case "report":
        dispatchModal({
          type: MODAL_STATUS.OPEN,
          data: {
            type: "report",
            btnOKText: "신고",
            btnCancelText: "취소",
            title: "무엇을 신고하려고 하시나요?",
            subTitle: "댓글 신고 시 자동으로 계정 차단이 진행됩니다.",
            content: <Report onChangeReason={setReasons} />,
          },
        });

        break;

      default:
        break;
    }
  };

  const handleReportUser = () => {
    const requestReason = reasons
      .filter((value) => value.checked)
      .map((item) => {
        return { reason: item.reason, type: item.type };
      });
    const requestData = {
      target: "user",
      target_id: "profileData.userID",
      reasons: requestReason,
    };
    console.log("requestData", requestData);
    handleReport(requestData);
  };

  //modal action
  const handleOk = () => {
    dispatchModal({ type: MODAL_STATUS.CLOSE });

    switch (modalData.type) {
      case "report":
        handleReportUser();
        break;
      default:
        break;
    }
  };

  const clearCachedPages = () => {
    queryClient.setQueryData(["recent-chats"], (existingData) => {
      return {
        pageParams: [],
        pages: [],
      };
    });
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  useEffect(() => {
    setData(dataFromApi);
  }, [dataFromApi]);

  useEffect(() => {
    return clearCachedPages();
  }, []);

  if (isEmptyData) {
    return (
      <>
        <ChatHeader onClickRight={onClickRight} />
        <Wrapper>
          <Skeleton variant='text' width={200} height={24} />
          <Box>
            <ChatSkeleton />
            <ChatSkeleton />
            <ChatSkeleton />
            <ChatSkeleton />
            <ChatSkeleton />
            <ChatSkeleton />
            <ChatSkeleton />
            <ChatSkeleton />
            <ChatSkeleton />
          </Box>
        </Wrapper>
      </>
    );
  }

  return (
    <>
      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame
            setOnDrawer={() => handleDrawer(false)}
            handleModal={handleDrawerClick}
            type={drawerType}
            // data={post}
            // refetch={handleDrawerRefetch}
            callBack={() => navigate(-1)}
          />
        </DrawerPortal>
      )}
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleOk={handleOk}
        handleCancel={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
      <ChatHeader onClickRight={onClickRight} />
      <Wrapper>
        <Typography
          sx={{
            ...textLargeMuiStyle,
            fontWeight: FONT_WEIGHT.xxLarge,
          }}
        >
          {t("message")}
        </Typography>
        <Box>
          {data?.length > 0 ? (
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                padding: 0,
              }}
            >
              {data?.map((chatItem) => (
                <ChatItem
                  key={chatItem.id}
                  receiverID={chatItem?.participant?.id}
                  nickname={chatItem?.participant?.globalName}
                  content={
                    chatItem?.lastMessage?.attachments?.length > 0
                      ? "[부착]"
                      : chatItem?.lastMessage?.content
                  }
                  profile_image_url={chatItem?.participant?.avatar?.avatarURL}
                  timestamp={chatItem?.lastMessage?.createdTimestamp}
                  countUnread={chatItem?.countUnread}
                />
              ))}
              <p ref={ref}></p>
            </List>
          ) : (
            <HeightWhenEmpty>
              <NoData text='다른 사람에게 메시지를 보내보세요.' />
            </HeightWhenEmpty>
          )}
        </Box>
      </Wrapper>
    </>
  );
}

export default ChatList;
