import React, { useEffect, useState } from "react";
import { COLORS } from "@theme/constants";
import { toast } from "react-toastify";

import { ReactComponent as ShareIcon } from "@icons/share.svg";
import { ReactComponent as ExploreBlackIcon } from "@icons/explore_black.svg";
import { ReactComponent as VisibilityOffIcon } from "@icons/visibility_off.svg";
import { ReactComponent as ReportIcon } from "@icons/report.svg";
import { ReactComponent as EditIcon } from "@icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@icons/delete.svg";
import { ReactComponent as EyeOpenIcon } from "@icons/eye_open.svg";
import { ReactComponent as EyeCloseIcon } from "@icons/eye_close.svg";
import { ReactComponent as EventIcon } from "@icons/event.svg";
import DrawerListComponent from "../../components/Drawer/DrawerListComponent";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/Auth";
import { isMine } from "@utils/commonFunction";
import dayjs from "dayjs";
import { convertResponseTimeToLocalTime } from "../../utils/dateTimeFormat";
import styled from "styled-components";
import { Typography } from "@mui/material";

function DrawerCreatePost({ close, handleModal, data, refetch, callBack }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [items, setItems] = useState([]);

  const createPost = {
    text: "게시물 업로드",
    icon: <ExploreBlackIcon className='icon_explore' />,
    fn: () => {
      handleModal("post");
      close();
    },
  };

  const createQuiz = {
    text: (
      <Typography component={"span"} sx={{ color: COLORS.primary4 }}>
        퀴즈 업로드
      </Typography>
    ),
    icon: <EventIcon />,
    fn: () => {
      handleModal("quiz");
      close();
    },
  };

  useEffect(() => {
    const newItems = [];
    newItems.push(createPost);
    // if (user?.type === 3) {
    //   newItems.push(createQuiz);
    // }
    setItems(newItems);
  }, []);

  return <DrawerListComponent close={close} modalData={items} />;
}

export default React.memo(DrawerCreatePost);
