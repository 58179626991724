import { Outlet } from "react-router-dom";
import { COLORS, WIDTH } from "../../theme/constants";
import { Box } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Navigation from "../../containers/navigations/Navigation";
import NavigateChat from "../../containers/navigations/NavigateChat";
import Footer from "../../containers/footers/Footer";
import { useContext, useRef } from "react";
import useElementSize from "../../hooks/useElementSize";
import NavigationTablet from "../../containers/navigations/NavigationTablet";
import { GlobalStateContext } from "../../context/GlobalStateProvider";

function ChatLayout(props) {
  const { isNavigation } = props;
  const contentRef = useRef(null);
  const contentSize = useElementSize(contentRef);
  const navRef = useRef(null);
  const { globalState } = useContext(GlobalStateContext);

  const isMobileWithoutFold = useMediaQuery({
    query: `(max-width: ${WIDTH.fold_from})`,
  });

  // 516-674
  // const isFold = useMediaQuery({ minWidth: 516, maxWidth: 674 })
  const isFold = useMediaQuery({
    query: `(min-width: ${WIDTH.fold_from})`,
  });

  const isShowNavigateTablet = useMediaQuery({ minWidth: 516, maxWidth: 1200 });

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const isMax500 = useMediaQuery({
    query: `(max-width: 500px)`,
  });

  const getNavigationStyle = () => {
    let result = {
      paddingLeft: isShowNavigateTablet && !isPc && isNavigation ? "80px" : "auto",
    };
    if (!globalState?.isNavbarLeft) {
      result = {
        paddingRight: isShowNavigateTablet && !isPc && isNavigation ? "80px" : "auto",
      };
    }
    return result;
  };

  const contentStyle = {
    overflow: "auto",
    // height: `calc(100vh - ${isMax500 && isNavigation ? "100px" : isPc ? "180px" : "0px"})`,
    height: `calc(100vh - ${isMax500 && isNavigation ? "100px" : isPc ? "0px" : "0px"})`,
    ...getNavigationStyle(),
  };

  const contentDesktopStyle = {
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: COLORS.grey400,
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: COLORS.grey700,
    },
    ...contentStyle,
  };

  const isSideBar = true;
  return (
    <>
      {isNavigation && isShowNavigateTablet && <NavigationTablet ref={navRef} />}
      {!isPc && isNavigation && isMobileWithoutFold && <Navigation ref={navRef} />}
      <Box sx={{ position: "relative", maxWidth: "1200px", margin: "auto" }}>
        <Box sx={{ display: "flex" }}>
          {isPc && isSideBar && <NavigateChat />}
          <Box
            sx={{
              width: isPc ? "900px" : `calc(100% - ${isPc ? "300px" : "0px"})`,
            }}
            ref={contentRef}
          >
            <Box sx={isPc ? contentDesktopStyle : contentStyle} id={"scrollableContent"}>
              <Outlet />
            </Box>
          </Box>
        </Box>
        {/* {isPc && <Footer contentWidth={contentSize.width} />} */}
      </Box>
    </>
  );
}

export default ChatLayout;
