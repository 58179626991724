import {
  Avatar,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import {
  COLORS,
  FONT_WEIGHT,
  ICON_SIZE,
  TEXTSIZE,
} from "../../theme/constants";
import {
  textMediumMuiStyle,
  buttonMuiStyle,
  truncateStyle,
} from "../../utils/commonStyle";
import { createConnection, deleteConnection } from "../../api";
import { toast } from "react-toastify";
import { handleError, isMine } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import ImageAvatar from "../../assets/images/avatar.png";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div``;

function ItemConnect(props) {
  const navigate = useNavigate();
  const {t} = useTranslation()
  const {
    user_id = "",
    nickname = "",
    introduce = "",
    profile_image_url = "",
    fetchData,
    listItemStyle = {},
    // xac dinh minh co connect toi ng ta hay ko
    isConnect = false,
  } = props;
  const { logout } = useAuth();

  const redirectToProfile = () => {
    navigate(`/user-profile/${nickname}`);
  };

  //connection action
  const handleConnect = async () => {
    try {
      if (isConnect) {
        await deleteConnection(user_id);
        toast("성공적으로 커넥트 해제되었습니다.");
      } else {
        await createConnection({ to_user_id: user_id });
        toast("성공적으로 커넥트되었습니다.");
      }
      fetchData();
    } catch (error) {
      handleError(error, logout);
    }
  };

  return (
    <>
      <Wrapper>
        <ListItem
          sx={{
            pl: 1.5,
            py: 0,
            my: "1.5rem",
            maxWidth: "300px",
            alignItems: "center",
            "& .MuiListItemSecondaryAction-root": {
              right: "12px",
            },
            ...listItemStyle,
          }}
          secondaryAction={
            !isMine(user_id) && (
              <Button
                sx={{
                  ...buttonMuiStyle,
                  p: 0.25,
                  minWidth: "80px",
                  backgroundColor: isConnect ? COLORS.grey50 : COLORS.primary4,
                  color: isConnect ? COLORS.grey600 : COLORS.white,
                  "&:hover": {
                    backgroundColor: isConnect
                      ? COLORS.grey50
                      : COLORS.primary4,
                    color: isConnect ? COLORS.grey600 : COLORS.white,
                  },
                }}
                onClick={handleConnect}
              >
                {isConnect ? t("button.connecting") : t("button.connect")}
              </Button>
            )
          }
        >
          <ListItemAvatar
            sx={{ minWidth: ICON_SIZE.large, mr: 1, my: 0, cursor: "pointer" }}
            onClick={redirectToProfile}
          >
            <Avatar
              alt=""
              src={profile_image_url || ImageAvatar}
              sx={{
                width: ICON_SIZE.large,
                height: ICON_SIZE.large,
              }}
            />
          </ListItemAvatar>
          <ListItemText
            onClick={redirectToProfile}
            primary={nickname}
            sx={{
              my: 0,
              "& .MuiListItemText-primary": {
                ...textMediumMuiStyle,
                ...truncateStyle,
                width: "70%",
                fontWeight: FONT_WEIGHT.large,
                cursor: "pointer",
              },
            }}
            secondaryTypographyProps={{ component: "div" }}
            secondary={
              <Typography
                sx={{
                  ...textMediumMuiStyle,
                  ...truncateStyle,
                  color: COLORS.grey500,
                  fontSize: TEXTSIZE.small,
                  width: "70%",
                }}
              >
                {introduce}
              </Typography>
            }
          />
        </ListItem>
      </Wrapper>
    </>
  );
}

export default ItemConnect;
