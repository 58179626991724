import React from "react";
import styled from "styled-components";
import { Box, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as LOGODARK } from "../assets/svgs/logo-dark.svg";
import LoginWithSocial from "../containers/login/LoginWithSocial";
import {
  COLORS,
  FONT_WEIGHT,
  HEIGHT,
  TEXTSIZE,
  WIDTH,
} from "../theme/constants";
import {
  buttonLargeMuiStyle,
  textLargeMuiStyle,
  textMediumMuiStyle,
} from "../utils/commonStyle";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  position: relative;
  height: ${HEIGHT.noNavigation};
  width: 100%;
  color: ${COLORS.white};

  .logo-title {
    position: absolute;
    top: 20%;
  }

  .buttons-wrap {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`;

function LoginComponent() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="logo-title">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
              }}
            >
              <LOGODARK />
            </Box>
            <Typography
              sx={{
                ...textMediumMuiStyle,
                color: COLORS.grey200,
                fontWeight: FONT_WEIGHT.xxLarge,
                textAlign: "center",
              }}
            >
              {/* 콘텐츠로 만드는 당신만의 세상 */}
              {t("create_your_own_world_with_content")}
            </Typography>
          </div>
        </Box>
        <div className="buttons-wrap">
          <Typography
            sx={{
              ...textLargeMuiStyle,
              color: COLORS.white,
              lineHeight: "1.25rem",
              textAlign: "center",
            }}
          >
            {t("create_an_account_in_3_seconds")}
          </Typography>
          <LoginWithSocial />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{
                ...buttonLargeMuiStyle,
                backgroundColor: COLORS.white,
                color: COLORS.black1,
                maxWidth: WIDTH.desktop_main_contents,
                "&:hover": {
                  backgroundColor: COLORS.white,
                  color: COLORS.black1,
                },
                padding: "0.75rem",
              }}
              variant="contained"
              size="large"
              onClick={() => navigate("/auth")}
            >
              {t("button.start_with_email")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              fontSize: TEXTSIZE.small,
              justifyContent: "center",
              mt: 3,
            }}
          >
            <Typography>
              {t("already_signed_up")}
            </Typography>
            <Link to={"/login/account"}>
              <Typography sx={{ textDecoration: "underline", pl: 1 }}>
                {t("login")}
              </Typography>
            </Link>
          </Box>
        </div>
      </Wrapper>
    </>
  );
}

export default React.memo(LoginComponent);
