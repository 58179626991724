import { useReducer } from "react";

export const MODAL_STATUS = {
  OPEN: "OPEN",
  CLOSE: "CLOSE",
};

const initModal = {
  open: false,
  btnOKText: "",
  btnCancelText: "",
  title: "",
  subTitle: "",
  content: "",
  type: "",
};

function useModalReducer(props) {
  const reducerModal = (state, action) => {
    switch (action.type) {
      case MODAL_STATUS.OPEN:
        return { ...state, open: true, ...action.data };
      case MODAL_STATUS.CLOSE:
        return initModal;
      default:
        return state;
    }
  };

  const [modalData, dispatchModal] = useReducer(reducerModal, initModal);

  return { modalData, dispatchModal };
}

export default useModalReducer;
