import { useMutation } from "@tanstack/react-query";
import { createConnectKOL } from "../api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { handleError } from "../utils/commonFunction";
import { toast } from "react-toastify";

function useCreateConnects() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleConnects = async (requestData) => {
    try {
      await createConnectKOL(requestData);
      // navigate("/");
    } catch (error) {
      const errorMessage = handleError(error, logout, navigate);
      toast(errorMessage);
    } finally {
      navigate("/");
    }
  };

  const { isLoading, isError, error, mutate } = useMutation({
    mutationFn: handleConnects,
  });

  return {
    isLoading,
    handleConnects,
  };
}

export default useCreateConnects;
