import React, { useRef } from "react";
import { getPresignedUrlBiz } from "../../api";
import { uploadPresigned } from "../../service/cloudflare_services";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";
import { ReactComponent as UploadIcon } from "@icons/upload.svg";
import { COLORS } from "../../theme/constants";

function ButtonUpload(props) {
  const { handleChange, limitSize = 2, handleSetError } = props;
  const { logout } = useAuth();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const handleUploadImage = async (files) => {
    try {
      const filename = files[0].name;
      const contentType = files[0].type;

      console.log("files[0]", files[0].size);
      if (files[0].size > limitSize * 1000000) {
        handleSetError("OVER_SIZE");
        return;
      }
      const requestData = {
        list: [{ filename: filename, contentType: contentType }],
      };
      const res = await getPresignedUrlBiz(requestData);
      const file_buffer = await files[0].arrayBuffer();

      await uploadPresigned(res.data.data[0].url, file_buffer, {
        Authorization: null,
        lang: "kr",
        "Content-Type": contentType,
      });

      handleChange(res.data.data[0].filename);
      //   handleChange({
      //     name: [clickType],
      //     value: res.data.data[0].filename,
      //   });
    } catch (error) {
      //   if (clickType === imageType.PROFILE) {
      //     setPreviewProfile("");
      //   } else {
      //     setPreviewBackground("");
      //   }
      handleError(error, logout, navigate);
    }
  };

  const onDrop = (acceptedFiles) => {
    handleUploadImage(acceptedFiles);
    // const previewUrl = URL.createObjectURL(acceptedFiles[0]);
    // if (clickType === imageType.PROFILE) {
    //   setPreviewProfile(previewUrl);
    // } else {
    //   setPreviewBackground(previewUrl);
    // }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": ["png, jpg, jpeg"] },
    onDrop,
  });

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} ref={fileInputRef} />
      </div>
      <Box
        sx={{
          cursor: "pointer",
          display: "flex",
          gap: "4px",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 1,
          border: `1px solid ${COLORS.grey300}`,
          minHeight: "48px",
        }}
        onClick={() => fileInputRef.current.click()}
      >
        <UploadIcon />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "16px",
            color: "#555555",
          }}
        >
          파일 업로드
        </Typography>
        <Typography
          component={"span"}
          sx={{
            fontSize: "10px",
            fontWeight: "400",
            lineHeight: "16px",
            color: "#A6A6A6",
          }}
        >
          (최대2M / PNG, JPG, JPEG)
        </Typography>
      </Box>
    </div>
  );
}

export default ButtonUpload;
