import React from "react";
import { Box } from "@mui/material";

function Overlay(props) {
  const { styleCustomize } = props;
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        // backgroundColor: "rgb(0,0,0,0.04)",
        backgroundColor: "rgb(51,51,51,0.03)",
        top: 0,
        ...styleCustomize,
      }}
    />
  );
}

export default Overlay;
