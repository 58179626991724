import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { createConnection, deleteConnection } from "@api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import { useTranslation } from "react-i18next";
import { COLORS } from "@theme/constants";
import { buttonMuiStyle } from "@utils/commonStyle";
import { handleError } from "@utils/commonFunction";

function ButtonConnect(props) {
  const {
    isConnect = false,
    userId = "",
    buttonProps = {},
    handleConnectSuccess,
    textCancelConnect = "커넥팅",
    textConnect = "커넥트",
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const [isConnectLocal, setIsConnectLocal] = useState(isConnect);

  const handleConnect = async () => {
    try {
      let newStatus = "";
      if (isConnectLocal) {
        await deleteConnection(userId);
        toast("성공적으로 커넥트 해제되었습니다.");
        newStatus = "deleted";
      } else {
        await createConnection({ to_user_id: userId });
        toast("성공적으로 커넥트되었습니다.");
        newStatus = "created";
      }
      handleConnectSuccess(userId, newStatus);
      setIsConnectLocal(!isConnectLocal);
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  useEffect(() => {
    setIsConnectLocal(isConnect)
  },[isConnect])

  return (
    <Button
      sx={{
        ...buttonMuiStyle,
        padding: 0,
        px: 1,
        py: 0.5,
        minWidth: "42px",
        backgroundColor: isConnectLocal ? COLORS.grey50 : COLORS.primary4,
        color: isConnectLocal ? COLORS.grey600 : COLORS.white,
        "&:hover": {
          backgroundColor: isConnectLocal ? COLORS.grey50 : COLORS.primary4,
          color: isConnectLocal ? COLORS.grey600 : COLORS.white,
        },
        ...buttonProps,
      }}
      onClick={handleConnect}
    >
      <Typography
        sx={{
          fontSize: "10px",
          fontWeight: "500",
          lineHeight: "12px",
        }}
      >
        {isConnectLocal ? textCancelConnect : textConnect}
      </Typography>
    </Button>
  );
}

export default ButtonConnect;
