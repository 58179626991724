import React, { useEffect, useState } from "react";
import {
  blockUser,
  createConnection,
  deleteConnection,
  getOtherUserProfile,
  getUserProfile,
} from "../../../api";
import { handleError } from "../../../utils/commonFunction";
import ProfileBackgroundComponent from "./ProfileBackgroundComponent";
import { useNavigate, useParams } from "react-router-dom";
import DrawerPortal from "../../drawers/DrawerPortal";
import DrawerFrame from "../../drawers/DrawerFrame";
import ModalComponent from "../../../components/modal/ModalComponent";
import Report from "../../../components/newComponents/report";
import { toast } from "react-toastify";
import { useAuth } from "../../../context/Auth";
import { getParticipantExternal } from "../../../service/chat_services";
import ProfileSkeleton from "../../../components/layouts/skeleton/ProfileSkeleton";

function ProfileBackground(props) {
  const { profileType, setUserInfo } = props;
  const { nickname } = useParams();
  const { logout, setProfile } = useAuth();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("appDown");
  const [reasons, setReasons] = useState([]);
  const [modalData, setModalData] = useState({
    open: false,
    btnOKText: "",
    btnCancelText: "",
    title: "",
    subTitle: "",
    content: "",
  });

  // handle action from drawer
  const handleDrawer = (value, type = "appDown") => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerType(type);
    setDrawerOpen(value);
  };

  const handleModal = (key) => {
    switch (key) {
      case "share":
        navigator.clipboard.writeText(`${window.location.origin}/user-profile/${nickname}`);
        toast("링크가 복사되었습니다.");
        break;
      case "connect":
        onClickFollow();
        break;
      case "report":
        setModalData({
          ...modalData,
          type: "report",
          open: true,
          btnOKText: "신고",
          btnCancelText: "취소",
          title: "무엇을 신고하려고 하시나요?",
          subTitle: "댓글 신고 시 자동으로 계정 차단이 진행됩니다.",
          content: <Report onChangeReason={setReasons} />,
        });
        break;
      case "block":
        handleBlockUser();
        break;
      default:
        break;
    }
  };

  const handleReportUser = async () => {
    try {
      const requestReason = reasons
        .filter((value) => value.checked)
        .map((item) => {
          return { reason: item.reason, type: item.type };
        });
      console.log("requestReason", requestReason);
      const requestData = {
        target: "user",
        target_id: profileData.userID,
        reasons: requestReason,
      };
      await createReport(requestData);
      toast("신고가 완료되었습니다.");
    } catch (error) {
      handleError(error, logout);
    }
  };

  const handleBlockUser = async () => {
    try {
      const requestData = {
        target_user_id: profileData.userID,
        reason: "차단사유",
      };
      await blockUser(requestData);
      toast("차단이 완료되었습니다.");
      navigate("/");
    } catch (error) {
      handleError(error, logout);
    }
  };

  //only action in others profile
  // "is_connect": false, // is other user is my follower
  // "is_connecting": false // is following other user
  const onClickFollow = async () => {
    try {
      if (profileData.isConnecting) {
        await deleteConnection(profileData.userID);
        toast("성공적으로 커넥트 해제되었습니다.");
      } else {
        await createConnection({ to_user_id: profileData.userID });
        toast("성공적으로 커넥트되었습니다.");
      }
      handleGetUserProfile();
    } catch (error) {
      handleError(error, logout);
    }
  };

  //modal action
  const handleOk = () => {
    setModalData({ ...modalData, open: false });

    switch (modalData.type) {
      case "report":
        handleReportUser();
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
  };

  const isDisableBtnOk = () => {
    let result = false;
    if (
      modalData.type === "report" &&
      reasons
        .filter((value) => value.checked)
        .map((item) => {
          return { reason: item.reason, type: item.type };
        }).length === 0
    )
      result = true;
    return result;
  };
  //end modal action

  //get user profile
  const handleGetUserProfile = async () => {
    try {
      setIsLoading(true);
      const res =
        profileType === "my" ? await getUserProfile() : await getOtherUserProfile({ nickname });
      if (!res) return;
      if (profileType === "my") {
        const user = {
          ...res,
          profile: {
            ...res?.user_profile,
          },
        };
        setProfile(user);
      }
      handleMappingProfileData(res);
    } catch (error) {
      handleError(error, logout, navigate);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMappingProfileData = (data) => {
    let profileData = {};
    if (profileType === "my") {
      profileData = {
        bgImage: data?.user_profile?.bg_image_url,
        nickname: data?.user_profile?.nickname,
        profileImage: data?.user_profile?.profile_image_url,
        introduce: data?.user_profile?.introduce,
        comment: data?.user_profile?.comment,
        outLinks: data?.user_profile?.out_links,
        connectionsCount: data?.connecting_user_count + data?.connect_user_count,
        postCount: data?.post_count,
        myPic: data.user_fund.balance,
        userType: data?.type,
      };
    } else {
      profileData = {
        bgImage: data?.bg_image_url,
        nickname: data?.nickname,
        profileImage: data?.profile_image_url,
        introduce: data?.introduce,
        comment: data?.comment,
        outLinks: data?.out_links,
        connectionsCount: data?.connecting_user_count + data?.connect_user_count,
        postCount: data?.post_count,
        isConnecting: data?.is_connecting,
        userID: data?.user_id,
        userType: data?.type,
      };
    }
    setProfileData(profileData);
  };

  const onClickMessage = async () => {
    // get conversation information
    try {
      const res = await getParticipantExternal(profileData?.userID, {});
      navigate(`/chat/detail/${res?.id}`, { state: { userName: res?.name } });
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  useEffect(() => {
    handleGetUserProfile();
  }, [profileType]);

  if (isLoading) {
    return <ProfileSkeleton />;
  }

  return (
    <>
      <ProfileBackgroundComponent
        profileType={profileType}
        onClickPurchase={() => console.log("onClickPurchase")}
        onClickFollow={onClickFollow}
        onClickMessage={onClickMessage}
        handleDrawer={handleDrawer}
        {...profileData}
      />
      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame
            type={drawerType}
            data={profileData}
            setOnDrawer={() => handleDrawer(false)}
            handleModal={handleModal}
          />
        </DrawerPortal>
      )}
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => handleClose()}
        handleOk={() => handleOk()}
        handleCancel={() => handleCancel()}
        btnOKProps={{
          disabled: isDisableBtnOk(),
        }}
        btnCancelProps={{}}
      />
    </>
  );
}

export default ProfileBackground;
