import axios from "axios";

const uninterceptedAxiosInstance = axios.create();
uninterceptedAxiosInstance.interceptors.request.use(
  (config) => {
    config.headers[`lang`] = "kr";
    const token = JSON.parse(localStorage.getItem("chat_access_token"));
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

// chat
export function getChats(requestParams) {
  return uninterceptedAxiosInstance
    .get(`${process.env.REACT_APP_CHAT_API_URL}/user/me/recent-chats`, {
      params: requestParams,
    })
    .then((res) => {
      return res.data.data;
    });
}

export function getChatMessageInPrivate(receiverID, requestParams) {
  return uninterceptedAxiosInstance
    .get(
      `${process.env.REACT_APP_CHAT_API_URL}/dm/participant/${receiverID}/messages`,
      {
        params: requestParams,
      }
    )
    .then((res) => {
      return res.data.data;
    });
}

//get user to add new chat
export function getChatNewUser(requestParams) {
  return uninterceptedAxiosInstance
    .get(`${process.env.REACT_APP_CHAT_API_URL}/user`, {
      params: requestParams,
    })
    .then((res) => {
      return res.data.data;
    });
}

//get box chat to create conversations
export function getBoxChat(receiverID, requestParams) {
  return uninterceptedAxiosInstance
    .get(
      `${process.env.REACT_APP_CHAT_API_URL}/dm/participant/${receiverID}/boxchat`,
      {
        params: requestParams,
      }
    )
    .then((res) => {
      return res.data;
    });
}

//upload file
export function uploadFileToChatSDK(data, conversationID) {
  return uninterceptedAxiosInstance
    .post(
      `${process.env.REACT_APP_CHAT_UPLOAD_API_URL}/private-chat/${conversationID}`,
      data,
      {}
    )
    .then((res) => {
      return res?.data?.data;
    });
}

//get participant id to create conversations
// https://dev.api.tdichatsdk.metacrew.vn/user/e9aeaf2f-3f30-48ad-afe5-8dfe55ba979b/external
export function getParticipantExternal(userID, requestParams) {
  return uninterceptedAxiosInstance
    .get(`${process.env.REACT_APP_CHAT_API_URL}/user/${userID}/external`, {
      params: requestParams,
    })
    .then((res) => {
      console.log("res", res);
      return res.data.data;
    });
}

export function getProducts(requestParams) {
  return uninterceptedAxiosInstance.get(
    `${process.env.REACT_APP_API_URL}/products`,
    {
      params: requestParams,
    }
  );
}
