import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Truncate from "react-truncate";
import { COLORS, FONT_WEIGHT, LINE_HEIGHT, TEXTSIZE } from "@theme/constants";
import { textMediumMuiStyle } from "@utils/commonStyle";

function TruncateText(props) {
  const {
    more = "더 보기",
    less = "덜 본다",
    breakLine = false,
    content,
    ellipsis = "...",
    lines = 2,
    styleContent = {},
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState(false);
  // const [lines, setLines] = useState(2);

  const handleTruncate = (newTruncated) => {
    if (truncated !== newTruncated) {
      setTruncated(newTruncated);
    }
  };

  const toggleLines = () => {
    setExpanded(!expanded);
  };

  return (
    <Box>
      <Truncate
        lines={!expanded && lines}
        style={{
          color: COLORS.black1,
          lineHeight: LINE_HEIGHT.xxxLarge,
          ...styleContent
        }}
        ellipsis={
          <span>
            {ellipsis}
            {breakLine ? (
              <Typography
                style={{
                  ...textMediumMuiStyle,
                  cursor: "pointer",
                  color: COLORS.grey500,
                  fontWeight: FONT_WEIGHT.large,
                  // pt: 0.5,
                }}
                onClick={toggleLines}
              >
                {more}
              </Typography>
            ) : (
              <span
                style={{
                  cursor: "pointer",
                  color: COLORS.grey500,
                  pt: 0.5,
                }}
                onClick={toggleLines}
              >
                {more}
              </span>
            )}
          </span>
        }
        onTruncate={handleTruncate}
      >
        {content}
      </Truncate>
      {!truncated && expanded && (
        <Typography
          sx={{
            cursor: "pointer",
            color: COLORS.grey500,
            pt: 0.5,
            lineHeight: LINE_HEIGHT.xxxLarge,
            fontSize: TEXTSIZE.medium,
          }}
          onClick={toggleLines}
        >
          {less}
        </Typography>
      )}
    </Box>
  );
}

export default TruncateText;
