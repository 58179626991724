import { Box } from "@mui/material";
import React from "react";
import { RADIUS } from "../../../../theme/constants";
import styled from "styled-components";
import ReactPlayer from "react-player/file";
import iconPlayWhite from "../../../../assets/images/icon_play_white.png";

const Wrapper = styled.div`
  // aspect-ratio: 1;
  width: 100%;
  height: 100%;
  .bg-img {
    border-radius: ${RADIUS.large};
    width: 100%;
    height: 100%;
  }
`;

function MyFeedMediaVideoViewOnly(props) {
  const { data, onClickMedia, playerStyle = {}, boxStyle = {} } = props;

  return (
    <Wrapper>
      <Box
        className="bg-img"
        sx={{ backgroundColor: "black" }}
        style={boxStyle}
      >
        <Box onClick={onClickMedia} sx={{ width: "100%", height: "100%" }}>
          <ReactPlayer
            width="100%"
            height="100%"
            playing={false}
            className="bg-img"
            url={data?.url}
            loop={true}
            muted={true}
            style={playerStyle}
          />
        </Box>
        <Box
          sx={{
            color: "white",
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bottom: 0,
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgba(0,0,0,0.6)",
              borderRadius: "50%",
              width: "48px",
              height: "48px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={iconPlayWhite}
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
}

export default MyFeedMediaVideoViewOnly;
