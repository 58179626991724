import React from "react";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

function useFirebaseInit() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;

  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);
  // const messaging = getMessaging(firebaseApp);

  let messaging;
  try {
    messaging = getMessaging(firebaseApp);
  } catch (error) {
    console.error("Failed to initialize Firebase Messaging", error);
  }

  const handleGetToken = () => {
    // console.log("vapidKey",vapidKey)
    return getToken(messaging, { vapidKey: vapidKey })
      .then((currentToken) => {
        if (currentToken) {
        //   console.log('current token for client: ', currentToken);
          window.localStorage.setItem("push_token", currentToken);
          // setTokenFound(true);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // setTokenFound(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
      });
  };

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        console.log("onMessage firebase", messaging);
        resolve(payload);
      });
    });

  const firebaseAuth = getAuth(firebaseApp);
  return { handleGetToken, onMessageListener, firebaseAuth };
}

export default useFirebaseInit;
