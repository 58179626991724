import {
  createContext,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import styledTheme from "../theme/theme";

const defaultValue = {
  theme: window.localStorage.getItem("theme")
    ? window.localStorage.getItem("theme")
    : "light",
  toggleTheme: () => {},
};
export const ThemeContext = createContext(defaultValue);

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(
    window.localStorage.getItem("theme") ?? "light"
  );

  const toggleTheme = useCallback(() => {
    setTheme((currentTheme) => (currentTheme === "light" ? "dark" : "light"));
  }, []);

  const themeObject = useMemo(() => {
    return theme === "light" ? styledTheme.light : styledTheme.dark;
  }, [theme]);

  useEffect(() => {
    const body = document.querySelector("body");
    body.className = theme;
    window.localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, themeObject }}>
      <StyledThemeProvider theme={themeObject}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
