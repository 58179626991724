import React from "react";
import { Box, ImageList, ImageListItem, Skeleton } from "@mui/material";
import { getFileTypeByName } from "@utils/commonFunction";
import { ReactComponent as IconPlay } from "@icons/play.svg";

export const TYPE_MESSAGE_CHAT = {
  MESSAGE: "message",
  FILE: "file",
  STICKER: "sticker",
  ERROR: "error",
};

const MessageSkeleton = ({
  hasAfter = false,
  isSameTimeDay = false,
  typePosition = "right",
  content = "",
  attachments = [],
  type = TYPE_MESSAGE_CHAT.MESSAGE,
  isSameTimeMinute = false,
}) => {
  const renderMessageContent = (item) => {
    switch (getFileTypeByName(item?.name || item?.rawPath)) {
      case "image":
        return (
          <Skeleton
            variant='rectangular'
            sx={{
              width: "100%",
              aspectRatio: 1 / 1,
              height: "auto",
            }}
          />
        );
      case "video":
        return (
          <Box>
            <Skeleton
              variant='rectangular'
              sx={{
                width: "100%",
                aspectRatio: 1 / 1,
                height: "auto",
              }}
            />
            <Box
              sx={{
                color: "white",
                position: "absolute",
                top: "calc(50% - 20px)",
                left: "calc(50% - 20px)",
                zIndex: 9,
                cursor: "pointer",
                backgroundColor: "rgba(255, 255, 255, 0.3)",
                backdropFilter: "blur(4px)",
                p: 1,
                borderRadius: "50%",
                height: "40px",
              }}
            >
              <IconPlay />
            </Box>
          </Box>
        );
      case "file":
        return <Skeleton variant='text' width={400} height={34} />;

      default:
        break;
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          p: 0,
          mb: !hasAfter ? "1.25rem" : "0.5rem",
          alignItems: "flex-end",
          flexDirection: typePosition === "left" ? "row" : "row-reverse",
        }}
      >
        {typePosition === "left" && (
          <Skeleton variant='circular' width={34} height={34} sx={{ mr: 1 }} />
        )}
        {type === TYPE_MESSAGE_CHAT.FILE && attachments?.length > 0 && (
          <Box sx={{ mx: 1, textAlign: typePosition, width: "50%" }}>
            {getFileTypeByName(attachments[0]?.name || attachments[0].rawPath) !== "file" ? (
              <Box>
                {attachments?.length <= 3 && (
                  <ImageList cols={attachments?.length} sx={{ overflow: "hidden" }}>
                    {attachments.map((item) => (
                      <ImageListItem key={item.id}>{renderMessageContent(item)}</ImageListItem>
                    ))}
                  </ImageList>
                )}
                {attachments?.length > 3 && (
                  <Box>
                    <ImageList cols={3} sx={{ overflow: "hidden" }}>
                      {attachments.slice(0, 3).map((item) => (
                        <ImageListItem key={item.id}>{renderMessageContent(item)}</ImageListItem>
                      ))}
                    </ImageList>

                    <ImageList
                      cols={attachments.slice(3, attachments.length).length}
                      sx={{ marginTop: "4px", overflow: "hidden" }}
                    >
                      {attachments.slice(3, attachments.length).map((item) => (
                        <ImageListItem key={item.id}>{renderMessageContent(item)}</ImageListItem>
                      ))}
                    </ImageList>
                  </Box>
                )}
              </Box>
            ) : (
              <Box>
                {attachments.map((item) => (
                  <Box key={item.id}>{renderMessageContent(item)}</Box>
                ))}
              </Box>
            )}
            {content && (
              <Skeleton
                variant='text'
                sx={{
                  width: "fit-content",
                  cursor: "pointer",
                  borderRadius:
                    typePosition === "left" ? "0.5rem 1rem 1rem 0" : "1rem 0.5rem 0rem 1rem",
                  padding: "0.5rem 0.75rem",
                  ml: typePosition === "left" ? 0 : "auto",
                  mr: typePosition === "left" ? "auto" : "0",
                  mt: 1,
                }}
                width={200}
              />
            )}
          </Box>
        )}
        {type === TYPE_MESSAGE_CHAT.MESSAGE && (
          <Skeleton
            variant='text'
            width={200}
            height={34}
            sx={{
              borderRadius:
                typePosition === "left" ? "0.5rem 1rem 1rem 0" : "1rem 0.5rem 0rem 1rem",
            }}
          />
        )}
        <Skeleton
          variant='text'
          width={80}
          height={18}
          sx={{ mx: 1, visibility: !hasAfter || !isSameTimeMinute ? "visible" : "hidden" }}
        />
      </Box>
      {!isSameTimeDay && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <Skeleton variant='text' width={100} height={18} />
        </Box>
      )}
    </>
  );
};

export default MessageSkeleton;
