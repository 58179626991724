import React from "react";
import { Box, Skeleton } from "@mui/material";

function PostSkeleton() {
  return (
    <Box sx={{my: 2}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Skeleton variant='circular' width={48} height={48} />
          <Box>
            <Skeleton variant='text' width={48} height={18} sx={{ mb: 1 }} />
            <Skeleton variant='text' width={100} height={18} />
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Skeleton
            variant='rectangular'
            width={42}
            height={20}
            sx={{
              borderRadius: "28px",
            }}
          />
          <Skeleton variant='rounded' width={16} height={4} />
        </Box>
      </Box>
      <Skeleton
        variant='rectangular'
        sx={{
          width: "100%",
          height: "auto",
          aspectRatio: 1,
          borderRadius: 2,
        }}
      />
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pb: 1,
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.25rem",
                cursor: "pointer",
              }}
            >
              <Skeleton variant='circular' width={24} height={24} />
              <Skeleton variant='text' width={40} height={16} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.25rem",
                cursor: "pointer",
              }}
            >
              <Skeleton variant='circular' width={24} height={24} />
              <Skeleton variant='text' width={40} height={16} />
            </Box>
          </Box>
          <Skeleton variant='text' width={40} height={16} sx={{}} />
        </Box>
        <Skeleton variant='text' width={100} height={16} sx={{ mb: 1 }} />
        <Skeleton variant='text' width={200} height={16} />
      </Box>
    </Box>
  );
}

export default PostSkeleton;
