import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import {
  COLORS,
  FONT_WEIGHT,
  ICON_SIZE,
  LINE_HEIGHT,
  RADIUS,
  TEXTSIZE,
} from "../../theme/constants";
import {
  textSmallMuiStyle,
  textMediumMuiStyle,
  buttonMuiStyle,
  truncateStyle,
} from "../../utils/commonStyle";
import { createConnection, deleteConnection, unblockUser } from "../../api";
import { toast } from "react-toastify";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { Link, useNavigate } from "react-router-dom";
import ModalComponent from "../../components/modal/ModalComponent";
import backgroundSpace from "../../assets/images/bg_space.jpg";
import { relativeTimeKorean } from "../../utils/dateTimeFormat";
import ImageAvatar from "../../assets/images/avatar.png";
import ImageDefault from "../../assets/images/default_image.jpg";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  margin-bottom: "72px";
`;

const WrapperImg = styled.img`
  aspect-ratio: 1;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

const notificationImageTypes = ["comment", "pic", "purchase"];

function NotificationItem(props) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const {t} = useTranslation()
  const {
    user_id = "",
    nickname = "",
    fetchData,
    type = "",
    // "id": "2cfe7962-e463-4a5c-801b-b2b483c4cc02",
    // "user_id": "30e8f142-ac91-4de9-9f8d-09ca33399adc",
    // "push_status": 2,
    // "type": "comment",
    title = "",
    body = "",
    // "body": "ㄱㅈㅂㅈㅅㅊㅎ",
    // "payload": {
    //     "post_id": "a801b0cb-e2e8-46ff-aad6-1182c7d239bd",
    //     "thumbnail": "/2023/12/28/8af93940-9a28-1e23-8d9c-1501d7d429d0_1703753557.png",
    //     "comment_id": "50d6f140-c190-4627-83e9-b9e44025cd88",
    //     "from_user_id": "ddd733f8-fcb3-42dc-8cfe-36c31084beee",
    //     "notification_id": "2cfe7962-e463-4a5c-801b-b2b483c4cc02",
    //     "from_user_nickname": "tester"
    // },
    // "is_seen": 0,
    // "is_deleted": 0,
    created_at = "",
    prefix_title = "tester",
    left_image = "",
    right_image = "",
    payload = {},
    is_connect = false,
  } = props;

  const [modalData, setModalData] = useState({
    open: false,
    type: "",
    btnOKText: "",
    btnCancelText: "",
    title: "",
    subTitle: "",
    content: "",
  });

  const onClickLeftAvatar = () => {
    if (["pic", "purchase", "comment", "connect", "post-detail"].includes(type)) {
      navigate(`/user-profile/${prefix_title}`);
    }
    if (["search"].includes(type)) {
      navigate(`/search`);
    }
    if (["explore"].includes(type)) {
      navigate(`/explore`);
    }
    if (["home"].includes(type)) {
      navigate(`/`);
    }
  };

  const onClickContent = () => {
    if (["pic", "purchase"].includes(type)) {
      navigate(`/post/detail/${payload?.post_id}`);
    }

    if (["comment"].includes(type)) {
      navigate(`/comment/${payload?.post_id}/${payload?.from_user_nickname}님의 게시물`);
    }

    if (["connect"].includes(type)) {
      navigate(`/user-profile/${payload?.from_user_nickname}`);
    }

    if (["search"].includes(type)) {
      navigate(`/search`);
    }

    if (["explore"].includes(type)) {
      navigate(`/explore`);
    }

    if (["home"].includes(type)) {
      navigate(`/`);
    }

    if (["post-detail"].includes(type)) {
      navigate(`/quiz/${payload?.redirect_value}`);
    }
  };

  const onClickRightImage = () => {
    if (notificationImageTypes.includes(type)) {
      navigate(`/post/detail/${payload?.post_id}`);
    }
  };

  const onClickConnect = async () => {
    try {
      if (is_connect) {
        await deleteConnection(payload?.from_user_id);
        toast("성공적으로 커넥트 해제되었습니다.");
      } else {
        await createConnection({ to_user_id: payload?.from_user_id });
        toast("성공적으로 커넥트되었습니다.");
      }
      fetchData();
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const handleUnblock = async () => {
    try {
      await unblockUser({
        target_user_id: user_id,
      });
      toast("차단이 해제되었습니다.");
      fetchData();
    } catch (error) {
      handleError(error, logout);
    }
  };

  const onUnblock = () => {
    setModalData({
      open: true,
      type: "confirm",
      btnOKText: "해제",
      btnCancelText: "취소",
      title: "차단 해제",
      subTitle: "",
      content: (
        <Typography
          sx={{
            ...textMediumMuiStyle,
            color: COLORS.grey900,
            lineHeight: LINE_HEIGHT.xxxLarge,
          }}
        >
          {nickname}을 차단 해제하시겠습니까?
        </Typography>
      ),
    });
  };

  //modal action
  const handleOk = () => {
    setModalData({ ...modalData, open: false });
    handleUnblock();
  };

  const handleCancel = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
  };

  return (
    <>
      <Wrapper>
        <ListItem
          sx={{
            pl: 0,
            py: 0,
            my: "1.5rem",
            alignItems: "center",
            "& .MuiListItemSecondaryAction-root": {
              right: "0px",
            },
          }}
          secondaryAction={
            notificationImageTypes.includes(type) ? (
              <WrapperImg
                onClick={onClickRightImage}
                className="bg-img"
                src={right_image}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = ImageDefault;
                }}
              />
            ) : (
              ["connect"].includes(type) && (
                <Button
                  sx={{
                    ...buttonMuiStyle,
                    p: 0.25,
                    minWidth: "80px",
                    backgroundColor: is_connect ? COLORS.grey50 : COLORS.primary4,
                    color: is_connect ? COLORS.grey600 : COLORS.white,
                    "&:hover": {
                      backgroundColor: is_connect ? COLORS.grey50 : COLORS.primary4,
                      color: is_connect ? COLORS.grey600 : COLORS.white,
                    },
                  }}
                  onClick={onClickConnect}
                >
                  {is_connect ? t("button.connecting") : t("button.connect")}
                </Button>
              )
            )
          }
        >
          <ListItemAvatar
            sx={{
              minWidth: ICON_SIZE.xMedium,
              mr: 1,
              my: 0,
              cursor: "pointer",
            }}
            onClick={onClickLeftAvatar}
          >
            <Avatar
              alt=""
              src={left_image || ImageAvatar}
              sx={{
                width: ICON_SIZE.xMedium,
                height: ICON_SIZE.xMedium,
              }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box
                sx={{ display: "flex", cursor: "pointer", maxWidth: "80%" }}
                onClick={onClickContent}
              >
                <Typography
                  // onClick={redirectToProfile}
                  sx={{
                    ...textMediumMuiStyle,
                    // fontWeight: FONT_WEIGHT.xxLarge,
                  }}
                >
                  {type == "post-detail" ? title : prefix_title}{" "}
                  <Typography component="span">{body}</Typography>
                </Typography>
              </Box>
            }
            sx={{
              my: 0,
            }}
            secondaryTypographyProps={{ component: "div" }}
            secondary={
              <Typography
                sx={{
                  ...textSmallMuiStyle,
                  ...truncateStyle,
                  fontSize: TEXTSIZE.xSmall,
                  lineHeight: LINE_HEIGHT.small,
                }}
              >
                {relativeTimeKorean(created_at)}
              </Typography>
            }
          />
        </ListItem>
      </Wrapper>
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => handleClose()}
        handleOk={() => handleOk()}
        handleCancel={() => handleCancel()}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
    </>
  );
}

export default NotificationItem;
