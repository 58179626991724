export const listBizType = [
  {
    value: 1,
    label: "개인사업자",
  },
  {
    value: 2,
    label: "일반 법인",
  },
  {
    value: 3,
    label: "비영리 법인",
  },
  {
    value: 4,
    label: "단체",
  },
];
