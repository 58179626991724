import { Box, List } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { getUserNotifications, seenAllNotifications } from "../../api";
import NoData from "../../components/NoData";
import CircularComponent from "../process/CircularComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import NotificationItem from "./NotificationItem";
import { useNavigate } from "react-router-dom";
import { WIDTH } from "../../theme/constants";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    // margin-left: 100px;
    max-width: 600px;
  }
`;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 230px);
  position: relative;
`;

function NotificationList(props) {
  const { logout } = useAuth();
  const { refreshPage } = props;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMoreData = async (newRequestParam) => {
    try {
      if (data.length == 0) {
        setIsLoading(true);
      }
      const requestParam = {
        limit: 20,
        offset: data.length,
        ...newRequestParam,
      };
      const res = await getUserNotifications(requestParam);
      setIsLoading(false);
      if (!res?.list) return;
      if (newRequestParam?.offset === 0) {
        setData(res.list);
      } else {
        setData((prevState) => [...prevState, ...res.list]);
      }
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const handleSeenAll = async (newRequestParam) => {
    try {
      await seenAllNotifications({ is_seen: 1 });
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const fetchData = () => {
    fetchMoreData({ offset: 0, limit: data.length });
    refreshPage();
  };

  useEffect(() => {
    fetchMoreData({ offset: 0 });
    handleSeenAll();
  }, []);

  if (isLoading)
    return (
      <HeightWhenEmpty>
        <CircularComponent />
      </HeightWhenEmpty>
    );

  return (
    <Wrapper>
      <Box>
        {data.length > 0 ? (
          <InfiniteScroll
            dataLength={data.length}
            next={fetchMoreData}
            hasMore={true}
            // scrollableTarget="scrollableContent"
          >
            <List
              sx={{
                width: "100%",
                // height: "calc(100vh - 80px)",
                bgcolor: "background.paper",
              }}
            >
              {data.map((user) => (
                <NotificationItem
                  {...user}
                  fetchData={fetchData}
                  key={user.id}
                />
              ))}
            </List>
          </InfiniteScroll>
        ) : (
          <HeightWhenEmpty>
            <NoData />
          </HeightWhenEmpty>
        )}
      </Box>
    </Wrapper>
  );
}

export default NotificationList;
