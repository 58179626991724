import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Charge from "./Charge";
import PicHistory from "./PicHistory";
import CalculateComponent from "../containers/withdraw/CalculateComponent";
import CalculateHistory from "../containers/withdraw/CalculateHistory";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { COLORS } from "../theme/constants";

const Wrapper = styled.div`
  .MuiTabs-scroller,
  .MuiTabs-root,
  .MuiButtonBase-root {
    min-height: 100%;
  }

  .MuiTabs-flexContainer {
    align-items: center;
    justify-content: space-around;
  }

  .tab-wrap {
    .MuiButtonBase-root {
      max-width: 100%;
      color: var(--subTextColor);
      flex: 1 0 auto;
      padding: 8px 0;

      &.Mui-selected {
        color: ${COLORS.primary4};
        .tab__icon {
          fill: ${COLORS.primary4};
        }
      }
      .tab__icon {
        fill: ${COLORS.grey500};
      }
    }

    .MuiTabs-indicator {
      height: 1px;
      background-color: ${COLORS.primary4};
    }
  }

  .tab-content__wrap {
    position: relative;

    .tab-content {
      height: 100%;
    }
  }
`;

const TabContent = styled.div``;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <TabContent
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </TabContent>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

function TabsComponent({
  tabList = [{ label: "", text: "tab", type: "gallery", data: [] }],
  defaultValue = 0,
  refetch = () => {},
}) {
  const [value, setValue] = useState(defaultValue);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };

  const tabDetail = (tabItem) => {
    switch (tabItem.type) {
      case "calculate":
        return <CalculateComponent data={tabItem.data} />;
      case "calculate_history":
        return (
          <CalculateHistory
            data={tabItem.data}
            monthlyData={tabItem.monthlyData}
          />
        );
      case "charge":
        return <Charge data={tabItem.data} />;
      case "pic_history":
        return <PicHistory tab_type={tabItem.tab_type} />;
      default:
        break;
    }
  };

  useEffect(() => {
    setValue(Number(searchParams.get("tab")));
  }, [searchParams.get("tab")]);

  return (
    <Wrapper>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          className="tab-wrap"
        >
          {tabList.map((item, index) => (
            <Tab
              data-label={item.label}
              label={item.text}
              // {...a11yProps(index)}
              key={index}
            />
          ))}
        </Tabs>
        <div className="tab-content__wrap" style={{ paddingTop: "8px" }}>
          {tabList.map((tabItem, index) => (
            <TabPanel
              key={index}
              value={value}
              index={index}
              className="tab-content"
            >
              {tabDetail(tabItem)}
            </TabPanel>
          ))}
        </div>
      </Box>
    </Wrapper>
  );
}

export default React.memo(TabsComponent);
