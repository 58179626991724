import { Box, Button, List } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NoData from "../../components/NoData";
import InfiniteScroll from "react-infinite-scroll-component";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import {
  connectInstagram,
  disconnectInstagram,
  getInstagramConnect,
} from "../../api";
import MemberConnected from "./MemberConnected";
import { COLORS, WIDTH } from "../../theme/constants";
import { buttonMuiStyle } from "../../utils/commonStyle";
import { ReactComponent as PlusCircleIcon } from "@icons/plus_circle.svg";
import { useTranslation } from "react-i18next";
import CircularComponent from "../process/CircularComponent";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }

  .icon-plus {
    path {
      stroke: ${COLORS.grey600};
    }
    circle {
      stroke: ${COLORS.grey600};
    }
  }

  .btn-add:hover {
    .icon-plus {
      path {
        stroke: ${COLORS.white};
      }
      circle {
        stroke: ${COLORS.white};
      }
    }
  }
`;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 250px);
  position: relative;
`;

function MemberConnectedInstagram() {
  const [data, setData] = useState([]);
  const popupWidth = 600;
  const popupHeight = 600;
  const redirectUri = `${process.env.REACT_APP_URL}/instagram-connecting`;
  const clientId = process.env.REACT_APP_INSTAGRAM_CLIENT_ID;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const { logout, user } = useAuth();
  const navigate = useNavigate();

  const fetchMoreDataConnected = async (newRequestParam) => {
    try {
      setIsLoading(true);
      const res = await getInstagramConnect();
      if (!res) return;

      if (newRequestParam?.offset === 0) {
        setData(res);
      } else {
        setData((prevState) => [...prevState, ...res]);
      }
    } catch (error) {
      handleError(error, logout, navigate);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConnect = async () => {
    const connectUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code`;
    const left = (screenWidth - popupWidth) / 2;
    const top = (screenHeight - popupHeight) / 2;
    window.open(
      connectUrl,
      "_blank",
      `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`
    );
  };

  const handleDisconnectInstagram = async (id) => {
    try {
      setIsLoading(true);
      await disconnectInstagram(id);
      fetchMoreDataConnected({ offset: 0 });
    } catch (error) {
      handleError(error, logout, navigate);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConnectInstagram = async (onMessageData) => {
    console.log(onMessage);
    try {
      const requestBody = {
        code: "",
        redirect_uri: redirectUri,
        ...onMessageData,
      };
      await connectInstagram(requestBody);
      fetchMoreDataConnected({ offset: 0 });
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const onMessage = (event) => {
    if (event.data.type === "instagram-connected") {
      console.log(event);
      const onMessageData = event?.data?.data;
      handleConnectInstagram(onMessageData);
    }
  };

  useEffect(() => {
    if (![2, 3].includes(user?.type)) {
      navigate("/404");
    }
    fetchMoreDataConnected({ offset: 0 });
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", () => {});
    };
  }, []);

  if (isLoading) {
    return (
      <HeightWhenEmpty>
        <CircularComponent />
      </HeightWhenEmpty>
    );
  }

  return (
    <>
      <Wrapper>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            className="btn-add"
            disabled={data?.length > 0}
            sx={{
              ...buttonMuiStyle,
              p: 1,
              // maxWidth: "150px",
              width: "fit-content",
            }}
            startIcon={<PlusCircleIcon className="icon-plus" />}
            onClick={handleConnect}
          >
            {t("button.new_profile")}
          </Button>
        </Box>
        {data?.length > 0 ? (
          <InfiniteScroll
            dataLength={data.length}
            next={() => {
              fetchMoreDataConnected({ offset: data.length });
            }}
            hasMore={true}
            // scrollableTarget="scrollableMemberConnected"
          >
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                overflowY: "auto",
                padding: 0,
              }}
              id="scrollableMemberConnected"
            >
              {data.map((user) => (
                <MemberConnected
                  {...user}
                  fetchData={() => {
                    fetchMoreDataConnected({ offset: 0 });
                  }}
                  key={user.id}
                  listItemStyle={{ maxWidth: "100%", margin: "1rem 0" }}
                  onDisconnect={handleDisconnectInstagram}
                  onConnect={handleConnect}
                />
              ))}
            </List>
          </InfiniteScroll>
        ) : (
          <HeightWhenEmpty>
            <NoData text={t("cannot_be_loaded")} />
          </HeightWhenEmpty>
        )}
      </Wrapper>
    </>
  );
}

export default MemberConnectedInstagram;
