import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";

function PaymentConnecting() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const channel = new BroadcastChannel("payment-connected");

  useEffect(() => {
    const status = searchParams.get("status");
    channel.postMessage(status);
    window.close();
  }, [params, searchParams]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <CircularProgress /> */}
      <Typography
        sx={{
          whiteSpace: "pre-line",
          textAlign: "center",
        }}
      >
        {`${
          !searchParams.get("status")
            ? "성공적으로 결제되었습니다."
            : "결제 실패."
        }\nPicaverse 연결 상태를 확인하려면 이전 데이터 변환 페이지로 돌아가세요.`}
      </Typography>
    </Box>
  );
}

export default PaymentConnecting;
