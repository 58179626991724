import { createContext, useState } from "react";
import { SORT_BY } from "../theme/constants";
// import { GlobalStateContext } from "./GlobalStateContext";

export const GlobalStateContext = createContext();

const GlobalStateProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    chat: {
      message: "",
      messageError: "",
      notification: "",
    },
    video: {
      isMuted: true,
    },
    backgroundHomeType: 0,
    isNavbarLeft: true,
  });

  const [searchQuery, setSearchQuery] = useState({
    category_id: "",
    sort_by: SORT_BY.POPULAR,
    offset: 0,
    limit: 16,
  });

  const setGlobalStateObj = (obj) => {
    setGlobalState({ ...globalState, ...obj });
  };

  const [backgroundHomeType, setBackgroundHomeType] = useState(1);
  const [callBackRedirectCategory, setCallBackRedirectCategory] = useState("");
  const [selectedPost, setSelectedPost] = useState({
    id: "",
    index: 0,
    from: "",
    nickname: "",
    category_id: "",
  });

  const [recommendPosts, setRecommendPosts] = useState([]);

  return (
    <GlobalStateContext.Provider
      value={{
        globalState,
        setGlobalState,
        selectedPost,
        setSelectedPost,
        backgroundHomeType,
        setBackgroundHomeType,
        callBackRedirectCategory,
        setCallBackRedirectCategory,
        recommendPosts,
        setRecommendPosts,
        setGlobalStateObj,
        searchQuery, setSearchQuery
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;
