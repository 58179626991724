import { Box, List, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import {
  COLORS,
  FONT_WEIGHT,
  LINE_HEIGHT,
  TEXTSIZE,
} from "../../theme/constants";
import ItemMemberConnected from "./ItemMemberConnected";
import NoData from "../../components/NoData";
import InfiniteScroll from "react-infinite-scroll-component";

const Wrapper = styled.div``;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 500px);
  position: relative;
`;

function MemberConnected(props) {
  const {  data, isTitle = false, fetchMoreData } = props;

  return (
    <>
      <Wrapper>
        {isTitle && <Box sx={{ display: "flex" }}>
          {/* <Link to={"/post/오늘의 새 콘텐츠"}> */}
          <Typography
            sx={{
              fontSize: TEXTSIZE.large,
              fontWeight: FONT_WEIGHT.xxLarge,
              lineHeight: LINE_HEIGHT.xxLarge,
              color: COLORS.black1,
            }}
          >
            친구
          </Typography>
          {/* </Link> */}
        </Box>}
        {data.length > 0 ? (
          <InfiniteScroll
            dataLength={data.length}
            next={fetchMoreData}
            hasMore={true}
            // scrollableTarget="scrollableMemberConnected"
          >
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                overflowY: "auto",
                padding: 0
              }}
              id="scrollableMemberConnected"
            >
              {data.map((user) => (
                <ItemMemberConnected {...user} key={user.id} />
              ))}
            </List>
          </InfiniteScroll>
        ) : (
          <HeightWhenEmpty>
            <NoData />
          </HeightWhenEmpty>
        )}
      </Wrapper>
    </>
  );
}

export default MemberConnected;
