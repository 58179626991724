import {
  Box,
  Button,
  Dialog,
  DialogContent,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPostDetail, updatePost } from "@api";
import MyFeedMedia from "@components/newComponents/posts/MyFeed/MyFeedMedia";
import { COLORS, TEXTSIZE, RADIUS, HEIGHT, WIDTH, LINE_HEIGHT } from "@theme/constants";
import styled from "styled-components";
import { handleError } from "@utils/commonFunction";
import { useAuth } from "../../context/Auth";
import useModalReducer, { MODAL_STATUS } from "@hooks/useModalReducer";
import ModalComponent from "@components/modal/ModalComponent";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";
import { textSmallMuiStyle, textMediumMuiStyle, textLargeMuiStyle } from "@utils/commonStyle";
import HeaderEditCategories from "@components/header/HeaderEditCategories";
import PostCategory from "./PostCategory";
import { ReactComponent as RightArrow } from "@icons/arrow_right.svg";
import { useMediaQuery } from "react-responsive";
import { GlobalStateContext } from "../../context/GlobalStateProvider";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    // margin-left: 100px;
    max-width: 600px;
  }
`;

const LIMIT_LENGTH_TITLE = 100;
const LIMIT_LENGTH_CONTENTS = 500;

function PostEdit() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const { modalData, dispatchModal } = useModalReducer();
  const [post, setPost] = useState({});
  const [requestData, setRequestData] = useState({
    title: "",
    contents: "",
  });
  const { t } = useTranslation();

  const { selectedPost } = useContext(GlobalStateContext);

  // edit category
  const [openCategory, setOpenCategory] = useState(false);
  const [categorySelected, setCategorySelected] = useState([]);

  // Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("editCost");

  // screen
  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const handleGetDetail = async () => {
    try {
      const res = await getPostDetail(selectedPost?.id);
      if (!res?.can_edit) {
        navigate(`/post/detail/${selectedPost?.id}`, {
          replace: true,
        });
      }
      setPost(res);
      setRequestData({
        title: res?.title,
        contents: res?.contents,
      });
      setCategorySelected(res?.categories);
    } catch (error) {
      const errorMessage = handleError(error, logout, navigate);
      console.log("errorMessage", errorMessage);
    }
  };

  const handleEditPost = async (updateData) => {
    try {
      const requestBody = {
        post: {
          ...requestData,
          ...updateData,
          categoryIds: categorySelected.length > 0 ? categorySelected.map((item) => item.id) : [],
        },
        // files: post.files,
      };
      await updatePost(selectedPost?.id, requestBody);
      if (selectedPost?.from) {
        navigate(`/details/${selectedPost?.from}`, {
          state: { isUpdatePostSuccess: true },
          replace: true,
        });
      } else {
        navigate(`/post/detail/${selectedPost?.id}`, {
          state: { isUpdatePostSuccess: true },
          replace: true,
        });
      }
    } catch (error) {
      const errorResponse = handleError(error, logout, navigate);
      handleOpenModalError(errorResponse?.response?.data?.message);
    }
  };

  const handleChange = (key, value) => {
    setRequestData({ ...requestData, [key]: value });
  };

  //Modal begin
  const handleOpenModalError = (content) => {
    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        type: "error",
        btnOKText: "확인",
        btnCancelText: "",
        title: "해당 게시물은 수정할 수 없어요",
        subTitle: "",
        content: (
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey900,
              lineHeight: LINE_HEIGHT.xxxLarge,
              whiteSpace: "pre-line",
            }}
          >
            {content}
          </Typography>
        ),
      },
    });
  };

  const handleOk = () => {
    dispatchModal({ type: MODAL_STATUS.CLOSE });
  };
  //Modal end

  // Start edit category
  const handleClickOpenCategory = () => {
    setOpenCategory(true);
  };

  const handleCloseCategory = () => {
    // setCategoryIds([])
    setOpenCategory(false);
  };

  const handleOkCategory = (newCategories) => {
    setOpenCategory(false);
    setCategorySelected(newCategories);
  };
  // End edit category

  //Drawer begin
  const onEditCost = () => {
    setDrawerType("editCost");
    handleDrawer(true);
  };

  const onEditLimited = () => {
    setDrawerType("editLimited");
    handleDrawer(true);
  };

  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  const handleDrawerClick = (value) => {
    if (drawerType === "editCost") {
      if (post?.type != 2) {
        handleDrawer(false);
        handleEditPost({
          cost: value,
        });
      } else {
        handleChange("cost", value);
        onEditLimited();
      }
    } else if (drawerType === "editLimited") {
      handleEditPost({
        limited: value,
      });
    }
  };
  //Drawer end

  const onHandleNext = () => {
    if (post?.cost > 0) {
      onEditCost();
    } else {
      handleEditPost();
    }
  };

  useEffect(() => {
    handleGetDetail();
  }, [selectedPost?.id]);

  return (
    <>
      <Dialog open={openCategory} onClose={handleCloseCategory} fullScreen={!isPc}>
        <DialogContent>
          <HeaderEditCategories onClickLeft={handleCloseCategory} title={"카테고리 선택"} />
          <PostCategory
            // setCategoryIds={setCategoryIds}
            categorySelected={categorySelected}
            handleSubmit={handleOkCategory}
          />
        </DialogContent>
      </Dialog>

      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame
            setOnDrawer={() => handleDrawer(false)}
            handleModal={handleDrawerClick}
            type={drawerType}
            data={post}
            // refetch={handleDrawerRefetch}
            callBack={() => navigate(-1)}
          />
        </DrawerPortal>
      )}
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleOk={handleOk}
        handleCancel={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        btnOKProps={{}}
        btnCancelProps={{}}
      />

      <Wrapper>
        <Box
          sx={{
            fontSize: TEXTSIZE.medium,
            color: COLORS.black1,
            pb: 1,
            // height: "calc(100% - 100px)",
            // overflow: "auto",
          }}
        >
          <Box>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              pagination={post?.files?.length > 1 && { type: "fraction" }}
              modules={[Pagination]}
            >
              {post?.files?.map((media, index) => (
                <SwiperSlide key={media.id}>
                  <MyFeedMedia data={media} index={index} post={post} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          <Box>
            <Box>
              <Box sx={{ mt: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <InputLabel
                    sx={{
                      color: COLORS.black1,
                      fontSize: TEXTSIZE.medium,
                      lineHeight: "18px",
                    }}
                  >
                    제목
                  </InputLabel>
                  <Typography
                    sx={{
                      color: COLORS.grey400,
                      fontSize: TEXTSIZE.small,
                    }}
                  >
                    {`${requestData?.title?.length}/${LIMIT_LENGTH_TITLE}`}
                  </Typography>
                </Box>
                <TextField
                  name='title'
                  value={requestData.title}
                  onChange={(event) => handleChange("title", event.target.value)}
                  placeholder={t("please_enter_your_email_address")}
                  sx={{
                    width: "100%",
                    height: "48px",
                    borderRadius: RADIUS.large,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: RADIUS.xxLarge,
                      backgroundColor: COLORS.grey50,
                      border: "none",
                    },
                  }}
                  inputProps={{
                    style: {
                      p: 1,
                      zIndex: 1,
                      color: COLORS.black1,
                      fontSize: TEXTSIZE.medium,
                    },
                    sx: {
                      "&::placeholder": {},
                    },
                    maxLength: LIMIT_LENGTH_TITLE,
                  }}
                  // InputProps={{
                  //   endAdornment: requestData?.title?.length > 0 && (
                  //     <InputAdornment
                  //       position="end"
                  //       sx={{
                  //         zIndex: 1,
                  //         marginBottom: "-4px",
                  //         cursor: "pointer",
                  //       }}
                  //       onClick={() => handleChange("title", "")}
                  //     >
                  //       <img src="/icon/clear.svg" />
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </Box>
              <Box sx={{ pt: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <InputLabel sx={{ color: COLORS.black1, fontSize: TEXTSIZE.medium }}>
                    내용
                  </InputLabel>
                  <Typography
                    sx={{
                      color: COLORS.grey400,
                      fontSize: TEXTSIZE.small,
                    }}
                  >
                    {`${requestData?.contents?.length}/${LIMIT_LENGTH_CONTENTS}`}
                  </Typography>
                </Box>
                <TextField
                  name='contents'
                  type='contents'
                  multiline
                  rows={8}
                  value={requestData.contents}
                  onChange={(event) => handleChange("contents", event.target.value)}
                  placeholder=''
                  sx={{
                    width: "100%",
                    // height: "48px",
                    borderRadius: RADIUS.large,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: RADIUS.xxLarge,
                      backgroundColor: COLORS.grey50,
                      border: "none",
                    },
                  }}
                  inputProps={{
                    style: {
                      p: 1,
                      zIndex: 1,
                      color: COLORS.black1,
                      fontSize: TEXTSIZE.medium,
                    },
                    sx: {
                      "&::placeholder": {},
                    },
                    maxLength: LIMIT_LENGTH_CONTENTS,
                  }}
                  // InputProps={{
                  //   endAdornment: requestData?.contents?.length && (
                  //     <InputAdornment
                  //       position="end"
                  //       sx={{
                  //         zIndex: 1,
                  //         marginBottom: "-4px",
                  //         cursor: "pointer",
                  //       }}
                  //       onClick={() => handleChange("contents", "")}
                  //     >
                  //       <img src="/icon/clear.svg" />
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </Box>
            </Box>
            {/* Start category */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                my: 4,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    ...textLargeMuiStyle,
                    lineHeight: "20px",
                  }}
                >
                  카테고리
                </Typography>
                <Typography
                  sx={{
                    ...textSmallMuiStyle,
                    lineHeight: "18px",
                    fontWeight: "400",
                  }}
                >
                  콘텐츠의 카테고리를 설정해 주세요!
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleClickOpenCategory}
              >
                {categorySelected.length > 0 && (
                  <Typography
                    component={"span"}
                    sx={{
                      ...textMediumMuiStyle,
                      lineHeight: "22px",
                      color: COLORS.primary4,
                    }}
                  >
                    {categorySelected[0].name}
                    {categorySelected.length > 1 && `+ ${categorySelected.length - 1}`}
                  </Typography>
                )}
                <RightArrow fill={COLORS.grey600} />
              </Box>
            </Box>
            {/* End category */}
          </Box>
        </Box>
        <Box sx={{ position: "sticky", bottom: 0, zIndex: 1}}>
          <Button
            sx={{
              my: 2,
              p: 2,
              // position: "absolute",
              // bottom: "0px",
              borderRadius: RADIUS.xxxLarge,
              width: "100%",
              backgroundColor: COLORS.primary4,
              color: COLORS.white,
              boxShadow: "none",
              "&:hover": {
                backgroundColor: COLORS.primary4,
                boxShadow: "none",
              },
              "&.Mui-disabled": {
                backgroundColor: COLORS.grey100,
                color: COLORS.grey600,
              },
            }}
            //   disabled={
            //     !!!requestData.password ||
            //     !!!requestData.name ||
            //     requestData.name != requestData.password ||
            //     !isValidPassword(requestData.password)
            //   }
            variant='contained'
            size='large'
            className='submit'
            onClick={onHandleNext}
          >
            저장
          </Button>
        </Box>
      </Wrapper>
    </>
  );
}

export default PostEdit;
