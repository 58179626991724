import React from "react";
import styled from "styled-components";
import DrawerPortal from "./DrawerPortal";
import ListDrawer from "../../components/Drawer/ListDrawer";
import AppDownloadDrawer from "../../components/Drawer/AppDownloadDrawer";
import SendPic from "./DrawerPurchase";
import Setting from "../../components/Drawer/Setting";
import Edit from "../../components/Drawer/Edit";
import Comment from "../../components/Drawer/Comment";
import Upload from "../../components/Drawer/Upload";
import User from "../../components/Drawer/User";
import { WIDTH } from "../../theme/constants";
import ProfileUpload from "../../components/Drawer/ProfileUpload";
import ConnectedSponsors from "../../components/Drawer/ConnectedSponsors";
import DrawerChat from "./DrawerChat";
import DrawerEditCost from "./DrawerEditCost";
import DrawerEditLimited from "./DrawerEditLimited";
import PaymentDrawer from "../../components/Drawer/PaymentDrawer";
import OtherUser from "../../components/Drawer/OtherUser";
import DrawerPost from "./DrawerPost";
import DrawerCreatePost from "./DrawerCreatePost";

const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1200;

  .drawerDim {
    z-index: 9;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    min-height: 175px;
    background-color: transparent;
    max-width: 400px;
    @media screen and (min-width: ${WIDTH.desktop}) {
      left: calc(50% + 150px);
      > div {
        // max-width: calc(${WIDTH.desktop_contents} + 32px);
        margin: 0 auto;
      }
    }

    .MuiList-root {
      .MuiListItem-root {
        &:not(:last-child) {
          border-bottom: 1px solid var(--colorF3to3);
        }
      }
    }
  }
`;

const DrawerFrame = ({
  type = "list",
  setOnDrawer,
  handleModal,
  isConnected,
  data,
  refetch,
  hadnleLottie,
  callBack,
  isMinePost,
}) => {
  // const drawerRoot = document.querySelector("#drawer-root");

  // const closeDrawer = () => {
  //   drawerRoot.style.display = "none";
  //   setOnDrawer(false);
  // };

  const getContent = () => {
    {
      switch (type) {
        case "create":
          return (
            <DrawerCreatePost
              close={setOnDrawer}
              handleModal={handleModal}
              data={data}
              callBack={callBack}
              refetch={refetch}
            />
          );
        case "list":
          return (
            <DrawerPost
              close={setOnDrawer}
              handleModal={handleModal}
              data={data}
              callBack={callBack}
              refetch={refetch}
            />
          );
        case "sendpic":
          return (
            <SendPic
              close={setOnDrawer}
              data={data}
              hadnleLottie={hadnleLottie}
              refetch={refetch}
              callBack={callBack}
            />
          );
        case "setting":
          return <Setting close={setOnDrawer} data={data} />;
        case "edit":
          return <Edit close={setOnDrawer} />;
        case "comment":
          return (
            <Comment
              close={setOnDrawer}
              data={data}
              handleModal={handleModal}
              isMinePost={isMinePost}
            />
          );
        case "user":
          return <User close={setOnDrawer} data={data} />;
        case "profile-upload":
          return <ProfileUpload close={setOnDrawer} callBack={callBack} data={data} />;
        case "otherUser":
          return <OtherUser close={setOnDrawer} data={data} handleModal={handleModal} />;
        case "connectedSponsors":
          return <ConnectedSponsors close={setOnDrawer} data={data} handleModal={handleModal} />;

        case "chat":
          return <DrawerChat close={setOnDrawer} data={data} handleModal={handleModal} />;
        case "editCost":
          return (
            <DrawerEditCost
              close={setOnDrawer}
              data={data}
              refetch={refetch}
              callBack={callBack}
              handleModal={handleModal}
            />
          );
        case "editLimited":
          return (
            <DrawerEditLimited
              close={setOnDrawer}
              data={data}
              refetch={refetch}
              callBack={callBack}
              handleModal={handleModal}
            />
          );
        case "payment":
          return <PaymentDrawer close={setOnDrawer} handleModal={handleModal} />;
        default:
          break;
      }
    }
  };

  return (
    <DrawerPortal>
      <Wrapper>
        <div className='drawerDim'>{getContent()}</div>
        {/* <div className='drawerDim'>
          {type === "list" ? (
            <ListDrawer
              close={setOnDrawer}
              handleModal={handleModal}
              data={data}
              callBack={callBack}
              refetch={refetch}
            />
          ) : type === "appDown" ? (
            <AppDownloadDrawer close={setOnDrawer} />
          ) : type === "sendpic" ? (
            <SendPic
              close={setOnDrawer}
              data={data}
              hadnleLottie={hadnleLottie}
              refetch={refetch}
              callBack={callBack}
            />
          ) : type === "setting" ? (
            <Setting close={setOnDrawer} data={data} />
          ) : type === "edit" ? (
            <Edit close={setOnDrawer} />
          ) : type === "comment" ? (
            <Comment
              close={setOnDrawer}
              data={data}
              handleModal={handleModal}
              isMinePost={isMinePost}
            />
          ) : type === "upload" ? (
            <Upload close={setOnDrawer} />
          ) : type === "user" ? (
            <User close={setOnDrawer} data={data} />
          ) : type === "profile-upload" ? (
            <ProfileUpload close={setOnDrawer} callBack={callBack} data={data} />
          ) : type === "otherUser" ? (
            <OtherUser close={setOnDrawer} data={data} handleModal={handleModal} />
          ) : type === "connectedSponsors" ? (
            <ConnectedSponsors close={setOnDrawer} data={data} handleModal={handleModal} />
          ) : type === "chat" ? (
            <DrawerChat close={setOnDrawer} data={data} handleModal={handleModal} />
          ) : type === "editCost" ? (
            <DrawerEditCost
              close={setOnDrawer}
              data={data}
              refetch={refetch}
              callBack={callBack}
              handleModal={handleModal}
            />
          ) : type === "editLimited" ? (
            <DrawerEditLimited
              close={setOnDrawer}
              data={data}
              refetch={refetch}
              callBack={callBack}
              handleModal={handleModal}
            />
          ) : type === "payment" ? (
            <PaymentDrawer close={setOnDrawer} handleModal={handleModal} />
          ) : null}
        </div> */}
      </Wrapper>
    </DrawerPortal>
  );
};

export default React.memo(DrawerFrame);
