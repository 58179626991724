import React from "react";
import { RADIUS } from "../../../../theme/constants";
import styled from "styled-components";
import ImageDefault from "../../../../assets/images/default_image.jpg";
import Overlay from "../../../overlay/Overlay";

const Wrapper = styled.div`
  aspect-ratio: 1;
  .bg-img {
    border-radius: ${RADIUS.medium};
    width: 100%;
    height: 100%;
  }
`;

function RecommendMedia(props) {
  const { data, todayNewMediaStyle = {} } = props;
  return (
    <Wrapper style={todayNewMediaStyle}>
      <img
        className="bg-img"
        src={!data ? ImageDefault : data?.view_url || data?.url}
        // src={!data ? ImageDefault : data?.view_url || data?.small_url}
        style={{}}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = ImageDefault;
        }}
      />
      <Overlay
        styleCustomize={{
          borderRadius: RADIUS.medium,
        }}
      />
    </Wrapper>
  );
}

export default RecommendMedia;
