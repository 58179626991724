import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../assets/svgs/close.svg";
import { ReactComponent as PicedIcon } from "../../assets/svgs/piced.svg";
import { ReactComponent as PicedDoubleIcon } from "../../assets/svgs/piced_double.svg";
import { ReactComponent as PlusIcon } from "../../assets/svgs/plus.svg";
import { ReactComponent as PurchasePurpleIcon } from "@icons/purchase_purple.svg";
import {
  COLORS,
  FONT_WEIGHT,
  LINE_HEIGHT,
  RADIUS,
  TEXTSIZE,
} from "../../theme/constants";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { getUserBalance, unlockOrDonatePost } from "../../api";
import { toast } from "react-toastify";
import {
  buttonMuiStyle,
  inputAdornmentMuiStyle,
  inputMuiStyle,
  textFieldMuiStyle,
  textMediumMuiStyle,
  textSmallMuiStyle,
} from "../../utils/commonStyle";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import ModalComponent from "../../components/modal/ModalComponent";
import InformationIcon from "../../assets/svgs/information.svg";
import { NumericFormatCustom } from "../../components/numberic/NumbericFormatCustom";

const Wrapper = styled.div`
  border-radius: ${RADIUS.large} ${RADIUS.large} 0 0;
  padding: 1.25rem;
  background: ${COLORS.white};

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .button-close {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .text-area {
    margin-top: 32px;
    text-align: center;
    line-height: 20.4px;
  }

  .text-title {
    font-size: ${TEXTSIZE.large};
    font-size: 700;
    line-height: 27px;
    margin-bottom: 7px;
  }

  .text-sub,
  .button-charge {
    font-size: ${TEXTSIZE.small};
    color: ${COLORS.gray_7};
    margin: 4px auto;
  }

  .button-charge {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .list-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    width: -webkit-fill-available;
    height: 100%;
  }

  .radio-wrap {
    .MuiFormControlLabel-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 1.5rem 0 0;
      padding: 0.75rem;

      &:has(.Mui-checked),
      &:hover {
        background-color: ${COLORS.grey50};
        border-radius: ${RADIUS.medium};
      }
    }
    .double-icon {
      span {
        width: 43px !important;
      }
    }
    .MuiButtonBase-root {
      padding: 0;
      margin-bottom: 0.25rem;
    }
  }

  .button-custom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    min-height: 72px;
    cursor: pointer;

    .icon-wrap {
      display: flex;
      align-items: center;
      height: 42px;
    }
  }

  .error-message {
    color: var(--notification_errorLight);
    font-size: ${TEXTSIZE.small};
    margin: 8px 0;
    height: 20px;
  }

  .button-submit {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--mainColor);
    color: ${COLORS.white};

    &:hover {
      text-decoration: underline;
      background-color: var(--mainColor);
    }
  }
`;

const BpIcon = styled.span`
  width: 24px;
  height: 24px;
`;

const BpCheckedIcon = styled(BpIcon)`
  position: relative;
  width: 24px;
  height: 24px;
`;

function BpRadioOne(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={
        <BpCheckedIcon>
          <PicedIcon stroke={COLORS.primary4} />
        </BpCheckedIcon>
      }
      icon={
        <BpIcon>
          <PicedIcon stroke={COLORS.primary4} />
        </BpIcon>
      }
      {...props}
    />
  );
}

function BpDoubleIconRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      className="double-icon"
      checkedIcon={
        <BpCheckedIcon>
          <PicedDoubleIcon className="icon-stroke__main-color" />
        </BpCheckedIcon>
      }
      icon={
        <BpIcon>
          <PicedDoubleIcon className="icon-stroke__main-color" />
        </BpIcon>
      }
      {...props}
    />
  );
}

function DrawerEditCost({ close, data, refetch, handleModal }) {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [picValue, setPicValue] = useState(0);
  const [isCustom, setIsCustom] = useState(true);
  const [errorMessages, setErrorMessages] = useState("");

  const [userBalance, setUserBalance] = useState({
    charge: 0,
    revenue: 0,
    bonus: 0,
    event: 0,
    pic_in_month: 0,
    balance: 0,
    picSuggestion: [],
  });

  const [modalData, setModalData] = useState({
    open: false,
    type: "",
    btnOKText: "",
    btnCancelText: "",
    title: "",
    subTitle: "",
    content: "",
  });

  //begin modal
  const onCharge = () => {
    setModalData({
      open: true,
      type: "",
      btnOKText: "확인",
      btnCancelText: "",
      title: "알림",
      subTitle: "이 기능은 앱에서만 사용할 수 있습니다.",
      content: "",
    });
  };

  const handleOk = () => {
    setModalData({ ...modalData, open: false });
  };

  //handle change
  const onChangeValue = (e) => {
    setPicValue(e.target.value);
    setErrorMessages("");
  };

  const handleRadioChange = (event) => {
    setErrorMessages("");
    setPicValue(event.target.value);
  };

  const onClickCustom = () => {
    setIsCustom(true);
    setPicValue(0);
    setErrorMessages("");
  };

  const handleSubmit = () => {
    handleModal(picValue);
    // try {
    //   if (userBalance?.balance < picValue) {
    //     setErrorMessages("PIC이 부족해요!");
    //     return;
    //   }

    //   await unlockOrDonatePost(data.id, {
    //     pic: +picValue,
    //     type: "donate",
    //   });
    //   refetch();
    //   close();
    //   toast(`${picValue}PIC을 보냈습니다.`);
    // } catch (error) {
    //   const err = handleError(error, logout, navigate);
    //   if (typeof err === "object" && err !== null) {
    //     setErrorMessages(err);
    //   } else {
    //     console.log("Donate Post", err);
    //   }
    // }
  };

  // const handleGetMyBalance = async () => {
  //   try {
  //     const res = await getUserBalance();
  //     if (res) {
  //       setUserBalance(res);
  //     }
  //   } catch (error) {
  //     handleError(error, logout);
  //   }
  // };

  useEffect(() => {
    console.log("data", data);
    setPicValue(data?.cost);
  }, []);

  return (
    <>
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleOk={handleOk}
      />
      <Wrapper>
        <IconButton
          onClick={close}
          sx={{
            width: "fit-content",
            marginLeft: "auto",
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* <Box sx={{ mb: 1.5 }}> */}
        <Typography
          sx={{
            fontSize: TEXTSIZE.large,
            fontWeight: FONT_WEIGHT.xxLarge,
            lineHeight: LINE_HEIGHT.xxxxLarge,
            color: COLORS.black1,
            mb: 1.5,
          }}
        >
          잠금 해제 PIC 개수
        </Typography>
        {/* <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey600,
              fontSize: TEXTSIZE.small,
              mb: 0.5,
            }}
          >
            현재 보유하고 있는 PIC : {userBalance.balance}
          </Typography>
          <Typography
            sx={{
              lineHeight: LINE_HEIGHT.xLarge,
              fontWeight: FONT_WEIGHT.large,
              color: COLORS.primary4,
              fontSize: TEXTSIZE.small,
              cursor: "pointer",
            }}
            onClick={onCharge}
          >
            충전하기
          </Typography> */}
        {/* </Box> */}

        <TextField
          placeholder="PIC 개수 입력"
          sx={{
            ...textFieldMuiStyle,
            "& .MuiInputBase-root": {
              height: "48px",
              paddingLeft: 0.75,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              backgroundColor: COLORS.grey50,
              border: "none",
              borderRadius: "8px",
            },
          }}
          inputProps={{
            sx: {
              ...inputMuiStyle,
              fontWeight: TEXTSIZE.large,
              fontWeight: FONT_WEIGHT.xxLarge,
            },
            min: 0,
          }}
          value={picValue}
          // onChange={(e) => setPicValue(e.target.value)}
          onChange={onChangeValue}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="end"
                sx={{ ...inputAdornmentMuiStyle, mr: 1 }}
              >
                {/* <PicedIcon stroke={COLORS.primary4} /> */}
                <PurchasePurpleIcon />
              </InputAdornment>
            ),
            inputComponent: NumericFormatCustom,
          }}
        />

        <Box sx={{ mt: 1.5, display: "flex" }}>
          {/* <InformationIcon
            style={{
              width: "16px",
              height: "16px",
            }}
          /> */}
          <img
            src={InformationIcon}
            style={{
              width: "1rem",
              height: "1rem",
              marginRight: "0.25rem",
            }}
          />
          <Typography
            sx={{
              ...textMediumMuiStyle,
              fontSize: TEXTSIZE.small,
              color: COLORS.grey600,
            }}
          >
            {
              "유료 게시물은 업로드 이후, 픽을 받은 순간부터는 수정이 불가능해요. 이미지와 문구를 다시 확인해주세요!"
            }
          </Typography>
        </Box>

        <Typography
          sx={{
            ...textMediumMuiStyle,
            fontSize: TEXTSIZE.small,
            color: COLORS.redError,
          }}
        >
          {errorMessages}
        </Typography>
        <Button
          sx={{
            ...buttonMuiStyle,
            mt: 2.5,
          }}
          // disabled={!!errorMessages || picValue == 0}
          variant="contained"
          size="large"
          onClick={handleSubmit}
        >
          {data?.type == 2 ? "다음" : "등록"}
        </Button>
      </Wrapper>
    </>
  );
}

export default React.memo(DrawerEditCost);
