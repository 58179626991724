import { Avatar, Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useNavigate } from "react-router-dom";
import {
  answerQuizPost,
  createConnection,
  deleteConnection,
  deletePost,
  getPostDetail,
} from "@api";
import MyFeedMedia from "@components/newComponents/posts/MyFeed/MyFeedMedia";
import { ReactComponent as MoreIcon } from "@icons/more.svg";
import { ICON_SIZE, COLORS, LINE_HEIGHT, TEXTSIZE } from "@theme/constants";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";
import ModalComponent from "@components/modal/ModalComponent";
import { toast } from "react-toastify";
import { handleError, isMine } from "@utils/commonFunction";
import { useAuth } from "../../context/Auth";
import MyFeedMediaVideo from "@components/newComponents/posts/MyFeed/MyFeedMediaVideo";
import { useTranslation } from "react-i18next";
import QuizEvent from "@images/quiz-event.png";
import ClockImage from "@images/clock.png";
import { buttonLargeMuiStyle, truncateStyle, textMediumMuiStyle } from "@utils/commonStyle";
import Countdown, { zeroPad } from "react-countdown";
import QuizAnswer from "./QuizAnswer";
import dayjs from "dayjs";
import Author from "../author/Author";
import ImageAvatar from "@images/avatar.png";
import ButtonConnect from "../../components/connect/ButtonConnect";
import { convertResponseTimeToLocalTime } from "../../utils/dateTimeFormat";

const REACT_APP_WEB_SHARE_URL = process.env.REACT_APP_WEB_SHARE_URL;

function QuizHomeItem(props) {
  const { post, isPostInView = true, onVideoInView = () => {}, onVideoOutView = () => {} } = props;
  const { logout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [quizAnswer, setQuizAnswer] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentPost, setCurrentPost] = useState({});
  const [modalData, setModalData] = useState({
    open: false,
    btnOKText: "",
    btnCancelText: "",
    title: "",
    subTitle: "",
    content: "",
  });

  // Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("list");
  const [dateCountdown, setDateCountdown] = useState(dayjs().unix() + 1000);

  const isBeforeStart = () => {
    return dayjs().isBefore(convertResponseTimeToLocalTime(post?.event_start));
  };

  const isGoingOn = () => {
    return (
      dayjs().isAfter(convertResponseTimeToLocalTime(post?.event_start)) &&
      dayjs().isBefore(convertResponseTimeToLocalTime(post?.event_end))
    );
  };

  const isEnd = () => {
    return dayjs().isAfter(convertResponseTimeToLocalTime(post?.event_end));
  };

  const handleMore = () => {
    setDrawerType("list");
    handleDrawer(true);
  };

  const fetchData = async () => {
    try {
      const res = await getPostDetail(currentPost?.id);
      setCurrentPost(res);
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const handleDrawerRefetch = () => {
    fetchData();
  };

  // end donate

  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  //  Delete begin
  const handleOpenModalErrorDelete = (value) => {
    setModalData({
      type: "error",
      open: true,
      title:
        currentPost?.cost > 0
          ? t("dialog.title.can_not_delete_post", { emotion: "(:" })
          : t("dialog.title.can_not_delete_post"),
      subTitle: "",
      btnOKText: "확인",
      btnCancelText: "",
      content: (
        <Typography
          sx={{
            ...textMediumMuiStyle,
            color: COLORS.grey900,
            lineHeight: LINE_HEIGHT.xxxLarge,
            whiteSpace: "pre-line",
          }}
        >
          {value}
        </Typography>
      ),
    });
  };

  const handleDeletePost = async () => {
    try {
      await deletePost(currentPost.id);
      navigate(0);
    } catch (error) {
      const errorResponse = handleError(error, logout, navigate);
      handleOpenModalErrorDelete(errorResponse?.response?.data?.message);
    }
  };

  const openModalDelete = () => {
    if (!currentPost.can_delete) {
      setModalData({
        type: "delete",
        open: true,
        title:
          currentPost?.cost > 0
            ? t("dialog.title.can_not_delete_post", { emotion: "(:" })
            : t("dialog.title.can_not_delete_post"),
        subTitle: "",
        btnOKText: "확인",
        btnCancelText: "",
        content: (
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey900,
              lineHeight: LINE_HEIGHT.xxxLarge,
              whiteSpace: "pre-line",
            }}
          >
            {currentPost?.cost > 0
              ? t("dialog.content.can_not_delete_purchased_post")
              : t("dialog.content.can_not_delete_sponsored_post")}
          </Typography>
        ),
      });
    } else {
      setModalData({
        type: "delete",
        open: true,
        title: t("dialog.title.delete_post"),
        subTitle: "",
        btnOKText: t("button.delete"),
        btnCancelText: t("button.cancel"),
        content: (
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey900,
              lineHeight: LINE_HEIGHT.xxxLarge,
              whiteSpace: "pre-line",
            }}
          >
            {t("dialog.content.delete_post")}
          </Typography>
        ),
      });
    }
  };
  // Delete end

  const handleModal = (type = "select") => {
    switch (type) {
      case "share":
        navigator.clipboard.writeText(
          `${REACT_APP_WEB_SHARE_URL}/quiz/${currentPost.id}/${currentPost?.user_profile?.user_id}`
        );
        break;
      case "delete":
        openModalDelete();
        break;
      default:
        break;
    }
  };

  //START modal action
  const handleOk = () => {
    handleClose();

    switch (modalData.type) {
      case "answerQuiz":
        handleConfirmAnswer();
        break;
      case "connect":
        handleConnect();
        break;
      case "delete":
        if (currentPost.can_delete) {
          handleDeletePost();
        }
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
  };

  const isDisableBtnOk = () => {
    let result = false;
    if (!quizAnswer && modalData?.type == "answerQuiz") result = true;
    return result;
  };
  //END modal action

  // START QUIZ
  const handleAnswer = () => {
    if (isMine(currentPost?.user_profile?.user_id)) {
      navigate(`/quiz/${currentPost?.id}/status`);
      return;
    }

    if (!currentPost?.connection) {
      const content = (
        <>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "24px",
              color: COLORS.black1,
              whiteSpace: "pre-line",
              textAlign: "center",
              mb: 2,
            }}
          >
            {t("dialog.content.quiz_need_connect")}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
            <Avatar
              sx={{
                height: "100px",
                width: "100px",
              }}
              src={currentPost?.user_profile?.profile_image_url || ImageAvatar}
            />
          </Box>
        </>
      );
      setModalData({
        ...modalData,
        type: "connect",
        open: true,
        title: "",
        subTitle: "",
        btnOKText: t("button.quiz_need_connect"),
        btnCancelText: "",
        content: content,
      });
      return;
    }

    if (isBeforeStart()) {
      const content = (
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "24px",
            color: COLORS.black1,
            whiteSpace: "pre-line",
            textAlign: "center",
            mb: 4,
          }}
        >
          {t("dialog.content.quiz_will_start", {
            time: convertResponseTimeToLocalTime(post?.event_start).format("HH:mm"),
          })}
        </Typography>
      );
      setModalData({
        ...modalData,
        type: "not_started",
        open: true,
        title: "",
        subTitle: "",
        btnOKText: t("button.check"),
        btnCancelText: "",
        content: content,
      });
      return;
    }

    setModalData({
      type: "answerQuiz",
      open: true,
      btnOKText: t("button.check"),
      btnCancelText: t("button.cancel"),
      title:
        currentPost?.quiz?.[0]?.answer_type == "text" ? (
          <p style={{ textAlign: "center" }}>정답 입력</p>
        ) : (
          <p>정답은 무엇일까요?</p>
        ),
      content: (
        <QuizAnswer
          setQuizAnswer={setQuizAnswer}
          answers={currentPost?.quiz?.[0]?.answer}
          answer_type={currentPost?.quiz?.[0]?.answer_type}
          answer_length={currentPost?.quiz?.[0]?.answer_length}
        />
      ),
    });
  };

  const handleConfirmAnswer = async () => {
    try {
      await answerQuizPost(currentPost.id, {
        quiz: [
          {
            question_id: currentPost?.quiz?.[0]?.question_id, // question_id
            answer: quizAnswer, // choice: answer id | text: answer content
          },
        ],
      });
      toast("퀴즈 이벤트 참여가 완료되었습니다.", {
        style: {
          marginTop: "-80px",
        },
      });
      fetchData();
    } catch (error) {
      const errorMessage = handleError(error, logout, navigate);
      toast(errorMessage);
    }
  };
  // END QUIZ

  //connection action
  const handleConnect = async () => {
    try {
      if (currentPost.connection) {
        await deleteConnection(currentPost?.user_profile?.user_id);
        toast(t("disconnected_successfully"));
      } else {
        await createConnection({
          to_user_id: currentPost?.user_profile?.user_id,
        });
        toast(t("connected_successfully"));
      }
      fetchData();
    } catch (error) {
      handleError(error, logout);
    }
  };

  const getTextButtonAnswer = () => {
    // is owner
    if (isMine(post?.user_profile?.user_id)) {
      return t("button.participation_status");
    }

    // is user
    if (isBeforeStart()) {
      return t("button.answer_correctly_and_receive", {
        cost: currentPost?.cost,
      });
    }

    if (isGoingOn()) {
      // is_bonus == is answer
      if (!currentPost?.is_bonus) {
        return t("button.answer_correctly_and_receive", {
          cost: currentPost?.cost,
        });
      } else {
        return t("button.go_to_the_correct_answer");
      }
    }

    if (isEnd()) {
      if (currentPost?.is_bonus) {
        return t("button.participation_completed");
      } else {
        return t("button.quiz_end");
      }
    }
  };

  const getTextCountdownTimer = () => {
    if (isBeforeStart()) {
      return t("until_the_quiz_starts");
    }

    if (isGoingOn()) {
      return t("until_the_end_of_the_quiz");
    }

    if (isEnd()) {
      return t("until_the_end_of_the_quiz");
    }
  };

  const handleCompleteCountdown = () => {
    if (Math.abs(dayjs().diff(convertResponseTimeToLocalTime(post?.event_start))) < 1000) {
      setDateCountdown(convertResponseTimeToLocalTime(post?.event_end).toString());
    }
  };

  useEffect(() => {
    setCurrentPost(post);
    if (isBeforeStart()) {
      setDateCountdown(convertResponseTimeToLocalTime(post?.event_start).toString());
    } else {
      setDateCountdown(convertResponseTimeToLocalTime(post?.event_end).toString());
    }
  }, [post]);

  const rendererCountdown = ({ hours, minutes, seconds, completed }) => {
    return (
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: COLORS.white,
            // minWidth: "40px"
          }}
        >
          {zeroPad(hours)}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: COLORS.grey300,
            marginTop: "-5px",
          }}
        >
          :
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: COLORS.white,
            // minWidth: "40px"
          }}
        >
          {zeroPad(minutes)}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: COLORS.grey300,
            marginTop: "-5px",
          }}
        >
          :
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: COLORS.white,
            // minWidth: "40px",
          }}
        >
          {zeroPad(seconds)}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame
            setOnDrawer={() => handleDrawer(false)}
            handleModal={handleModal}
            type={drawerType}
            data={currentPost}
            refetch={handleDrawerRefetch}
            callBack={() => navigate(-1)}
          />
        </DrawerPortal>
      )}

      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => handleClose()}
        handleOk={() => handleOk()}
        handleCancel={() => handleClose()}
        btnOKProps={{
          disabled: isDisableBtnOk(),
        }}
        btnCancelProps={{}}
      />
      <Box sx={{ minHeight: "calc(100vh - 56px - 80px)" }}>
        {currentPost && (
          <Box sx={{ fontSize: TEXTSIZE.medium, color: COLORS.black1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Author
                authorData={{
                  id: currentPost?.user_profile?.user_id,
                  avatar: currentPost?.user_profile?.profile_image_url,
                  name: currentPost?.user_profile?.nickname,
                  job: currentPost?.user_profile?.introduce,
                }}
                authorStyle={{
                  width: "calc(100% - 90px)",
                  gap: "1rem",
                }}
                avatarStyle={{
                  width: ICON_SIZE.large,
                  height: ICON_SIZE.large,
                }}
                nameStyle={{
                  color: COLORS.black1,
                  fontSize: TEXTSIZE.medium,
                  lineHeight: LINE_HEIGHT.xLarge,
                }}
                jobStyle={{
                  color: COLORS.grey600,
                  fontSize: TEXTSIZE.medium,
                  lineHeight: LINE_HEIGHT.xLarge,
                }}
              />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {!isMine(currentPost?.user_profile?.user_id) && (
                  <ButtonConnect
                    isConnect={!!currentPost?.connection}
                    userId={currentPost?.user_profile?.user_id}
                    buttonProps={{ marginRight: "1rem", height: "20px" }}
                    handleConnectSuccess={() => fetchData()}
                    textCancelConnect={t("button.connecting")}
                    textConnect={t("button.connect")}
                  />
                )}
                <Box
                  sx={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                  }}
                >
                  <MoreIcon onClick={() => handleMore()} />
                </Box>
              </Box>
            </Box>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              pagination={
                currentPost?.files?.length > 1 && currentPost?.is_paid && { type: "fraction" }
              }
              modules={[Pagination]}
              onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            >
              {currentPost?.files?.length > 0 &&
                currentPost.files.map((media, indexMedia) => (
                  <SwiperSlide key={media.id}>
                    <Box sx={{ width: "100%" }}>
                      {media?.type === "video" ? (
                        <MyFeedMediaVideo
                          data={media}
                          onInView={onVideoInView}
                          onOutView={onVideoOutView}
                          isPlay={activeIndex === indexMedia && isPostInView}
                          onClickMedia={() => {
                            if (activeIndex === indexMedia) {
                              // screenfull.request(
                              //   document.querySelector(
                              //     `#react-player-${media?.id}`
                              //   )
                              // );
                              // setActiveIndex();
                            } else {
                              setActiveIndex(indexMedia);
                            }
                          }}
                        />
                      ) : (
                        <MyFeedMedia data={media} index={indexMedia} post={currentPost} />
                      )}
                    </Box>
                  </SwiperSlide>
                ))}
            </Swiper>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "12px 0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  borderRadius: "8px",
                  padding: "6px 12.5px",
                  backgroundColor: "#F2F2F2",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <img src={ClockImage} style={{ width: "20px" }} />
                  <Typography
                    sx={{
                      fontSize: "8px",
                      fontWeight: "500",
                      lineHeight: "12px",
                      color: COLORS.black1,
                    }}
                  >
                    {getTextCountdownTimer()}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundColor: COLORS.grey900,
                    borderRadius: 1,
                    p: "0.25rem 1.25rem",
                  }}
                >
                  <Countdown
                    key={dateCountdown}
                    date={dateCountdown}
                    onComplete={handleCompleteCountdown}
                    renderer={rendererCountdown}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {/* START QUIZ */}
        <Box
          sx={{
            padding: "0px 12px",
          }}
        >
          <Box sx={{ mb: 3 }}>
            <Box
              sx={{
                display: "flex",
                mb: 1,
              }}
            >
              <img src={QuizEvent} style={{ width: "16px" }} />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "14px",
                  color: COLORS.primary6,
                }}
              >
                Quiz Event
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                color: COLORS.black1,
                ...truncateStyle,
              }}
            >
              {currentPost?.contents}
            </Typography>
          </Box>
          <Button
            sx={{
              ...buttonLargeMuiStyle,
            }}
            variant='contained'
            size='large'
            className='submit'
            onClick={handleAnswer}
            disabled={
              (!isMine(currentPost?.user_profile?.user_id) &&
                dayjs().isAfter(convertResponseTimeToLocalTime(post?.event_end))) ||
              currentPost?.is_bonus
            }
          >
            {getTextButtonAnswer()}
          </Button>
        </Box>
        {/* END QUIZ */}
      </Box>
    </>
  );
}

export default QuizHomeItem;
