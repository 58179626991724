import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { COLORS, RADIUS, TEXTSIZE } from "../../theme/constants";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";

const ListStyleWrap = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;

  .list-drawer {
    background: ${COLORS.white};
    margin: 0 16px;
    padding: 0;
    border-radius: ${RADIUS.medium};
    overflow: hidden;

    &__button {
      width: calc(100% - 32px);
      margin: 12px 16px 20px;
      background: ${COLORS.white};
      border: 0;
      font-size: ${TEXTSIZE.medium};
      color: ${COLORS.black};
      border-radius: ${RADIUS.medium};
    }

    .warning {
      color: var(--notification_errorLight);
    }
  }

  .MuiButtonBase-root {
    padding: 0;
    height: 56px;
    background-color: var(--bgColor);
    color: var(--textColor);

    &:hover {
      background-color: var(--bgColor);
      text-decoration: underline;
    }

    * {
      height: 100%;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${TEXTSIZE.medium};

      &.warning {
        color: var(--notification_errorLight);
      }
    }

    .point {
      color: var(--mainColor);
    }
  }
`;

function UploadDrawer({ close }) {
  // const navigate = useNavigate();
  const { t } = useTranslation();

  const modalData = [
    {
      text: "게시물 업로드",
      fn: () => {
        console.log("게시물 업로드");
      },
    },
    {
      text: "유료 게시물 업로드",
      fn: () => {
        console.log("유료 게시물 업로드");
      },
    },
  ];

  return (
    <>
      <ListStyleWrap>
        <List className='list-drawer'>
          {modalData.map((item, index) => (
            <ListItem disablePadding key={index}>
              <ListItemButton>
                <ListItemText
                  primary={item.text}
                  onClick={item.fn}
                  className={item.text.includes("유료") ? "point" : null}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Button
          variant='text'
          size='large'
          className='list-drawer__button'
          onClick={close}>
          {t("button.close")}
        </Button>
      </ListStyleWrap>
    </>
  );
}

export default React.memo(UploadDrawer);
