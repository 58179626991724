import arrowRightImage from "@images/arrowRight.svg";
import { Dialog, Button } from "@mui/material";
import { COLORS, RADIUS } from "@theme/constants";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import ButtonUpload from "../uploadCloudflare/ButtonUpload";
import styled from "styled-components";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 40px)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "16px",
  padding: "24px 20px",
  textAlign: "center",
};
const Dot = () => {
  return (
    <div
      style={{
        width: "4px",
        minWidth: "4px",
        height: "4px",
        borderRadius: "4px",
        backgroundColor: "#3B3B3B",
        margin: "6px",
        marginLeft: 0,
      }}
    ></div>
  );
};

const InputWithoutPickNum = styled.input`
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Radio = ({ value, onChange, label }) => {
  // const [value, setChecked] = useState(false);
  return (
    <div
      onClick={onChange}
      style={{ cursor: "pointer", display: "flex", gap: "8px" }}
    >
      <div
        style={{
          width: "24px",
          height: "24px",
          borderRadius: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
          border: "2px solid #D9D9D9",
          borderColor: value ? "#1E1E1E" : "#D9D9D9",
        }}
      >
        <div
          style={{
            width: "12px",
            height: "12px",
            borderRadius: "6px",
            backgroundColor: value ? "#1E1E1E" : "",
          }}
        ></div>
      </div>

      <p
        style={{
          fontSize: "14px",
          lineHeight: "18px",
          fontWeight: 500,
          marginTop: "3px",
        }}
      >
        {label}
      </p>
    </div>
  );
};

const CheckBox = ({ onChange, value }) => {
  return (
    <div
      onClick={() => onChange(!value)}
      style={{ cursor: "pointer", display: "flex", gap: "8px" }}
    >
      <div
        style={{
          width: "24px",
          height: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "4px",
          backgroundColor: value ? "#1E1E1E" : "white",
          border: "1px solid #D9D9D9",
          borderColor: value ? "#1E1E1E" : "#D9D9D9",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M3 8L6.75 12L13 4"
            stroke={value ? "white" : "#D9D9D9"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <p
        style={{
          fontSize: "14px",
          lineHeight: "18px",
          fontWeight: 500,
          marginTop: "3px",
        }}
      >
        개인정보수집동의
      </p>
    </div>
  );
};
const ConsentPayment = ({ nextStep, register, setValue, getValues }) => {
  const [isShowImgErrModal, setIsShowImgErrModal] = useState(false);

  const handleChangeRadio = (value) => () => {
    setValue("type", value, {
      shouldValidate: true,
    });

    setValue("personalName", "");
    setValue("prefixResidentNumber", "");
    setValue("suffixResidentNumber", "");
    setValue("companyName", "");
    setValue("companyNumber", "");
    setValue("companyImage", "");
    setValue("companyImage", "");
    setValue("totalMoney", 0);
  };

  const isEnableButton =
    !!(
      getValues("isAccess") &&
      getValues("personalName") &&
      getValues("prefixResidentNumber") &&
      getValues("suffixResidentNumber")
    ) ||
    !!(
      getValues("isAccess") &&
      getValues("companyName") &&
      getValues("companyNumber") &&
      getValues("companyImage")
    );

  return (
    <div
      style={{
        //   maxWidth: "360px",
        margin: "auto",
        backgroundColor: "white",
        wordBreak: "keep-all",
        color: "#1E1E1E",
        display: "flex",
        flexDirection: "column",
        minHeight: "90svh",
      }}
    >
      <div
        style={{
          padding: "16px",
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <div style={{ flex: 1, textAlign: "center" }}>
          <p
            style={{
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "24px",
            }}
          >
            창작파트너 후원하기
          </p>
        </div>
      </div>
      <form style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <div style={{ flex: 1 }}>
          <div style={{ padding: "0 20px", borderBottom: "1px solid #EBEBEB" }}>
            <div
              style={{
                backgroundColor: "#F5F5F5",
                borderRadius: "8px",
                padding: "20px 16px",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 400,
                color: "#3B3B3B",
              }}
            >
              <p style={{ marginBottom: 12 }}>
                피카버스는 창작파트너(크리에이터)의 보다 나은 창작 활동을 위한
                후원을 제공합니다.
              </p>

              <div style={{ display: "flex" }}>
                <Dot />
                <p>
                  수집한 개인정보 및 기업정보는 후원 내역 관리 및 크리에이터
                  후원자 관리를 위해서만 사용되며, 관련 법령에 따라 5년간
                  보관됩니다.
                </p>
              </div>

              <div style={{ display: "flex", marginTop: "8px" }}>
                <Dot />
                <p>
                  피카버스는 후원자의 이름, 주민등록번호의 앞 6자리 정보에 대해
                  회원 정보와 별도로 구분하여 보유합니다. 위 정보는 피카버스
                  개인정보처리방침에 따라 후원 내역 관리 및 크리에이터 후원자
                  관리에 사용됩니다.
                </p>
              </div>
            </div>

            <div style={{ marginTop: "15px", marginBottom: "26px" }}>
              <CheckBox
                onChange={(value) => {
                  console.log(value);
                  setValue("isAccess", value, {
                    shouldValidate: true,
                  });
                }}
                value={getValues("isAccess")}
              />
            </div>
          </div>

          <div style={{ padding: "24px 20px 20px" }}>
            <div style={{ display: "flex", gap: "32px", marginBottom: "24px" }}>
              <Radio
                value={getValues("type") === "personal"}
                onChange={handleChangeRadio("personal")}
                label="개인"
              />

              <Radio
                value={getValues("type") === "enterprise"}
                onChange={handleChangeRadio("enterprise")}
                label="기업"
              />
            </div>
            {getValues("type") === "personal" ? (
              <>
                {" "}
                <div>
                  <p
                    style={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: 500,
                    }}
                  >
                    이름
                  </p>

                  <input
                    type="text"
                    name="personalName"
                    style={{
                      padding: "12px",
                      borderRadius: "8px",
                      border: "1px solid #D9D9D9",
                      width: "100%",
                      marginTop: "8px",
                      maxWidth: 600,
                    }}
                    placeholder="이름을 입력해주세요."
                    {...register("personalName", { required: true })}
                  />
                  {/* {errors.email && <span>This field is required</span>} */}
                </div>
                <div style={{ marginTop: "20px" }}>
                  <p
                    style={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: 500,
                    }}
                  >
                    주민등록번호
                  </p>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 6,
                      maxWidth: 600,
                    }}
                  >
                    <InputWithoutPickNum
                      type="number"
                      style={{
                        padding: "12px",
                        borderRadius: "8px",
                        border: "1px solid #D9D9D9",
                        width: "100%",
                        marginTop: "8px",
                        height: 40,
                      }}
                      placeholder="주민등록번호 앞 6자리"
                      {...register("prefixResidentNumber", {
                        required: true,
                      })}
                    />

                    <div
                      style={{
                        width: 16,
                        height: 2,
                        background: "#707070",
                        marginTop: "8px",
                      }}
                    ></div>

                    <input
                      type="password"
                      style={{
                        padding: "12px",
                        borderRadius: "8px",
                        border: "1px solid #D9D9D9",
                        width: "100%",
                        marginTop: "8px",
                        height: 40,
                      }}
                      placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                      {...register("suffixResidentNumber", {
                        required: true,
                      })}
                    />
                  </div>
                  {/* {errors.nickname && <span>This field is required</span>} */}
                </div>
              </>
            ) : (
              //enterprise start
              <>
                <div>
                  <p
                    style={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: 500,
                    }}
                  >
                    기업명
                  </p>

                  <input
                    type="text"
                    style={{
                      padding: "12px",
                      borderRadius: "8px",
                      border: "1px solid #D9D9D9",
                      width: "100%",
                      marginTop: "8px",
                      maxWidth: 400,
                    }}
                    placeholder="이름을 입력해주세요."
                    {...register("companyName", { required: true })}
                  />
                  {/* {errors.email && <span>This field is required</span>} */}
                </div>
                <div style={{ marginTop: "20px" }}>
                  <p
                    style={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: 500,
                    }}
                  >
                    사업자등록번호
                  </p>

                  <input
                    type="number"
                    name="companyNumber"
                    style={{
                      padding: "12px",
                      borderRadius: "8px",
                      border: "1px solid #D9D9D9",
                      width: "100%",
                      marginTop: "8px",
                      height: 40,
                      maxWidth: 400,
                    }}
                    placeholder="주민등록번호 앞 6자리"
                    {...register("companyNumber", { required: true })}
                  />

                  <div style={{ maxWidth: 400, marginTop: "20px" }}>
                    <ButtonUpload
                      limitSize={2}
                      handleChange={(value) => {
                        setValue("companyImage", value, {
                          shouldValidate: true,
                        });
                      }}
                      handleSetError={(value) => {
                        setIsShowImgErrModal(true);
                      }}
                    />
                    <Dialog
                      sx={{
                        "& .MuiPaper-rounded": {
                          borderRadius: RADIUS.large,
                          width: "328px",
                        },
                        p: 2,
                      }}
                      open={isShowImgErrModal}
                    >
                      <div style={{ margin: "32px 20px" }}>
                        <div
                          style={{
                            fontWeight: 700,
                            textAlign: "center",
                            fontSize: 16,
                            lineHeight: "24px",
                          }}
                        >
                          업로드 실패
                        </div>
                        <div
                          style={{
                            fontSize: 14,
                            lineHeight: "20px",
                            textAlign: "center",
                            paddingTop: 16,
                            paddingBottom: 24,
                          }}
                        >
                          파일 업로드를 실패했습니다.
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            style={{
                              background: "#1E1E1E",
                              borderRadius: 8,
                              width: 320,
                              height: 48,
                              padding: 12,
                              textAlign: "center",
                              color: "white",
                            }}
                            onClick={() => setIsShowImgErrModal(false)}
                          >
                            확인
                          </Button>
                        </div>
                      </div>
                    </Dialog>
                    {getValues("companyImage") && (
                      <div style={{ marginTop: 8, display: "flex" }}>
                        <div style={{ marginRight: 4 }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M4.91901 6.79351L3.43004 8.28247C2.87395 8.83856 2.55416 9.59521 2.56 10.3904C2.56585 11.1856 2.87864 11.9469 3.46111 12.5114C4.02557 13.0939 4.78699 13.4066 5.58207 13.4125C6.39529 13.4185 7.13402 13.1167 7.69014 12.5606L9.17911 11.0716M11.081 9.20612L12.57 7.71715C13.126 7.16106 13.4458 6.40442 13.44 5.60921C13.4341 4.814 13.1214 4.05274 12.5389 3.48825C11.9746 2.92393 11.2133 2.61112 10.4181 2.60528C9.62285 2.59944 8.86611 2.90107 8.30999 3.45718L6.82102 4.94614M5.74207 10.2179L10.209 5.75101"
                              stroke="#C0C0C0"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div
                          style={{
                            fontSize: 12,
                            lineHeight: "16px",
                            fontWeight: 500,
                          }}
                        >
                          {getValues("companyImage")}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* {errors.nickname && <span>This field is required</span>} */}
                </div>
              </>
            )}
          </div>
        </div>
        <div style={{ padding: "20px", background: "white" }}>
          <button
            style={{
              borderRadius: "8px",
              padding: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              color: isEnableButton ? "#FFF" : "#707070",
              backgroundColor: isEnableButton ? "#1E1E1E" : "#EBEBEB",
              width: "100%",
            }}
            disabled={!isEnableButton}
            onClick={() => nextStep()}
          >
            확인
          </button>
        </div>
      </form>
    </div>
  );
};

export default ConsentPayment;
