import React from "react";
import { Box, Skeleton } from "@mui/material";
import {
  COLORS,
  FONT_WEIGHT,
  ICON_SIZE,
  LINE_HEIGHT,
  RADIUS,
  TEXTSIZE,
  WIDTH,
} from "@theme/constants";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  margin-bottom: 1rem;
  position: relative;

  .display-flex-row-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .display-flex-column-center {
    display: flex;

    justify-content: center;
    align-items: center;
  }

  .background-image {
    position: relative;
    height: 160px;
  }

  .background-image__first {
    background-color: ${COLORS.grey400};
    height: 100%;
    border-bottom-right-radius: ${RADIUS.superLarge};
  }

  .background-image__first__second {
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.7) 0.69%,
      rgba(0, 0, 0, 0) 40.28%,
      rgba(0, 0, 0, 0) 66.32%,
      rgba(0, 0, 0, 0.7) 100%
    );
    height: 100%;
    border-bottom-right-radius: ${RADIUS.superLarge};
  }

  .profile {
    // position: absolute;
    bottom: 0;
    display: flex;
    align-items: start;
    padding: 0 1rem;
    // height: 130px;
  }

  .profile__avatar {
    width: 100px;
    height: 100px;
    cursor: pointer;
  }

  .profile__nickname {
    color: ${COLORS.white};
    font-size: ${TEXTSIZE.large};
    font-weight: ${FONT_WEIGHT.xxLarge};
    line-height: ${LINE_HEIGHT.xxxxLarge};
    width: fit-content;
    // margin-bottom: 0.25rem;
  }

  .profile__introduce {
    // margin-bottom: 0.5rem;
    color: ${COLORS.grey200};
    font-size: ${TEXTSIZE.xSmall};
    font-weight: 500;
    line-height: ${LINE_HEIGHT.small};
    width: calc(100vw - 100px - 64px);
    max-width: calc(600px - 100px - 32px);
  }

  .profile__greeting {
    margin-top: 1rem;
    max-height: 70px;
    color: ${COLORS.grey500};
    font-size: ${TEXTSIZE.small};
    line-height: ${LINE_HEIGHT.xLarge};
    font-weight: 400;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .count-number {
    font-size: ${TEXTSIZE.xxLarge};
    font-weight: ${FONT_WEIGHT.xxLarge};
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
  }

  .content-text {
    font-size: ${TEXTSIZE.xSmall};
    font-weight: ${FONT_WEIGHT.large};
    line-height: ${LINE_HEIGHT.small};
    text-align: center;
    color: ${COLORS.grey500};
  }

  .icon_plus {
    width: ${ICON_SIZE.small};
    height: ${ICON_SIZE.small};
    cursor: pointer;
  }

  .btn-action {
    width: 80px;
    height: 24px;
    padding: 4px, 12px, 4px, 12px;
    border-radius: ${RADIUS.large};
    margin: 0 0.25rem;
    color: ${COLORS.grey600};
    background: ${COLORS.grey50};
    :hover {
      color: ${COLORS.grey600};
      background: ${COLORS.grey50};
    }
  }

  .btn-action--active {
    color: ${COLORS.white};
    background: ${COLORS.primary4};
    :hover {
      color: ${COLORS.white};
      background: ${COLORS.primary4};
    }
  }

  .btn-action__text {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  .social-link {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    color: ${COLORS.primary4};
    margin-left: 0.5rem;
  }

  .profile__icon {
    height: 16px;
    width: 16px;
    cursor: pointer;
    margin-left: 4px;
  }
`;

function ProfileSkeleton(props) {
  const { profileType } = props;

  const isMobileWithoutFold = useMediaQuery({
    query: `(max-width: ${WIDTH.fold})`,
  });

  return (
    <>
      <Wrapper>
        <Skeleton
          variant='rectangular'
          width={"100%"}
          height={160}
          sx={{
            height: "160px",
            width: "100%",
            borderBottomRightRadius: "40px",
          }}
        />

        <Box sx={{ position: "relative", mt: "-50px", zIndex: 2 }}>
          <Box sx={{ display: "flex", flexDirection: `${isMobileWithoutFold ? "column" : "row"}` }}>
            <Box className='profile' sx={{ width: isMobileWithoutFold ? "100%" : "50%", gap: 2 }}>
              <Skeleton variant='circular' width={100} height={100} />
              <Box
                sx={{
                  width: "calc(100% - 132px)",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Skeleton variant='text' />
                </Box>
                <Skeleton variant='text' />
                <Box sx={{ mt: 2 }}>
                  <Skeleton variant='text' sx={{ mb: "8px" }} />
                  <Skeleton variant='text' />
                </Box>
              </Box>
            </Box>
            {!isMobileWithoutFold && (
              <>
                <Box
                  sx={{
                    mt: "58px",
                    width: "50%",
                    right: "0",
                    borderLeft: `1px solid ${COLORS.grey50}`,
                  }}
                >
                  <Box className='display-flex-row-center' sx={{ gap: "6px" }}>
                    <Skeleton variant='rectangular' width={72} height={42} />
                    <Skeleton variant='rectangular' width={72} height={42} />
                  </Box>
                </Box>
              </>
            )}

            {/* conections */}
            {isMobileWithoutFold && (
              <Box className='display-flex-row-center' sx={{ mt: 1 }}>
                <Skeleton variant='rectangular' width={72} height={42} />
                <Skeleton variant='rectangular' width={72} height={42} />
              </Box>
            )}
          </Box>

          {/* action */}
          {profileType != "my" && (
            <Box
              sx={{
                py: "0.75rem",
                display: "flex",
                alignItems: "center",
                justifyContent: isMobileWithoutFold ? "center" : "start",
                ml: isMobileWithoutFold ? "0px" : "132px",
                gap: 1,
              }}
            >
              <Skeleton variant='rectangular' width={80} height={24} />
              <Skeleton variant='rectangular' width={80} height={24} />
            </Box>
          )}
          <Box
            sx={{ position: "sticky", top: "56px", zIndex: "10", mt: isMobileWithoutFold ? 1 : 3 }}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <Skeleton variant='rectangular' width={"50%"} height={42} />
              <Skeleton variant='rectangular' width={"50%"} height={42} />
            </Box>
          </Box>
          <Skeleton variant='rectangular' width={"100%"} height={"500px"} sx={{ mt: 1 }} />
        </Box>
      </Wrapper>
    </>
  );
}

export default ProfileSkeleton;
