import { Box, Button } from "@mui/material";
import React from "react";
import { ReactComponent as LockIcon } from "../../assets/svgs/lock_white.svg";
import { COLORS } from "../../theme/constants";
import styled from "styled-components";
import { buttonMuiStyle } from "../../utils/commonStyle";

const Wrapper = styled.div`
   {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding: 1rem 0px;

    .flex-align-center {
      display: flex;
      align-items: center;
    }

    .pic {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      margin-top: 0.75rem;
      margin-bottom: 1.25rem;
      color: ${COLORS.white};
    }

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 0.25rem;
    }
  }
`;

function PayDetail(props) {
  const { cost = 0, handlePurchase } = props;
  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 1,
        top: "0",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgb(0,0,0,0.5)",
        borderRadius: "16px"
      }}
    >
      <Wrapper>
        <Box>
          <LockIcon />
          <p className="pic">{`${cost} PIC을 사용하여 볼 수 있어요.`}</p>
          <Box
            sx={{
              // display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <Box className="flex-align-center">
            <Box className="flex-align-center" style={{ marginRight: "12px" }}>
              <ImageIcon className="icon" />
              <p>이미지 {total_image}장</p>
            </Box>
            <Box className="flex-align-center">
              <VideoIcon className="icon" />
              <p>동영상 {total_video}개</p>
            </Box>
          </Box> */}
            <Button
              sx={{
                ...buttonMuiStyle,
                padding: "0.75rem 2rem",
                width: "fit-content",
                backgroundColor: COLORS.primary4,
                color: COLORS.white,
              }}
              variant="contained"
              className="submit"
              onClick={handlePurchase}
            >
              해제하기
            </Button>
          </Box>
        </Box>
      </Wrapper>
    </Box>
  );
}

export default PayDetail;
