import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const SkeletonWrap = styled.div`
  margin-top: 20px;
`;

function ChargeSkeleton() {
  return (
    <SkeletonWrap>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Skeleton variant='text' width={"3rem"} height={".7rem"} />
          <Skeleton variant='text' width={"10rem"} height={"1.3rem"} />
        </Stack>
        <Stack spacing={1}>
          <Skeleton
            variant='text'
            width={"3rem"}
            height={".7rem"}
            style={{ marginBottom: "8px" }}
          />
          <Skeleton variant='text' width={"100%"} height={"3rem"} />
          <Skeleton variant='text' width={"100%"} height={"3rem"} />
          <Skeleton variant='text' width={"100%"} height={"3rem"} />
          <Skeleton variant='text' width={"100%"} height={"3rem"} />
        </Stack>
      </Stack>
    </SkeletonWrap>
  );
}

export default ChargeSkeleton;
