import React, { useContext, useEffect } from "react";
import {
  ICON_SIZE,
  COLORS,
  LINE_HEIGHT,
  RADIUS,
  TEXTSIZE,
  FONT_WEIGHT,
  POST_TYPE,
} from "@theme/constants";
import { Link, useNavigate } from "react-router-dom";
import NoData from "../../../NoData";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import RecommendMedia from "./RecommendMedia";
import { textXLargeMuiStyle, textSmallMuiStyle } from "@utils/commonStyle";
import PICComponent from "../PICComponent";
import Author from "@containers/author/Author";
import { useTranslation } from "react-i18next";
import ButtonConnect from "../../../connect/ButtonConnect";
import { GlobalStateContext } from "../../../../context/GlobalStateProvider";

function RecommendInside(props) {
  const { data, offset } = props;
  const { backgroundHomeType, setRecommendPosts } =
    useContext(GlobalStateContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSelectedPost } = useContext(GlobalStateContext);

  const onClickRecommend = (ID = "", index = 8) => {
    setRecommendPosts(data);
    setSelectedPost({
      id: ID,
      index: index,
      from: POST_TYPE.RECOMMEND,
      nickname: "",
    });
    navigate(`/details/${POST_TYPE.RECOMMEND}/${offset}`);
  };

  return (
    <Box sx={{ width: "100%", pl: 2, py: 3.5 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          cursor: "pointer",
        }}
        onClick={() => onClickRecommend()}
      >
        {/* <Link to={"/post/오늘의 새 콘텐츠"}> */}
        <Typography
          sx={{
            ...textXLargeMuiStyle,
            color: COLORS.black1,
          }}
        >
          {t("recommendations_for_you")}
        </Typography>
        <Typography sx={{ ...textSmallMuiStyle, color: COLORS.grey300 }}>
          {t("see_more")}
        </Typography>
      </Box>
      {data ? (
        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 3,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {data.map((post, indexPost) => (
            <SwiperSlide key={post.id} style={{ background: "transparent" }}>
              <Box sx={{ position: "relative", my: 2 }}>
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: "9",
                    top: "0",
                    width: "100%",
                  }}
                >
                  <Author
                    authorData={{
                      id: post?.user_profile?.user_id,
                      avatar: post?.user_profile?.profile_image_url,
                      name: post?.user_profile?.nickname,
                      job: post?.user_profile?.introduce,
                    }}
                    authorStyle={{
                      borderRadius: `${RADIUS.medium} ${RADIUS.medium} 0 0`,
                      background: "rgba(0,0,0,0.3)",
                      gap: "0.25rem",
                    }}
                    avatarStyle={{
                      width: ICON_SIZE.small,
                      height: ICON_SIZE.small,
                    }}
                    nameStyle={{
                      fontSize: TEXTSIZE.xSmall,
                      lineHeight: LINE_HEIGHT.small,
                      fontWeight: FONT_WEIGHT.large,
                      width: "100%",
                      textAlign: "left",
                    }}
                    jobStyle={{
                      fontSize: TEXTSIZE.xSmall,
                      lineHeight: LINE_HEIGHT.small,
                      fontWeight: FONT_WEIGHT.large,
                      textAlign: "left",
                    }}
                  />
                </Box>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => onClickRecommend(post.id, indexPost)}
                >
                  <RecommendMedia data={post.files?.[0]} />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: "9",
                    bottom: "8px",
                    left: "8px",
                  }}
                >
                  <ButtonConnect
                    isConnect={post?.connection ? true : false}
                    userId={post?.user_profile?.user_id}
                    buttonProps={{}}
                    handleConnectSuccess={() => {}}
                    textCancelConnect={t("button.connecting")}
                    textConnect={t("button.connect")}
                  />
                </Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <NoData />
      )}
    </Box>
  );
}

export default RecommendInside;
