import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { handleError } from "../../../utils/commonFunction";
import CircularComponent from "../../process/CircularComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import NoData from "../../../components/NoData";
import { getProfilePost } from "../../../api";
import styled from "styled-components";
import { useAuth } from "../../../context/Auth";
import ImageDefault from "../../../assets/images/default_image.jpg";
import ItemProfilePostList from "./ItemProfilePostList";
import MyFeedMediaVideoViewOnly from "../../../components/newComponents/posts/MyFeed/MyFeedMediaVideoViewOnly";
import Overlay from "../../../components/overlay/Overlay";

const LIMIT_POST = 16;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 20vh - 360px);
`;

function ProfilePostList(props) {
  const { endpoint, emptyText } = props;
  const { logout } = useAuth();
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMoreData = async (newRequestParams = {}) => {
    try {
      if (posts.length == 0) {
        setIsLoading(true);
      }

      const requestParams = {
        offset: posts.length,
        limit: LIMIT_POST,
        ...newRequestParams,
      };

      const res = await getProfilePost(endpoint, requestParams);

      if (res?.list) {
        const newPost = res.list;
        setPosts((prevState) => [...prevState, ...newPost]);
      }

      setIsLoading(false);
    } catch (error) {
      handleError(error, logout);
    }
  };

  const handleFetchMoreData = () => {
    if (posts.length === 0) {
      fetchMoreData();
    } else {
      fetchMoreData();
    }
  };

  const getPage = () => {
    switch (endpoint) {
      case "posts":
        return "profile/tab1";
      case "my-limited-paid":
        return "profile/tab2";
      case "my-quiz":
        return "profile/my-quiz";
      case "my-paid":
        return "profile/tab3";
      default:
        break;
    }
  };

  useEffect(() => {
    endpoint && handleFetchMoreData();
  }, [endpoint]);

  return (
    <Box>
      {isLoading ? (
        <HeightWhenEmpty>
          <CircularComponent />
        </HeightWhenEmpty>
      ) : (
        <>
          {posts.length > 0 ? (
            <InfiniteScroll
              dataLength={posts.length}
              next={handleFetchMoreData}
              hasMore={true}
              // scrollableTarget={"scrollableContent"}
            >
              <Grid container spacing={0.2} sx={{ mb: "86px" }}>
                {posts.length > 0 &&
                  posts.map((post, indexPost) => (
                    <Grid item key={post.id} id={post.id} xs={6} md={4}>
                      <ItemProfilePostList
                        post={post}
                        indexPost={indexPost}
                        media={
                          post.files?.[0]?.type === "video" ? (
                            <MyFeedMediaVideoViewOnly
                              data={post.files?.[0]}
                              onClickMedia={() => {}}
                              playerStyle={{
                                borderRadius: "0",
                              }}
                              boxStyle={{
                                aspectRatio: 1,
                                borderRadius: "0",
                                margin: 0,
                              }}
                            />
                          ) : (
                            <Box
                              style={{
                                width: "100%",
                                height: "100%",
                                position: "relative",
                              }}
                            >
                              <img
                                src={
                                  !post.files?.[0]
                                    ? ImageDefault
                                    : post.files?.[0]?.view_url || post.files?.[0]?.small_url
                                  // post.files?.[0]?.url
                                }
                                style={{ aspectRatio: "1/1" }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = ImageDefault;
                                }}
                              />
                              <Overlay />
                            </Box>
                          )
                        }
                        page={getPage()}
                      />
                    </Grid>
                  ))}
              </Grid>
            </InfiniteScroll>
          ) : (
            <HeightWhenEmpty>
              <NoData text={emptyText ? emptyText : "게시물이 없습니다."}/>
            </HeightWhenEmpty>
          )}
        </>
      )}
    </Box>
  );
}

export default ProfilePostList;
