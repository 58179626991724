import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { encode as base64_encode } from "base-64";
import { setChargethePic } from "../api";

export default function PaymentSuccess() {
  const navigate = useNavigate();
  let orderId = new URL(window.location.href).searchParams.get("orderId");
  let paymentKey = new URL(window.location.href).searchParams.get("paymentKey");
  let amount = new URL(window.location.href).searchParams.get("amount");

  const chargethePic = (pic) => {
    setChargethePic(pic)
      .then((res) => {
        if (res.data.result) {
          const user = JSON.parse(window.localStorage.getItem("user"));
          user.user_funds.charge = res.data.data.charge;
          window.localStorage.setItem("user", JSON.stringify(user));
          toast("결제가 완료되었습니다");
          navigate(-2);
        }
      })
      .catch((error) => {
        console.log(error);
        toast(error.response.data.message);
      });
  };

  useEffect(() => {
    const secret = `Basic ${base64_encode(
      process.env.REACT_APP_TOSS_CLIENT_SECRET + ":"
    )}`;
    axios
      .post(
        `https://api.tosspayments.com/v1/payments/confirm`,
        {
          paymentKey,
          amount,
          orderId,
        },
        {
          headers: {
            Authorization: secret,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status == 200 && res.data.method === "가상계좌") {
          sessionStorage.setItem(
            "accountNumber",
            res.data.virtualAccount.accountNumber
          );
          sessionStorage.setItem("bank", res.data.virtualAccount.bank);
          sessionStorage.setItem("dueDate", res.data.virtualAccount.dueDate);
          navigate("/deposit");
        } else if (
          res.status == 200 &&
          (res.data.method === "카드" || res.data.method === "게좌이체")
        ) {
          // window.alert({
          //   position: "center",
          //   icon: "success",
          //   text: "결제가 완료되었습니다!",
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
        }

        chargethePic(Number(res.data.orderName.split("PIC")[0]));
      })
      .catch((err) => {
        console.log(err);
        toast.error("결제에 실패하였습니다");
        navigate(-1);
      });
  }, []);

  return;
}
