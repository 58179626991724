import { Box, Checkbox, FormControlLabel, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { COLORS, LINE_HEIGHT, TEXTSIZE } from "@theme/constants";
import checkBoxImage from "@images/checkBox.svg";
import checkedBoxImage from "@images/checkedBox.svg";
import { textMediumMuiStyle } from "@utils/commonStyle";
import Categories from "@components/categories/Categories";
import { handleError, removeEmpty } from "@utils/commonFunction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { getMarketList, getUserCategories } from "@api";
import styled from "styled-components";
import { TabContext, TabPanel } from "@mui/lab";
import MarketList from "../containers/market/MarketList";
import { getHotCreator } from "../api";
import HotCreatorMarket from "../components/newComponents/posts/Hot/HotCreatorMarket";
import { useTranslation } from "react-i18next";

const WrapperTabs = styled.div`
  width: 25%;
  .MuiTabs-scroller,
  .MuiTabs-root,
  .MuiButtonBase-root {
    min-height: 100%;
  }

  .MuiTabs-flexContainer {
    align-items: center;
    justify-content: space-around;
  }

  .tab-wrap {
    background: ${COLORS.white};
    .MuiButtonBase-root {
      max-width: 100%;
      color: var(--subTextColor);
      flex: 1 0 auto;
      padding: 8px 0;
      text-transform: capitalize;
      font-weight: 700;

      &.Mui-selected {
        color: ${COLORS.black1};
        .tab__icon {
          fill: ${COLORS.black1};
        }
      }
      .tab__icon {
        fill: ${COLORS.grey200};
      }
    }

    .MuiTabs-indicator {
      height: 3px;
      background-color: ${COLORS.black1};
    }
  }

  .tab-content__wrap {
    position: relative;

    .tab-content {
      height: 100%;
    }
  }
`;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LIMIT_POST = 16;

function Market(props) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [selectedTag, setSelectedTag] = useState("전체");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [hotPosts, setHotPosts] = useState([]);
  const [total, setTotal] = useState(0);

  //   const [tabList, setTabList] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  //   const [searchParams, setSearchParams] = useSearchParams();

  const tabList = [
    {
      label: "All",
      text: "All",
      type: "all",
      data: [],
    },
    {
      label: "New",
      text: "New",
      type: "new",
      data: [],
    },
  ];

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
    // setSearchParams({ tab: newValue });
  };

  const handleUserGetCategories = async () => {
    try {
      const res = await getUserCategories();
      if (res?.list) {
        const resTmp = [...res.list];
        const all = {
          id: "전체",
          icon: "",
          name: "전체",
        };
        resTmp.unshift(all);
        setCategories(resTmp);
      }
    } catch (error) {
      handleError(error, logout, navigate);
      // console.error("handleGetTag error", error);
    }
  };

  // get hot creator begin
  const getHotPostByTag = async (newRequestData) => {
    try {
      const requestData = {
        offset: 0,
        limit: 5,
        type: 2,
        ...newRequestData,
      };
      const res = await getHotCreator(requestData);

      if (res?.list) {
        const newPost = res.list;
        if (requestData.offset == 0) {
          setHotPosts(newPost);
        } else {
          setHotPosts((prevState) => [...prevState, ...newPost]);
        }
      }
    } catch (error) {
      handleError(error, logout);
    }
  };
  // get hot creator end

  const fetchMoreData = async (newRequestData) => {
    try {
      if (data.length == 0) {
        setIsLoading(true);
      }
      // setSearchParams({ ...requestData });
      const requestData = {
        exclude: checked ? 1 : 0,
        category_id: "",
        offset: 0,
        limit: LIMIT_POST,
        ...newRequestData,
      };
      const res = await getMarketList(removeEmpty(requestData));
      if (res?.list) {
        const newPost = res.list;
        if (newRequestData.offset == 0) {
          setData(newPost);
        } else {
          setData((prevState) => [...prevState, ...newPost]);
        }
      }
      setTotal(res?.meta?.total);
      setIsLoading(false);
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const onClickTag = (value) => {
    const requestData = {
      category_id: value === "전체" ? "" : value,
      offset: 0,
      limit: LIMIT_POST,
      // sort_by: SORT_BY.POPULAR,
    };
    setSelectedTag(value);
    fetchMoreData(requestData);
    if (value !== "전체") {
      const requestHotData = {
        offset: 0,
        category_id: value,
      };
      getHotPostByTag(requestHotData);
    }
  };

  useEffect(() => {
    // logEvent(analytics, "screen_view", {
    //   firebase_screen: "search",
    //   firebase_screen_class: "search",
    // });
    const requestData = {
      category_id: "",
      offset: 0,
      // sort_by: "popular",
      limit: LIMIT_POST,
    };
    fetchMoreData(requestData);
    handleUserGetCategories();
  }, []);

  return (
    <>
      <TabContext value={activeTab}>
        <Box
          sx={{
            position: "sticky",
            top: -1,
            zIndex: "10",
            backgroundColor: COLORS.white,
          }}
        >
          <WrapperTabs>
            <Tabs
              value={activeTab}
              onChange={handleChangeTab}
              aria-label='tabs'
              className='tab-wrap'
            >
              {tabList.map((item, index) => (
                <Tab
                  sx={{ minWidth: "24px" }}
                  value={item.type}
                  data-label={item.label}
                  label={item.label}
                  {...a11yProps(index)}
                  key={index}
                />
              ))}
            </Tabs>
          </WrapperTabs>
        </Box>
        <Box
          sx={{
            pt: 1,
            position: "sticky",
            top: "32px",
            zIndex: 10,
            backgroundColor: COLORS.white,
          }}
        >
          <Categories
            selectedTag={selectedTag}
            tags={categories}
            onClickTag={onClickTag}
            redirect={"/market"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            px: 1,
            mb: 1,
            justifyContent: "space-between",
            alignItems: "center",
            // position: "sticky",
            // top: "78px",
            zIndex: 10,
            backgroundColor: COLORS.white,
          }}
        >
          <FormControlLabel
            label={
              <Typography
                sx={{
                  color: COLORS.grey900,
                  fontSize: TEXTSIZE.medium,
                  lineHeight: LINE_HEIGHT.xxxLarge,
                }}
              >
                {t("exclude_out_of_stock")}
              </Typography>
            }
            control={
              <Checkbox
                checked={checked}
                //   defaultChecked={true}
                onChange={() => {
                  fetchMoreData({
                    category_id: selectedTag === "전체" ? "" : selectedTag,
                    offset: 0,
                    limit: data.length,
                    exclude: !checked ? 1 : 0,
                  });
                  setChecked(!checked);
                }}
                icon={<img src={checkBoxImage} />}
                checkedIcon={<img src={checkedBoxImage} />}
              />
            }
          />
          {total > 0 && (
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  ...textMediumMuiStyle,
                  fontWeight: "500",
                }}
              >
                {total}
              </Typography>
              <Typography
                sx={{
                  ...textMediumMuiStyle,
                  fontWeight: "500",
                  color: COLORS.grey600,
                }}
              >
                개
              </Typography>
            </Box>
          )}
        </Box>
        <TabPanel value={"all"} sx={{ p: 0 }}>
          {hotPosts.length > 0 && (
            <HotCreatorMarket
              data={hotPosts}
              categoryId={selectedTag}
              fetchMoreData={() => {
                const requestHotData = {
                  offset: hotPosts.length,
                  category_id: selectedTag,
                };
                getHotPostByTag(requestHotData);
              }}
            />
          )}
          <MarketList
            data={data}
            selectedTag={selectedTag}
            LIMIT_POST={LIMIT_POST}
            fetchMoreData={fetchMoreData}
          />
        </TabPanel>
        <TabPanel value={"new"} sx={{ p: 0 }}>
          NEW
        </TabPanel>
      </TabContext>
    </>
  );
}

export default Market;
