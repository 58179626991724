import React from "react";
import { Box, Avatar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { isMine } from "@utils/commonFunction";
import ImageAvatar from "@images/avatar.png";
import { COLORS } from "@theme/constants";

const truncateStyle = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "calc(100% - 60px)",
  overflow: "hidden",
};

function Author(props) {
  const {
    authorData = {
      id: "",
      avatar: "",
      name: "",
      job: "",
    },
    authorStyle = {},
    avatarStyle = {},
    nameStyle = {},
    jobStyle = {},
  } = props;

  const { id, avatar, name, job } = authorData;

  const redirectToProfile = () => {
    let result = "/profile";
    if (!isMine(id)) {
      result = `/user-profile/${name}`;
    }
    return result;
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: 1,
        // width: "100%",
        ...authorStyle,
      }}
    >
      <Link to={redirectToProfile()}>
        <Avatar
          sx={{
            ...avatarStyle,
          }}
          // alt={name}
          src={avatar || ImageAvatar}
        />
      </Link>
      <Box
        sx={{
          // ml: "0.5rem",
          width: "100%",
        }}
      >
        <Link to={redirectToProfile()}>
          <Typography
            sx={{
              color: COLORS.white,
              textAlign: "left",
              ...truncateStyle,
              visibility: name ? "visible" : "hidden",
              ...nameStyle,
            }}
          >
            {name}
          </Typography>
        </Link>
        <Typography
          sx={{
            color: COLORS.white,
            ...truncateStyle,
            visibility: job ? "visible" : "hidden",
            ...jobStyle,
          }}
        >
          {job}
        </Typography>
      </Box>
    </Box>
  );
}

export default Author;
