import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPostDetail } from "../api";
import { HEIGHT, WIDTH } from "../theme/constants";
import { toast } from "react-toastify";
import styled from "styled-components";
import { handleError } from "@utils/commonFunction";
import { useAuth } from "../context/Auth";
import CircularComponent from "../containers/process/CircularComponent";
import PostDetailItem from "../containers/posts/PostDetailItem";

const Wrapper = styled.div`
  // position: relative;
  // min-height: ${HEIGHT.content};

  // @media screen and (min-width: ${WIDTH.desktop}) {
  //   width: ${WIDTH.desktop_main_contents};
  //   margin: 0 auto;
  // }
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;
  // display: flex;
  // justify-content: center;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    margin-left: 100px;
    max-width: 600px;
  }
`;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 230px);
  position: relative;
`;

function PostDetail() {
  const params = useParams();
  const { logout } = useAuth();
  const { state: locationState } = useLocation();
  const [post, setPost] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleGetDetail = async () => {
    try {
      setIsLoading(true);
      const res = await getPostDetail(params.id);
      setPost(res);
      if (locationState?.isUpdatePostSuccess) {
        toast("게시물을 수정했습니다.");
        // window.history.replaceState({}, document.title);
      }
    } catch (error) {
      handleError(error, logout, navigate);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    params.id && handleGetDetail();
  }, [params.id]);

  if (isLoading)
    return (
      <HeightWhenEmpty>
        <CircularComponent />
      </HeightWhenEmpty>
    );

  return <Wrapper>{post && <PostDetailItem post={post} fetchData={handleGetDetail} />}</Wrapper>;
}

export default PostDetail;
