import React, { useContext, useEffect, useRef, useState } from "react";
import PostDetailItem from "../posts/PostDetailItem";
import styled from "styled-components";
import { WIDTH } from "../../theme/constants";
import { scroller, Element } from "react-scroll";
import { useNavigate, useParams } from "react-router-dom";
import { searchPosts } from "../../api";
import InfiniteScroll from "react-infinite-scroll-component";
import NoData from "../../components/NoData";
import { handleError, removeEmpty } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { GlobalStateContext } from "../../context/GlobalStateProvider";
import PostSkeleton from "../../components/layouts/skeleton/PostSkeleton";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 180px);
`;

const LIMIT_POST = 4;

function SearchPostListDetailByCategory() {
  const params = useParams();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  let scrollInterval = useRef(null);
  const [inViewIndex, setInViewIndex] = useState(0);

  const { selectedPost } = useContext(GlobalStateContext);

  const handleScroll = (id) => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      // containerId: "scrollableContent",
    });
  };

  const onVideoInView = (index) => {
    setInViewIndex(() => {
      return index;
    });
  };

  const fetchMoreData = async (newRequestData) => {
    try {
      if (posts.length == 0) {
        setIsLoading(true);
      }
      const requestData = {
        offset: posts.length,
        limit: LIMIT_POST,
        sort_by: selectedPost?.sort_by,
        ...newRequestData,
      };
      const res = await searchPosts(removeEmpty(requestData));

      if (!res?.list) return;
      const newPost = res.list;

      if (newRequestData.offset === 0) {
        handleScroll(selectedPost.id);
        setPosts(newPost);
      } else {
        setPosts((prevState) => [...prevState, ...newPost]);
      }
    } catch (error) {
      handleError(error, logout, navigate);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchMoreData = () => {
    if (posts.length === 0) {
      fetchMoreData({
        offset: 0,
        limit: parseInt(selectedPost.index) + 2,
        category_id: selectedPost?.category_id === "전체" ? "" : selectedPost?.category_id,
      });
    } else {
      fetchMoreData({
        category_id: selectedPost?.category_id === "전체" ? "" : selectedPost?.category_id,
      });
    }
  };

  const fetchNewData = () => {
    const requestData = {
      offset: 0,
      limit: posts.length,
      category_id: selectedPost?.category_id === "전체" ? "" : selectedPost?.category_id,
    };
    setPosts([]);
    fetchMoreData(requestData);
  };

  useEffect(() => {
    scrollInterval = setInterval(() => {
      if (document.getElementById(selectedPost.id)) {
        handleScroll(selectedPost.id);
        clearInterval(scrollInterval);
      }
    }, 1000);

    handleFetchMoreData();
    return () => {
      clearInterval(scrollInterval);
    };
  }, []);

  if (isLoading) {
    return (
      <Wrapper>
        <PostSkeleton />
        <PostSkeleton />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {posts.length > 0 ? (
        <InfiniteScroll
          dataLength={posts.length}
          next={handleFetchMoreData}
          hasMore={true}
          // scrollableTarget="scrollableContent"
        >
          {posts.length > 0 &&
            posts.map((post, index) => (
              <Element name={post.id} key={post.id} id={post.id}>
                <PostDetailItem
                  post={post}
                  fetchData={fetchNewData}
                  onVideoInView={() => onVideoInView(index)}
                  onVideoOutView={() => onVideoInView(-1)}
                  isPostInView={inViewIndex === index}
                />
              </Element>
            ))}
        </InfiniteScroll>
      ) : (
        <HeightWhenEmpty>
          <NoData />
        </HeightWhenEmpty>
      )}
    </Wrapper>
  );
}

export default SearchPostListDetailByCategory;
