import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "@theme/constants";
import { toast } from "react-toastify";

import { ReactComponent as ShareIcon } from "@icons/share.svg";
import { ReactComponent as ExploreIcon } from "@icons/explore.svg";
import { ReactComponent as VisibilityOffIcon } from "@icons/visibility_off.svg";
import { ReactComponent as ReportIcon } from "@icons/report.svg";
import { ReactComponent as EditIcon } from "@icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@icons/delete.svg";
import { ReactComponent as EyeOpenIcon } from "@icons/eye_open.svg";
import { ReactComponent as EyeCloseIcon } from "@icons/eye_close.svg";
import DrawerListComponent from "./DrawerListComponent";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/Auth";
import { isMine } from "@utils/commonFunction";
import dayjs from "dayjs";
import { convertResponseTimeToLocalTime } from "../../utils/dateTimeFormat";

const CancelToast = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    color: #777;
    cursor: pointer;
  }
`;

function ListDrawer({ close, handleModal, data, refetch, callBack }) {
  const { t } = useTranslation();
  // const user = JSON.parse(window.localStorage.getItem("user"));
  const { user } = useAuth();
  const isMineTmp = isMine(data?.user_profile?.user_id);

  const [items, setItems] = useState([]);

  const isEnd = () => {
    return dayjs().isAfter(convertResponseTimeToLocalTime(data?.event_end));
  };


  const edit = {
    text: t("button.edit_post"),
    icon: <EditIcon />,
    fn: () => {
      handleModal("edit");
      close();
    },
  };

  const share = {
    text: t("button.share_post"),
    icon: <ShareIcon />,
    fn: () => {
      // navigator.clipboard.writeText(
      //   `${window.location.origin}/post/detail/${data.id}`
      // );
      handleModal("share");
      close();
      toast(t("link_has_been_copied"));
    },
  };

  const viewBuyerPermission = {
    text: data?.is_view_buyer === 1 ? t("buyer_list_private") : t("buyer_list_public"),
    icon: data?.is_view_buyer === 1 ? <EyeCloseIcon /> : <EyeOpenIcon />,
    fn: () => {
      handleModal("edit_is_view_buyer");
      close();
    },
  };

  const deletePost = {
    text: t("button.delete_post"),
    icon: <DeleteIcon />,
    fn: () => {
      handleModal("delete");
      close();
    },
  };

  const connect = {
    text: data?.connection ? t("button.connecting") : t("button.connect"),
    icon: <ExploreIcon style={{ stroke: COLORS.black1, fill: COLORS.black1 }} />,
    fn: () => {
      if (isMineTmp) return false;
      handleModal("connect");
      close();
    },
  };

  const hide = {
    text: t("button.hide_post"),
    icon: <VisibilityOffIcon />,
    fn: () => {
      handleModal("hide");
      close();
    },
  };

  const report = {
    text: t("button.report"),
    icon: <ReportIcon />,
    fn: () => {
      handleModal("report");
      close();
    },
  };

  useEffect(() => {
    const newItems = [];
    if (isMineTmp) {
      if (data?.type != 3) newItems.push(edit);
      newItems.push(share);
      // if (data?.cost > 0) newItems.push(viewBuyerPermission);
      if(!isEnd()) newItems.push(deletePost);
    } else {
      newItems.push(share);
      if (data?.type != 3) newItems.push(connect);
      if (data?.type != 3) newItems.push(hide);
      if (data?.type != 3) newItems.push(report);
    }

    setItems(newItems);
  }, []);

  return <DrawerListComponent close={close} modalData={items} />;
}

export default React.memo(ListDrawer);
