import arrowRightImage from "@images/arrowRight.svg";
import styled from "styled-components";
import { numberWithCommas } from "../../utils";
import { NumericFormat } from "react-number-format";

const CustomInput = styled.input`
  text-align: right;
  outline: none;
  border: 0;
  padding: 0;
  width: 100%;
  font-weight: 700;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default function Payment({
  preStep,
  nextStep,
  register,
  getValues,
  setValue,
  watch,
  handlePayment,
}) {
  const totalMoney = watch("totalMoney");
  const paymentMethod = watch("paymentMethod");
  const isEnableButton = !!(totalMoney >= 100);

  return (
    <div
      style={{
        //   maxWidth: "360px",
        margin: "auto",
        backgroundColor: "white",
        wordBreak: "keep-all",
        color: "#1E1E1E",
        display: "flex",
        flexDirection: "column",
        minHeight: "90svh",
      }}
    >
      <div
        style={{
          padding: "16px",
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <div className="icon-wrap" onClick={() => preStep()}>
          <img
            src={arrowRightImage}
            style={{
              transform: "rotate(180deg)",
              width: "24px",
              height: "24px",
            }}
          />
        </div>

        <div style={{ flex: 1, textAlign: "center" }}>
          <p
            style={{
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "24px",
            }}
          >
            후원하기
          </p>
        </div>

        <div
          style={{
            width: "24px",
            height: "24px",
          }}
        ></div>
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <div style={{ flex: 1 }}>
          <div style={{ marginTop: 28, padding: "0 20px" }}>
            <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 20 }}>
              후원금액
            </div>

            <div
              style={{
                display: "flex",
                gap: 4,
                fontWeight: 500,
                marginBottom: 12,
              }}
            >
              <div
                style={{
                  padding: 12,
                  borderRadius: 8,
                  border: "1px solid #D9D9D9",
                  fontSize: 12,
                  color: totalMoney == 1000000 ? "white" : "#3B3B3B",
                  background: totalMoney == 1000000 ? "#1E1E1E" : "white",
                  fontWeight: totalMoney == 1000000 ? 500 : 400,
                  width: 60,
                  height: 36,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => setValue("totalMoney", 1000000)}
              >
                +100만
              </div>
              <div
                style={{
                  padding: 12,
                  borderRadius: 8,
                  border: "1px solid #D9D9D9",
                  fontSize: 12,
                  color: totalMoney == 500000 ? "white" : "#3B3B3B",
                  background: totalMoney == 500000 ? "#1E1E1E" : "white",
                  fontWeight: totalMoney == 500000 ? 500 : 400,
                  width: 60,
                  height: 36,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => setValue("totalMoney", 500000)}
              >
                +50만
              </div>
              <div
                style={{
                  padding: 12,
                  borderRadius: 8,
                  border: "1px solid #D9D9D9",
                  fontSize: 12,
                  color: totalMoney == 100000 ? "white" : "#3B3B3B",
                  background: totalMoney == 100000 ? "#1E1E1E" : "white",
                  fontWeight: totalMoney == 100000 ? 500 : 400,
                  width: 60,
                  height: 36,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => setValue("totalMoney", 100000)}
              >
                +10만
              </div>

              <div
                style={{
                  padding: 12,
                  borderRadius: 8,
                  border: "1px solid #D9D9D9",
                  fontSize: 12,
                  color: totalMoney == 50000 ? "white" : "#3B3B3B",
                  background: totalMoney == 50000 ? "#1E1E1E" : "white",
                  fontWeight: totalMoney == 50000 ? 500 : 400,
                  width: 60,
                  height: 36,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => setValue("totalMoney", 50000)}
              >
                +5만
              </div>
              <div
                style={{
                  padding: 12,
                  borderRadius: 8,
                  border: "1px solid #D9D9D9",
                  fontSize: 12,
                  color: totalMoney == 10000 ? "white" : "#3B3B3B",
                  background: totalMoney == 10000 ? "#1E1E1E" : "white",
                  fontWeight: totalMoney == 10000 ? 500 : 400,
                  width: 60,
                  height: 36,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => setValue("totalMoney", 10000)}
              >
                +1만
              </div>
            </div>

            <div
              style={{
                display: "flex",
                gap: 4,
                fontWeight: 500,
                marginBottom: 28,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                onClick={() => setValue("totalMoney", 0)}
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="7.5"
                  stroke="#D9D9D9"
                />
                <path
                  d="M27.1288 22.5C26.1109 25.6939 23.1954 28 19.7576 28C15.4732 28 12 24.4183 12 20C12 15.5817 15.4732 12 19.7576 12C22.2706 12 24.5045 13.2322 25.9221 15.1428M26.4773 16C26.3099 15.7015 26.1243 15.4153 25.9221 15.1428M25.9221 15.1428L24.1212 17H28V13L25.9221 15.1428ZM26.0606 16.6667L27.2727 15"
                  stroke="#3B3B3B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div
                style={{
                  width: 272,
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                  padding: 12,
                  border: "1px solid #D9D9D9",
                  borderRadius: 8,
                  fontSize: "14px",
                  lineHeight: "16px",
                  textAlign: "right",
                  color: "#1E1E1E",
                }}
              >
                <NumericFormat
                  style={{
                    textAlign: "right",
                    width: "100%",
                    border: "none",
                    outline: "none",
                    fontWeight: "700",
                  }}
                  onValueChange={(values) => {
                    setValue("totalMoney", values.floatValue || 0);
                  }}
                  value={totalMoney > 0 ? Math.abs(totalMoney) : ""}
                  thousandSeparator
                  valueIsNumericString
                  allowNegative={false}
                />

                <div
                  style={{
                    marginLeft: 4,
                  }}
                >
                  원
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ width: "100vw", height: 1, background: "#EBEBEB" }}
          ></div>

          <div style={{ marginTop: 28, padding: "0 20px" }}>
            <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 20 }}>
              결제방법 선택
            </div>

            <div
              style={{
                display: "flex",
                gap: 8,
                fontWeight: 500,
                marginBottom: 6,
              }}
            >
              <div
                style={{
                  padding: "16px 12px",
                  borderRadius: 8,
                  border: "1px solid #D9D9D9",
                  fontSize: 12,
                  color: paymentMethod == 1 ? "white" : "#3B3B3B",
                  background: paymentMethod == 1 ? "#1E1E1E" : "white",
                  minWidth: 102,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                신용카드
              </div>

              <div
                style={{
                  padding: "16px 12px",
                  borderRadius: 8,
                  border: "1px solid #D9D9D9",
                  fontSize: 12,
                  color: "#3B3B3B",
                  minWidth: 102,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#EBEBEB",
                }}
                disabled
              >
                계좌이체
              </div>

              <div
                style={{
                  padding: "16px 12px",
                  borderRadius: 8,
                  border: "1px solid #D9D9D9",
                  fontSize: 12,
                  color: "#3B3B3B",
                  minWidth: 102,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#EBEBEB",
                }}
                disabled
              >
                휴대폰소액결제
              </div>
            </div>

            <div
              style={{
                display: "flex",
                gap: 8,
                fontWeight: 500,
              }}
            >
              <div
                style={{
                  padding: "16px 12px",
                  borderRadius: 8,
                  border: "1px solid #D9D9D9",
                  fontSize: 12,
                  color: "#3B3B3B",
                  minWidth: 102,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#EBEBEB",
                }}
                disabled
              >
                네이버페이
              </div>

              <div
                style={{
                  padding: "16px 12px",
                  borderRadius: 8,
                  border: "1px solid #D9D9D9",
                  fontSize: 12,
                  color: "#3B3B3B",
                  minWidth: 102,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#EBEBEB",
                }}
                disabled
              >
                카카오페이
              </div>

              <div
                style={{
                  padding: "16px 12px",
                  borderRadius: 8,
                  border: "1px solid #D9D9D9",
                  fontSize: 12,
                  color: "#3B3B3B",
                  minWidth: 102,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#EBEBEB",
                }}
                disabled
              >
                토스
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100vw",
              height: 1,
              background: "#EBEBEB",
              margin: "28px 0 36px 0",
            }}
          ></div>

          <div
            style={{
              fontSize: 12,
              lineHeight: "20px",
              whiteSpace: "pre-line",
              paddingLeft: 20,
            }}
          >{`본인은 만 14세 이상이고,\n피카버스 개인정보 처리방침에 따른 정보수집 동의 후 결제합니다.`}</div>
        </div>
        <div style={{ padding: "20px", background: "white" }}>
          <button
            style={{
              borderRadius: "8px",
              padding: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              color: isEnableButton ? "#FFF" : "#707070",
              backgroundColor: isEnableButton ? "#1E1E1E" : "#EBEBEB",
              width: "100%",
            }}
            disabled={!isEnableButton}
            onClick={() => handlePayment()}
          >
            {isEnableButton
              ? `${numberWithCommas(totalMoney)}원 결제하기`
              : "결제하기"}
          </button>
        </div>
      </div>
    </div>
  );
}
