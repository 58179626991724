import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  COLORS,
  FONT_WEIGHT,
  ICON_SIZE,
  LINE_HEIGHT,
  RADIUS,
  TEXTSIZE,
} from "../../theme/constants";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Modal, Typography } from "@mui/material";
import { getUserBalance } from "../../api";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";
import { handleError, numberWithCommas } from "../../utils/commonFunction";
import CircularComponent from "../process/CircularComponent";
import ModalComponent from "../../components/modal/ModalComponent";
// icon and image
import { ReactComponent as ArrowLeft } from "../../assets/svgs/arrow_left.svg";
import { ReactComponent as ExploreIcon } from "../../assets/svgs/explore.svg";
import { textMediumMuiStyle, buttonMuiStyle } from "../../utils/commonStyle";
import { useAuth } from "../../context/Auth";
import { useTranslation } from "react-i18next";
import Recharge from "../payment/Recharge";
import useModalReducer, { MODAL_STATUS } from "../../hooks/useModalReducer";
import { ReactComponent as PurchaseIcon } from "@icons/purchase_black.svg";

const Wrapper = styled.div`
  padding: 0;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  position: relative;
`;

const WrapperHeader = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  top: 0;

  .header {
    padding: 1rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header__icon {
    fill: ${COLORS.white};
    cursor: pointer;
  }

  .header__text {
    font-size: ${TEXTSIZE.large};
    font-weight: ${FONT_WEIGHT.large};
    line-height: ${LINE_HEIGHT.superLarge};
    color: ${COLORS.white};
  }

  .header__right {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: right;
    color: ${COLORS.white};
    cursor: pointer;
  }
`;

const WrapperContent = styled.div`
  // margin-bottom: 6rem;

  .display-flex-row-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .background-image {
    position: relative;
    // height: calc(20vh + 130px);
    padding-bottom: 80px;
  }

  .background-image__first {
    padding-top: 56px;
    padding-bottom: 2rem;
    position: relative;
    // height: calc(30vh + 50px);
    background: linear-gradient(124.24deg, #9725ae 0%, #5460d0 66.66%, #04a5fd 100%);
    // height: 100%;
    border-bottom-left-radius: ${RADIUS.superLarge};
    border-bottom-right-radius: ${RADIUS.superLarge};
  }

  .background-image__first__second {
    background: linear-gradient(124.24deg, #9725ae 0%, #5460d0 66.66%, #04a5fd 100%);
    height: 100%;
    border-bottom-right-radius: ${RADIUS.superLarge};
  }

  .profile {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    height: 130px;
  }

  .profile__avatar {
    width: 100px;
    height: 100px;
  }

  .profile__icon {
    position: absolute;
    background-color: ${COLORS.white};
    border-radius: 50%;
    padding: 3px;
    height: ${ICON_SIZE.medium};
    width: ${ICON_SIZE.medium};
    box-shadow: 2px 2px 5px 0px ${COLORS.grey500};
    cursor: pointer;
    stroke: ${COLORS.grey500};
  }

  .profile__icon--camera {
    top: 5px;
    right: 15px;
  }

  .profile__icon--edit {
    bottom: 4px;
    right: 4px;
  }

  .purchase-icon {
    path {
      fill: white;
    }
  }
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "16px",
  padding: "24px 20px",
  maxWidth: "328px",
};

function MyWallet() {
  const { logout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "Fail",
    status: "",
  });
  const channel = new BroadcastChannel("payment-connected");
  const { vertical, horizontal, open, message, status } = state;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef(null);

  const { modalData, dispatchModal } = useModalReducer();

  const [userFund, setUserFund] = useState({
    charge: 0,
    revenue: 0,
    bonus: 0, // Bonus PIC (tiền nhận từ hệ thống)
    event: 0,
    total: 0,
    pic_in_month: 0, // 이번 달 받은 PIC은 => Số PIC nhận trong tháng (hàng đầu tiên)
    balance: 0, // PIC (số dư hiện tại)
  });

  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  const handleDrawerClick = (type) => {
    switch (type) {
      case "selectFile":
        fileInputRef.current.click();
        break;
      case "revertFile":
        handleRevertFile();
        break;
      case "deleteFile":
        handleDeleteFile();
        break;
      default:
        break;
    }
    handleDrawer(false);
  };

  const handleGetUserBalance = async () => {
    try {
      const res = await getUserBalance();
      setUserFund(res);
    } catch (error) {
      handleError(error, logout);
    }
  };

  useEffect(() => {
    handleGetUserBalance();
  }, []);

  //modal action
  const handleShowModalWithdraw = (data) => {
    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        btnOKText: t("button.check"),
        btnCancelText: "",
        title: "확인",
        subTitle: "",
        content: <Typography>준비중입니다.</Typography>,
      },
    });
  };
  // modal end

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleConnectPayment = async (onMessageData) => {
    channel.close();
    setState({ ...state, open: true, status: onMessageData?.status });
  };
  channel.onmessage = (msg) => {
    const onMessageData = {
      status: msg.data,
    };
    handleConnectPayment(onMessageData);
  };

  useEffect(() => {
    if (status && open) {
      handleGetUserBalance();
    }
  }, [status, open]);

  return (
    <Box>
      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame
            setOnDrawer={() => handleDrawer(false)}
            type='profile-upload'
            callBack={handleDrawerClick}
            data={drawerData}
          />
        </DrawerPortal>
      )}

      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleOk={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleCancel={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
      <Wrapper>
        <WrapperHeader>
          <Box className='header'>
            <ArrowLeft className='header__icon' onClick={() => navigate(-1)} />
            <Typography className='header__text'>My PIC</Typography>
            <Link to={"/profile/history"}>
              <Typography className='header__right'>내역보기</Typography>
            </Link>
          </Box>
        </WrapperHeader>
        <WrapperContent className='editProfile'>
          {isLoading ? (
            <CircularComponent />
          ) : (
            <>
              <Box className='background-image__first'>
                <Box
                  sx={{
                    margin: "12px 28px 0px 28px",
                    borderRadius: "20px",
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    padding: "1.25rem 1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "12px",
                      borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ExploreIcon fill={COLORS.white} stroke={COLORS.white} />
                      <Typography
                        sx={{
                          ...textMediumMuiStyle,
                          fontSize: TEXTSIZE.small,
                          color: COLORS.white,
                          ml: "4px",
                        }}
                      >
                        {t("currently_available", { content: "PIC" })}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      paddingTop: "12px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          alignItems: "center",
                          color: COLORS.white,
                          marginBottom: "16px",
                          gap: "8px"
                        }}
                      >
                        <PurchaseIcon className="purchase-icon"/>
                        <Typography
                          sx={{
                            fontSize: "24px",
                            fontWeight: "700",
                            lineHeight: "30px",
                            textAlign: "right",
                          }}
                        >
                          {numberWithCommas(userFund?.balance)}
                        </Typography>
                      </Box>
                      <Button
                        sx={{
                          ...buttonMuiStyle,
                          backgroundColor: COLORS.white,
                          color: COLORS.grey900,
                          padding: "12px",
                          "&.Mui-disabled": {
                            backgroundColor: COLORS.white,
                            color: COLORS.grey900,
                          },
                        }}
                        variant='contained'
                        onClick={handleShowModalWithdraw}
                      >
                        정산하기
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Recharge />
            </>
          )}
        </WrapperContent>
      </Wrapper>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            style={{ fontSize: "18px", fontWeight: 700, lineHeight: "22px" }}
          >
            {status ? "구매 완료" : "구매 실패"}
          </Typography>
          <Typography
            id='modal-modal-description'
            sx={{ mt: 2 }}
            style={{ fontSize: "14px", lineHeight: "22px" }}
          >
            {status
              ? "구매가 정상적으로 완료되었습니다!"
              : "구매에 오류가 발생했습니다. 다시 시도해 주세요!"}
          </Typography>

          <Typography
            id='modal-modal-description'
            sx={{ mt: "24px" }}
            style={{
              fontSize: "16px",
              borderRadius: "28px",
              padding: "12px",
              lineHeight: "24px",
              backgroundColor: "#8F0DED",
              textAlign: "center",
              color: "white",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            확인
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
}

export default MyWallet;
