import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ko";
import { getUserTimezone } from "./commonFunction";
// import utc from "dayjs/plugin/utc";
// dayjs.extend(utc)

dayjs.extend(relativeTime);
dayjs.locale("ko");

export const dateTimeKorean = (date) => {
  const result = dayjs(date).format("MM.DD HH.mm 기준");
  return result;
};

export const relativeTimeKorean = (date) => {
  const result = dayjs(convertResponseTimeToLocalTime(date)).fromNow();
  return result;
};

// export const formatDate = (date, format = "MM.DD") => {
//   const result = dayjs(date).format(format);
//   return result;
// };

export const dateTimeFormat = (date, format = "MM.DD") => {
  const result = dayjs(date).format(format);
  return result;
};

export const relativeTimeMoreThanHour = (date, hour) => {
  const result = dayjs().diff(dayjs(date),"h") > hour;
  return result;
};

export const convertResponseTimeToLocalTime = (date) => {
  // current response time is local time but dayjs auto check it is +0 so we need to convert to local time again
  const result = dayjs(date).subtract(getUserTimezone(), "hour");
  return result;
};