import { Box, Button, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import { forgotPassword } from "../../api";
import { COLORS, HEIGHT, LINE_HEIGHT, TEXTSIZE, WIDTH } from "../../theme/constants";
import clearImage from "../../assets/images/clear.svg";
import { handleError, validateEmail } from "../../utils/commonFunction";
import {
  inputAdornmentMuiStyle,
  inputMuiStyle,
  labelMediumMuiStyle,
  textFieldMuiStyle,
  buttonLargeMuiStyle,
  textMediumMuiStyle,
} from "../../utils/commonStyle";
import useModalReducer, { MODAL_STATUS } from "../../hooks/useModalReducer";
import ModalComponent from "../../components/modal/ModalComponent";
import { useTranslation } from "react-i18next";
import HeaderBack from "../../components/header/HeaderBack";

const Wrapper = styled.div`
  position: relative;
  height: calc(calc(var(--vh, 1vh) * 100) - ${HEIGHT.header_mo} - ${HEIGHT.navigation});
  width: 100%;

  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [requestData, setRequestData] = useState({
    email: "",
  });
  const [countString, setCountString] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const { modalData, dispatchModal } = useModalReducer();

  const handleChange = (key, value) => {
    let message = "";
    if (value && !validateEmail(value)) {
      message = "올바른 이메일 형식이 아닙니다.";
    }
    setCountString(value.length);
    setErrorMessage(message);
    setRequestData({ ...requestData, [key]: value });
  };

  const handleReset = async () => {
    try {
      const res = await forgotPassword(requestData);
      dispatchModal({
        type: "OPEN",
        data: {
          btnOKText: "확인",
          btnCancelText: "",
          title: res?.title,
          subTitle: "",
          content: (
            <Typography
              sx={{
                ...textMediumMuiStyle,
                color: COLORS.grey900,
                lineHeight: LINE_HEIGHT.xxxLarge,
                whiteSpace: "pre-line",
              }}
            >
              {res?.description}
            </Typography>
          ),
        },
      });
    } catch (error) {
      console.log("handleReset error", error);
      switch (error?.response?.data?.code) {
        case 400:
          setErrorMessage(error?.response?.data?.message);
          break;
        case 422:
          setErrorMessage(error?.response?.data?.errors?.email?.[0]);
          break;
        default:
          // setErrorMessage();
          break;
      }
      handleError(error, logout);
    }
  };

  const handleCloseModal = () => {
    navigate("/login", { replace: true });
    dispatchModal({ type: MODAL_STATUS.CLOSE });
  };

  return (
    <>
      <Wrapper>
        <HeaderBack title={t("page_title.identify")}/>
        <Box sx={{ pt: 7 }}>
          <InputLabel sx={labelMediumMuiStyle}>가입시 등록한 이메일 주소</InputLabel>
          <TextField
            value={requestData?.email}
            onChange={(event) => handleChange("email", event.target.value)}
            placeholder={t("please_enter_your_email_address")}
            sx={textFieldMuiStyle}
            inputProps={{
              sx: inputMuiStyle,
            }}
            InputProps={{
              endAdornment: countString > 0 && (
                <InputAdornment
                  position='end'
                  sx={inputAdornmentMuiStyle}
                  onClick={() => handleChange("email", "")}
                >
                  <img src={clearImage} />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
            <Typography
              sx={{
                color: COLORS.redError,
                fontSize: TEXTSIZE.small,
                visibility: errorMessage ? "visible" : "hidden",
              }}
            >
              {errorMessage}
            </Typography>
          </Box>
        </Box>
        <Button
          sx={{
            ...buttonLargeMuiStyle,
            position: "absolute",
            bottom: "0px",
          }}
          disabled={!!!requestData.email || !!errorMessage}
          variant='contained'
          size='large'
          className='submit'
          onClick={handleReset}
        >
          재설정 하기
        </Button>
      </Wrapper>
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={handleCloseModal}
        handleOk={handleCloseModal}
        handleCancel={handleCloseModal}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
    </>
  );
}

export default ResetPassword;
