import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled as styledMui } from "@mui/material";
import { COLORS, FONT_WEIGHT, WIDTH } from "../../theme/constants";
import { textMediumMuiStyle } from "../../utils/commonStyle";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import CircularComponent from "../process/CircularComponent";
import styledComponent from "styled-components";
import { getUserConfigsNotifications, setUserConfigsNotifications } from "../../api";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import IOSSwitch from "../../components/switch/IOSSwitch";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

const HeightWhenEmpty = styledComponent.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 230px);
  position: relative;
`;

export default function SettingNotifications() {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const nameByType = {
    connect: t("connect_notification"),
    comment: t("comment_notification"),
    pic: t("PIC_notification"),
    message: t("message_notification"),
  };

  const handleSubmit = async (submitData) => {
    try {
      const requestData = {
        configs: [...submitData],
      };
      await setUserConfigsNotifications(requestData);
      getData();
    } catch (error) {
      handleError(error, logout);
    }
  };

  const handleChange = (event) => {
    const tmpData = [...data];
    const index = tmpData.findIndex((el) => el.type === event.target.name);
    tmpData[index].is_active = !tmpData[index].is_active;
    setData(tmpData);
    handleSubmit(tmpData);
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await getUserConfigsNotifications();
      console.log("res", res);
      if (!res) return;
      setData(res?.configs);
    } catch (error) {
      handleError(error, logout);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  if (isLoading)
    return (
      <HeightWhenEmpty>
        <CircularComponent />
      </HeightWhenEmpty>
    );

  return (
    <Wrapper>
      <FormControl component='fieldset' variant='standard' fullWidth>
        <FormGroup>
          {data.map((item) => (
            <FormControlLabel
              key={item.type}
              sx={{
                ml: 0,
                py: 2,
                my: 0.75,
                width: "100%",
                "& .MuiFormControlLabel-label": {
                  ...textMediumMuiStyle,
                  marginRight: "auto",
                  fontWeight: FONT_WEIGHT.large,
                },
              }}
              control={
                <IOSSwitch checked={item.is_active} onChange={handleChange} name={item.type} />
              }
              labelPlacement='start'
              label={nameByType[item.type]}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Wrapper>
  );
}
