import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { COLORS, RADIUS, TEXTSIZE } from "../../theme/constants";
// import { useNavigate } from "react-router-dom";

const ListStyleWrap = styled.div`
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  .list-drawer {
    background: ${COLORS.white};
    margin: 0 16px;
    padding: 0;
    border-radius: ${RADIUS.medium};
    overflow: hidden;

    &__button {
      width: calc(100% - 32px);
      margin: 12px 16px 20px;
      background: ${COLORS.white};
      border: 0;
      font-size: ${TEXTSIZE.medium};
      color: ${COLORS.black};
      border-radius: ${RADIUS.medium};
    }

    .warning {
      color: var(--notification_errorLight);
    }
  }

  .MuiButtonBase-root {
    padding: 0;
    height: 56px;
    background-color: var(--bgColor);
    color: var(--textColor);

    &:hover {
      background-color: var(--bgColor);
      text-decoration: underline;
    }

    * {
      height: 100%;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${TEXTSIZE.medium};

      &.warning {
        color: var(--notification_errorLight);
      }
    }
  }
`;

// const CancelToast = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;

//   button {
//     color: #777;
//     cursor: pointer;
//   }
// `;

function EditDrawer({ close }) {
  // const navigate = useNavigate();

  const modalData = [
    {
      text: "수정",
      fn: () => {
        // navigate("/setting/설정");
      },
    },
    {
      text: "공유",
      fn: () => {
        console.log("공유");
      },
    },
    {
      text: "삭제",
      fn: () => {
        console.log("삭제");
      },
    },
  ];

  return (
    <>
      <ListStyleWrap>
        <List className='list-drawer'>
          {modalData.map((item, index) => (
            <ListItem disablePadding key={index}>
              <ListItemButton>
                <ListItemText primary={item.text} onClick={item.fn} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Button
          variant='text'
          size='large'
          className='list-drawer__button'
          onClick={close}>
          닫기
        </Button>
      </ListStyleWrap>
    </>
  );
}

export default React.memo(EditDrawer);
