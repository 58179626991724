import React from "react";
import { COLORS, RADIUS } from "../../../../theme/constants";
import styled from "styled-components";
import ImageDefault from "../../../../assets/images/default_image.jpg";
import Overlay from "../../../overlay/Overlay";
import { useMatch } from "react-router-dom";
import { isMine } from "../../../../utils/commonFunction";
import { BlurhashCanvas } from "react-blurhash";

const Wrapper = styled.div`
  width: 100%;
  aspect-ratio: 1;
  .bg-img {
    border-radius: ${RADIUS.large};
    width: 100%;
    height: 100%;
  }
`;

function MyFeedMedia(props) {
  const { data, index = 0, post } = props;

  const getImageUrl = () => {
    let result = ImageDefault;
    if (post?.is_paid || post?.cost <= 0 || index === 0 || isMine(post?.user_profile?.user_id)) {
      result = data?.view_url || data?.url;
    }

    return result || ImageDefault;
  };

  const isBlurImage = () => {
    let result = false;
    if (
      !isMine(post?.user_profile?.user_id) &&
      post?.cost > 0 &&
      !post?.is_paid &&
      index != 0 &&
      data?.is_locked == 1
    ) {
      result = true;
    }
    return result;
  };

  return (
    <Wrapper>
      {isBlurImage() && data?.blur_view_url ? (
        <BlurhashCanvas className='bg-img' hash={data?.blur_view_url} punch={0} />
      ) : (
        <img
          className='bg-img'
          src={getImageUrl()}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = ImageDefault;
          }}
        />
      )}

      <Overlay
        styleCustomize={{
          borderRadius: RADIUS.large,
        }}
      />
      {/* {homeMatch && (
        <Overlay
          styleCustomize={{
            borderRadius: RADIUS.large,
            background:
              "linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7))",
          }}
        />
      )} */}
    </Wrapper>
  );
}

export default MyFeedMedia;
