import React from "react";
import styled from "styled-components";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { COLORS, RADIUS, TEXTSIZE } from "../../theme/constants";
import { format } from "date-fns";
import PicHistorySkeleton from "../../components/layouts/skeleton/PcikHistory";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState } from "react";
import { Box, Button, InputLabel, Typography } from "@mui/material";
import { labelMediumMuiStyle } from "../../utils/commonStyle";

const Wrapper = styled.div`
  padding: 38px 0 0;

  .history-list {
    .history-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 64px;
      background-color: var(--colorF3to3);
      border-radius: ${RADIUS.medium};
      padding: 12px;
      font-size: ${TEXTSIZE.medium};
      font-weight: 600;

      .pic-price {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }

  .history-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 12px;
    text-align: left;
    font-size: ${TEXTSIZE.xSmall};
    line-height: 17px;
    color: ${COLORS.gray_7};
  }

  .info-text {
    margin: 20px auto;
    font-size: ${TEXTSIZE.xSmall};
    color: ${COLORS.gray_7};
    text-align: center;
  }

  .header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;
    padding: 30px 24px;
    width: 100%;
    height: 89px;
    background: var(--colorF3to3);
    border-radius: ${RADIUS.medium};
    font-size: ${TEXTSIZE.xxxLarge};
    font-weight: 700;

    .button-submit {
      margin: auto 0 auto auto;
      background: ${COLORS.grey50};
      color: ${COLORS.grey600};
      padding: 0.75rem;
      border-radius: ${RADIUS.xxLarge};
      &:hover {
        background: ${COLORS.primary4};
        color: ${COLORS.white};
      }

      &.disabled {
        background: ${COLORS.grey100};
        color: ${COLORS.grey600};
        cursor: not-allowed;
      }
    }
  }

  .header--left {
    display: flex;
    align-items: center;
    width: 50%;

    .MuiFormControl-root {
      width: 190px;
      height: 100%;
    }

    .MuiSelect-select {
      font-size: ${TEXTSIZE.xxxLarge};
      font-weight: 700;
      line-height: 100%;
      min-height: unset;
      color: ${COLORS.black1};
      border-radius: 28px;
      background: ${COLORS.grey50};
      padding: 0.75rem;
    }

    .MuiOutlinedInput-notchedOutline {
      border-radius: 28px;
    }

    // .Mui-focused .MuiOutlinedInput-notchedOutline {
    //   border-color: ${COLORS.primary4};
    // }
  }

  .top-list {
    margin-bottom: 60px;
  }

  .title__name {
    font-size: ${TEXTSIZE.xxLarge};
    font-weight: 700;
    cursor: pointer;
  }

  .text--header {
    font-size: ${TEXTSIZE.medium};
  }

  .pagination {
    margin: 24px auto 0;

    .MuiPagination-ul {
      justify-content: center;
    }
  }
`;

function CalculateHistory({ data, monthlyData }) {
  const isLoading = false;
  const [isDisabled, setIsDisabled] = useState(true);
  const [values, setValues] = useState({
    date: "202304",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setIsDisabled(false);
    setValues({
      ...values,
      [name]: value,
    });
  };

  console.log(data);

  return (
    // <Wrapper>
    //   {isLoading ? (
    //     <PicHistorySkeleton />
    //   ) : (
    //     <>
    //       <section className="section">
    //         <h2 className="title__name">월간 정산 금액</h2>
    //         <div className="header-wrap">
    //           <div className="header--left">
    //             <FormControl sx={{ width: "30%", height: "44px" }}>
    //               <InputLabel sx={labelMediumMuiStyle} id="select-date-label">
    //                 2023년 4월
    //               </InputLabel>
    //               <Select
    //                 className="select-date"
    //                 labelId="select-date-label"
    //                 id="select-date-id"
    //                 value={values.date}
    //                 label="2023년 4월"
    //                 name="date"
    //                 onChange={handleChange}
    //               >
    //                 <MenuItem value="202304">2023년 04월</MenuItem>
    //                 <MenuItem value="202303">2023년 03월</MenuItem>
    //                 <MenuItem value="202302">2023년 02월</MenuItem>
    //               </Select>
    //             </FormControl>
    //             <Typography sx={{ marginLeft: 2 }}>의 정산 금액</Typography>
    //           </div>
    //           <div className="header--right">
    //             <Button type="button" className="button-submit">
    //               정산 금액 확인
    //             </Button>
    //           </div>
    //         </div>
    //         <section className="top-list">
    //           <div className="history-table-header">
    //             <div className="pic-price-header text--header">정산 금액</div>
    //             <div className="pic-count-header text--header">PIC 개수</div>
    //             <div className="pic-date-header text--header">날짜</div>
    //           </div>
    //           <List className="history-list">
    //             {monthlyData.map((item) => (
    //               <ListItem
    //                 disablePadding
    //                 className="history-item-wrap"
    //                 key={item.id}
    //               >
    //                 <ListItemText
    //                   primary={
    //                     <div className="history-item">
    //                       <div className="pic-price">
    //                         {item.price
    //                           .toString()
    //                           .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    //                         원
    //                       </div>
    //                       <div className="pic-count">
    //                         {item.pic_count
    //                           .toString()
    //                           .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    //                         개
    //                       </div>
    //                       <div className="pic-date">
    //                         {format(
    //                           new Date(item.date.replace(/-/g, "/")),
    //                           "yy.MM.dd"
    //                         )}
    //                       </div>
    //                     </div>
    //                   }
    //                 />
    //               </ListItem>
    //             ))}
    //           </List>
    //         </section>
    //       </section>
    //       <section className="section">
    //         <h2 className="title__name">전체 정산 내역</h2>
    //         <div className="history-table-header">
    //           <div className="pic-price-header text--header">정산 금액</div>
    //           <div className="pic-count-header text--header">PIC 개수</div>
    //           <div className="pic-date-header text--header">날짜</div>
    //         </div>
    //         <List className="history-list">
    //           {data.map((item) => (
    //             <ListItem
    //               disablePadding
    //               className="history-item-wrap"
    //               key={item.id}
    //             >
    //               <ListItemText
    //                 primary={
    //                   <div className="history-item">
    //                     <div className="pic-price">
    //                       {item.price
    //                         .toString()
    //                         .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    //                       원
    //                     </div>
    //                     <div className="pic-count">
    //                       {item.pic_count
    //                         .toString()
    //                         .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    //                       개
    //                     </div>
    //                     <div className="pic-date">
    //                       {format(
    //                         new Date(item.date.replace(/-/g, "/")),
    //                         "yy.MM.dd"
    //                       )}
    //                     </div>
    //                   </div>
    //                 }
    //               />
    //             </ListItem>
    //           ))}
    //         </List>

    //         <Stack spacing={2} className="pagination">
    //           <Pagination count={10} shape="rounded" />
    //         </Stack>
    //       </section>
    //     </>
    //   )}
    // </Wrapper>
    <Box sx={{ display: "flex", justifyContent: "center", pt:5 }}>
      <Typography variant="h5">개발 중인 상태입니다</Typography>
    </Box>
  );
}

export default React.memo(CalculateHistory);
