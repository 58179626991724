import React from "react";
import { COLORS } from "../../theme/constants";
import { useNavigate } from "react-router-dom";
import DrawerListComponent from "./DrawerListComponent";
import { ReactComponent as MessageIcon } from "../../assets/svgs/message.svg";
import { ReactComponent as SmileIcon } from "../../assets/svgs/smile_black_1.svg";
import { handleError } from "@utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { getParticipantExternal } from "../../service/chat_services";
import { useTranslation } from "react-i18next";

function ConnectedSponsors({ close, data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const onClickMessage = async () => {
    try {
      const res = await getParticipantExternal(data?.user_id, {});
      navigate(`/chat/detail/${res?.id}`, {state: {userName: res?.name}});
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const modalData = [
    {
      text: t("send_message"),
      icon: <MessageIcon stroke={COLORS.black1} />,
      fn: () => {
        onClickMessage()
        close();
      },
    },
    {
      text: t("redirect_to_profile"),
      icon: <SmileIcon stroke={COLORS.black1} />,
      fn: () => {
        close();
        navigate(`/user-profile/${data?.nickname}`);
      },
    },
  ];

  return <DrawerListComponent close={close} modalData={modalData} />;
}

export default React.memo(ConnectedSponsors);
