import { Box, InputAdornment, List, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../theme/constants";
import {
  inputAdornmentMuiStyle,
  inputMuiStyle,
  textFieldMuiStyle,
} from "../../utils/commonStyle";
import { handleError, isMine } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { getConnecting, getConnectingOther } from "../../api";
import CircularComponent from "../process/CircularComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import ItemConnect from "./ItemConnect";
import { ReactComponent as SearchIcon } from "../../assets/svgs/search.svg";
import { ReactComponent as ClearIcon } from "../../assets/svgs/clear.svg";
import NoData from "../../components/NoData";
import { useNavigate, useParams } from "react-router-dom";

const Wrapper = styled.div``;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 230px);
  position: relative;
`;

function ListConnecting(props) {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { pageName: nickname } = useParams();
  const { refreshPage } = props;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleOnKeyUp = (e) => {
    if (e.key === "Enter") {
      fetchMoreData({ offset: 0 });
    }
  };

  const handleClear = () => {
    setSearchKeyword("");
    fetchMoreData({ offset: 0, keyword: "" });
  };

  const fetchMoreData = async (newRequestParam) => {
    try {
      if (data.length == 0) {
        setIsLoading(true);
      }
      const requestParam = {
        limit: 15,
        offset: data.length,
        keyword: searchKeyword,
        ...newRequestParam,
      };
      const res = isMine(nickname)
        ? await getConnecting(requestParam)
        : await getConnectingOther(requestParam, { nickname: nickname });
      if (!res?.list) return;
      if (newRequestParam?.offset === 0) {
        setData(res.list);
      } else {
        setData((prevState) => [...prevState, ...res.list]);
      }
    } catch (error) {
      handleError(error, logout, navigate);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = () => {
    // setData(() => []);
    fetchMoreData({ offset: 0 });
    refreshPage();
  };

  useEffect(() => {
    fetchMoreData({ offset: 0 });
  }, []);

  if (isLoading)
    return (
      <HeightWhenEmpty>
        <CircularComponent />
      </HeightWhenEmpty>
    );

  return (
    <Wrapper>
      <TextField
        name="searchKeyword"
        placeholder="아이디 검색"
        value={searchKeyword}
        onChange={(event) => {
          setSearchKeyword(event.target.value);
        }}
        onKeyUp={handleOnKeyUp}
        sx={{
          position: "sticky",
          top: "calc(48px + 56px)",
          zIndex: 1,
          py: 1,
          background: COLORS.white,
          ...textFieldMuiStyle,
          "& .MuiOutlinedInput-notchedOutline": {
            backgroundColor: COLORS.white,
            border: `1px solid ${COLORS.grey100}`,
            borderRadius: "28px",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: COLORS.grey100,
            },
            "&:hover fieldset": {
              borderColor: COLORS.grey100,
            },
            "&.Mui-focused fieldset": {
              borderColor: COLORS.grey100,
            },
          },
        }}
        inputProps={{
          sx: {
            ...inputMuiStyle,
            pl: 1,
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end" sx={inputAdornmentMuiStyle}>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: searchKeyword.length > 0 && (
            <InputAdornment
              position="end"
              sx={inputAdornmentMuiStyle}
              onClick={handleClear}
            >
              <ClearIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box>
        {data.length > 0 ? (
          <InfiniteScroll
            dataLength={data.length}
            next={fetchMoreData}
            hasMore={true}
            // scrollableTarget="scrollableContent"
          >
            <List
              sx={{
                width: "100%",
                // height: "calc(100vh - 220px)",
                bgcolor: "background.paper",
                // overflowY: "scroll",
              }}
              // id="scrollableConnecting"
            >
              {data.map((connect) => (
                <ItemConnect
                  {...connect.connecting_user_profile}
                  fetchData={fetchData}
                  key={connect.id}
                  listItemStyle={{ maxWidth: "100%" }}
                  isConnect={isMine(nickname) ? true : connect.isConnect}
                />
              ))}
            </List>
          </InfiniteScroll>
        ) : (
          <HeightWhenEmpty>
            {/* <NoData text={isMine(nickname) && `콘텐츠를 탐색해\n다양한 커넥팅을 만들어보세요!`} /> */}
            <NoData text={`콘텐츠를 탐색해\n다양한 커넥팅을 만들어보세요!`} />
          </HeightWhenEmpty>
        )}
      </Box>
    </Wrapper>
  );
}

export default ListConnecting;
