import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { FONT_WEIGHT } from "../../theme/constants";
import SwiperTags from "../../components/newComponents/tags/SwiperTags";
import { SearchContext } from "../../components/layouts/SearchFeedLayout";
import {
  textMediumMuiStyle,
  textSmallMuiStyle,
} from "../../utils/commonStyle";

function RecentlyKeywords(props) {
  const { onClickTag, isSearch = false } = props;
  const { enterPress, searchKeyword, setSearchKeyword } =
    useContext(SearchContext);
  //   const isSearch = false;
  const localKey = isSearch ? "recentlyKeywords" : "exploreRecentlyKeywords";
  const localRecently = JSON.parse(localStorage.getItem(localKey)) || [];

  const [isEdit, setIsEdit] = useState(false);
  const [recentlyKeywords, setRecentlyKeywords] = useState(localRecently);

  const onDeleteRecentlyKeyword = (value) => {
    const found = localRecently.filter((element) => element !== value);
    setRecentlyKeywords(found);
    localStorage.setItem(localKey, JSON.stringify(found));
  };

  const clearRecentlyData = () => {
    if (isEdit) {
      setRecentlyKeywords([]);
      localStorage.removeItem(localKey);
    }
  };

  useEffect(() => {
    if (enterPress > 0 && searchKeyword != "") {
      const found = localRecently.find((element) => element === searchKeyword);
      if (found) {
        return;
      }
      localRecently.unshift(searchKeyword);
      setRecentlyKeywords(localRecently);
      localStorage.setItem(localKey, JSON.stringify(localRecently));
    }
  }, [enterPress]);

  return (
    <Box>
      {recentlyKeywords?.length ? (
        <Box sx={{ mb: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              sx={{
                ...textMediumMuiStyle,
                fontWeight: FONT_WEIGHT.xxLarge,
              }}
            >
              {isSearch ? "최근 검색어" : "최근 검색 유저"}
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {isEdit && (
                <Typography
                  sx={{ ...textSmallMuiStyle, cursor: "pointer", mr: 1.5 }}
                  onClick={() => clearRecentlyData()}
                >
                  전체 삭제
                </Typography>
              )}
              <Typography
                sx={{ ...textSmallMuiStyle, cursor: "pointer" }}
                onClick={() => setIsEdit(!isEdit)}
              >
                {isEdit ? "닫기" : "편집"}
              </Typography>
            </Box>
          </Box>
          <SwiperTags
            selectedTag={""}
            tags={recentlyKeywords}
            isEdit={isEdit}
            onClickTag={onClickTag}
            onDelete={onDeleteRecentlyKeyword}
          />
        </Box>
      ) : null}
    </Box>
  );
}

export default RecentlyKeywords;
