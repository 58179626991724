import { useInfiniteQuery } from "@tanstack/react-query";
// import { getChats } from "../../api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import { handleError } from "../../utils/commonFunction";
import { useCallback, useEffect, useState } from "react";
import { getChats } from "../../service/chat_services";

function useGetChats(props) {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const [filters, setFilters] = useState({ keyword: "" });
  const [recentlyChats, setRecentlyChats] = useState([]);

  const handleChangeFilters = useCallback((filterParams) => {
    setFilters((_prev) => filterParams);
  }, []);

  const fetchData = async ({ pageParam, queryKey }) => {
    // console.log("pageParam", pageParam, queryKey);
    try {
      const res = await getChats({ includeEmptyMessage: true, ...filters });
      return res;
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["recent-chats"],
    queryFn: fetchData,
    // staleTime: 0,
    // initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage) return;
      // return lastPage?.meta?.offset_next;
    },
  });

  useEffect(() => {
    const newRecentlyChats = [];
    if (!data?.pages?.[0]) return;
    data?.pages?.forEach((chat) => {
      if (chat?.length > 0) {
        const recentlyChat = structuredClone(chat);
        newRecentlyChats.push(...recentlyChat);
      }
    });
    setRecentlyChats(newRecentlyChats);
  }, [data]);

  return {
    data: recentlyChats,
    isEmptyData: !data?.pages?.[0],
    isLoading,
    filters,
    fetchNextPage,
    handleChangeFilters,
    // handleChangeQueryKey,
  };
}

export default useGetChats;
