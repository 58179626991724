import { Box, List, Typography } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  COLORS,
  FONT_WEIGHT,
  LINE_HEIGHT,
  TEXTSIZE,
} from "../../theme/constants";
import { textSmallMuiStyle } from "../../utils/commonStyle";
import { Swiper, SwiperSlide } from "swiper/react";
import NoData from "../../components/NoData";
import ItemMemberNotConnected from "./ItemMemberNotConnected";

const Wrapper = styled.div``;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  position: relative;
`;

function MemberNotConnected(props) {
  const { postID } = useParams();
  const {  type, data, fetchData, fetchMoreData } = props;

  return (
    <Wrapper>
      <Box sx={{ mb: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Link
              to={`/post/${postID}/sponsors/${type}/not-connected`}
            >
              <Typography
                sx={{
                  fontSize: TEXTSIZE.large,
                  fontWeight: FONT_WEIGHT.xxLarge,
                  lineHeight: LINE_HEIGHT.xxLarge,
                  color: COLORS.black1,
                }}
              >
                알 수도 있는 사람
              </Typography>
            </Link>
          </Box>
          <Link
            to={`/post/${postID}/sponsors/${type}/not-connected`}
          >
            <Typography sx={{ ...textSmallMuiStyle }}>더보기</Typography>
          </Link>
        </Box>
        {data.length > 0 ? (
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              padding: 0,
            }}
          >
            {/* onReachEnd={fetchMoreData} */}
            <Swiper width={316} onReachEnd={fetchMoreData}>
              {data.map((user) => (
                <SwiperSlide key={user.id}>
                  <ItemMemberNotConnected {...user} fetchData={fetchData} />
                </SwiperSlide>
              ))}
            </Swiper>
          </List>
        ) : (
          <HeightWhenEmpty>
            <NoData />
          </HeightWhenEmpty>
        )}
      </Box>
    </Wrapper>
  );
}

export default MemberNotConnected;
