import { useEffect } from "react";
import { io } from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || "";

function useSocket(props) {
  const chat_access_token = JSON.parse(
    localStorage.getItem("chat_access_token")
  );

  const socket = io(SOCKET_URL, {
    auth: {
      token: chat_access_token || "",
    },
    transports: ["websocket"],
  });

  // useEffect(() => {
  //   if (!socket?.connected && chat_access_token) {
  //     // // Update the extraHeaders of the socket
  //     // socket.io.opts.transports = ["websocket"];
  //     // socket.io.opts.extraHeaders = {
  //     //     Authorization: auth.access_token || '',
  //     // };

  //     // @ts-ignore
  //     socket.auth.token = chat_access_token || "";
  //     // // Reconnect with the updated headers
  //     socket.connect();
  //     console.log("socket connect", socket);
  //   }
  // }, []);

  const getConfigSocket = () => {
    return {
      Authorization: chat_access_token || "",
    };
  };

  return { socket, getConfigSocket };
}

export default useSocket;
