import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { animateScroll } from "react-scroll";
import { ReactComponent as ArrowUp } from "@icons/arrow_up.svg";
import styled from "styled-components";
import { COLORS, WIDTH } from "@theme/constants";
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  .icon-up--black {
    path {
      stroke: ${COLORS.black0};
    }
  }
`;

function BaseButtonScrollTop(props) {
  const { styleButtonProps, isDarkMode, isScrolling, isSideBar, isFooter } =
    props;

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const [isShow, setIsShow] = useState(false);

  const scrollToTop = () => {
    animateScroll.scrollToTop({
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "scrollableContent",
    });
  };

  useEffect(() => {
    if (document.getElementById("scrollableContent")?.scrollTop > 200) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }, [isScrolling]);

  return (
    <>
      {isPc && (
        <Wrapper>
          <Button
            onClick={scrollToTop}
            sx={{
              transition: "right 0.3s",
              zIndex: 2,
              position: "absolute",
              right: isShow ? (isSideBar ? "40px" : "200px") : "-500px",
              bottom: isFooter || isSideBar ? "180px" : "0",
              background: !isDarkMode ? COLORS.black0 : COLORS.white,
              width: "60px",
              height: "60px",
              borderRadius: "8px",
              boxShadow: "none",
              "&:hover": {
                background: !isDarkMode ? COLORS.black0 : COLORS.white,
              },
              "&.Mui-disabled": {
                backgroundColor: COLORS.grey100,
              },
              ...styleButtonProps,
            }}
          >
            <ArrowUp className={isDarkMode ? "icon-up--black" : ""} />
          </Button>
        </Wrapper>
      )}
    </>
  );
}

export default BaseButtonScrollTop;
