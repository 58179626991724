import { Box, Button, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { COLORS, HEIGHT, LINE_HEIGHT, TEXTSIZE, WIDTH } from "../../theme/constants";
import styled from "styled-components";
import { changePassword } from "../../api";
import { useNavigate } from "react-router-dom";
import clearImage from "../../assets/images/clear.svg";
import { handleError, isValidPassword } from "../../utils/commonFunction";
import {
  textMediumMuiStyle,
  labelMediumMuiStyle,
  textFieldMuiStyle,
  inputMuiStyle,
  inputAdornmentMuiStyle,
  buttonLargeMuiStyle,
} from "../../utils/commonStyle";
import { useAuth } from "../../context/Auth";
import useModalReducer, { MODAL_STATUS } from "../../hooks/useModalReducer";
import ModalComponent from "../../components/modal/ModalComponent";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  position: relative;
  // height: calc(
  //   calc(var(--vh, 1vh) * 100) - ${HEIGHT.header_mo} - ${HEIGHT.navigation}
  // );
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

const LIMIT_LENGTH = 16;

function ChangePassword(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const [requestData, setRequestData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const { modalData, dispatchModal } = useModalReducer();

  const handleChange = (key, value) => {
    setRequestData({ ...requestData, [key]: value });
  };

  const handleChangePassword = async () => {
    console.log("handleChangePassword");
    try {
      const res = await changePassword(requestData);
      // console.log("res", res);
      // navigate("/");
      dispatchModal({
        type: MODAL_STATUS.OPEN,
        data: {
          type: "success",
          open: true,
          btnOKText: "확인",
          btnCancelText: "",
          title: "알림",
          subTitle: "",
          content: (
            <Typography
              sx={{
                ...textMediumMuiStyle,
                color: COLORS.grey900,
                lineHeight: LINE_HEIGHT.xxxLarge,
                whiteSpace: "pre-line",
              }}
            >
              {res.message}
            </Typography>
          ),
        },
      });
    } catch (error) {
      const err = handleError(error, logout);
      if (typeof err === "object" && err !== null) {
        setErrorMessages(err);
      } else {
        dispatchModal({
          type: MODAL_STATUS.OPEN,
          data: {
            type: "error",
            btnOKText: "확인",
            btnCancelText: "",
            title: "알림",
            subTitle: "",
            content: (
              <Typography
                sx={{
                  ...textMediumMuiStyle,
                  color: COLORS.grey900,
                  lineHeight: LINE_HEIGHT.xxxLarge,
                  whiteSpace: "pre-line",
                }}
              >
                {err}
              </Typography>
            ),
          },
        });
      }
    }
  };

  const handleCloseModal = () => {
    if (modalData?.type !== "error") {
      // logout();
      navigate(-1, { replace: true });
    }
    dispatchModal({ type: MODAL_STATUS.CLOSE });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Wrapper>
          <Box>
            <Box sx={{ pt: 7 }}>
              <InputLabel sx={labelMediumMuiStyle}>현재 비밀번호</InputLabel>
              <TextField
                name='current_password'
                type='password'
                value={requestData.current_password}
                onChange={(event) => handleChange("current_password", event.target.value)}
                placeholder={t("please_enter_a_password")}
                sx={textFieldMuiStyle}
                inputProps={{
                  sx: inputMuiStyle,
                  maxLength: LIMIT_LENGTH,
                }}
                InputProps={{
                  endAdornment: requestData.current_password.length > 0 && (
                    <InputAdornment
                      position='end'
                      sx={inputAdornmentMuiStyle}
                      onClick={() => handleChange("current_password", "")}
                    >
                      <img src={clearImage} />
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                <Typography
                  sx={{
                    color:
                      requestData.current_password && !isValidPassword(requestData.current_password)
                        ? COLORS.redError
                        : COLORS.grey400,
                    fontSize: TEXTSIZE.small,
                  }}
                >
                  비밀번호 8~16자 영문, 숫자 조합
                </Typography>
                <Typography
                  sx={{
                    color: COLORS.grey400,
                    fontSize: TEXTSIZE.small,
                  }}
                >{`${requestData.current_password.length}/${LIMIT_LENGTH}`}</Typography>
              </Box>
            </Box>
            <Box sx={{ mt: 3.5 }}>
              <InputLabel sx={labelMediumMuiStyle}>새 비밀번호</InputLabel>
              <TextField
                name='new_password'
                type='password'
                value={requestData.new_password}
                onChange={(event) => handleChange("new_password", event.target.value)}
                placeholder={t("please_enter_a_password")}
                sx={textFieldMuiStyle}
                inputProps={{
                  sx: inputMuiStyle,
                  maxLength: LIMIT_LENGTH,
                }}
                InputProps={{
                  endAdornment: requestData.new_password.length > 0 && (
                    <InputAdornment
                      position='end'
                      sx={inputAdornmentMuiStyle}
                      onClick={() => handleChange("new_password", "")}
                    >
                      <img src={clearImage} />
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                <Typography
                  sx={{
                    color:
                      requestData.new_password && !isValidPassword(requestData.new_password)
                        ? COLORS.redError
                        : COLORS.grey400,
                    fontSize: TEXTSIZE.small,
                  }}
                >
                  비밀번호 8~16자 영문, 숫자 조합
                </Typography>
                <Typography
                  sx={{
                    color: COLORS.grey400,
                    fontSize: TEXTSIZE.small,
                  }}
                >{`${requestData.new_password.length}/${LIMIT_LENGTH}`}</Typography>
              </Box>
            </Box>
            <Box sx={{ mt: 3.5 }}>
              <InputLabel sx={labelMediumMuiStyle}>비밀번호 확인</InputLabel>
              <TextField
                name='confirm_password'
                type='password'
                value={requestData.confirm_password}
                onChange={(event) => handleChange("confirm_password", event.target.value)}
                placeholder='비밀번호를 한번 더 입력해주세요.'
                sx={textFieldMuiStyle}
                inputProps={{
                  sx: inputMuiStyle,
                  maxLength: LIMIT_LENGTH,
                }}
                InputProps={{
                  endAdornment: requestData.confirm_password.length > 0 && (
                    <InputAdornment
                      position='end'
                      sx={inputAdornmentMuiStyle}
                      onClick={() => handleChange("confirm_password", "")}
                    >
                      <img src={clearImage} />
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                <Typography
                  sx={{
                    color: COLORS.redError,
                    fontSize: TEXTSIZE.small,
                    visibility:
                      requestData.confirm_password &&
                      requestData.confirm_password != requestData.new_password
                        ? "visible"
                        : "hidden",
                  }}
                >
                  {/* 비밀번호가 일치하지 않습니다. */}
                  {t("passwords_do_not_match")}
                </Typography>
                <Typography
                  sx={{
                    color: COLORS.grey400,
                    fontSize: TEXTSIZE.small,
                  }}
                >{`${requestData.confirm_password.length}/${LIMIT_LENGTH}`}</Typography>
              </Box>
            </Box>
          </Box>
          <Button
            sx={{
              ...buttonLargeMuiStyle,
              position: "sticky",
              bottom: "10px",
            }}
            disabled={
              !!!requestData.current_password ||
              !!!requestData.new_password ||
              !!!requestData.confirm_password ||
              requestData.new_password != requestData.confirm_password ||
              !isValidPassword(requestData.current_password) ||
              !isValidPassword(requestData.new_password)
            }
            variant='contained'
            size='large'
            className='submit'
            onClick={handleChangePassword}
          >
            변경하기
          </Button>
        </Wrapper>
      </Box>
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={handleCloseModal}
        handleOk={handleCloseModal}
        handleCancel={handleCloseModal}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
    </>
  );
}

export default ChangePassword;
