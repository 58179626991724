import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const SkeletonWrap = styled.div`
  width: 100%;
  margin-bottom: 20px;

  .oneline {
    display: flex;
    align-items: center;
    gap: 8px;

    .MuiSkeleton-circular {
      flex: 0 1 auto;
    }

    .user-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4px;
      width: 100px;

      .MuiSkeleton-text {
        width: 100%;

        &:nth-child(2) {
          width: 60%;
          height: 8px;
        }
      }
    }

    & ~ .MuiSkeleton-rounded {
      position: relative;
      width: 100%;
      height: fit-content;
      overflow: hidden;

      &:after {
        display: block;
        content: "";
        padding-bottom: 100%;
      }
    }
  }

  .flex-wrap {
    display: flex;
    justify-content: space-between;
  }
`;

function FeedSkeleton() {
  return (
    <SkeletonWrap>
      <Stack spacing={1}>
        <Skeleton variant="text" sx={{width: "30%"}} />
        <div className="userprofile oneline">
          <Skeleton variant="circular" width={"2rem"} height={"2rem"} />
          <div className="user-text">
            <Skeleton className="title" variant="text" />
            <Skeleton className="title" variant="text" />
          </div>
        </div>
        <Skeleton variant="rounded" width={"100%"} />
        <div className="flex-wrap">
          <Skeleton
            className="title"
            variant="text"
            width={"2.5rem"}
            height={"1rem"}
          />
          <Skeleton variant="circular" width={"1rem"} height={"1rem"} />
        </div>
        <Skeleton variant="rounded" width={"100%"} height={"2.5rem"} />
        <Skeleton variant="text" width={"3rem"} height={"1rem"} />
      </Stack>
    </SkeletonWrap>
  );
}

export default FeedSkeleton;
