import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  COLORS,
  FONT_WEIGHT,
  ICON_SIZE,
  LINE_HEIGHT,
  RADIUS,
  TEXTSIZE,
  WIDTH,
} from "../../theme/constants";
import TextField from "@mui/material/TextField";
import { useBlocker, useNavigate } from "react-router-dom";
import { Avatar, Box, Button, InputAdornment, InputLabel, Typography } from "@mui/material";
import {
  getUserProfile,
  getPresignedUrl,
  // uploadPresigned,
  updateProfile,
} from "../../api";
import { getAnalytics, logEvent } from "firebase/analytics";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";
import { handleError } from "../../utils/commonFunction";
import { useDropzone } from "react-dropzone";
import CircularComponent from "../process/CircularComponent";
import ModalComponent from "../../components/modal/ModalComponent";
// icon and image
import { ReactComponent as EditIcon } from "../../assets/svgs/edit.svg";
import { ReactComponent as CameraIcon } from "../../assets/svgs/camera.svg";
import clearImage from "../../assets/images/clear.svg";
import { ReactComponent as ArrowLeft } from "../../assets/svgs/arrow_left.svg";
import {
  inputAdornmentMuiStyle,
  inputMuiStyle,
  labelMediumMuiStyle,
  textFieldMuiStyle,
  buttonLargeMuiStyle,
  textSmallMuiStyle,
} from "../../utils/commonStyle";
import { useAuth } from "../../context/Auth";
import useWindowDimensions from "../../hooks/useWindownDimensions";
import { useMediaQuery } from "react-responsive";
import ImageAvatar from "../../assets/images/avatar.png";
import { uploadPresigned } from "../../service/cloudflare_services";
import { useTranslation } from "react-i18next";
import BgDefaultMobile from "@images/bg_default_mobile.jpg";
import BgDefaultFold from "@images/bg_default_fold.jpg";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    // margin-left: 100px;
    max-width: 600px;
  }
`;

const WrapperHeader = styled.div`
  position: relative;
  z-index: 99;
  width: 100%;
  top: 0;
  margin-bottom: -56px;

  .header {
    padding: 1rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header__icon {
    fill: ${COLORS.white};
    cursor: pointer;
  }

  .header__text {
    font-size: ${TEXTSIZE.large};
    font-weight: ${FONT_WEIGHT.large};
    line-height: ${LINE_HEIGHT.superLarge};
    color: ${COLORS.white};
  }
`;

const WrapperContent = styled.div`
  margin-bottom: 6rem;

  .display-flex-row-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .background-image {
    position: relative;
    height: 220px;
    padding-bottom: 60px;
  }

  .background-image__first {
    background-color: ${COLORS.grey400};
    height: 100%;
    border-bottom-right-radius: ${RADIUS.superLarge};
  }

  .background-image__first__second {
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.7) 0.69%,
      rgba(0, 0, 0, 0) 40.28%,
      rgba(0, 0, 0, 0) 66.32%,
      rgba(0, 0, 0, 0.7) 100%
    );
    height: 100%;
    border-bottom-right-radius: ${RADIUS.superLarge};
  }

  .profile {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    height: 130px;
  }

  .profile__avatar {
    width: 100px;
    height: 100px;
  }

  .profile__icon {
    position: absolute;
    background-color: ${COLORS.white};
    border-radius: 50%;
    padding: 3px;
    height: ${ICON_SIZE.medium};
    width: ${ICON_SIZE.medium};
    box-shadow: 2px 2px 5px 0px ${COLORS.grey500};
    cursor: pointer;
    stroke: ${COLORS.grey500};
  }

  .profile__icon--camera {
    top: 5px;
    right: 15px;
  }

  .profile__icon--edit {
    bottom: 4px;
    right: 4px;
  }
`;

const imageType = {
  PROFILE: "profile_image_url",
  BACKGROUND: "bg_image_url",
};

function EditProfile() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const formItems = [
    {
      name: "nickname",
      label: t("name"),
      maxLength: 30,
      placeholder: "",
    },
    {
      name: "introduce",
      label: "사용자 소개",
      maxLength: 30,
      placeholder: "자기소개를 입력해주세요.",
      multiline: true,
      rows: 3,
    },
    {
      name: "comment",
      label: "프로필 인사말",
      maxLength: 100,
      placeholder: "프로필 인사말을 입력해주세요.",
      multiline: true,
      rows: 8,
    },
  ];

  const { height, width } = useWindowDimensions();
  const analytics = getAnalytics();
  const fileInputRef = useRef(null);
  const [clickType, setClickType] = useState(imageType.PROFILE);
  const [drawerData, setDrawerData] = useState({
    isRevert: false,
    isDelete: false,
  });
  const [previewProfile, setPreviewProfile] = useState("");
  const [previewBackground, setPreviewBackground] = useState("");
  const [originData, setOriginData] = useState({});
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    nickname: "",
    introduce: "",
    comment: "",
    profile_image_url: "",
    bg_image_url: "",
    out_links: ["", ""],
  });
  const [modalData, setModalData] = useState({
    open: false,
    btnOKText: "확인",
    btnCancelText: "취소",
    title: "저장하지 않고 나가시겠습니까?",
    // subTitle: (
    //   <Box>
    //     <Typography>저장하지 않고 나가시면 </Typography>
    //     <Typography>작업하신 내용이 저장되지 않습니다.</Typography>
    //   </Box>
    // ),
    content: (
      <Box>
        <Typography>저장하지 않고 나가시면 </Typography>
        <Typography>작업하신 내용이 저장되지 않습니다.</Typography>
      </Box>
    ),
  });

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const isMobileWithoutFold = useMediaQuery({
    query: `(max-width: ${WIDTH.fold})`,
  });

  const editSuccess = useRef(null);
  let blocker = useBlocker(() => checkIsEdited());

  const onDrop = (acceptedFiles) => {
    handleUploadImage(acceptedFiles);
    const previewUrl = URL.createObjectURL(acceptedFiles[0]);
    if (clickType === imageType.PROFILE) {
      setPreviewProfile(previewUrl);
    } else {
      setPreviewBackground(previewUrl);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const handleResponseErrors = (responseErr) => {
    setErrors({ ...errors, ...responseErr });
  };

  const handleChange = ({ name, value }) => {
    // const { name, value } = event.target;

    if (name === "nickname") {
      let errorMessage = "";
      if (value.length < 2) {
        errorMessage = "최소 2자 이상으로 작성해주세요.";
      }
      setErrors({ nickname: [errorMessage] });
    }

    let outLinks = [...values.out_links];
    if (name === "out_links_1") {
      outLinks[0] = value;
      setValues({
        ...values,
        out_links: outLinks,
      });
    } else if (name === "out_links_2") {
      outLinks[1] = value;
      setValues({
        ...values,
        out_links: outLinks,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      editSuccess.current = false;
      const requestData = { ...values };
      if (originData.profile_image_url === values.profile_image_url) {
        delete requestData.profile_image_url;
      }
      if (originData.bg_image_url === values.bg_image_url) {
        delete requestData.bg_image_url;
      }
      await updateProfile(requestData);
      editSuccess.current = true;
      navigate("/profile", { replace: true });
    } catch (error) {
      switch (error?.response?.data?.code) {
        case 400:
          handleResponseErrors({ nickname: [error?.response?.data?.message] });
          break;
        default:
          handleResponseErrors(error?.response?.data?.errors);
          break;
      }
      handleError(error, logout);
    }
  };

  const handleProfileDrawer = (type) => {
    setClickType(type);
    handleDrawer(true);
  };

  const handleRevertFile = () => {
    if (clickType == imageType.PROFILE) {
      setPreviewProfile(originData.profile_image_url);
    } else {
      setPreviewBackground(originData.bg_image_url);
    }
    setValues((prevState) => ({
      ...prevState,
      [clickType]: originData[clickType],
    }));
  };

  const handleDeleteFile = () => {
    if (clickType == imageType.PROFILE) {
      setPreviewProfile(null);
    } else {
      setPreviewBackground(null);
    }
    setValues((prevState) => ({ ...prevState, [clickType]: "" }));
  };

  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  const handleDrawerClick = (type) => {
    switch (type) {
      case "selectFile":
        fileInputRef.current.click();
        break;
      case "revertFile":
        handleRevertFile();
        break;
      case "deleteFile":
        handleDeleteFile();
        break;
      default:
        break;
    }
    handleDrawer(false);
  };

  const handleUploadImage = async (files) => {
    try {
      const filename = files[0].name;
      const contentType = files[0].type;
      const requestData = {
        list: [{ filename: filename, contentType: contentType }],
      };
      const res = await getPresignedUrl(requestData);
      const file_buffer = await files[0].arrayBuffer();

      await uploadPresigned(res.data.data[0].url, file_buffer, {
        Authorization: null,
        lang: "kr",
        "Content-Type": contentType,
      });

      // if (clickType === imageType.PROFILE) {
      //   handleChange({
      //     name: "profile_image_url",
      //     value: res.data.data[0].filename,
      //   });
      // } else {
      //   handleChange({
      //     name: "bg_image_url",
      //     value: res.data.data[0].filename,
      //   });
      // }
      handleChange({
        name: [clickType],
        value: res.data.data[0].filename,
      });
    } catch (error) {
      if (clickType === imageType.PROFILE) {
        setPreviewProfile("");
      } else {
        setPreviewBackground("");
      }
      handleError(error, logout);
    }
  };

  const handleGetUserProfile = async () => {
    try {
      setIsLoading(true);
      const res = await getUserProfile();
      if (!res) return;
      setOriginData(res?.user_profile);
      const newValues = { ...res?.user_profile };
      delete newValues.hashtag;
      setValues(newValues);
      setIsLoading(false);
    } catch (error) {
      handleError(error, logout);
    }
  };

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "editProfile",
      firebase_screen_class: "editProfile",
    });
    handleGetUserProfile();
  }, []);

  const handleEditBackground = () => {
    handleProfileDrawer(imageType.BACKGROUND);
    let isRevert = false;
    let isDelete = false;
    if (values.bg_image_url !== originData.bg_image_url) {
      isRevert = true;
    }

    if (values.bg_image_url) {
      isDelete = true;
    }
    setDrawerData({
      isRevert,
      isDelete,
    });
  };

  const handleEditProfile = () => {
    let isRevert = false;
    let isDelete = false;
    if (values.profile_image_url !== originData.profile_image_url) {
      isRevert = true;
    }

    if (values.profile_image_url) {
      isDelete = true;
    }
    setDrawerData({
      isRevert,
      isDelete,
    });
    handleProfileDrawer(imageType.PROFILE);
  };

  const checkIsEdited = () => {
    const keys = Object.keys(values);
    if (editSuccess.current) return false;
    return keys.some((key) => JSON.stringify(values[key]) !== JSON.stringify(originData[key]));
  };

  //modal action
  const handleOk = () => {
    blocker.proceed();
    setModalData({ ...modalData, open: false });
  };

  const handleCancel = () => {
    blocker.reset();
    setModalData({ ...modalData, open: false });
  };

  return (
    <Wrapper>
      <WrapperHeader>
        <Box className='header'>
          <ArrowLeft className='header__icon' onClick={() => navigate(-1)} />
          <Typography className='header__text'>프로필 편집</Typography>
          <Typography
            className='header__text'
            sx={{
              pointerEvents: values.nickname.length < 2 ? "none" : "auto",
              cursor: "pointer",
            }}
            onClick={() => handleSubmit()}
          >
            저장
          </Typography>
        </Box>
      </WrapperHeader>
      <WrapperContent className='editProfile'>
        <>
          <div {...getRootProps()}>
            <input {...getInputProps()} ref={fileInputRef} />
          </div>

          <Box className='background-image'>
            <Box
              className='background-image__first'
              style={{
                backgroundImage: `url(${
                  previewBackground ? previewBackground : values?.bg_image_url || (isMobileWithoutFold ? BgDefaultMobile : BgDefaultFold)
                })`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // backgroundSize: "contain",
              }}
              sx={{ position: "relative" }}
            >
              <Box className='background-image__first__second'></Box>
            </Box>
            <Box className='profile'>
              <CameraIcon
                className='profile__icon profile__icon--camera'
                onClick={handleEditBackground}
              />
              <Box position={"relative"}>
                <Avatar
                  className='profile__avatar'
                  // alt={nickname}
                  src={previewProfile ? previewProfile : values?.profile_image_url || ImageAvatar}
                />
                <EditIcon
                  className='profile__icon profile__icon--edit'
                  onClick={handleEditProfile}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ padding: "0 1rem" }}>
            {formItems.map(({ label, name, maxLength, placeholder, multiline, rows }) => (
              <Box sx={{ mb: "1.5rem" }} key={name}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // mb: "0.5rem",
                  }}
                >
                  <InputLabel sx={labelMediumMuiStyle}>{label}</InputLabel>
                  {maxLength && (
                    <Typography
                      sx={{
                        ...textSmallMuiStyle,
                        fontWeight: FONT_WEIGHT.medium,
                        lineHeight: LINE_HEIGHT.xLarge,
                      }}
                    >
                      {values[name]?.length || 0}/{maxLength}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <TextField
                    name={name}
                    value={values[name]}
                    multiline={multiline}
                    rows={rows}
                    helperText={errors?.[name]?.[0]}
                    FormHelperTextProps={{
                      style: {
                        color: COLORS.redError,
                      },
                    }}
                    onChange={(event) =>
                      handleChange({
                        name: event?.target?.name,
                        value: event?.target?.value,
                      })
                    }
                    placeholder={placeholder}
                    sx={{
                      width: "100%",
                      borderRadius: RADIUS.large,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: RADIUS.xxLarge,
                        backgroundColor: COLORS.grey50,
                        border: "none",
                      },
                    }}
                    inputProps={{
                      sx: {
                        ...inputMuiStyle,
                        borderRadius: multiline && 0,
                        px: multiline && 0,
                      },
                      maxLength: maxLength,
                    }}
                    InputProps={{
                      endAdornment: values[name]?.length > 0 && !multiline && (
                        <InputAdornment
                          position='end'
                          sx={inputAdornmentMuiStyle}
                          onClick={() => handleChange({ name, value: "" })}
                        >
                          <img src={clearImage} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            ))}
            <Box>
              <InputLabel sx={labelMediumMuiStyle}>{"링크"}</InputLabel>
              <TextField
                name={"out_links_1"}
                value={values.out_links[0]}
                onChange={(event) =>
                  handleChange({
                    name: "out_links_1",
                    value: event?.target?.value,
                  })
                }
                placeholder={"링크를 추가하세요."}
                sx={{ ...textFieldMuiStyle, marginBottom: "0.5rem" }}
                inputProps={{
                  sx: inputMuiStyle,
                }}
              />
              <TextField
                name={"out_links_2"}
                value={values.out_links[1]}
                onChange={(event) =>
                  handleChange({
                    name: "out_links_2",
                    value: event?.target?.value,
                  })
                }
                placeholder={"링크를 추가하세요."}
                sx={textFieldMuiStyle}
                inputProps={{
                  sx: inputMuiStyle,
                }}
              />
            </Box>
          </Box>
          {drawerOpen && (
            <DrawerPortal>
              <DrawerFrame
                setOnDrawer={() => handleDrawer(false)}
                type='profile-upload'
                callBack={handleDrawerClick}
                data={drawerData}
              />
            </DrawerPortal>
          )}

          <ModalComponent
            open={blocker.state === "blocked"}
            modalData={modalData}
            handleClose={() => handleCancel()}
            handleOk={() => handleOk()}
            handleCancel={() => handleCancel()}
            btnOKProps={{}}
            btnCancelProps={{}}
          />
        </>
      </WrapperContent>
      <Box
        sx={{
          padding: "0 1rem",
          zIndex: 10,
          // width: `${isPc ? 826 : width}px`,
          position: "sticky",
          bottom: 10,
        }}
      >
        <Button
          sx={{ ...buttonLargeMuiStyle }}
          disabled={values.nickname.length < 2}
          variant='contained'
          size='large'
          onClick={handleSubmit}
        >
          {t("button.next")}
        </Button>
      </Box>
    </Wrapper>
  );
}

export default EditProfile;
