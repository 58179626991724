import React from "react";
import { COLORS, WIDTH } from "../theme/constants";
import styled from "styled-components";
import FloatingButtonNew from "../containers/quiz/FloatingButtonNew";
import QuizList from "../containers/quiz/QuizList";
import { Typography } from "@mui/material";
import { color } from "framer-motion";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

function Quizzes(props) {
  return (
    <>
      <FloatingButtonNew />
      <Wrapper id='page-content'>
        <Typography
          sx={{
            py: 2,
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: "24px",
            color: COLORS.black1,
            whiteSpace: "pre-line",
            borderBottom: `1px solid ${COLORS?.grey50}`
          }}
        >{`퀴즈풀고,\nPIC과 상품을 받아가세요.`}</Typography>
        <QuizList />
      </Wrapper>
    </>
  );
}

export default Quizzes;
