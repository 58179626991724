import React, { useState, useContext, useEffect } from "react";
import { Link, useMatch, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Alarm } from "@icons/alarm.svg";
import { ReactComponent as Message } from "@icons/message.svg";
import { ReactComponent as LOGODARK } from "@icons/logo-dark.svg";
import { ReactComponent as LOGOLIGHT } from "@icons/logo-light.svg";
import {
  TEXTSIZE,
  WIDTH,
  COLORS,
  HEADER_RIGHT_TYPE,
  FONT_WEIGHT,
  LINE_HEIGHT,
} from "../../../theme/constants";
import DrawerFrame from "../../../containers/drawers/DrawerFrame";
import DrawerPortal from "../../../containers/drawers/DrawerPortal";
import { ThemeContext } from "../../../context/themeProvider";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as SearchIcon } from "@icons/search.svg";
import { ReactComponent as ArrowLeft } from "@icons/arrow_left.svg";
import { ReactComponent as Setting } from "@icons/setting.svg";
import { ReactComponent as More } from "@icons/more.svg";
import { Badge, Box, Typography } from "@mui/material";
import { handleError } from "@utils/commonFunction";
import { getUserNotifications } from "@api";
import { useAuth } from "../../../context/Auth";
import { textLargeMuiStyle } from "@utils/commonStyle";
import { useTranslation } from "react-i18next";
import { ReactComponent as LogoIcon } from "@icons/logo-light.svg";
import { GlobalStateContext } from "../../../context/GlobalStateProvider";

const Header = styled.header`
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 1rem;
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .icon {
    cursor: pointer;
    &:hover {
      path {
        fill: ${COLORS.primary6};
      }
    }
  }

  .icon-message {
    cursor: pointer;
    &:hover {
      path {
        stroke: ${COLORS.primary6};
      }
    }
  }
`;

const Icon = styled.div`
  cursor: pointer;

  &:hover {
    svg {
      :not(.message-icon) {
        fill: var(--mainColor);
      }

      &.message-icon {
        stroke: var(--mainColor);
      }
    }
  }
`;

function CommonHeader(props) {
  const navigate = useNavigate();
  const params = useParams();
  const homeMatch = useMatch("/");
  const { logout } = useAuth();
  const { searchUrl = "/search/feed" } = props;
  const { t } = useTranslation();
  const { backgroundHomeType } = useContext(GlobalStateContext);

  const { leftType = "", centerType = "", rightType = "", titleStyle = {}, pageTitle = "", background = "white" } = props;
  const { theme } = useContext(ThemeContext);
  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [drawer, setDrawer] = useState({
    open: false,
    type: "",
  });

  const [totalUnread, setTotalUnread] = useState(0);

  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSetting = () => {
    // handleDrawer(true);
    setDrawer({
      open: true,
      type: "setting",
    });
  };

  const handleOtherUser = () => {
    // handleDrawer(true);
    setDrawer({
      open: true,
      type: "otherUser",
    });
  };

  const handleMessage = () => {
    // handleDrawer(true);
    setDrawer({
      open: true,
      type: "appDown",
    });
  };

  const setOnDrawer = () => {
    setDrawer({
      open: false,
    });
  };

  const onClickLogo = () => {
    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getNotifications = async () => {
    try {
      const requestParam = {
        limit: 1,
        offset: 0,
      };
      const res = await getUserNotifications(requestParam);
      if (!res?.pagination) return;
      // console.log("res?.pagination", res?.pagination);
      setTotalUnread(res?.pagination?.totalUnread);
    } catch (error) {
      handleError(error, logout);
    }
  };

  useEffect(() => {
    handleDrawer(drawer.open);
  }, [drawer]);

  useEffect(() => {
    if (homeMatch?.pathname !== "/") return;
    getNotifications();
  }, []);

  const renderLeft = (key) => {
    switch (key) {
      case "logo":
        return (
          !isPc && (
            <Box onClick={onClickLogo} sx={{ cursor: "pointer" }}>
              {theme === "dark" ? <LOGODARK /> : <LOGOLIGHT />}
            </Box>
          )
        );
      case "arrow":
        return (
          <ArrowLeft
            fill={rightType === HEADER_RIGHT_TYPE.SETTING ? COLORS.white : COLORS.grey600}
            style={{ cursor: "pointer" }}
            onClick={handleGoBack}
          />
        );
      default:
        break;
    }
  };

  const renderCenter = (key) => {
    switch (key) {
      case "title":
        return (
          <Typography
            sx={{
              fontSize: TEXTSIZE.large,
              fontWeight: FONT_WEIGHT.xxLarge,
              lineHeight: LINE_HEIGHT.superLarge,
              color: COLORS.black1,
              ...titleStyle,
            }}
          >
            {pageTitle ? t(pageTitle) : params.pageName ? params.pageName : params.nickname}
          </Typography>
        );

      default:
        break;
    }
  };

  const renderRight = (key) => {
    switch (key) {
      case "message":
        return (
          <Icons>
            <Badge
              onClick={() => navigate("/notifications")}
              badgeContent={totalUnread}
              overlap='circular'
              sx={{
                mr: isPc ? 1 : 0,
                ".MuiBadge-badge": {
                  backgroundColor: COLORS.primary4,
                  color: COLORS.white,
                  fontSize: "9px",
                  lineHeight: "12px",
                  minWidth: "16px",
                  px: "2px",
                  height: "12px",
                },
              }}
              invisible={totalUnread === 0}
            >
              <Alarm width='24' height='24' fill={COLORS.grey500} className='icon' />
            </Badge>
            {!isPc && (
              <Message
                width='24'
                height='24'
                stroke={COLORS.grey500}
                onClick={() => navigate("/chat")}
                className='icon-message'
              />
            )}
          </Icons>
        );
      case "search":
        return (
          <Icons>
            <Link to={searchUrl}>
              <SearchIcon
                fill={
                  // searchUrl === "/explore/user" &&
                  COLORS.grey500
                }
              />
            </Link>
          </Icons>
        );
      case "setting":
        return (
          <Icons>
            <Icon>
              <Setting width='24' height='24' fill={COLORS.white} onClick={handleSetting} />
            </Icon>
          </Icons>
        );
      case "more":
        return (
          <Icons>
            <Icon>
              <More width='24' height='24' fill={COLORS.white} onClick={handleOtherUser} />
            </Icon>
          </Icons>
        );
      case "skip":
        return (
          <Link to={"/"}>
            <Typography
              sx={{
                ...textLargeMuiStyle,
                color: COLORS.grey600,
              }}
            >
              건너뛰기
            </Typography>
          </Link>
        );

      default:
        return <div></div>;
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {isPc && (
          <Box
            sx={{
              flex: "0 1 300px",
              display: "flex",
              alignItems: "center",
              pl: "40px",
              cursor: "pointer"
            }}
            onClick={onClickLogo}
          >
            <LogoIcon width='200px' />
          </Box>
        )}
        <Box sx={{ flex: "1 1 auto", height: "fit-content", background: background }}>
          <Header>
            {renderLeft(leftType)}
            {renderCenter(centerType)}
            {renderRight(rightType)}
            {drawer.open && (
              <DrawerPortal>
                <DrawerFrame type={drawer.type} setOnDrawer={setOnDrawer} />
              </DrawerPortal>
            )}
          </Header>
        </Box>
      </Box>
    </>
  );
}

export default React.memo(CommonHeader);
