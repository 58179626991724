import { useEffect, useRef, useState } from "react";
import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk";
import styled from "styled-components";
import { COLORS, RADIUS, TEXTSIZE } from "../../theme/constants";
import { v4 as uuid } from "uuid";

const Wrapper = styled.div`
  .title {
    font-weight: 700;
    font-size: ${TEXTSIZE.xLarge};
    padding-left: 20px;
  }

  .sub-text {
    color: ${COLORS.mainColor};
    font-weight: 700;
    margin: 10px auto;
    padding-left: 20px;
  }

  .buttons {
    width: 100%;
    margin-top: 12px;
  }

  .button-submit {
    width: 100%;
    border-radius: ${RADIUS.medium};
    font-weight: 700;
    cursor: pointer;
  }

  .button-close {
    background-color: var(--bglightColor);
    border-radius: ${RADIUS.medium};
    font-weight: 700;
    width: 40%;
    cursor: pointer;
  }
`;

export default function Checkout({ data, closeModal }) {
  const { amount, pic } = data;
  const paymentWidgetRef = useRef(null);
  const paymentMethodsWidgetRef = useRef(null);
  const clientKey = process.env.REACT_APP_TOSS_CLIENT_ID;
  const customerKey = JSON.parse(
    window.localStorage.getItem("user")
  ).provider_id;

  useEffect(() => {
    (async () => {
      const paymentWidget = await loadPaymentWidget(clientKey, customerKey);

      const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
        "#payment-widget",
        amount
      );

      paymentWidgetRef.current = paymentWidget;
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
    })();
  }, []);

  const handlePayment = async () => {
    const paymentWidget = paymentWidgetRef.current;
    const user = JSON.parse(window.localStorage.getItem("user"));
    await paymentWidget
      ?.requestPayment({
        orderId: uuid(),
        orderName: pic + "PIC",
        customerName: user.name,
        customerEmail: user.email,
        successUrl: `${window.location.origin}/paymentSuccess`,
        failUrl: `${window.location.origin}/paymentFail`,
      })
      .catch((err) => {
        console.log(err);
      });

    // const { IMP } = window;
    // IMP.init(process.env.REACT_APP_PORTONE_CODE);
    // IMP.request_pay(
    //   {
    //     // param
    //     pg: "kcp.{상점ID}",
    //     pay_method: "card",
    //     merchant_uid: "ORD20180131-0000011",
    //     name: "노르웨이 회전 의자",
    //     amount: 64900,
    //     buyer_email: "gildong@gmail.com",
    //     buyer_name: "홍길동",
    //     buyer_tel: "010-4242-4242",
    //     buyer_addr: "서울특별시 강남구 신사동",
    //     buyer_postcode: "01181",
    //   },
    //   (res) => {
    //     // callback
    //     if (res.success) {
    //       alert("결제 성공");
    //     } else {
    //       alert(`결제 실패: ${res.error_msg}`);
    //     }
    //   }
    // );
  };

  return (
    <Wrapper>
      <h1 className='title'>결제하기</h1>
      <p className='sub-text'>
        {pic} pic/{amount} 원
      </p>
      <div id='payment-widget' />
      <div className='buttons'>
        <button className='button-close' onClick={closeModal}>
          취소하기
        </button>
        <button className='button-submit' onClick={handlePayment}>
          결제하기
        </button>
      </div>
    </Wrapper>
  );
}
