import {
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { COLORS, EXPIRES_IN, HEIGHT, RADIUS, TEXTSIZE } from "../../theme/constants";
import styled from "styled-components";
import { codeRequest, codeVerify } from "../../api";
import Countdown from "react-countdown";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../components/modal/ModalComponent";
import {
  textMediumMuiStyle,
  textSmallMuiStyle,
  labelMediumMuiStyle,
  textFieldMuiStyle,
  inputMuiStyle,
  inputAdornmentMuiStyle,
  buttonLargeMuiStyle,
  textLargeMuiStyle,
} from "../../utils/commonStyle";
import { validateEmail } from "../../utils/commonFunction";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  position: relative;
  height: calc(calc(var(--vh, 1vh) * 100) - ${HEIGHT.header_mo} - ${HEIGHT.navigation});
  width: 100%;
`;

function SignUpWithEmail(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { login } = useAuth();
  const [requestData, setRequestData] = useState({});
  const [errors, setErrors] = useState({});
  const [isCountDown, setIsCountDown] = useState(false);
  const [isDisabledResend, setIsDisabledResend] = useState(true);

  const [modalData, setModalData] = useState({
    open: false,
    btnOKText: t("button.check"),
    btnCancelText: "",
    title: t("dialog.title.notifications"),
    subTitle: "",
    content: (
      <Box sx={{ color: COLORS.grey900, fontSize: TEXTSIZE.medium }}>
        <Typography
          sx={{
            whiteSpace: "pre-line",
          }}
        >
          {t("dialog.content.verification_code_has_been_sent")}
        </Typography>
      </Box>
    ),
  });

  // const validateEmail = (email) => {
  //   const emailRegex = new RegExp(
  //     /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
  //     "gm"
  //   );
  //   return emailRegex.test(email);
  // };

  const handleResponseErrors = (responseErr) => {
    setErrors({ ...errors, ...responseErr });
  };

  const handleChange = (key, value) => {
    let errorMessage = [];

    if (key === "email") {
      setIsCountDown(false);
      setIsDisabledResend(true);
    }

    if (key === "email" && !validateEmail(value) && value) {
      errorMessage = [t("invalid_email_format")];
    }

    if (key === "code" && !value) {
      errorMessage = [t("please_enter_the_verification_code")];
    }

    setErrors({ ...errors, [key]: errorMessage });
    setRequestData({ ...requestData, [key]: value });
  };

  const handleCodeRequest = async () => {
    setIsCountDown(false);
    try {
      await codeRequest({
        email: requestData?.email,
        expires_in: EXPIRES_IN,
      });
      setIsCountDown(true);
      setIsDisabledResend(true);
      setModalData({ ...modalData, open: true });
    } catch (error) {
      // console.log("handleCodeRequest error", error?.response?.data?.code);
      switch (error?.response?.data?.code) {
        case 400:
          handleResponseErrors({ email: [error?.response?.data?.message] });
          break;
        default:
          handleResponseErrors(error?.response?.data?.errors);

          break;
      }
    }
  };

  const handleResendCode = () => {
    // setModalAlarmData({
    //   ...modalAlarmData,
    //   content: (
    //     <Box>
    //       <Typography>확인 코드가 유효하지 않습니다.</Typography>
    //     </Box>
    //   ),
    // });
  };

  const handleCodeVerify = async () => {
    try {
      const res = await codeVerify({
        email: requestData?.email,
        code: requestData?.code,
      });
      if (res) {
        // const responseData = {
        //   meta: { access_token: { token: res?.data?.data?.token } },
        //   user: { email: requestData?.email },
        // };
        // login(responseData);
        const verifyUser = {
          email: requestData?.email,
          verify_token: res?.data?.data?.token,
        };
        localStorage.setItem("verifyUser", JSON.stringify(verifyUser));
        navigate("/signup/set-password");
      }
    } catch (error) {
      console.log("handleCodeVerify error", errors);
      handleResponseErrors(error?.response?.data?.errors);
    }
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
  };

  const rendererCountdown = ({ hours, minutes, seconds, completed }) => {
    if (!completed) {
      return (
        <Typography
          sx={{
            color: COLORS.primary4,
            fontSize: TEXTSIZE.small,
            marginBottom: "-4px",
          }}
        >{`${minutes}:${seconds}`}</Typography>
      );
    }
  };

  return (
    <>
      <Wrapper>
        <Box sx={{ pt: 7 }}>
          <InputLabel sx={labelMediumMuiStyle}>{t("id_email")}</InputLabel>
          <TextField
            onChange={(event) => handleChange("email", event.target.value)}
            placeholder={t("please_enter_your_email_address")}
            sx={textFieldMuiStyle}
            inputProps={{
              sx: inputMuiStyle,
            }}
            InputProps={{
              endAdornment: requestData?.email && (
                <InputAdornment position='end'>
                  <Button
                    sx={{
                      cursor: "pointer",
                      backgroundColor: errors?.email?.[0] ? COLORS.redError : COLORS.primary4,
                      color: COLORS.white,
                      borderRadius: RADIUS.large,
                      width: "72px",
                      py: 0,
                      zIndex: 1,
                      "&:hover": {
                        backgroundColor: errors?.email?.[0] ? COLORS.redError : COLORS.primary4,
                      },
                      "&.Mui-disabled": {
                        backgroundColor: errors?.email?.[0] ? COLORS.redError : COLORS.primary4,
                        color: COLORS.white,
                      },
                    }}
                    onClick={handleCodeRequest}
                    disabled={!!errors?.email?.[0]}
                  >
                    {t("not_available")}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText
            sx={{
              ...textMediumMuiStyle,
              mt: 1,
              color: COLORS.redError,
              fontSize: TEXTSIZE.small,
              visibility: errors?.email?.[0] ? "visible" : "hidden",
            }}
          >
            {errors?.email?.[0] || "error"}
          </FormHelperText>
        </Box>
        <Box>
          <InputLabel sx={{ color: COLORS.black1, fontSize: TEXTSIZE.medium }}>
            {/* 인증코드 */}
            {t("authentication_code")}
          </InputLabel>
          <TextField
            autoComplete='one-time-code'
            onChange={(event) => handleChange("code", event.target.value)}
            placeholder={t("please_enter_the_verification_code")}
            sx={textFieldMuiStyle}
            inputProps={{
              sx: inputMuiStyle,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' sx={{ zIndex: 1 }}>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 1,
                      }}
                    >
                      {isCountDown && (
                        <Countdown
                          onComplete={() => setIsDisabledResend(false)}
                          date={Date.now() + 180000}
                          renderer={rendererCountdown}
                        />
                      )}
                    </Box>
                    <Button
                      disabled={isDisabledResend}
                      sx={{
                        backgroundColor: COLORS.primary4,
                        color: COLORS.white,
                        borderRadius: RADIUS.large,
                        width: "72px",
                        py: 0,
                        "&:hover": {
                          backgroundColor: COLORS.primary4,
                          color: COLORS.white,
                        },
                        "&.Mui-disabled": {
                          backgroundColor: COLORS.grey100,
                          color: COLORS.grey600,
                        },
                      }}
                      onClick={() => handleCodeRequest()}
                    >
                      {/* 재발송 */}
                      {t("button.resend")}
                    </Button>
                  </Box>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText
            sx={{
              ...textMediumMuiStyle,
              mt: 1,
              color: COLORS.redError,
              fontSize: TEXTSIZE.small,
              visibility: errors?.code?.[0] ? "visible" : "hidden",
            }}
          >
            {errors?.code?.[0] || "error"}
          </FormHelperText>
        </Box>
        <Button
          sx={{
            ...buttonLargeMuiStyle,
            position: "absolute",
            bottom: "0px",
          }}
          disabled={
            !requestData?.email || !requestData?.code || errors?.email?.[0] || errors?.code?.[0]
          }
          variant='contained'
          size='large'
          className='submit'
          onClick={handleCodeVerify}
        >
          {/* 인증하기 */}
          {t("button.verify")}
        </Button>

        <ModalComponent
          open={modalData.open}
          modalData={modalData}
          handleClose={handleClose}
          handleOk={handleClose}
          handleCancel={handleClose}
        />
      </Wrapper>
    </>
  );
}

export default SignUpWithEmail;
