import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { animateScroll } from "react-scroll";
import ItemConnect from "../connects/ItemConnect";
import { isMine } from "../../utils/commonFunction";

function SearchUserList(props) {
  const { data, fetchMoreData, fetchData } = props;

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMoreData}
      hasMore={true}
      // scrollableTarget="scrollableContent"
    >
      <div>
        {[...data].map((item, index) => (
          <ItemConnect
            {...item}
            fetchData={fetchData}
            key={item.id + index}
            listItemStyle={{ maxWidth: "100%" }}
            isConnect={isMine(item?.id) ? true : item?.is_connect}
          />
        ))}
      </div>
    </InfiniteScroll>
  );
}

export default SearchUserList;
