import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { useNavigate } from "react-router-dom";
import { getPostDetail } from "@api";
import { COLORS, TEXTSIZE, RADIUS, WIDTH, LINE_HEIGHT } from "@theme/constants";
import styled from "styled-components";
import { handleError } from "@utils/commonFunction";
import useModalReducer, { MODAL_STATUS } from "@hooks/useModalReducer";
import ModalComponent from "@components/modal/ModalComponent";
import { textSmallMuiStyle, textMediumMuiStyle, textLargeMuiStyle } from "@utils/commonStyle";
import HeaderEditCategories from "@components/header/HeaderEditCategories";
import { ReactComponent as RightArrow } from "@icons/arrow_right.svg";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import DrawerPortal from "../containers/drawers/DrawerPortal";
import DrawerFrame from "../containers/drawers/DrawerFrame";
import { useAuth } from "../context/Auth";
import PostCategory from "../containers/posts/PostCategory";
import { GlobalStateContext } from "../context/GlobalStateProvider";
import { ReactComponent as PlusIcon } from "@icons/plus.svg";
import ButtonUploadImage from "../containers/uploadCloudflare/ButtonUploadImage";
import { ReactComponent as ClearIcon } from "@icons/clear.svg";
import iconPlayWhite from "@images/icon_play_white.png";
import { styled as styledMui } from "@mui/material";
import { inputAdornmentMuiStyle, inputMuiStyle, textFieldMuiStyle } from "../utils/commonStyle";
import { NumericFormatCustom } from "../components/numberic/NumbericFormatCustom";
import { ReactComponent as PurchaseIcon } from "@icons/purchase_black.svg";
import { ReactComponent as LockedIcon } from "@icons/locked.svg";
import { ReactComponent as LimitIcon } from "@icons/limit_white.svg";
import { createPost, getPresignedUrl } from "../api";
import { uploadPresigned } from "../service/cloudflare_services";
import { LoadingButton } from "@mui/lab";
import IOSSwitch from "../components/switch/IOSSwitch";

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 632px;
  padding: 0 16px;
  .close-icon {
    display: none;
  }
  .preview-image:hover {
    .close-icon {
      display: block;
    }
  }

  .swiper-slide-thumb-active {
    border: 1px solid ${COLORS.primary4};
  }
`;

const LIMIT_LENGTH_TITLE = 100;
const LIMIT_LENGTH_CONTENTS = 500;

// const IOSSwitch = styledMui((props) => (
//   <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
// ))(({ theme }) => ({
//   width: 42,
//   height: 24,
//   padding: 0,
//   "& .MuiSwitch-switchBase": {
//     padding: 0,
//     margin: 3,
//     transitionDuration: "300ms",
//     "&.Mui-checked": {
//       transform: "translateX(16px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : COLORS.primary4,
//         opacity: 1,
//         border: 0,
//       },
//       "&.Mui-disabled + .MuiSwitch-track": {
//         opacity: 0.5,
//       },
//     },
//     "&.Mui-focusVisible .MuiSwitch-thumb": {
//       color: "#33cf4d",
//       border: "6px solid #fff",
//     },
//     "&.Mui-disabled .MuiSwitch-thumb": {
//       color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
//     },
//     "&.Mui-disabled + .MuiSwitch-track": {
//       opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxSizing: "border-box",
//     width: 18,
//     height: 18,
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 24 / 2,
//     backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
//     opacity: 1,
//     transition: theme.transitions.create(["background-color"], {
//       duration: 500,
//     }),
//   },
// }));

const PlayIcon = (props) => {
  const { smallSize } = props;
  return (
    <Box
      sx={{
        color: "white",
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bottom: 0,
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          backgroundColor: "rgba(0,0,0,0.6)",
          borderRadius: "50%",
          width: smallSize ? "32px" : "48px",
          height: smallSize ? "32px" : "48px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={iconPlayWhite}
          style={{
            width: smallSize ? "18px" : "24px",
            height: smallSize ? "18px" : "24px",
          }}
        />
      </Box>
    </Box>
  );
};

const Locked = (props) => {
  const { smallSize } = props;
  return (
    <Box
      sx={{
        color: "white",
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bottom: 0,
        zIndex: 1,
        borderRadius: RADIUS.medium,
        border: `2px solid ${COLORS.primary4}`,
        background: "rgba(255, 255, 255, 0.6)",
      }}
    >
      <LockedIcon />
    </Box>
  );
};

const Title = ({ text, count }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 0.5,
      }}
    >
      <Typography
        sx={{
          ...textLargeMuiStyle,
          lineHeight: "20px",
        }}
      >
        {text}
      </Typography>
      {count && (
        <Typography
          sx={{
            color: COLORS.grey400,
            fontSize: TEXTSIZE.small,
          }}
        >
          {count}
        </Typography>
      )}
    </Box>
  );
};

const SubTitle = ({ text }) => {
  return (
    <Typography
      sx={{
        ...textSmallMuiStyle,
        lineHeight: "18px",
        fontWeight: "400",
        whiteSpace: "pre-line",
        mb: 1,
      }}
    >
      {text}
    </Typography>
  );
};

const TitleWithNumber = ({ number, text, count }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "6px",
        mb: 0.5,
      }}
    >
      <Box
        sx={{
          background: COLORS.grey700,
          color: COLORS.white,
          width: "16px",
          height: "16px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          margin: "2px",
        }}
      >
        {number}
      </Box>
      <Title text={text} count={count} />
    </Box>
  );
};

const LIMIT_FILES = 10;

function PostCreateAndEdit(props) {
  const { mode = "create" } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { modalData, dispatchModal } = useModalReducer();
  const [post, setPost] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isUnderstandFreePost, setIsUnderstandFreePost] = useState(false);
  const [requestData, setRequestData] = useState({
    title: "",
    contents: "",
    hashtag: "",
    cost: "",
    limit: "",
    type: 0,
    is_shareable: 0,
    is_payable: 1,
    is_view_buyer: 1, // 0: private | 1: public
    categoryIds: [],
    files: [],
  });
  const buttonRef = useRef(null);

  const { selectedPost } = useContext(GlobalStateContext);

  // edit category
  const [openCategory, setOpenCategory] = useState(false);
  const [categorySelected, setCategorySelected] = useState([]);

  // Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("editCost");

  //paid post
  const [isPaidPost, setIsPaidPost] = useState(false);

  // upload image
  const [fileUploaded, setFileUploaded] = useState([]);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // screen
  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const handleGetDetail = async () => {
    try {
      const res = await getPostDetail(selectedPost?.id);
      if (!res?.can_edit) {
        navigate(`/post/detail/${selectedPost?.id}`, {
          replace: true,
        });
      }
      setPost(res);
      setRequestData({
        title: res?.title,
        contents: res?.contents,
      });
      setCategorySelected(res?.categories);
    } catch (error) {
      const errorMessage = handleError(error, logout, navigate);
      console.log("errorMessage", errorMessage);
    }
  };

  const removeIsLocked = (postFiles) => {
    if (postFiles?.length == 0) return [];
    const previewTmp = structuredClone(postFiles);
    const newPreviewTmp = [];
    previewTmp.forEach((item) => {
      // const index = previewTmp.findIndex((element) => element?.id === item);
      item.is_locked = 0;
      newPreviewTmp.push(item);
    });
    return newPreviewTmp;
  };

  const handleEditPost = async (updateData) => {
    // default: 1
    // limited + cost > 0: 2
    // quiz: 3
    const getPostType = (cost, limited) => {
      if (cost > 0 && limited > 0) {
        return 2;
      }
      return 1;
    };

    try {
      let requestBody = {
        post: {
          ...requestData,
          hashtag: "",
          is_shareable: 0, // not used but in quiz is 1
          is_payable: requestData?.cost > 0 ? 1 : 0,
          type: getPostType(requestData?.cost, requestData?.limited),
          is_view_buyer: 1,
          categoryIds: categorySelected.length > 0 ? categorySelected.map((item) => item.id) : [],
          cost: requestData?.cost || 0,
        },
        ...updateData,
      };

      if (!isPaidPost) {
        requestBody = {
          post: {
            ...requestBody?.post,
            cost: 0,
            limited: 0,
            type: 1,
          },
          files: removeIsLocked(requestBody.files),
        };
      }

      await createPost(requestBody);
      navigate("/", { state: { createdPost: requestBody } });
    } catch (error) {
      const errorResponse = handleError(error, logout, navigate);
      handleOpenModalError(errorResponse?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (key, value) => {
    setRequestData({ ...requestData, [key]: value });
  };

  //Modal begin
  const handleOpenModalError = (content) => {
    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        type: "error",
        btnOKText: "확인",
        btnCancelText: "",
        title: "해당 게시물은 수정할 수 없어요",
        subTitle: "",
        content: (
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey900,
              lineHeight: LINE_HEIGHT.xxxLarge,
              whiteSpace: "pre-line",
            }}
          >
            {content}
          </Typography>
        ),
      },
    });
  };

  const handleOpenModalConfirmUploadFreePost = () => {
    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        type: "free_post",
        btnOKText: "확인",
        btnCancelText: "취소",
        title: "콘텐츠를 업로드하겠습니까?",
        subTitle: "",
        content: (
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey900,
              lineHeight: LINE_HEIGHT.xxxLarge,
              whiteSpace: "pre-line",
            }}
          >
            {`해당 콘텐츠는 무료로 제공합니다.\n판매를 원하실 경우,  유료콘텐츠 설정해주세요.`}
          </Typography>
        ),
      },
    });
  };

  const handleOk = () => {
    buttonRef.current.disabled = false;
    dispatchModal({ type: MODAL_STATUS.CLOSE });
  };

  const handleCancel = () => {
    dispatchModal({ type: MODAL_STATUS.CLOSE });
    switch (modalData.type) {
      case "free_post":
        handleUploadMedia(previewFiles);
        break;

      default:
        break;
    }
  };
  //Modal end

  // Start edit category
  const handleClickOpenCategory = () => {
    setOpenCategory(true);
  };

  const handleCloseCategory = () => {
    // setCategoryIds([])
    setOpenCategory(false);
  };

  const handleOkCategory = (newCategories) => {
    setOpenCategory(false);
    setCategorySelected(newCategories);
  };
  // End edit category

  //Drawer begin
  const onEditCost = () => {
    setDrawerType("editCost");
    handleDrawer(true);
  };

  const onEditLimited = () => {
    setDrawerType("editLimited");
    handleDrawer(true);
  };

  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  const handleDrawerClick = (value) => {
    if (drawerType === "editCost") {
      if (post?.type != 2) {
        handleDrawer(false);
        handleEditPost({
          cost: value,
        });
      } else {
        handleChange("cost", value);
        onEditLimited();
      }
    } else if (drawerType === "editLimited") {
      handleEditPost({
        limited: value,
      });
    }
  };
  //Drawer end

  // UPLOAD begin

  const handleSetPreview = (newFiles) => {
    setPreviewFiles((prevState) => [...prevState, ...newFiles]);
  };

  const removeImage = (id) => {
    const previewTmp = structuredClone(previewFiles);
    const fileUploadedTmp = structuredClone(fileUploaded);

    const foundPreview = previewTmp.filter((element) => element?.id !== id);
    const foundFileUploaded = fileUploadedTmp.filter((element) => element?.id !== id);

    setPreviewFiles(foundPreview);
    setFileUploaded(foundFileUploaded);
  };

  const onLockImage = (id) => {
    const previewTmp = structuredClone(previewFiles);
    const index = previewTmp.findIndex((element) => element?.id === id);
    if (index === 0) return;
    previewTmp[index].is_locked = previewTmp[index].is_locked == 1 ? 0 : 1;
    setPreviewFiles(previewTmp);
  };

  const handleGetPresignedUrl = async (requestData) => {
    try {
      const res = await getPresignedUrl(requestData);
      return res?.data?.data;
    } catch (error) {
      const errorMessage = handleError(error, logout, navigate);
      console.log("errorMessage", errorMessage);
    }
  };

  const getRequestFiles = async (element, index, fileLinks, fileThumbnailLinks) => {
    const file_buffer = await element?.file?.arrayBuffer();
    await uploadPresigned(fileLinks[index].url, file_buffer, {
      Authorization: null,
      lang: "kr",
      "Content-Type": element.type,
    });

    if (element?.thumbnail) {
      const file_thumbnail_buffer = await element?.thumbnail?.arrayBuffer();
      await uploadPresigned(fileThumbnailLinks[index].url, file_thumbnail_buffer, {
        Authorization: null,
        lang: "kr",
        "Content-Type": element.type,
      });
    }

    const result = {
      ...element,
      url: fileLinks[index].filename,
      view_url: element?.thumbnail ? fileThumbnailLinks[index].filename : "",
      thumbnail_url: element?.thumbnail ? fileThumbnailLinks[index].filename : "",
    };

    delete result.id;
    delete result.name;
    delete result.link;
    delete result.file;
    delete result.thumbnail;

    return result;
    // upload success but need post file to store and then get ulr
  };

  const handleUploadMedia = async (files) => {
    try {
      setIsLoading(true);
      // setPreviewFiles([]);
      const requestData = {
        list: [],
      };
      const requestThumbnailData = {
        list: [],
      };
      // const requestFilesChatSDK = [];
      files.forEach((element) => {
        const objFile = {
          filename: element?.file?.name,
          contentType: element?.file?.type,
        };
        const objThumbnail = {
          filename: element?.thumbnail?.name,
          contentType: "image/png",
        };
        requestData.list.push(objFile);
        requestThumbnailData.list.push(objThumbnail);
      });
      const [fileLinks, fileThumbnailLinks] = await Promise.all([
        handleGetPresignedUrl(requestData),
        handleGetPresignedUrl(requestThumbnailData),
      ]);

      const requestFiles = await Promise.all(
        files.map((element, index) =>
          getRequestFiles(element, index, fileLinks, fileThumbnailLinks)
        )
      );
      // handleChange("files", requestFiles);
      await handleEditPost({ files: requestFiles });
    } catch (error) {
      setIsLoading(false);
      const errorMessage = handleError(error, logout, navigate);
      console.log("errorMessage", errorMessage);
    }
  };
  // UPLOAD end

  const onHandleNext = async () => {
    if (buttonRef.current && !buttonRef.current.disabled) {
      buttonRef.current.disabled = true;
      // Perform the action
      // After the action is complete, reset buttonRef.current.disabled to false
    }
    if (isUnderstandFreePost || isPaidPost) {
      await handleUploadMedia(previewFiles);
    } else {
      setIsUnderstandFreePost(true);
      handleOpenModalConfirmUploadFreePost();
    }
  };

  const onHandlePaidPostDefault = (currentIsPaidPost) => {
    if (!currentIsPaidPost) return;
    handleChange("limited", "");
    handleChange("cost", "");

    if (previewFiles?.length == 0) return;
    const previewTmp = structuredClone(previewFiles);
    const newPreviewTmp = [];
    previewTmp.forEach((item) => {
      // const index = previewTmp.findIndex((element) => element?.id === item);
      item.is_locked = 0;
      newPreviewTmp.push(item);
    });
    setPreviewFiles(newPreviewTmp);
  };

  const isDisabledSubmit = () => {
    const previewTmp = structuredClone(previewFiles);
    const isLocked = previewTmp.some((element) => element?.is_locked == 1);

    if (!requestData?.contents) {
      return true;
    }
    if (previewFiles?.length == 0) {
      return true;
    }
    if (categorySelected?.length == 0) {
      return true;
    }
    if (isPaidPost && (Number(requestData?.cost) <= 0 || !isLocked)) {
      return true;
    }
    return false;
  };

  return (
    <Wrapper>
      <Backdrop open={isLoading} sx={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      <>
        <Box
          sx={{
            fontSize: TEXTSIZE.medium,
            color: COLORS.black1,
            pb: 1,
          }}
        >
          {/* preview full width */}
          <Box sx={{ mb: "40px" }}>
            <Swiper
              spaceBetween={10}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
            >
              {previewFiles?.map(({ id, link, type }) => (
                <SwiperSlide
                  key={id}
                  style={{
                    borderRadius: RADIUS.medium,
                    aspectRatio: 1,
                  }}
                >
                  <Box
                    className='preview-image'
                    sx={{
                      width: "100%",
                      height: "100%",
                      backgroundImage: `url("${link}")`,
                      borderRadius: RADIUS.medium,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      // backgroundPosition: "center"
                    }}
                  ></Box>
                  {type === "video" && <PlayIcon />}
                </SwiperSlide>
              ))}
              {previewFiles?.length !== LIMIT_FILES && (
                <SwiperSlide
                  style={{
                    borderRadius: RADIUS.medium,
                    aspectRatio: 1,
                  }}
                >
                  <ButtonUploadImage
                    handleSetPreview={handleSetPreview}
                    remainLimit={LIMIT_FILES - previewFiles?.length}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        p: "18px",
                        cursor: "pointer",
                        backgroundColor: COLORS.grey50,
                        borderRadius: RADIUS.medium,
                      }}
                    >
                      <PlusIcon fill={COLORS.grey300} />
                    </Box>
                  </ButtonUploadImage>
                </SwiperSlide>
              )}
            </Swiper>
          </Box>

          {/* preview thumbs */}
          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "20px",
                color: COLORS.black1,
                mb: 1,
              }}
            >
              미리보기
            </Typography>
            <Swiper
              spaceBetween={8}
              // width={60}
              // height={60}
              onSwiper={setThumbsSwiper}
              slidesPerView={5}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
            >
              {previewFiles?.map(({ id, link, type }) => (
                <SwiperSlide
                  key={id}
                  style={{
                    borderRadius: RADIUS.medium,
                    aspectRatio: 1,
                    cursor: "pointer",
                  }}
                >
                  <Box
                    className='preview-image'
                    sx={{
                      width: "100%",
                      height: "100%",
                      backgroundImage: `url("${link}")`,
                      borderRadius: RADIUS.medium,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      // backgroundPosition: "center"
                    }}
                  >
                    <ClearIcon
                      className='close-icon'
                      style={{
                        position: "absolute",
                        top: -2,
                        right: -2,
                        cursor: "pointer",
                        zIndex: 2,
                      }}
                      onClick={() => removeImage(id)}
                    />
                    {type === "video" && <PlayIcon smallSize />}
                  </Box>
                </SwiperSlide>
              ))}
              {previewFiles?.length !== LIMIT_FILES && (
                <SwiperSlide
                  style={{
                    borderRadius: RADIUS.medium,
                    aspectRatio: 1,
                    border: "none",
                  }}
                >
                  <ButtonUploadImage
                    handleSetPreview={handleSetPreview}
                    remainLimit={LIMIT_FILES - previewFiles?.length}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        p: "18px",
                        cursor: "pointer",
                        backgroundColor: COLORS.grey50,
                        borderRadius: RADIUS.medium,
                      }}
                    >
                      <PlusIcon fill={COLORS.grey300} />
                    </Box>
                  </ButtonUploadImage>
                </SwiperSlide>
              )}
            </Swiper>
          </Box>

          <Box>
            <Box sx={{ my: 2.5 }}>
              <Title text={"제목"} count={`${requestData?.title?.length}/${LIMIT_LENGTH_TITLE}`} />
              <TextField
                name='title'
                value={requestData.title}
                onChange={(event) => handleChange("title", event.target.value)}
                placeholder={t("please_enter_the_subject")}
                sx={{
                  width: "100%",
                  height: "48px",
                  borderRadius: RADIUS.large,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: RADIUS.xxLarge,
                    backgroundColor: COLORS.grey50,
                    border: "none",
                  },
                }}
                inputProps={{
                  style: {
                    p: 1,
                    zIndex: 1,
                    color: COLORS.black1,
                    fontSize: TEXTSIZE.medium,
                  },
                  sx: {
                    "&::placeholder": {},
                  },
                  maxLength: LIMIT_LENGTH_TITLE,
                }}
              />
            </Box>
            <Box sx={{ my: 2.5 }}>
              <Title
                text={"내용"}
                count={`${requestData?.contents?.length}/${LIMIT_LENGTH_CONTENTS}`}
              />
              <TextField
                name='contents'
                type='contents'
                multiline
                rows={8}
                value={requestData.contents}
                onChange={(event) => handleChange("contents", event.target.value)}
                placeholder='내용을 입력해 주세요.'
                sx={{
                  width: "100%",
                  // height: "48px",
                  borderRadius: RADIUS.large,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: RADIUS.xxLarge,
                    backgroundColor: COLORS.grey50,
                    border: "none",
                  },
                }}
                inputProps={{
                  style: {
                    p: 1,
                    zIndex: 1,
                    color: COLORS.black1,
                    fontSize: TEXTSIZE.medium,
                  },
                  sx: {
                    "&::placeholder": {},
                  },
                  maxLength: LIMIT_LENGTH_CONTENTS,
                }}
              />
            </Box>
            {/* Start category */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                my: 2.5,
              }}
            >
              <Box>
                <Title text={"카테고리"} />
                <SubTitle text={"콘텐츠의 카테고리를 설정해 주세요!"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleClickOpenCategory}
              >
                {categorySelected.length > 0 && (
                  <Typography
                    component={"span"}
                    sx={{
                      ...textMediumMuiStyle,
                      lineHeight: "22px",
                      color: COLORS.primary4,
                    }}
                  >
                    {categorySelected[0].name}
                    {categorySelected.length > 1 && `+ ${categorySelected.length - 1}`}
                  </Typography>
                )}
                <RightArrow fill={COLORS.grey600} />
              </Box>
            </Box>
            {/* End category */}

            {/* Start paid content */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                my: 2.5,
              }}
            >
              {/* head */}
              <Box>
                <Title text={"유료콘텐츠 설정"} />
                <SubTitle text={"콘텐츠를 유료로 판매하려면 활성화해 주세요!"} />
              </Box>
              <IOSSwitch
                value={isPaidPost}
                onChange={() => {
                  // onHandlePaidPostDefault(!isPaidPost);
                  setIsPaidPost(!isPaidPost);
                }}
              />
            </Box>
            {/* content */}
            {isPaidPost && (
              <>
                <Box
                  sx={{
                    my: 2.5,
                  }}
                >
                  <TitleWithNumber text={"잠금 설정"} number={"1"} />
                  <Box sx={{ pl: "24px" }}>
                    <SubTitle text={"유료화할 이미지 또는 영상을 선택해 주세요!"} />
                    {previewFiles?.length <= 0 && (
                      <Box
                        sx={{
                          width: "25%",
                          aspectRatio: 1,
                          // height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          p: "18px",
                          backgroundColor: COLORS.grey50,
                          borderRadius: RADIUS.medium,
                        }}
                      />
                    )}
                    <Swiper
                      spaceBetween={8}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                    >
                      {previewFiles?.map(({ id, link, type, is_locked }) => (
                        <SwiperSlide
                          key={id}
                          style={{
                            borderRadius: RADIUS.medium,
                            aspectRatio: 1,
                            cursor: "pointer",
                          }}
                          onClick={() => onLockImage(id)}
                        >
                          <Box
                            className='preview-image'
                            sx={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url("${link}")`,
                              borderRadius: RADIUS.medium,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              // backgroundPosition: "center"
                            }}
                          >
                            {type === "video" && <PlayIcon smallSize />}
                            {is_locked === 1 && <Locked />}
                          </Box>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Box>
                </Box>
                <Box
                  sx={{
                    my: 2.5,
                  }}
                >
                  <TitleWithNumber text={"판매가격"} number={"2"} />
                  <Box sx={{ pl: "24px" }}>
                    <SubTitle
                      text={
                        "유료콘텐츠는 업로드 이후, 픽을 받은 순간부터는 수정이 불가능해요. 이미지와 문구를 다시 확인해 주세요!"
                      }
                    />
                    <TextField
                      name='cost'
                      value={requestData?.cost}
                      placeholder='판매가격 입력'
                      onChange={(event) => handleChange("cost", event.target.value)}
                      sx={{
                        ...textFieldMuiStyle,
                        "& .MuiInputBase-root": {
                          height: "48px",
                          paddingLeft: 0.75,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          backgroundColor: COLORS.grey50,
                          border: "none",
                          borderRadius: "8px",
                        },
                      }}
                      inputProps={{
                        sx: {
                          ...inputMuiStyle,
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "20px",
                          color: COLORS.black1,
                          paddingLeft: 1,
                        },
                        min: 0,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='end' sx={{ ...inputAdornmentMuiStyle, mr: 1 }}>
                            <PurchaseIcon width={"24px"} height={"24px"} />
                          </InputAdornment>
                        ),
                        inputComponent: NumericFormatCustom,
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    my: 2.5,
                  }}
                >
                  <TitleWithNumber text={"판매수량"} number={"3"} />
                  <Box sx={{ pl: "24px" }}>
                    <SubTitle
                      text={`판매할 콘텐츠의 수량을 정하려면 선택해 주세요!\n수량을 제한하지 않을 경우, 무제한으로 판매됩니다!`}
                    />
                    <TextField
                      placeholder='판매수량 입력'
                      value={requestData?.limited}
                      onChange={(event) => handleChange("limited", event.target.value)}
                      sx={{
                        ...textFieldMuiStyle,
                        "& .MuiInputBase-root": {
                          height: "48px",
                          paddingLeft: 0.75,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          backgroundColor: COLORS.grey50,
                          border: "none",
                          borderRadius: "8px",
                        },
                      }}
                      inputProps={{
                        sx: {
                          ...inputMuiStyle,
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "20px",
                          color: COLORS.black1,
                          paddingLeft: 1,
                        },
                        min: 0,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='end' sx={{ ...inputAdornmentMuiStyle, mr: 1 }}>
                            <LimitIcon />
                          </InputAdornment>
                        ),
                        inputComponent: NumericFormatCustom,
                      }}
                    />
                  </Box>
                </Box>
              </>
            )}
            {/* End paid content */}
          </Box>
        </Box>
        <Box sx={{ position: "sticky", bottom: 0, zIndex: 1 }}>
          <LoadingButton
            ref={buttonRef}
            loading={isLoading}
            sx={{
              my: 2,
              p: 2,
              // position: "absolute",
              // bottom: "0px",
              borderRadius: RADIUS.xxxLarge,
              width: "100%",
              backgroundColor: COLORS.primary4,
              color: COLORS.white,
              boxShadow: "none",
              "&:hover": {
                backgroundColor: COLORS.primary4,
                boxShadow: "none",
              },
              "&.Mui-disabled": {
                backgroundColor: COLORS.grey100,
                color: COLORS.grey600,
              },
            }}
            disabled={isDisabledSubmit()}
            variant='contained'
            size='large'
            className='submit'
            onClick={onHandleNext}
          >
            다음
          </LoadingButton>
        </Box>
      </>

      <Dialog open={openCategory} onClose={handleCloseCategory} fullScreen={!isPc}>
        <DialogContent>
          <HeaderEditCategories onClickLeft={handleCloseCategory} title={"카테고리 선택"} />
          <PostCategory
            // setCategoryIds={setCategoryIds}
            categorySelected={categorySelected}
            handleSubmit={handleOkCategory}
          />
        </DialogContent>
      </Dialog>

      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame
            setOnDrawer={() => handleDrawer(false)}
            handleModal={handleDrawerClick}
            type={drawerType}
            data={post}
            // refetch={handleDrawerRefetch}
            callBack={() => navigate(-1)}
          />
        </DrawerPortal>
      )}
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={handleCancel}
        handleOk={handleOk}
        handleCancel={handleCancel}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
    </Wrapper>
  );
}

export default PostCreateAndEdit;
