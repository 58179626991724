import { Backdrop, Box, CircularProgress, List } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useAuth } from "../../context/Auth";
import { getBlockUser } from "../../api";
import InfiniteScroll from "react-infinite-scroll-component";
import ItemBlockAccount from "./ItemBlockAccount";
import NoData from "../../components/NoData";
import CircularComponent from "../process/CircularComponent";
import { handleError } from "../../utils/commonFunction";
import { WIDTH } from "../../theme/constants";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 236px);
  position: relative;
`;

function BlockAccount(props) {
  const { logout } = useAuth();
  const { refreshPage } = props;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMoreData = async (newRequestParam) => {
    try {
      if (data.length == 0) {
        setIsLoading(true);
      }
      const requestParam = {
        limit: 15,
        offset: data.length,
        ...newRequestParam,
      };
      const res = await getBlockUser(requestParam);
      setIsLoading(false);
      if (!res?.list) return;
      if (newRequestParam?.offset === 0) {
        setData(res.list);
      } else {
        setData((prevState) => [...prevState, ...res.list]);
      }
    } catch (error) {
      handleError(error, logout);
    }
  };

  const fetchData = () => {
    // setData(() => []);
    fetchMoreData({ offset: 0 });
    refreshPage();
  };

  useEffect(() => {
    fetchMoreData({ offset: 0 });
  }, []);

  // if (isLoading)
  //   return (
  //     <HeightWhenEmpty>
  //       <CircularComponent />
  //     </HeightWhenEmpty>
  //   );

  return (
    <>
      <Backdrop open={isLoading} sx={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>

      <Wrapper>
        <Box>
          {data.length > 0 ? (
            <InfiniteScroll
              dataLength={data.length}
              next={fetchMoreData}
              hasMore={true}
              // id="scrollableContent"
            >
              <List
                sx={{
                  width: "100%",
                  // height: "calc(100vh - 80px)",
                  bgcolor: "background.paper",
                }}
              >
                {data.map((user) => (
                  <ItemBlockAccount {...user} fetchData={fetchData} key={user.user_id} />
                ))}
              </List>
            </InfiniteScroll>
          ) : (
            <HeightWhenEmpty>
              <NoData text="차단한 계정이 없습니다." />
            </HeightWhenEmpty>
          )}
        </Box>
      </Wrapper>
    </>
  );
}

export default BlockAccount;
