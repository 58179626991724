import React from "react";
import styled from "styled-components";
import { COLORS, RADIUS, TEXTSIZE } from "../../theme/constants";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const Wrapper = styled.div`
  padding-top: 60px;

  .step__name {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 60px;
  }

  .step__title {
    font-size: ${TEXTSIZE.xxLarge};
    font-weight: 700;
    margin-bottom: 12px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormGroup-root {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 21px;
    width: 100%;
  }

  .MuiButtonBase-root {
    display: none;
  }

  .MuiFormControlLabel-root {
    margin: 0;
    flex: 1 0 auto;
  }

  .MuiTypography-root {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    background: ${COLORS.grey50};
    border-radius: ${RADIUS.medium};
    color: ${COLORS.grey600};
  }

  .Mui-checked + .MuiTypography-root {
    background: ${COLORS.primary4};
    color: ${COLORS.white};
  }

  .radio__item {
    font-size: ${TEXTSIZE.large};
    font-weight: 700;
  }

  section {
    margin-bottom: 60px;
  }
`;

function Step1(props) {
  const { data, handleChange } = props;
  
  const handleValue = (event) => {
    const { name, value } = event.target;

    let newData = { [name]: value };
    if (name === "national") {
      newData = { [name]: value, user_type: "" };
    }

    handleChange(newData);
  };

  return (
    <Wrapper>
      <h2 className="step__name">이용 동의</h2>
      <FormControl>
        <section>
          <p className="step__title">거주 국가 선택</p>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="national"
            onChange={handleValue}
            value={data.national}
          >
            <FormControlLabel
              value="local"
              control={<Radio />}
              label={<div className="radio__item">대한민국</div>}
            />
            <FormControlLabel
              value="foreigner"
              control={<Radio />}
              label={<div className="radio__item">대한민국 이외</div>}
            />
          </RadioGroup>
        </section>
        <section>
          <p className="step__title">회원 유형 선택</p>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="user_type"
            value={data.user_type}
            onChange={handleValue}
          >
            <FormControlLabel
              value="personal_local"
              control={<Radio disabled={data.national !== "local"} />}
              label={<div className="radio__item">개인 (한국인)</div>}
            />
            <FormControlLabel
              value="foreigner_local"
              control={<Radio disabled={data.national !== "local"} />}
              label={<div className="radio__item">외국인(대한민국 거주)</div>}
            />
            <FormControlLabel
              value="personal_business"
              control={<Radio />}
              label={<div className="radio__item">개인사업자</div>}
            />
            <FormControlLabel
              value="corporaterodl"
              control={<Radio />}
              label={<div className="radio__item">법인사업자</div>}
            />
          </RadioGroup>
        </section>
      </FormControl>
    </Wrapper>
  );
}

export default React.memo(Step1);
