import React from "react";
import styled from "styled-components";
import { COLORS, TEXTSIZE } from "../theme/constants";
import { ReactComponent as Explore } from "../assets/svgs/explore.svg";
import { ReactComponent as Replay } from "../assets/svgs/replay.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const IconWrap = styled.div``;

const Text = styled.p`
  color: ${COLORS.gray_7};
  font-size: ${TEXTSIZE.xSmall};
  margin-top: 4px;
  white-space: pre-line;
  text-align: center;
`;

function NoData({ text = "게시물이 없습니다.", type = "nodata" }) {
  // type: nodata/noconnect
  return (
    <Wrapper className='nodata'>
      <IconWrap>
        {type === "nodata" ? (
          <Explore fill={COLORS.gray_7} stroke={COLORS.gray_7} />
        ) : (
          <Replay fill={COLORS.gray_7} stroke={COLORS.gray_7} />
        )}
      </IconWrap>
      {type === "noconnect" ? (
        <Text>화면을 불러올 수 없습니다.</Text>
      ) : (
        text && <Text>{text}</Text>
      )}
    </Wrapper>
  );
}

export default React.memo(NoData);
