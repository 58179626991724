import React from "react";
import styled from "styled-components";
import {
  COLORS,
  FONT_WEIGHT,
  LINE_HEIGHT,
  TEXTSIZE,
  WIDTH,
} from "../../theme/constants";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../assets/svgs/arrow_left.svg";
import { ReactComponent as MoreIcon } from "../../assets/svgs/more_vertical.svg";
import { ReactComponent as PlusCircleIcon } from "../../assets/svgs/plus_circle.svg";
import useWindowDimensions from "../../hooks/useWindownDimensions";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const WrapperHeader = styled.div`
  position: sticky;
  background-color: ${COLORS.white};
  z-index: 99;
  width: 100%;
  top: 0;

  .header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header__icon {
    fill: ${COLORS.grey600};
    cursor: pointer;
  }

  .header__text {
    font-size: ${TEXTSIZE.large};
    font-weight: ${FONT_WEIGHT.xxLarge};
    line-height: ${LINE_HEIGHT.superLarge};
    color: ${COLORS.black1};
  }

  .icon_right {
    cursor: pointer;
    circle {
      stroke: ${COLORS.grey600};
    }
    path {
      stroke: ${COLORS.grey600};
    }
  }
`;

const ICON_TYPE = {
  PLUS: "plus",
  MORE: "more",
};

function NewChatHeader(props) {
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const { onClickRight } = props;
  const { t } = useTranslation();

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });
  return (
    <>
      <WrapperHeader>
        <Box
          className="header"
          sx={{
            width: `${isPc ? 826 : width}px`,
          }}
        >
          <ArrowLeft className="header__icon" onClick={() => navigate(-1)} />
          <Typography className="header__text">{t("send_message")}</Typography>
          <Box onClick={onClickRight}>
            {/* <MoreIcon
              className="icon_right"
              // onClick={() => handleMore()}
            /> */}
            {/* <PlusCircleIcon className="icon_right"/> */}
          </Box>
        </Box>
      </WrapperHeader>
      {/* <Box sx={{ height: "56px" }} /> */}
    </>
  );
}

export default NewChatHeader;
