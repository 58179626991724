import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Box, CircularProgress, ImageList, ImageListItem, Typography } from "@mui/material";
import { truncateStyle, textMediumMuiStyle } from "@utils/commonStyle";
import { COLORS, FONT_WEIGHT, LINE_HEIGHT, STATUS, TEXTSIZE } from "@theme/constants";
import { getFileTypeByName, setBaseFileUrl } from "@utils/commonFunction";
import { dateTimeFormat } from "@utils/dateTimeFormat";
import { ReactComponent as IconPlay } from "@icons/play.svg";
import useModalReducer from "@hooks/useModalReducer";
import { MODAL_STATUS } from "@hooks/useModalReducer";
import ModalComponent from "../../components/modal/ModalComponent";
import ReactPlayer from "react-player";

export const TYPE_MESSAGE_CHAT = {
  MESSAGE: "message",
  FILE: "file",
  STICKER: "sticker",
  ERROR: "error",
};

const Message = ({
  hasAfter = false,
  isSameTimeMinute = false,
  isSameTimeDay = false,
  typePosition = "right",
  avatarURL = "",
  content = "",
  createdTimestamp,
  attachments = [],
  type = TYPE_MESSAGE_CHAT.MESSAGE,
  status = "STATUS.SUCCESS",
}) => {
  const { modalData, dispatchModal } = useModalReducer();

  const getStatus = () => {
    switch (status) {
      case STATUS.LOADING:
        return (
          // <Box>
          //   <CircularProgress size={28} />
          // </Box>
          <Typography
            sx={{
              fontSize: TEXTSIZE.small,
              fontWeight: FONT_WEIGHT.medium,
              lineHeight: LINE_HEIGHT.xLarge,
              color: COLORS.grey400,
              visibility: !hasAfter || !isSameTimeMinute ? "visible" : "hidden",
            }}
          >
            {dateTimeFormat(createdTimestamp, "HH:mm")}
          </Typography>
        );
      case STATUS.ERROR:
        return (
          <Typography
            sx={{
              fontSize: TEXTSIZE.small,
              fontWeight: FONT_WEIGHT.medium,
              lineHeight: LINE_HEIGHT.xLarge,
              color: COLORS.redError,
            }}
          >
            {"message is not send"}
          </Typography>
        );
      case STATUS.SUCCESS:
        return (
          <Typography
            sx={{
              fontSize: TEXTSIZE.small,
              fontWeight: FONT_WEIGHT.medium,
              lineHeight: LINE_HEIGHT.xLarge,
              color: COLORS.grey400,
              visibility: !hasAfter || !isSameTimeMinute ? "visible" : "hidden",
            }}
          >
            {dateTimeFormat(createdTimestamp, "HH:mm")}
          </Typography>
        );

      default:
        return (
          <Typography
            sx={{
              fontSize: TEXTSIZE.small,
              fontWeight: FONT_WEIGHT.medium,
              lineHeight: LINE_HEIGHT.xLarge,
              color: COLORS.grey400,
              visibility: !hasAfter || !isSameTimeMinute ? "visible" : "hidden",
            }}
          >
            {dateTimeFormat(createdTimestamp, "HH:mm")}
          </Typography>
        );
    }
  };

  const renderMessageContent = (item) => {
    switch (getFileTypeByName(item?.name || item?.rawPath)) {
      case "image":
        return (
          <img
            src={status === STATUS.LOADING ? item.path : setBaseFileUrl(item.rawPath)}
            style={{ width: "100%", height: "100%" }}
          />
        );
      case "video":
        return (
          <Box>
            <img
              src={
                status === STATUS.LOADING
                  ? item?.thumbnailPath
                  : setBaseFileUrl(item?.meta?.thumbnail)
              }
              style={{ width: "100%", height: "100%" }}
            />
            <Box
              sx={{
                color: "white",
                position: "absolute",
                top: "calc(50% - 20px)",
                left: "calc(50% - 20px)",
                zIndex: 9,
                cursor: "pointer",
                backgroundColor: "rgba(255, 255, 255, 0.3)",
                backdropFilter: "blur(4px)",
                p: 1,
                borderRadius: "50%",
                height: "40px",
              }}
            >
              <IconPlay />
            </Box>
          </Box>
        );
      case "file":
        return (
          <Link to={setBaseFileUrl(item.rawPath)}>
            <Typography
              sx={{
                ...textMediumMuiStyle,
                ...truncateStyle,
                textDecoration: "underline",
              }}
            >
              {item?.name || item.originalName}
            </Typography>
          </Link>
        );

      default:
        break;
    }
  };

  // Begin modal

  const onClickMedia = (item) => {
    switch (getFileTypeByName(item?.name || item?.rawPath)) {
      case "image":
        dispatchModal({
          type: MODAL_STATUS.OPEN,
          data: {
            type: "previewImage",
            btnOKText: "",
            btnCancelText: "",
            title: "",
            subTitle: "",
            content: (
              <img src={setBaseFileUrl(item.rawPath)} style={{ width: "100%", height: "100%" }} />
            ),
          },
        });
        break;
      case "video":
        dispatchModal({
          type: MODAL_STATUS.OPEN,
          data: {
            type: "previewVideo",
            btnOKText: "",
            btnCancelText: "",
            title: "",
            subTitle: "",
            content: (
              <ReactPlayer
                controls={true}
                width='100%'
                height='100%'
                url={setBaseFileUrl(item.rawPath)}
                loop={true}
              />
            ),
          },
        });
        break;

      default:
        break;
    }
  };

  // End modal

  return (
    <>
      <ModalComponent
        isCloseIcon={true}
        open={modalData.open}
        modalData={modalData}
        handleClose={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleOk={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleCancel={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        btnOKProps={{}}
        btnCancelProps={{}}
        dialogProps={{
          sx: {
            padding: "20px",
            "& .MuiDialogContent-root": {
              py: 2,
              px: 2,
            },
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          p: 0,
          //   mt: !hasAfter ? "0.5rem" : "1.25rem",
          mb: !hasAfter ? "1.25rem" : "0.5rem",
          alignItems: "flex-end",
          flexDirection: typePosition === "left" ? "row" : "row-reverse",
        }}
      >
        {typePosition === "left" && (
          <Avatar
            alt=''
            src={avatarURL}
            sx={{
              width: "34px",
              height: "34px",
              visibility: !hasAfter ? "visible" : "hidden",
            }}
          />
        )}
        {type === TYPE_MESSAGE_CHAT.FILE && attachments?.length > 0 && (
          <Box sx={{ mx: 1, textAlign: typePosition, maxWidth: "50%" }}>
            {getFileTypeByName(attachments[0]?.name || attachments[0].rawPath) !== "file" ? (
              <Box>
                {attachments?.length <= 3 && (
                  <ImageList cols={attachments?.length} sx={{ overflow: "hidden" }}>
                    {attachments.map((item) => (
                      <ImageListItem key={item.id} onClick={() => onClickMedia(item)}>
                        {renderMessageContent(item)}
                      </ImageListItem>
                    ))}
                  </ImageList>
                )}
                {attachments?.length > 3 && (
                  <Box>
                    <ImageList cols={3} sx={{ overflow: "hidden" }}>
                      {attachments.slice(0, 3).map((item, index) => (
                        <ImageListItem key={item.id} onClick={() => onClickMedia(item)}>
                          {renderMessageContent(item)}
                        </ImageListItem>
                      ))}
                    </ImageList>

                    <ImageList
                      cols={attachments.slice(3, attachments.length).length}
                      sx={{ marginTop: "4px", overflow: "hidden" }}
                    >
                      {attachments.slice(3, attachments.length).map((item) => (
                        <ImageListItem key={item.id} onClick={() => onClickMedia(item)}>
                          {renderMessageContent(item)}
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Box>
                )}
              </Box>
            ) : (
              <Box>
                {attachments.map((item) => (
                  <Box style={{}} key={item.id}>
                    {renderMessageContent(item)}
                  </Box>
                ))}
              </Box>
            )}
            {content && (
              <Typography
                sx={{
                  ...textMediumMuiStyle,
                  width: "fit-content",
                  // maxWidth: "calc(100% - 40px)",
                  cursor: "pointer",
                  backgroundColor: typePosition === "left" ? COLORS.primary4 : COLORS.grey50,
                  borderRadius:
                    typePosition === "left" ? "0.5rem 1rem 1rem 0" : "1rem 0.5rem 0rem 1rem",
                  color: typePosition === "left" ? COLORS.white : COLORS.black1,
                  padding: "0.5rem 0.75rem",
                  ml: typePosition === "left" ? 0 : "auto",
                  mr: typePosition === "left" ? "auto" : "0",
                }}
              >
                {content}
              </Typography>
            )}
          </Box>
        )}
        {type === TYPE_MESSAGE_CHAT.MESSAGE && (
          <Typography
            sx={{
              ...textMediumMuiStyle,
              width: "fit-content",
              // maxWidth: "calc(100% - 40px)",
              cursor: "pointer",
              backgroundColor: typePosition === "left" ? COLORS.primary4 : COLORS.grey50,
              borderRadius:
                typePosition === "left" ? "0.5rem 1rem 1rem 0" : "1rem 0.5rem 0rem 1rem",
              color: typePosition === "left" ? COLORS.white : COLORS.black1,
              padding: "0.5rem 0.75rem",
              mx: 1,
            }}
          >
            {content}
          </Typography>
        )}
        {getStatus()}
      </Box>
      {!isSameTimeDay && (
        <Typography
          sx={{
            fontSize: TEXTSIZE.small,
            fontWeight: FONT_WEIGHT.medium,
            lineHeight: LINE_HEIGHT.xLarge,
            color: COLORS.grey400,
            textAlign: "center",
            my: 1,
          }}
        >
          {dateTimeFormat(createdTimestamp, "YYYY:MM:DD: HH:mm")}
        </Typography>
      )}
    </>
  );
};

export default React.memo(Message);
