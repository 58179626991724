import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useEffect, createContext, useState, useContext, useRef } from "react";
import { setOneVh } from "./utils/setOnVh";
import SnackBar from "./components/SnackBar";
import { GlobalStyle } from "./theme/GlobalStyle";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import DrawerPortal from "./containers/drawers/DrawerPortal";
import DrawerFrame from "./containers/drawers/DrawerFrame";
// import { fetchToken } from "./service/firebase";
// import "./utils/fcm";
import { ThemeContext } from "./context/themeProvider";
import { isMobile } from "react-device-detect";
import ModalPortal from "./components/modal/ModalPortal";
import ModalFrame from "./components/modal/ModalFrame";
// import "./utils/pg_init";
import { useMediaQuery } from "react-responsive";
import { WIDTH } from "./theme/constants";

//언어를 ko en ja zh에 따라서 변환하기 위해서 필요
import { useTranslation } from "react-i18next";
import i18n from "./location/index";
import useFirebaseInit from "./hooks/useFirebaseInit";
import useSocketListener from "./hooks/chat/useSocketListener";

function App() {
  useSocketListener();
  const { handleGetToken } = useFirebaseInit();
  const [openSnackBar, setSnackBarOpen] = useState(false);
  const [SnackBarTransition, setSnackBarTransition] = useState(undefined);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { theme } = useContext(ThemeContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [popupData, setPopupData] = useState({
    title: "",
    text: "",
  });

  const { t } = useTranslation();

  useEffect(() => {
    // console.log("navigator.language",navigator.language)
    // 처음키자마자 설정된 언어에 따라서 언어를 바까준다.
    // if (/^en-US\b/.test(navigator.language)) {
    //   i18n.changeLanguage("en_US");
    // } else if (/^ko\b/.test(navigator.language)) {
    //   i18n.changeLanguage("ko");
    // } else if (/^ja\b/.test(navigator.language)) {
    //   i18n.changeLanguage("ja");
    // } else if (/^zh\b/.test(navigator.language)) {
    //   i18n.changeLanguage("zh");
    // } else {
    //   console.log("ko language")
    //   i18n.changeLanguage("en_US");
    // }

    setOneVh();
    window.addEventListener("resize", setOneVh);

    // const timer = setInterval(() => {
    //   !drawerOpen && handleDrawer(true);
    // }, process.env.REACT_APP_DOWNLOAD_POPUP_TIME);

    // return () => {
    //   clearInterval(timer);
    // };

    handleGetToken();
  }, []);

  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Helmet>
        {/* <title>PICAVERSE</title> */}
        {/* i18n다국어 사용방법 */}
        <title>{t("PICAVERSE")}</title>
        <meta name="description" content="Picaverse helmet" />
      </Helmet>
      <GlobalStyle />
      <SnackBarContext.Provider
        value={{
          openSnackBar,
          setSnackBarOpen,
          SnackBarTransition,
          setSnackBarTransition,
        }}
      >
        <SnackBar />
      </SnackBarContext.Provider>
      <ToastContainer
        // autoClose={false}
        autoClose={2000}
        hideProgressBar={true}
        style={{
          zIndex: 2000,
          // width: "calc(100% - 32px)",
          maxWidth: "360px",
          minWidth: "280px",
          height: "48px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
        position="bottom-center"
        theme={theme}
      />
      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame type="appDown" setOnDrawer={() => handleDrawer(false)} />
        </DrawerPortal>
      )}
      {modalOpen && (
        <ModalPortal>
          <ModalFrame modalType="alert" data={popupData} setOnModal={setModalOpen}></ModalFrame>
        </ModalPortal>
      )}
    </Router>
  );
}

export const SnackBarContext = createContext();
export default App;
