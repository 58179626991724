import React from "react";
import styled from "styled-components";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { COLORS, TEXTSIZE } from "../../theme/constants";
import { ReactComponent as CloseIcon } from "../../assets/svgs/close.svg";

const Wrapper = styled.div`
  .top--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text--title {
    font-weight: 700;
    font-size: ${TEXTSIZE.xxLarge};
  }

  .table {
    width: 100%;
    margin-top: 16px;
  }

  .table__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.header {
      background: var(--colorF3to3);
    }
  }

  .table__header,
  .table__body {
    display: flex;
    align-items: center;
    flex-basis: 33%;
    height: 40px;
    font-size: ${TEXTSIZE.medium};
    font-weight: 400;
    color: ${COLORS.gray_7};
    padding: 8px 12px;
  }

  .table__header {
    background: var(--colorF3to3);
  }

  .table__body {
    height: 48px;
    border-bottom: 1px solid var(--colorF3to3);
  }

  .pagination {
    margin: 24px auto 0;

    .MuiPagination-ul {
      justify-content: center;
    }
  }
`;

function picHistory({ closeModal }) {
  const data = [
    {
      id: 0,
      sendId: "issimplydummytextoftheprintidd",
      pic: 10000,
      date: "23.05.12",
    },
    {
      id: 1,
      sendId: "issimplydummytextoftheprintidd",
      pic: 10000,
      date: "23.05.12",
    },
    {
      id: 2,
      sendId: "issimplydummytextoftheprintidd",
      pic: 10000,
      date: "23.05.12",
    },
    {
      id: 3,
      sendId: "issimplydummytextoftheprintidd",
      pic: 10000,
      date: "23.05.12",
    },
  ];

  return (
    <Wrapper>
      <div className='top--header'>
        <p className='text--title'>받은 PIC 리스트</p>
        <button className='button--close button' onClick={() => closeModal()}>
          <CloseIcon fill={COLORS.gray_7} />
        </button>
      </div>
      <div className='table'>
        <div className='table__row header'>
          <div className='column--pic table__header'>PIC 개수</div>
          <div className='column--id table__header'>ID</div>
          <div className='column--date table__header'>날짜</div>
        </div>

        {data.map((item) => (
          <div className='table__row' key={item.id}>
            <div className='column--pic table__body'>{item.pic}개</div>
            <div className='column--id table__body'>{item.sendId}</div>
            <div className='column--date table__body'>{item.date}</div>
          </div>
        ))}
      </div>
      <Stack spacing={2} className='pagination'>
        <Pagination count={10} shape='rounded' />
      </Stack>
    </Wrapper>
  );
}

export default React.memo(picHistory);
