import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "@icons/close.svg";
import { COLORS, FONT_WEIGHT, LINE_HEIGHT, RADIUS } from "@theme/constants";
import { buttonMuiStyle, textLargeMuiStyle, textSmallMuiStyle } from "@utils/commonStyle";
import { useMediaQuery } from "react-responsive";
import { WIDTH } from "../../theme/constants";
import { useMatch } from "react-router-dom";

function ModalComponent(props) {
  const {
    open,
    modalData,
    handleClose,
    handleOk,
    handleCancel,
    btnOKProps,
    btnCancelProps,
    dialogProps = {},
    isCloseIcon = false,
  } = props;

  const joinMatch = useMatch("/join");

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  return (
    <>
      <Dialog
        sx={{
          "& .MuiPaper-rounded": {
            borderRadius: RADIUS.large,
            minWidth: "350px",
            ml: isPc && !joinMatch ? "325px" : "32px",
          },
          p: 2,
        }}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        open={open}
        {...dialogProps}
      >
        <DialogTitle
          sx={{
            ...textLargeMuiStyle,
            padding: "1.25rem 1.25rem 0.25rem 1.25rem",
            fontWeight: FONT_WEIGHT.xxLarge,
          }}
          id="dialog-title"
        >
          {modalData?.title}
        </DialogTitle>
        <Typography
          sx={{
            ...textSmallMuiStyle,
            // m: 0,
            px: "1.25rem",
            color: COLORS.grey600,
            lineHeight: LINE_HEIGHT.xLarge,
          }}
        >
          {modalData?.subTitle}
        </Typography>
        {isCloseIcon && (
          <IconButton
            onClick={handleClose}
            sx={{
              width: "fit-content",
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <DialogContent sx={{ py: 2, px: "1.25rem" }}>{modalData?.content}</DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {modalData.btnCancelText && (
              <Button
                sx={{
                  ...buttonMuiStyle,
                  mx: 0.5,
                  backgroundColor: COLORS.grey50,
                  color: COLORS.grey600,
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: COLORS.grey50,
                    boxShadow: "none",
                  },
                }}
                //   disabled={isdisabled}
                variant="contained"
                size="large"
                onClick={handleCancel}
                {...btnCancelProps}
              >
                {modalData.btnCancelText}
              </Button>
            )}
            {modalData.btnOKText && (
              <Button
                sx={{
                  ...buttonMuiStyle,
                  mx: 0.5,
                  backgroundColor: COLORS.primary4,
                  color: COLORS.white,
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: COLORS.primary4,
                    boxShadow: "none",
                  },
                }}
                //   disabled={isdisabled}
                variant="contained"
                size="large"
                onClick={handleOk}
                {...btnOKProps}
              >
                {modalData.btnOKText}
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ModalComponent;
