import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { useNavigate } from "react-router-dom";
import { getPostDetail } from "@api";
import { COLORS, TEXTSIZE, RADIUS, WIDTH, LINE_HEIGHT } from "@theme/constants";
import styled from "styled-components";
import { handleError } from "@utils/commonFunction";
import useModalReducer, { MODAL_STATUS } from "@hooks/useModalReducer";
import ModalComponent from "@components/modal/ModalComponent";
import { textSmallMuiStyle, textMediumMuiStyle, textLargeMuiStyle } from "@utils/commonStyle";
import HeaderEditCategories from "@components/header/HeaderEditCategories";
import { ReactComponent as RightArrow } from "@icons/arrow_right.svg";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import DrawerPortal from "../containers/drawers/DrawerPortal";
import DrawerFrame from "../containers/drawers/DrawerFrame";
import { useAuth } from "../context/Auth";
import PostCategory from "../containers/posts/PostCategory";
import { GlobalStateContext } from "../context/GlobalStateProvider";
import { ReactComponent as PlusIcon } from "@icons/plus.svg";
import ButtonUploadImage from "../containers/uploadCloudflare/ButtonUploadImage";
import { ReactComponent as ClearIcon } from "@icons/clear.svg";
import iconPlayWhite from "@images/icon_play_white.png";
import { styled as styledMui } from "@mui/material";
import { inputAdornmentMuiStyle, inputMuiStyle, textFieldMuiStyle } from "../utils/commonStyle";
import { NumericFormatCustom } from "../components/numberic/NumbericFormatCustom";
import { ReactComponent as PurchaseBlackIcon } from "@icons/purchase_black.svg";
import { ReactComponent as PurchasePurpleFilledIcon } from "@icons/purchase_purple_filled.svg";
import { ReactComponent as LockedIcon } from "@icons/locked.svg";
import { ReactComponent as LimitIcon } from "@icons/limit_white.svg";
import { ReactComponent as UserPlusIcon } from "@icons/user_plus.svg";
import { ReactComponent as InformationIcon } from "@icons/information.svg";
import { createPost, getPresignedUrl } from "../api";
import { uploadPresigned } from "../service/cloudflare_services";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// import RadioButton from "../components/Radio/RadioButton";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 632px;
  padding: 0 16px;
  .close-icon {
    display: none;
  }
  .preview-image:hover {
    .close-icon {
      display: block;
    }
  }

  .swiper-slide-thumb-active {
    border: 1px solid ${COLORS.primary4};
  }
`;

const buttonStyle = {
  // padding: "1rem",
  // width: "100%",
  // height: "100%",
  borderRadius: "40px",
  border: `1px solid ${COLORS.grey100}`,
  color: COLORS.grey700,
  fontSize: "14px",
  lineHeight: "20px",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: COLORS.primary4,
    color: COLORS.white,
  },
  "&.Mui-disabled": {
    backgroundColor: COLORS.grey100,
    color: COLORS.grey600,
  },
};

const textMediumStyle = {
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "18px",
  color: COLORS.black1,
};

const inputStyle = {
  ...textMediumStyle,
  borderRadius: "8px",
  padding: "12px 16px",
  backgroundColor: COLORS.grey50,
  height: "24px",
};

const textFieldStyle = {
  ...textMediumStyle,
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
};

const LIMIT_LENGTH_TITLE = 100;
const LIMIT_LENGTH_CONTENTS = 500;

const PlayIcon = (props) => {
  const { smallSize } = props;
  return (
    <Box
      sx={{
        color: "white",
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bottom: 0,
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          backgroundColor: "rgba(0,0,0,0.6)",
          borderRadius: "50%",
          width: smallSize ? "32px" : "48px",
          height: smallSize ? "32px" : "48px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={iconPlayWhite}
          style={{
            width: smallSize ? "18px" : "24px",
            height: smallSize ? "18px" : "24px",
          }}
        />
      </Box>
    </Box>
  );
};

const Locked = (props) => {
  const { smallSize } = props;
  return (
    <Box
      sx={{
        color: "white",
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bottom: 0,
        zIndex: 1,
        borderRadius: RADIUS.medium,
        border: `2px solid ${COLORS.primary4}`,
        background: "rgba(255, 255, 255, 0.6)",
      }}
    >
      <LockedIcon />
    </Box>
  );
};

const Title = ({ text, count, suffix = <></> }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          ...textLargeMuiStyle,
          lineHeight: "20px",
        }}
      >
        {text}
      </Typography>
      {count && (
        <Typography
          sx={{
            color: COLORS.grey400,
            fontSize: TEXTSIZE.small,
          }}
        >
          {count}
        </Typography>
      )}
      {suffix}
    </Box>
  );
};

const SubTitle = ({ text, isShowIcon = false }) => {
  return (
    <Box>
      <Typography
        component={"span"}
        sx={{
          ...textSmallMuiStyle,
          lineHeight: "18px",
          fontWeight: "400",
          whiteSpace: "pre-line",
        }}
      >
        {text}
      </Typography>
      {isShowIcon && <InformationIcon style={{ marginBottom: "-2px" }} />}
    </Box>
  );
};

const TitleWithNumber = ({ number, text, count }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "6px",
        mb: 0.5,
      }}
    >
      <Box
        sx={{
          background: COLORS.grey700,
          color: COLORS.white,
          width: "16px",
          height: "16px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          margin: "2px",
        }}
      >
        {number}
      </Box>
      <Title text={text} count={count} />
    </Box>
  );
};

const FormInput = (props) => {
  const { name, placeholder, errors, register, maxLength, startAdornment } = props;
  return (
    <>
      <TextField
        fullWidth
        name={name}
        placeholder={placeholder}
        sx={{
          ...textFieldStyle,
          borderRadius: "8px",
          backgroundColor: COLORS.grey50,
        }}
        inputProps={{
          sx: inputStyle,
          maxLength: maxLength,
        }}
        InputProps={{
          startAdornment: startAdornment,
        }}
        {...register(name)}
      />
      {errors[name] && <Typography className='text-error'>{errors[name]?.message}</Typography>}
    </>
  );
};

const FormRadioButton = (props) => {
  const { label, value, register, name } = props;
  return (
    <Radio
      sx={{
        color: "#904DFF",
        "&.Mui-checked": {
          color: "#904DFF",
        },
      }}
      value={value}
      {...register(name)}
      // checkedIcon={
      //   <Button
      //     sx={{
      //       ...buttonStyle,
      //       backgroundColor: COLORS.primary4,
      //       color: COLORS.white,
      //     }}
      //   >
      //     <Typography>{label}</Typography>
      //   </Button>
      // }
      // icon={
      //   <Button
      //     sx={{
      //       ...buttonStyle,
      //     }}
      //   >
      //     <Typography>{label}</Typography>
      //   </Button>
      // }
    />
  );
};

const LIMIT_FILES = 10;

function QuizCreateAndEdit(props) {
  const { mode = "create" } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { modalData, dispatchModal } = useModalReducer();
  const [post, setPost] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isUnderstandFreePost, setIsUnderstandFreePost] = useState(false);
  const [requestData, setRequestData] = useState({
    title: "",
    contents: "",
    hashtag: "",
    cost: "",
    limit: "",
    type: 0,
    is_shareable: 0,
    is_payable: 1,
    is_view_buyer: 1, // 0: private | 1: public
    categoryIds: [],
    files: [],
  });
  const buttonRef = useRef(null);

  const { selectedPost } = useContext(GlobalStateContext);

  // edit category
  const [openCategory, setOpenCategory] = useState(false);
  const [categorySelected, setCategorySelected] = useState([]);

  // Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("editCost");

  //paid post
  const [isPaidPost, setIsPaidPost] = useState(false);

  // upload image
  const [fileUploaded, setFileUploaded] = useState([]);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // screen
  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const handleGetDetail = async () => {
    try {
      const res = await getPostDetail(selectedPost?.id);
      if (!res?.can_edit) {
        navigate(`/post/detail/${selectedPost?.id}`, {
          replace: true,
        });
      }
      setPost(res);
      setRequestData({
        title: res?.title,
        contents: res?.contents,
      });
      setCategorySelected(res?.categories);
    } catch (error) {
      const errorMessage = handleError(error, logout, navigate);
      console.log("errorMessage", errorMessage);
    }
  };

  const removeIsLocked = (postFiles) => {
    if (postFiles?.length == 0) return [];
    const previewTmp = structuredClone(postFiles);
    const newPreviewTmp = [];
    previewTmp.forEach((item) => {
      // const index = previewTmp.findIndex((element) => element?.id === item);
      item.is_locked = 0;
      newPreviewTmp.push(item);
    });
    return newPreviewTmp;
  };

  const handleEditPost = async (updateData) => {
    // default: 1
    // limited + cost > 0: 2
    // quiz: 3
    const getPostType = (cost, limited) => {
      if (cost > 0 && limited > 0) {
        return 2;
      }
      return 1;
    };

    try {
      let requestBody = {
        post: {
          ...requestData,
          hashtag: "",
          is_shareable: 0, // not used but in quiz is 1
          is_payable: requestData?.cost > 0 ? 1 : 0,
          type: getPostType(requestData?.cost, requestData?.limited),
          is_view_buyer: 1,
          categoryIds: categorySelected.length > 0 ? categorySelected.map((item) => item.id) : [],
          cost: requestData?.cost || 0,
        },
        ...updateData,
      };

      if (!isPaidPost) {
        requestBody = {
          post: {
            ...requestBody?.post,
            cost: 0,
            limited: 0,
            type: 1,
          },
          files: removeIsLocked(requestBody.files),
        };
      }

      await createPost(requestBody);
      navigate("/", { state: { createdPost: requestBody } });
    } catch (error) {
      const errorResponse = handleError(error, logout, navigate);
      handleOpenModalError(errorResponse?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (key, value) => {
    setRequestData({ ...requestData, [key]: value });
  };

  //Modal begin
  const handleOpenModalError = (content) => {
    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        type: "error",
        btnOKText: "확인",
        btnCancelText: "",
        title: "해당 게시물은 수정할 수 없어요",
        subTitle: "",
        content: (
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey900,
              lineHeight: LINE_HEIGHT.xxxLarge,
              whiteSpace: "pre-line",
            }}
          >
            {content}
          </Typography>
        ),
      },
    });
  };

  const handleOpenModalConfirmUploadFreePost = () => {
    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        type: "free_post",
        btnOKText: "확인",
        btnCancelText: "취소",
        title: "콘텐츠를 업로드하겠습니까?",
        subTitle: "",
        content: (
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey900,
              lineHeight: LINE_HEIGHT.xxxLarge,
              whiteSpace: "pre-line",
            }}
          >
            {`해당 콘텐츠는 무료로 제공합니다.\n판매를 원하실 경우,  유료콘텐츠 설정해주세요.`}
          </Typography>
        ),
      },
    });
  };

  const handleOk = () => {
    buttonRef.current.disabled = false;
    dispatchModal({ type: MODAL_STATUS.CLOSE });
  };

  const handleCancel = () => {
    dispatchModal({ type: MODAL_STATUS.CLOSE });
    switch (modalData.type) {
      case "free_post":
        handleUploadMedia(previewFiles);
        break;

      default:
        break;
    }
  };
  //Modal end

  // Start edit category
  const handleClickOpenCategory = () => {
    setOpenCategory(true);
  };

  const handleCloseCategory = () => {
    // setCategoryIds([])
    setOpenCategory(false);
  };

  const handleOkCategory = (newCategories) => {
    setOpenCategory(false);
    setCategorySelected(newCategories);
  };
  // End edit category

  //Drawer begin
  const onEditCost = () => {
    setDrawerType("editCost");
    handleDrawer(true);
  };

  const onEditLimited = () => {
    setDrawerType("editLimited");
    handleDrawer(true);
  };

  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  const handleDrawerClick = (value) => {
    if (drawerType === "editCost") {
      if (post?.type != 2) {
        handleDrawer(false);
        handleEditPost({
          cost: value,
        });
      } else {
        handleChange("cost", value);
        onEditLimited();
      }
    } else if (drawerType === "editLimited") {
      handleEditPost({
        limited: value,
      });
    }
  };
  //Drawer end

  // UPLOAD begin

  const handleSetPreview = (newFiles) => {
    setPreviewFiles((prevState) => [...prevState, ...newFiles]);
  };

  const removeImage = (id) => {
    const previewTmp = structuredClone(previewFiles);
    const fileUploadedTmp = structuredClone(fileUploaded);

    const foundPreview = previewTmp.filter((element) => element?.id !== id);
    const foundFileUploaded = fileUploadedTmp.filter((element) => element?.id !== id);

    setPreviewFiles(foundPreview);
    setFileUploaded(foundFileUploaded);
  };

  const onLockImage = (id) => {
    const previewTmp = structuredClone(previewFiles);
    const index = previewTmp.findIndex((element) => element?.id === id);
    if (index === 0) return;
    previewTmp[index].is_locked = previewTmp[index].is_locked == 1 ? 0 : 1;
    setPreviewFiles(previewTmp);
  };

  const handleGetPresignedUrl = async (requestData) => {
    try {
      const res = await getPresignedUrl(requestData);
      return res?.data?.data;
    } catch (error) {
      const errorMessage = handleError(error, logout, navigate);
      console.log("errorMessage", errorMessage);
    }
  };

  const getRequestFiles = async (element, index, fileLinks, fileThumbnailLinks) => {
    const file_buffer = await element?.file?.arrayBuffer();
    await uploadPresigned(fileLinks[index].url, file_buffer, {
      Authorization: null,
      lang: "kr",
      "Content-Type": element.type,
    });

    if (element?.thumbnail) {
      const file_thumbnail_buffer = await element?.thumbnail?.arrayBuffer();
      await uploadPresigned(fileThumbnailLinks[index].url, file_thumbnail_buffer, {
        Authorization: null,
        lang: "kr",
        "Content-Type": element.type,
      });
    }

    const result = {
      ...element,
      url: fileLinks[index].filename,
      view_url: element?.thumbnail ? fileThumbnailLinks[index].filename : "",
      thumbnail_url: element?.thumbnail ? fileThumbnailLinks[index].filename : "",
    };

    delete result.id;
    delete result.name;
    delete result.link;
    delete result.file;
    delete result.thumbnail;

    return result;
    // upload success but need post file to store and then get ulr
  };

  const handleUploadMedia = async (files) => {
    try {
      setIsLoading(true);
      // setPreviewFiles([]);
      const requestData = {
        list: [],
      };
      const requestThumbnailData = {
        list: [],
      };
      // const requestFilesChatSDK = [];
      files.forEach((element) => {
        const objFile = {
          filename: element?.file?.name,
          contentType: element?.file?.type,
        };
        const objThumbnail = {
          filename: element?.thumbnail?.name,
          contentType: "image/png",
        };
        requestData.list.push(objFile);
        requestThumbnailData.list.push(objThumbnail);
      });
      const [fileLinks, fileThumbnailLinks] = await Promise.all([
        handleGetPresignedUrl(requestData),
        handleGetPresignedUrl(requestThumbnailData),
      ]);

      const requestFiles = await Promise.all(
        files.map((element, index) =>
          getRequestFiles(element, index, fileLinks, fileThumbnailLinks)
        )
      );
      // handleChange("files", requestFiles);
      await handleEditPost({ files: requestFiles });
    } catch (error) {
      setIsLoading(false);
      const errorMessage = handleError(error, logout, navigate);
      console.log("errorMessage", errorMessage);
    }
  };
  // UPLOAD end

  const onHandleNext = async () => {
    if (buttonRef.current && !buttonRef.current.disabled) {
      buttonRef.current.disabled = true;
      // Perform the action
      // After the action is complete, reset buttonRef.current.disabled to false
    }
    if (isUnderstandFreePost || isPaidPost) {
      await handleUploadMedia(previewFiles);
    } else {
      setIsUnderstandFreePost(true);
      handleOpenModalConfirmUploadFreePost();
    }
  };

  const onHandlePaidPostDefault = (currentIsPaidPost) => {
    if (!currentIsPaidPost) return;
    handleChange("limited", "");
    handleChange("cost", "");

    if (previewFiles?.length == 0) return;
    const previewTmp = structuredClone(previewFiles);
    const newPreviewTmp = [];
    previewTmp.forEach((item) => {
      // const index = previewTmp.findIndex((element) => element?.id === item);
      item.is_locked = 0;
      newPreviewTmp.push(item);
    });
    setPreviewFiles(newPreviewTmp);
  };

  const isDisabledSubmit = () => {
    const previewTmp = structuredClone(previewFiles);
    const isLocked = previewTmp.some((element) => element?.is_locked == 1);

    if (!requestData?.contents) {
      return true;
    }
    if (previewFiles?.length == 0) {
      return true;
    }
    if (categorySelected?.length == 0) {
      return true;
    }
    if (isPaidPost && (Number(requestData?.cost) <= 0 || !isLocked)) {
      return true;
    }
    return false;
  };

  const schema = yup
    .object({
      company_type: yup.string().required(""),
      // email: yup.string().required("이메일을 입력해 주세요.").email("포함하여 작성해 주세요."),
      // nickname: yup
      //   .string()
      //   .required("닉네임을 입력해 주세요.")
      //   .min(2, "닉네임은 두 글자 이상이어야 합니다.")
      //   .max(30),
      // fullName: yup.string().required("기업명을 입력해 주세요."),
      // registration_number: yup
      //   .number()
      //   .transform((value) => (isNaN(value) ? undefined : value))
      //   .required("번호를 입력해 주세요.")
      //   .typeError("숫자여야 합니다"),
      // type_of_business: yup.string().required("사업 종류를 입력해 주세요."),
      // location_of_business: yup.string().required("소재지를 입력해 주세요."),
      // documentary: yup.string().required(),
      // official_site: yup.string().required(),
      // youtube: yup.string().required(),
      // instagram: yup.string().required(),
    })
    .required();

  const {
    register,
    setValue,
    getValues,
    setError,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const onSubmit = async (data) => {
    console.log("data", data);
    // try {
    //   const res = await registerBusinessAccount(data);
    //   if (res?.status == 200 || res?.status == 201) handleShowModalComplete(data);
    // } catch (error) {
    //   const errors = error?.response?.data?.data?.[0];
    //   if (errors?.email) {
    //     setError("email", {
    //       type: "server",
    //       message: error?.response?.data?.data?.[0]?.email,
    //     });
    //   }
    //   if (errors?.nickname) {
    //     setError("nickname", {
    //       type: "server",
    //       message: error?.response?.data?.data?.[0]?.nickname,
    //     });
    //   }
    // }
  };

  return (
    <Wrapper>
      <Backdrop open={isLoading} sx={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      <>
        <Box
          sx={{
            fontSize: TEXTSIZE.medium,
            color: COLORS.black1,
            pb: 1,
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          {/* banner */}
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Title text={"메인배너 이미지"} />
            <SubTitle
              text={`해당 이미지는 퀴즈 페이지에서 이벤트 메인배너로 표시됩니다!(필수) 1125x873픽셀 이상, 6MB 이하의 PNG 파일이 권장됩니다. 메인배너 이미지는 1장만 업로드 가능합니다.`}
              isShowIcon
            />
            {/* preview thumbs */}
            <Box>
              <Swiper
                spaceBetween={8}
                // width={60}
                // height={60}
                onSwiper={setThumbsSwiper}
                slidesPerView={5}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
              >
                {previewFiles?.map(({ id, link, type }) => (
                  <SwiperSlide
                    key={id}
                    style={{
                      borderRadius: "100px",
                      aspectRatio: 4 / 3,
                      cursor: "pointer",
                      height: "auto",
                    }}
                  >
                    <Box
                      className='preview-image'
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url("${link}")`,
                        borderRadius: RADIUS.medium,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        // backgroundPosition: "center"
                      }}
                    >
                      <ClearIcon
                        className='close-icon'
                        style={{
                          position: "absolute",
                          top: -2,
                          right: -2,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        onClick={() => removeImage(id)}
                      />
                      {type === "video" && <PlayIcon smallSize />}
                    </Box>
                  </SwiperSlide>
                ))}
                {previewFiles?.length !== LIMIT_FILES && (
                  <SwiperSlide
                    style={{
                      borderRadius: RADIUS.medium,
                      aspectRatio: 4 / 3,
                      border: "none",
                    }}
                  >
                    <ButtonUploadImage
                      handleSetPreview={handleSetPreview}
                      remainLimit={LIMIT_FILES - previewFiles?.length}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          p: "18px",
                          cursor: "pointer",
                          backgroundColor: COLORS.grey50,
                          borderRadius: RADIUS.medium,
                        }}
                      >
                        <PlusIcon fill={COLORS.grey300} />
                      </Box>
                    </ButtonUploadImage>
                  </SwiperSlide>
                )}
              </Swiper>
            </Box>
            {/* preview full width */}
            <Box>
              <Swiper
                spaceBetween={10}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
              >
                {previewFiles?.map(({ id, link, type }) => (
                  <SwiperSlide
                    key={id}
                    style={{
                      borderRadius: RADIUS.medium,
                      aspectRatio: 4 / 3,
                    }}
                  >
                    <Box
                      className='preview-image'
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url("${link}")`,
                        borderRadius: RADIUS.medium,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        // backgroundPosition: "center"
                      }}
                    ></Box>
                    {type === "video" && <PlayIcon />}
                  </SwiperSlide>
                ))}
                {previewFiles?.length !== LIMIT_FILES && (
                  <SwiperSlide
                    style={{
                      borderRadius: RADIUS.medium,
                      aspectRatio: 4 / 3,
                    }}
                  >
                    <ButtonUploadImage
                      handleSetPreview={handleSetPreview}
                      remainLimit={LIMIT_FILES - previewFiles?.length}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          p: "18px",
                          cursor: "pointer",
                          backgroundColor: COLORS.grey50,
                          borderRadius: RADIUS.medium,
                        }}
                      >
                        <PlusIcon fill={COLORS.grey300} />
                      </Box>
                    </ButtonUploadImage>
                  </SwiperSlide>
                )}
              </Swiper>
            </Box>
          </Box>

          {/* thumbnail */}
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Title text={"썸네일 이미지"} />
            <SubTitle
              text={`해당 이미지는 퀴즈 상세페이지에서 썸네일로 표시됩니다!(필수) 1080x1080픽셀 이상, 6MB 이하의 PNG 파일이 권장됩니다. 썸네일 이미지는 10장 이하로 업로드 가능합니다.`}
              isShowIcon
            />
            {/* preview thumbs */}
            <Box>
              <Swiper
                spaceBetween={8}
                // width={60}
                // height={60}
                onSwiper={setThumbsSwiper}
                slidesPerView={5}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
              >
                {previewFiles?.map(({ id, link, type }) => (
                  <SwiperSlide
                    key={id}
                    style={{
                      borderRadius: "100px",
                      aspectRatio: 1,
                      cursor: "pointer",
                      height: "auto",
                    }}
                  >
                    <Box
                      className='preview-image'
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url("${link}")`,
                        borderRadius: RADIUS.medium,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        // backgroundPosition: "center"
                      }}
                    >
                      <ClearIcon
                        className='close-icon'
                        style={{
                          position: "absolute",
                          top: -2,
                          right: -2,
                          cursor: "pointer",
                          zIndex: 2,
                        }}
                        onClick={() => removeImage(id)}
                      />
                      {type === "video" && <PlayIcon smallSize />}
                    </Box>
                  </SwiperSlide>
                ))}
                {previewFiles?.length !== LIMIT_FILES && (
                  <SwiperSlide
                    style={{
                      borderRadius: RADIUS.medium,
                      aspectRatio: 1,
                      border: "none",
                    }}
                  >
                    <ButtonUploadImage
                      handleSetPreview={handleSetPreview}
                      remainLimit={LIMIT_FILES - previewFiles?.length}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          p: "18px",
                          cursor: "pointer",
                          backgroundColor: COLORS.grey50,
                          borderRadius: RADIUS.medium,
                        }}
                      >
                        <PlusIcon fill={COLORS.grey300} />
                      </Box>
                    </ButtonUploadImage>
                  </SwiperSlide>
                )}
              </Swiper>
            </Box>
            {/* preview full width */}
            <Box>
              <Swiper
                spaceBetween={10}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
              >
                {previewFiles?.map(({ id, link, type }) => (
                  <SwiperSlide
                    key={id}
                    style={{
                      borderRadius: RADIUS.medium,
                      aspectRatio: 1,
                    }}
                  >
                    <Box
                      className='preview-image'
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url("${link}")`,
                        borderRadius: RADIUS.medium,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        // backgroundPosition: "center"
                      }}
                    ></Box>
                    {type === "video" && <PlayIcon />}
                  </SwiperSlide>
                ))}
                {previewFiles?.length !== LIMIT_FILES && (
                  <SwiperSlide
                    style={{
                      borderRadius: RADIUS.medium,
                      aspectRatio: 1,
                    }}
                  >
                    <ButtonUploadImage
                      handleSetPreview={handleSetPreview}
                      remainLimit={LIMIT_FILES - previewFiles?.length}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          p: "18px",
                          cursor: "pointer",
                          backgroundColor: COLORS.grey50,
                          borderRadius: RADIUS.medium,
                        }}
                      >
                        <PlusIcon fill={COLORS.grey300} />
                      </Box>
                    </ButtonUploadImage>
                  </SwiperSlide>
                )}
              </Swiper>
            </Box>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display={"flex"} flexDirection={"column"} gap={4}>
              {/* question */}
              <Box>
                <Title
                  text={"문제"}
                  count={`${requestData?.title?.length}/${LIMIT_LENGTH_TITLE}`}
                />
                {/* <TextField
              name='title'
              value={requestData.title}
              onChange={(event) => handleChange("title", event.target.value)}
              placeholder={t("please_enter_the_subject")}
              sx={{
                width: "100%",
                height: "48px",
                borderRadius: RADIUS.large,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: RADIUS.xxLarge,
                  backgroundColor: COLORS.grey50,
                  border: "none",
                },
              }}
              inputProps={{
                style: {
                  p: 1,
                  zIndex: 1,
                  color: COLORS.black1,
                  fontSize: TEXTSIZE.medium,
                },
                sx: {
                  "&::placeholder": {},
                },
                maxLength: LIMIT_LENGTH_TITLE,
              }}
            /> */}
                <FormInput
                  name='title'
                  placeholder={t("please_enter_the_subject")}
                  errors={errors}
                  register={register}
                />
              </Box>

              {/* hint_type */}
              <Box>
                <Title text={"퀴즈 힌트"} />
                <SubTitle text={"퀴즈 힌트를 확인할 수 있는 방법을 선택해 주세요!(필수)"} />
                <FormControl fullWidth>
                  <RadioGroup
                    aria-labelledby='hint-type'
                    name='hint_type'
                    // value={value}
                    // onChange={handleChange}
                    sx={{
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      value='undefine'
                      control={<FormRadioButton name='hint_type' register={register} />}
                      label='힌트 없음'
                    />
                    <FormControlLabel
                      value='link'
                      control={<FormRadioButton name='hint_type' register={register} />}
                      label='링크 제공'
                    />
                    <FormControlLabel
                      value='text'
                      control={<FormRadioButton name='hint_type' register={register} />}
                      label='검색 유도'
                    />
                  </RadioGroup>
                  <FormInput
                    name='hint_text'
                    placeholder={t("please_enter_the_subject")}
                    errors={errors}
                    register={register}
                  />
                </FormControl>
              </Box>

              {/* answer */}
              <Box>
                <Title text={"정답"} />
                <FormControl fullWidth>
                  <RadioGroup
                    aria-labelledby='demo-controlled-radio-buttons-group'
                    name='controlled-radio-buttons-group'
                    // value={value}
                    // onChange={handleChange}
                    sx={{
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      value='none'
                      control={<FormRadioButton name='answer' register={register} />}
                      label='객관식'
                    />
                    <FormControlLabel
                      value='link'
                      control={<FormRadioButton name='answer' register={register} />}
                      label='주관식'
                    />
                  </RadioGroup>
                </FormControl>

                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <FormInput
                      name='answer'
                      placeholder={t("please_enter_the_subject")}
                      errors={errors}
                      register={register}
                    />
                    <Button sx={{ ...buttonStyle, width: "64px", height: "40px" }}>정답</Button>
                  </Box>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <FormInput
                      name='answer'
                      placeholder={t("please_enter_the_subject")}
                      errors={errors}
                      register={register}
                    />
                    <Button sx={{ ...buttonStyle, width: "64px", height: "40px" }}>정답</Button>
                  </Box>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <FormInput
                      name='answer'
                      placeholder={t("please_enter_the_subject")}
                      errors={errors}
                      register={register}
                    />
                    <Button sx={{ ...buttonStyle, width: "64px", height: "40px" }}>정답</Button>
                  </Box>
                </Box>
              </Box>

              {/* reward */}
              <Box>
                <Title
                  text={"상품"}
                  suffix={
                    <Box
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "16px",
                        color: COLORS.primary4,
                        display: "flex",
                        alignItems: "center",
                        gap: 0.25,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "16px",
                        }}
                      >
                        총 사용
                      </Typography>
                      <PurchasePurpleFilledIcon style={{ width: "16px", height: "16px" }} />
                      <Typography
                        sx={{
                          fontWeight: "700",
                        }}
                      >
                        1,000
                      </Typography>
                    </Box>
                  }
                />
                <FormControl fullWidth>
                  <RadioGroup
                    aria-labelledby='reward-type'
                    name='reward_type'
                    // value={value}
                    // onChange={handleChange}
                    sx={{
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      value='none'
                      control={<FormRadioButton name='reward_type' register={register} />}
                      label='객관식'
                    />
                    <FormControlLabel
                      value='link'
                      control={<FormRadioButton name='reward_type' register={register} />}
                      label='주관식'
                    />
                  </RadioGroup>
                  <Box display={"flex"} gap={1} flexDirection={"column"}>
                    <FormInput
                      name='cost'
                      placeholder={t("please_enter_the_subject")}
                      errors={errors}
                      register={register}
                      startAdornment={
                        <InputAdornment position='end' sx={{ zIndex: 1, marginLeft: "-1px" }}>
                          <PurchaseBlackIcon />
                        </InputAdornment>
                      }
                    />
                    <FormInput
                      name='limited'
                      placeholder={t("please_enter_the_subject")}
                      errors={errors}
                      register={register}
                      startAdornment={
                        <InputAdornment position='end' sx={{ zIndex: 1, marginLeft: "-1px" }}>
                          <UserPlusIcon />
                        </InputAdornment>
                      }
                    />
                  </Box>

                  <Box
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "16px",
                      color: COLORS.black1,
                      display: "flex",
                      alignItems: "center",
                      gap: 0.25,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "16px",
                      }}
                    >
                      총 사용
                    </Typography>
                    <PurchaseBlackIcon style={{ width: "16px", height: "16px" }} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      1,000
                    </Typography>
                  </Box>
                </FormControl>
              </Box>

              <Box>
                <Title text={"참여 기간 설정하기"} suffix={<h1>date</h1>} />
                <DateTimePicker
                  sx={{ width: "100%" }}
                  referenceDate={dayjs("2022-04-17T15:30")}
                  format={`YYYY.mm.DD | HH:MM~${"123"}`}
                />
              </Box>
            </Box>
          </form>
        </Box>
        <Box sx={{ position: "sticky", bottom: 0, zIndex: 1 }}>
          <LoadingButton
            ref={buttonRef}
            loading={isLoading}
            sx={{
              my: 2,
              p: 2,
              // position: "absolute",
              // bottom: "0px",
              borderRadius: RADIUS.xxxLarge,
              width: "100%",
              backgroundColor: COLORS.primary4,
              color: COLORS.white,
              boxShadow: "none",
              "&:hover": {
                backgroundColor: COLORS.primary4,
                boxShadow: "none",
              },
              "&.Mui-disabled": {
                backgroundColor: COLORS.grey100,
                color: COLORS.grey600,
              },
            }}
            disabled={isDisabledSubmit()}
            variant='contained'
            size='large'
            className='submit'
            onClick={onHandleNext}
          >
            다음
          </LoadingButton>
        </Box>
        <Button
          onClick={() => {
            new daum.Postcode({
              oncomplete: (data) => {
                console.log("data", data);
              },
            }).open();
          }}
        >Get postCode</Button>
      </>

      <Dialog open={openCategory} onClose={handleCloseCategory} fullScreen={!isPc}>
        <DialogContent>
          <HeaderEditCategories onClickLeft={handleCloseCategory} title={"카테고리 선택"} />
          <PostCategory
            // setCategoryIds={setCategoryIds}
            categorySelected={categorySelected}
            handleSubmit={handleOkCategory}
          />
        </DialogContent>
      </Dialog>

      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame
            setOnDrawer={() => handleDrawer(false)}
            handleModal={handleDrawerClick}
            type={drawerType}
            data={post}
            // refetch={handleDrawerRefetch}
            callBack={() => navigate(-1)}
          />
        </DrawerPortal>
      )}
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={handleCancel}
        handleOk={handleOk}
        handleCancel={handleCancel}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
    </Wrapper>
  );
}

export default QuizCreateAndEdit;
