import React from "react";
import styled from "styled-components";
import { COLORS, FONT_WEIGHT, LINE_HEIGHT, TEXTSIZE } from "@theme/constants";
import { Box, Typography } from "@mui/material";
import { ReactComponent as ArrowLeft } from "@icons/arrow_left.svg";

const WrapperHeader = styled.div`
  // position: fixed;
  background-color: ${COLORS.white};
  z-index: 99;
  width: 100%;
  top: 0;

  .header {
    // padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header__icon {
    fill: ${COLORS.grey600};
    cursor: pointer;
  }

  .header__text {
    font-size: ${TEXTSIZE.large};
    font-weight: ${FONT_WEIGHT.xxLarge};
    line-height: ${LINE_HEIGHT.superLarge};
    color: ${COLORS.black1};
  }

  .icon_right {
    cursor: pointer;
    circle {
      stroke: ${COLORS.grey600};
    }
    path {
      stroke: ${COLORS.grey600};
    }
  }
`;

function HeaderEditCategories(props) {
  const { onClickLeft, title } = props;

  return (
    <>
      <WrapperHeader>
        <Box className="header">
          <ArrowLeft className="header__icon" onClick={onClickLeft} />
          <Typography className="header__text">{title}</Typography>
          <Box></Box>
        </Box>
      </WrapperHeader>
    </>
  );
}

export default HeaderEditCategories;
