import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as RightArrow } from "@icons/arrow_right.svg";
import { COLORS, FONT_WEIGHT, LINE_HEIGHT, WIDTH } from "../../theme/constants";
import { Box, Typography } from "@mui/material";
import { textMediumMuiStyle } from "../../utils/commonStyle";
import ModalComponent from "../../components/modal/ModalComponent";
import { useAuth } from "../../context/Auth";
import { useTranslation } from "react-i18next";
import { useModalReducer } from "@hooks";
import { MODAL_STATUS } from "../../hooks/useModalReducer";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    // margin-left: 100px;
    max-width: 600px;
  }
`;

function Setting() {
  const { logout, user } = useAuth();
  const { t } = useTranslation();
  const { modalData, dispatchModal } = useModalReducer();

  const onLogout = () => {
    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        type: "logout",
        btnOKText: t("button.check"),
        btnCancelText: t("button.cancel"),
        title: t("dialog.title.logout"),
        subTitle: "",
        content: (
          <Typography
            sx={{
              ...textMediumMuiStyle,
              color: COLORS.grey900,
              lineHeight: LINE_HEIGHT.xxxLarge,
            }}
          >
            {t("dialog.content.logout")}
          </Typography>
        ),
      },
    });
  };

  //modal action
  const handleOk = () => {
    dispatchModal({ type: MODAL_STATUS.CLOSE });
    logout();
  };

  // const handleDrawer = (value) => {
  //   const drawerRoot = document.querySelector("#drawer-root");
  //   drawerRoot.style.display = value ? "block" : "none";
  //   setDrawerOpen(value);
  // };

  const settingItems = [
    {
      label: t("setting.account"),
      link: "/setting/account",
      isShow: true
    },
    {
      label: t("setting.notifications"),
      link: "/setting/notifications",
      isShow: true
    },
    // {
    //   label: t("setting.background"),
    //   link: "/setting/background",
    //   isShow: true
    // },
    {
      label: t("setting.info"),
      link: "/setting/info",
      isShow: true
    },
    {
      label: t("setting.block_user"),
      link: "/setting/block",
      isShow: true
    },
    {
      label: t("setting.question"),
      link: "/question",
      isShow: true
    },
    {
      label: t("setting.instagram_connect"),
      link: "/instagram",
      isShow: [2,3].includes(user?.type) ? true : false
    },
  ];

  return (
    <>
      <Wrapper>
        {settingItems.map(({ label, link, isShow }) => (
          isShow && <Link to={link} key={link}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: 4,
                alignItems: "center",
              }}
            >
              <Typography component={"span"} sx={{ ...textMediumMuiStyle }}>
                {label}
              </Typography>
              <RightArrow fill={COLORS.grey600} />
            </Box>
          </Link>
        ))}
        <Box sx={{ display: "flex", justifyContent: "space-between", my: 4 }}>
          <Typography sx={{ ...textMediumMuiStyle }}>
            {t("setting.app_version")}
          </Typography>
          <Typography
            sx={{
              ...textMediumMuiStyle,
              fontWeight: FONT_WEIGHT.xxLarge,
              color: COLORS.grey400,
            }}
          >
            1.0.0
          </Typography>
        </Box>
        <Typography
          onClick={onLogout}
          sx={{ ...textMediumMuiStyle, my: 4, cursor: "pointer" }}
        >
          {/* 로그아웃 */}
          {t("logout")}
        </Typography>
      </Wrapper>
      {/* {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame setOnDrawer={() => handleDrawer(false)} type="logout" />
        </DrawerPortal>
      )} */}
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleOk={handleOk}
        handleCancel={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
    </>
  );
}

export default React.memo(Setting);
