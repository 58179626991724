import { useNavigate, useNavigation } from "react-router-dom";
import { COLORS, PIC_TYPE, WIDTH } from "../theme/constants";
import { useAuth } from "../context/Auth";
import dayjs from "dayjs";

export const handleSetImageSize = (posts) => {
  let tmpPosts = [];

  const itemSize11 = {
    cols: 1,
    rows: 1,
  };

  const itemSize22 = {
    cols: 2,
    rows: 2,
  };

  const itemSize12 = {
    cols: 1,
    rows: 2,
  };

  for (let index = 0; index < posts.length; index++) {
    let tmpPostItem = posts[index];
    if (index == 0 || index == 9 || index == 16) {
      tmpPostItem = { ...tmpPostItem, ...itemSize22 };
    } else if (index == 5 || index == 11 || index == 21) {
      tmpPostItem = { ...tmpPostItem, ...itemSize12 };
    } else {
      tmpPostItem = { ...tmpPostItem, ...itemSize11 };
    }
    tmpPosts.push(tmpPostItem);
  }

  return tmpPosts;
};

export const handleError = (error, logout, navigate) => {
  // if (error?.response?.status === 401) {
  //   logout();
  // }
  switch (error?.response?.data?.code) {
    case 401:
      logout();
      break;
    case 400:
      return error?.response?.data?.message;
    case 404:
      return navigate("/404", { replace: true });
    case 422:
      return error?.response?.data?.errors;
    default:
      // setErrorMessage();
      return error;
  }
};

export const getTotalObjectValues = (obj) => {
  let result = 0;
  Object.values(obj).forEach((num) => {
    result += num;
  });
  return result;
};

export const getPicStyleByStatusListPost = (post) => {
  //- White heart : chưa có donate
  // if (post?.donations_count == 0) {
  //   return {
  //     picType: PIC_TYPE.white,
  //     styleText: {},
  //   };
  // }

  // //- Number heart : bài viết phải trả tiền & bản thân đã trả tiền
  // if (post?.cost > 0 && post?.is_paid) {
  //   return {
  //     picType: PIC_TYPE.purple,
  //     styleText: { color: COLORS.white },
  //   };
  // }

  // //- PIC heart (màu tím) : có donation & bản thân đã donated
  // if (post?.total_donated_cost > 0 && post?.is_donated) {
  //   return {
  //     picType: PIC_TYPE.purple,
  //     styleText: { color: COLORS.primary6 },
  //   };
  // }

  if (post?.is_paid || post?.is_donated) {
    return {
      picType: PIC_TYPE.purple,
      // styleText: { color: COLORS.primary6 },
    };
  }

  return;
};

export const isMine = (value) => {
  const user = JSON.parse(window.localStorage.getItem("user"));
  return user?.id === value || user?.profile?.nickname === value;
};

export const validateEmail = (email) => {
  const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
  return emailRegex.test(email);
};

export const isValidPassword = (password) => {
  const passwordRegex = new RegExp(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]{8,16}$/);
  return passwordRegex.test(password);
};

export const numberFormatThousand = (number = 151500) => {
  let result = number;

  if (number > 999) {
    if (number % 1000 < 100) {
      result = `${Math.floor(number / 1000)}k`;
    } else {
      result = `${(number / 1000).toFixed(1)}k`;
    }
  }

  return result;
};

export const numberWithCommas = (number) => {
  let result = 0;
  result = number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return result;
};

export const infiniteQueryEmptyResponse = (data) => {
  let result = true;
  try {
    if (data.pages[0].list.length > 0) {
      result = false;
    }
  } catch (error) {
    console.log("check infiniteQueryEmptyResponse error");
  }
  return result;
};

export const infiniteQueryChatEmptyResponse = (data) => {
  let result = true;
  try {
    if (data.pages[0].length > 0) {
      result = false;
    }
  } catch (error) {
    console.log("check infiniteQueryChatEmptyResponse error");
  }
  return result;
};

export const renderUId = () => {
  return dayjs().unix().toString(36) + Math.random().toString(36).substr(2);
};

export const setBaseFileUrl = (url) => {
  return process.env.REACT_APP_FILE_BASE_URL + `/${url}`;
};

export const getFileTypeByName = (filename) => {
  const extension = filename.split(".").pop();
  const typeImages = [
    "jpg",
    "jpeg",
    "png",
    "apng",
    "avif",
    "bmp",
    "gif",
    "ico",
    "svg",
    "tif",
    "tiff",
    "webp",
  ];
  const typeVideos = ["mp4", "webm", "avi", "mpeg", "ogv", "ts", "3gp", "3g2", "mov"];
  const typeFiles = ["doc", "docx", "xlsx", "xls"];

  if (typeImages.includes(extension.toLowerCase())) {
    return "image";
  } else if (typeVideos.includes(extension.toLowerCase())) {
    return "video";
  } else if (typeFiles.includes(extension.toLowerCase())) {
    return "file";
  } else {
    return "file";
  }
};

export const generateVideoThumbnail = (file) => {
  if (!file) return;
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");

    // this is important
    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(file);

    video.onloadeddata = () => {
      let ctx = canvas.getContext("2d");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      return resolve(canvas.toDataURL("image/png"));
    };
  });
};

export const dataURLtoFile = (dataUrl, filename) => {
  var arr = dataUrl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const removeEmpty = (obj) => {
  Object.keys(obj).forEach((k) => !obj[k] && delete obj[k]);
  return obj;
};

export const getUserTimezone = () => {
  // Get the timezone offset in minutes
  const offsetMinutes = new Date().getTimezoneOffset();

  // Convert the offset to hours and minutes
  const offsetHours = Math.abs(offsetMinutes / 60);
  const offsetMinutesPart = Math.abs(offsetMinutes % 60);

  // Determine the sign of the offset
  const sign = offsetMinutes > 0 ? "-" : "+";

  // Format the timezone as +/-HH:MM
  const timezone = sign + offsetHours;

  return timezone;
};

export const checkExitedAndAddLocalStorage = (key = "recentlyKeywords", value) => {
  const localRecently = JSON.parse(localStorage.getItem(key)) || [];
  const found = localRecently.find((element) => element === value);
  if (found) {
    return;
  }
  localRecently.unshift(value);
  localStorage.setItem(key, JSON.stringify(localRecently));
};