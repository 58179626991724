import React from "react";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "@icons/check-primary.svg";
import { COLORS } from "@theme/constants";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { listBizType } from "./listBizType";

const Wrapper = styled.div`
  width: fit-content;
  margin: 0 auto 100px;
  text-align: center;
  margin: .text--title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: ${COLORS.black1};
  }

  .icon {
    margin: 1.25rem auto;
  }

  .text {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  .text--description {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .info {
    margin: 40px auto;
    padding: 24px 0;
    border-radius: 20px;
    background: ${COLORS.grey50};
    font-size: 16px;
    line-height: 20px;
    .info__title {
      font-weight: 500;
      margin-bottom: 8px;
    }
    .info__text {
      font-weight: 700;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
`;

function RegisterBIZComplete(props) {
  const { companyName = "", email = "", type = "" } = props;
  const { t } = useTranslation();

  const findType = listBizType?.find((i) => i?.value == type);
  console.log(findType);

  return (
    <Wrapper>
      <Typography className='text--title'>가입 신청 완료</Typography>
      <CheckIcon className='icon' />
      <p className='text'>가입 등록 신청이 완료되었습니다.</p>
      <p className='text--description'>
        심사에는 2~3일의 영업일이 소요되며 승인 안내는 이메일을 확인해주세요!
      </p>
      <dl className='info'>
        <dt className='info__title'>기업명</dt>
        <dd className='info__text'>{companyName}</dd>
        <dt className='info__title'>가입 이메일</dt>
        <dd className='info__text'>{email}</dd>
        <dt className='info__title'>비즈니스 유형</dt>
        <dd className='info__text'>{findType?.label}</dd>
      </dl>
    </Wrapper>
  );
}

export default React.memo(RegisterBIZComplete);
