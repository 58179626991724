import { useCallback } from "react";
import { EVENT_NAME } from "../../theme/constants";
import useSocket from "./useSocket";

function useSocketEmit() {
  const { socket } = useSocket();
  const emitSendMessage = useCallback((newMessage) => {
    if (socket) {
      // console.log("socket", socket);
      console.log("emitSendMessage newMessage", newMessage);
      socket.emit(EVENT_NAME.SEND_MESSAGE, newMessage);
    }
  }, []);

  const emitJoinGroup = useCallback((groupId) => {
    if (socket) {
      socket.emit(EVENT_NAME.JOIN_GROUP, { groupId });
    }
  }, []);

  const emitLeaveGroup = useCallback((groupId) => {
    if (socket) {
      socket.emit(EVENT_NAME.LEAVE_GROUP, { groupId });
    }
  }, []);

  const emitJoinPrivateChat = useCallback((participantId) => {
    if (socket) {
      socket.emit(EVENT_NAME.JOIN_PRIVATE_CHAT, { participantId });
    }
  }, []);

  const emitLeavePrivateChat = useCallback((data) => {
    if (socket) {
      socket.emit(EVENT_NAME.LEAVE_PRIVATE_CHAT, data);
    }
  }, []);

  const emitChangeAliasName = useCallback((data) => {
    if (socket) {
      socket.emit(EVENT_NAME.CHANGE_ALIAS_NAME, data);
    }
  }, []);

  const emitChangeGroupName = useCallback((data) => {
    if (socket) {
      socket.emit(EVENT_NAME.CHANGE_GROUP_NAME, data);
    }
  }, []);

  const emitChangeNotification = useCallback((data) => {
    if (socket) {
      socket.emit(EVENT_NAME.UPDATE_NOTIFICATION, data);
    }
  }, []);

  return {
    emitSendMessage,
    emitJoinGroup,
    emitLeaveGroup,
    emitJoinPrivateChat,
    emitLeavePrivateChat,
    emitChangeAliasName,
    emitChangeGroupName,
    emitChangeNotification,
  };
}

export default useSocketEmit;
