import { useEffect } from "react";
import BubbleUI from "react-bubble-ui";
import "react-bubble-ui/dist/index.css";
import BubbleItem from "./BubbleItem";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { WIDTH } from "../../theme/constants";

const Wrapper = styled.div`
  .myBubbleUI {
    width: 100%;
    overflow-y: hidden;
    margin-top: 60px;
  }

  .child {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .childComponent {
    width: 100%; /* width expands to fit bubble */
    height: 100%; /* width expands to fit bubble */
    border-radius: 50%; /* rounded border forms a circle */
    display: flex;
    user-select: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export default function ExploreBubbles(props) {
  const { data } = props;
  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });
  const options = {
    size: 100,
    minSize: 30,
    gutter: 30,
    provideProps: true,
    numCols: isPc ? 10 : 6,
    fringeWidth: 45,
    yRadius: 200,
    xRadius: 150,
    cornerRadius: 150,
    showGuides: false,
    compact: true,
    gravitation: 5,
  };

  useEffect(() => {
    const bubbles = document.querySelector("._2MD0k");
    const img = document.querySelectorAll(".childComponent");
    img.forEach(
      (i) =>
        (i.ondragstart = () => {
          return false;
        })
    );
    const dragSpeed = 2;
    let isDown = false;
    let startX;
    let startY;
    let scrollLeft;
    let scrollTop;

    bubbles.addEventListener("mousedown", (e) => {
      isDown = true;
      bubbles.classList.add("active");
      startX = e.pageX - bubbles.offsetLeft;
      startY = e.pageY - bubbles.offsetTop;
      scrollLeft = bubbles.scrollLeft;
      scrollTop = bubbles.scrollTop;
    });
    bubbles.addEventListener("mouseleave", () => {
      isDown = false;
      bubbles.classList.remove("active");
    });
    bubbles.addEventListener("mouseup", () => {
      isDown = false;
      bubbles.classList.remove("active");
    });
    bubbles.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - bubbles.offsetLeft;
      const y = e.pageY - bubbles.offsetTop;
      const walk = (x - startX) * dragSpeed;
      const topWalk = (y - startY) * dragSpeed;
      bubbles.scrollLeft = scrollLeft - walk;
      bubbles.scrollTop = scrollTop - topWalk;
    });
  });

  const children = data.map((user, i) => {
    return <BubbleItem data={user} className="child" key={i} />;
  });

  return (
    <Wrapper>
      <BubbleUI
        key={1}
        options={options}
        className="myBubbleUI"
        style={{ height: `calc(100vh - ${isPc ? "220" : "300"}px)` }}
      >
        {children}
      </BubbleUI>
    </Wrapper>
  );
}
