import { useEffect, useState } from "react";
import ConsentPayment from "../containers/paymentGateway/ConsentPayment";
import Payment from "../containers/paymentGateway/Payment";
import Complete from "../containers/paymentGateway/Complete";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Alert, Box, Button, Snackbar, Tab, Typography } from "@mui/material";
import { postStorePaymentDonate } from "../api";
import FooterPaymentGateway from "../containers/footers/FooterPaymentGateway";

export default function PaymentGateway() {
  const channel = new BroadcastChannel("payment-connected");
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "Fail",
    status: "",
  });
  const { vertical, horizontal, open, message, status } = state;
  const [step, setStep] = useState(1);
  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handPreStep = () => {
    setStep(step - 1);
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      personalName: "",
      prefixResidentNumber: "",
      suffixResidentNumber: "",
      companyName: "",
      companyNumber: "",
      companyImage: "",
      isAccess: true,
      type: "personal",
      totalMoney: 0,
      paymentMethod: "1",
    },
  });

  const init_orderid = () => {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();
    let time = today.getTime();

    if (parseInt(month) < 10) {
      month = "0" + month;
    }

    if (parseInt(date) < 10) {
      date = "0" + date;
    }

    return "Danal_" + year + "" + month + "" + date + "" + time;
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  channel.onmessage = (msg) => {
    let mess = "";
    let status = "";
    const onMessageData = msg?.data;
    console.log(onMessageData);
    if (onMessageData?.toString() == "true") setStep(3);
    else {
      // alert("Fail");
      mess = "거래를 제대로 처리할 수 없습니다. 거래를 다시 시도하세요.";
      status = "error";
      console.log("Fail");
    }

    setState({ ...state, open: true, message: mess, status });
  };

  function handlePayment() {
    try {
      const selectType = getValues("type");
      const totalMoney = getValues("totalMoney");
      const userCode = getValues("prefixResidentNumber");
      const companyCode = getValues("companyNumber");
      const userName = getValues("personalName");
      const companyName = getValues("companyName");

      const userId = selectType == "personal" ? userCode : companyCode;
      const name = selectType == "personal" ? userName : companyName;

      const obj = {
        type: selectType,
        amount: totalMoney,
        itemname: "FUND",
        useragent: "WP",
        orderid: init_orderid(),
        userid: userId,
        username: name,
        return_url: window.location.origin + "/payment-connecting?status=true",
        cancel_url: window.location.origin + "/payment-connecting",
        bypass_value: "user_product_id=123",
        useremail: "123123@123123.COM",
        channel_type: "donate",
      };
      const data = new FormData();

      Object.entries(obj)?.forEach((item) => {
        data.append(item[0], item[1]);
      });

      const axiosPayment = axios.create();
      const win = window.open("about:blank", "_blank");
      win?.document?.write(`<!DOCTYPE html>
                    <html>
                    <head>
                    <meta name="viewport" content="width=device-width, initial-scale=1">
                    <style>
                    .loader {
                      border: 8px solid #f3f3f3;
                      border-radius: 50%;
                      border-top: 8px solid #3498db;
                      width: 60px;
                      height: 60px;
                      margin: auto;
                      margin-top: 200px;
                      -webkit-animation: spin 2s linear infinite; /* Safari */
                      animation: spin 2s linear infinite;
                    }
                    
                    /* Safari */
                    @-webkit-keyframes spin {
                      0% { -webkit-transform: rotate(0deg); }
                      100% { -webkit-transform: rotate(360deg); }
                    }
                    
                    @keyframes spin {
                      0% { transform: rotate(0deg); }
                      100% { transform: rotate(360deg); }
                    }
                    </style>
                    </head>
                    <body>
                    <div class="loader"></div>
                    </body>
                    </html>`);

      axiosPayment
        .post(process.env.REACT_APP_PAYMENT_PHP_API, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const value = res?.data?.data;

          postStorePaymentDonate({
            type: obj?.type,
            itemname: obj?.itemname,
            username: obj?.username,
            userid: obj?.userid,
            orderid: obj?.orderid,
            amount: obj?.amount,
          })
            .then((res) => {
              if (res?.status === 201) {
                win?.document
                  ?.write(`<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
                  <html xmlns="http://www.w3.org/1999/xhtml" >
                  <head>
                  <meta http-equiv="Content-Type" content="text/html; charset=euc-kr">
                  <link href="./css/style.css" type="text/css" rel="stylesheet"  media="all" />
                  <title>*** �ٳ� �ſ�ī�� ���� ***</title>
                  </head>
                  <body>
                      <form name="form" ACTION="https://checkout.teledit.com/creditcard_std/web" METHOD="POST" >
                      <input TYPE="HIDDEN" NAME="STARTPARAMS"  	VALUE="${value}">
                      </form>
                      <script>
                          document.form.submit();
                      </script>
                  </body>`);
              }
            })
            .catch((error) => {
              setState({
                ...state,
                open: true,
                message: "System Error",
                status: "error",
              });
            });
        })
        .catch((error) => {
          setState({
            ...state,
            open: true,
            message: "System Error",
            status: "error",
          });
        });
    } catch (error) {
      setState({
        ...state,
        open: true,
        message: "System Error",
        status: "error",
      });
    }
  }

  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF", minHeight: "90svh" }}>
        {step === 1 ? (
          <ConsentPayment
            nextStep={handleNextStep}
            register={register}
            setValue={setValue}
            getValues={getValues}
          />
        ) : step === 2 ? (
          <>
            <Payment
              nextStep={handleNextStep}
              preStep={handPreStep}
              register={register}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              handlePayment={handlePayment}
            />
          </>
        ) : (
          <>
            <Complete setStep={setStep} getValues={getValues} />
          </>
        )}

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          // message={message}
          key={vertical + horizontal}
          autoHideDuration={3000}
        >
          <Alert
            onClose={handleClose}
            severity={status}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
      <FooterPaymentGateway/>
    </>
  );
}
