import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";
import HotCreatorSkeleton from "./HotCreatorSkeleton";

const SkeletonWrap = styled.div`
  .skeleton-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6px;
  }

  .skeleton-item {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:after {
      display: block;
      content: "";
      padding-bottom: 100%;
    }
  }

  .span-col-2 {
    grid-column: span 2 / auto;
  }

  .span-row-2 {
    grid-row: span 2 / auto;
  }
`;

export default function SearchSkeleton() {
  const randomGridArray = [
    { class: "grid1", row: [2, 5, 6, 8, 10, 13, 14, 15], col: [5, 8, 10, 14] },
    { class: "grid2", row: [1, 3, 4, 7, 10, 15, 16, 17], col: [1, 3, 16, 17] },
    { class: "grid3", row: [0, 1, 3, 7, 10, 15, 18, 19], col: [0, 3, 15, 19] },
  ];
  const randomeNum = Math.floor(Math.random() * 3);
  const items = Array.from({ length: 20 }, (_, i) => {
    return { id: i, class: null };
  });

  items.forEach((item, index) => {
    if (randomGridArray[randomeNum].row.includes(index)) {
      item.class += " span-row-2";
    }
    if (randomGridArray[randomeNum].col.includes(index)) {
      item.class += " span-col-2";
    }
  });

  return (
    <>
      <HotCreatorSkeleton />
      <SkeletonWrap>
        <div className='skeleton-wrap'>
          {items.map((item, index) => (
            <Skeleton variant='rounded' className={`skeleton-item ${item.class}`} key={index} />
          ))}
        </div>
      </SkeletonWrap>
    </>
  );
}
