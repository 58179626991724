import React from "react";
import { Box, Avatar, Typography } from "@mui/material";
import { COLORS } from "@theme/constants";
import ImageAvatar from "@images/avatar.png";

const truncateStyle = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "calc(100% - 40px)",
  overflow: "hidden",
};

function MessageToast(props) {
  const {
    authorData = {
      id: "",
      avatar: "",
      name: "",
      job: "",
    },
    authorStyle = {},
    avatarStyle = {},
    nameStyle = {},
    jobStyle = {},
  } = props;

  const { id, avatar, name, job } = authorData;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: 1,
        width: "100%",
        ...authorStyle,
      }}
    >
      <Avatar
        sx={{
          ...avatarStyle,
        }}
        src={avatar || ImageAvatar}
      />
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: COLORS.white,
            ...truncateStyle,
            visibility: name ? "visible" : "hidden",
            ...nameStyle,
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            color: COLORS.white,
            ...truncateStyle,
            visibility: job ? "visible" : "hidden",
            ...jobStyle,
          }}
        >
          {job}
        </Typography>
      </Box>
    </Box>
  );
}

export default MessageToast;
