import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { COLORS, HEIGHT, TEXTSIZE, WIDTH } from "../theme/constants";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LOGODARK } from "@icons/logo-dark.svg";
import { ReactComponent as LOGOLIGHT } from "@icons/logo-light.svg";
import Lottie from "lottie-react";
import welcomeLottie from "../utils/join.json";
import { ThemeContext } from "../context/themeProvider";
import checkBoxImage from "@images/checkBox.svg";
import checkedBoxImage from "@images/checkedBox.svg";
import arrowRightImage from "@images/arrowRight.svg";
import { useTranslation } from "react-i18next";
import HeaderBack from "../components/header/HeaderBack";

const Wrapper = styled.div`
  // position: relative;
  // height: calc(calc(var(--vh, 1vh) * 100) - ${HEIGHT.header_mo} - ${HEIGHT.navigation});

  display: flex;
  flex-direction: column;
  height: 100vh;

  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }

  .login__section {
    margin-top: 40px;
    // position: relative;
    // top: 13%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    // width: 205px;
    flex: 1 1 auto;
  }

  .section {
    // position: absolute;
    width: 100%;
    bottom: 0;
  }

  .MuiFormControlLabel-root {
    margin: 0 0;
  }

  .terms {
    font-size: ${TEXTSIZE.medium};

    .MuiFormControlLabel-root {
      margin: 0 0 12px;
    }
  }

  .MuiSvgIcon-root {
    color: var(--textColor);
  }

  .MuiButtonBase-root {
    padding: 3px;
  }

  .MuiTypography-root {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  .row-wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  .point-text {
    margin: 0 auto 0 5px;
    color: var(--mainColor);
  }

  .icon-wrap {
    margin: auto 0 0 auto;
    width: 24px;
    cursor: pointer;
  }

  .submit {
    width: 100%;
    height: 56px;
    margin: 28px auto 12px;
    background: var(--mainColor);
    color: var(--bgColor);

    &.MuiButton-root.Mui-disabled {
      background: var(--colorF3to3);
      color: var(--subTextColor);
    }

    &:hover {
      background: var(--mainColor);
      color: var(--bgColor);
      text-decoration: underline;
    }
  }

  .lottie-wrap {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);

    .lottie svg > g {
      width: 100vw;
    }
  }
`;

const checkboxStyle = {
  height: "18px",
  width: "18px",
  margin: "0px 1px",
};

function Join() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState([false, false, false, false]);
  const [isdisabled, setIsdisabled] = useState(true);
  const [isLottieShow, setIsLottieShow] = useState(false);
  const { theme } = useContext(ThemeContext);

  const termsList = [
    {
      text: t("picaverse_terms_of_use"),
      require: true,
      terms: "terms",
      title: "서비스 이용약관",
    },
    {
      text: t("privacy_policy"),
      require: true,
      terms: "privacy",
      title: "개인정보 처리방침",
    },
    {
      text: t("you_are_over_14_years_old"),
      require: true,
      terms: "age",
      title: "만 14세 이상",
    },
    {
      text: t("receive_marketing_information"),
      require: false,
      terms: "advertisement",
      title: "마케팅 정보 수신",
    },
  ];

  const handleChangeAll = () => {
    setChecked(checked.map((item) => !item));
  };

  const handleChecked = (eventIndex) => {
    setChecked(
      checked.map((item, index) => {
        return index === eventIndex ? !item : item;
      })
    );
  };

  useEffect(() => {
    setIsdisabled(!(checked[0] && checked[1] && checked[2]));
  }, [checked]);

  return (
    <>
      <Wrapper>
        <HeaderBack title={t("page_title.join")}/>
        <div className='login__section'>
          <h1>{theme === "dark" ? <LOGODARK /> : <LOGOLIGHT />}</h1>
          <p
            style={{
              color: COLORS.grey400,
              fontSize: TEXTSIZE.small,
              justifyContent: "flex-start",
            }}
          >
            {/* 콘텐츠로 만드는 당신만의 세상 */}
            {t("create_your_own_world_with_content")}
          </p>
          <Box sx={{ width: "100%", pt: 6 }}>
            <FormControlLabel
              label={t("totally_agree")}
              control={
                <Checkbox
                  checked={!checked.includes(false)}
                  onChange={handleChangeAll}
                  icon={<img src={checkBoxImage} style={{ ...checkboxStyle, width: "18px" }} />}
                  checkedIcon={
                    <img src={checkedBoxImage} style={{ ...checkboxStyle, width: "18px" }} />
                  }
                />
              }
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderTop: `1px solid var(--colorF3to3)`,
                marginTop: "25px",
                paddingTop: "25px",
              }}
              className='terms'
            >
              {termsList.map((item, index) => (
                <FormControlLabel
                  key={index}
                  label={
                    <div className='row-wrap'>
                      {item.text}
                      <span className='point-text'>
                        {item.require ? `(${t("required")})` : `(${t("optional")})`}
                      </span>
                      <div className='icon-wrap' onClick={() => console.log("click terms")}>
                        <img src={arrowRightImage} />
                      </div>
                    </div>
                  }
                  control={
                    <Checkbox
                      data-index={index}
                      checked={checked[index]}
                      onChange={() => handleChecked(index)}
                      icon={<img src={checkBoxImage} style={checkboxStyle} />}
                      checkedIcon={<img src={checkedBoxImage} style={checkboxStyle} />}
                    />
                  }
                />
              ))}
            </Box>
          </Box>
        </div>
        <section className='section'>
          <Button
            sx={{ borderRadius: "32px" }}
            disabled={isdisabled}
            variant='contained'
            size='large'
            className='submit'
            onClick={() => navigate("/signup/email")}
          >
            {/* 다음 */}
            {t("button.next")}
          </Button>
        </section>
        {isLottieShow ? (
          <div className='lottie-wrap'>
            <Lottie animationData={welcomeLottie} width='100%' className='lottie' />
          </div>
        ) : null}
      </Wrapper>
    </>
  );
}

export default React.memo(Join);
