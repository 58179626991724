import { Chip, Stack } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { COLORS, RADIUS, TEXTSIZE } from "../../../theme/constants";

const Wrapper = styled.div`
  .chip-wrap {
    .swiper {
      margin-left: 0;
    }
  }

  .chip-slide {
    width: fit-content !important;
  }

  .chip {
    border: 1px solid var(--bglightColor);
    background: var(--bgColor);

    span {
      color: var(--textColor);
    }

    .MuiChip-deleteIcon {
      font-size: ${TEXTSIZE.large};
      color: ${COLORS.gray_9};

      &:hover {
        color: var(--textColor);
      }
    }
  }
`;

function SwiperTags(props) {
  const { tags, selectedTag, isEdit = false, onClickTag, onDelete } = props;

  return (
    <Wrapper>
      <Stack className="chip-wrap" direction="row" spacing={1}>
        <Swiper
          style={{ marginBottom: "10px" }}
          spaceBetween={5}
          slidesPerView={3}
        >
          {tags.length > 0 &&
            tags.map((item) => (
              <SwiperSlide className="chip-slide" key={item}>
                {isEdit ? (
                  <Chip
                    label={item}
                    sx={{
                      width: "fit-content",
                      cursor: "pointer",
                      backgroundColor: COLORS.white,
                      color: COLORS.grey700,
                      fontSize: TEXTSIZE.medium,
                      textAlign: "center",
                      px: 1,
                      py: 2,
                      border: `1px solid ${COLORS.grey100}`,
                      borderRadius: RADIUS.xLarge,
                      "&:hover": {
                        backgroundColor: COLORS.white,
                        color: COLORS.grey700,
                        boxShadow: "none",
                      },
                    }}
                    onClick={() => onClickTag(item)}
                    onDelete={() => onDelete(item)}
                  />
                ) : (
                  <Chip
                    label={item}
                    sx={{
                      width: "fit-content",
                      cursor: "pointer",
                      color:
                        selectedTag === item ? COLORS.white : COLORS.grey700,
                      backgroundColor:
                        selectedTag === item ? COLORS.primary4 : COLORS.white,
                      fontSize: TEXTSIZE.medium,
                      textAlign: "center",
                      px: 1,
                      py: 2,
                      border: `1px solid ${COLORS.grey100}`,
                      borderRadius: RADIUS.xLarge,
                      "&:hover": {
                        color: COLORS.white,
                        backgroundColor: COLORS.primary4,
                        boxShadow: "none",
                      },
                    }}
                    onClick={() => onClickTag(item)}
                  />
                )}
              </SwiperSlide>
            ))}
        </Swiper>
      </Stack>
    </Wrapper>
  );
}

export default SwiperTags;
