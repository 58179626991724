import { Avatar, Button } from "@mui/material";
import React, { useEffect } from "react";
import naverImage from "../../assets/images/naver.png";

const NAVER_ID_SDK_URL =
  "https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2.js";

function NaverLoginComponent(props) {
  const initLoginButton = () => {
    // if (!("browser" in process)) {
    //   return;
    // }
    const { clientId, callbackUrl, onSuccess, onFailure } = props;

    const naver = window["naver"];

    const naverLogin = new naver.LoginWithNaverId({
      callbackUrl: `${callbackUrl}`,
      clientId,
      loginButton: { color: "green", type: 3, height: 60 },
      isPopup: false,
      //   callbackHandle: true,
    });

    naverLogin.init();

    if (!window.opener) {
      naver.successCallback = (data) => {
        onSuccess(data);
      };
      naver.failureCallback = onFailure;
    } else {
      naverLogin.getLoginStatus((status) => {
        if (status) {
          window.opener.naver.successCallback(naverLogin.user);
        } else {
          window.opener.failureCallback();
        }
        window.close();
      });
    }
  };

  const appendNaverButton = () => {
    if (document && document.querySelectorAll("#naverIdLogin").length === 0) {
      const naverId = document.createElement("div");
      naverId.id = "naverIdLogin";
      naverId.style.position = "absolute";
      naverId.style.top = "-10000px";
      document.body.appendChild(naverId);
    }
  };

  const loadScript = () => {
    if (
      document &&
      document.querySelectorAll("#naver-login-sdk").length === 0
    ) {
      const script = document.createElement("script");
      script.id = "naver-login-sdk";
      script.src = NAVER_ID_SDK_URL;
      script.onload = () => initLoginButton();
      document.head.appendChild(script);
    }
  };

  const handleClick = () => {
    if (!document || !document.querySelector("#naverIdLogin").firstChild)
      return;
    const naverLoginButton = document.querySelector("#naverIdLogin").firstChild;
    naverLoginButton.click();
  };

  const getData = () => {
    if (window.location.href.includes("access_token")) {
      console.log("We got AccessToken");
      const access_token = location.hash
        .split("access_token=")[1]
        .split("&state")[0];
      console.log("access_token naver: ", access_token);
    }
  };

  useEffect(() => {
    appendNaverButton();
    loadScript();
    // getData();
  }, []);

  //   return <Button onClick={handleClick}>Naver Login Component</Button>;
  return (
    <Avatar
      onClick={handleClick}
      sx={{ cursor: "pointer" }}
      alt="naver"
      src={naverImage}
    />
  );
}

export default NaverLoginComponent;
