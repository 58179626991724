import React from "react";
import { Box } from "@mui/material";
import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";
import backgroundUrl from "../../../assets/images/base_bg.png";

function RiveComponent(props) {
  const { src = "/riv/cosmos.riv" } = props;
  const { rive, RiveComponent } = useRive({
    src: src,
    // Be sure to specify the correct state machine (or animation) name
    // stateMachines: "Motion",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.FitWidth, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    autoplay: true,
  });

  return (
    <Box sx={{ width: "100%", height: "100%", position: "absolute", top: 0 }}>
      <RiveComponent />
      <Box
        sx={{
          backgroundImage: `url(${backgroundUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          opacity: 0.8,
        }}
      />
    </Box>
  );
}

export default RiveComponent;
