import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "swiper/css";
import { COLORS, TEXTSIZE } from "../../theme/constants";
import { getMarketList, getMarketProfile } from "@api";
import { useAuth } from "../../context/Auth";
import CircularComponent from "../process/CircularComponent";
import NoData from "../../components/NoData";
import { SearchContext } from "../../components/layouts/SearchFeedLayout";
import { handleError } from "@utils/commonFunction";
import dayjs from "dayjs";
import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import MarketSearchList from "./MarketSearchList";
import { TabContext, TabPanel } from "@mui/lab";
import SearchUserList from "../search/SearchUserList";
import RecentlyKeywords from "../search/RecentlyKeywords";
import RecommendTags from "../search/RecommendTags";
import SuggestionTags from "../search/SuggestionTags";

const Wrapper = styled.div`
  .search-feed__item {
    cursor: pointer;
  }

  .search-user {
    &__list {
    }

    &__item {
      padding: 0;
      cursor: pointer;

      .user-text {
        .MuiListItemText-primary {
          font-size: ${TEXTSIZE.medium};
          color: var(--textColor);
        }

        .MuiListItemText-secondary {
          font-size: ${TEXTSIZE.small};
          color: ${COLORS.gray_7};
        }
      }
    }
  }
`;

const LIMIT_POST = 16;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 150px);
  position: relative;
`;

const WrapperTabs = styled.div`
  width: 100%;
  .MuiTabs-scroller,
  .MuiTabs-root,
  .MuiButtonBase-root {
    min-height: 100%;
  }

  .MuiTabs-flexContainer {
    align-items: center;
    justify-content: space-around;
  }

  .tab-wrap {
    background: ${COLORS.white};
    .MuiButtonBase-root {
      max-width: 100%;
      color: var(--subTextColor);
      flex: 1 0 auto;
      padding: 8px 0;
      text-transform: capitalize;
      font-weight: 700;

      &.Mui-selected {
        color: ${COLORS.black1};
        .tab__icon {
          fill: ${COLORS.black1};
        }
      }
      .tab__icon {
        fill: ${COLORS.grey200};
      }
    }

    .MuiTabs-indicator {
      height: 3px;
      background-color: ${COLORS.black1};
    }
  }

  .tab-content__wrap {
    position: relative;

    .tab-content {
      height: 100%;
    }
  }
`;

function MarketSearch(props) {
  const { isSearch = true } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const {
    enterPress,
    searchKeyword,
    inputChange,
    setSearchParams,
    setSelectedPost,
    setSearchKeyword,
  } = useContext(SearchContext);

  //new state
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [activeTab, setActiveTab] = useState("post");
  let enterRef = useRef(null);

  const tabList = [
    {
      label: "콘텐츠",
      text: "Post",
      type: "post",
      data: [],
    },
    {
      label: "유저",
      text: "User",
      type: "user",
      data: [],
    },
  ];

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
    if (!searchKeyword) return;
    if (newValue == "user") {
      fetchMoreUserData({
        offset: 0,
      });
    } else {
      fetchMoreData({
        offset: 0,
      });
    }
    // setSearchParams({ tab: newValue });
  };

  //User begin
  const fetchMoreUserData = async (newRequestData = {}) => {
    try {
      if (userData.length == 0) {
        setIsLoading(true);
      }

      setIsSearching(true);

      const requestData = {
        keyword: searchKeyword,
        offset: userData.length,
        limit: LIMIT_POST,
        ...newRequestData,
      };

      const res = await getMarketProfile(requestData);

      if (!res?.list) return;
      const newPost = res.list;

      if (newRequestData?.offset === 0) {
        setUserData(newPost);
      } else {
        setUserData((prevState) => [...prevState, ...newPost]);
      }
    } catch (error) {
      handleError(error, logout, navigate);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickUser = (nickname) => {
    navigate(`/user-profile/${nickname}`);
  };
  //User end

  const setLocalRecently = (searchKeyword) => {
    const localKey = "recentlyKeywords";
    const localRecently = JSON.parse(localStorage.getItem(localKey)) || [];
    const found = localRecently.find((element) => element === searchKeyword);
    if (found) {
      return;
    }
    localRecently.unshift(searchKeyword);
    localStorage.setItem(localKey, JSON.stringify(localRecently));
  };

  const fetchMoreData = async (newRequestData = {}) => {
    try {
      // if (searchKeyword === currentSearchKeyword) return;

      if (data.length == 0) {
        setIsLoading(true);
      }

      setIsSearching(true);

      const requestData = {
        keyword: searchKeyword,
        offset: data.length,
        limit: LIMIT_POST,
        // sort_by: SORT_BY.LATEST,
        ...newRequestData,
      };

      setSearchParams({ ...requestData });

      const res = await getMarketList(requestData);

      if (res?.list) {
        const newPost = res.list;
        if (newRequestData.offset == 0) {
          setData(newPost);
        } else {
          setData((prevState) => [...prevState, ...newPost]);
        }
      }
    } catch (error) {
      handleError(error, logout, navigate);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickPost = (id, index) => {
    setSelectedPost({
      keyword: searchKeyword,
      // sort_by: SORT_BY.LATEST,
      id: id,
      index: index,
    });
    navigate(`/search/details/keyword`);
  };

  const onClickTag = (value) => {
    const requestData = {
      keyword: value,
      offset: 0,
    };
    setSearchKeyword(value);
    setIsSearching(true);
    fetchMoreData(requestData);
    fetchMoreUserData(requestData);
    setLocalRecently(value);
  };

  useEffect(() => {
    // logEvent(analytics, "screen_view", {
    //   firebase_screen: isSearch ? "search" : "explore",
    //   firebase_screen_class: isSearch ? "search" : "explore",
    // });
    enterRef.current = dayjs();
  }, []);

  useEffect(() => {
    if (enterPress > 0 && searchKeyword != "") {
      if (dayjs().diff(enterRef.current) > 180) {
        enterRef.current = dayjs();

        fetchMoreData({
          offset: 0,
        });
      }
    } else {
      setIsSearching(false);
    }
  }, [enterPress]);

  useEffect(() => {
    setIsSearching(false);
  }, [inputChange]);

  if (isLoading)
    return (
      <HeightWhenEmpty>
        <CircularComponent />
      </HeightWhenEmpty>
    );

  return (
    <Wrapper>
      {!isSearching && !isLoading && !searchKeyword && (
        <>
          <RecentlyKeywords onClickTag={onClickTag} isSearch={isSearch} />
          <RecommendTags onClickTag={onClickTag} isSearch={isSearch} />
        </>
      )}

      {searchKeyword && !isSearching && !isLoading && (
        <SuggestionTags onClickTag={onClickTag} keyword={searchKeyword} />
      )}
      {isSearching && !isLoading && (
        <>
          <TabContext value={activeTab}>
            <Box
              sx={{
                position: "sticky",
                top: -1,
                zIndex: "10",
                backgroundColor: COLORS.white,
              }}
            >
              <WrapperTabs>
                <Tabs
                  value={activeTab}
                  onChange={handleChangeTab}
                  aria-label="tabs"
                  className="tab-wrap"
                >
                  {tabList.map((item, index) => (
                    <Tab
                      sx={{ minWidth: "24px" }}
                      value={item.type}
                      data-label={item.label}
                      label={item.label}
                      key={index}
                    />
                  ))}
                </Tabs>
              </WrapperTabs>
            </Box>
            <TabPanel value={"post"}>
              {isSearching && !isLoading && (
                <>
                  {data.length > 0 ? (
                    <MarketSearchList
                      data={data}
                      fetchMoreData={fetchMoreData}
                      onClickPost={handleClickPost}
                    />
                  ) : (
                    <HeightWhenEmpty>
                      <NoData
                        text={t("no_results_were_found_for_your_search")}
                      />
                    </HeightWhenEmpty>
                  )}
                </>
              )}
            </TabPanel>
            <TabPanel value={"user"}>
              {isSearching && !isLoading && (
                <>
                  {userData.length > 0 ? (
                    <SearchUserList
                      data={[...userData]}
                      fetchMoreData={fetchMoreUserData}
                      fetchData={() => {
                        fetchMoreUserData({
                          offset: 0,
                        });
                      }}
                      onClickPost={handleClickUser}
                    />
                  ) : (
                    <HeightWhenEmpty>
                      <NoData
                        text={t("no_results_were_found_for_your_search")}
                      />
                    </HeightWhenEmpty>
                  )}
                </>
              )}
            </TabPanel>
          </TabContext>
        </>
      )}
    </Wrapper>
  );
}

export default React.memo(MarketSearch);
