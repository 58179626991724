import React, { useEffect, useState } from "react";
import MemberNotConnected from "./MemberNotConnected";
import MemberConnected from "./MemberConnected";
import { useAuth } from "../../context/Auth";
import { useNavigate, useParams } from "react-router-dom";
import { handleError, isMine } from "../../utils/commonFunction";
import { getUserUnlockOrDonate } from "../../api";
import ItemMemberConnected from "./ItemMemberConnected";
import ListMemberNotConnected from "./ListMemberNotConnected";
import styled from "styled-components";
import { WIDTH } from "../../theme/constants";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    // margin-left: 100px;
    max-width: 600px;
  }
`;

function Sponsors(props) {
  const { type } = props;
  const { logout } = useAuth();
  const { postID } = useParams();
  const navigate = useNavigate();
  const [dataNotConnected, setDataNotConnected] = useState([]);
  const [dataConnected, setDataConnected] = useState([]);

  const fetchMoreDataNotConnected = async (newRequestParam) => {
    try {
      const requestParam = {
        type: type,
        connect: false,
        limit: 10,
        offset: dataNotConnected.length,
        ...newRequestParam,
      };
      const res = await getUserUnlockOrDonate(postID, requestParam);
      if (!res?.list) return;
      if (newRequestParam?.offset === 0) {
        return res.list;
      } else {
        setDataNotConnected((prevState) => [...prevState, ...res.list]);
        const prevState = [...dataNotConnected];
        return [...prevState, ...res.list];
      }
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const fetchMoreDataConnected = async (newRequestParam) => {
    try {
      const requestParam = {
        type: type,
        connect: true,
        limit: 10,
        offset: dataConnected.length,
        ...newRequestParam,
      };
      const res = await getUserUnlockOrDonate(postID, requestParam);
      if (!res?.list) return;

      if (newRequestParam?.offset === 0) {
        return res.list;
      } else {
        setDataConnected((prevState) => [...prevState, ...res.list]);
        const prevState = [...dataConnected];
        return [...prevState, ...res.list];
      }
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const handleRefreshData = async () => {
    const [dataNotConnected, dataConnected] = await Promise.all([
      fetchMoreDataNotConnected({ offset: 0 }),
      fetchMoreDataConnected({
        offset: 0,
      }),
    ]);
    setDataNotConnected(dataNotConnected);
    setDataConnected(dataConnected);
  };

  const removeFirstItem = (arr = []) => {
    const arrTmp = [...arr];
    if (!isMine(arr[0]?.user_id)) return arrTmp;
    arrTmp.shift();
    return arrTmp;
  };

  useEffect(() => {
    handleRefreshData();
  }, []);

  return (
    <Wrapper>
      {dataNotConnected?.length > 0 &&
        isMine(dataNotConnected[0]?.user_id) && (
          <ItemMemberConnected {...dataNotConnected[0]} isMore={false} />
        )}
      {removeFirstItem(dataNotConnected)?.length > 0 &&
        dataConnected?.length === 0 && (
          <ListMemberNotConnected
            isTitle={isMine(dataNotConnected[0]?.user_id)}
            type={type}
            data={removeFirstItem(dataNotConnected)}
            fetchMoreData={() => fetchMoreDataNotConnected()}
            fetchData={handleRefreshData}
          />
        )}
      {removeFirstItem(dataNotConnected)?.length > 0 &&
        dataConnected?.length > 0 && (
          <MemberNotConnected
            type={type}
            data={removeFirstItem(dataNotConnected)}
            fetchData={handleRefreshData}
            fetchMoreData={() => fetchMoreDataNotConnected()}
          />
        )}
      {dataConnected?.length > 0 && (
        <MemberConnected
          data={dataConnected}
          isTitle={dataNotConnected?.length > 0}
          fetchMoreData={() => fetchMoreDataConnected()}
        />
      )}
    </Wrapper>
  );
}

export default Sponsors;
