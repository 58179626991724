import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  COLORS,
  FONT_WEIGHT,
  ICON_SIZE,
  LINE_HEIGHT,
  RADIUS,
  TEXTSIZE,
} from "../../theme/constants";
import {
  textSmallMuiStyle,
  textMediumMuiStyle,
  buttonMuiStyle,
  truncateStyle,
} from "../../utils/commonStyle";
import { createConnection, deleteConnection, unblockUser } from "../../api";
import { toast } from "react-toastify";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { Link, useNavigate } from "react-router-dom";
import ModalComponent from "../../components/modal/ModalComponent";
import backgroundSpace from "../../assets/images/bg_space.jpg";
import { dateTimeFormat } from "../../utils/dateTimeFormat";
import dayjs from "dayjs";
import Message from "./Message";

const Wrapper = styled.div``;

const WrapperImg = styled.img`
  aspect-ratio: 1;
  border-radius: 4px;
  width: 36px;
  height: 36px;
`;

function MessageItem(props) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const {
    user_id = "",
    nickname = "",
    introduce = "",
    profile_image_url = "",
    index = 0,

    messages = [],
    status="",
    isDeleted = false,
    timestamp = "",
    content = "",
    system = false,
    type = "message",
    pinned = false,
    attachments = [],
    stickers = [],
    mentions = null,
    privateChatId = "",
    receiver = {
      id: "",
      externalId: "",
      app: "",
      system: false,
      globalName: "",
      avatar: {
        avatarURL: "",
      },
    },
    isReaded = false,
    id = "",
    author = {
      id: "",
      externalId: "",
      app: "",
      system: false,
      globalName: "",
      avatar: {
        avatarURL: "",
      },
      email: "",
    },
    createdTimestamp,
    localUser,
    lastMessage,
  } = props;

  const [modalData, setModalData] = useState({
    open: false,
    type: "",
    btnOKText: "",
    btnCancelText: "",
    title: "",
    subTitle: "",
    content: "",
  });

  const redirectToProfile = () => {
    // navigate(`/user-profile/${nickname}`);
  };

  //modal action
  const handleOk = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleCancel = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
  };

  return (
    <>
      <Message
        typePosition={author?.externalId && author?.externalId !== localUser?.id ? "left" : "right"}
        hasAfter={
          author?.externalId === messages[index - 1]?.author?.externalId
            ? true
            : false
        }
        isSameTimeDay={
          dayjs(createdTimestamp).isSame(
            messages[index - 1]?.createdTimestamp,
            "day"
          ) && messages[index + 1]
        }
        isSameTimeMinute={dayjs(createdTimestamp).isSame(
          messages[index - 1]?.createdTimestamp,
          "minute"
        )}
        createdTimestamp={createdTimestamp}
        avatarURL={author?.avatar?.avatarURL}
        content={content}
        type={type}
        attachments={attachments}
        status={status}
      />
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => handleClose()}
        handleOk={() => handleOk()}
        handleCancel={() => handleCancel()}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
    </>
  );
}

export default MessageItem;
