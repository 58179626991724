import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import DrawerListComponent from "./DrawerListComponent";
import { ReactComponent as DeleteIcon } from "@icons/delete.svg";
import { ReactComponent as ReportIcon } from "@icons/report.svg";
import { ReactComponent as BlockIcon } from "@icons/block.svg";
import { useTranslation } from "react-i18next";

function CommentDrawer({ close, data, handleModal, isMinePost }) {
  // const navigate = useNavigate();
  const { user } = useAuth();
  const [modalData, setModalData] = useState([]);
  const { t } = useTranslation();

  const isMineComment = user.id === data.user_profile.user_id;

  const reportItem = {
    text: t("button.report"),
    icon: <ReportIcon />,
    fn: () => {
      handleModal("report");
      close();
    },
  };

  const blockItem = {
    text: t("button.block"),
    icon: <BlockIcon />,
    fn: () => {
      handleModal("block");
      close();
    },
  };

  const deleteItem = {
    text: t("button.delete"),
    icon: <DeleteIcon />,
    fn: () => {
      handleModal("delete");
      close();
    },
  };

  useEffect(() => {
    const prevData = [];
    if (isMinePost && !isMineComment) {
      prevData.push(reportItem, blockItem, deleteItem);
    } else if (isMineComment) {
      prevData.push(deleteItem);
    } else {
      prevData.push(reportItem, blockItem);
    }
    setModalData(prevData);
  }, []);

  return <DrawerListComponent close={close} modalData={modalData} />;
}

export default React.memo(CommentDrawer);
