import React, { useContext, useState } from "react";
import styled from "styled-components";
import { HEIGHT, RADIUS, WIDTH } from "@theme/constants";
import { getUserCategories } from "@api";
import SearchSkeleton from "@components/layouts/skeleton/SearchSkeleton";
import { useEffect } from "react";
import NoData from "@components/NoData";
import { Link, useNavigate } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Box } from "@mui/material";
import SearchPostList from "@containers/search/SearchPostList";
import { handleError, handleSetImageSize, removeEmpty } from "../utils/commonFunction";
import { useAuth } from "../context/Auth";
import { useMediaQuery } from "react-responsive";
import Categories from "../components/categories/Categories";
import { useTranslation } from "react-i18next";
import { getHotCreator, searchPosts } from "../api";
import { SORT_BY } from "../theme/constants";
import HotCreator from "../components/newComponents/posts/Hot/HotCreator";
import FloatingButtonNew from "../containers/quiz/FloatingButtonNew";
import { ReactComponent as SearchIcon } from "@icons/search.svg";
import { GlobalStateContext } from "../context/GlobalStateProvider";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

const Section = styled.section`
  .nodata {
    min-height: ${HEIGHT.content};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* overflow-y: auto; */

  .img-list {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-auto-flow: dense; */
  }

  .img-wrap {
    width: 100%;
    position: relative;
    border-radius: ${RADIUS.medium};
    overflow: hidden;
    cursor: pointer;

    &:after {
      display: block;
      content: "";
      padding-bottom: 100%;
    }

    img,
    video {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    video {
      object-fit: cover;
    }
  }

  .video-icon {
    position: absolute;
    fill: white;
    top: 8px;
    right: 8px;
  }

  .MuiImageListItem-root {
    cursor: pointer;
  }

  .chip-wrap {
    .swiper {
      margin-left: 0;
    }
  }

  .chip-slide {
    width: fit-content !important;
  }
`;

const LIMIT_POST = 16;

function Search() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const analytics = getAnalytics();
  const { searchQuery, setSearchQuery, setSelectedPost } = useContext(GlobalStateContext);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [hotPosts, setHotPosts] = useState([]);

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const isTabletCustom = useMediaQuery({
    query: `(min-width: 458px)`,
  });

  const getHotPostByTag = async (newRequestData) => {
    try {
      const requestData = {
        offset: 0,
        limit: 5,
        ...newRequestData,
      };
      const res = await getHotCreator(requestData);

      if (res?.list) {
        const newPost = res.list;
        if (requestData.offset == 0) {
          setHotPosts(newPost);
        } else {
          setHotPosts((prevState) => [...prevState, ...newPost]);
        }
      }
    } catch (error) {
      handleError(error, logout);
    }
  };

  const fetchMoreData = async (requestData) => {
    try {
      if (requestData.offset == 0) {
        setIsLoading(true);
      }
      setSearchQuery({ ...requestData });
      const res = await searchPosts(removeEmpty(requestData));

      if (res?.list) {
        const newPost = handleSetImageSize(res.list);
        if (requestData.offset == 0) {
          setData(newPost);
        } else {
          setData((prevState) => [...prevState, ...newPost]);
        }
      }
      setIsLoading(false);
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const handleUserGetCategories = async () => {
    try {
      const res = await getUserCategories();
      if (res?.list) {
        const resTmp = [...res.list];
        const all = {
          id: "",
          icon: "",
          name: "전체",
        };
        resTmp.unshift(all);
        setCategories(resTmp);
      }
    } catch (error) {
      handleError(error, logout);
    }
  };

  const onClickTag = (value) => {
    const requestData = {
      category_id: value === "전체" ? "" : value,
      offset: 0,
      limit: LIMIT_POST,
      sort_by: SORT_BY.POPULAR,
    };
    setData([]);
    setHotPosts({});
    fetchMoreData(requestData);
    if (value) {
      const requestHotData = {
        offset: 0,
        category_id: value,
      };
      getHotPostByTag(requestHotData);
    }
  };

  const handleClickPost = (id, type, index) => {
    setSelectedPost({
      category_id: searchQuery?.category_id,
      sort_by: searchQuery?.sort_by,
      id: id,
      index: index,
    });

    if (type == 3) {
      navigate(`/quiz/${id}`);
      return;
    }
    navigate(`/search/details/category`);
  };

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "search",
      firebase_screen_class: "search",
    });
    const requestData = {
      category_id: searchQuery?.category_id || "",
      offset: 0,
      sort_by: "popular",
      limit: LIMIT_POST,
    };
    fetchMoreData(requestData);
    if (searchQuery?.category_id) {
      getHotPostByTag({ category_id: searchQuery?.category_id });
    }
    handleUserGetCategories();
  }, []);

  return (
    <>
      <FloatingButtonNew />
      <Wrapper id='page-content'>
        <Box
          sx={{
            width: `100%`,
            background: "white",
            zIndex: 10,
            margin: "auto",
          }}
          style={{
            top: "56px",
            position: "sticky",
          }}
        >
          <Box sx={{ my: 1, display: "flex", alignItems: "center", gap: "8px" }}>
            <Link
              to={"/search/feed"}
              style={{
                mt: "-6px",
                cursor: "pointer",
              }}
            >
              <SearchIcon
                style={{
                  height: "28px",
                  width: "28px",
                }}
              />
            </Link>
            <Categories
              selectedTag={searchQuery?.category_id}
              tags={categories}
              onClickTag={onClickTag}
              redirect={"/search"}
            />
          </Box>
        </Box>
        <Section
          className='search'
          style={{
            marginBottom: isPc ? "0px" : isTabletCustom ? "90px" : "70px",
            maxWidth: WIDTH.fold,
            margin: "auto",
          }}
        >
          {isLoading ? (
            <SearchSkeleton />
          ) : (
            <>
              {hotPosts.length > 0 && (
                <HotCreator
                  data={hotPosts}
                  categoryId={searchQuery?.category_id}
                  fetchMoreData={() => {
                    const requestHotData = {
                      offset: hotPosts.length,
                      category_id: searchQuery?.category_id,
                    };
                    getHotPostByTag(requestHotData);
                  }}
                />
              )}
              {data.length > 0 ? (
                <Box sx={{ px: 0 }}>
                  <SearchPostList
                    data={data}
                    onClickPost={handleClickPost}
                    fetchMoreData={() => {
                      fetchMoreData({
                        category_id:
                          searchQuery?.category_id === "전체" ? "" : searchQuery?.category_id,
                        offset: data.length,
                        limit: LIMIT_POST,
                        sort_by: searchQuery?.sort_by,
                      });
                    }}
                  />
                </Box>
              ) : (
                <NoData text={t("no_results_were_found_for_your_search")} />
              )}
            </>
          )}
        </Section>
      </Wrapper>
    </>
  );
}

export default React.memo(Search);
