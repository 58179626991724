import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { COLORS, FONT_WEIGHT } from "../../theme/constants";
import { getExplore, getRecommendUser, getTagsExplore } from "../../api";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import {
  textMediumMuiStyle,
  textSmallMuiStyle,
} from "../../utils/commonStyle";
import { dateTimeKorean } from "../../utils/dateTimeFormat";

function RecommendTags(props) {
  const { onClickTag, isSearch = false } = props;
  const { logout } = useAuth();
  const [surgeTags, setSurgeTags] = useState([]);
  const [latestTime, setLatestTime] = useState("");

  const handleGetTags = async () => {
    try {
      const res = await getTagsExplore();

      if (res?.surgeTags) {
        const tmpRes = [...res.surgeTags];
        setSurgeTags(tmpRes);
      }

      if (res?.latestTime) {
        setLatestTime(dateTimeKorean(res.latestTime));
      }
    } catch (error) {
      handleError(error, logout);
    }
  };

  const handleGetRecommendUser = async () => {
    try {
      const res = await getExplore({}, "recommend");
      setLatestTime("");
      if (res?.recommended) {
        const tmpRes = [...res.recommended];
        setSurgeTags(tmpRes);
      }
    } catch (error) {
      handleError(error, logout);
    }
  };

  useEffect(() => {
    isSearch ? handleGetTags() : handleGetRecommendUser();
  }, [isSearch]);

  return (
    <div>
      {surgeTags?.length ? (
        <Box sx={{ mb: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography
              sx={{
                ...textMediumMuiStyle,
                fontWeight: FONT_WEIGHT.xxLarge,
              }}
            >
              {isSearch ? "실시간 급상승" : "추천 유저"}
            </Typography>

            <Typography sx={textSmallMuiStyle}>{latestTime}</Typography>
          </Box>
          <Box>
            {surgeTags?.length > 0 &&
              surgeTags.map((item, index) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    py: 0.5,
                    cursor: "pointer",
                  }}
                  onClick={() => onClickTag(item)}
                  key={item}
                >
                  <Typography
                    sx={{
                      ...textMediumMuiStyle,
                      fontWeight: 700,
                      color: index === 0 && COLORS.primary4,
                      mr: 1,
                    }}
                  >
                    {index + 1}
                  </Typography>
                  <Typography sx={textMediumMuiStyle}>{item}</Typography>
                </Box>
              ))}
          </Box>
        </Box>
      ) : null}
    </div>
  );
}

export default RecommendTags;
