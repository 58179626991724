import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { COLORS, HEIGHT, RADIUS, TEXTSIZE } from "../theme/constants";
import { ReactComponent as LOGODARK } from "../assets/svgs/logo-dark.svg";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/Auth";

const Wrapper = styled.div`
  position: relative;
  height: ${HEIGHT.noNavigation};
  width: 100%;
  color: ${COLORS.white};
  display: flex;
  justify-content: center;

  .logo-title {
    position: absolute;
    top: 20%;
  }

  .user-information {
    position: absolute;
    top: 50%;
  }
`;

function WelcomeSignUp() {
  const navigate = useNavigate();
  // const [nickname, setNickName] = useState("");
  const { user } = useAuth();

  // useEffect(() => {
  //   const userLocalStorage = JSON.parse(localStorage.getItem("user"));
  //   setNickName(userLocalStorage?.nickname);
  // }, []);

  return (
    <Wrapper>
      <Box className='logo-title'>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 2,
          }}
        >
          {/* {theme === "dark" ? <LOGODARK /> : <LOGOLIGHT />} */}
          <LOGODARK />
        </Box>
        <Typography
          sx={{
            color: COLORS.grey200,
            fontSize: TEXTSIZE.medium,
            textAlign: "center",
          }}
        >
          콘텐츠로 만드는 당신만의 세상
        </Typography>
      </Box>
      <Box className='user-information'>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              color: COLORS.white,
              fontSize: TEXTSIZE.large,
              textAlign: "center",
            }}
          >
            {user?.profile?.nickname} 님
          </Typography>
          <Typography
            sx={{
              color: COLORS.white,
              fontSize: TEXTSIZE.large,
              textAlign: "center",
            }}
          >
            피카버스 가입을 환영합니다!
          </Typography>
        </Box>
      </Box>
      <Button
        sx={{
          p: 2,
          position: "absolute",
          bottom: "0px",
          borderRadius: RADIUS.xxxLarge,
          width: "100%",
          backgroundColor: COLORS.white,
          color: COLORS.grey900,
          boxShadow: "none",
          "&:hover": {
            backgroundColor: COLORS.white,
            color: COLORS.grey900,
            boxShadow: "none",
          },
          maxWidth: "442px",
        }}
        variant='contained'
        size='large'
        className='submit'
        onClick={() => navigate("/signup/connect")}
      >
        피카버스 시작하기
      </Button>
    </Wrapper>
  );
}

export default WelcomeSignUp;
