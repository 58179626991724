import { useInfiniteQuery } from "@tanstack/react-query";
import { getConnecting } from "../../api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import { handleError } from "../../utils/commonFunction";
import { useCallback, useEffect, useState } from "react";

function useGetNewChatUsers() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const [filters, setFilters] = useState({ keyword: "" });
  const [users, setUsers] = useState([]);

  const handleChangeFilters = useCallback((filterParams) => {
    setFilters((_prev) => filterParams);
  }, []);

  const fetchData = async ({ pageParam, queryKey }) => {
    try {
      const res = await getConnecting({ limit: 20, offset: pageParam });
      return res;
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["new-chat-users", filters],
    queryFn: fetchData,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.meta?.offset_next;
      // if (!lastPage) return;
      // const { pagination = {} } = lastPage || {};
      // const { page = 1, pageSize = 10, total = 0 } = pagination;
      // const nextPage = page + 1;
      // const totalPage = Math.ceil(total / pageSize);
      // return nextPage <= totalPage ? nextPage : undefined;
    },
  });

  useEffect(() => {
    const newData = [];
    if (!data?.pages?.[0]) return;
    data?.pages?.forEach((item) => {
      if (item?.list?.length > 0) {
        const newUsers = structuredClone(item?.list);
        newData.push(...newUsers);
      }
    });
    setUsers(newData);
  }, [data]);

  // useEffect(() => {
  //   const newUsers = [];
  //   if (!data?.pages?.[0]) return;
  //   data?.pages?.forEach(({ items }) => {
  //     newUsers.push(...items);
  //   });
  //   setUsers(newUsers);
  // }, [data]);

  // useEffect(() => {
  //   console.log("user", users);
  // }, [users]);

  return {
    data: users,
    isLoading,
    filters,
    fetchNextPage,
    handleChangeFilters,
  };
}

export default useGetNewChatUsers;
