import React, { useEffect } from "react";
import { Box, ImageList, ImageListItem } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { animateScroll } from "react-scroll";
import { RADIUS, WIDTH } from "../../theme/constants";
import { useMediaQuery } from "react-responsive";
import useWindowDimensions from "../../hooks/useWindownDimensions";
import ImageDefault from "../../assets/images/default_image.jpg";
import MyFeedMediaVideoViewOnly from "../../components/newComponents/posts/MyFeed/MyFeedMediaVideoViewOnly";
import Overlay from "../../components/overlay/Overlay";

// function srcset(image, size, rows = 1, cols = 1) {
//   return {
//     src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
//     srcSet: `${image}?w=${size * cols}&h=${
//       size * rows
//     }&fit=crop&auto=format&dpr=2 2x`,
//   };
// }

function SearchPostList(props) {
  const { height, width } = useWindowDimensions();
  const { data, onClickPost, fetchMoreData } = props;

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const isTablet = useMediaQuery({
    query: `(min-width: ${WIDTH.tablet})`,
    query: `(max-width: ${WIDTH.desktop1024})`,
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${WIDTH.mobile})`,
  });

  const getRowHeight = () => {
    let result = (width - 32) / 3;
    if (isMobile) {
      result = (width - 32) / 3;
    } else if (isTablet) {
      result = (width - 32) / 4;
    } else if (isPc) {
      result = (794 - 32) / 4;
    }
    return result;
  };

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMoreData}
      hasMore={true}
      // scrollableTarget='scrollableContent'
    >
      <ImageList
        // sx={{ overflow: "hidden" }}
        variant='quilted'
        cols={isMobile ? 3 : 4}
        rowHeight={getRowHeight()}
        gap={10}
        sx={{ overflow: "hidden" }}
      >
        {data.map((item, index) => (
          <ImageListItem
            id={index}
            key={item.id}
            cols={item?.cols || 1}
            rows={item?.rows || 1}
            onClick={() => onClickPost(item.id, item?.type, index)}
          >
            {item.files?.[0]?.type === "video" ? (
              <MyFeedMediaVideoViewOnly data={item.files?.[0]} onClickMedia={() => {}} />
            ) : (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: RADIUS.medium,
                  position: "relative",
                }}
              >
                <img
                  style={{
                    borderRadius: RADIUS.medium,
                    width: "100%",
                    height: "100%",
                  }}
                  src={
                    !item.files?.[0]
                      ? ImageDefault
                      : item.files?.[0]?.view_url || item.files?.[0]?.url
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = ImageDefault;
                  }}
                  alt={item.caption}
                  loading='lazy'
                />
                <Overlay
                  styleCustomize={{
                    borderRadius: RADIUS.medium,
                  }}
                />
              </Box>
            )}
          </ImageListItem>
        ))}
      </ImageList>
    </InfiniteScroll>
  );
}

export default SearchPostList;
