import { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Box } from "@mui/material";
import { COLORS, HEADER_LEFT_TYPE, HEADER_CENTER_TYPE, WIDTH } from "@theme/constants";
import Navigation from "@containers/navigations/Navigation";
import { useAuth } from "../../context/Auth";
import NavigationTablet from "../../containers/navigations/NavigationTablet";
import NavigateDesktop from "../../containers/navigations/NavigateDesktop";
import CommonHeader from "./header/CommonHeader";
import BaseButtonScrollTopNew from "../buttonScrollTop/BaseButtonScrollTopNew";
import { handleError } from "../../utils/commonFunction";
import { GlobalStateContext } from "../../context/GlobalStateProvider";
import BaseFooterNew from "../../containers/footers/BaseFooterNew"

function CommonLayout(props) {
  const {
    isNavigation = false,
    isSideBar = false,
    isFooter = false,
    isHeader = true,
    isScrollTop = true,
    isGuideline = true,
    leftType = "",
    centerType = "",
    rightType = "",
    pageTitle = "",
    titleStyle = {},
    widthDesktop = WIDTH.fold,
    backgroundStyle = {},
    childContentStyle = {},
  } = props;

  const { logout, setProfile } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const contentRef = useRef(null);
  const navRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  const { globalState } = useContext(GlobalStateContext);

  const isMobileWithoutFold = useMediaQuery({
    query: `(max-width: ${WIDTH.fold_from})`,
  });

  // 516-674
  const isFold = useMediaQuery({ minWidth: 516, maxWidth: 674 });
  // const isFold = useMediaQuery({
  //   query: `(min-width: ${WIDTH.fold_from})`,
  // });

  const isShowNavigateTablet = useMediaQuery({ minWidth: 516, maxWidth: 1200 });

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const isMax500 = useMediaQuery({
    query: `(max-width: 500px)`,
  });

  const handleGetUserData = async () => {
    if (location?.pathname === "/login/account") return;
    try {
      const res = await getUserProfile();
      if (!res) return;
      const user = {
        ...res,
        profile: {
          ...res?.user_profile,
        },
      };
      setProfile(user);
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const styleContentByNavbar = (left) => {
    const styleLeft = {
      pl:
        isMobileWithoutFold || !isSideBar
          ? "0px"
          : isShowNavigateTablet && isNavigation
          ? "80px"
          : isPc
          ? "300px"
          : 0,
    };

    const styleRight = {
      pr: isShowNavigateTablet && isNavigation ? "80px" : 0,
      pl: isPc ? "300px" : 0,
    };

    return !left ? styleRight : styleLeft;
  };

  useEffect(() => {
    handleGetUserData();
  }, []);

  return (
    <>
      {isNavigation && isShowNavigateTablet && <NavigationTablet ref={navRef} />}
      {!isPc && isNavigation && isMobileWithoutFold && <Navigation ref={navRef} />}
      <Box
        sx={{
          maxWidth: "1200px",
          mx: "auto",
        }}
        id={"scrollableContent"}
        // onScroll={handleScroll}
        // ref={containerRef}
      >
        {/* header */}
        {isHeader && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              width: "100%",
              zIndex: 100,
              maxWidth: "1200px",
            }}
          >
            <CommonHeader
              leftType={leftType || HEADER_LEFT_TYPE.ARROW}
              centerType={centerType || HEADER_CENTER_TYPE.TITLE}
              titleStyle={{
                ...titleStyle,
                color: COLORS.black1,
              }}
              rightType={rightType}
              pageTitle={pageTitle}
            />
          </Box>
        )}
        {/* sidebar */}
        {isSideBar && (
          <Box
            sx={{
              position: "fixed",
              top: "56px",
              width: "100%",
              zIndex: 100,
              maxWidth: "300px",
            }}
          >
            <NavigateDesktop isGuideline={isGuideline} />
          </Box>
        )}
        {/* content */}
        <Box
          sx={{
            width: "100%",
            maxWidth: "1200px",
            mx: isPc ? "0" : "auto",
            pt: isHeader ? "56px" : 0,
            pb: !isHeader ? 0 : isPc ? "180px" : "100px",
            ...styleContentByNavbar(globalState?.isNavbarLeft),
          }}
          ref={contentRef}
          id='main-content'
        >
          <Outlet />
        </Box>
        {isScrollTop && (
          <BaseButtonScrollTopNew
            styleButtonProps={{}}
            isDarkMode={false}
            isScrolling={isScrolling}
            isSideBar={isSideBar}
            isFooter={isFooter}
          />
        )}
        {/* footer */}
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            zIndex: 100,
            maxWidth: "1200px",
            maxHeight: "180px",
          }}
        >
         {/*  <BaseFooterNew contentWidth={contentSize.width} isSideBar={isSideBar} /> */}
       
        </Box>
      </Box>
    </>
  );
}

export default CommonLayout;
