import { useQuery } from "@tanstack/react-query";
// import { getBoxChat } from "../../api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import { handleError } from "../../utils/commonFunction";
import { getBoxChat } from "../../service/chat_services";

function useGetConversation(props) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { receiverID } = props;

  const handleGetBoxChat = async () => {
    try {
      const res = await getBoxChat(receiverID, {});
      return res;
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["box-chat"],
    queryFn: handleGetBoxChat,
  });

  return {
    isLoading,
    data,
  };
}

export default useGetConversation;
