import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "react-bubble-ui/dist/index.css";
import {
  COLORS,
  FONT_WEIGHT,
  LINE_HEIGHT,
  TEXTSIZE,
  WIDTH,
} from "../theme/constants";
import { ReactComponent as Refresh } from "../assets/svgs/refresh.svg";
import { useNavigate } from "react-router-dom";
import { getExplore } from "../api";
import { useAuth } from "../context/Auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Box, Typography } from "@mui/material";
import BubbleSkeleton from "../components/layouts/skeleton/Bubble";
import NoData from "../components/NoData";
import { handleError } from "../utils/commonFunction";
import ExploreBubbles from "../containers/explore/ExploreBubbles";
import NetCharts from "../containers/charts/NetCharts";
import { useMediaQuery } from "react-responsive";
import marketAlienImage from "@images/market_alien.png";

const Wrapper = styled.div`
  position: relative;
`;
const windowWidth = document.body.offsetWidth;

function Explore() {
  const navigate = useNavigate();
  const analytics = getAnalytics();
  const { logout } = useAuth();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (newRequestData = {}) => {
    try {
      if (data.length == 0) {
        setIsLoading(true);
      }

      const requestData = {
        offset: data.length,
        limit: 50,
        ...newRequestData,
      };

      const res = await getExplore(requestData, "profile");
      if (!res?.list) return;

      if (newRequestData?.offset === 0) {
        setData(res.list);
      } else {
        setData((prevState) => [...prevState, ...res.list]);
      }
    } catch (error) {
      handleError(error, logout);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReload = () => {
    // navigate(0);
    fetchData({ offset: 0 });
  };

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "explore",
      firebase_screen_class: "explore",
    });
    fetchData({ offset: 0 });
  }, []);

  const options = {
    size: 100,
    minSize: 30,
    gutter: 8,
    provideProps: true,
    numCols: 6,
    fringeWidth: 45,
    yRadius: 100,
    xRadius: 100,
    cornerRadius: 50,
    showGuides: false,
    compact: true,
    gravitation: 5,
  };

  // if (isLoading) return <BubbleSkeleton />;

  const isMobileWithoutFold = useMediaQuery({
    query: `(max-width: ${WIDTH.fold})`,
  });

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  return (
    <Box
      sx={{
        maxWidth: WIDTH.fold,
        margin: "auto",
      }}
    >
      {/* <Box sx={{ display: "flex", my: 1 }}>
        <Typography
          sx={{
            fontSize: TEXTSIZE.xLarge,
            fontWeight: FONT_WEIGHT.xxLarge,
            lineHeight: LINE_HEIGHT.xxxLarge,
            color: COLORS.white,
          }}
        >
          프로필 탐색하기
        </Typography>
        <Refresh
          style={{ fill: COLORS.grey500, cursor: "pointer" }}
          onClick={handleReload}
        />
      </Box> */}

      {/* {data.length > 0 ? ( */}
      {/* // <ExploreBubbles data={data} />
        // <NetCharts data={data}/> */}
      <Box
        sx={{
          // display: "flex",
          width: "100%",
          // height: "calc(100vh - 180px)",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          mt: isMobileWithoutFold ? "80px" : isPc ? "0" : "100px",
        }}
      >
        <Typography
          sx={{
            color: COLORS.white,
            fontSize: "50px",
            fontWeight: 800,
            lineHeight: "42px",
            textAlign: "center",
            mb: isMobileWithoutFold ? "24px" : 4,
          }}
        >
          Who's Next?
        </Typography>
        <Typography
          sx={{
            color: COLORS.white,
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "24px",
            textAlign: "center",
            whiteSpace: "pre-line",
          }}
        >
          {`새로운 서비스로\n곧 찾아가겠습니다!`}
        </Typography>
        <Box sx={{ mt: isPc ? "56px" : "56px" }}>
          <img src={marketAlienImage} width={"100%"} height={"100%"} />
        </Box>
      </Box>
      {/* ) : (
         <NoData type="noconnect" />
       )} */}
    </Box>
  );
}

export default React.memo(Explore);
