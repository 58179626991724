import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import "swiper/css";
import { getAnalytics, logEvent } from "firebase/analytics";
import { COLORS, TEXTSIZE } from "@theme/constants";
import { SearchContext } from "@components/layouts/SearchFeedLayout";
import RecentlyKeywords from "./RecentlyKeywords";
import RecommendTags from "./RecommendTags";
import { WIDTH } from "../../theme/constants";
import SearchResult from "./SearchResult";
import { Box } from "@mui/material";

const Wrapper = styled.div`
  padding: 0 16px;
  // margin: 20px auto 0;

  // max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    // max-width: 600px;
  }

  .search-feed__item {
    cursor: pointer;
  }

  .search-user {
    &__list {
    }

    &__item {
      padding: 0;
      cursor: pointer;

      .user-text {
        .MuiListItemText-primary {
          font-size: ${TEXTSIZE.medium};
          color: var(--textColor);
        }

        .MuiListItemText-secondary {
          font-size: ${TEXTSIZE.small};
          color: ${COLORS.gray_7};
        }
      }
    }
  }
`;

function SearchPost() {
  const analytics = getAnalytics();
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);

  const onClickTag = (value) => {
    setSearchKeyword(value);
  };

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "search",
      firebase_screen_class: "search",
    });
  }, []);

  return (
    <Wrapper className={"search"}>
      {!searchKeyword && (
        <Box sx={{mt: 2.5}}>
          <RecentlyKeywords onClickTag={onClickTag} isSearch={true} />
          <RecommendTags onClickTag={onClickTag} isSearch={true} />
        </Box>
      )}
      {searchKeyword && <SearchResult />}
    </Wrapper>
  );
}

export default React.memo(SearchPost);
