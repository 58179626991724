import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { RADIUS, TEXTSIZE } from "../theme/constants";
import { Button } from "@mui/material";
import { buttonMuiStyle } from "../utils/commonStyle";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--textColor);

  h1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 170%;
  }

  .one-paragraph {
    font-size: ${TEXTSIZE.large};
    line-height: 27px;
    font-weight: 700;
  }

  .multy-paragraph {
    font-size: ${TEXTSIZE.medium};
    line-height: 23.8px;
    font-weight: 400;
    text-align: center;
    margin-top: 8px;
  }

  .button {
    width: 120px;
    height: 48px;
    margin-top: 12px;
    background: var(--mainColor);
    border-radius: ${RADIUS.medium};
    font-weight: 600;
    font-size: ${TEXTSIZE.large};
    color: var(--bgColor);
  }
`;

function NotFound() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <h1>404</h1>
      <p className="one-paragraph">페이지를 찾을 수 없어요.</p>
      <p className="multy-paragraph">
        페이지가 삭제되었거나
        <br /> 주소가 변경되었을 수 있어요.
      </p>
      <Button
        sx={{ ...buttonMuiStyle, maxWidth: "200px", my: 2 }}
        variant="contained"
        size="large"
        onClick={() => navigate("/")}
      >
        홈으로 이동
      </Button>
    </Wrapper>
  );
}

export default React.memo(NotFound);
