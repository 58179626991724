import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  COLORS,
  FONT_WEIGHT,
  ICON_SIZE,
  LINE_HEIGHT,
  RADIUS,
  TEXTSIZE,
  WIDTH,
} from "../../theme/constants";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Box, Button, Snackbar, Tab, Typography, Modal } from "@mui/material";
import { getUserBalance } from "../../api";
import { getAnalytics, logEvent } from "firebase/analytics";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";
import { handleError, numberWithCommas } from "../../utils/commonFunction";
import CircularComponent from "../process/CircularComponent";
import ModalComponent from "../../components/modal/ModalComponent";
// icon and image
import { ReactComponent as ArrowLeft } from "../../assets/svgs/arrow_left.svg";
import { ReactComponent as MoreIcon } from "../../assets/svgs/more.svg";
import { ReactComponent as ExploreIcon } from "../../assets/svgs/explore.svg";
import { textMediumMuiStyle, buttonMuiStyle } from "../../utils/commonStyle";
import { useAuth } from "../../context/Auth";
import useWindowDimensions from "../../hooks/useWindownDimensions";
import { useMediaQuery } from "react-responsive";
import { TabPanel, TabList, TabContext } from "@mui/lab";
import HistoryMyPic from "./HistoryMyPic";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../assets/svgs/close.svg";

const Wrapper = styled.div`
  padding: 0;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  position: relative;
`;

const WrapperHeader = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  top: 0;

  .header {
    padding: 1rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header__icon {
    fill: ${COLORS.white};
    cursor: pointer;
  }

  .header__text {
    font-size: ${TEXTSIZE.large};
    font-weight: ${FONT_WEIGHT.large};
    line-height: ${LINE_HEIGHT.superLarge};
    color: ${COLORS.white};
  }

  .header__right {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: right;
    color: ${COLORS.white};
    cursor: pointer;
  }
`;

const WrapperContent = styled.div`
  // margin-bottom: 6rem;

  .display-flex-row-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .background-image {
    position: relative;
    // height: calc(20vh + 130px);
    padding-bottom: 80px;
  }

  .background-image__first {
    padding-top: 56px;
    padding-bottom: 2rem;
    position: relative;
    // height: calc(30vh + 50px);
    background: linear-gradient(124.24deg, #9725ae 0%, #5460d0 66.66%, #04a5fd 100%);
    // height: 100%;
    border-bottom-left-radius: ${RADIUS.superLarge};
    border-bottom-right-radius: ${RADIUS.superLarge};
  }

  .background-image__first__second {
    background: linear-gradient(124.24deg, #9725ae 0%, #5460d0 66.66%, #04a5fd 100%);
    height: 100%;
    border-bottom-right-radius: ${RADIUS.superLarge};
  }

  .profile {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    height: 130px;
  }

  .profile__avatar {
    width: 100px;
    height: 100px;
  }

  .profile__icon {
    position: absolute;
    background-color: ${COLORS.white};
    border-radius: 50%;
    padding: 3px;
    height: ${ICON_SIZE.medium};
    width: ${ICON_SIZE.medium};
    box-shadow: 2px 2px 5px 0px ${COLORS.grey500};
    cursor: pointer;
    stroke: ${COLORS.grey500};
  }

  .profile__icon--camera {
    top: 5px;
    right: 15px;
  }

  .profile__icon--edit {
    bottom: 4px;
    right: 4px;
  }
`;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "16px",
  padding: "24px 20px",
  maxWidth: "328px",
};

const imageType = {
  PROFILE: "profile_image_url",
  BACKGROUND: "bg_image_url",
};

function MyHistoryTransaction() {
  const { logout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "Fail",
    status: "",
  });
  const channel = new BroadcastChannel("payment-connected");
  const { vertical, horizontal, open, message, status } = state;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { height, width } = useWindowDimensions();
  const analytics = getAnalytics();
  const fileInputRef = useRef(null);
  const [clickType, setClickType] = useState(imageType.PROFILE);
  const [drawerData, setDrawerData] = useState({
    isRevert: false,
    isDelete: false,
  });
  const [previewProfile, setPreviewProfile] = useState("");
  const [previewBackground, setPreviewBackground] = useState("");
  const [originData, setOriginData] = useState({});
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    nickname: "",
    introduce: "",
    comment: "",
    profile_image_url: "",
    bg_image_url: "",
    out_links: ["", ""],
  });
  const [modalData, setModalData] = useState({
    open: false,
    btnOKText: "",
    btnCancelText: "",
    title: "",
    subTitle: "",
    content: "",
  });

  const [userFund, setUserFund] = useState({
    charge: 0,
    revenue: 0,
    bonus: 0, // Bonus PIC (tiền nhận từ hệ thống)
    event: 0,
    total: 0,
    pic_in_month: 0, // 이번 달 받은 PIC은 => Số PIC nhận trong tháng (hàng đầu tiên)
    balance: 0, // PIC (số dư hiện tại)
  });

  const [openPayment, setOpenPayment] = useState(false);

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const editSuccess = useRef(null);
  // let blocker = useBlocker(() => checkIsEdited());

  const handleResponseErrors = (responseErr) => {
    setErrors({ ...errors, ...responseErr });
  };

  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  const handleDrawerClick = (type) => {
    switch (type) {
      case "selectFile":
        fileInputRef.current.click();
        break;
      case "revertFile":
        handleRevertFile();
        break;
      case "deleteFile":
        handleDeleteFile();
        break;
      default:
        break;
    }
    handleDrawer(false);
  };

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "profileHistory",
      firebase_screen_class: "profileHistory",
    });
    const drawerRoot = document.getElementById("drawer-root");
    drawerRoot.style.display = "block";
  }, []);

  //modal action
  const handleOk = () => {
    // blocker.proceed();
    setModalData({ ...modalData, open: false });
  };

  const handleCancel = () => {
    // blocker.reset();
    setModalData({ ...modalData, open: false });
  };

  const [activeTab, setActiveTab] = useState("purchase");

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
    // setSearchParams({ tab: newValue });
  };

  const tabLabel = [
    { label: t("pic_usage_history"), type: "usage" },
    { label: t("pic_received"), type: "purchase" },
    { label: t("charging_history"), type: "charge" },
  ];

  const tabPanel = [
    { content: <HistoryMyPic type='usage' />, type: "usage" },
    { content: <HistoryMyPic type='purchase' />, type: "purchase" },
    { content: <HistoryMyPic type='charge' />, type: "charge" },
  ];

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleConnectPayment = async (onMessageData) => {
    channel.close();
    setOpenPayment(false);
    setState({ ...state, open: true, status: onMessageData?.status });
  };
  channel.onmessage = (msg) => {
    const onMessageData = {
      status: msg.data,
    };
    handleConnectPayment(onMessageData);
  };

  return (
    <Box>
      <Wrapper>
        <WrapperContent className='editProfile'>
          {isLoading ? (
            <CircularComponent />
          ) : (
            <>
              <Box sx={{ width: "100%" }}>
                <TabContext value={activeTab}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      position: "sticky",
                      top: "56px",
                      zIndex: "10",
                      backgroundColor: COLORS.white,
                    }}
                  >
                    <TabList
                      variant='fullWidth'
                      onChange={handleChangeTab}
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: COLORS.primary4,
                        },
                      }}
                    >
                      {tabLabel.map((tabItem, index) => (
                        <Tab
                          label={tabItem.label}
                          key={tabItem.type}
                          value={tabItem.type}
                          sx={{
                            ...textMediumMuiStyle,
                            "&.Mui-selected": {
                              color: COLORS.primary4,
                            },
                          }}
                        />
                      ))}
                    </TabList>
                  </Box>
                  {tabPanel.map((tabItem, index) => (
                    <TabPanel value={tabItem.type} key={tabItem.type}>
                      {tabItem.content}
                    </TabPanel>
                  ))}
                </TabContext>
              </Box>
              {drawerOpen && (
                <DrawerPortal>
                  <DrawerFrame
                    setOnDrawer={() => handleDrawer(false)}
                    type='profile-upload'
                    callBack={handleDrawerClick}
                    data={drawerData}
                  />
                </DrawerPortal>
              )}

              <ModalComponent
                open={modalData.open}
                modalData={modalData}
                handleClose={() => handleCancel()}
                handleOk={() => handleOk()}
                handleCancel={() => handleCancel()}
                btnOKProps={{}}
                btnCancelProps={{}}
              />
            </>
          )}
        </WrapperContent>

        {/* <Box
        sx={{
          padding: "0 1rem",
          zIndex: 10,
          width: `${isPc ? 826 : width}px`,
          position: "fixed",
          bottom: 10,
        }}
      >
        <Button
          sx={{ ...buttonLargeMuiStyle }}
          disabled={values.nickname.length < 2}
          variant="contained"
          size="large"
          onClick={handleSubmit}
        >
          다음
        </Button>
      </Box> */}
      </Wrapper>
    </Box>
  );
}

export default MyHistoryTransaction;
