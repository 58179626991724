import React from "react";
import { Box, Skeleton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

function HotCreatorSkeleton() {
  return (
    <Box sx={{ width: "100%", pl: 0, pb: 3.5 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <Skeleton variant='text' width={150} height={22} />
        <Skeleton variant='text' width={35} height={16} />
      </Box>
      <Swiper width={140}>
        {new Array(10).fill(null).map((_, indexPost) => (
          <SwiperSlide key={indexPost} style={{ background: "transparent" }}>
            <Box sx={{ position: "relative", my: 2, mr: 1.5 }}>
              <Skeleton
                variant='rectangular'
                width={128}
                height={128}
                sx={{
                  borderRadius: "8px",
                }}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}

export default HotCreatorSkeleton;
