import { useInfiniteQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { handleError } from "@utils/commonFunction";
import { useEffect, useState } from "react";
import { getQuizzes } from "../api";

function useGetQuizzes() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [filters, setFilters] = useState({ expired: 0 });
  const [posts, setPosts] = useState([]);
  const fetchData = async ({ pageParam, queryKey }) => {
    console.log("pageParam", pageParam);
    console.log("queryKey", queryKey[1]);
    try {
      const res = await getQuizzes({ limit: 10, offset: pageParam, ...queryKey[1] });
      return res;
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  useEffect(() => {
    console.log("filters", filters);
  }, [filters]);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [`quizzes${filters?.expired}`, filters],
    queryFn: fetchData,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.meta?.offset_next;
    },
  });

  useEffect(() => {
    const newData = [];
    if (!data?.pages?.[0]) return;
    data?.pages?.forEach((post) => {
      if (post?.list?.length > 0) {
        const newPosts = structuredClone(post?.list);
        newData.push(...newPosts);
      }
    });
    setPosts(newData);
  }, [data]);

  return {
    data,
    posts,
    isLoading,
    fetchNextPage,
    setFilters,
  };
}

export default useGetQuizzes;
