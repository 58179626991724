import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { handleError, numberWithCommas } from "../../utils/commonFunction";
import { Box, Grid, List, ListItem, Typography } from "@mui/material";

import { profilesHistory } from "../../api";
import { COLORS, FONT_WEIGHT, LINE_HEIGHT } from "../../theme/constants";
import {
  textMediumMuiStyle,
  textSmallMuiStyle,
  truncateStyle,
} from "../../utils/commonStyle";
import { dateTimeFormat } from "../../utils/dateTimeFormat";
import NoData from "../../components/NoData";
import styled from "styled-components";
import CircularComponent from "../process/CircularComponent";
import { useAuth } from "../../context/Auth";
import { useTranslation } from "react-i18next";

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 450px);
`;

function HistoryMyPic(props) {
  const { type = "purchase" } = props;
  const { logout } = useAuth();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMoreData = async (newRequestData = {}) => {
    try {
      if (data.length == 0) {
        setIsLoading(true);
      }

      const requestData = {
        offset: data.length,
        limit: 10,
        history_type: type,
        ...newRequestData,
      };

      const res = await profilesHistory(requestData);
      if (res?.list) {
        setData([...data, ...res.list]);
      }

      setIsLoading(false);
    } catch (error) {
      handleError(error, logout);
    }
  };

  useEffect(() => {
    fetchMoreData();
  }, []);

  const labels = [
    {
      title: t("date"),
    },
    {
      title: t("item"),
    },
    {
      title:
        type === "usage"
          ? t("id")
          : type === "purchase"
          ? t("sponsor_id")
          : t("payment_method"),
    },
    {
      title: "PIC",
    },
  ];

  return (
    <>
      {isLoading ? (
        <HeightWhenEmpty>
          <CircularComponent />
        </HeightWhenEmpty>
      ) : (
        <>
          {data.length > 0 ? (
            <Box>
              <Grid container>
                {labels.map((item, index) => (
                  <Grid item xs={3} key={item.title}>
                    <Typography
                      sx={{
                        ...textSmallMuiStyle,
                        lineHeight: LINE_HEIGHT.xLarge,
                        color: COLORS.grey400,
                        textAlign:
                          index === labels.length - 1 ? "right" : "left",
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Grid>
                ))}
              </Grid>

              <InfiniteScroll
                dataLength={data.length}
                next={fetchMoreData}
                hasMore={true}
                // scrollableTarget="scrollableContent"
              >
                <List 
                // sx={{ height: "calc(100vh - 450px)"
                // }}
                >
                  {data.length > 0 &&
                    data.map((history) => (
                      <ListItem sx={{ px: 0 }} key={history?.id}>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography
                              sx={{
                                ...textMediumMuiStyle,
                                ...truncateStyle,
                                fontWeight: FONT_WEIGHT.large,
                                color: COLORS.grey500,
                              }}
                            >
                              {dateTimeFormat(history?.created_at)}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            {type === "charge" ? (
                              <Box display={"flex"}>
                                <Typography
                                  sx={{
                                    ...textMediumMuiStyle,
                                    ...truncateStyle,
                                    maxWidth: "100%",
                                    width: "fit-content",
                                    fontWeight: FONT_WEIGHT.xxLarge,
                                  }}
                                >
                                  {numberWithCommas(history?.target?.price)}
                                </Typography>
                                <Typography
                                  sx={{
                                    ...textMediumMuiStyle,
                                    fontWeight: FONT_WEIGHT.large,
                                  }}
                                >
                                  {history?.target?.currency}
                                </Typography>
                              </Box>
                            ) : (
                              <Typography
                                sx={{ ...textMediumMuiStyle, ...truncateStyle }}
                              >
                                {history?.target?.label}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              sx={{ ...textMediumMuiStyle, ...truncateStyle }}
                            >
                              {history?.target?.nickname}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              sx={{
                                ...textMediumMuiStyle,
                                ...truncateStyle,
                                fontWeight: FONT_WEIGHT.xxLarge,
                                textAlign: "right",
                              }}
                            >
                              {numberWithCommas(history?.amount)}P
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                </List>
              </InfiniteScroll>
            </Box>
          ) : (
            <HeightWhenEmpty>
              <NoData text="최근 1개월간 충전 PIC가 없습니다." />
            </HeightWhenEmpty>
          )}
        </>
      )}
    </>
  );
}

export default HistoryMyPic;
