import React, { useEffect } from "react";
import styled from "styled-components";
import TabsComponent from "../../components/TabsComponent";
import { useMediaQuery } from "react-responsive";
import { WIDTH } from "../../theme/constants";
import { getAnalytics, logEvent } from "firebase/analytics";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

function Calculate() {
  const analytics = getAnalytics();

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "calculate",
      firebase_screen_class: "calculate",
    });
  }, []);

  const tabList = [
    { text: "PIC 출금", type: "calculate", data: null },
    {
      text: "정산 내역",
      type: "calculate_history",
      // tab_type: "history",
      data: [
        {
          id: 0,
          price: 10000,
          pic_count: 10000,
          date: "2023-04-06 12:19:18",
        },
        {
          id: 1,
          price: 10000,
          pic_count: 10000,
          date: "2023-04-06 12:19:18",
        },
      ],
      monthlyData: [
        {
          id: 2,
          price: 10000,
          pic_count: 10000,
          date: "2023-04-06 12:19:18",
        },
      ],
    },
  ];

  return (
    <Wrapper className='calculate'>
      <TabsComponent tabList={tabList} style={{ marginTop: "6px" }} />
    </Wrapper>
  );
}

export default React.memo(Calculate);
