import { Outlet, createBrowserRouter } from "react-router-dom";
import EmptyLayoutRiv from "../components/layouts/EmptyLayoutRiv";
import SearchMainLayout from "../components/layouts/SearchMainLayout";
import SearchFeedLayout from "../components/layouts/SearchFeedLayout";
import ChatLayout from "../components/layouts/ChatLayout.jsx";
import CommonLayout from "../components/layouts/CommonLayout";
import Info from "../containers/settings/Info.jsx";
import Terms from "../containers/settingInfo/Terms.jsx";
import Account from "../containers/settings/Account.jsx";
import Notifications from "../containers/settings/Notifications.jsx";
import Calculate from "../containers/withdraw/Calculate";
import Register from "../containers/withdrawRegister/Register";
import RequireAuth from "./RequireAuth";
import CalculateComplete from "../containers/withdraw/CalculateComplete";
import Claim from "../containers/withdraw/Claim.jsx";
import PaymentSuccess from "../Routes/PaymentSuccess";
import PaymentFail from "../Routes/PaymentFail";
import RegisterPage from "../containers/register/index.jsx";
import LoginWithAccount from "../containers/login/LoginWithAccount.jsx";
import PostDetail from "../pages/PostDetail.jsx";
import PostEdit from "../containers/posts/PostEdit.jsx";
import SearchPostListDetail from "../containers/search/SearchPostListDetail.jsx";
import {
  HEADER_LEFT_TYPE,
  HEADER_RIGHT_TYPE,
  WIDTH,
} from "@theme/constants.js";
import EditProfile from "../containers/profile/ProfileEdit.jsx";
import ResetPassword from "../containers/register/ResetPassword.jsx";
import ChangePassword from "../containers/register/ChangePassword.jsx";
import SearchPost from "../containers/search/SearchPost.jsx";
import MyWallet from "../containers/purchase/MyWallet.jsx";
import Sponsors from "../containers/members/Sponsors.jsx";
import ListMemberNotConnectedPage from "../containers/members/ListMemberNotConnectedPage";
import UserQuestion from "../containers/settings/UserQuestion.jsx";
import Policy from "../containers/settingInfo/Policy.jsx";
import Setting from "../containers/settings/Setting.jsx";
import BlockAccount from "../containers/settingBlockAccount/BlockAccount.jsx";
import Connect from "../containers/connects/Connect.jsx";
import DeleteAccount from "../containers/settings/DeleteAccount.jsx";
import NotificationList from "../containers/notifications/NotificationList.jsx";
import Comment from "../containers/comments/Comment.jsx";
import PostDetails from "../containers/posts/PostDetails.jsx";
import ChatList from "../containers/chats/ChatList.jsx";
import NewChatUserList from "../containers/newChat/NewChatUserList";
import MessageList from "../containers/messages/MessageList.jsx";
import SearchCategory from "../containers/search/SearchCategory";
import SearchPostListDetailByCategory from "../containers/search/SearchPostListDetailByCategory.jsx";
import MarketSearch from "../containers/market/MarketSearch.jsx";
import MarketDetail from "../containers/market/MarketDetail.jsx";
import QuizDetail from "../containers/quiz/QuizDetail.jsx";
import QuizDetailStatus from "../pages/QuizDetailStatus.jsx";
import {
  Search,
  Profile,
  Market,
  SuggestConnect,
  Login,
  Join,
  NotFound,
  Home,
  Welcome,
} from "@pages";
import MemberConnectedInstagram from "../containers/instagram/MemberConnectedInstagram.jsx";
import { Box } from "@mui/material";
import InstagramConnecting from "../pages/InstagramConnecting.jsx";
import RegisterBIZ from "../containers/biz/RegisterBIZ.jsx";
import DataTransfer from "../pages/DataTransfer.jsx";
import { ReactComponent as LogoIcon } from "@icons/logo-light.svg";
import PostCreateAndEdit from "../pages/PostCreateAndEdit.jsx";
import DataTransferWeb from "../pages/DataTransferWeb.jsx";
import CommonLayoutConnectKOL from "../components/layouts/CommonLayoutConnectKOL.jsx";
import PaymentConnecting from "../pages/PaymentConnecting.jsx";
import PaymentGateway from "../pages/PaymentGateway";
import MyHistoryTransaction from "../containers/purchase/MyHistoryTransaction.jsx";
import Quizzes from "../pages/Quizzes.jsx";
import QuizCreateAndEdit from "../pages/QuizCreateAndEdit.jsx";
import GetAddress from "../pages/GetAddress.jsx";
import Feedback from "../pages/Feedback";

const REACT_APP_URL = process.env.REACT_APP_URL;

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          widthDesktop={WIDTH.fold}
          leftType={HEADER_LEFT_TYPE.LOGO}
          rightType={HEADER_RIGHT_TYPE.MESSAGE}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Home /> }],
  },
  {
    path: "/quiz",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          widthDesktop={WIDTH.fold}
          leftType={HEADER_LEFT_TYPE.LOGO}
          rightType={HEADER_RIGHT_TYPE.MESSAGE}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Quizzes /> }],
  },
  // {
  //   path: "/quiz/create",
  //   element: (
  //     <RequireAuth>
  //       <CommonLayout
  //         isNavigation={false}
  //         isSideBar={true}
  //         isFooter={true}
  //         pageTitle={"page_title.quiz_create"}
  //         widthDesktop={WIDTH.desktop_main_contents}
  //         childContentStyle={{ paddingBottom: 0 }}
  //       />
  //     </RequireAuth>
  //   ),
  //   children: [{ index: true, element: <QuizCreateAndEdit mode={"create"} /> }],
  // },
  {
    path: "/post/detail/:id",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          widthDesktop={WIDTH.desktop_main_contents}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <PostDetail /> }],
  },
  {
    path: "/market/:id",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={false}
          isSideBar={true}
          isFooter={true}
          widthDesktop={WIDTH.desktop_main_contents}
          childContentStyle={{ padding: 0 }}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <MarketDetail /> }],
  },
  {
    path: "/quiz/:id",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={false}
          isSideBar={true}
          isFooter={true}
          // widthDesktop={WIDTH.desktop_main_contents}
          childContentStyle={{ padding: 0 }}
          pageTitle={"page_title.quiz_event"}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <QuizDetail /> }],
  },
  {
    path: "/quiz/:id/status",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={false}
          isSideBar={true}
          isFooter={true}
          widthDesktop={WIDTH.desktop_main_contents}
          childContentStyle={{ padding: 0 }}
          pageTitle={"page_title.quiz_status"}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <QuizDetailStatus /> }],
  },
  {
    path: "/post/edit",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={false}
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.post_edit"}
          widthDesktop={WIDTH.desktop_main_contents}
          childContentStyle={{ paddingBottom: 0 }}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <PostEdit /> }],
  },
  {
    path: "/post/create",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={false}
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.post_create"}
          widthDesktop={WIDTH.desktop_main_contents}
          childContentStyle={{ paddingBottom: 0 }}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <PostCreateAndEdit mode={"create"} /> }],
  },
  // list member donate for a post
  {
    path: "/post/:postID/sponsors/donate",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={false}
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.donate"}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Sponsors type="donate" /> }],
  },
  {
    path: "/post/:postID/sponsors/donate/not-connected",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={false}
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.someone_who_might_know"}
        />
      </RequireAuth>
    ),
    children: [
      { index: true, element: <ListMemberNotConnectedPage type="donate" /> },
    ],
  },
  // list member unlock post
  {
    path: "/post/:postID/sponsors/unlock",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={false}
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.unlock"}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Sponsors type="unlock" /> }],
  },
  {
    path: "/post/:postID/sponsors/unlock/not-connected",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={false}
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.someone_who_might_know"}
        />
      </RequireAuth>
    ),
    children: [
      { index: true, element: <ListMemberNotConnectedPage type="unlock" /> },
    ],
  },
  {
    path: "/profile/connection/:pageName",
    element: (
      <RequireAuth>
        <CommonLayout isNavigation={false} isSideBar={true} isFooter={true} />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Connect /> }],
  },
  {
    path: "/auth",
    children: [{ index: true, element: <Join /> }],
  },
  {
    path: "/signup/profile-settings",
    element: (
      <RequireAuth>
        <Outlet />
      </RequireAuth>
    ),
    children: [
      { index: true, element: <RegisterPage page={"profile_settings"} /> },
    ],
  },
  {
    path: "/signup/set-password",
    children: [
      { index: true, element: <RegisterPage page={"set_password"} /> },
    ],
  },
  {
    path: "/signup/email",
    children: [
      { index: true, element: <RegisterPage page={"create_account"} /> },
    ],
  },
  {
    path: "/signup/welcome",
    element: <EmptyLayoutRiv />,
    children: [{ index: true, element: <Welcome /> }],
  },
  {
    path: "/signup/connect",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayoutConnectKOL
          isNavigation={false}
          isSideBar={true}
          isFooter={true}
          isBackground={true}
          // leftType={HEADER_LEFT_TYPE.HIDE}
          leftType={HEADER_LEFT_TYPE.LOGO}
          rightType={HEADER_RIGHT_TYPE.SKIP}
          widthDesktop={WIDTH.fold}
          // backgroundStyle={backgroundImageChild}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <SuggestConnect /> }],
  },
  {
    path: "/login",
    element: <EmptyLayoutRiv />,
    children: [{ index: true, element: <Login /> }],
  },
  {
    path: "/login/account",
    children: [{ index: true, element: <LoginWithAccount /> }],
  },
  {
    path: "/login/identify",
    children: [{ index: true, element: <ResetPassword /> }],
  },
  {
    path: "/change-password",
    element: (
      <RequireAuth>
        <CommonLayout
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.change_password"}
          childContentStyle={{ paddingBottom: 0 }}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <ChangePassword /> }],
  },
  {
    path: "/search",
    exact: true,
    element: (
      <RequireAuth>
        <SearchMainLayout
          searchUrl="/search/feed"
          page={"searchByCategory"}
          isSideBar={true}
          isNavigation={true}
          leftType={HEADER_LEFT_TYPE.LOGO}
          rightType={HEADER_RIGHT_TYPE.HIDE}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Search /> }],
  },
  {
    path: "/search/category",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={false}
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.category"}
          childContentStyle={{ paddingBottom: 0 }}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <SearchCategory /> }],
  },
  {
    path: "market/category",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={false}
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.category"}
          childContentStyle={{ paddingBottom: 0 }}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <SearchCategory /> }],
  },
  {
    path: "/search/feed",
    element: (
      <RequireAuth>
        <SearchFeedLayout isSearchInput={true} />
      </RequireAuth>
    ),
    children: [{ index: true, element: <SearchPost /> }],
  },
  {
    path: "/search/details/keyword",
    element: (
      <RequireAuth>
        <SearchFeedLayout isSearchInput={false} />
      </RequireAuth>
    ),
    children: [{ index: true, element: <SearchPostListDetail /> }],
  },
  {
    path: "/search/details/category",
    element: (
      <RequireAuth>
        <SearchMainLayout
          isSideBar={true}
          isNavigation={true}
          leftType={HEADER_LEFT_TYPE.ARROW}
          rightType={HEADER_RIGHT_TYPE.HIDE}
          page={"searchDetailByCategory"}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <SearchPostListDetailByCategory /> }],
  },
  {
    path: "/details",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          widthDesktop={WIDTH.desktop_main_contents}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <PostDetails /> }],
  },
  {
    path: "/details/:from",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          widthDesktop={WIDTH.desktop_main_contents}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <PostDetails /> }],
  },
  {
    path: "/details/:from/:offset",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          widthDesktop={WIDTH.desktop_main_contents}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <PostDetails /> }],
  },
  {
    path: "/details/nickname/:from/:nickname",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          widthDesktop={WIDTH.desktop_main_contents}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <PostDetails /> }],
  },
  {
    path: "/details/category/:from/:category_id",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          widthDesktop={WIDTH.desktop_main_contents}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <PostDetails /> }],
  },
  // {
  //   path: "/market",
  //   exact: true,
  //   element: (
  //     <RequireAuth>
  //       <SearchMainLayout searchUrl='/market/search' page={"searchByCategory"} />
  //     </RequireAuth>
  //   ),
  //   children: [{ index: true, element: <Market /> }],
  // },
  // {
  //   path: "/market/search",
  //   element: (
  //     <RequireAuth>
  //       <SearchFeedLayout isSearchInput={true} />
  //     </RequireAuth>
  //   ),
  //   children: [{ index: true, element: <MarketSearch /> }],
  // },
  // {
  //   path: "/explore/user",
  //   element: (
  //     <RequireAuth>
  //       <SearchFeedLayout isSearchInput={true} />
  //     </RequireAuth>
  //   ),
  //   children: [{ index: true, element: <ExploreSearch /> }],
  // },
  {
    path: "/profile",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          leftType={HEADER_LEFT_TYPE.LOGO}
          rightType={HEADER_RIGHT_TYPE.MESSAGE}
          widthDesktop={WIDTH.fold}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Profile type={"my"} /> }],
  },
  {
    path: "/user-profile/:nickname",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          leftType={HEADER_LEFT_TYPE.LOGO}
          rightType={HEADER_RIGHT_TYPE.MESSAGE}
          widthDesktop={WIDTH.fold}
          titleStyle={{ visibility: "hidden" }}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Profile type={"others"} /> }],
  },
  {
    path: "/paymentSuccess",
    exact: true,
    children: [{ index: true, element: <PaymentSuccess /> }],
  },
  {
    path: "/paymentFail",
    exact: true,
    children: [{ index: true, element: <PaymentFail /> }],
  },
  {
    path: "/calculate",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          leftType={HEADER_LEFT_TYPE.LOGO}
          rightType={HEADER_RIGHT_TYPE.MESSAGE}
          widthDesktop={WIDTH.desktop_900}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Calculate /> }],
  },
  {
    path: "/signup-account",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          leftType={HEADER_LEFT_TYPE.LOGO}
          rightType={HEADER_RIGHT_TYPE.MESSAGE}
          widthDesktop={WIDTH.fold}
          isScrollTop={false}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Register /> }],
  },
  {
    path: "/join",
    exact: true,
    element: (
      <div style={{ background: "white" }}>
        <div
          style={{
            // display: "flex",
            padding: "16px",
            margin: "0 auto",
            maxWidth: "1200px",
          }}
        >
          <a href={REACT_APP_URL}>
            <LogoIcon />
          </a>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // paddingTop: 50,
          }}
        >
          <RegisterBIZ />
        </div>
      </div>
    ),
    children: [{ index: true, element: <RegisterBIZ /> }],
  },
  {
    path: "/complete/:type",
    exact: true,
    element: (
      <CommonLayout
        isNavigation={true}
        isSideBar={true}
        isFooter={true}
        leftType={HEADER_LEFT_TYPE.LOGO}
        rightType={HEADER_RIGHT_TYPE.MESSAGE}
        widthDesktop={WIDTH.desktop_900}
      />
    ),
    children: [{ index: true, element: <CalculateComplete /> }],
  },
  {
    path: "/claim",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          leftType={HEADER_LEFT_TYPE.LOGO}
          rightType={HEADER_RIGHT_TYPE.MESSAGE}
          widthDesktop={WIDTH.desktop_900}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Claim /> }],
  },
  {
    path: "/claim/complete",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          leftType={HEADER_LEFT_TYPE.LOGO}
          rightType={HEADER_RIGHT_TYPE.MESSAGE}
          widthDesktop={WIDTH.desktop_900}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Claim /> }],
  },
  {
    path: "/comment/:id/:pageName",
    element: (
      <RequireAuth>
        <CommonLayout isNavigation={false} isSideBar={true} isFooter={true} />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Comment /> }],
  },
  {
    path: "/notifications",
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.notifications"}
        />
      </RequireAuth>
    ),
    children: [
      {
        index: true,
        element: <NotificationList />,
      },
    ],
  },
  {
    path: "/chat",
    element: (
      <RequireAuth>
        <ChatLayout isNavigation={true} />
      </RequireAuth>
    ),
    children: [{ index: true, element: <ChatList /> }],
  },
  {
    path: "/chat/new-chat",
    element: (
      <RequireAuth>
        <ChatLayout isNavigation={true} />
      </RequireAuth>
    ),
    children: [{ index: true, element: <NewChatUserList /> }],
  },
  {
    path: "/chat/detail/:receiverID",
    element: (
      <RequireAuth>
        <ChatLayout isNavigation={false} />
      </RequireAuth>
    ),
    children: [{ index: true, element: <MessageList /> }],
  },
  {
    path: "/setting",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.setting"}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Setting /> }],
  },
  {
    path: "/setting/account",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.setting_account"}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Account /> }],
  },
  {
    path: "/setting/notifications",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.notifications"}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Notifications /> }],
  },
  {
    path: "/setting/info",
    element: (
      <RequireAuth>
        <CommonLayout
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.setting_info"}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <Info /> }],
  },
  {
    path: "/terms",
    element: (
      <CommonLayout
        isSideBar={false}
        pageTitle={"page_title.terms"}
        childContentStyle={{
          paddingBottom: "100px",
          height: "calc(100vh - 58px)",
        }}
      />
    ),
    children: [{ index: true, element: <Terms /> }],
  },
  {
    path: "/policy",
    element: (
      <CommonLayout
        isSideBar={false}
        pageTitle={"page_title.policy"}
        childContentStyle={{
          paddingBottom: "100px",
          height: "calc(100vh - 58px)",
        }}
      />
    ),
    children: [{ index: true, element: <Policy /> }],
  },
  {
    path: "/setting/block",
    element: (
      <RequireAuth>
        <CommonLayout
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.setting_block"}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <BlockAccount /> }],
  },
  {
    path: "/question",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.question"}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <UserQuestion /> }],
  },
  {
    path: "/instagram",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.instagram_connection"}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <MemberConnectedInstagram /> }],
  },
  {
    path: "/instagram-connecting",
    exact: true,
    element: (
      <Box>
        <Outlet />
      </Box>
    ),
    children: [{ index: true, element: <InstagramConnecting /> }],
  },
  {
    path: "/delete",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isSideBar={true}
          isFooter={true}
          pageTitle={"page_title.delete"}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <DeleteAccount /> }],
  },
  {
    path: "/profile/edit",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={false}
          isSideBar={true}
          isFooter={true}
          leftType={HEADER_LEFT_TYPE.LOGO}
          rightType={HEADER_RIGHT_TYPE.MESSAGE}
          widthDesktop={WIDTH.fold}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <EditProfile /> }],
  },
  {
    path: "/profile/wallet",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          leftType={HEADER_LEFT_TYPE.LOGO}
          rightType={HEADER_RIGHT_TYPE.MESSAGE}
          widthDesktop={WIDTH.fold}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <MyWallet /> }],
  },
  {
    path: "/profile/history",
    exact: true,
    element: (
      <RequireAuth>
        <CommonLayout
          isNavigation={true}
          isSideBar={true}
          isFooter={true}
          leftType={HEADER_LEFT_TYPE.ARROW}
          rightType={HEADER_RIGHT_TYPE.HIDE}
          widthDesktop={WIDTH.fold}
          pageTitle={"page_title.history"}
        />
      </RequireAuth>
    ),
    children: [{ index: true, element: <MyHistoryTransaction /> }],
  },
  {
    path: "/payment-connecting",
    exact: true,
    element: (
      <Box>
        <Outlet />
      </Box>
    ),
    children: [{ index: true, element: <PaymentConnecting /> }],
  },
  {
    path: "/datatransfer",
    children: [{ index: true, element: <DataTransfer /> }],
  },
  {
    path: "/data-transfer",
    children: [{ index: true, element: <DataTransferWeb /> }],
  },
  {
    path: "/donation",
    children: [{ index: true, element: <PaymentGateway /> }],
  },
  {
    path: "/get-address",
    children: [{ index: true, element: <GetAddress /> }],
  },
  {
    path: "/feedback",
    element: <Feedback />,
  },
  {
    path: "/*",
    exact: true,
    element: <NotFound />,
  },
]);

export default router;
