import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { COLORS, FONT_WEIGHT, ICON_SIZE, LINE_HEIGHT } from "@theme/constants";
import {
  textSmallMuiStyle,
  textMediumMuiStyle,
  truncateStyle,
} from "@utils/commonStyle";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import ModalComponent from "@components/modal/ModalComponent";
import {
  dateTimeFormat,
  relativeTimeMoreThanHour,
} from "@utils/dateTimeFormat";
import dayjs from "dayjs";
import ImageAvatar from "@images/avatar.png";

const Wrapper = styled.div``;

const WrapperImg = styled.img`
  aspect-ratio: 1;
  border-radius: 4px;
  width: 36px;
  height: 36px;
`;

function ChatItem(props) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const {
    receiverID = "",
    nickname = "",
    content = "",
    profile_image_url = "",
    timestamp = "",
    countUnread = 0,
  } = props;

  const [modalData, setModalData] = useState({
    open: false,
    type: "",
    btnOKText: "",
    btnCancelText: "",
    title: "",
    subTitle: "",
    content: "",
  });

  const redirectToProfile = () => {
    // navigate(`/user-profile/${nickname}`);
  };

  const onClickContent = () => {
    // console.log("onClickContent");
    navigate(`/chat/detail/${receiverID}`, { state: { userName: nickname } });
  };

  //modal action
  const handleOk = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleCancel = () => {
    setModalData({ ...modalData, open: false });
  };

  const handleClose = () => {
    setModalData({ ...modalData, open: false });
  };

  return (
    <>
      <Wrapper>
        <ListItem
          sx={{
            p: 0,
            my: "1.5rem",
            alignItems: "center",
            "& .MuiListItemSecondaryAction-root": {
              right: "0px",
            },
          }}
          secondaryAction={
            <Box sx={{ display: "flex", gap: "0.25rem" }}>
              {countUnread > 0 && (
                <Typography
                  sx={{
                    backgroundColor: COLORS.primary4,
                    color: COLORS.white,
                    borderRadius: "16px",
                    px: "4px",
                    minWidth: "16px",
                    textAlign: "center",
                    fontSize: "8px",
                    lineHeight: "16px",
                  }}
                >
                  {countUnread}
                </Typography>
              )}
              <Typography sx={textSmallMuiStyle}>
                {dateTimeFormat(
                  dayjs(timestamp),
                  relativeTimeMoreThanHour(timestamp, 24) ? "MM:DD" : "HH:mm"
                )}
              </Typography>
            </Box>
          }
        >
          <ListItemAvatar
            sx={{
              minWidth: ICON_SIZE.large,
              mr: 1,
              my: 0,
              cursor: "pointer",
            }}
            onClick={redirectToProfile}
          >
            <Avatar
              alt=""
              src={profile_image_url || ImageAvatar}
              sx={{
                width: ICON_SIZE.large,
                height: ICON_SIZE.large,
              }}
            />
          </ListItemAvatar>
          <ListItemText
            onClick={onClickContent}
            primary={`${nickname}`}
            sx={{
              my: 0,
              "& .MuiListItemText-primary": {
                ...textMediumMuiStyle,
                ...truncateStyle,
                width: "80%",
                cursor: "pointer",
                fontWeight: FONT_WEIGHT.large,
              },
            }}
            secondaryTypographyProps={{ component: "div" }}
            secondary={
              <Typography
                sx={{
                  ...textSmallMuiStyle,
                  ...truncateStyle,
                  fontWeight: FONT_WEIGHT.medium,
                  lineHeight: LINE_HEIGHT.xLarge,
                  width: "70%",
                  cursor: "pointer",
                }}
              >
                {content}
              </Typography>
            }
          />
        </ListItem>
      </Wrapper>
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => handleClose()}
        handleOk={() => handleOk()}
        handleCancel={() => handleCancel()}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
    </>
  );
}

export default ChatItem;
