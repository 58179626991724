import React from "react";
import { Box, Typography } from "@mui/material";
import { COLORS, ICON_SIZE } from "@theme/constants";
import { textSmallMuiStyle } from "@utils/commonStyle";
import { numberFormatThousand } from "@utils/commonFunction";
import { ReactComponent as ImageIcon } from "@icons/image.svg";
import { ReactComponent as MovieCameraIcon } from "@icons/movie_camera.svg";
// import { ReactComponent as HeartIcon } from "@icons/heart.svg";
// import { ReactComponent as PicWhite } from "@icons/PIC_default.svg";
// import { ReactComponent as PicSelect } from "@icons/PIC_select.svg";
import { ReactComponent as PurchaseIcon } from "@icons/purchase.svg";
import { useMatch } from "react-router-dom";
import { isMine } from "@utils/commonFunction";

function HomeFeedOverview(props) {
  const homeMatch = useMatch("/");
  const { post = {} } = props;

  // const renderPic = (type) => {
  //   switch (type) {
  //     case PIC_TYPE.black:
  //       return <HeartIcon width={24} height={24} />;
  //     case PIC_TYPE.purple:
  //       return <PicSelect width={24} height={24} />;
  //     default:
  //       return <PicWhite width={24} height={24} />;
  //   }
  // };

  const isShowPurchase = () => {
    if(homeMatch) {
      return true
    }

    if (isMine(post?.user_profile?.user_id)) {
      return false;
    }

    if (!isMine(post?.user_profile?.user_id) && post?.is_paid) {
      return false;
    }

    return true;
  };

  const renderPurchaseItem = () => {
    let result = { isShow: false };
    if (post?.cost > 0) {
      result = {
        icon: <PurchaseIcon />, // renderPic(post?.is_paid ? PIC_TYPE.purple : PIC_TYPE.black)
        value: Number.isInteger(post?.cost)
          ? numberFormatThousand(post?.cost)
          : post?.cost,
        isShow: isShowPurchase(),
      };
    }

    return result;
  };

  const overviewInfo = [
    renderPurchaseItem(),
    {
      icon: <ImageIcon width={"16px"} height={"16px"} />,
      value: post?.total_image,
      isShow: post?.total_image != 0,
    },
    {
      icon: <MovieCameraIcon width={"16px"} height={"16px"} />,
      // handleClick: handleClickPIC,
      value: post?.total_video,
      isShow: post?.total_video != 0,
    },
  ];
  return (
    <Box
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        borderRadius: "80px",
        padding: "0.25rem 0.75rem",
        display: "flex",
        justifyContent: "center",
        // border: `1px solid ${COLORS.grey50}`,
        gap: "0.5rem",
      }}
    >
      {overviewInfo.map(
        ({ isShow, icon, value, handleClick }, index) =>
          isShow && (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.125rem",
                cursor: "pointer",
              }}
            >
              {icon}
              <Typography
                sx={{
                  ...textSmallMuiStyle,
                  color: "#1E1E1E",
                  fontWeight: "400",
                }}
              >
                {value}
              </Typography>
            </Box>
          )
      )}
    </Box>
  );
}

export default HomeFeedOverview;
