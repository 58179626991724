import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
// import Slide from "@mui/material/Slide";
// import SnackBarContext from "../App";

// function TransitionUp(props) {
//   return <Slide {...props} direction='up' />;
// }

function SnackBar(props) {
  // const {
  //   openSnackBar,
  //   setSnackBarOpen,
  //   SnackBarTransition,
  //   setSnackBarTransition,
  // } = useContext(SnackBarContext);
  const [openSnackBar, setSnackBarOpen] = useState(props.open);
  const [SnackBarTransition, setSnackBarTransition] = useState(undefined);

  // console.log(openSnackBar);

  const handleSnackBar = (Transition) => () => {
    setSnackBarTransition(() => Transition);
    setSnackBarOpen(true);
  };
  handleSnackBar();

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  return (
    <Snackbar
      open={openSnackBar}
      onClose={handleSnackBarClose}
      TransitionComponent={SnackBarTransition}
      message={props.message}
      key={SnackBarTransition ? SnackBarTransition.name : ""}
    />
  );
}

export default React.memo(SnackBar);
