import React, { useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/material";
import { v4 as uuid } from "uuid";
import {
  dataURLtoFile,
  generateVideoThumbnail,
  getFileTypeByName,
} from "../../utils/commonFunction";
import { getImageSize } from "react-image-size";

function ButtonUploadImage(props) {
  const { handleSetPreview, children, remainLimit = 10 } = props;
  const fileInputRef = useRef(null);

  const getNewFiles = async (element) => {
    const id = uuid();
    let thumbnail = "";
    let width = 0;
    let height = 0;
    const isVideo = getFileTypeByName(element?.name) === "video";

    if (isVideo) {
      const thumbnailTmp = await generateVideoThumbnail(element);
      thumbnail = dataURLtoFile(thumbnailTmp, `${element?.name}.png`);
      const dimensions = await getImageSize(URL.createObjectURL(thumbnail));
      width = dimensions?.width;
      height = dimensions?.height;
    } else {
      const dimensions = await getImageSize(URL.createObjectURL(element));
      width = dimensions?.width;
      height = dimensions?.height;
    }

    const newFile = {
      id: id,
      name: element?.name,
      link: isVideo ? URL.createObjectURL(thumbnail) : URL.createObjectURL(element),
      file: element,
      type: isVideo ? "video" : "image",
      mime: element?.type,
      thumbnail: thumbnail,
      width: width,
      height: height,
      is_locked: 0,
      caption: element?.name,
    };
    return newFile;
  };

  const onDrop = async (acceptedFiles) => {
    // handleUploadImage(acceptedFiles);
    if (remainLimit == 0) return;
    const newFiles = await Promise.all(acceptedFiles.map(getNewFiles));
    handleSetPreview(newFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [], "video/*": [] },
    maxFiles: remainLimit,
    onDrop,
  });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} ref={fileInputRef} />
      </div>
      <Box
        onClick={() => {
          fileInputRef.current.click();
          fileInputRef.current.value = "";
        }}
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        {children}
      </Box>
    </>
  );
}

export default ButtonUploadImage;
