import axios from "axios";

// 댓글 리스트
export function getComments(id, requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/posts/${id}/comments`, {
      params: requestParams,
    })
    .then((res) => res.data.data);
}

// 댓글 등록
export function setComment(data, id) {
  return axios.post(`${process.env.REACT_APP_API_URL}/posts/${id}/comments`, data);
}

// 게시물 신고하기
export function setReport(id, data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/posts/${id}/report`, data);
}

// 댓글 신고하기
export function setConmmentReport(post_id, comment_id, data) {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/api/v1/posts/${post_id}/comments/${comment_id}/report`,
    data
  );
}

// 유료 게시물 결제(해제)
export function setPaythePic(id, pic) {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/posts/${id}/unlock`, {
    pic: Number(pic),
  });
}

// 픽 충전하기
export function setChargethePic(pic) {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/payments`, {
    pic: Number(pic),
  });
}

// 후원하기
export function setPiced(id, pic) {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/posts/${id}/donate`, {
    pic: Number(pic),
  });
}

// 탈퇴하기
export function setWithdrawal(reason) {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/users/withdrawal`, { reason });
}

// 픽 사용 내역 리스트
export function getPicHistory(pageParam) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v1/pic/consumed-history?p=cursor&cursor=${
        pageParam ? pageParam : null
      }`
    )
    .then((res) => {
      return res.data.data;
    });
}

// start
// code request (Register)
export function codeRequest(content) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/signup/email`, content);
}

export function loginSocial(requestBody) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, requestBody);
}

export function updateProfile(requestBody) {
  return axios.put(`${process.env.REACT_APP_API_URL}/users/profile`, requestBody);
}

export function codeVerify(requestBody) {
  return axios.patch(`${process.env.REACT_APP_API_URL}/auth/signup/email`, requestBody);
}

// reset password
export function forgotPassword(data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, data).then((res) => {
    return res.data.data;
  });
}

// change password
export function changePassword(requestBody) {
  return axios
    .put(`${process.env.REACT_APP_API_URL}/users/change-password`, requestBody)
    .then((res) => {
      return res.data;
    });
}

export function signUpRequest(requestBody) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/signup`, requestBody);
}

export function loginWithAccount(requestParams) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, requestParams).then((res) => {
    return res.data.data;
  });
}

// post
// export function getHotPost(requestParams) {
//
//   return axios
//     .get(`${process.env.REACT_APP_API_URL}/posts/hot`, {
//
//       params: requestParams,
//     })
//     .then((res) => {
//       return res.data.data;
//     });
// }

// Hot creator
export function getHotCreator(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/posts/hot-creator-area`, {
      params: requestParams,
    })
    .then((res) => {
      return res.data.data;
    });
}

// 5.1.1
export function getPosts(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/posts/home`, {
      params: requestParams,
    })
    .then((res) => {
      return res.data.data;
    });
}

// 5.1.2 search post
export function searchPosts(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/posts/search`, {
      params: requestParams,
    })
    .then((res) => {
      return res.data.data;
    });
}

export function getConnect(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/explore/connect`, {
      params: requestParams,
    })
    .then((res) => {
      return res.data.data;
    });
}

// 5.1.4
export function getQuizzes(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/posts/quiz`, {
      params: requestParams,
    })
    .then((res) => {
      return res.data.data;
    });
}

// 9.4 Market Profile
export function getMarketProfile(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/explore/market`, {
      params: requestParams,
    })
    .then((res) => {
      return res.data.data;
    });
}

// 5.13 get post recommend area
export function getPostsRecommend(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/posts/recommend-area`, {
      params: requestParams,
    })
    .then((res) => {
      return res.data.data;
    });
}

export function getPostDetail(id) {
  return axios.get(`${process.env.REACT_APP_API_URL}/posts/${id}`).then((res) => {
    return res.data.data;
  });
}

export function blockPost(ID, data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/posts/${ID}/block`, data);
}

// 5.6 create post
export function createPost(requestBody) {
  return axios.post(`${process.env.REACT_APP_API_URL}/posts/create`, requestBody).then((res) => {
    return res.data.data;
  });
}

export function updatePost(id, requestBody) {
  return axios.put(`${process.env.REACT_APP_API_URL}/posts/${id}/edit`, requestBody).then((res) => {
    return res.data.data;
  });
}

export function deletePost(id) {
  return axios.delete(`${process.env.REACT_APP_API_URL}/posts/${id}/delete`);
}

export function getTagsExplore(id) {
  return axios.get(`${process.env.REACT_APP_API_URL}/tags/explore`).then((res) => {
    return res.data.data;
  });
}

// 17.2
export function getSuggestionTags(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/tags`, {
      params: requestParams,
    })
    .then((res) => {
      return res.data.data;
    });
}

export function createReport(data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/reports`, data);
}

// block user begin
export function getBlockUser(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/users/block`, {
      params: requestParams,
    })
    .then((res) => res.data.data);
}

export function blockUser(data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/users/block`, data);
}

export function unblockUser(requestBody) {
  return axios.delete(`${process.env.REACT_APP_API_URL}/users/block`, {
    data: requestBody,
  });
}
// block user end

// comment
export function deleteComment(commentId) {
  return axios.delete(`${process.env.REACT_APP_API_URL}/comments/${commentId}`);
}

//profile
export function getUserProfile() {
  return axios.get(`${process.env.REACT_APP_API_URL}/users/profile`).then((res) => res.data.data);
}

export function getOtherUserProfile(data) {
  const url = `${process.env.REACT_APP_API_URL}/profiles/public`;
  return axios.post(url, data).then((res) => res.data.data);
}

//profile post
export function getProfilePost(endpoint, requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/users/${endpoint}`, {
      params: requestParams,
    })
    .then((res) => res.data.data);
}

// get profile public
export function getProfilePublicContent(requestParams, data, endpoint) {
  const url = `${process.env.REACT_APP_API_URL}/profiles/public/${endpoint}`;
  return axios
    .post(url, data, {
      params: requestParams,
    })
    .then((res) => res.data.data);
}

export function getPublicPost(requestParams, data) {
  const url = `${process.env.REACT_APP_API_URL}/profiles/public/posts`;
  return axios
    .post(url, data, {
      params: requestParams,
    })
    .then((res) => res.data.data);
}

// 4.11 getKOLProfile
export function getSuggestConnection() {
  return axios.get(`${process.env.REACT_APP_API_URL}/profiles/kol`).then((res) => res.data.data);
}

// 4.12 connect with KOL
export function createConnectKOL(data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/profiles/connect-kol`, data);
}

// donate
export function unlockOrDonatePost(postID, data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/posts/${postID}/pic`, data);
}

export function getUserUnlockOrDonate(postID, requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/posts/${postID}/pic`, {
      params: requestParams,
    })
    .then((res) => res.data.data);
}

// user balance
export function getUserBalance() {
  return axios.get(`${process.env.REACT_APP_API_URL}/users/balance`).then((res) => res.data.data);
}

export function profilesHistory(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/profiles/history`, {
      params: requestParams,
    })
    .then((res) => res.data.data);
}

//connection
export function createConnection(data) {
  console.log("createConnection");

  return axios.post(`${process.env.REACT_APP_API_URL}/connections`, data);
}

export function deleteConnection(userID) {
  return axios.delete(`${process.env.REACT_APP_API_URL}/connections/${userID}`);
}

// 5.7.1 Edit view buyer
export function editViewBuyer(postID, data) {
  return axios.put(`${process.env.REACT_APP_API_URL}/posts/${postID}/edit-view-buyer`, data);
}

// 6.3 get connection
export function getConnection(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/connections/connect`, {
      params: requestParams,
    })
    .then((res) => res.data.data);
}
// 6.5 get connecting
export function getConnecting(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/connections/connecting`, {
      params: requestParams,
    })
    .then((res) => res.data.data);
}

// 6.4.1 get connection other
export function getConnectionOther(requestParams, data) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/connections/connect`, data, {
      params: requestParams,
    })
    .then((res) => res.data.data);
}
// 6.6.1 get connecting other
export function getConnectingOther(requestParams, data) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/connections/connecting`, data, {
      params: requestParams,
    })
    .then((res) => res.data.data);
}

//upload image
export function getPresignedUrl(data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/upload/presigned`, data);
}

//report
export function getReportReason() {
  return axios.get(`${process.env.REACT_APP_API_URL}/report-reason`).then((res) => {
    return res.data.data;
  });
}

//withdrawer
export function postUserWithdrawer(data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/users/withdraw`, data);
}

export function getUserWithdrawer() {
  return axios.get(`${process.env.REACT_APP_API_URL}/users/withdraw`).then((res) => {
    return res.data.data;
  });
}

// 16 User question
export function postUserQuestion(data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/users/questions`, data);
}

// 18 Setting
export function getConfigs(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/configs`, {
      params: requestParams,
    })
    .then((res) => {
      return res.data.data;
    });
}

export function getSetting(endpoint = "information") {
  return axios.get(`${process.env.REACT_APP_API_URL}/${endpoint}`).then((res) => {
    return res.data.data;
  });
}

// 3.5 Delete account
export function deleteAccount(data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/users/delete`, data);
}

// 1.8 Logout
export function Logout(data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, data);
}

// 3.3 Get User Configs Notifications
export function getUserConfigsNotifications() {
  return axios.get(`${process.env.REACT_APP_API_URL}/users/configs/notifications`).then((res) => {
    return res.data.data;
  });
}

// 3.3 Set User Configs Notifications
export function setUserConfigsNotifications(requestBody) {
  return axios.patch(`${process.env.REACT_APP_API_URL}/users/configs/notifications`, requestBody);
}

// 9.1 9.2 9.3 Explore explore profile
export function getExplore(requestParams, endpoint) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/explore/${endpoint}`, {
      params: requestParams,
    })
    .then((res) => {
      return res.data.data;
    });
}

// 11.1 get user notifications
export function getUserNotifications(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/users/notifications`, {
      params: requestParams,
    })
    .then((res) => res.data.data);
}

// 11.4 Make seen all notifications
export function seenAllNotifications(requestBody) {
  return axios
    .patch(`${process.env.REACT_APP_API_URL}/users/notifications`, requestBody, {})
    .then((res) => res.data.data);
}

export function getUserCategories(
  requestParams = {
    offset: 0,
    limit: 20,
  }
) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/categories/user`, {
      params: requestParams,
    })
    .then((res) => {
      return res.data.data;
    });
}

export function getAllCategories(
  requestParams = {
    offset: 0,
    limit: 20,
  }
) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/categories`, {
      params: requestParams,
    })
    .then((res) => {
      return res.data.data;
    });
}

export function updateUserCategories(data, conversationID) {
  return axios.post(`${process.env.REACT_APP_API_URL}/categories/add`, data).then((res) => {
    return res?.data?.data;
  });
}

export function purchasesConfirm(data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/purchases/toss/confirm`, data);
}

// 5.1.3 get market list
export function getMarketList(requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/posts/market`, {
      params: requestParams,
    })
    .then((res) => res.data.data);
}

export function getProducts(platform = "toss") {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/products?platform=${platform}`)
    .then((res) => res.data.data);
}

//5.15 Answer Quiz Post
export function answerQuizPost(id, data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/posts/${id}/answer`, data);
}

//5.16 Answer Quiz Result
export function getAnswerQuiz(id) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/posts/${id}/answer`)
    .then((res) => res.data.data);
}

//5.17 Floating Quiz
export function getFloatingQuiz() {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/posts/floating-quiz`)
    .then((res) => res.data.data);
}

//5.18 Share Quiz
export function getShareQuiz(id) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/posts/${id}/sharing`)
    .then((res) => res.data.data);
}

//5.18 Quiz result
export function getQuizResult(id, requestParams) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/posts/${id}/quiz-result`, {
      params: requestParams,
    })
    .then((res) => res.data.data);
}

//5.20 Give Bonus
export function postGiveBonusPIC(id, requestBody) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/posts/${id}/give-bonus-pic`, requestBody)
    .then((res) => res.data.data);
}

//21.0 Connect Instagram
export function connectInstagram(requestBody) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/social-account/connect/instagram`, requestBody)
    .then((res) => res.data.data);
}

//21.1 Get Instagram Connect
export function getInstagramConnect() {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/social-account/instagram`)
    .then((res) => res.data.data);
}

//21.2 Disconnect Instagram
export function disconnectInstagram(id) {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/social-account/disconnect/${id}`)
    .then((res) => res.data.data);
}

//1.13 Register Business Account
export function registerBusinessAccount(body) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/register-business-account`, body);
}

export function getPresignedUrlBiz(data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/upload/presigned-biz`, data);
}

export function postStorePayment(data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/purchases/danal/store-payment`, data);
}

export function postStorePaymentDonate(data) {
  return axios.post(`${process.env.REACT_APP_API_URL}/purchases/danal/store-payment-donate`, data);
}
