import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { HEIGHT, RADIUS } from "../../../theme/constants";

const SkeletonWrap = styled.div`
  width: 100%;
  height: calc(100vh - ${HEIGHT.navigation} - 110px - ${HEIGHT.header_mo});

  .bubbles-wrap {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-auto-columns: 10px;
    place-items: center;
    /* flex-wrap: wrap;
    flex-direction: row; */
  }

  /* .bubbles-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .bubble {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    max-width: 80px;
    margin: 0;

    &:nth-child(1) {
      width: 20px;
    }
  } */

  .blank {
    width: 40%;
  }
  .blank_circle {
    width: 100%;
  }
  .blank_text {
    margin: 10% auto 0;
    width: 80%;
    height: 17px;
    font-family: Pretendard;
    text-align: center;
    font-size: 10px;
    letter-spacing: 0;
  }

  .bubble_4 {
    width: 80%;
  }
  .bubble_4_circle {
    width: 100%;
    height: 100%;
  }
  .bubble_4_text {
    margin: 10% auto 0;
    width: 80%;
    height: 17px;
    font-family: Pretendard;
    text-align: center;
    font-size: 10px;
    letter-spacing: 0;
  }
  .bubble_7 {
    width: 20%;
    justify-self: start;
    align-self: flex-start;
  }
  .bubble_7_circle {
    width: 100%;
  }
  .bubble_7_text {
    margin: 10% auto 0;
    width: 80%;
    height: 7px;
    font-family: Pretendard;
    text-align: center;
    font-size: 10px;
    letter-spacing: 0;
  }
  .bubble_2 {
    width: 20%;
    align-self: flex-end;
    justify-self: start;
  }
  .bubble_2_circle {
    width: 100%;
  }
  .bubble_2_text {
    margin: 10% auto 0;
    width: 80%;
    height: 7px;
    font-family: Pretendard;
    text-align: center;
    font-size: 10px;
    letter-spacing: 0;
  }
  .bubble_5 {
    width: 50%;
  }
  .bubble_5_circle {
    width: 100%;
  }
  .bubble_5_text {
    margin: 10% auto 0;
    width: 80%;
    height: 17px;
    font-family: Pretendard;
    text-align: center;
    font-size: 10px;
    letter-spacing: 0;
  }
  .bubble_3 {
    width: 50%;
  }
  .bubble_3_circle {
    width: 100%;
  }
  .bubble_3_text {
    margin: 10% auto 0;
    width: 80%;
    height: 17px;
    font-family: Pretendard;
    text-align: center;
    font-size: 10px;
    letter-spacing: 0;
  }
  .bubble_6 {
    width: 20%;
    justify-self: end;
    align-self: flex-start;
  }
  .bubble_6_circle {
    width: 100%;
  }
  .bubble_6_text {
    margin: 10% auto 0;
    width: 80%;
    height: 7px;
    font-family: Pretendard;
    text-align: center;
    font-size: 10px;
    letter-spacing: 0;
  }
  .bubble_1 {
    width: 20%;
    justify-self: end;
    align-self: flex-end;
  }
  .bubble_1_circle {
    width: 100%;
  }
  .bubble_1_text {
    margin: 10% auto 0;
    width: 80%;
    height: 7px;
    font-family: Pretendard;
    text-align: center;
    font-size: 10px;
    letter-spacing: 0;
  }

  .MuiSkeleton-circular {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &:after {
      display: block;
      content: "";
      padding-bottom: 100%;
    }
  }
`;

function BubbleSkeleton() {
  return (
    <SkeletonWrap>
      <Stack spacing={1} className='bubbles-wrap'>
        <div className='bubble_1'>
          <Skeleton variant='circular' className='bubble_1_circle'></Skeleton>
          <Skeleton variant='text' className='bubble_1_text'></Skeleton>
        </div>
        <div className='blank'>
          <Skeleton variant='circular' className='blank_circle'></Skeleton>
          <Skeleton variant='text' className='blank_text'></Skeleton>
        </div>
        <div className='bubble_2'>
          <Skeleton variant='circular' className='bubble_2_circle'></Skeleton>
          <Skeleton variant='text' className='bubble_2_text'></Skeleton>
        </div>
        <div className='bubble_3'>
          <Skeleton variant='circular' className='bubble_3_circle'></Skeleton>
          <Skeleton variant='text' className='bubble_3_text'></Skeleton>
        </div>
        <div className='bubble_4'>
          <Skeleton variant='circular' className='bubble_4_circle'></Skeleton>
          <Skeleton variant='text' className='bubble_4_text'></Skeleton>
        </div>
        <div className='bubble_5'>
          <Skeleton variant='circular' className='bubble_5_circle'></Skeleton>
          <Skeleton variant='text' className='bubble_5_text'></Skeleton>
        </div>
        <div className='bubble_6'>
          <Skeleton variant='circular' className='bubble_6_circle'></Skeleton>
          <Skeleton variant='text' className='bubble_6_text'></Skeleton>
        </div>
        <div className='blank'>
          <Skeleton variant='circular' className='blank_circle'></Skeleton>
          <Skeleton variant='text' className='blank_text'></Skeleton>
        </div>
        <div className='bubble_7'>
          <Skeleton variant='circular' className='bubble_7_circle'></Skeleton>
          <Skeleton variant='text' className='bubble_7_text'></Skeleton>
        </div>
      </Stack>
    </SkeletonWrap>
  );
}

export default BubbleSkeleton;
