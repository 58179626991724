import React, { useEffect } from "react";
import styled from "styled-components";
import { COLORS, RADIUS, TEXTSIZE, WIDTH } from "../../theme/constants";
import ModalPortal from "./ModalPortal";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ReactComponent as CheckOffIcon } from "../../assets/svgs/check_box_off.svg";
import { ReactComponent as CheckOnIcon } from "../../assets/svgs/check_box_on.svg";
import { toast } from "react-toastify";
import PicHistory from "./PicHistory";
import Checkout from "./Checkout";
import { setConmmentReport, setReport, setUserReport } from "../../api";
import UserBlockCheck from "./UserBlockCheck";

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);

  .modalDim {
    overflow-y: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 32px);
    max-width: ${WIDTH.mobile};
    min-height: 175px;
    max-height: 95%;
    padding: 20px;
    background: var(--bgColor);
    border-radius: ${RADIUS.medium};
  }

  .modal-wrap {
    display: block;
    width: 100%;
    height: 100%;

    .modal__title {
      font-size: ${TEXTSIZE.large};
      font-weight: 700;
      line-height: 27px;
      height: 27px;
      color: var(--textColor);
    }

    .modal__content {
      display: inline-block;
      font-size: ${TEXTSIZE.medium};
      line-height: 23.8px;
      margin: 12px 0 24px;
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    button:hover {
      background: var(--mainColorLight);
      color: var(--subTextColor);
    }

    .MuiButtonBase-root,
    .MuiButton-root {
      box-shadow: none;
    }
  }

  .button-cancel,
  .button-submit {
    width: calc(50% - 4px);
    height: 48px;
  }

  .button-cancel {
    background: var(--colorF3to3);
    color: ${COLORS.gray_7};
  }

  .button-submit,
  .button-bothSubmit {
    background: var(--mainColor);
    color: var(--bgColor);

    &.Mui-disabled {
      background: var(--bglightColor);
      color: var(--subTextColor);
    }
  }

  .multyButton {
    margin-bottom: 20px;

    + .buttons {
      .button-cancel,
      .button-bothSubmit,
      .button-submit {
        width: fit-content;
        height: 28px;
        padding: 4px 12px;
      }
    }
  }
`;

const BpIcon = styled.span`
  width: 18px;
  height: 18px;
`;

const BpCheckedIcon = styled(BpIcon)`
  position: relative;
  width: 18px;
  height: 18px;
`;

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color='default'
      checkedIcon={
        <BpCheckedIcon>
          <CheckOnIcon className='icon-fill_color1CtoF' />
        </BpCheckedIcon>
      }
      icon={
        <BpIcon>
          <CheckOffIcon />
        </BpIcon>
      }
      {...props}
    />
  );
}

const ModalFrame = ({ modalType = "alert", data, setOnModal, callBack }) => {
  const modalRoot = document.querySelector("#modal-root");
  const [value, setValue] = React.useState(null);

  const closeModal = () => {
    // document.body.classList.remove("default");
    modalRoot.style.display = "none";
    setOnModal(false);
  };

  const closeModalnCallback = () => {
    console.log(callBack);
    document.body.classList.remove("default");
    modalRoot.style.display = "none";
    setOnModal(false);
    callBack();
  };

  const handleWarning = () => {
    if (!data.comment) {
      // 게시물 신고
      setReport(data.id, {
        target: "post",
        target_id: data.id,
        type: value,
        // reason: "신고 사유",
      })
        .then((res) => {
          if (res.data.result) {
            toast("신고가 완료되었습니다.");
            closeModal();
          }
        })
        .catch((err) => console.log(err));
    } else if (data.comment & !data.out_links) {
      // 댓글 신고
      setConmmentReport(data.post_id, data.id, {
        target: "comment",
        target_id: data.id,
        type: value,
      })
        .then((res) => {
          if (res.data.result) {
            toast("신고가 완료되었습니다.");
            closeModal();
          }
        })
        .catch((err) => console.log(err));
    } else if (data.out_links) {
      // TODO: 유저 신고 api 연동
      console.log("user 신고");
      // setUserReport(data.id, {
      //   target: "user",
      //   target_id: data.id,
      //   type: value,
      // })
      //   .then((res) => {
      //     if (res.data.result) {
      //       toast("신고가 완료되었습니다.");
      //       closeModal();
      //     }
      //   })
      //   .catch((err) => console.log(err));
    }
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const modalDataSelet = {
    title: "무엇을 신고하려고 하시나요?",
    list: [
      {
        id: 0,
        text: "성적인 콘텐츠",
        value: "sexual",
      },
      {
        id: 1,
        text: "폭력적이거나 혐오스러운 콘텐츠",
        value: "violent",
      },
      {
        id: 2,
        text: "유해하거나 위험한 행동",
        value: "harm",
      },
      {
        id: 3,
        text: "저작권을 침해하는 행위",
        value: "copyright",
      },
      {
        id: 4,
        text: "아동 학대",
        value: "abuse",
      },
    ],
  };

  return (
    <ModalPortal onClick={closeModal}>
      <Wrapper>
        <div className='modalDim'>
          {modalType === "select" ? (
            <>
              <FormControl style={{ width: "100%" }}>
                <div className='modal-wrap'>
                  <FormLabel id='demo-customized-radios'>
                    <h3 className='modal__title'>{modalDataSelet.title}</h3>
                  </FormLabel>
                  <div className='modal__content'>
                    <RadioGroup
                      onChange={handleRadioChange}
                      aria-labelledby='demo-customized-radios'
                      name='customized-radios'>
                      {modalDataSelet.list.map((item, index) => (
                        <FormControlLabel
                          label={item.text}
                          value={item.value}
                          control={<BpRadio />}
                          key={index}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                </div>
                <div className='buttons'>
                  <Button
                    className='button-cancel'
                    variant='contained'
                    size={modalType === "multyButton" ? "small" : "large"}
                    onClick={closeModal}>
                    취소
                  </Button>
                  <Button
                    disabled={!value}
                    className='button-submit'
                    variant='contained'
                    size={modalType === "multyButton" ? "small" : "large"}
                    onClick={handleWarning}>
                    신고
                  </Button>
                </div>
              </FormControl>
            </>
          ) : modalType === "confirm" ||
            modalType === "multyButton" ||
            modalType === "alert" ||
            modalType === "delete" ? (
            <>
              <div className={`modal-wrap ${modalType}`}>
                <h3 className='modal__title'>
                  {data.title ? data.title : null}
                </h3>
                <div className='modal__content'>{data.text}</div>
              </div>
              <div className='buttons'>
                {modalType === "multyButton" || modalType === "delete" ? (
                  <Button
                    className='button-cancel'
                    variant='contained'
                    size='large'
                    onClick={closeModal}>
                    취소
                  </Button>
                ) : null}
                {modalType === "multyButton" ? (
                  <Button
                    className='button-bothSubmit'
                    variant='contained'
                    size='small'
                    onClick={closeModal}>
                    차단 및 신고
                  </Button>
                ) : null}
                <Button
                  className='button-submit'
                  variant='contained'
                  size='large'
                  onClick={
                    modalType !== "confirm" || callBack
                      ? closeModalnCallback
                      : closeModal
                  }>
                  {data.submitMessage ? data.submitMessage : "확인"}
                </Button>
              </div>
            </>
          ) : modalType === "history" ? (
            <PicHistory closeModal={closeModal} />
          ) : modalType === "charge" ? (
            <Checkout closeModal={closeModal} data={data} />
          ) : modalType === "block" ? (
            <UserBlockCheck
              closeModal={closeModal}
              data={data}
              callBack={closeModalnCallback}
            />
          ) : null}
        </div>
      </Wrapper>
    </ModalPortal>
  );
};

export default React.memo(ModalFrame);
