import React, { useState } from "react";
import styled from "styled-components";
import { COLORS, RADIUS, TEXTSIZE } from "../../theme/constants";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  inputMuiStyle,
  labelMediumMuiStyle,
  textFieldMuiStyle,
  textSmallMuiStyle,
} from "../../utils/commonStyle";
import { FormHelperText, TextField } from "@mui/material";

const Wrapper = styled.div`
  padding-top: 60px;

  .step__name {
    font-size: 28px;
    font-weight: 700;
  }

  .step__description {
    font-size: ${TEXTSIZE.medium};
    font-weight: 400;
    color: ${COLORS.gray_7};
    margin: 16px 0 60px;
  }

  .step__title {
    font-size: ${TEXTSIZE.xxLarge};
    font-weight: 700;
    margin-bottom: 12px;
  }

  section {
    margin-bottom: 40px;
  }

  .MuiFormHelperText-root {
    margin: 4px 0;
    color: var(--notification_errorLight);
  }

  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused {
    color: var(--textColor);
  }

  .MuiSvgIcon-root {
    fill: var(--textColor);
  }

  .hyphen {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 6%;
    height: 59px;
  }

  .select-phone {
    .MuiSelect-select {
      border-radius: 28px;
      background: ${COLORS.grey50};
      padding: 0.75rem;
    }
    .MuiOutlinedInput-notchedOutline {
      border-radius: 28px;
    }
  }

  .phone-input {
    margin: 0;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    height: 59px;
  }
`;

const BootstrapInput = styled(InputBase)`
  margin-top: 3px;
  height: 56px;

  .MuiInputBase-input {
    width: 100%;
    height: 100%;
    padding: 16px 12px;
    background: var(--bglightColor);
    border-radius: ${RADIUS.medium};
    color: var(--textColor);
  }
`;

function Step4({ data, handleChange }) {
  const handleChangeValue = (event) => {
    const { name, value } = event.target;
    handleChange({ [name]: value });
  };

  return (
    <Wrapper>
      <h2 className="step__name">계좌 인증</h2>
      <p className="step__description">
        본 과정은 피카버스 계정에 '정산 받을 계좌'를 등록하는 과정이며, 정산
        등록이 승인된 후 매월 1~10일 사이에 정산 신청을 해주셔야 정산 입금이
        진행됩니다.
      </p>
      <section>
        {/* <p className="step__title">은행명</p> */}
        <InputLabel sx={labelMediumMuiStyle}>{"은행명"}</InputLabel>
        <FormControl fullWidth>
          {/* <InputLabel sx={labelMediumMuiStyle} id="demo-simple-select-label">
            은행명
          </InputLabel> */}
          <Select
            className="select-phone"
            labelId="demo-simple-select-label"
            // label="은행명"
            id="demo-simple-select"
            value={data.bank || ""}
            name="bank"
            onChange={handleChangeValue}
          >
            <MenuItem value="kb">국민은행</MenuItem>
          </Select>
        </FormControl>
      </section>
      <section>
        {/* <p className="step__title">계좌번호</p>
        <FormControl fullWidth>
          <BootstrapInput
            name="account_number"
            value={values.account_number || ""}
            placeholder="- 없이 입력해 주세요."
            onChange={handleChange}
          />
        </FormControl> */}
        <InputLabel sx={labelMediumMuiStyle}>{"계좌번호"}</InputLabel>
        <FormControl fullWidth>
          <TextField
            name="account_number"
            type="number"
            value={data.account_number || ""}
            onChange={handleChangeValue}
            placeholder={"- 없이 입력해 주세요."}
            sx={textFieldMuiStyle}
            inputProps={{
              sx: inputMuiStyle,
              min: 0, 
            }}
          />
        </FormControl>
      </section>
      <section>
        {/* <p className='step__title'>예금주 명</p>
        <FormControl fullWidth>
          <BootstrapInput
            name='account_name'
            value={values.account_name || ""}
            placeholder='정산 받으실 계좌와 동일한 정보를 입력해 주세요.'
            onChange={handleChange}
          />
        </FormControl> */}
        <InputLabel sx={labelMediumMuiStyle}>{"예금주 명"}</InputLabel>
        <FormControl fullWidth>
          <TextField
            name="account_name"
            value={data.account_name || ""}
            onChange={handleChangeValue}
            placeholder={"정산 받으실 계좌와 동일한 정보를 입력해 주세요."}
            sx={textFieldMuiStyle}
            inputProps={{
              sx: inputMuiStyle,
            }}
          />
          {data.account_name && data.tax_username !== data.account_name && (
            <FormHelperText
              sx={{ ...textSmallMuiStyle, color: COLORS.redError }}
            >
              정산 받으실 계좌와 동일한 정보를 입력해 주세요.
            </FormHelperText>
          )}
        </FormControl>
      </section>
    </Wrapper>
  );
}

export default React.memo(Step4);
