import React from "react";
import DrawerComponentList from "./DrawerComponentList";
import { ReactComponent as ReportIcon } from "../../assets/svgs/report.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svgs/delete.svg";
import { ReactComponent as BlockIcon } from "../../assets/svgs/block.svg";

function DrawerChat({ close, handleModal, data, refetch, callBack }) {
  const modalData = [
    {
      text: "신고하기",
      icon: <ReportIcon />,
      fn: () => {
        handleModal("report");
        close();
      },
    },
    {
      text: "차단하기",
      icon: <BlockIcon />,
      fn: () => {
        handleModal("block");
        close();
      },
    },
    {
      text: "게시물 삭제",
      icon: <DeleteIcon />,
      fn: () => {
        handleModal("delete");
        close();
      },
    },
  ];

  return <DrawerComponentList close={close} modalData={modalData} />;
}

export default React.memo(DrawerChat);
