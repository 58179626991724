import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { Box, List, Typography } from "@mui/material";
import NoData from "../../components/NoData";
import CircularComponent from "../process/CircularComponent";
import NewChatHeader from "./NewChatHeader";
import { textLargeMuiStyle } from "../../utils/commonStyle";
import { FONT_WEIGHT, WIDTH } from "../../theme/constants";
import DrawerPortal from "../drawers/DrawerPortal";
import DrawerFrame from "../drawers/DrawerFrame";
import useModalReducer, { MODAL_STATUS } from "../../hooks/useModalReducer";
import ModalComponent from "../../components/modal/ModalComponent";
import Report from "../../components/newComponents/report";
import useCreateReport from "../../hooks/useCreateReport";
import NewChatUserItem from "./NewChatUserItem";
import useGetNewChatUsers from "../../hooks/chat/useGetNewChatUsers";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  padding: 0px 1rem;
  margin-top: 1.25rem;
  margin-bottom: 100px;
  max-width: ${WIDTH.fold};
  margin-left: auto;
  margin-right: auto;
`;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 230px);
  position: relative;
`;

function NewChatUserList(props) {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const { data, isLoading, fetchNextPage } = useGetNewChatUsers();
  const { modalData, dispatchModal } = useModalReducer();
  const { handleReport } = useCreateReport();
  const [reasons, setReasons] = useState([]);
  // Drawer state
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("list");

  //Drawer begin
  const handleDrawer = (value) => {
    const drawerRoot = document.querySelector("#drawer-root");
    drawerRoot.style.display = value ? "block" : "none";
    setDrawerOpen(value);
  };

  const onOpenDrawer = () => {
    setDrawerType("chat");
    handleDrawer(true);
  };
  //Drawer end

  const onClickRight = (type = "add") => {
    if (type === "add") {
    }
  };

  const handleDrawerClick = (type = "select") => {
    switch (type) {
      case "report":
        dispatchModal({
          type: MODAL_STATUS.OPEN,
          data: {
            type: "report",
            btnOKText: "신고",
            btnCancelText: "취소",
            title: "무엇을 신고하려고 하시나요?",
            subTitle: "댓글 신고 시 자동으로 계정 차단이 진행됩니다.",
            content: <Report onChangeReason={setReasons} />,
          },
        });

        break;

      default:
        break;
    }
  };

  const handleReportUser = () => {
    const requestReason = reasons
      .filter((value) => value.checked)
      .map((item) => {
        return { reason: item.reason, type: item.type };
      });
    const requestData = {
      target: "user",
      target_id: "profileData.userID",
      reasons: requestReason,
    };
    // console.log("requestData", requestData);
    handleReport(requestData);
  };

  //modal action
  const handleOk = () => {
    dispatchModal({ type: MODAL_STATUS.CLOSE });

    switch (modalData.type) {
      case "report":
        handleReportUser();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  if (isLoading)
    return (
      <HeightWhenEmpty>
        <CircularComponent />
      </HeightWhenEmpty>
    );

  return (
    <>
      {drawerOpen && (
        <DrawerPortal>
          <DrawerFrame
            setOnDrawer={() => handleDrawer(false)}
            handleModal={handleDrawerClick}
            type={drawerType}
            // data={post}
            // refetch={handleDrawerRefetch}
            callBack={() => navigate(-1)}
          />
        </DrawerPortal>
      )}
      <ModalComponent
        open={modalData.open}
        modalData={modalData}
        handleClose={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        handleOk={handleOk}
        handleCancel={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
        btnOKProps={{}}
        btnCancelProps={{}}
      />
      <NewChatHeader onClickRight={onClickRight} />
      <Wrapper>
        <Typography
          sx={{
            ...textLargeMuiStyle,
            fontWeight: FONT_WEIGHT.xxLarge,
          }}
        >
          {t("choose_friends")}
        </Typography>
        <Box>
          {data?.length > 0 ? (
            <>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  padding: 0,
                }}
              >
                {data?.map((user) => (
                  <NewChatUserItem
                    // key={user.id}
                    // receiverID={user.id}
                    // nickname={user?.name}
                    // profile_image_url={user?.avatar}
                    key={user?.connecting_user_profile?.user_id}
                    receiverID={user?.connecting_user_profile?.user_id}
                    nickname={user?.connecting_user_profile?.nickname}
                    profile_image_url={user?.connecting_user_profile?.profile_image_url}
                  />
                ))}
              </List>
              <p ref={ref}></p>
            </>
          ) : (
            <HeightWhenEmpty>
              <NoData />
            </HeightWhenEmpty>
          )}
        </Box>
      </Wrapper>
    </>
  );
}

export default NewChatUserList;
