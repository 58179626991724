import React, { useState } from "react";
import styled from "styled-components";
import { COLORS, RADIUS, TEXTSIZE } from "../../theme/constants";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box, TextField } from "@mui/material";
import {
  inputMuiStyle,
  labelMediumMuiStyle,
  textFieldMuiStyle,
} from "../../utils/commonStyle";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  padding-top: 60px;

  .step__name {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 60px;
  }

  .step__title {
    font-size: ${TEXTSIZE.xxLarge};
    font-weight: 700;
    margin-bottom: 12px;
  }

  section {
    margin-bottom: 40px;
  }

  .MuiFormHelperText-root {
    margin: 4px 0;
    color: var(--notification_errorLight);
  }

  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused {
    color: var(--textColor);
  }

  .MuiSvgIcon-root {
    fill: var(--textColor);
  }

  .hyphen {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 6%;
    height: 59px;
  }

  .select-phone {
    .MuiSelect-select {
      border-radius: 28px;
      background: ${COLORS.grey50};
      padding: 0.75rem;
    }
    .MuiOutlinedInput-notchedOutline {
      border-radius: 28px;
    }
  }

  .phone-input {
    margin: 0;
    color: var(--textColor);
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    height: 59px;
  }
`;

const BootstrapInput = styled(InputBase)`
  margin-top: 3px;
  height: 56px;

  .MuiInputBase-input {
    width: 100%;
    height: 100%;
    padding: 16px 12px;
    background: var(--bglightColor);
    border-radius: ${RADIUS.medium};
    color: var(--textColor);
  }
`;

function Step3({data, handleChange}) {
  const { t } = useTranslation();
  const handleChangeValue = (event) => {
    const { name, value } = event.target;
    handleChange({[name]: value});
  };

  return (
    <Wrapper>
      <h2 className="step__name">본인 인증</h2>
      <section>
        {/* <p className="step__title">과세 유형</p>
        <FormControl fullWidth>
          <BootstrapInput
            name="type"
            value={values.type || ""}
            placeholder="개인 (사업소득)"
            onChange={handleChange}
          />
        </FormControl> */}
        <InputLabel sx={labelMediumMuiStyle}>{"과세 유형"}</InputLabel>
        <FormControl fullWidth>
          {/* <TextField
            name="type"
            value={values.type || ""}
            onChange={handleChange}
            placeholder={"개인 (사업소득)"}
            sx={textFieldMuiStyle}
            inputProps={{
              sx: inputMuiStyle,
            }}
          /> */}
          <Select
            className="select-phone"
            value={data.tax_type || ""}
            placeholder="개인 (사업소득)"
            name="tax_type"
            onChange={handleChangeValue}
          >
            <MenuItem value={1}>개인</MenuItem>
            <MenuItem value={2}>사업</MenuItem>
          </Select>
        </FormControl>
      </section>
      <section>
        <InputLabel sx={labelMediumMuiStyle}>{t("name")}</InputLabel>
        <FormControl fullWidth>
          <TextField
            name="tax_username"
            value={data.tax_username || ""}
            onChange={handleChangeValue}
            placeholder={"정산 받으실 계좌와 동일한 정보를 입력해 주세요."}
            sx={textFieldMuiStyle}
            inputProps={{
              sx: inputMuiStyle,
            }}
          />
        </FormControl>
      </section>
      <section>
        {/* <p className="step__title">주민등록 번호</p>
        <FormControl sx={{ width: "47%" }}>
          <BootstrapInput
            name="personalNumber_1"
            value={values.personalNumber_1 || ""}
            placeholder="주민등록 번호 앞자리"
            onChange={handleChange}
          />
        </FormControl> */}
        <InputLabel sx={labelMediumMuiStyle}>{"주민등록 번호"}</InputLabel>
        <FormControl sx={{ width: "47%" }}>
          <TextField
            name="personal_number_1"
            type="number"
            value={data.personal_number_1 || ""}
            onChange={handleChangeValue}
            // label={"주민등록 번호"}
            placeholder={"주민등록 번호 앞자리"}
            sx={textFieldMuiStyle}
            inputProps={{
              sx: inputMuiStyle,
              min: 0, 
            }}
          />
        </FormControl>
        <span className="hyphen">-</span>
        <FormControl sx={{ width: "47%" }}>
          <TextField
            name="personal_number_2"
            type="number"
            value={data.personal_number_2 || ""}
            onChange={handleChangeValue}
            placeholder={"주민등록 번호 앞자리"}
            sx={textFieldMuiStyle}
            inputProps={{
              sx: inputMuiStyle,
              min: 0,
            }}
          />
        </FormControl>
        {/* <FormControl sx={{ width: "47%" }}>
          <BootstrapInput
            name="personalNumber_2"
            value={values.personalNumber_2 || ""}
            placeholder="주민등록 번호 뒷자리"
            onChange={handleChange}
          />
        </FormControl> */}
      </section>
      <section>
        {/* <p className="step__title">전화번호</p> */}
        <InputLabel sx={labelMediumMuiStyle}>{"전화번호"}</InputLabel>
        <div className="flex">
          <FormControl sx={{ width: "30%", height: "auto" }}>
            {/* <InputLabel id="phone-code-select-label" sx={labelMediumMuiStyle}>{"통신사"}</InputLabel> */}
            <Select
              className="select-phone"
              labelId="phone-code-select-label"
              id="demo-simple-select"
              value={data.phone_type || ""}
              placeholder="통신사"
              name="phone_type"
              onChange={handleChangeValue}
            >
              <MenuItem value="skt">SKT</MenuItem>
              <MenuItem value="kt">KT</MenuItem>
              <MenuItem value="lg">LG</MenuItem>
              <MenuItem value="save">알뜰폰</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: "70%" }}>
            {/* <BootstrapInput
              className="phone-input"
              name="phone_number"
              value={values.phone_number || ""}
              placeholder="전화번호를 입력해 주세요."
              onChange={handleChange}
            /> */}
            <TextField
              name="phone_number"
              type="number"
              value={data.phone_number || ""}
              onChange={handleChangeValue}
              placeholder={"전화번호를 입력해 주세요"}
              sx={textFieldMuiStyle}
              inputProps={{
                sx: inputMuiStyle,
                min: 0, 
              }}
            />
          </FormControl>
        </div>
      </section>
    </Wrapper>
  );
}

export default React.memo(Step3);
