import { Chip, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { COLORS, RADIUS, TEXTSIZE } from "@theme/constants";
import { Box } from "@mui/system";
import { ReactComponent as PlusIcon } from "@icons/plus.svg";
import { textMediumMuiStyle } from "@utils/commonStyle";
import { useNavigate } from "react-router";
import { GlobalStateContext } from "../../context/GlobalStateProvider";

const Wrapper = styled.div`
  max-width: calc(600px - 38px);
  width: calc(100% - 38px);
  .chip-wrap {
    .swiper {
      margin-left: 0;
    }
  }

  .chip-slide {
    width: fit-content !important;
  }

  .chip {
    border: 1px solid var(--bglightColor);
    background: var(--bgColor);

    span {
      color: var(--textColor);
    }

    .MuiChip-deleteIcon {
      font-size: ${TEXTSIZE.large};
      color: ${COLORS.gray_9};

      &:hover {
        color: var(--textColor);
      }
    }
  }
`;

function Categories(props) {
  const { tags, selectedTag, isEdit = false, onClickTag, onDelete, redirect = "/search" } = props;

  const navigate = useNavigate();
  const { setCallBackRedirectCategory } = useContext(GlobalStateContext);

  const onClickAddMore = () => {
    setCallBackRedirectCategory(redirect);
    navigate(`${redirect}/category`);
  };

  return (
    <Wrapper>
      <Stack className='chip-wrap' direction='row' spacing={1}>
        <Swiper style={{ marginBottom: "10px" }} spaceBetween={5} slidesPerView={"auto"}>
          {tags.length > 0 &&
            tags.map((item) => (
              <SwiperSlide className='chip-slide' key={item.id}>
                <Chip
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.25rem",
                      }}
                    >
                      {item.icon && (
                        <img
                          src={item.icon}
                          style={{
                            width: "1rem",
                            height: "1rem",
                          }}
                        />
                      )}
                      <Typography
                        sx={{
                          ...textMediumMuiStyle,
                          lineHeight: "22px",
                          // color: COLORS.black1 ,
                          color: selectedTag === item.id ? COLORS.white : COLORS.grey600,
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  }
                  sx={{
                    width: "fit-content",
                    cursor: "pointer",
                    color: selectedTag === item.id ? COLORS.white : COLORS.grey700,
                    backgroundColor: selectedTag === item.id ? COLORS.primary4 : COLORS.white,
                    fontSize: TEXTSIZE.medium,
                    textAlign: "center",
                    border: `1px solid ${COLORS.grey100}`,
                    borderRadius: RADIUS.xLarge,
                    height: "30px",
                    "&:hover": {
                      color: COLORS.white,
                      backgroundColor: COLORS.primary4,
                      boxShadow: "none",
                      "& .MuiChip-label": {
                        "& .MuiTypography-root": {
                          color: COLORS.white,
                        },
                      },
                    },
                  }}
                  onClick={() => onClickTag(item.id)}
                />
              </SwiperSlide>
            ))}
          <SwiperSlide className='chip-slide'>
            <Chip
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.25rem",
                  }}
                >
                  <PlusIcon />
                </Box>
              }
              sx={{
                width: "fit-content",
                cursor: "pointer",
                color: COLORS.grey700,
                backgroundColor: COLORS.white,
                fontSize: TEXTSIZE.medium,
                textAlign: "center",
                // px: 1,
                // py: 2,
                width: "30px",
                height: "30px",
                border: `1px solid ${COLORS.grey100}`,
                borderRadius: RADIUS.xLarge,
                "&:hover": {
                  color: COLORS.white,
                  backgroundColor: COLORS.primary4,
                  boxShadow: "none",
                  "& .MuiChip-label": {
                    "& svg": {
                      fill: COLORS.white,
                    },
                  },
                },
                "& .MuiChip-label": {
                  padding: 0,
                  "& svg": {
                    fill: COLORS.grey600,
                  },
                },
              }}
              onClick={onClickAddMore}
            />
          </SwiperSlide>
        </Swiper>
      </Stack>
    </Wrapper>
  );
}

export default Categories;
