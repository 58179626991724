import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { COLORS, LINE_HEIGHT, TEXTSIZE } from "@theme/constants";
import checkBoxImage from "@images/checkBox.svg";
import checkedBoxImage from "@images/checkedBox.svg";

function RememberPurchase(props) {
  const { setCheckedPurchase } = props;
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setCheckedPurchase(checked);
  }, [checked]);

  return (
    <FormControlLabel
      label={
        <Typography
          sx={{
            color: COLORS.grey900,
            fontSize: TEXTSIZE.medium,
            lineHeight: LINE_HEIGHT.xxxLarge,
          }}
        >
          {"다시 묻지 않기"}
        </Typography>
      }
      control={
        <Checkbox
          checked={checked}
          //   defaultChecked={true}
          onChange={() => setChecked(!checked)}
          icon={<img src={checkBoxImage} />}
          checkedIcon={<img src={checkedBoxImage} />}
        />
      }
    />
  );
}

export default RememberPurchase;
