import { Box, Grid } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import MarketItemHorizontal from "./MarketItemHorizontal";

function MarketList(props) {
  const { data, selectedTag, LIMIT_POST, fetchMoreData } = props;
  return (
    <>
      <InfiniteScroll
        dataLength={data.length}
        next={() =>
          fetchMoreData({
            category_id: selectedTag === "전체" ? "" : selectedTag,
            offset: data.length,
            limit: LIMIT_POST,
          })
        }
        hasMore={true}
        scrollableTarget="scrollableContent"
      >
        <Grid container spacing={2}>
          {data.length > 0 &&
            data.map((post, index) => (
              <Grid item xs={12} md={6} key={post.id}>
                <Box sx={{ mb: 3 }}>
                  {/* <MarketItemVertical
                    post={post}
                    index={index}
                    data={post.files?.[0]}
                    fetchData={() =>
                      fetchMoreData({
                        category_id: selectedTag === "전체" ? "" : selectedTag,
                        offset: 0,
                        limit: data.length,
                      })
                    }
                  /> */}
                  <MarketItemHorizontal
                    post={post}
                    index={0}
                    data={post.files?.[0]}
                    fetchData={() =>
                      fetchMoreData({
                        category_id: selectedTag === "전체" ? "" : selectedTag,
                        offset: 0,
                        limit: data.length,
                      })
                    }
                  />
                </Box>
              </Grid>
            ))}
        </Grid>
      </InfiniteScroll>
    </>
  );
}

export default MarketList;
