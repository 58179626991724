import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as AlertIcon } from "../../assets/svgs/alert.svg";
import { TEXTSIZE, RADIUS, COLORS, WIDTH } from "../../theme/constants";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import ModalPortal from "../../components/modal/ModalPortal";
import ModalFrame from "../../components/modal/ModalFrame";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  inputMuiStyle,
  labelMediumMuiStyle,
  textFieldMuiStyle,
} from "../../utils/commonStyle";
import { Button, InputLabel, TextField } from "@mui/material";
import { handleError } from "../../utils/commonFunction";
import { getUserProfile, getUserWithdrawer, postUserWithdrawer } from "../../api";
import { useAuth } from "../../context/Auth";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  // padding: 60px 0 16px;
  // min-height: 100vh;

  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }

  .step__name {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 60px;
  }

  .section {
    margin-bottom: 40px;
  }

  .step__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${TEXTSIZE.xxLarge};
    font-weight: 700;
    margin-bottom: 12px;
  }

  .text--title {
    display: flex;
    align-items: center;
  }

  .icon--alert,
  .button {
    cursor: pointer;
  }

  .text--sub {
    font-size: ${TEXTSIZE.large};
    font-weight: 600;
    color: ${COLORS.gray_7};
  }

  .flex-row {
    display: flex;
    gap: 20px;
    width: 100%;

    .column {
      width: 100%;
    }
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--colorF3to3);
    border-radius: ${RADIUS.medium};
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 12px;
    flex-basis: 33%;
  }

  .info__title,
  .info__text {
    font-size: ${TEXTSIZE.medium};
    font-weight: 400;
  }

  .info__title {
    color: ${COLORS.gray_7};
    margin-bottom: 16px;
  }

  .buttons {
    width: 100%;
    margin: auto auto 0;
    padding: 16px 0;
    text-align: right;
    border-top: 1px solid var(--colorF3to3);
  }

  .button--submit {
    // width: 120px;
    // height: 48px;
    // font-size: ${TEXTSIZE.large};
    // font-weight: 600;
    // background: var(--textColor);
    // color: var(--bgColor);
    // border-radius: ${RADIUS.medium};
    padding: 0.75rem 1rem;
    border-radius: ${RADIUS.xxLarge};
    background: ${COLORS.grey50};
    color: ${COLORS.grey600};
    &:hover {
      background: ${COLORS.primary4};
      color: ${COLORS.white};
    }

    &.Mui-disabled {
      background: ${COLORS.grey100};
      color: ${COLORS.grey600};
      cursor: not-allowed;
    }
  }
`;

const BootstrapInput = styled(InputBase)`
  margin-top: 3px;
  height: 56px;

  .MuiInputBase-input {
    width: 100%;
    height: 100%;
    padding: 16px 12px;
    background: var(--bglightColor);
    border-radius: ${RADIUS.medium};
    font-size: ${TEXTSIZE.medium};
  }
`;
const BootstrapInputWeight = styled(OutlinedInput)`
  margin-top: 3px;
  height: 56px;
  background: var(--bglightColor);
  border-radius: ${RADIUS.medium};

  .MuiInputBase-input {
    width: 100%;
    height: 100%;
    padding: 16px 12px;
    font-weight: 400;
    font-size: ${TEXTSIZE.medium};
  }

  .MuiTypography-root {
    font-weight: 600;
  }
`;

function Claim() {
  const navigate = useNavigate();
  // const [isDisabled, setIsDisabled] = useState(true);
  const { logout } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const [requestCode, setRequestCode] = useState("");

  const [values, setValues] = useState({
    able: 0,
    amount: "",
    left: 0,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    // setIsDisabled(false);
    if (Number(values.able) - Number(value) < 0) return;
    setValues({
      ...values,
      [name]: value ? Number(value) : "",
      left: Number(values.able) - Number(value),
    });
  };

  const handleModal = () => {
    const modalRoot = document.querySelector("#modal-root");
    modalRoot.style.display = "block";
    setModalOpen(true);
  };

  const analytics = getAnalytics();

  const handleGetUserWithdrawer = async () => {
    try {
      const res = await getUserWithdrawer();
      if (res) {
        setUserData(res[0]);
      }
    } catch (error) {
      handleError(error, logout);
    }
  };

  //get user profile
  const handleGetUserProfile = async () => {
    try {
      const res = await getUserProfile();
      if (!res) return;
      const ableValue =
        Number(res.user_fund.bonus) + Number(res.user_fund.revenue);
      setValues({ ...values, able: ableValue, left: ableValue });
    } catch (error) {
      handleError(error, logout);
    }
  };

  // handleSubmit to withdrawer
  const handleSubmit = async () => {
    console.log("submit");
    try {
      const requestData = {
        ...userData,
        request_code: requestCode,
        amount: Number(values.amount),
      };
      delete requestData?.terms;
      delete requestData?.able;
      delete requestData?.left;
      await postUserWithdrawer(requestData);
      navigate("/complete/claim", {state: {amount: Number(values.amount)}});
    } catch (error) {
      handleError(error, logout);
    }
  };

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "claim",
      firebase_screen_class: "claim",
    });
    setRequestCode("P"+Math.trunc(Math.random()*1000000000000));
    handleGetUserWithdrawer();
    handleGetUserProfile();
  }, []);

  // const isDisable = () => {
  //   let result = false;
  //   if (values?.amount < 50000 || values?.left < 0) {
  //     result = true;
  //   }
  //   console.log("result", result);
  //   setIsDisabled(result);
  // };
  // useEffect(() => {
  //   isDisable();
  // }, [values]);

  return (
    <Wrapper className="claim">
      <div className="contents">
        <h2 className="step__name">정산 신청</h2>
        <section className="section">
          <div className="step__title">
            <div className="text--title">
              {/* 신청 가능한 PIC */}
              <InputLabel sx={{ ...labelMediumMuiStyle, marginBottom: 0 }}>
                {"신청 가능한 PIC"}
              </InputLabel>
              <Tooltip title="다른 유저에게 받은 PIC만 정산이 가능해요 :)">
                <AlertIcon className="icon--alert" />
              </Tooltip>
            </div>
            <button
              type="button"
              className="text--sub button"
              onClick={handleModal}
              disabled
            >
              자세히 보기
            </button>
            {modalOpen && (
              <ModalPortal>
                <ModalFrame
                  modalType="history"
                  setOnModal={setModalOpen}
                ></ModalFrame>
              </ModalPortal>
            )}
          </div>
          <FormControl fullWidth>
            {/* <BootstrapInputWeight
              name='able'
              defaultValue={values.able}
              endAdornment={<InputAdornment position='end'>PIC</InputAdornment>}
              aria-describedby='outlined-weight-helper-text'
              inputProps={{
                "aria-label": "PIC",
              }}
              readOnly
            /> */}
            <TextField
              name="able"
              value={values.able}
              sx={textFieldMuiStyle}
              inputProps={{
                sx: inputMuiStyle,
              }}
              disabled={true}
            />
          </FormControl>
        </section>
        <section className="flex-row section">
          <div className="column">
            {/* <div className="step__title">
              <div className="text--title">정산할 PIC</div>
            </div> */}
            <InputLabel sx={{ ...labelMediumMuiStyle }}>
              {"정산할 PIC"}
            </InputLabel>
            <FormControl fullWidth>
              {/* <BootstrapInputWeight
                name="want"
                placeholder="50,000PIC 이상 입력해 주세요."
                endAdornment={
                  <InputAdornment position="end">PIC</InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "PIC",
                }}
                onChange={handleChange}
              /> */}
              <TextField
                name="amount"
                value={values.amount}
                onChange={handleChange}
                placeholder={"50,000PIC 이상 입력해 주세요."}
                sx={textFieldMuiStyle}
                type="number"
                inputProps={{
                  sx: inputMuiStyle,
                  min: 0, 
                }}
              />
            </FormControl>
          </div>
          <div className="column">
            {/* <div className="step__title">
              <div className="text--title">잔여 PIC</div>
            </div> */}
            <InputLabel sx={{ ...labelMediumMuiStyle }}>
              {"잔여 PIC"}
            </InputLabel>
            <FormControl fullWidth>
              {/* <BootstrapInputWeight
                name="left"
                value={values.left}
                endAdornment={
                  <InputAdornment position="end">PIC</InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "PIC",
                }}
                readOnly
              /> */}
              <TextField
                name="left"
                value={values.left}
                sx={textFieldMuiStyle}
                inputProps={{
                  sx: inputMuiStyle,
                }}
                disabled={true}
              />
            </FormControl>
          </div>
        </section>
        <section className="section">
          {/* <div className="step__title">
            <div className="text--title">____님의 피카버스 정산 코드</div>
          </div> */}
          <InputLabel sx={{ ...labelMediumMuiStyle }}>
            {"____님의 피카버스 정산 코드"}
          </InputLabel>
          <FormControl fullWidth>
            {/* <BootstrapInput
              name="type"
              id=""
              readOnly
              defaultValue="P000000000000"
              onChange={handleChange}
            /> */}
            <TextField
              name="requestCode"
              // value={values.requestCode || ""}
              value={requestCode}
              onChange={() => console.log("")}
              sx={textFieldMuiStyle}
              inputProps={{
                sx: inputMuiStyle,
              }}
              disabled={true}
            />
          </FormControl>
        </section>
        <section>
          <div className="step__title">
            {/* <div className="text--title">정산 계좌 번호</div> */}
            <InputLabel sx={{ ...labelMediumMuiStyle }}>
              {"정산 계좌 번호"}
            </InputLabel>
            <button
              type="button"
              className="text--sub button"
              onClick={() => navigate("/signup-account", { state: { step: 3 } })}
            >
              수정하기
            </button>
          </div>
          <div className="info">
            <dl className="flex-column">
              <dt className="info__title">은행명</dt>
              <dd className="info__text">{userData?.bank}</dd>
            </dl>
            <dl className="flex-column">
              <dt className="info__title">계좌</dt>
              <dd className="info__text">{userData?.account_number}</dd>
            </dl>
            <dl className="flex-column">
              <dt className="info__title">예금주</dt>
              <dd className="info__text">{userData?.account_name}</dd>
            </dl>
          </div>
        </section>
      </div>
      <div className="buttons">
        <Button
          type="button"
          className="button button--submit"
          // onClick={() => navigate("/complete/claim")}?
          disabled={values?.amount < 50000 || values?.left < 0}
          onClick={handleSubmit}
        >
          정산 신청
        </Button>
      </div>
    </Wrapper>
  );
}

export default React.memo(Claim);
