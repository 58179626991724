import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { INNER_PADDING } from "../../theme/constants";
import RiveComponent from "../newComponents/riv/RivCosmos";

const Container = styled.div`
  position: relative;
  overflow-y: auto;
  min-height: calc(calc(var(--vh, 1vh) * 100));
  height: fit-content;
  padding: 8px ${INNER_PADDING.medium};
`;

function EmptyLayoutRiv(props) {
  return (
    <>
      <RiveComponent />
      <Container className="container" {...props}>
        <Outlet />
      </Container>
    </>
  );
}

export default EmptyLayoutRiv;
