import React from "react";
import { WIDTH } from "../../theme/constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import DrawerListComponent from "./DrawerListComponent";
import { ReactComponent as ShareIcon } from "@icons/share.svg";
import { ReactComponent as SettingIcon } from "@icons/setting.svg";
import { ReactComponent as PICIcon } from "@icons/PIC_black.svg";
import { useTranslation } from "react-i18next";

function SettingDrawer({ close, data }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const modalData = [
    {
      text: t("button.setting"),
      icon: <SettingIcon />,
      fn: () => {
        navigate("/setting");
      },
    },
    // {
    //   text: "프로필 편집",
    //   icon: <EditIcon />,
    //   fn: () => {
    //     navigate("/profile/edit/");
    //     // console.log("프로필 편집");
    //   },
    // },
    {
      text: t("button.share_my_profile"),
      icon: <ShareIcon />,
      fn: () => {
        navigator.clipboard.writeText(
          `${window.location.origin}/user-profile/${data.nickname}`
        );
        close();
        toast("링크가 복사되었습니다.");
      },
    },
    {
      text: t("button.withdrawal", { content: "PIC" }),
      icon: <PICIcon />,
      fn: () => {
        navigate("/calculate");
        // console.log("마이 PIC 출금");
      },
    },
  ];

  return (
    <DrawerListComponent close={close} modalData={modalData} />
  );
}

export default React.memo(SettingDrawer);
