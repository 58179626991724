import React, { useEffect, useRef, useState } from "react";
import { getAllCategories } from "@api";
import { Box, Button, Chip, Typography } from "@mui/material";
import { COLORS, RADIUS, TEXTSIZE, WIDTH } from "@theme/constants";
import {
  textMediumMuiStyle,
  buttonLargeMuiStyle,
  textSmallMuiStyle,
} from "@utils/commonStyle";
import { useMediaQuery } from "react-responsive";
// import useWindowDimensions from "@hooks/useWindownDimensions";
import { useNavigate } from "react-router";
import { useAuth } from "../../context/Auth";
import { handleError } from "@utils/commonFunction";
import { useElementSize } from "@hooks";

function PostCategory(props) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { categorySelected, handleSubmit } = props;
  const [categories, setCategories] = useState([]);
  // const [userCategories, setUserCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });
  const headerRef = useRef(null);
  const headerSize = useElementSize(headerRef);
  const buttonSubmitRef = useRef(null);
  const buttonSize = useElementSize(buttonSubmitRef);
  // const { height, width } = useWindowDimensions();

  const onClickTag = (value) => {
    const selectedTagsTmp = structuredClone(selectedTags);

    const index = selectedTagsTmp.map((item) => item.id).indexOf(value.id);
    if (index >= 0) {
      selectedTagsTmp.splice(index, 1);
    } else {
      selectedTagsTmp.push(value);
    }

    setSelectedTags(selectedTagsTmp);
  };

  const handleGetCategories = async () => {
    try {
      const res = await getAllCategories();

      if (res?.list) {
        const resTmp = [...res.list];
        setCategories(resTmp);
      }
    } catch (error) {
      handleError(error, logout, navigate);
      // console.error("handleGetTag error", error);
    }
  };

  const isSelectedId = (id) => {
    return selectedTags.map((item) => item.id).includes(id);
  };

  useEffect(() => {
    handleGetCategories();
    setSelectedTags(categorySelected);
    // console.log('useEffect')
  }, []);

  // useEffect(() => {
  //   console.log("headerSize", headerSize);
  // }, [headerSize]);

  // useEffect(() => {
  //   console.log("buttonSize", buttonSize);
  // }, [buttonSize]);

  return (
    <>
      <Box
        sx={{
          height: "calc(100% - 40px)",
        }}
        // sx={{
        //   overflow: "auto",
        //   height: `calc(100vh - 56px - ${"0px"})`,
        //   px: 2,
        // }}
        // id={"scrollableContent"}
      >
        <Box
          sx={{
            mb: 4,
          }}
          ref={headerRef}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "26px",
              color: COLORS.black1,
              my: 2,
            }}
          >
            콘텐츠 카테고리 선택하기
          </Typography>
          <Typography
            sx={{
              //styleName: Title/1-1;
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "24px",
              color: COLORS.black1,
              mb: 1,
              whiteSpace: "pre-line",
            }}
          >
            {`보고싶은 콘텐츠의 카테고리를 선택해\n나만을 위한 유니버스를 만들어 보세요!`}
          </Typography>
          <Typography
            sx={{
              ...textSmallMuiStyle,
              fontWeight: "400",
              lineHeight: "18px",
            }}
          >
            {`최대 ${categories?.length}개까지 선택 가능합니다!`}
          </Typography>
        </Box>
        <Box
          sx={{
            height: `calc(100% - ${
              headerSize.height + buttonSize.height
            }px - 32px)`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexFlow: "wrap",
              gap: "0.75rem 0.25rem",
              my: 0.25,
            }}
          >
            {categories.length > 0 &&
              categories.map((item) => (
                <Chip
                  key={item.id}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // gap: "0.75rem 0.25rem",
                      }}
                    >
                      {item.icon && (
                        <img
                          src={item.icon}
                          style={{
                            width: "1rem",
                            height: "1rem",
                          }}
                        />
                      )}
                      <Typography
                        sx={{
                          ...textMediumMuiStyle,
                          lineHeight: "22px",
                          // color: COLORS.black1 ,
                          color: isSelectedId(item.id)
                            ? COLORS.white
                            : COLORS.grey600,
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  }
                  sx={{
                    width: "fit-content",
                    cursor: "pointer",
                    color: isSelectedId(item.id)
                      ? COLORS.white
                      : COLORS.grey700,
                    backgroundColor: isSelectedId(item.id)
                      ? COLORS.primary4
                      : COLORS.white,
                    fontSize: TEXTSIZE.medium,
                    textAlign: "center",
                    border: `1px solid ${COLORS.grey100}`,
                    borderRadius: RADIUS.xLarge,
                    height: "30px",
                    "&:hover": {
                      // color: COLORS.white,
                      backgroundColor: isSelectedId(item.id)
                        ? COLORS.primary4
                        : COLORS.white,
                      boxShadow: "none",
                      "& .MuiChip-label": {
                        "& .MuiTypography-root": {
                          color: isSelectedId(item.id)
                            ? COLORS.white
                            : COLORS.grey700,
                        },
                      },
                    },
                  }}
                  onClick={() => onClickTag(item)}
                />
              ))}
          </Box>
        </Box>
        <Box
          sx={{
            zIndex: 10,
            bottom: 10,
            mt: 1,
            // width: `${isPc ? 826 : width - 32}px`,
            // maxWidth: `calc(794px + 32px)`,
            // position: "fixed",
          }}
          ref={buttonSubmitRef}
        >
          <Button
            sx={{ ...buttonLargeMuiStyle }}
            disabled={selectedTags?.length === 0}
            variant="contained"
            size="large"
            onClick={() => handleSubmit(selectedTags)}
          >
            선택 완료
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default PostCategory;
