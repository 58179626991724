import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function PaymentFail() {
  const navigate = useNavigate();
  let code = new URL(window.location.href).searchParams.get("code");
  let message = new URL(window.location.href).searchParams.get("message");

  useEffect(() => {
    toast.error(message + "이전 페이지로 이동합니다");
    setTimeout(() => {
      navigate(-2);
    }, 2000);
  }, []);

  return;
}
