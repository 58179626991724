import styled from "styled-components";
import { COLORS } from "../theme/constants";

// export const WrapperStyle = styled.div`{
//     .text {
//         font-size: 14px;
//         font-weight: 400;
//         line-height: 18px;
//         text-align: left;
//         color: ${COLORS.black1}

//         .text--small {
//             font-size: 12px;
//             line-height: 16px;

//             .text--small--w5 {
//                 font-weight: 500;
//             }
//         }

//         .text--medium {
//             .text--medium-l22 {
//                 line-height: 22px;
//             }
//             .text--medium--w5 {
//                 font-weight: 500;
//             }
//         }

//     }
// }`

export const buttonLargeMuiStyle = {
  width: "100%",
  padding: "1rem",
  borderRadius: "32px",
  backgroundColor: COLORS.grey50,
  color: COLORS.grey600,
  boxShadow: "none",
  "&:hover": {
    backgroundColor: COLORS.primary4,
    color: COLORS.white,
  },
  "&.Mui-disabled": {
    backgroundColor: COLORS.grey100,
    color: COLORS.grey600,
  },
};

export const buttonMuiStyle = {
  padding: "0.75rem",
  width: "100%",
  borderRadius: "28px",
  backgroundColor: COLORS.grey50,
  color: COLORS.grey600,
  boxShadow: "none",
  "&:hover": {
    backgroundColor: COLORS.primary4,
    color: COLORS.white,
  },
  "&.Mui-disabled": {
    backgroundColor: COLORS.grey100,
    color: COLORS.grey600,
  },
};

export const textXLargeMuiStyle = {
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "22px",
  color: COLORS.black1,
};

export const textLargeMuiStyle = {
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "24px",
  color: COLORS.black1,
};

export const textMediumMuiStyle = {
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "18px",
  color: COLORS.black1,
};

export const textSmallMuiStyle = {
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",
  color: COLORS.grey500,
};

export const labelMediumMuiStyle = {
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "18px",
  marginBottom: "0.5rem",
  color: COLORS.black1,
};

export const textFieldMuiStyle = {
  width: "100%",
  "& .MuiOutlinedInput-notchedOutline": {
    backgroundColor: COLORS.grey50,
    border: "none",
    borderRadius: "28px",
  },
  "& .MuiInputBase-root": {
    height: "48px",
  },
};

export const inputMuiStyle = {
  ...textMediumMuiStyle,
  borderRadius: "36px",
  height: "48px",
  padding: "0px 16px",
  zIndex: 1,
  "&::placeholder": {},
};

export const inputAdornmentMuiStyle = {
  zIndex: 1,
  cursor: "pointer",
};

export const truncateStyle = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%",
  overflow: "hidden",
};
