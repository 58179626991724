import { Box, CircularProgress, List, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { COLORS, FONT_WEIGHT, LINE_HEIGHT, TEXTSIZE, WIDTH } from "../../theme/constants";
import { textSmallMuiStyle } from "../../utils/commonStyle";
import { Swiper, SwiperSlide } from "swiper/react";
import { handleError } from "../../utils/commonFunction";
import { useAuth } from "../../context/Auth";
import { getUserUnlockOrDonate } from "../../api";
import NoData from "../../components/NoData";
import CircularComponent from "../process/CircularComponent";
import ItemMemberNotConnected from "./ItemMemberNotConnected";
import InfiniteScroll from "react-infinite-scroll-component";

const Wrapper = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  max-width: 632px;
  width: 100%;

  @media screen and (min-width: ${WIDTH.desktop}) {
    padding: 0;
    max-width: 600px;
  }
`;

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 60px);
  position: relative;
`;

function ListMemberNotConnectedPage(props) {
  const { logout } = useAuth();
  const { postID } = useParams();
  const navigate = useNavigate();
  const { refreshPage, type } = props;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMoreData = async (newRequestParam) => {
    try {
      if (data.length == 0) {
        setIsLoading(true);
      }

      const requestParam = {
        type: type,
        connect: false,
        limit: 10,
        offset: data.length,
        ...newRequestParam,
      };
      const res = await getUserUnlockOrDonate(postID, requestParam);
      setIsLoading(false);
      if (!res?.list) return;
      if (newRequestParam?.offset === 0) {
        setData(res.list);
      } else {
        setData((prevState) => [...prevState, ...res.list]);
      }
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const fetchData = () => {
    setData(() => []);
    fetchMoreData({ offset: 0 });
    refreshPage();
  };

  useEffect(() => {
    fetchMoreData({ offset: 0 });
  }, []);

  if (isLoading)
    return (
      <HeightWhenEmpty>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </HeightWhenEmpty>
    );

  return (
    <Wrapper>
      <Box>
        {data.length > 0 ? (
          <InfiniteScroll dataLength={data.length} next={fetchMoreData} hasMore={true}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              {data.map((user) => (
                <ItemMemberNotConnected
                  {...user}
                  fetchData={fetchData}
                  key={user.id}
                  listItemStyle={{ maxWidth: "100%", margin: "1rem 0" }}
                />
              ))}
            </List>
          </InfiniteScroll>
        ) : (
          <HeightWhenEmpty>
            <NoData />
          </HeightWhenEmpty>
        )}
      </Box>
    </Wrapper>
  );
}

export default ListMemberNotConnectedPage;
