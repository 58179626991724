import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const SkeletonWrap = styled.div`
  margin-top: 20px;

  .flex {
    display: flex;

    &.jc-sb {
      justify-content: space-between;
    }

    &.fd-column {
      flex-direction: column;
    }

    &.fd-row {
      flex-direction: row;
    }

    &.ai-center {
      align-items: center;
    }
  }
`;

function picHistorySkeleton() {
  return (
    <SkeletonWrap>
      <Stack spacing={4}>
        <div className='flex jc-sb fd-row ai-center'>
          <Skeleton variant='text' width={"2rem"} height={".7rem"} />
          <Skeleton variant='text' width={"2rem"} height={".7rem"} />
          <Skeleton variant='text' width={"1rem"} height={".7rem"} />
        </div>
        <Stack spacing={1} style={{ marginTop: "13px" }}>
          <Skeleton variant='text' width={"100%"} height={"3rem"} />
          <Skeleton variant='text' width={"100%"} height={"3rem"} />
          <Skeleton variant='text' width={"100%"} height={"3rem"} />
          <Skeleton variant='text' width={"100%"} height={"3rem"} />
          <Skeleton variant='text' width={"100%"} height={"3rem"} />
          <Skeleton variant='text' width={"100%"} height={"3rem"} />
        </Stack>
      </Stack>
    </SkeletonWrap>
  );
}

export default picHistorySkeleton;
