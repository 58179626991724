import { useInfiniteQuery } from "@tanstack/react-query";
// import { getChatMessageInPrivate } from "../../api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import { handleError } from "../../utils/commonFunction";
import { useCallback, useEffect, useState } from "react";
import { getChatMessageInPrivate } from "../../service/chat_services";

function useGetMessages(props) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { receiverID } = props;

  const [filters, setFilters] = useState({ pageSize: 20 });
  const [messages, setMessages] = useState([]);

  const handleChangeFilters = useCallback((filterParams) => {
    setFilters((_prev) => filterParams);
  }, []);

  const fetchData = async ({ pageParam, queryKey }) => {
    // console.log("pageParam", pageParam, queryKey);
    try {
      const res = await getChatMessageInPrivate(receiverID, {
        lastToken: pageParam,
        ...filters,
      });
      return res;
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [receiverID],
    queryFn: fetchData,
    initialPageParam: "",
    keepPreviousData: true,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage) return;
      const { pagination = {} } = lastPage || {};
      const { hasNextPage, lastToken } = pagination;
      return hasNextPage ? lastToken : undefined;
    },
  });

  useEffect(() => {
    const newMessages = [];
    if (!data?.pages?.[0]) return;
    data?.pages?.forEach((message) => {
      if (message?.items.length > 0) {
        // console.log('message?.items',message?.items)
        const messagesTmp = structuredClone(message?.items).reverse();
        newMessages.push(...messagesTmp);
      }
    });
    setMessages(newMessages);
  }, [data]);

  return {
    data: messages,
    isLoading,
    filters,
    fetchNextPage,
    handleChangeFilters,
  };
}

export default useGetMessages;
