import darkLogo from "../assets/svgs/logo-light.svg";
import lightLogo from "../assets/svgs/logo-dark.svg";

let themeMode = window.localStorage.getItem("theme") || "dark";

export let LOGO = themeMode !== "dark" ? lightLogo : darkLogo;

export const COLORS = {
  mainColor: "#A347FF",
  black: "#333",
  bgBlack: "#1c1c1c",
  gray_5: "#555",
  gray_7: "#777",
  gray_9: "#999",
  lightGray_b: "#bbb",
  lightGray_d: "#ddd",
  lightGray_f3: "#f3f3f3",
  redError: "#EB4325",
  white: "#FFFFFF",
  black0: "#000000",
  black1: "#1E1E1E",
  grey25: "#F5F5F5",
  grey50: "#EBEBEB",
  grey100: "#D9D9D9",
  grey200: "#D3D3D5",
  grey300: "#C0C0C0",
  grey400: "#A6A6A6",
  grey500: "#89888D",
  grey600: "#707070",
  grey700: "#555555",
  grey800: "#3B3B3B",
  grey900: "#303030",
  primary3: "#690AAE",
  primary4: "#8F0DED",
  primary6: "#BB69F7",
  primary8: "#D39EFA",
};

export const TEXTSIZE = {
  xSmall: "10px",
  small: "12px",
  medium: "14px",
  large: "16px",
  xLarge: "18px",
  xxLarge: "20px",
  xxxLarge: "24px",
};

export const RADIUS = {
  medium: "8px",
  pc: "12px",
  large: "16px",
  xLarge: "24px",
  xxLarge: "28px",
  xxxLarge: "32px",
  xxxxLarge: "36px",
  superLarge: "40px",
};

export const INNER_PADDING = {
  medium: "16px",
};

export const HEIGHT = {
  pc: "calc(calc(var(--vh, 1vh) * 100) - 80px)",
  content: "calc(calc(var(--vh, 1vh) * 100) - 56px - 62px)",
  noNavigation: "calc(calc(var(--vh, 1vh) * 100) - 56px - 16px)",
  navigation: "62px",
  header_mo: "56px",
};

export const WIDTH = {
  desktop: "1190px",
  tablet: "768px",
  mobile: "767px",
  fold_from: "516px",
  fold: "674px",
  fold_small: "320px",
  desktop_main_contents: "500px",
  desktop_contents: "794px",
  desktop1024: "1024px",
  desktop_main: "826px",
  desktop_900: "900px",
};

export const LINE_HEIGHT = {
  xSmall: "10px",
  small: "12px",
  medium: "14px",
  large: "16px",
  xLarge: "18px",
  xxLarge: "20px",
  xxxLarge: "22px",
  xxxxLarge: "24px",
  superLarge: "24px",
};

export const ICON_SIZE = {
  small: "24px",
  medium: "32px",
  xMedium: "36px",
  large: "48px",
};

export const EXPIRES_IN = 180;

export const PIC_TYPE = {
  white: "WHITE",
  black: "BLACK",
  grey: "GREY",
  purple: "PURPLE",
};

export const FONT_WEIGHT = {
  small: 300,
  medium: 400,
  large: 500,
  xLarge: 600,
  xxLarge: 700,
  xxxLarge: 800,
  xxxxLarge: 900,
};

export const HEADER_LEFT_TYPE = {
  HIDE: "hide",
  LOGO: "logo",
  ARROW: "arrow",
  CLOSE: "close",
};

export const HEADER_CENTER_TYPE = {
  TITLE: "title",
};

export const HEADER_RIGHT_TYPE = {
  HIDE: "hide",
  MESSAGE: "message",
  SEARCH: "search",
  SETTING: "setting",
  MORE: "more",
  SKIP:"skip"
};

export const EVENT_NAME = {
  DISCONNECT: "disconnect",
  SEND_MESSAGE: "sendMessage",
  UPDATE_CHAT_DIRECT_MESSAGE: "updateChatDirectMessage",
  UPDATE_CHAT_GROUP: "updateChatGroup",
  UPDATE_NAME_GROUP: "updateNameGroup",
  JOIN_GROUP: "joinGroup",
  LEAVE_GROUP: "leaveGroup",
  LEAVE_PRIVATE_CHAT: "leavePrivateChat",
  JOIN_PRIVATE_CHAT: "joinPrivateChat",
  INVITED_TO_GROUP: "invitedToGroup",
  INVITE_TO_GROUP: "inviteToGroup",

  CHANGE_ALIAS_NAME: "changeAliasName",
  CHANGE_GROUP_NAME: "changeGroupName",

  CREATE_USER: "createUser",
  UPDATE_ROOMS: "updateRooms",
  CREATE_ROOM: "createRoom",

  EXCEPTION: "exception",
  ERROR: "error",

  // SDK
  UPDATE_NOTIFICATION: "updateNotification",

  GROUP_REMOVED: "groupRemoved",

  REMOVED_FROM_GROUP_BY_OWNER: "removedFromGroupByOwner",
};


export const QUERIES = {
  CHAT_LIST: 'chat-list',
  USERS_LIST: 'users-list',
  GROUP_LIST: 'group-list',
  MY_GROUP_LIST: 'my-group-list',
  GROUP_DETAIL: 'group-detail',
  PRIVATE_LIST: 'private-list',
  CATEGORY_LIST: 'category-list',
  STICKER_LIST: 'sticker-list',
  PACKAGE_LIST: 'package-list',
  PRIVATE_DETAIL: 'private-detail',
  ALL_CHAT_LIST: 'all-chat-list',
  LIST_MESSAGE_GROUP: 'list-message-group',
  LIST_MESSAGE_PRIVATE: 'list-message-private',
  LIST_USER_IN_GROUP: 'list-user-in-group',
  LIST_USER_CAN_JOIN_GROUP: 'list-user-can-join-group',
  LIST_MESSAGE_SEARCH: 'list-message-search',
  HASHTAG_LIST: 'hashtag-list',
  COLOR_LIST: 'color-list',
  DESIGN_LIST: 'design-list'
}

export const POST_TYPE = {
  RECOMMEND: "RECOMMEND",
  MY_FEED: "MY_FEED",
  MY_POST: "MY_POST",
  MY_POST_PAID: "MY_POST_PAID",
  MY_LIMITED_PAID: "MY_LIMITED_PAID",
  MY_QUIZ: "MY_QUIZ",
  MY_PAID: "MY_PAID",
  PUBLIC_POST: "PUBLIC_POST",
  PUBLIC_POST_PAID: "PUBLIC_POST_PAID",
  HOT_CREATOR: "HOT_CREATOR",
  HOT_CREATOR_MARKET: "HOT_CREATOR_MARKET",
};

export const STATUS = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const SORT_BY = {
  POPULAR: "popular",
  LATEST: "latest",
}