import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";

function GetAddress(props) {
  const handleGetAddress = () => {
    try {
      var element_layer = document.getElementById("layer");
      new daum.Postcode({
        oncomplete: (data) => {
          window?.flutter_inappwebview?.callHandler("oncomplete", data);
        },
        // }).open();
        width: "100%",
        height: "100%",
      }).embed(element_layer);
      element_layer.style.display = "block";
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    handleGetAddress();
  }, []);

  return (
    <>
      <Box display={"flex"} justifyContent={"center"}>
        <Box id='layer' style={{ display: "none", width: "100vw", height: "100vh" }}></Box>
      </Box>
      <Button sx={{ zIndex: "-1" }} id='get-address' onClick={handleGetAddress}>
        Get Address Change
      </Button>
    </>
  );
}

export default GetAddress;
