import React, { useRef, useState } from "react";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { COLORS, STATUS } from "../../theme/constants";
import { inputAdornmentMuiStyle } from "../../utils/commonStyle";
import dayjs from "dayjs";
import { useDropzone } from "react-dropzone";
import useSocketEmit from "../../hooks/chat/useSocketEmit";
import { generateVideoThumbnail, renderUId } from "@utils/commonFunction";
import { ReactComponent as PlusIcon } from "@icons/plus.svg";
import { ReactComponent as ImageIcon } from "@icons/image_white.svg";
import { ReactComponent as MessageIcon } from "@icons/message.svg";
import { ReactComponent as FileIcon } from "@icons/file_white.svg";
import { ReactComponent as ClearIcon } from "@icons/clear.svg";
import { ReactComponent as FileBlackIcon } from "@icons/file.svg";
import { getPresignedUrl } from "../../api";
import { TYPE_MESSAGE_CHAT } from "./Message";
import { dataURLtoFile, getFileTypeByName } from "../../utils/commonFunction";
import { uploadFileToChatSDK } from "../../service/chat_services";
import { uploadPresigned } from "../../service/cloudflare_services";
import { useTranslation } from "react-i18next";

function MessageInput(props) {
  const {
    receiverId,
    receiverName,
    localUser,
    conversationInfo = {},
    saveLocalMessage = () => {},
  } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const mediaInputRef = useRef(null);
  const fileInputRef = useRef(null);

  let enterRef = useRef(null);
  enterRef.current = dayjs();

  const { emitSendMessage } = useSocketEmit();

  const getNewFiles = async (element) => {
    const id = renderUId();
    let thumbnail = "";
    const isVideo = getFileTypeByName(element?.name) === "video";
    if (isVideo) {
      const thumbnailTmp = await generateVideoThumbnail(element);
      thumbnail = dataURLtoFile(thumbnailTmp, `${element?.name}.png`);
    }
    const newFile = {
      id: id,
      preview: URL.createObjectURL(element),
      path: URL.createObjectURL(element),
      name: element?.name,
      thumbnail: thumbnail,
      thumbnailPath: isVideo && URL.createObjectURL(thumbnail),
      file: element,
    };
    return await newFile;
  };

  const onDrop = async (acceptedFiles) => {
    const newFiles = await Promise.all(acceptedFiles.map(getNewFiles));
    setPreviewFiles((prevState) => [...prevState, ...newFiles]);
    setFiles((prevState) => [...prevState, ...newFiles]);
  };

  const onClearFile = (index) => {
    const newPreviewFiles = structuredClone(previewFiles);
    newPreviewFiles.splice(index, 1);
    setPreviewFiles(newPreviewFiles);

    const newFiles = structuredClone(files);
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [], "video/*": [] },
    maxFiles: 5,
    onDrop,
  });

  const { getRootProps: getRootPropsFile, getInputProps: getInputPropsFile } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    },
    maxFiles: 1,
    onDrop,
  });

  const uploadFiles = [
    {
      key: "image",
      name: t("gallery"),
      icon: <ImageIcon />,
    },
    // {
    //   key:"camera",
    //   name: "카메라",
    //   icon: <CameraIcon />,
    // },
    {
      key: "file",
      name: t("file"),
      icon: <FileIcon />,
    },
  ];

  const onUpload = (key) => {
    switch (key) {
      case "image":
        mediaInputRef.current.click();
        mediaInputRef.current.value = "";
        break;
      case "file":
        fileInputRef.current.click();
        fileInputRef.current.value = "";
        break;

      default:
        break;
    }
  };


  const handleChange = (event) => {
    const { value } = event.target;
    setValue(value);
  };

  const handleOnKeyDown = (e) => {
    if (e.keyCode == 13 && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  };

  const getRequestFilesChatSDK = async (element, index, fileLinks, fileThumbnailLinks) => {
    const file_buffer = await element?.file?.arrayBuffer();
    await uploadPresigned(fileLinks[index].url, file_buffer, {
      Authorization: null,
      lang: "kr",
      "Content-Type": element.type,
    });

    if (element?.thumbnail) {
      const file_thumbnail_buffer = await element?.thumbnail?.arrayBuffer();
      await uploadPresigned(fileThumbnailLinks[index].url, file_thumbnail_buffer, {
        Authorization: null,
        lang: "kr",
        "Content-Type": element.type,
      });
    }

    const result = {
      originalName: element?.file?.name,
      path: fileLinks[index].filename,
      meta: {
        thumbnail: element?.thumbnail ? fileThumbnailLinks[index].filename : "",
      },
    };

    return result;
    // upload success but need post file to store and then get ulr
  };

  const handleGetPresignedUrl = async (requestData) => {
    try {
      const res = await getPresignedUrl(requestData);
      return res?.data?.data;
    } catch (error) {}
  };

  const handleUploadImage = async (files, messageLocal) => {
    try {
      setPreviewFiles([]);
      const requestData = {
        list: [],
      };
      const requestThumbnailData = {
        list: [],
      };
      // const requestFilesChatSDK = [];
      files.forEach((element) => {
        const objFile = {
          filename: element?.file?.name,
          contentType: element?.file?.type,
        };
        const objThumbnail = {
          filename: element?.thumbnail?.name,
          contentType: "image/png",
        };
        requestData.list.push(objFile);
        requestThumbnailData.list.push(objThumbnail);
      });
      const [fileLinks, fileThumbnailLinks] = await Promise.all([
        handleGetPresignedUrl(requestData),
        handleGetPresignedUrl(requestThumbnailData),
      ]);

      const requestFilesChatSDK = await Promise.all(
        files.map((element, index) =>
          getRequestFilesChatSDK(element, index, fileLinks, fileThumbnailLinks)
        )
      );

      const conversationID = conversationInfo?.id;
      const resUploadFileChatSDK = await uploadFileToChatSDK(
        { files: requestFilesChatSDK },
        conversationID
      );

      const message = {
        ...messageLocal,
        attachments: resUploadFileChatSDK,
      };
      emitSendMessage(message);
      setValue("");
      setFiles([]);
    } catch (error) {
      saveLocalMessage({
        ...messageLocal,
        status: STATUS.ERROR,
        isLocalMessage: true,
      });
    }
  };

  const onSubmit = () => {
    if (!value && files?.length === 0) return;
    if (dayjs().diff(enterRef.current) > 180) {
      handleSubmit();
    }
    enterRef.current = dayjs();
  };

  const handleSubmit = () => {
    if (files?.length > 0) {
      const clientId = renderUId();
      handleSubmitFiles(clientId, files);
      return;
    }

    if (value) {
      const clientId = renderUId();
      handleSubmitText(clientId);
    }
  };

  const handleSubmitText = (clientId) => {
    const messageType = TYPE_MESSAGE_CHAT.MESSAGE;
    const messageLocal = {
      status: STATUS.LOADING,
      receiverId,
      receiverName,
      type: messageType,
      content: value,
      clientId: clientId,
      attachments: [],
      stickers: [],
      // app: "6502d509f0efbc58a4933f1f",
      // id: "17161608236382552151",
      author: {
        // id: "656aabf08f9a3c48746641cb",
        externalId: localUser?.profile?.user_id,
        // app: "6502d509f0efbc58a4933f1f",
        // system: false,
        globalName: localUser?.profile?.nickname,
        avatar: {
          avatarURL: localUser?.profile?.profile_image_url,
        },
        email: localUser?.email,
      },
      createdTimestamp: dayjs().valueOf(),
      // system: false,
      // pinned: false,
      // mentions: null,
    };
    saveLocalMessage({
      ...messageLocal,
      isLocalMessage: true,
      status: STATUS.LOADING,
    });

    const message = {
      // groupId: "", // do not need
      // groupName: "", // do not need
      receiverId,
      receiverName,
      type: messageType,
      content: value,
      clientId: clientId, // auto generation
      attachments: [], // do not need "message" type
      stickers: [], // do not need "message" type
    };
    emitSendMessage(message);
    setValue("");
  };

  const handleSubmitFiles = (clientId, files) => {
    const messageType = TYPE_MESSAGE_CHAT.FILE;
    const messageLocal = {
      status: STATUS.LOADING,
      receiverId,
      receiverName,
      type: messageType,
      content: value,
      clientId: clientId,
      attachments: previewFiles,
      stickers: [],
      // app: "6502d509f0efbc58a4933f1f",
      // id: "17161608236382552151",
      author: {
        // id: "656aabf08f9a3c48746641cb",
        externalId: localUser?.profile?.user_id,
        // app: "6502d509f0efbc58a4933f1f",
        // system: false,
        globalName: localUser?.profile?.nickname,
        avatar: {
          avatarURL: localUser?.profile?.profile_image_url,
        },
        email: localUser?.email,
      },
      createdTimestamp: dayjs().valueOf(),
      // system: false,
      // pinned: false,
      // mentions: null,
    };
    saveLocalMessage({
      ...messageLocal,
      isLocalMessage: true,
      status: STATUS.LOADING,
    });

    handleUploadImage(files, messageLocal);
  };

  return (
    <Box
      sx={{
        // position: "fixed",
        // bottom: "1.5rem",
        // width: `${isPc ? 826 - 40 : width - 40}px`,
        // px: "1rem",
        width: "100%",
        marginBottom: "1.5rem",
      }}
    >
      <div {...getRootProps()}>
        <input
          {...getInputProps()}
          ref={mediaInputRef}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
      </div>
      <div {...getRootPropsFile()}>
        <input
          {...getInputPropsFile()}
          ref={fileInputRef}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.75rem",
          overflow: "auto",
        }}
      >
        {previewFiles.map((item, index) => (
          <Box sx={{ position: "relative", aspectRatio: 1 }} key={item.id}>
            {getFileTypeByName(item?.name) === "video" && (
              <img src={item.thumbnailPath} style={{ width: "60px", height: "60px" }} />
            )}
            {getFileTypeByName(item?.name) === "image" && (
              <img src={item.path} style={{ width: "60px", height: "60px" }} />
            )}
            {getFileTypeByName(item?.name) === "file" && (
              <FileBlackIcon style={{ width: "60px", height: "60px" }} />
            )}
            <ClearIcon
              style={{
                position: "absolute",
                top: -2,
                right: -2,
                cursor: "pointer",
              }}
              onClick={() => onClearFile(index)}
            />
          </Box>
        ))}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <PlusIcon
          style={{
            marginRight: "0.5rem",
            width: "24px",
            height: "24px",
            cursor: "pointer",
            // visibility: "hidden",
          }}
          onClick={() => setIsUpload(!isUpload)}
        />
        <TextField
          name='message'
          type='text'
          value={value}
          onChange={handleChange}
          onKeyDown={handleOnKeyDown}
          placeholder={t("enter_your_message")}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              backgroundColor: COLORS.grey50,
              border: "none",
              borderRadius: "28px",
            },
            "& .MuiInputBase-root": {
              // height: "48px",
            },
            "& .MuiInputBase-input": {
              zIndex: "1",
            },
          }}
          maxRows={4}
          inputProps={{
            style: {
              padding: "0px 16px",
              "&::placeholder": {},
            },
          }}
          multiline
          InputProps={{
            endAdornment: (
              <InputAdornment position='end' sx={inputAdornmentMuiStyle} onClick={onSubmit}>
                <MessageIcon style={{ stroke: COLORS.black1 }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {isUpload && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            mt: "10px",
            mb: "20px",
          }}
        >
          {uploadFiles.map((item) => (
            <Box
              key={item.key}
              sx={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => onUpload(item.key)}
            >
              <Box
                sx={{
                  backgroundColor: COLORS.grey200,
                  padding: "0.5rem",
                  borderRadius: "50%",
                  width: "48px",
                  height: "48px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item.icon}
              </Box>

              <Typography
                sx={{
                  mt: 0.5,
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "18px",
                  color: COLORS.black1,
                }}
              >
                {item.name}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default MessageInput;
