import React from "react";
import { Box, Typography } from "@mui/material";
import { COLORS } from "@theme/constants";
import { ReactComponent as Comment } from "@icons/comment.svg";

function CommentComponent(props) {
  const {
    post = {
      comment_count: 0,
    },
    handleClickComment,
    styleBox,
    styleIcon,
    styleText,
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        mx: 0.5,
        alignItems: "center",
        cursor: "pointer",
        ...styleBox,
      }}
      onClick={handleClickComment}
    >
      <Comment
        width={24}
        height={24}
        style={{
          fill: COLORS.black1,
          ...styleIcon,
        }}
      />
      <Typography sx={{ ml: 0.3, ...styleText }}>
        {post.comment_count}
      </Typography>
    </Box>
  );
}

export default CommentComponent;
