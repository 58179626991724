import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Countdown, { zeroPad } from "react-countdown";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import dayjs from "dayjs";
import { ReactComponent as QuizBackground } from "@icons/quiz-bg.svg";
import { handleError } from "@utils/commonFunction";
import { COLORS, WIDTH } from "@theme/constants";
import { getFloatingQuiz } from "@api";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { convertResponseTimeToLocalTime } from "../../utils/dateTimeFormat";

const Wrapper = styled.div`
  cursor: pointer;

  .quiz-bg {
    margin-bottom: 2px;
    width: 32px;
    height: 24px;
    path {
      fill: ${COLORS.primary4};
      fill-opacity: 1;
    }
  }
`;

function FloatingButtonNew(props) {
  const { styleProps } = props;
  const { logout } = useAuth();
  const [post, setPost] = useState();
  const navigate = useNavigate();
  const [dateCountdown, setDateCountdown] = useState(dayjs().unix() + 1000);

  const isMobileWithoutFold = useMediaQuery({
    query: `(max-width: ${WIDTH.fold_from})`,
  });

  // 516-674
  const isFold = useMediaQuery({ minWidth: 516, maxWidth: 674 });

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const isMax500 = useMediaQuery({
    query: `(max-width: 500px)`,
  });

  const isBeforeStart = (res) => {
    return dayjs().isBefore(convertResponseTimeToLocalTime(res?.event_start));
  };

  const handleGetFloatingData = async () => {
    try {
      const res = await getFloatingQuiz();
      if (isBeforeStart(res)) {
        setDateCountdown(convertResponseTimeToLocalTime(res?.event_start).toString());
      } else {
        setDateCountdown(convertResponseTimeToLocalTime(res?.event_end).toString());
      }
      setPost(res);
    } catch (error) {
      handleError(error, logout, navigate);
    }
  };

  const handleCompleteCountdown = () => {
    if (Math.abs(dayjs().diff(convertResponseTimeToLocalTime(post?.event_start))) < 1000) {
      setDateCountdown(convertResponseTimeToLocalTime(post?.event_end).toString());
    }
  };

  useEffect(() => {
    handleGetFloatingData();
  }, []);

  const rendererCountdown = ({ hours, minutes, seconds, completed }) => {
    return (
      <Box
        sx={{ display: "flex", gap: "0px", justifyContent: "center", alignItems: "center" }}
        id='btn-quiz'
      >
        <Typography
          sx={{
            fontSize: "8px",
            fontWeight: "300",
            lineHeight: "8px",
            color: COLORS.primary3,
            // minWidth: "40px"
          }}
        >
          {zeroPad(hours)}
        </Typography>
        <Typography
          sx={{
            fontSize: "8px",
            fontWeight: "300",
            lineHeight: "8px",
            color: COLORS.primary3,
            marginTop: "-2px",
          }}
        >
          :
        </Typography>
        <Typography
          sx={{
            fontSize: "8px",
            fontWeight: "300",
            lineHeight: "8px",
            color: COLORS.primary3,
            // minWidth: "40px"
          }}
        >
          {zeroPad(minutes)}
        </Typography>
        <Typography
          sx={{
            fontSize: "8px",
            fontWeight: "300",
            lineHeight: "8px",
            color: COLORS.primary3,
            marginTop: "-2px",
          }}
        >
          :
        </Typography>
        <Typography
          sx={{
            fontSize: "8px",
            fontWeight: "300",
            lineHeight: "8px",
            color: COLORS.primary3,
            // minWidth: "40px",
          }}
        >
          {zeroPad(seconds)}
        </Typography>
      </Box>
    );
  };

  if (!post) {
    return;
  }

  return (
    <Wrapper>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          zIndex: 100,
          left: `${`calc(${document.getElementById("page-content")?.offsetLeft}px + ${
            document.getElementById("page-content")?.offsetWidth
          }px - 70px)`}`,
          bottom: `${isMobileWithoutFold ? "90px" : "10px"}`,
          // bottom: `${isPc || isFold ? "10px" : "90px"}`,
        }}
      >
        <Box
          onClick={() => navigate(`/quiz/${post?.id}`)}
          sx={{
            // position: "fixed", bottom: 0

            background: COLORS.white,
            width: "52px",
            height: "52px",
            // bottom: `${isPc ? "0px" : "90px"}`,
            // right: `${isPc ? "0px" : "16px"}`,
            // zIndex: 99,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            border: `1px solid ${COLORS.primary4}`,
            ...styleProps,
          }}
        >
          <QuizBackground className='quiz-bg' />

          <Typography
            sx={{
              position: "absolute",
              fontSize: "10px",
              fontWeight: "700",
              lineHeight: "14px",
              color: COLORS.white,
              top: "12px",
            }}
          >
            QUIZ
          </Typography>
          <Countdown
            key={dateCountdown}
            date={dateCountdown}
            onComplete={handleCompleteCountdown}
            renderer={rendererCountdown}
          />
        </Box>
      </Box>
    </Wrapper>
  );
}

export default FloatingButtonNew;
