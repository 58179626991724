import { useContext, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Box } from "@mui/material";
import { COLORS, HEADER_LEFT_TYPE, HEADER_CENTER_TYPE, WIDTH } from "@theme/constants";
import Navigation from "@containers/navigations/Navigation";
import NavigationTablet from "../../containers/navigations/NavigationTablet";
import NavigateDesktop from "../../containers/navigations/NavigateDesktop";
import CommonHeader from "./header/CommonHeader";
import BaseButtonScrollTopNew from "../buttonScrollTop/BaseButtonScrollTopNew";
import MainHeader from "./header/MainHeader";
import { HEADER_RIGHT_TYPE } from "../../theme/constants";
import { GlobalStateContext } from "../../context/GlobalStateProvider";

function SearchFeedLayout(props) {
  const {
    isNavigation = false,
    isSideBar = false,
    isFooter = false,
    isHeader = true,
    isScrollTop = true,
    isGuideline = true,
    leftType = "",
    centerType = "",
    rightType = "",
    pageTitle = "",
    titleStyle = {},
    page,
    searchUrl,
  } = props;

  const { globalState } = useContext(GlobalStateContext);
  const contentRef = useRef(null);
  const navRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  const isMobileWithoutFold = useMediaQuery({
    query: `(max-width: ${WIDTH.fold_from})`,
  });

  // 516-674
  const isFold = useMediaQuery({ minWidth: 516, maxWidth: 674 });
  const isShowNavigateTablet = useMediaQuery({ minWidth: 516, maxWidth: 1200 });

  const isPc = useMediaQuery({
    query: `(min-width: ${WIDTH.desktop})`,
  });

  const styleContentByNavbar = (left) => {
    const styleLeft = {
      pl:
        isMobileWithoutFold || !isSideBar
          ? "0px"
          : isShowNavigateTablet && isNavigation
          ? "80px"
          : isPc
          ? "300px"
          : 0,
    };

    const styleRight = {
      pr: isShowNavigateTablet && isNavigation ? "80px" : 0,
      pl: isPc ? "300px" : 0,
    };

    return !left ? styleRight : styleLeft;
  };

  const getHeader = () => {
    switch (page) {
      case "searchByCategory":
        return (
          <MainHeader
            leftType={isPc ? "" : HEADER_LEFT_TYPE.LOGO}
            rightType={HEADER_RIGHT_TYPE.SEARCH}
            searchUrl={searchUrl}
          />
        );
      case "searchDetailByCategory":
        return (
          <MainHeader leftType={HEADER_LEFT_TYPE.ARROW} rightType={""} searchUrl={searchUrl} />
        );

      default:
        break;
    }
  };

  return (
    <>
      {isNavigation && isShowNavigateTablet && <NavigationTablet ref={navRef} />}
      {!isPc && isNavigation && isMobileWithoutFold && <Navigation ref={navRef} />}
      <Box
        sx={{
          maxWidth: "1200px",
          mx: "auto",
        }}
        id={"scrollableContent"}
      >
        {/* header */}
        {isHeader && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              width: "100%",
              zIndex: 100,
              maxWidth: "1200px",
            }}
          >
            <CommonHeader
              leftType={leftType || HEADER_LEFT_TYPE.ARROW}
              centerType={centerType || HEADER_CENTER_TYPE.TITLE}
              titleStyle={{
                ...titleStyle,
                color: COLORS.black1,
              }}
              rightType={rightType || HEADER_RIGHT_TYPE.SEARCH}
              pageTitle={pageTitle}
            />
          </Box>
        )}
        {/* sidebar */}
        {isSideBar && (
          <Box
            sx={{
              position: "fixed",
              top: "56px",
              width: "100%",
              zIndex: 100,
              maxWidth: "300px",
            }}
          >
            <NavigateDesktop isGuideline={isGuideline} />
          </Box>
        )}
        {/* content */}
        <Box
          sx={{
            width: "100%",
            maxWidth: "1200px",
            mx: isPc ? "0" : "auto",
            pt: isHeader ? "56px" : 0,
            pb: !isHeader ? 0 : isPc ? "180px" : "100px",
            ...styleContentByNavbar(globalState?.isNavbarLeft),
          }}
          ref={contentRef}
          id='main-content'
        >
          <Outlet />
        </Box>
        {isScrollTop && (
          <BaseButtonScrollTopNew
            styleButtonProps={{}}
            isDarkMode={false}
            isScrolling={isScrolling}
            isSideBar={isSideBar}
            isFooter={isFooter}
          />
        )}
      </Box>
    </>
  );
}

export default SearchFeedLayout;
