import React, { useContext } from "react";
import {
  ICON_SIZE,
  COLORS,
  LINE_HEIGHT,
  RADIUS,
  TEXTSIZE,
  FONT_WEIGHT,
  POST_TYPE,
} from "@theme/constants";
import { useNavigate } from "react-router-dom";
import NoData from "../../../NoData";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import HotCreatorMedia from "./HotCreatorMedia";
import { textXLargeMuiStyle, textSmallMuiStyle } from "@utils/commonStyle";
import { useTranslation } from "react-i18next";
import ButtonConnect from "../../../connect/ButtonConnect";
import { GlobalStateContext } from "../../../../context/GlobalStateProvider";
import Author from "@containers/author/Author";

function HotCreator(props) {
  const { data, categoryId, fetchMoreData } = props;

  const backgroundHomeType = 2;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSelectedPost } = useContext(GlobalStateContext);

  return (
    <Box sx={{ width: "100%", pl: 0, py: 3.5 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedPost({
                id: "",
                index: 8,
                from: POST_TYPE.HOT_CREATOR,
                category_id: categoryId,
              });
              navigate(`/details/category/${POST_TYPE.HOT_CREATOR}/${categoryId}`);
            }}
          >
            <Typography
              sx={{
                ...textXLargeMuiStyle,
                color: backgroundHomeType == 1 ? COLORS.white : COLORS.black1,
              }}
            >
              HOT Creator
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedPost({
              id: "",
              index: 8,
              from: POST_TYPE.HOT_CREATOR,
              category_id: categoryId,
            });
            navigate(`/details/category/${POST_TYPE.HOT_CREATOR}/${categoryId}`);
          }}
        >
          <Typography sx={{ ...textSmallMuiStyle, color: COLORS.grey300 }}>
            {t("see_more")}
          </Typography>
        </Box>
      </Box>
      {data ? (
        <Swiper width={140} onReachEnd={fetchMoreData}>
          {data.map((post, indexPost) => (
            <SwiperSlide key={post.id} style={{ background: "transparent" }}>
              <Box sx={{ position: "relative", my: 2, mr: 1.5 }}>
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: "9",
                    top: "0",
                    width: "100%",
                  }}
                >
                  <Author
                    authorData={{
                      id: post?.user_profile?.user_id,
                      avatar: post?.user_profile?.profile_image_url,
                      name: post?.user_profile?.nickname,
                      job: post?.user_profile?.introduce,
                    }}
                    authorStyle={{
                      borderRadius: `${RADIUS.medium} ${RADIUS.medium} 0 0`,
                      background: "rgba(0,0,0,0.3)",
                      gap: "0.25rem",
                    }}
                    avatarStyle={{
                      width: ICON_SIZE.small,
                      height: ICON_SIZE.small,
                    }}
                    nameStyle={{
                      fontSize: TEXTSIZE.xSmall,
                      lineHeight: LINE_HEIGHT.small,
                      fontWeight: FONT_WEIGHT.large,
                      width: "100%",
                      textAlign: "left",
                    }}
                    jobStyle={{
                      fontSize: TEXTSIZE.xSmall,
                      lineHeight: LINE_HEIGHT.small,
                      fontWeight: FONT_WEIGHT.large,
                      textAlign: "left",
                    }}
                  />
                </Box>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedPost({
                      id: post.id,
                      index: indexPost,
                      from: POST_TYPE.HOT_CREATOR,
                      category_id: categoryId,
                    });
                    navigate(`/details/category/${POST_TYPE.HOT_CREATOR}/${categoryId}`);
                  }}
                >
                  <HotCreatorMedia data={post.files?.[0]} />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: "9",
                    bottom: "8px",
                    left: "8px",
                  }}
                >
                  <ButtonConnect
                    isConnect={post?.connection ? true : false}
                    userId={post?.user_profile?.user_id}
                    buttonProps={{}}
                    handleConnectSuccess={() => {}}
                    textCancelConnect={t("button.connecting")}
                    textConnect={t("button.connect")}
                  />
                </Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <NoData />
      )}
    </Box>
  );
}

export default HotCreator;
