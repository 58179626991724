import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import PostDetailItem from "../../containers/posts/PostDetailItem";
import QuizHomeItem from "../../containers/quiz/QuizHomeItem";
import NoData from "../../components/NoData";
import RecommendInside from "../../components/newComponents/posts/Recommend/RecommendInside";
import PostSkeleton from "../../components/layouts/skeleton/PostSkeleton";
import useGetQuizzes from "../../hooks/useGetQuizzes";
import checkBoxImage from "@images/checkBox.svg";
import checkedBoxImage from "@images/checkedBox.svg";
import QuizPageItem from "./QuizPageItem";

const HeightWhenEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 500px);
  position: relative;
`;

function QuizList() {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const [inViewIndex, setInViewIndex] = useState(0);
  const { data, posts, isLoading, fetchNextPage, setFilters } = useGetQuizzes();
  const [postViews, setPostViews] = useState([]);

  const onVideoInView = (index) => {
    setInViewIndex(() => {
      return index;
    });
  };

  useEffect(() => {
    setPostViews(posts);
  }, [posts]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  const handleConnectSuccess = (userId, status) => {
    const newConnection =
      status == "created" ? { created_at: dayjs().format("YYYY-MM-DDTHH:mm:ssZ[Z]") } : "";
    const postsTmp = postViews.map((post) =>
      post?.user_profile?.user_id == userId
        ? {
            ...postViews,
            connection: newConnection,
          }
        : post
    );
    setPostViews(postsTmp);
  };

  const [isChecked, setIsChecked] = useState(false);
  if (isLoading)
    return (
      <Box>
        <PostSkeleton />
        <PostSkeleton />
      </Box>
    );

  return (
    <>
      <Box sx={{pt: "3px"}}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => {
                  setIsChecked(!isChecked);
                  setFilters({
                    expired: !isChecked ? 1 : 0,
                  });
                }}
                checked={isChecked}
                icon={<img src={checkBoxImage} />}
                checkedIcon={<img src={checkedBoxImage} />}
              />
            }
            label='참여내역보기'
          />
        </FormGroup>
      </Box>
      {postViews?.length > 0 ? (
        <Grid container spacing={2} sx={{mt: 0}}>
          {postViews?.map((post, indexPost) =>
            post?.is_recommend_area ? (
              <RecommendInside
                key={indexPost}
                data={post?.recommend_list}
                offset={post?.recommend_offset}
              />
            ) : (
              post?.id && (
                <Grid item xs={12} md={12} key={post.id}>
                  {post?.type == 3 ? (
                    <QuizPageItem post={post} />
                  ) : (
                    <PostDetailItem
                      indexPost={indexPost}
                      prevPost={postViews[indexPost - 1]}
                      post={post}
                      fetchData={fetchNextPage}
                      onVideoInView={() => onVideoInView(indexPost)}
                      onVideoOutView={() => onVideoInView(-1)}
                      isPostInView={inViewIndex === indexPost}
                      handleConnectSuccess={handleConnectSuccess}
                    />
                  )}
                </Grid>
              )
            )
          )}
          <p ref={ref}></p>
        </Grid>
      ) : (
        <HeightWhenEmpty>
          <NoData text='게시물이 없습니다.' />
        </HeightWhenEmpty>
      )}
    </>
  );
}

export default QuizList;
